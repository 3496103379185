$feature-link-right-margin: 16px;
$feature-links-top-margin: 32px;
$feature-link-to-link-spacing: 16px;
$feature-secondary-link-to-link-padding-top-bottom: 8px;
$feature-secondary-link-to-link-padding-left-right: 16px;
$feature-content-textplacement-left-margin: auto 40px auto 20px;
$feature-content-textplacement-right-margin: auto 20px auto 40px;
$feature-content-text-margin-top: 21px;
$feature-content-button-margin-top: 32px;
$feature-cta-font-size: var(--dobbies-body-font-size-s);
$feature-cta-line-height: 20px;
$feature-content-mobile-margin-top: 21px;

:root {
  // heading
  --msv-content-block-heading-font-size: var(--dobbies-body-font-size-xxl);
  --msv-content-block-heading-font-color: var(--dobbies-font-primary-color);

  // text
  --msv-content-block-text-font-size: var(--dobbies-body-font-size-l);
  --msv-content-block-textheme-dark: var(--dobbies-font-primary-color);
  
  // primary button
  --msv-content-block-primary-btn-bg: var(--dobbies-accent-brand-color);
  --msv-content-block-primary-btn-font-color: var(--dobbies-font-secondary-color);
  --msv-content-block-primary-btn-border: var(--dobbies-accent-brand-color);
 }

.ms-content-block {

  &[data-m-layout='left-right'] {
    .ms-content-block__image {
      width: 100%;
    }

    .ms-content-block__details {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: 0;
      padding: 20px 60px 50px 60px;
      position: absolute;
      right: 0;
      top: 0;
    }

    .msc-cta__primary {
      @include button-default();
      @include primary-button(var(--msv-content-block-primary-btn-bg), var(--msv-content-block-primary-btn-font-color), var(--msv-content-block-primary-btn-border));
    }

    .msc-cta__secondary {
      padding: $feature-secondary-link-to-link-padding-top-bottom $feature-secondary-link-to-link-padding-left-right;
    }

    .ms-content-block__title {
      @include font-content(var(--msv-font-weight-normal), var(--msv-content-block-heading-font-size), $dobbies-line-height-xxl);
    }

    .ms-content-block__text {
      @include font-content(var(--msv-font-weight-normal), var(--msv-content-block-text-font-size), $dobbies-line-height-l);
    }

    .ms-content-block__cta {
      display: flex;
      align-items: flex-end;
    }

    &.textplacement__left {
      .ms-content-block__details {    
        .card-cta {
          justify-content: flex-start;
        }
      }    
    }

    &.textplacement__right {
      .ms-content-block__details {  
        .card-cta {
          justify-content: flex-end;
        }
      }    
    }

    &.textplacement__center {
      .ms-content-block__details {  
        text-align: center;      
        .card-cta {
          justify-content: center;
        }
      }    
    }

    .ms-content-block__image img {
      width: 100%;
    }

    /* body should have top margin only when there's something above it */
    * + .ms-content-block__text {
      margin-top: $feature-content-text-margin-top;
    }

    /* links should have top margin only when there's something above it */
    * + .ms-content-block__cta {
      margin-top: $feature-content-button-margin-top;
    }

    @media screen and (min-width: $msv-breakpoint-m) {
      &.textplacement__left {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        
        .ms-content-block__details {   
          margin: $feature-content-textplacement-left-margin;
        }
      }

      &.textplacement__right {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        
        .ms-content-block__details {
          margin: $feature-content-textplacement-right-margin;
        }
      }

      &.textplacement__center {
        .ms-content-block__details {
          align-items: center;
          text-align: center;
        }
        .ms-content-block__image img {
          width: 100%;
        }
      }

      &.textplacement__left {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;

        .ms-content-block__image {
          grid-column: 2 / span 1;
          grid-row: 1 / span 1;
          margin-bottom: auto;
          margin-top: auto;
        }

        .ms-content-block__details {
          grid-column: 1 / span 1;
          grid-row: 1 / span 1;
        }
      }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
      .ms-content-block__details {
        margin-top: $feature-content-mobile-margin-top;
      }

      .ms-content-block__image img {
        width: 100%;
      }
    }
  }
  
  &.category-banner {
    @media screen and (max-width: $msv-breakpoint-m) {
      margin-bottom: 10px;
    }
    .ms-content-block__details {
      display: flex;
      height: calc(100% - 32px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      left: 0;
      padding: 10px 30px 30px 30px;
      position: absolute;
      right: 0;
      top: 32px;
      bottom: 0;
      width: calc(100% - 60px);
      // height: 250px;
      margin: auto;
      text-align: center;
      @media screen and (max-width: $msv-breakpoint-m) {
        // padding: 10px 15px 20px 15px;
        // max-width: 90%;
        // height: 230px;
        flex-wrap: wrap;

        .ms-content-block__text {
          font-size: var(--dobbies-body-font-size-ml);
          position: absolute;
          top: 140px;
          left: -20px;
          text-align: left;
          line-height: 24px;
          width: calc(100% + 40px);

          @media screen and (max-width: $msv-breakpoint-mbl-sm){
            font-size: var(--dobbies-body-font-size-m);
            top: 112px;
            line-height: 21px;
          }
          @media screen and (max-width: $msv-breakpoint-mbl){
            top: 96px;
            left: -24px;
          }
        }
      }
      @media screen and (max-width: $msv-breakpoint-mbl-sm) {
        height: 200px;
        margin: 0 auto;
      }
    }
    .ms-content-block__title {
      color: $dobbies-footer-font-color;
      @include font-content(var(--msv-font-weight-normal), var(--msv-content-block-heading-font-size));
      line-height: 50px;
      font-size: 46px;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      margin-bottom: 20px;
      text-align: left;
      @media screen and (max-width: $msv-breakpoint-m){
        position: relative;
        top: -25px;
        left: -20px;
        width: 76%;
        font-size: 40px;
        padding-bottom: 10px;
      }
      @media screen and (max-width: 698px){
        width: 85%;
      }
      @media screen and (max-width: $msv-breakpoint-mbl-sm) {
        line-height: 42px;
        font-size: 30px;
        margin-bottom: 0;
        top: -40px;
        left: -30px;
        width: 84%;
      }
      @media screen and (max-width: 476px){
        width: 95%;
      }
      @media screen and (max-width: $msv-breakpoint-mbl){
        width: 100%;
        font-size: 26px;
        line-height: 32px;
        top: -48px;
        left: -32px;
      }
      @media screen and (max-width: 383px){
        font-size: 24px;
        width: calc(100% + 45px);
        left: -35px;
      }
      @media screen and (max-width: 320px){
        font-size: 24px;
        width: calc(100% + 60px);
        left: -35px;
      }
    }

    .ms-content-block__text {
      color: $dobbies-footer-font-color;
      max-width: 400px;
      text-align: left;
      font-size: 14px;
      @media screen and (max-width: $msv-breakpoint-m){
        max-width: 768px;
      }
      @include font-content(var(--msv-font-weight-normal), $dobbies-line-height-l);
      p, span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        white-space: normal;
        font-size: 14px;

      }
    }
    &.white-text {
      .ms-content-block__title,.ms-content-block__text  {
        color: $dobbies-white;
        @media screen and (max-width: $msv-breakpoint-m){
          color: $dobbies-grey;
        }
      }
    }
    &.no-image-categories {
			height: auto;
			min-height: auto;
			margin: 0;
      padding: 0;
        .ms-content-block__image {
          display: none;
        }
        .ms-content-block__details {
          position: static;
          margin: 0;
          width: 100%;
          @media screen and (max-width: $msv-breakpoint-m) {
            margin-top: 0px;
          }
          .ms-content-block__title {
            color: #111;
            font-weight: 500;
            font-size: 30px;
            line-height: 1;
            text-align: center;
            text-transform: uppercase;
            padding: 20px 0 10px 0;
            border-bottom: 1px solid #1e1e1e;
            text-transform: uppercase;
            width: 100%;
            margin: 0;
            @media screen and (max-width: $dobbies-container-max-width-tablet) {
              font-size: 24px;
            }
  
            @media screen and (max-width: $dobbies-container-max-width-m) {
              margin-bottom: 20px;
            }
  
            @media screen and (max-width: $dobbies-container-max-width-s) {
              margin-bottom: 0px;
            }
          }
          .ms-content-block__text {
            display: none;
          }
        }
    }
    &.no-category-image {
      min-height: auto;
      padding: 0;
      margin-bottom: -70px;
      @media (max-width: $msv-breakpoint-m) {
        margin: 0 -12px;
      }
      @media (max-width: $dobbies-container-max-width-m) {
        margin-bottom: 0;
      }
      .ms-content-block__details {
        position: static;
        display: block;
        padding: 0;
        width: 100%;
        height: auto;
        padding: 0 20px;
        max-width: 1068px;
        width: 100%;
        @media (max-width: $msv-breakpoint-m) {
          padding: 0px 28px;
        }
        @media (min-width: ($dobbies-container-max-width-xl + 1px)) {
          max-width: 1476px;
        }
      }
      .ms-content-block__image {
        display: none;
      }
      .ms-content-block__text {
        display: none;
      }
      .ms-content-block__title {
        color: $dobbies-grey;
        font-size: 40px;
        line-height: 1;
        text-align: center;
        padding: 20px 0;
        font-weight: $dobbies-font-weight-normal;
        border-bottom: 1px solid $dobbies-light-grey;
        text-transform: uppercase;
        @media (max-width: $msv-breakpoint-m) {
          font-size: 32px;
        }
        @media (max-width: $msv-breakpoint-mbl-sm) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
