$msv-icon-font-family: 'Font Awesome 5 Free';
$dobbies-icon-font-family: 'icomoon';
$msv-solid-icon-weight: 900;
$msv-outline-icon-weight: 400;

$dobbies-solid-icon-weight: 900;
$dobbies-outline-icon-weight: 400;

$dobbies-font-path: '../../../webfonts';
$dobbies-icons-path: '../../../icons';


$msv-solid-icon-weight: normal;
$msv-outline-icon-weight: 400;

$msv-font-family-rubik: "Rubik";
$msv-font-family-rubik-regular: "Rubik-Regular";
$msv-font-family-rubik-bold: "Rubik-Bold";
$msv-font-family-rubik-medium: "Rubik-Medium";
$msv-font-family-beth-ellen: "BethEllen";
$msv-font-family-nothing-you-could-do: "Nothing You Could Do";

$msv-font-path: "../../../webfonts";


$msv-icon-size-m: 20px;
$msv-icon-size-xs: 24px;
$msv-icon-size-small: 28px;
$msv-icon-size-medium: 40px;
$msv-icon-size-large: 48px;


$msv-CommentSolid: "\f27a";
$msv-ChevronDown: "\f078";
$msv-ChevronUp: "\f077";
$msv-Edit: "\f303";
$msv-Add: "\f067";
$msv-Cancel: "\f00d";
$msv-More: "\f141";
$msv-Settings: "\f013";
$msv-Phone: "\f095";
$msv-Shop: "\f290";
$msv-Shop-Address: "\f3c5";
$msv-Filter: "\f0b0";
$msv-Search: "\f002";
$msv-FavoriteStar: "\f005";
$msv-FavoriteStarFill: "\f005";
$msv-half-star: "\f089";
$msv-Delete: "\f2ed";
$msv-Save: "\f0c7";
$msv-Play: "\f04b";
$msv-Pause: "\f04c";
$msv-ChevronLeft: "\f053";
$msv-ChevronRight: "\f054";
$msv-Contact: "\f007";
$msv-Warning: "\f071";
$msv-IncidentTriangle: "\f071";
$msv-Dislike: "\f165";
$msv-Like: "\f164";
$msv-DislikeSolid: "\f165";
$msv-LikeSolid: "\f164";
$msv-Comment: "\f27a";
$msv-Heart: "\f004";
$msv-HeartFill: "\f004";
$msv-IdCard: "\f2c2";
$msv-DeliveryTruck: "\f0d1";
$msv-SolidPlay: "\f04b";
$msv-Mute: "\f6a9";
$msv-UnMute: "\f028";
$msv-Fullscreen: "\f065";
$msv-ExitFullscreen: "\f066";
$msv-ClosedCaptions: "\f20a";
$msv-Selected: "\f00c";
$msv-Error: "\f06a";
$msv-Checkbox: "\f0c8";
$msv-Checkbox-Checked: "\f14a";
$msv-Checkbox-Circle-Checked: "\f058";
$msv-RadioBtnOff: "\f111";
$msv-RadioBtnOn: "\f192";
$msv-SignIn: "\f2f6";
$msv-SignOut: "\f2f5";
$msv-History: "\f1da";
$msv-MapPin: "\f276";
$msv-information: "\ee59";
$msv-ContactCard: "\f2c2";
$msv-GlobalNavButton: "\f0c9";
$msv-Remove:"\f068";
$msv-ShoppingCart:"\f07a";
$msv-ToggleLeft:"\f204";
$msv-ToggleRight:"\f205";
$msv-arrow-right: '\f061';
$msv-Spinner: '\f110';
$msv-eye: "\ecb5";
$msv-file: '\f15c';
$msv-Heart-Fill: "\ee0e";
$msv-StoreAlt: '\f54f';
$msv-DollarSign: '\f155';
$msv-OrganizationUsers:'\f0c0';
$msv-ListAlt: '\f022';

$msv-magnifying-glass: $msv-Search;
$msv-heart: $msv-Heart;
$msv-shopping-bag: $msv-Shop;
$msv-x-shape: $msv-Cancel;

$dobbies-icon-store: '\e925';
$dobbies-icon-account: '\e926';
$dobbies-icon-cart-filled: "\e90f";
$dobbies-icon-cart: "\e927";
$dobbies-icon-favourite: "\e928";
$dobbies-icon-search: "\e91c";
$dobbies-icon-hamburger: "\e91b";
$dobbies-icon-arrow: "\e91a";
$dobbies-icon-star: "\e919";
$dobbies-icon-close: "\e918";
$dobbies-icon-minus: "\e90d";
$dobbies-icon-plus: "\e90e";
$dobbies-icon-thumbs-up: "\e909";
$dobbies-icon-thumbs-up-filled: "\e90a";
$dobbies-icon-thumbs-down: "\e90b";
$dobbies-icon-thumbs-down-filled: "\e90c";
$dobbies-icon-star-filled: "\e910";
$dobbies-icon-favourites-icon-filled: "\e911";
$dobbies-icon-dobbies: "\e912";
$dobbies-icon-home: "\e913";
$dobbies-icon-booking: "\e900";
$dobbies-icon-vouchers: "\e901";
$dobbies-icon-check: "\e902";
$dobbies-icon-cross: "\e903";
$dobbies-icon-cup: "\e904";
$dobbies-icon-member: "\e905";
$dobbies-icon-present: "\e906";
$dobbies-icon-savings: "\e907";
$dobbies-icon-vip: "\e908";
$dobbies-icon-club: "\e91d";
$dobbies-icon-shopping: "\f290";

@font-face {
    font-family: $msv-icon-font-family;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: $msv-outline-icon-weight;
    font-display: auto;
    src: url('#{$dobbies-font-path}/fa-regular-400.woff2') format('woff2'),
    url('#{$dobbies-font-path}/fa-regular-400.woff') format('woff');
}

@font-face {
    font-family: $msv-icon-font-family;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: $msv-solid-icon-weight;
    font-display: auto;
    src: url('#{$dobbies-font-path}/fa-solid-900.woff2') format('woff2'),
    url('#{$dobbies-font-path}/fa-solid-900.woff') format('woff');
}

@mixin msv-icon($icon-weight: $msv-solid-icon-weight) {
    font-family: $msv-icon-font-family;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: $icon-weight;
    text-rendering: auto;
    line-height: 1;
}

@mixin add-icon($icon, $selector: before, $icon-weight: $msv-solid-icon-weight ) {
    &:#{$selector} {
        @include msv-icon($icon-weight);
        content: $icon;
    }
}

@font-face {
    font-family: '$dobbies-icon-font-family';
    src:  url('#{$dobbies-icons-path}/icomoon.eot?ukpxx1');
    src:  url('#{$dobbies-icons-path}/icomoon.eot?ukpxx1#iefix') format('embedded-opentype'),
      url('#{$dobbies-icons-path}/icomoon.ttf?ukpxx1') format('truetype'),
      url('#{$dobbies-icons-path}/icomoon.woff?ukpxx1') format('woff'),
      url('#{$dobbies-icons-path}/icomoon.svg?ukpxx1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '$dobbies-icon-font-family' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

@mixin dobbies-icon($icon-weight: $dobbies-outline-icon-weight) {
    font-family: '$dobbies-icon-font-family';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: $icon-weight;
    text-rendering: auto;
    line-height: 1;
}
@mixin add-dobbies-icon($icon, $selector: before, $icon-weight: $dobbies-outline-icon-weight) {
    &:#{$selector} {
        @include dobbies-icon($icon-weight);
        content: $icon;
    }
}

@mixin add-icon($icon, $selector: before, $icon-weight: $msv-solid-icon-weight) {
    &:#{$selector} {
        @include msv-icon($icon-weight);
        content: $icon;
    }
}

@mixin add-spinner($selector: before) {
    @include add-icon($msv-Spinner, $selector);
    &:#{$selector} {
        margin-right: 8px;
        -webkit-animation: spin 1s steps(8) infinite;
        animation: spin 1s steps(8) infinite;
    }
    cursor: progress;
}

@mixin msv-rubik-bold($font-weight: 700, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-font-family-rubik-bold;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

@mixin msv-rubik-regular($font-weight: 400, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-font-family-rubik-regular;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

@mixin msv-rubik-medium($font-weight: 700, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-font-family-rubik-medium;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

@mixin msv-beth-ellen($font-weight: 400, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-font-family-beth-ellen;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

@mixin msv-nothing-you-could-do($font-weight: 400, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-font-family-nothing-you-could-do;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}
