$search-result-container-refiner-section-padding-left: 40px;
$search-result-container-refiner-submenu-item-label-padding-right: 12px;


[dir="rtl"] {
    .ms-search-result-container__refiner-section { 
        padding-left: $search-result-container-refiner-section-padding-left;
        padding-right: 0;
    }

    .ms-refine-submenu.list-group {
        .multi-select,
        .multi-select-checked,
        .single-select,
        .single-select-checked {
            span.ms-refine-submenu-item__label {
                padding-right: $search-result-container-refiner-submenu-item-label-padding-right;
                padding-left: 0;
            }
        }
    }
    .slider {
        .slider__labels-item.start {
            float : right;
        }
       .slider__labels-item.end {
            float: left;
        }
    }
}