.club-benefits {
  background: $white;
  color: var(--dobbies-club-background-color);
  padding: 20px 130px;
  padding-top: 40px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;

  @media(max-width: $dobbies-container-max-width-s) {
    padding: 20px 10px;
    max-width: 100%;
    padding-bottom: 0;
  }
  p {
    margin-bottom: 0px;
    color: $dobbies-club-grey;
    letter-spacing: 0.5px;
  }
  h3 {
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 42px;
    letter-spacing: 0.5px;
    @media(max-width: $dobbies-container-max-width-s) {
      margin-bottom: 22px;
    }
  }
  .benefits-table {
    font-weight: 500;
    .table-head {
      display: none;
      margin-bottom: 16px;
      .th-club-plus, .th-club {
        width: 50%;
        height: 60px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
      }

      .th-club-premium {
        background: $dobbies-club-premium-background;
        background: $dobbies-club-premium-background-gradient;
        color: black;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        font-size: 16px;
      }
      .th-club-plus {
        background: var(--dobbies-club-primary-color);
      }
      .th-club {
        background: var(--dobbies-club-benefit-green);
      }
    }
    .benefit-cell {
      border-bottom: 1px solid rgba(112, 112, 112, 0.5);
      padding-bottom: 16px;
      margin-bottom: 16px;
      color: black;

      @media(max-width: $dobbies-container-max-width-tablet) {
        padding-bottom: 12px;
        margin-bottom: 12px;
      }
    }
    .checks {
      display: flex;
      align-items: flex-start;
      .clubplus-cell, .clubpremium-cell , .club-cell {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .fa-check {
          @include add-icon($msv-Selected);
          font-size: 25px
        }
        .fa-times{
          @include add-icon($msv-Cancel);
          font-size: 25px;
        }
      }
      .clubplus-cell {
        width: 100%;
        color: $dobbies-gray-100;
        margin-top: 13px;
        .fa-check {
          margin-top: 9px;
        }
      }
      .club-cell {
        display: none;
      }
      
    .clubpremium-cell {
      .fa-check,
      .fa-times {
          color: var(--dobbies-club-benefit-black);
      }
    }

      .clubplus-cell {
        .fa-check, .fa-times {
          color: var(--dobbies-club-primary-color);
        }
      }
      .club-cell {
        .fa-check, .fa-times {
          color: var(--dobbies-club-benefit-green);
        }
      }
    }
  }
  .button-wrapper {
    display: grid;
    justify-content: center;
    gap: 20px;
    @media(max-width: $msv-breakpoint-m) {
      flex-wrap: wrap;
    }
    @media(max-width: $dobbies-container-max-width-s) {
      gap: 0px;
    }
    button {
      width: 245px;
      color: var(--dobbies-club-background-color);
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.5s;
      border: 0;
      font-weight: 500;
      @media(max-width: $dobbies-container-max-width-s) {
        width: 245px;
        margin-bottom: 20px;
        height: 42px;
      }
    }
    .continue-btn {
      @include club-plus-primary-button();
      @include button-verticle-padding-zero();
      cursor: pointer;
      border-radius: $dobbies-btn-border-radius;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      position: relative;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      overflow: hidden;
      border: 0;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-primary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover,
      &:focus,
      &:active {
        color: var(--dobbies-font-secondary-color);
        background: var(--dobbies-bg-color-secondary);
        &:before {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
    .cancel-btn {
      height: auto;
      width: auto;
      background: none;
      font-size: 16px;
      font-weight: $dobbies-font-weight-bold;
      @media(max-width: $dobbies-container-max-width-s) {
        margin-bottom: 0px;
      }

      &:hover {
        color: $dobbies-club-yellow;
      }
    }
  }
}

.ms-loyalty-plus {
  @media(max-width: $dobbies-container-max-width-s) {
    background-size: contain;
  }
  .cancel-membership-head {
    padding: 50px 300px;
    text-align: center;
    @media(max-width: $dobbies-container-max-width-xl) {
      padding: 39px 150px 20px;
    }
    @media(max-width: $dobbies-container-max-width-tablet) {
      padding: 38px 140px 20px;
    }

    @media(max-width: $dobbies-container-max-width-s) {
      padding: 40px 20px 20px 20px;
    }
  
    h2 {
      padding: 0;
      margin-bottom: 20px;
      @media(max-width: $dobbies-container-max-width-tablet) {
        margin-bottom: 12px;
        font-size: 30px;
        line-height: 34px;
      }
      @media(max-width: $dobbies-container-max-width-s) {
        margin-bottom: 8px;
        line-height: 32px;
      }
    }
  
    p {
      color: $white;
    }
  }  
}

.membership-expire {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  background: $white;
  color: var(--dobbies-club-background-color);
  padding: 20px;
  font-size: 14px;
  line-height: 21px;
  @media (max-width: $msv-breakpoint-m) {
    flex-wrap: wrap;
  }
  .membership-expire-details {
    @media (max-width: $msv-breakpoint-m) {
      width: 100%;
      text-align: center;
    }
  }
  p {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
      @media (max-width: $msv-breakpoint-m) {
        margin-bottom: 16px;
      }
    }
  }
  .membership-card {
    width: 250px;
    border-radius: 8px;
    overflow: hidden;
    @media (max-width: $msv-breakpoint-m) {
      width: 100%;
    }
    img {
      width: 100%;
      margin: 0 auto;
      max-width: 250px;
      height: auto;
      border-radius: 8px;
    }
  }
}

.ms-loyalty-free,
.ms-loyalty-plus {
  background: var(--dobbies-club-background-color) url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/ME52gx?pubver=0) center top no-repeat;
  position: relative;
  background-size: contain;

  @media(max-width: $dobbies-container-max-width-xl) {
    background-size: 100% 10%;
  }

  @media(max-width: $msv-breakpoint-mbl-sm) {
    background-size: 100% 5%;
  }

 

  .membership-detail-boxes_perbox {
    min-height: 201px;
    padding-top: 20px;
    .icon-holder {
      @media(max-width: $dobbies-container-max-width-s) {
        margin-bottom: 36px;
      }
      span.icon-cup {
        font-size: 140px;
        width: 53px;
        display: inline-block;
        height: 67px;
        &:after {
          top: -20px;
          left: -40px;

          @media(max-width: $dobbies-container-max-width-tablet) {
            top: -20px;
          }
          @media(max-width: $dobbies-container-max-width-s) {
            top: -37px;
          }
        }
      }

      span.icon-vouchers {
        font-size: 180px;
        position: relative;
        bottom: 36px;

        &::after {
          @media(max-width: $dobbies-container-max-width-s) {
            top: -37px;
          }
        }
      }
    } 

    .link-holder {  
      .cta__btn {
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }

    h4 {
      margin-bottom: 20px;
    }

    &.update-your-info {
      padding: 20px 0px;
      @media(max-width: $dobbies-container-max-width-s) {
        padding-top: 20px;
        padding-bottom: 46px;
      }
      
      .content-section {
        display: none;
      }

      .link-holder {
        position: initial;
        padding: 0 20px;
      }
    }

    &.user-points {
      > p:first-child {
        @media(max-width: $dobbies-container-max-width-s) {
          padding: 0;
          margin-bottom: 0;
        }
      }

      .points {
        p.ms-account-loyalty__total-points {
          @media(max-width: $dobbies-container-max-width-s) {
            font-size: 35px;
            padding-top: 18px;
            margin: 0;
          }
        }
      }

      .ms-account-loyalty__total-points {
        @media(max-width: $dobbies-container-max-width-s) {
          padding: 0;
          padding-top: 18px;
          margin: 0;
        }
      }
    }


    &.user-voucher,
    &.drinks-permonth {
      .icon-holder {
        margin-bottom: 46px;
      }
    }

    &.card-info {
      @media(max-width: $dobbies-container-max-width-s) {
        padding-top: 25px;
        padding-bottom: 32px;
      }
    }

    &.lost-your-card {
      padding: 0 10px;
      padding-top: 20px;
      padding-bottom: 15px;
    }
  }

  .ms-account-loyalty__heading {
    color: $dobbies-club-yellow;
    @media(max-width: $dobbies-container-max-width-tablet) {
      font-size: 30px;
    }
    @media(max-width: $dobbies-container-max-width-s) {
      font-size: 25px;
      padding: 40px 0;
    }
  }

  .lost-your-card {
    .link-holder {
      margin-top: 0;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 11px;

      @media (min-width: $msv-breakpoint-m) and (max-width: $dobbies-container-max-width-xl) {
        letter-spacing: -0.7px;
      }
    }

    h4 {
      margin-bottom: 20px;
    }
  }

  .membership {
    &-expire {
      background: var(--dobbies-club-background-color) url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/ME52gx?pubver=0) center top no-repeat;
      background-size: 100% auto;
      color: $white;
      display: block;
      text-align: center;
      padding: 55px 310px 32px;
      margin-top: 0;
      @media(max-width: $dobbies-container-max-width-xl) {
        padding: 38px 150px 30px;
        margin-bottom: 130px;
      }
      @media(max-width: $dobbies-container-max-width-s) {
        padding: 40px 30px 20px;
      }
      &-details {
        h2 {
          margin-bottom: 0;
          font-size: 35px;
          color: $dobbies-club-yellow;
          margin-bottom: 20px;
          @media(max-width: $dobbies-container-max-width-tablet) {
            font-size: 30px;
          }
          @media(max-width: $dobbies-container-max-width-s) {
            font-size: 25px;
            margin-bottom: 10px;
          }
        }
        p {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    &-home {
      position: absolute;
      bottom: -70px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      @media(max-width: $dobbies-container-max-width-s) {
        bottom: -50px;
      }

      .msc-cta__primary {
        @include club-plus-primary-button();
        @include button-verticle-padding-zero();
        min-width: 220px;
        height: 30px;
        cursor: pointer;
        border-radius: $dobbies-btn-border-radius;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        border: 0;
        display: inline-block;
        line-height: 28px;
        &:before {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: var(--dobbies-bg-color-primary);
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
          color: var(--dobbies-font-secondary-color);
          background: var(--dobbies-bg-color-secondary);
          &:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
        }
      }
    }

    &-card {
      display: none;
    }
  }

  .app-buttons {
    margin: 0 -10px;
    a {
      width: 100px;
      height: 30px; 
      font-size: 0;
      border-radius: 5px;
      margin: 0 5px;
      padding: 0;
      display: inline-block;
      @media(max-width: $dobbies-container-max-width-xl) {
        margin: 0 4px;
      }
    }
    .appstore {
      background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/ME8gug?pubver=0) center center no-repeat;
      background-size: 100% 32px;
    }
    .googleplay {
      background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/ME8gud?pubver=0) center center no-repeat;
      background-size: 114px 46px;
    }
  }
}

.fa-chevron-right{
  @include add-icon($msv-ChevronRight);
  font-size: 16px;
  margin-left: 5px;
  color: var(--dobbies-club-primary-color);
}

.dobbies-club-modal-text{
  text-align: center;
  font-size: 16px;
}

.dobbies-club-modal-btns {
  display: flex;
  justify-content: space-between;
  padding: 25px 50px 50px;
  max-width: 650px;
  margin: 0 auto;
  @media(max-width: $msv-breakpoint-l) {
    flex-wrap: wrap;
    padding: 25px 30px 50px;
  }
  button {
    width: 256px;
    color: var(--dobbies-club-background-color);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.5s;
    border: 0;
    font-weight: 500;
    @media(max-width: $msv-breakpoint-l) {
      width: 100%;
      margin-bottom: 16px;
      height: 50px;
    }
  }
  .join-club-btn {
    background: var(--dobbies-club-primary-color);
    &:hover {
      background: var(--dobbies-club-benefit-green);
    }
  }
  .join-trail-btn {
    background: var(--dobbies-club-benefit-green);
    &:hover {
      background: var(--dobbies-club-primary-color);
    }
  }
}
