$msv-store-locator-phone-section-padding-top: 5px;
$msv-store-locator-location-line-shop-address-padding-top: 2px;
$msv-store-locator-location-line-shop-address-padding-right: 15px;
$msv-store-locator-input-height: 48px;
$msv-store-locator-button-border-radius: 0px;
$msv-store-locator-input-border-radius: 2px 0 0 2px;
$msv-store-locator-input-padding-left: 12px;
$msv-store-locator-button-height: 52px;
$msv-store-locator-button-width: 52px;
$msv-store-locator-search-form-margin-bottom: 30px;
$msv-store-locator-search-form-margin: 15px 0 5px 0;
$msv-store-locator-location-line-item-margin-top: 10px;
$msv-breakpoint-modal-m: 450px;
$msv-store-locator-msc-modal-content-padding: 40px;
$msv-store-locator-height-m: 650px;

$homepage-images-path: '../../../';

//style presets
:root {
--msv-store-locate-heading-font-color: var(--dobbies-font-primary-color);
--msv-store-locate-heading-font-size: var(--msv-font-size-xl);

--msv-store-locate-form-bg: #{$dobbies-white};

//body title
--msv-store-locate-title-font-size: var(--msv-body-font-size-l);
--msv-store-locate-title-font-color: var(--dobbies-accent-brand-color);

//body text
--msv-store-locate-text-font-size: var(--msv-body-font-size-m);
--msv-store-locate-text-font-color: var(--dobbies-font-primary-color);

//Link
--msv-store-locate-link-size: var(--msv-body-font-size-s);
--msv-store-locate-link-color: var(--dobbies-font-primary-color);

--msv-store-locate-divider-color: #{$dobbies-gray-300};

// primary button
--msv-store-locate-primary-btn-bg: var(--dobbies-accent-brand-color);
--msv-store-locate-primary-btn-font-color: var(--dobbies-font-secondary-color);
--msv-store-locate-primary-btn-border: var(--dobbies-accent-brand-color);
}

.ms-store-select__search-see-all-stores-row {
    flex : 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ms-store-locate {
    height: $msv-store-locator-height-m;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--msv-store-locate-divider-color);

    .ms-store-select {
        &__header{
            margin: 15px 0 10px 0;
            span{
                font-size: var(--msv-body-font-size-l);
                font-weight: 600;
            }
        }

        &__body {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            min-height: 0;
        }

        &__found-locations{
            margin-bottom: 0;
        }

        &__search {
            padding: 0 30px 30px 0;
            display: block;


            &-see-all-stores-row {
                flex : 1; 
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            &-see-all-stores-button {
                    float: right;
                    margin-left: $cookie-compliance_accept-btn-margin-left;
                    width: $cookie-compliance_accept-btn-width;
                    @include primary-button(var(--dobbies-bg-color-secondary), var(--msv-cookie-compliance-primary-btn-font-color));
                    padding: 0;
                    border: 0;
                    color: var(--dobbies-font-primary-color);
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    border: 0;
                    &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--dobbies-bg-color-primary);
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 0 50%;
                    transform-origin: 0 50%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                    color: var(--dobbies-font-secondary-color);
                    background: var(--dobbies-bg-color-secondary);
                    &:after {
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                }
            }


            &-see-all-stores {
                cursor: pointer;
                width: fit-content;
                color: $dobbies-link-color;
                text-decoration: underline;
                margin: 5px 0;
                font-weight: var(--dobbies-font-weight-normal);
                font-size: 16px;
                color: $dobbies-footer-font-color;
                @include add-dobbies-icon($dobbies-icon-store, before);
                &::before {
                    font-size: 34px;
                    vertical-align: middle;
                    color: $dobbies-footer-font-color;
                    margin-right: 5px;
                    margin-left: -5px;
                }

                
            }
        }
            

            &-form {
                display: flex;
                // margin: $msv-store-locator-search-form-margin;
                position: relative;

                .MicrosoftMap {
                    .as_container_search {
                      left: 0;
                      top: 51px;
                      width: 100%;
                    }
                  }
            }
        
            &-input {
                @include vfi();
                flex: auto;
                border: 1px solid transparent;
                border-right: 0;
                border-radius: $msv-store-locator-input-border-radius;
                padding-left: $msv-store-locator-input-padding-left;
                height: $msv-store-locator-input-height;
                background: var(--msv-store-locate-form-bg);
                font-size: var(--msv-body-font-size-l);
                }
        
            &-button {
                @include vfi();
                align-items: center;
                background-color: var(--msv-store-locate-primary-btn-bg);
                border-radius: $msv-store-locator-button-border-radius;
                color: var(--msv-store-locate-primary-btn-font-color);
                height: $msv-store-locator-button-height;
                order: 2;
                width: $msv-store-locator-button-width;
                display: flex;
                border: 1px solid var(--msv-store-locate-primary-btn-border);
                &:before {
                    display: none;
                    // @include dobbies-icon();
                    // content: $dobbies-icon-search;
                    // width: $msv-store-locator-button-width;
                    // text-align: center;
                    // font-size: var(--dobbies-body-font-size-xl);
                    // font-weight: var(--dobbies-font-weight-heavy);
                }
            }
        
            .search-btn-disabled {
                background-color: $dobbies-gray-300;
            }
        }
        
        &__store-hours-details {
            @include font-content(var(--msv-font-weight-light), var(--msv-store-locate-text-font-size), $dobbies-line-height-m);
            display: flex;
            flex-wrap: wrap;
        
            .ms-store-locate__store-hours-day {
                flex: 0 0 40%;
            }
        
            .ms-store-locate__store-hours-time {
                flex: 0 0 60%;
            }
        }

        &__toggle-view {
            display: none;
            cursor: pointer;
            color: $dobbies-link-color;

            &:hover {
                text-decoration: underline;
            }
        }

        &__locations {
            border: none;
            overflow-y: auto;
            border-top: 1px solid var(--msv-store-locate-divider-color);
            position: relative;
            margin-top: 10px;
        }
                    
        &__location {
            padding: 20px 10px;
            border-top: 1px solid var(--msv-store-locate-divider-color);
            border-bottom: 0;

            &.selected {
                background-color: $dobbies-footer-grey;
            }

            &-line-item {
                color: $dobbies-footer-font-color;
                
                &-content {
                    display: flex;
                    flex-wrap: wrap;
                }

                &-contact-info, 
                &-store-hours {
                    flex: 0 0 50%;
                    margin: 0 0 15px 0;
                }

                &-set-as-preferred-store {
                    display: block;
                    color: var(--msv-store-locate-title-font-color);
                    border: none;
                    padding: 0;
                    outline: none;
                    background-color: transparent;
                    width: fit-content;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &-preferred-store {
                    font-weight: var(--msv-font-weight-bold);
                    display: block;
                    @include add-icon($msv-Checkbox-Circle-Checked, before);
                    cursor: pointer;
                    border: none;
                    padding: 0;
                    outline: none;
                    background-color: transparent;
                    &:hover {
                        text-decoration: underline;
                    }

                    &:before {
                        margin-right: 10px;
                    }
                }
            }
        

            &-line-item-header {
                flex: 1;
                justify-content: space-between;
                margin-bottom: 10px;
            }

            &-line-header,
            &-line-stock-status {
                margin-bottom: 10px;
            }
        
            &-line-item-store-name, &-line-item-store-index{
                color: var(--msv-store-locate-title-font-color);
                @include font-content(var(--msv-font-weight-bold),var(--msv-store-locate-title-font-size), $dobbies-line-height-l);
            }

            &-line-item-store-index{
                margin-right: 5px;
                display: none;
                &:after {
                    content:'.'
                }
            }
        
                    
            &-line-item-store-distance {
                font-size: var(--dobbies-body-font-size-s);
                font-weight: var(--dobbies-font-weight-normal);
                color: var(--msv-store-select-distance-color);
                margin-right: 12px; 
            }
                
            &-line-header {
                @include font-content(var(--msv-font-weight-bold), var(--msv-store-locate-text-font-size), $dobbies-line-height-m);
                margin-bottom: 3px;
                margin-top: 5px;
            }
        
            &-line-store-distance {
                color: $dobbies-gray-500;
            }
        
            &-line-shop-address {
            &-section {
                display: flex;
                padding-left: 0;
                @include add-dobbies-icon($dobbies-icon-store, before);
                &:before {
                    font-size: var(--dobbies-icon-font-size-m);
                    margin: 2px 2px 0 -15px;
                    display: none;
                }
            }
            &-glyph-icon {
                padding-top: $msv-store-locator-location-line-shop-address-padding-top;
                padding-right: $msv-store-locator-location-line-shop-address-padding-right;
                @include add-icon($msv-Shop-Address);
            }
            &-text {
                @include font-content(var(--msv-font-weight-normal), var(--msv-store-locate-text-font-size), $dobbies-line-height-m);
                white-space: pre-wrap;
            }
            }
        
            &-line-shop-phone {
            &-section {
                display: flex;
                padding-top: $msv-store-locator-phone-section-padding-top;
            }
            &-icon {
                padding-top: $msv-store-locator-location-line-shop-address-padding-top;
                padding-right: $msv-store-locator-location-line-shop-address-padding-right;
                @include add-icon($msv-Phone);
            }
            }
        
            &-line-locate-store {
                @include primary-button(var(--msv-store-locate-primary-btn-bg), var(--msv-store-locate-primary-btn-font-color), var(--msv-store-locate-primary-btn-border));
                @include vfi();
                @include font-content(var(--msv-font-weight-normal),var(--msv-store-locate-title-font-size), $dobbies-line-height-l);
            }
            &-line-shop-link {
                margin: 10px 0 0 0px;
                font-weight: var(--dobbies-font-weight-normal);
                color: $dobbies-footer-font-color;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }

        &__footer {
            position: absolute;
            bottom: -15px;
            width: 100%;
        }
        
        &__terms-link {
            @include font-content(var(--msv-font-weight-normal),var(--msv-store-locate-link-size), $dobbies-line-height-s);
            @include vfi();
            color: var(--msv-store-locate-link-color);
            cursor: pointer;
        }
    }

    abbr {
        text-decoration: none;
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-locate {
        height: auto;
        border: none;

        .ms-store-select {
            &__search {
                padding: 0 0 15px 0;
            }
    
            &__locations {
                 display: none;
                 border-bottom: 1px solid var(--msv-store-locate-divider-color);
                 
                 &.show {
                     display: block;
                 }
             }

             &__toggle-view {
                display: none;
                background-color: transparent;
                float: right;
                border: none;
                outline: none;
             }

             &__footer {
                &.map-only-view {
                    bottom: -660px;
                }
             }
        }
    }
 }

.ms-map {
    height: $msv-store-locator-height-m;
    @media screen and (max-width: $dobbies-container-max-width-s) {
        height: calc(100vh - 390px);
    }

    &__body {
        height: 100%;
    }
}

// @media screen and (max-width: $msv-breakpoint-m) {
//     .ms-map {
//         display: none;

//         &.show {
//             display: block;
//         }
//     }
//  }
 .map-locator {
     padding: 55px 0 25px;
     .ms-store-locate {
        .ms-store-select {
            &__header {
                margin: 0 0 18px 0;
                text-transform: uppercase;
                > h2 {
                    // text-align: center;
                    font-size: 24px;
                    font-weight: var(--dobbies-font-weight-bold);
                    color: $dobbies-footer-font-color;
                    line-height: 1;
                }
             }
             &__search {
                padding: 0;
             }
             &__search-form {
                 margin-bottom: 18px;
                 .MicrosoftMap .as_container_search {
                     top: 42px;
                 }
             }
             &__search-input {
                 height: 40px;
                 padding: 0;
                 padding-left: 10px;
                 border-radius: 4px;
                 border: 1px solid $dobbies-gray-100;
                 margin-right: 7px;
                 &::-webkit-search-cancel-button {
                    @include msv-icon();
                    content: $msv-Cancel;
                    font-size: var(--dobbies-body-font-size-xl);
                    font-weight: var(--dobbies-font-weight-heavy);                
                 }
                 &::placeholder {
                     color: $dobbies-footer-font-color;
                 }
             }
             &__search-button {
                background: transparent;
                color: $dobbies-footer-font-color;
                border: 0;
                padding: 0;
                height: 42px;
                width: 80px;
                cursor: pointer;
                background: $dobbies-store-find-color;
                font-weight: var(--dobbies-font-weight-bold);
                border-radius: 4px;
                justify-content: center;
                
                &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 4px;
                    background: $dobbies-grey;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 0 50%;
                    transform-origin: 0 50%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                  }

                &:hover {
                    color: $dobbies-white;
                    background: $dobbies-black;
                    transform: scaleX(1);
                        &:after {
                            -webkit-transform: scaleX(1);
                            transform: scaleX(1);
                            
                        }
                }
             }
             &__locations {
                 border-top: 0;
             }
             &__location {
                 padding: 15px 0 0;
                 transition: all 0.3s;
                 &-line-item-store-name {
                    font-size: var(--dobbies-body-font-size-ml);
                    font-weight: var(--dobbies-font-weight-bold);;
                    color: $dobbies-footer-font-color;
                    text-transform: uppercase;
                 }
                 &.selected {
                     background-color: #F5F5F5;
                     padding: 15px 0 0;
                 }
             }
         }
     }
     .ms-map {
         padding-left: 50px;
         margin-left: auto;
         @media screen and (max-width: $dobbies-container-max-width-s) {
             padding-left: 0;
         }
         &__header {
             display: none;
         }
     }
     @media screen and (max-width: $dobbies-container-max-width-s) {
         padding-top: 15px;
        .col-12:first-child {
            order: 2;
            margin-top: 15px;
        }
     }
 }
 .store-locator-footer {
     padding-bottom: 30px;
     h2 {
         width: 100%;
         margin: 30px 0;
         font-size: var(--dobbies-tile-heading-s);
         font-weight: var(--dobbies-font-weight-bold);
         @media screen and (max-width: $dobbies-container-max-width-s) {
             font-size: var(--dobbies-heading-font-size-25);
         }
     }
     > .row {
         > .col-12 {
             flex: 0 0 16.5%;
             max-width: 16.5%;
             &:nth-child(2) {
                flex: 0 0 67%;
                max-width: 67%;
                .ms-footer__list {
                    > li {
                        display: inline-block;
                        width: 25%;
                        padding: 2px 0;
                        @media screen and (max-width: $dobbies-container-max-width-s) {
                            width: 33.33%;
                        }
                    }
                }
             }
             .ms-footer__list {
                > li:first-child {
                   display: block;
                   margin-bottom: 10px;
                   h4 {
                       font-size: var(--dobbies-body-font-size-ml);
                       font-weight: var(--dobbies-font-weight-bold);
                       @media screen and (max-width: $dobbies-container-max-width-s) {
                           @include add-dobbies-icon($dobbies-icon-arrow ,after);
                           &:after {
                               color: var(--dobbies-breadcrumb-icon-color);
                               display: inline-block;
                               transform: rotate(90deg);
                               font-size: var(--dobbies-body-font-size-s);
                               font-weight: var(--dobbies-font-weight-heavy);
                               position: relative;
                               top: -1px;
                               margin-left: 3px;
                           }
                       }
                   }
                }
                > li.Show {
                    h4 {
                        @media screen and (max-width: $dobbies-container-max-width-s) {
                            &:after {
                                transform: rotate(180deg);
                                margin-left: 6px;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                flex: 0 0 100%;
                max-width: 100%;
                &:nth-child(2) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    .ms-footer__list {
                        > li {
                            display: block;
                            width: 100%;
                            padding: 2px 0;
                        }
                    }
                 }
                 .collapse {
                     margin-bottom: 15px;
                     height: fit-content !important;
                     > li {
                         padding: 3px 0;
                     }
                 }
            }
            > .container {
                padding-left: 0;
                padding-right: 0;
                > .row {
                    > .col-12 {
                        > ul {
                            margin-bottom: 30px;
                            @media screen and (max-width: $dobbies-container-max-width-s) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
         }
     }
 }
 .store-locator-button {
    .ms-text-block {
        .ms-homepage-store-locator {
            text-align: center;
            padding: 0 12px;
            a {
                background: var(--dobbies-bg-color-primary);
                color: var(--dobbies-font-secondary-color);
                font-size: var(--dobbies-body-font-size-l);
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                padding: 15px 40px 15px 65px;
                margin-bottom: 40px;
                text-decoration: none;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                position: relative;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                overflow: hidden;
                border: 0;
                text-transform: uppercase;
                width: 100%;
                max-width: 400px;
                @include add-dobbies-icon($dobbies-icon-store, before);
                &:before {
                    font-size: var(--dobbies-icon-font-size-m);
                    float: left;
                    position: relative;
                    left: 50%;
                    margin: -3px 0 0 -140px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--dobbies-bg-color-secondary);
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 0 50%;
                    transform-origin: 0 50%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                  }
                  &:hover,
                  &:focus,
                  &:active {
                    color: var(--dobbies-font-secondary-color);
                    background: var(--dobbies-font-primary-color);
                    &:after {
                      -webkit-transform: scaleX(1);
                      transform: scaleX(1);
                    }
                }
            }
        }
    }
 }

 .store-locator {
    margin: 20px 0;
    h2 {
        font-size: 25px;
    }
    @media screen and (min-width: $dobbies-container-max-width-tablet) {
        margin-top: 45px;
    }
    &__head {
        text-align: center;
        padding: 10px;
        padding-bottom: 9px;
        background-color: $dobbies-header-grey;
        margin: 20px auto;
        p {
            font-size: $dobbies-font-size-s;
            color: $dobbies-black;
            margin-bottom: 18px;
        }

        &-alphabet {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 13px;
            margin-bottom: 10px;

            @media screen and (min-width: 400px) {
                gap: 15px;
            }

            li {
                &.disabled {
                    cursor: none;
                    pointer-events: none;
                    a {
                        color: $dobbies-light-grey;
                        &:hover {
                            color: $dobbies-light-grey;
                            text-decoration: none;
                        }
                    }
                }
                a {
                    text-transform: uppercase;
                    color: $dobbies-black;
                    font-size: $dobbies-font-size-m;
                    font-weight: $dobbies-font-weight-bold;
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        font-size: $dobbies-font-size-l;
                    }
                    &:hover {
                        color: $dobbies-black;
                        text-decoration: underline;
                    }
                }
                &.store-selected {
                    a {
                        color: $dobbies-primary;
                        text-decoration: underline;
                    }
                }
            }
        }

        a {
            margin-top: 10px;
        }
    }

    &__block {
        display: flex;
        gap: 0 12px;

        @media screen and (min-width: $dobbies-container-max-width-xl) {
            gap: 0 14px;
        }
        &-header {
            font-size: $dobbies-font-size-l;
            color: $dobbies-black;
            font-weight: $dobbies-font-weight-bold;
            background-color: $dobbies-header-grey;
            padding: 14px 20px;
            border-bottom: 1px solid $dobbies-light-grey;
            border-top: 1px solid $dobbies-light-grey;
            line-height: 23px;
            cursor: pointer;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;

            &::after {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                font-size: $dobbies-font-size-m;
                margin-left: 1px;
                right: 17px;
                position: absolute;
            }

            &.active {
                &::after {
                    transform: rotate(180deg);
                    top: 18px;
                }
            }
        }

        > ul {
            margin-bottom: 0;
            max-width: 50%;
            width: 50%;
            padding: 0;
            list-style: none;
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                max-width: 25%;
                width: 25%;
            }
            li {
                padding: 15px 0;
                border-top: 1px solid $dobbies-light-grey;
                font-size: $dobbies-font-size-s;
                font-weight: $dobbies-font-weight-bold;
                color: $dobbies-black;
                display: block;
                &:first-child {
                    border-top: 0;
                }
            }
        }
    }
 }
