.dobbies-store-location-popup {
    .msc-modal{
        &__header {
            border: none;
            padding: 0;
            padding-left: 30px;
            margin-bottom: 41px;
            @media (max-width: $dobbies-container-max-width-s) {
                padding-left: 0;
                text-align: center;
            }

            @media (min-width: $dobbies-container-max-width-s) {
                margin-bottom: 15px;
            }

            @media (min-width: $dobbies-container-max-width-tablet) {
                margin-bottom: 32px;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                margin-bottom: 50px;
            }
        }

        &__title {
            font-size: 24px;
            line-height: 32px;

        }
        &__close-button {
            top: 20px;
            right: 20px;
            padding: 0;
            height: 20px;
            display: flex;
            @media (max-width: $dobbies-container-max-width-s) {
                top: 11px;
                right: 15px;
            }
            &:before {
                font-size: $dobbies-font-size-ml;
                color: $dobbies-black;
                @media (max-width: $dobbies-container-max-width-s) {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
        &__dialog {
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
            max-width: 384px;

            @media (min-width: $dobbies-container-max-width-s) {
                max-width: 740px;
            }
            @media (min-width: $dobbies-container-max-width-tablet) {
                max-width: 984px;
            }

            @media (min-width: $dobbies-container-max-width-xl) {
                max-width: 1145px;
            }

            @media (max-width: 400px) {
                max-width: 350px
            }
  
            .loyalty-signup-section__input-holder {
                display: flex;
                justify-content: center;
                @media (max-width: $dobbies-container-max-width-s) {
                    flex-wrap: wrap;
                }
                > div:first-child {
                    display: flex;
                    @media (max-width: $dobbies-container-max-width-s) {
                        margin-bottom: 40px;
                    }
                }
            }
            
            .store-location-apply-cta {
                @include button-transparent;
                transform: matrix(1, 0, 0, 1, 0, 0);
                font-family: $dobbies-primary-font-family;
                line-height: 28px;
                font-weight: 500;
                margin: 0;
                border-color: $dobbies-gray-100;
                min-width: auto;
                padding: 10px 30px;
                min-width: 120px;
                height: 46px;

                @media (max-width: $dobbies-container-max-width-s) {
                    padding: 10px 20px;
                }
            }

            .select-holder {
                position: relative;
                .fa-arrow-down {
                    height: 20px;
                    width: 20px;
                    color: $dobbies-gray-100;
                    font-size: var(--msv-body-font-size-xl);
                    line-height: $footer-back-to-top-line-height;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    right: 26px;
                    top: 15px;
                    pointer-events: none;
                    background: $dobbies-white;
                    text-align: center;
                    transform: rotate(180deg);
                    @media (max-width: $dobbies-container-max-width-s) {
                        right: 20px;
                    }
                    &:before {
                        @include dobbies-icon();
                        content: $dobbies-icon-arrow;
                        font-size: var(--dobbies-icon-font-size-m);
                        @media (max-width: $dobbies-container-max-width-s) {
                            font-size: 16px;
                        }
                    }
                }
                select {
                    min-height: 46px;
                    width: 450px;
                    margin-right: 10px;
                    padding: 0 20px;
                    outline: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    border: 1px solid $dobbies-gray-100;
                    color: $dobbies-black;
                    background: $dobbies-white;
                    @media (max-width: $dobbies-container-max-width-s) {
                        padding: 0 10px;
                        width: 300px;
                        margin-right: 0;
                    }
                    @media (max-width: 400px) {
                        width: 275px;
                    }
                }
            }

            .ms-header__icon-store {
                margin: 0;
                margin-right: 10px;
                &::before {
                    font-size: 46px;
                    color: $dobbies-gray-100;
                }
            }
        }

        &__content {
            padding: 38px 18px;

            @media (min-width: $dobbies-container-max-width-s) {
                padding: 13px 0 60px;
            }

            @media (min-width: $dobbies-container-max-width-tablet) {
                padding-top: 24px;
                padding-bottom: 86px;
            }

            @media (min-width: $dobbies-container-max-width-xl) {
                padding-top: 24px;
                padding-bottom: 104px;
            }
        }
    }
    &.msc-modal.show {
        .msc-modal__dialog {
            transform: translateY(-50%);
        }
    }
}

.notificationModal {

    @media (max-width: 390px), (max-height: 800px) {
        padding-top: 35px;
    }

    @media (max-width: 1600px) and (min-width: 1350px) {
        padding-top: 85px;
    }
    
    .msc-modal {
        &__dialog {
            margin:  0 auto;
            top: 50%;
            transform: translateY(-50%);
            padding: 20px;
            padding-bottom: 0;
            background: $dobbies-header-grey;
            @media (min-width: $dobbies-container-max-width-s) {
                max-width: 740px;
            }

            @media (min-width: $dobbies-container-max-width-tablet) {
                max-width: 983px;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                max-width: 1145px;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                padding: 15px;
                padding-bottom: 0;
                max-width: 384px;
                right: 10px;
            }
        }

        &__close-button {
            top: 20px;
            right: 20px;
            padding: 0;
            z-index: 999;
            @media (max-width: $dobbies-container-max-width-s) {
                top: 7px;
                right: 7px;
                line-height: 0;
            }
            &:before {
                font-size: 20px;
                color: $dobbies-white;
                @media (max-width: $dobbies-container-max-width-s) {
                    font-size: 14px;
                }
            }
        }

        &__content {
            background: none;
        }
    }

    &.msc-modal.fade {
        .msc-modal__dialog {
            transform: translateY(-50%);
        }
    }

    &_banner {
        position: relative;
        h2 {
            position: absolute;
            left: 23px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: $dobbies-white;
            max-width: 80%;
            @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                max-width: 530px;
                font-size: 22px;
            }
            @media (min-width: $dobbies-container-max-width-tablet) {
                max-width: 680px;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                max-width: 780px;
            }
            span {
                @media (min-width: $dobbies-container-max-width-s) {
                    display: block;
                }
     
            }
            @media (min-width: $dobbies-container-max-width-s) {
                max-width: 376px;
                font-size: 24px;
                line-height: 32px;
                left: 28px;
            }

            @media (min-width: $dobbies-container-max-width-tablet) {
                max-width: 594px;
                font-size: 28px;
                line-height: 37px;
                left: 45px;
            }

            @media (min-width: $dobbies-container-max-width-xl) {
                max-width: 680px;
                font-size: 32px;
                line-height: 43px;
                left: 75px;
            }
        }
        img {
            max-height: 216px;
            width: 100%;
            object-fit: cover;

            @media (max-width: $dobbies-container-max-width-s) {
                min-height: 100px;
            }
            @media (min-width: $dobbies-container-max-width-s) {
                max-height: 180px;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                max-height: 216px;
            }
        }
    }

    &_middletext {
        padding: 20px 0;
        @media (max-width: $dobbies-container-max-width-s) {
            padding: 10px 0;
        }
        h3 {
            font-size: 18px;
            text-align: center;
            @media (max-width: $dobbies-container-max-width-s) {
                font-size: 14px;
            }
        }
    }

    &_content {
        display: flex;
        @media (max-width: $dobbies-container-max-width-s) {
            display: block;
        }
    }

    &_image {
        width: 62%;
        @media (max-width: $dobbies-container-max-width-s) {
            width: 100%;
            margin-bottom: 29px;
        }
        img {
            object-fit: cover;
            max-height: 200px;
            width: 100%;
            @media (min-width: $dobbies-container-max-width-s) {
                max-height: 414px;
                min-height: 284px;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                min-height: 405px;
            }
        }
    }

    &_detail {
        width: 50%;
        padding: 0 30px;
        display: flex;
        align-items: center;
        padding-right: 0px;

        @media (min-width: $dobbies-container-max-width-xl) {
            padding-right: 100px;
        }

        @media (min-width: 1920px) {
            padding-right: 150px;
        }

        @media (max-width: $dobbies-container-max-width-s) {
            width: 100%;
            padding: 0;
            text-align: center;
            margin-bottom: 33px;
            display: inline-block;
        }

        h3 {
            text-transform: capitalize;
            font-weight: bold;
            font-size: $dobbies-font-size-l;
            line-height: 23px;
            @media (min-width: $dobbies-container-max-width-s) {
                font-size: 24px;
                line-height: 26px;
            }
        }

        h5 {
            font-size: $dobbies-font-size-s;
            line-height: 18px;
            margin-top: 15px;
            @media (min-width: $dobbies-container-max-width-s){
                margin-top: 28px;
            }
            @media (min-width: $dobbies-container-max-width-tablet){
                font-size: $dobbies-font-size-l;
                margin-top: 36px;
            }
        }

        p {
            font-size: $dobbies-font-size-s;
            margin-top: 15px;
            padding: 0 27px;
            @media (min-width: $dobbies-container-max-width-s){
                margin-top: 36px;
                padding: 0;
                max-width: 283px;
            }
            @media (min-width: $dobbies-container-max-width-tablet){
                margin-top: 46px;
                max-width: 360px;
            }
        }
    }

    &_cta {
        @include button-transparent;
        font-size: $dobbies-font-size-m;
        font-weight: 500;
        line-height: 28px;
        text-transform: uppercase;
        margin: 0;
        border-color: $dobbies-gray-100;
        padding: 0px 20px;
        margin-top: 15px;
        height: 30px;
        min-height: auto;
        text-transform: capitalize;
        justify-content: center;

        @media (min-width: $dobbies-container-max-width-s) {
            font-size: $dobbies-font-size-xs;
            margin-top: 30px;
            line-height: 30px;
            min-width: 170px;
        }
        @media (min-width: $dobbies-container-max-width-tablet) {
            font-size: $dobbies-font-size-m;
            margin-top: 58px;
            min-width: 215px;
        }
    }

    &_footer {
        margin: 0 -20px;
        margin-top: 16px;
        padding: 10px 0px;
        text-align: center;
        border-top: 1px solid $dobbies-gray-100;
        font-size: $dobbies-font-size-s;
        @media (min-width: $dobbies-container-max-width-tablet){
            margin-top: 20px;
        }
        @media (min-width: $dobbies-container-max-width-xl) {
            padding: 20px 0px 25px 0;
        }      
        @media (max-width: $dobbies-container-max-width-s) {
            margin: 0 -15px;
            margin-top: 15px;
            padding: 8px 0px 10px 0;
        }
        a {
            color: $dobbies-green;
            text-decoration: underline;
            font-weight: bold;
        }
    }

    &-product-bundle {
        background: transparent;
        margin-bottom: 0px;
        .product-bundle {
            padding: 0;
        }

        .product-bundle-container {
            .price-summary-wrapper {
                right: 0;
                width: auto;
                @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                    margin: 0;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    margin: 0;
                    padding-left: 5px;
                    padding-right: 4px;
                    display: flex;
                }
                @media (min-width: $dobbies-container-max-width-s) {
                    position: absolute;
                }                
            }

            .price-summary {
                @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                    width: 247px;
                    padding: 15px;
                    margin-top: -25px;
                }

                @media (max-width: $dobbies-container-max-width-s) {
                    padding: 7px 13px;
                    margin: 15px auto;
                    max-width: 302px;
                    margin-bottom: 0;
                }

                &__product-list {
                    display: block;
                    max-height: 220px;
                    overflow: auto;
                    @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                        max-height: 105px;
                    }
                    @media (max-width: $dobbies-container-max-width-s) {
                        display: none;
                    }
                }

                &__content {
                    .product-name {
                        font-size: 14px;
                        @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                            width: 140px;
                        }
                    }
                }

                &__total-price {
                    @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                        padding-top: 10px;
                        border-top: 1px solid $dobbies-gray-100;
                    }

                    span {
                        margin-left: 5px;
                    }
                }

                .msc-add-to-cart{
                    transform: matrix(1, 0, 0, 1, 0, 0);
                    @media (max-width: $dobbies-container-max-width-s) {
                        margin-bottom: 8px;
                        height: 25px;
                        font-size: $dobbies-font-size-s;
                    }
                    @media (min-width: $dobbies-container-max-width-s) {
                        margin-bottom: 8px;
                        height: 22px;
                        font-size: $dobbies-font-size-s;
                        line-height: 18px;
                    }  
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        margin-bottom: 10px;
                        height: 30px;
                    }  
                    +p {
                        font-size: $dobbies-font-size-xs;
                        @media (min-width: $dobbies-container-max-width-s) {
                            font-size: 9px;
                            text-align: left;
                        }
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            font-size: $dobbies-font-size-s;
                            text-align: center;
                        }
                    }
                }

                &::before {
                    width: 100%;
                    top: -10px;
                    left: 0;
                    @media (min-width: $dobbies-container-max-width-s) {
                        display: none;
                    }
                }

                button#up-arrow-btn.msc-btn, 
                button#down-arrow-btn.msc-btn {
                    @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                        display: inline-block;
                    }
                }

                button#down-arrow-btn.msc-btn {
                    @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                        bottom: 150px;
                    }
                }
                h3 {
                    @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                        display: block;
                    }
                }
            }

            .ms-product-collection.product-bundles {
                .msc-ss-carousel {
                    @media (max-width: $dobbies-container-max-width-s) {
                        left: -5px;
                    }
                    .msc-ss-carousel__flipper {
                        @media (max-width: $dobbies-container-max-width-s) {
                            left: -12px;
                            top: 165px;
                        }
                        @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                            top: 155px;
                            left: -10px;
                        }
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            left: -5px;
                        }
                        @media (min-width: $dobbies-container-max-width-xl) {
                            left: -20px;
                        }
                    }
    
                    .msc-ss-carousel__flipper--next {
                        @media (max-width: $dobbies-container-max-width-s) {
                            right: -27px;
                        }
                        @media (min-width: $dobbies-container-max-width-s) {
                            right: -16.5px;
                        }
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            right: -8px;
                        }
                        @media (min-width: $dobbies-container-max-width-xl) {
                            right: -30px;
                        }
                    }
                    .msc-ss-carousel-slide { 
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            padding-left: 0;
                        }
                        @media (max-width: $dobbies-container-max-width-s) {
                            display: block;
                        }
                        .ms-product-collection__item {
                            @media (max-width: $dobbies-container-max-width-s) {
                                width: 162px;
                                padding: 0;
                                min-height: 306px;
                            }
                            @media (min-width: $dobbies-container-max-width-s) {
                                width: 144px;
                                padding: 0 11px;
                                min-height: 283px;
                            }
                            @media (min-width: $dobbies-container-max-width-tablet) {
                                min-height: 380px;
                                width: 195px;
                                padding: 0 10px
                            }
                            @media (min-width: $dobbies-container-max-width-xl) {
                                min-height: 405px;
                            }
                        }
                    }
                }
            }

            div {
                .msc-product__product-quantity {
                    @media (max-width: $dobbies-container-max-width-s) {
                        padding-top: 6px;
                        margin-bottom: 6px;
                    }
                    .quantity {
                        @media (max-width: $dobbies-container-max-width-s) {
                            width: 80px;
                            height: 20px;
                        }
                        
                        .decrement{
                            &::before {
                                font-size: 12px;
                                margin-top: 0;
                            }
                        }

                        .increment {
                            &::before {
                               font-size: 13px; 
                            }
                        }

                        &-label {
                            font-size: 10px;
                            @media (min-width: $dobbies-container-max-width-tablet) {
                                font-size: 12px;
                            }
                        }
                    }
                }
                .msc-product__checkbox {
                    @media (max-width: $dobbies-container-max-width-tablet) {
                        width: 18px;
                        height: 18px;
                    }
                    @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                        width: 19px;
                        height: 19px;
                    }
                    &:checked+svg {
                        @media (max-width: $dobbies-container-max-width-s) {
                            right: 12px;
                            top: 8px;
                            width: 8px;
                        }
                        @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                            right: 10px;
                            top: 10px;
                            height: 12px;
                        }
                    }
                }
                 .msc-product {
                    a {
                        .msc-product__title {
                            @media (max-width: $dobbies-container-max-width-s) {
                                width: 140px;
                                margin-top: 6px;
                                margin-bottom: 6px;
                                font-size: $dobbies-font-size-xs;
                                line-height: 16px;
                            }
                            @media (min-width: $dobbies-container-max-width-s) {
                                margin: 8px 0;
                                line-height: 18px;
                                width: 130px;
                            }
                            @media (min-width: $dobbies-container-max-width-tablet) {
                                margin: 10px 0;
                                width: 175px;
                            }

                            @media (min-width: $dobbies-container-max-width-xl) {
                                margin-top: 4px;
                                margin-bottom: 7px;
                                line-height: 18px;
                            }
                        }
                    }
                    .msc-price {
                        @media (max-width: $dobbies-container-max-width-s) {
                            margin-bottom: 8px;
                            span {
                                font-size: $dobbies-font-size-s;
                            }
                        }
                        @media (min-width: $dobbies-container-max-width-s) {
                            margin-bottom: 6px;
                            span {
                                font-size: $dobbies-font-size-xs;
                            }
                        }
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            margin-bottom: 9px;
                            span {
                                font-size: $dobbies-font-size-m;
                            }
                        }
                        @media (min-width: $dobbies-container-max-width-xl) {
                            margin-bottom: 6px; 
                        }
                    }
                 }
            }
            .ms-wishlist-items__product-add-button {
                @media (max-width: $dobbies-container-max-width-s) {
                    height: 25px;
                    font-size: $dobbies-font-size-s;
                }
            }

            .msc-empty_image {
                @media (max-width: $dobbies-container-max-width-s) {
                    height: 196px;
                }
                @media (min-width: $dobbies-container-max-width-s) {
                    height: 182px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    height: 245px;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    height: 280px;
                }
            }
        }

        .product-bundle-left {
            align-items: center;
            @media (max-width: $dobbies-container-max-width-s) {
                display: block;
                position: relative;
            }

            @media (max-width: 400px) {
                left: 0;
            }
        }

        .ms-product-collection {
            // padding-left: 5px;
            // padding-right: 0px;
            // width: 670px;
            @media screen and (max-width: 400px) {
                width: 348px;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                width: 313px;
                margin: 0 auto;
                padding: 0;
            }
            @media (min-width: $dobbies-container-max-width-s) {
                width: 425px;
                left: 6px;
            }

            @media (min-width: $dobbies-container-max-width-tablet) {
                width: 585px;
                left: 10px;
            }
            @media (min-width: $msv-breakpoint-xl) {
                width: 625px;
                left: 50px;
                padding: 0;
            }
            .msc-product {
                .msc-product__image {
                    img {
                        @media (min-width: $dobbies-container-max-width-xl) {
                            height: 280px;
                        }
                    }
                }
            }
        }
    }

    &_PB {
        .notificationModal_middletext {
            padding-left: 20px;
            @media (max-width: $dobbies-container-max-width-s) {
                padding-left: 0;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                padding-left: 0;
            }
        }
        .product-bundle-container {
            .ms-wishlist-items {
                &__product-add-button {
                    @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                        max-height: 23px;
                    }

                    @media (min-width: $dobbies-container-max-width-tablet) {
                        max-height: 30px;
                    }
                }
            }
            div {
                .msc-product {
                    &__product-quantity {
                        @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                            margin-bottom: 5px;
                            padding-top: 5px;
                        }  
                        @media (min-width: $dobbies-container-max-width-xl) {
                            margin-bottom: 10px;
                        }
                        .quantity {
                            @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                                height: 18px;
                                width: 75px;
                                padding: 0 10px;
                            }  
                            .increment,
                            .decrement {
                                @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                                    height: 12px;
                                }       
                            }
                            .increment {
                                &::before {
                                    margin-top: -0.50px;
                                }
                            }
                        }
                    }
                    a {
                        .msc-product {
                            &__title {
                                @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                                    margin-bottom: 4px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                    .msc-price {
                        @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                            line-height: 16px;
                        }
                    }
                }
            }
            .ms-product-collection.product-bundles {
                .msc-ss-carousel {
                    .msc-ss-carousel-slide {
                        .ms-product-collection__item {
                            @media (min-width: $dobbies-container-max-width-xl) {
                                width: 210px;
                            }
                        }
                    }
                }
            }
        }
    }
}