$msv-breakpoint-xs: 0px;
$msv-breakpoint-s: 0px;
$msv-breakpoint-sm: 767px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-xl: 1476px;

$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;

$msv-container-max-width-s: 768px;
$msv-container-max-width-m: 992px;
$msv-container-max-width-l: 1492px;

$dobbies-container-max-width-xs: 390px;
$dobbies-container-max-width-s: 767px;
$dobbies-container-max-width-m: 991px;
$dobbies-container-max-width-l: 1068px;
$dobbies-container-max-width-tablet: 1210px;
$dobbies-container-max-width-xl: 1476px;
$dobbies-container-max-height: 500px;

$msv-container-min-width-m: 769px;

$msv-breakpoint-mbl: 436px;
$msv-breakpoint-mbl-sm: 576px;


$breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1340px,
);

//rule: min-width or max-width
@mixin breakpoint($bp, $rule: min-width) {
  @media (#{$rule}: map-get($breakpoints, $bp)) {
    @content;
  }
}