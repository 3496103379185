.ms-restraunt-container {
    .promo-banner {
        margin-top: 30px;
        padding: 0 10px;
        text-align: center;
        img {
            width: 100%;
        }
        .ms-content-block__text {
            margin-top: 15px;
        }
    }
    .dobbies-top-picks-carousel {
        border-top: 0;
    }
    .opening-hours {
        .row {
            align-items: center;
        }
        .ms-content-block {
            &__details {
                padding: 0;
                display: block;
                position: relative;
                height: auto;
                margin: 20px 0 40px;
                width: 100%;
                .ms-content-block__cta {
                    display: inline-block;
                    margin-top: 0;
                    a,
                    .msc-cta__primary,
                    .msc-cta__secondary {
                        min-height: 50px;
                        max-height: 50px;
                        line-height: initial;
                        min-width: 215px;
                        //border: 1px solid var(--dobbies-breadcrumb-icon-color);
                        font-size: var(--dobbies-body-font-size-s);
                        font-weight: var(--dobbies-font-weight-bold);
                        border-radius: 4px;
                        background-color: var(--dobbies-bg-color-secondary);
                        color: var(--dobbies-font-primary-color);
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                        cursor: pointer;
                        padding: 0 15px;
                        margin: 10px 0;
                        position: relative;
                        -webkit-transform: perspective(1px) translateZ(0);
                        transform: perspective(1px) translateZ(0);
                        position: relative;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        overflow: hidden;

                        @media (max-width: $msv-breakpoint-l) {
                            min-width: 173px;
                        }

                        @media (max-width: $msv-breakpoint-sm) and (min-width: $msv-breakpoint-mbl) {
                            min-width: 130px;
                        }

                        @media (max-width: $msv-breakpoint-mbl) {
                            min-width: 150px;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            z-index: -1;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: $dobbies-black;
                            -webkit-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transform-origin: 0 50%;
                            transform-origin: 0 50%;
                            -webkit-transition-property: transform;
                            transition-property: transform;
                            -webkit-transition-duration: 0.3s;
                            transition-duration: 0.3s;
                            -webkit-transition-timing-function: ease-out;
                            transition-timing-function: ease-out;
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            color: $dobbies-white;
                            background: $dobbies-black;
                            text-decoration: none;
                            &:after {
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
        .col-12:nth-child(2) {
            .ms-content-block {
                text-align: center;
                img {
                    margin: auto;
                }
                &__title {
                    display: none;
                }
            }
        }
    }
    .restraunt-product-section {
        padding: 0;
        .row {
            margin: 0 -10px;
        }
        > .row {
            justify-content: center;
            > .col-12 {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
                padding: 0 10px 30px;
                @media (max-width: $dobbies-container-max-width-m) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                // &:first-child,
                // &:nth-child(2),
                // &:nth-child(3),
                // &:nth-child(4) {
                //     flex: 0 0 100%;
                //     max-width: 100%;
                // }
                .ms-content-block[data-m-layout='media-product-image'] {
                    padding-bottom: 40px;
                    .ms-content-block {
                        &__image {
                            img {
                                margin: auto;
                            }
                        }
                        &__details {
                            position: initial;
                            height: auto;
                            padding: 0;
                            padding-top: 20px;
                            text-align: center;
                            margin: auto;
                            @media screen and (max-width: $dobbies-container-max-width-l) {
                                max-width: 390px;
                            }
                        }
                        &__title {
                            color: var(--dobbies-font-primary-color);
                            font-weight: var(--dobbies-font-weight-normal);
                            font-size: var(--dobbies-body-font-size-xml);
                            padding-bottom: 15px;
                            text-transform: uppercase;
                        }
                        &__text {
                            font-size: var(--dobbies-body-font-size-s);
                            margin-top: 0;
                        }
                        &__cta {
                            > a {
                                text-decoration: underline;
                                margin-top: 15px;
                                cursor: pointer;
                                &:hover {
                                    font-weight: var(--dobbies-font-weight-bold);
                                }
                            }
                        }
                    }
                }
                &:first-child,
                &:nth-child(2),
                &:nth-child(3) {
                    padding-bottom: 0;
                    max-width: 952px;
                    // margin: auto;
                    @media screen and (max-width: $dobbies-container-max-width-xl) {
                        max-width: 680px;
                    }
                    @media screen and (max-width: $dobbies-container-max-width-l) {
                        max-width: 740px;
                    }
                    @media screen and (max-width: $dobbies-container-max-width-l) {
                        max-width: 100%;
                    }
                    h2 {
                        margin: 0;
                        color: var(--dobbies-heading-primary-color);
                        font-size: var(--dobbies-tile-heading);
                        font-weight: var(--dobbies-font-weight-bold);
                        line-height: initial;
                        text-align: center;
                        @media screen and (max-width: $dobbies-container-max-width-m) {
                            font-size: var(--dobbies-tile-heading-s);
                        }
                        @media screen and (max-width: $dobbies-container-max-width-m) {
                            font-size: var(--dobbies-heading-font-size-25);
                        }
                    }
                    .ms-iframe {
                        position: relative;
                        width: 100%;
                        height: 0;
                        padding-bottom: 56.25%;
                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            border: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                &:first-child {
                    .ms-content-block {
                        min-height: auto;
                        &__details {
                            padding: 0;
                            position: relative;
                            display: block;
                            width: 100%;
                            height: auto;
                            margin-bottom: 10px;
                        }
                    }
                }
                &:nth-child(3) {
                    .ms-content-block {
                        min-height: auto;
                        &__details {
                            padding: 0;
                            width: 100%;
                            display: block;
                            position: relative;
                            height: auto;
                            margin-top: 15px;
                            margin-bottom: 50px;
                            @media (max-width: $dobbies-container-max-width-s) {
                                margin-bottom: 0;
                            }
                        }
                        &__text {
                            font-size: var(--dobbies-body-font-size-s);
                            max-width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
            > .col-12:last-child {
                > p {
                    font-size: var(--dobbies-body-font-size-ml);
                    font-weight: var(--dobbies-font-weight-bold);
                }
                > ul {
                    padding: 0;
                    list-style: none;
                }
            }
        }
    }
    .msc-carousel {
        &__control {
            &__prev,
            &__next {
                display: none;
            }
        }
    }
    .offer-slider {
        background-color: $dobbies-dark-blue;
        margin: 30px 0;
        .dobbies-top-picks-carousel {
            border: 0;
            &__heading {
                font-family: $dobbies-secondary-font-family;
                color: var(--dobbies-club-primary-color);
                font-size: 90px;
                font-weight: var(--dobbies-font-weight-normal);
                line-height: initial;
                padding: 10px 0;
                @media (max-width: $dobbies-container-max-width-m) {
                    font-size: 75px;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    font-size: 65px;
                }
            }
            &__item {
                &__heading {
                    font-size: var(--dobbies-body-font-size-ml);
                    color: var(--dobbies-club-primary-color);
                    margin-bottom: 0;
                }
                &__paragraph {
                    font-size: var(--dobbies-body-font-size-l);
                    color: var(--dobbies-font-secondary-color);
                    line-height: 20px;
                    margin-bottom: 3px;
                }
                &__link {
                    font-size: var(--dobbies-body-font-size-s);
                    color: var(--dobbies-font-secondary-color);
                    font-weight: var(--dobbies-font-weight-normal);
                    margin-top: 10px;
                    cursor: pointer;
                }
            }
            .msc-ss-carousel {
                @media screen and (min-width: $dobbies-container-max-width-l) {
                    padding: 0;
                }
                &__flipper {
                    @media screen and (min-width: $dobbies-container-max-width-l) {
                        left: -20px;
                        &--next {
                            right: -20px;
                            left: initial;
                        }
                    }
                    :before {
                        color: var(--dobbies-club-primary-color);
                    }
                }
            }
        }
        .carousel-text {
            text-align: center;
            padding: 20px;
            margin-top: -50px;
            a {
                color: var(--dobbies-font-secondary-color);
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .msc-ss-carousel-slide {
        .dobbies-top-picks-carousel__item {
            img {
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    height: 204px;
                    width: auto;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
    .hero {
        max-height: calc(100vh - 200px);
        overflow: hidden;
        &.ms-content-block {
            .ms-content-block__details {
                justify-content: flex-start;
                padding-top: 45px;
                padding-left: 40px;
                @media (max-width: $msv-breakpoint-m) {
                    padding-left: 0;
                }
                .ms-content-block__title {
                    @media (max-width: $msv-breakpoint-m) {
                        font-size: 36px;
                        padding-left: 20px;
                    }
                }
            }
        }
    }
    .opening-hours {
        text-align: center;
        .ms-content-block__title {
            @include font-content(var(--msv-font-weight-normal), 30px, $dobbies-line-height-xxl);
            text-align: center;
            text-transform: uppercase;
        }
        .ms-content-block__text {
            text-align: center;
        }
    }
}
.restraunt-location {
    margin-bottom: 40px;
    h2 {
        width: 100%;
        // margin: 30px 0;
        color: var(--dobbies-font-primary-color);
        // font-size: var(--dobbies-tile-heading);
        border-bottom: solid 1px var(--dobbies-gray-border-color);
        line-height: initial;
        font-weight: var(--dobbies-font-weight-normal);
        width: calc(100% - 15px);
        // @media screen and (max-width: $dobbies-container-max-width-m) {
        //     font-size: var(--dobbies-tile-heading-s);
        // }
    }
    > .row {
        padding-left: 12px;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            // padding-left: 0;
            padding-right: 12px;
        }
        > .col-12 {
            flex: 0 0 16.5%;
            max-width: 16.5%;
            &:nth-child(2) {
                flex: 0 0 67%;
                max-width: 67%;
                .ms-footer__list {
                    > li {
                        display: inline-block;
                        width: 25%;
                        //padding: 2px 0;
                        @media screen and (max-width: $dobbies-container-max-width-s) {
                            width: 33.33%;
                        }
                    }
                }
            }
            @media screen and (min-width: $msv-container-max-width-s) and (max-width: $msv-container-max-width-m) {
                flex: 0 0 50%;
                max-width: 50%;
                &:nth-child(2) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            .ms-footer__list {
                > li:first-child {
                    display: block;
                    margin-bottom: 20px;
                    width: 100%;
                    max-width: none;

                    &.Show {
                        .ms-footer__heading__title {
                            &::after {
                                transform: rotate(0deg);
                            }
                        }
                    }

                    @media screen and (max-width: $dobbies-container-max-width-tablet) {
                        margin-bottom: 0;
                    }
                    //padding: 2px 0;
                    .ms-footer__heading__title {
                        color: var(--dobbies-font-primary-color);
                        font-size: $dobbies-font-size-m;
                        border-bottom: solid 1px var(--dobbies-gray-border-color);
                        font-weight: var(--dobbies-font-weight-bold);
                        padding-bottom: 10px;
                        line-height: initial;
                        margin-bottom: 0;
                        @media screen and (max-width: $dobbies-container-max-width-tablet) {
                            @include add-dobbies-icon($dobbies-icon-arrow, after);
                            &:after {
                                color: var(--dobbies-breadcrumb-icon-color);
                                transform: rotate(180deg);
                                // font-size: var(--dobbies-body-font-size-s);
                                font-weight: var(--dobbies-font-weight-heavy);
                                position: relative;
                                top: -1px;
                                float: right;
                            }
                        }
                        //   @media (max-width: $msv-breakpoint-l) {
                        //     font-size: 18px;
                        // }
                    }
                }

                > li:not(:first-child) {
                    .ms-footer__heading {
                        margin-top: 10px;
                    }
                }
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                flex: 0 0 100%;
                max-width: 100%;
                &:nth-child(2) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    .ms-footer__list {
                        > li {
                            display: block;
                            width: 100%;
                        }
                    }
                }
                .collapse {
                    margin-bottom: 15px;
                    height: fit-content !important;
                    > li {
                        padding: 3px 0;
                    }
                }
            }
            > .container {
                padding-left: 0;
                padding-right: 0;
                margin-top: 22px;
                > .row {
                    > .col-12 {
                        > ul {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            > ul {
                padding-right: 15px;
                margin-top: 20px;
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    padding-right: 0;
                }
                > li {
                    padding: 2px 0;
                }
                .green-color {
                    color: var(--dobbies-heading-primary-color);
                }

                > div {
                    @media screen and (max-width: $dobbies-container-max-width-tablet) {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 10px;
                        gap: 20px 0;
                        margin: 0 -5px;
                        margin-top: 10px;

                        li {
                            flex: 50%;
                        }

                        > li {
                            padding: 0 5px;
                        }
                    }
                }
            }

            ul {
                > div {
                    @media screen and (max-width: $dobbies-container-max-width-tablet) {
                        margin-top: 10px;
                    }
                }
            }
            .note-text {
                font-style: italic;
                font-weight: var(--dobbies-font-weight-bold);
                color: var(--dobbies-heading-primary-color);
            }
            &:nth-child(3) {
                @media screen and (min-width: $msv-container-max-width-s) and (max-width: $msv-container-max-width-m) {
                    > ul {
                        > li {
                            display: inline-block;
                            width: 50%;
                        }
                    }
                }
            }
            &:nth-child(4) {
                @media screen and (min-width: $msv-container-max-width-s) and (max-width: $msv-container-max-width-m) {
                    .container {
                        > .row {
                            > .col-12 {
                                flex: 0 0 50%;
                                max-width: 50%;
                                &:first-child {
                                    padding-right: 15px;
                                }
                                &:last-child {
                                    flex: 0 0 100%;
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .col-12 {
            &:nth-child(2) {
                ul {

                    @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                        li {
                            flex: 25%;
                            max-width: 25%;
                        }
                    }
                }
            }
        }
    }
}
