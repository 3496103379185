$search-result-container-nav-height: 20px;
$search-result-container-nav-margin-bottom: 20px;
$search-result-container-nav-margin-top: 12px;
$search-result-container-nav-title-margin: 0px;
$search-result-container-refine-product-margin-bottom: 80px;
$search-result-container-refiner-section-padding-right: 15px;
$search-result-container-refiner-section-width: 25%;
$search-result-container-refiner-button-submenu-height: 64px;
$search-result-container-refiner-button-submenu-padding-left: 0px;
$search-result-container-refiner-button-submenu-padding-right: 0px;
$search-result-container-refiner-button-submenu-padding-top: 6px;
$search-result-container-refiner-button-submenu-padding-bottom: 6px;
$search-result-container-refiner-submenu-list-group-border-color: $dobbies-gray-900;
$search-result-container-refiner-submenu-list-group-margin-bottom: 0px;
$search-result-container-refiner-submenu-list-group-margin-left: 0px;
$search-result-container-refiner-submenu-item-label-padding-left: 12px;
$search-result-container-refiner-submenu-list-group-item-padding-left: 0px;
$search-result-container-refiner-submenu-list-group-item-margin-bottom: 10px;
$search-result-container-refiner-list-group-item-border: 1px solid rgba(0, 0, 0, 0);
$search-result-container-refiner-list-group-item-outline-offset: 2px;
$search-result-container-refiner-list-group-item-padding: 21px 0 0px 0;
$search-result-container-refiner-slider-horizontal-background-color: $dobbies-gray-300;
$search-result-container-refiner-slider-horizontal-height: 4px;
$search-result-container-refiner-slider-padding: 0px;
$search-result-container-refiner-slider--track-top: 0px;
$search-result-container-refiner-slider-thumb-background-size: 12px 12px;
$search-result-container-refiner-slider-thumb-border-radius: 50%;
$search-result-container-refiner-slider-thumb-height: 18px;
$search-result-container-refiner-slider-thumb-outline-offset: 2px;
$search-result-container-refiner-slider-thumb-top: 50%;
$search-result-container-refiner-slider-thumb-width: 18px;
$search-result-container-refiner-slider-thumb-before-bottom: 0px;
$search-result-container-refiner-slider-thumb-before-left: 0px;
$search-result-container-refiner-slider-thumb-before-right: 0px;
$search-result-container-refiner-slider-thumb-before-top: 0px;
$search-result-container-refiner-slider-labels-item-margin-top: 25px;
$search-result-container-refiner-slider-labels-item-padding-bottom: 20px;
$search-result-container-pagination-padding-left: 0px;
$search-result-container-product-section-width: 75%;
$search-result-container-sort-by-category-padding-left: 8px;
$search-result-container-sort-by-category-right: 0;
$search-result-container-sort-by-category-dropdown-label-margin-right: 10px;
$search-result-container-products-margin-bottom: 80px;
$search-result-container-products-margin-top: 20px;
$search-result-container-product-search-result-item-grid-column-gap: 20px;
$search-result-container-product-search-result-item-grid-column-gap-mobile: 12px;
$search-result-container-product-search-result-item-width: 220px;
$search-result-container-product-search-result-item-width-mobile: 150px;
$search-result-container-product-search-result-item-padding: 0px 0px 20px 0px;
$search-result-container-product-search-item-title-margin-bottom: 10px;
$search-result-container-product-search-item-title-margin-top: 10px;
$search-result-container-product-search-item-rating-margin-top: 4px;
$search-result-container-page-item-previous-padding-left: 4px;
$search-result-container-page-item-page-link-padding: 8px 4px;
$search-result-container-page-item-page-link-line-height: 1.25;
$search-result-container-page-item-padding-right: 10px;
$search-result-container-pagination-margin-bottom-mobile: 16px;
$search-result-container-products-margin-bottom-mobile: 40px;
$search-result-container-choice-summary-padding-inline-start: 0px;
$search-result-container-choice-summary-margin-left: 8px;
$search-result-container-choice-summary-margin-top: 20px;
$search-result-container-choice-summary-margin-bottom: 5px;
$search-result-container-choice-summary-background-color: $dobbies-gray-200;
$search-result-container-choice-summary-border: 1px solid $dobbies-gray-200;
$search-result-container-choice-summary-margin-right: 10px;
$search-result-container-choice-summary-border-radius: 3px;
$search-result-container-choice-summary-padding-left: 8px;
$search-result-container-choice-summary-outline-offset: 2px;
$search-result-container-choice-summary-clear-all-background-color: $dobbies-gray-100;
$search-result-container-choice-summary-clear-all-border: 0px;
$search-result-container-choice-summary-clear-all-outline-offset: 2px;
$search-result-container-choice-summary-glypg-margin-left: 8px;
$search-result-container-choice-summary-glypg-margin-right: 8px;
$search-result-modal-header-close-button-padding: 20px;
$search-result-modal-header-close-button-margin: -20px -20px -20px auto;
$search-result-modal-body-height: calc(100vh - 148px);
$search-result-modal-body-height-mobile: calc(100vh - 108px);
$search-result-modal-content-padding: 20px 25px;
$search-result-modal-content-padding-mobile: 0;
$search-result-modal-footer-button-background-color: $dobbies-black;
$search-result-modal-footer-button-border-color: $dobbies-black;
$search-result-modal-footer-button-padding: 12px 20px;
$search-result-modal-footer-button-min-width: 160px;
$search-result-modal-refiner-section-padding-right: 0px;
$search-result-container-padding-left-mobile: 2px;
$search-result-container-padding-right-mobile: 2px;
$search-result-container-sort-by-category-top-mobile: 0px;
$product-search-result_link__item-outline-width: 1px;
$product-search-result_link__item-outline-style: dashed;
$product-search-result_link__item-outline-offset: 2px;
$search-result-prev-next-padding: 0 4px;
$light-border-color: rgba(112, 112, 112, 0.5);
$listing-spacing: 15px;
$sub-menu-spacing: 10px 16px;
$dropdown-spacing: 3.5px 10px;
$dropdown-min-width: 225px;

@mixin three-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    min-height: 64px;
}
@mixin two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    min-height: 42px;
}

//style presets
:root {
    --msv-search-result-font-color: var(--dobbies-font-primary-color);
    --msv-search-result-font-size: var(--msv-body-font-size-m);

    --msv-search-result-heading-font-color: var(--dobbies-font-primary-color);
    --msv-search-result-heading-font-size: var(--msv-body-font-size-xl);

    --msv-search-result-sort-bg: transparent;
    --msv-search-result-sort-font-color: var(--dobbies-font-primary-color);
    --msv-search-result-sort-font-size: var(--msv-body-font-size-m);

    --msv-search-result-product-color: var(--dobbies-font-primary-color);
    --msv-search-result-product-size: var(--msv-body-font-size-m);

    --msv-search-result-product-price-color: var(--dobbies-font-primary-color);
    --dobbies-search-result-product-price-size: var(--dobbies-body-font-size-m);

    --msv-search-result-pagination-color: var(--dobbies-font-primary-color);
    --msv-search-result-pagination-size: var(--msv-body-font-size-m);

    //Refiner heading
    --msv-refiner-heading-font-color: var(--dobbies-font-primary-color);
    --msv-refiner-heading-font-size: var(--msv-body-font-size-l);

    //Refiner values
    --msv-refiner-values-font-color: var(--dobbies-font-primary-color);
    --msv-refiner-values-font-size: var(--msv-body-font-size-m);

    //border
    --msv-refiner-border: #{$dobbies-gray-300};

    // slider
    --msv-refiner-slider: var(--dobbies-font-primary-color);

    // rating star
    --msv-refiner-rating-star: var(--dobbies-font-primary-color);
    --msv-refiner-rating-star-size: var(--msv-body-font-size-m);

    --msv-feature-title-color: var(--dobbies-font-primary-color);
    --msv-feature-title-desktop-size: var(--msv-body-font-size-xxl);
    --msv-feature-title-mobile-size: var(--msv-body-font-size-xl);

    --msv-feature-heading-color: var(--dobbies-font-primary-color);
    --msv-feature-heading-font-size: var(--msv-body-font-size-l);
}

.ms-content-block.category-banner {
    margin-top: 5px;
    margin-bottom: 20px;
    max-height: 407px;
    padding: 0;
    position: relative;

    @media screen and (min-width: $dobbies-container-max-width-xl) {
        margin-bottom: 30px;
    }
    @media screen and (max-width: $dobbies-container-max-width-xl) {
        display: flex;
    }

    @media screen and (max-width: $dobbies-container-max-width-tablet) {
        max-height: 256px;
        min-height: auto;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        max-height: initial;
        display: block;
        margin-bottom: 0;
    }

    &.no-image-categories {
        @media screen and (max-width: $dobbies-container-max-width-tablet) {
            max-height: 200px;
            min-height: auto;
        }
    }

    .ms-content-block {
        &__image {
            order: 1;
            width: 100%;

            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                display: block;
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                margin: 20px 0 10px;
            }

            img {
                height: 100%;
                @media screen and (max-width: $msv-breakpoint-m) {
                    max-height:none;
                }
            }
        }

        &__details {
            margin: 0;
            padding: 0;
            height: 100%;
            top: 0;
            > * {
                padding-left: 20px;
                @media screen and (max-width: $msv-breakpoint-m) {
                    padding: 0 10px 10px;
                    text-align: center;
                }
            }
            > *:first-child {
                padding-top: 0px;
            }

            @media screen and (max-width: $dobbies-container-max-width-xl) {
                width: 40%;
                height: auto;
            }

            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                padding-left: 0px;

                .ms-content-block__title {
                    top: auto;
                    left: auto;
                }
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                max-height: initial;
                display: block;
                margin-bottom: 0;
                width: 100%;
                position: static;
            }
        }

        &__title {
            font-size: 30px;
            font-weight: 500;
            line-height: 36px;
            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                font-size: 24px;
                margin-bottom: 10px;
                line-height: 30px;
            }
            @media screen and (min-width: 768px) and (max-width: 992px) {
                margin-bottom: 0;
            }
            @media screen and (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }

        &__text {
            @media screen and (max-width: $dobbies-container-max-width-xl) {
                max-width: 85%;
            }
        }
    }

    .ms-content-block__details {
        .ms-content-block__text {
            @media screen and (max-width: $msv-breakpoint-l) {
                position: static;
                width: auto;
                max-width: 350px;
            }
            @media screen and (max-width: $msv-breakpoint-m) {
                text-align: center;
                max-width: none;
                padding-bottom: 0;
                max-width: 500px;
                margin: 0 auto;
            }
        }
        .ms-content-block__title {
            @media screen and (max-width: $msv-breakpoint-m) {
                padding-bottom: 0;
            }
        }
    }
}

.ms-search-result-container {
    position: relative;

    .top-title {
        display: none;
        padding: 20px 0 10px 0;
        border-bottom: 1px solid #1e1e1e;
        text-align: center;
        text-transform: uppercase;
        font-size: 30px;
        @media screen and (max-width: $dobbies-container-max-width-tablet) {
            font-size: 24px;
            margin-bottom: 20px;
        }

        @media screen and (max-width: $dobbies-container-max-width-tablet) {
            font-size: 24px;
            margin-bottom: 20px;
        }

        @media screen and (max-width: $msv-breakpoint-sm) {
            margin-bottom: 0;
        }
    }

    &__category-nav-section {
        padding: 15px 0;

        .ms-search-result-container__title {
            text-align: center;
            top: 0;
            left: 0;
            width: 100%;
            padding: 15px 0;
            border-bottom: solid 1px $dobbies-grey;
            h2,
            h5 {
                display: inline-block;
                .ms-search-result {
                    &__collection-title-prefix {
                        display: none;
                    }
                    &__collection-title-count {
                        @include font-content(
                            var(--msv-font-weight-normal),
                            var(--msv-search-result-heading-font-size),
                            $dobbies-line-height-xl
                        );
                        color: var(--msv-search-result-heading-font-color);
                    }
                }
            }
        }
        .ms-search-result-container__category-hierarchy {
            @include font-content-s();
            display: inline-block;
            margin: 0;
            a:last-child {
                font-weight: var(--dobbies-font-weight-bold);
            }
            > span {
                font-size: 0;
                width: 24px;
                display: inline-block;
                text-align: center;
                &:after {
                    @include dobbies-icon();
                    content: $dobbies-icon-arrow;
                    font-size: var(--dobbies-breadcrumb-icon);
                    transform: rotate(90deg);
                    color: var(--dobbies-breadcrumb-icon-color);
                }
            }
        }

        .ms-search-result-container__title {
            display: inline-block;
            h5 {
                font-size: var(--dobbies-body-font-size-m);
                margin: $search-result-container-nav-title-margin;

                .ms-search-result__collection-title-count {
                    @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-font-size), $dobbies-line-height-m);
                }
            }
        }
    }
}

.ms-search-result-container__refine-product-section {
    display: inline-flex;
    margin-bottom: $search-result-container-refine-product-margin-bottom;
    width: 100%;
}

.category-list-mobile,
.ms-search-result-container__category-nav-section {
    @media screen and (min-width: $dobbies-container-max-width-tablet) {
        padding: 15px 0;
        margin: 0;
        display: block;
    }
    @media screen and (max-width: $dobbies-container-max-width-tablet) {
        float: left;
        width: 172px;
        margin-bottom: 20px;
        // margin-top: 5px;
        padding: 0;
    }

    @media screen and (max-width: $msv-breakpoint-sm) {
        width: 186px;
        margin-top: 20px;
    }

    @media screen and (max-width: 400px) {
        width: 160px;
    }

    h3 {
        margin: 0 0 10px;
        font-size: var(--dobbies-body-font-size-m);
        line-height: 20px;
        font-weight: normal;
        text-align: center;
    }
    button {
        font-size: 14px;
        width: 100%;
        display: block;
        color: $dobbies-grey;
        border: 1px solid $dobbies-grey;
        padding: 1px 17px;
        background: $white;
        text-align: left;
        line-height: 26.4px;
        position: relative;
        @include add-icon($msv-ChevronDown, after);
        &:after {
            top: 4px;
            float: right;
            font-size: var(--dobbies-body-font-size-ml);
            position: relative;
            color: #facc00;
        }
    }
}

.category-list-mobile {
    @media screen and (max-width: $dobbies-container-max-width-xl) {
        display: none;
    }
    @media screen and (max-width: $dobbies-container-max-width-tablet) {
        display: flex;
    }

    width: 100% !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    h1 {
        padding: 0;
    }
    button {
        height: 27px;
        width: 114px;
        line-height: 20px;

        &::after {
            font-size: 16px;
            top: 3px;
        }
    }
}

#search-result-modal {
    float: right;
    width: 30%;
    color: $dobbies-grey;
    border: 1px solid $dobbies-grey;
    padding: 1px 17px;
    background: $white;
    font-size: 14px;
    text-align: left;
    line-height: 26.4px;
    position: relative;
    font-weight: var(--dobbies-font-weight-normal);
    @include add-icon($msv-ChevronDown, after);
    margin-bottom: 20px;
    margin-top: 5px;

    @media screen and (max-width: $dobbies-container-max-width-xl) {
        margin-top: 0;
        width: 172px;
    }

    @media screen and (max-width: $msv-breakpoint-sm) {
        width: 186px;
        margin-top: 20px !important;
    }

    @media screen and (max-width: 400px) {
        width: 160px;
    }

    &:after {
        top: 4px;
        float: right;
        font-size: var(--dobbies-body-font-size-ml);
        position: relative;
        color: #facc00;
    }
}

.mobile-category-listing {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.category-listing {
    &-title {
        padding: 16px;
    }

    .category-heading {
        font-size: 18px;
        font-weight: 500;
    }

    h3 {
        font-size: var(--dobbies-body-font-size-s);
        line-height: 20px;
        padding: $listing-spacing;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: var(--dobbies-body-font-size-s);
        line-height: 20px;
        li {
            border-top: 1px solid $dobbies-grey;
            a {
                display: block;
                padding: 15px 0 15px 15px;
                position: relative;
                text-decoration: none;
                // &:after {
                //     @include dobbies-icon();
                //     content: $dobbies-icon-arrow;
                //     font-size: var(--dobbies-icon-font-size-s);
                //     transform: rotate(90deg);
                //     float: right;
                //     display: none;
                //     color: var(--dobbies-icon-color);
                //     margin-top: 2px;
                // }
                &.browse-active-link,
                &:hover {
                    text-decoration: none;
                    background: $dobbies-header-grey;
                    //color: #111
                    font-weight: 500;
                }
                &.browse-active-link:after {
                    display: inline-block;
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        h3 {
            padding: 15px;
        }
        ul {
            li {
                a {
                    padding: 15px;
                }
            }
        }
    }
}

.ms-search-result-container__refine-menu {
    display: flex;
    flex-direction: column;
}

// refiner section
.ms-search-result-container__refiner-section {
    display: block;
    padding-right: $search-result-container-refiner-section-padding-right;
    width: $search-result-container-refiner-section-width;

    .collapse:not(.show) {
        display: none;
    }

    button.ms-refine-submenu__toggle_collapsed,
    .ms-refine-submenu__toggle_expanded {
        background: transparent;
        color: var(--msv-refiner-heading-font-color);
        font-size: var(--dobbies-body-font-size-s);
        font-weight: var(--dobbies-font-weight-normal);
        display: flex;
        justify-content: space-between;
        padding: 15px 0 15px 15px;
        width: 100%;
        height: auto;
        border-top: 1px solid #111;
        &:after {
            @include dobbies-icon();
            content: $dobbies-icon-arrow;
            font-size: var(--dobbies-icon-font-size-s);
            transform: rotate(180deg);
            margin-right: 10px;
            float: right;
            color: var(--dobbies-icon-color);
            //margin-top: 5px;
            color: #facc00;
        }
    }
    .ms-refine-submenu__toggle_expanded {
        &:after {
            transform: rotate(0deg);
        }
    }

    .ms-refine-submenu__list {
        padding: 15px 0 15px 15px;
        border-top: 1px solid #111;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;

        &.Rating {
            flex-direction: column-reverse;
        }

        &.Price {
            padding-right: 15px;
        }
    }

    .ms-refine-submenu.list-group {
        display: flex;
        flex-direction: column;
        margin-bottom: $search-result-container-refiner-submenu-list-group-margin-bottom;
        padding-left: $search-result-container-refiner-submenu-list-group-margin-left;

        .ms-refine-submenu-item {
            line-height: inherit;
            padding-left: $search-result-container-refiner-submenu-list-group-item-padding-left;
            &:last-child {
                margin-bottom: 0;
            }

            > a {
                @include vfi();
                padding-left: 22px;
                position: relative;
                padding-right: 15px;
                .ms-refine-submenu-item__label {
                    padding-left: 0;
                }

                &:after {
                    content: '';
                    position: absolute;
                    display: none;
                }

                // &.single-select {
                //     &:before {
                //         position: absolute;
                //         content: '';
                //         top: 3px;
                //         left: 0;
                //         height: 15px;
                //         width: 15px;
                //         background-color: $white;
                //         border: 1px solid $dobbies-grey;
                //         border-radius: 2px;
                //     }
                //     &::before {
                //         border-radius: 100%;
                //     }

                //     &-checked {
                //         &::before {
                //             border-radius: 100%;
                //         }
                //         &::after {
                //             display: block;
                //             left: 3px;
                //             top: 6.4px;
                //             width: 9px;
                //             height: 9px;
                //             border-radius: 100%;
                //             background: $dobbies-grey;
                //         }
                //     }
                // }

                &.multi-select,
                &.single-select {
                    &:before {
                        position: absolute;
                        content: '';
                        top: 3px;
                        left: 0;
                        height: 15px;
                        width: 15px;
                        background-color: $white;
                        border: 1px solid $dobbies-grey;
                        border-radius: 2px;
                    }

                    &-checked {
                        &:before {
                            position: absolute;
                            content: '';
                            top: 3px;
                            left: 0;
                            height: 15px;
                            width: 15px;
                            background-color: $white;
                            border: 1px solid $dobbies-grey;
                            border-radius: 2px;
                        }
                        &:after {
                            display: block;
                            left: 5px;
                            top: 4.5px;
                            width: 5px;
                            height: 10px;
                            border: solid $dobbies-grey;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            .msc-rating {
                display: inline-block;
                margin-bottom: 0;

                &__star {
                    color: var(--dobbies-heading-primary-color);
                    font-size: var(--msv-refiner-rating-star-size);
                }

                &__empty-star {
                    font-size: var(--msv-refiner-rating-star-size);
                    color: #fbeebb;
                }

                &__count {
                    color: var(--msv-refiner-values-font-color);
                    font-size: var(--msv-refiner-rating-star-size);
                }
            }
        }

        .refine-submenu-item__rating {
            color: var(--msv-refiner-values-font-color);
            font-size: 14px;
            margin-bottom: 0;
        }

        .multi-select,
        .multi-select-checked,
        .single-select,
        .single-select-checked {
            @include vfi();
            display: block;
            border: 1px solid transparent;

            span.ms-refine-submenu-item__label {
                color: var(--msv-refiner-values-font-color);
                font-size: var(--dobbies-body-font-size-s);
                //    padding-left: $search-result-container-refiner-submenu-item-label-padding-left;
                vertical-align: top;
            }
            &:hover {
                text-decoration: none;
                color: var(--dobbies-heading-primary-color);
                span.ms-refine-submenu-item__label {
                    color: var(--dobbies-heading-primary-color);
                }
            }
        }
        .collapse.show {
            height: fit-content !important;
        }

        &.Rating {
            .ms-refine-submenu-item {
                > a {
                    padding-left: 0;
                }
            }

            .msc-rating__empty-star,
            .msc-rating__star {
                font-size: 24px;
            }
        }
    }

    .list-group-item {
        border: $search-result-container-refiner-list-group-item-border;
        display: block;
        outline-offset: $search-result-container-refiner-list-group-item-outline-offset;
        padding: $search-result-container-refiner-list-group-item-padding;
        position: relative;
    }

    .slider-horizontal {
        background-color: $search-result-container-refiner-slider-horizontal-background-color;
        height: 2px;
    }

    .slider {
        box-shadow: inset 0 0 2px;
        cursor: pointer;
        display: block;
        padding: $search-result-container-refiner-slider-padding;
        position: relative;
        touch-action: none;

        .slider__track {
            background-color: #111;
            height: 100%;
            width: 100%;
            position: absolute;
            top: $search-result-container-refiner-slider--track-top;
        }

        .slider__thumb {
            @include vfi();
            background: #111;
            border: 1px solid #111;
            background-size: $search-result-container-refiner-slider-thumb-background-size;
            border-radius: $search-result-container-refiner-slider-thumb-border-radius;
            display: inline-block;
            height: $search-result-container-refiner-slider-thumb-height;
            outline-offset: $search-result-container-refiner-slider-thumb-outline-offset;
            position: absolute;
            top: $search-result-container-refiner-slider-thumb-top;
            transform: translate3d(-50%, -50%, 0);
            width: $search-result-container-refiner-slider-thumb-width;
        }

        .slider__thumb:before {
            bottom: $search-result-container-refiner-slider-thumb-before-bottom;
            content: '';
            display: block;
            left: $search-result-container-refiner-slider-thumb-before-left;
            outline-offset: calc(0px + 0.0625rem);
            position: absolute;
            right: $search-result-container-refiner-slider-thumb-before-right;
            top: $search-result-container-refiner-slider-thumb-before-top;
        }

        .slider__thumb:focus {
            border: 1px solid var(--dobbies-icon-color);
            outline: none;
        }

        &__thumb__range:focus {
            + .slider__thumb {
                border: 1px solid var(--dobbies-icon-color);
                outline: none;
            }
        }

        .slider__labels-item {
            color: var(--msv-refiner-values-font-color);
            font-size: var(--msv-refiner-values-font-size);
            font-weight: var(--dobbies-font-weight-normal);
            cursor: pointer;
            display: inline-block;
            margin-top: $search-result-container-refiner-slider-labels-item-margin-top;
        }

        .slider__labels-item.start {
            float: left;
        }

        .slider__labels-item.end {
            float: right;
        }
    }
}

// product section
.ms-search-result-container__product-section {
    width: $search-result-container-product-section-width;

    .ms-search-result-wrapper-title-choice-summary {
        flex: 0 0 75%;
        max-width: 75%;
        display: inline-block;
        position: relative;
        width: 100%;

        .ms-search-result-container__title h2 {
            color: var(--msv-search-result-font-color);
            font-size: var(--msv-search-result-font-size);

            .ms-search-result__collection-title-prefix,
            .ms-search-result__collection-title-text {
                @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-heading-font-size), $dobbies-line-height-xl);
                color: var(--msv-search-result-heading-font-color);
            }
            .ms-search-result__collection-title-count {
                @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-font-size), $dobbies-line-height-m);
                color: var(--msv-search-result-font-color);
                display: inline-block;
            }
        }
    }
    .ms-search-result-wrapper-sort-by-category {
        flex: 0 0 25%;
        max-width: 25%;
        display: inline-block;
        position: relative;
        width: 100%;
    }
}

.msc-choice-summary,
.ms-search-result-container__refine-product-section {
    // display: none;
    list-style-type: none;
    padding-inline-start: $search-result-container-choice-summary-padding-inline-start;
    margin-bottom: 0;

    > span {
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-block;
        @media (max-width: $msv-breakpoint-sm) {
            display: block;
            text-align: center;
            margin-right: 0;
        }
    }

    @media (max-width: $dobbies-container-max-width-m) {
        overflow: hidden;
        width: 100%;
    }
    @media (max-width: $dobbies-container-max-width-s) {
        text-align: center;
    }
    .msc-choice-summary__list {
        display: inline-block;
    }

    .msc-choice-summary__list-item {
        display: inline-block;
    }

    .msc-choice-summary__item {
        @include vfi();
        @include font-content(var(--msv-font-weight-normal), var(--msv-refiner-values-font-size), $dobbies-line-height-m);
        // background-color: $search-result-container-choice-summary-background-color;
        color: var(--msv-refiner-values-font-color);
        border: 1px solid #111;
        margin-right: $search-result-container-choice-summary-margin-right;
        border-radius: $search-result-container-choice-summary-border-radius;
        padding-left: $search-result-container-choice-summary-padding-left;
        outline-offset: $search-result-container-choice-summary-outline-offset;
        position: relative;
        letter-spacing: 0.28px;
        margin-bottom: 10px;

        @media (max-width: $dobbies-container-max-width-s) {
            margin-bottom: 20px;
        }
    }

    .msc-choice-summary__clear-all {
        @include vfi();
        @include font-content(var(--msv-font-weight-normal), var(--msv-refiner-values-font-size), $dobbies-line-height-m);
        display: inline-block;
        color: var(--msv-refiner-values-font-color);
        border: $search-result-container-choice-summary-clear-all-border;
        text-decoration: underline;
        outline-offset: $search-result-container-choice-summary-clear-all-outline-offset;
        position: relative;
    }

    .msc-choice-summary__glyph {
        margin-left: $search-result-container-choice-summary-glypg-margin-left;
        margin-right: $search-result-container-choice-summary-glypg-margin-right;
        vertical-align: text-top;

        &:after {
            @include dobbies-icon();
            display: inline-block;
            content: $dobbies-icon-close;
            font-size: 10px;
            font-weight: 600;
            width: 10px;
            top: 0px;
            position: relative;
            line-height: 10px;
        }
    }

    .msc-choice-summary__clear-all:after {
        display: none;
    }
}

.msc-choice-summary {
    @media (min-width: $msv-breakpoint-l) {
        width: calc(100% - 300px);
        float: left;
    }

    @media (max-width: $msv-breakpoint-sm) {
        .msc-choice-summary__clear-all {
            margin-bottom: 20px;
        }
    }
}

.ms-search-result-container__Sort-by-category {
    float: right;
    position: relative;
    @include font-content(var(--msv-font-weight-light), var(--msv-search-result-sort-font-size), $dobbies-line-height-m);
    @media (max-width: $msv-breakpoint-m) {
        padding: 5px 15px 10px;
    }

    .msc-l-dropdown__label {
        color: var(--msv-search-result-sort-font-color);
        margin-right: $search-result-container-sort-by-category-dropdown-label-margin-right;
        vertical-align: middle;
        margin-bottom: 0px;
    }

    .msc-l-dropdown {
        @include font-content($dobbies-line-height-m);
        font-size: 14px;
        font-weight: 400 !important;
        background: var(--msv-search-result-sort-bg);
        color: var(--msv-search-result-sort-font-color);
        vertical-align: middle;
        padding: $dropdown-spacing;
        min-width: $dropdown-min-width;
        border: 1px solid var(--dobbies-dark-border-color);
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        font-weight: normal;
        z-index: 2;
        position: relative;
    }
    &:after {
        @include dobbies-icon();
        content: $dobbies-icon-arrow;
        transform: rotate(-180deg);
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -9px;
        background: var(--dobbies-bg-color);
        font-weight: var(--dobbies-font-weight-heavy);
        font-size: var(--dobbies-body-font-size-l);
        color: #facc00;
        z-index: 1;
        pointer-events: none;
        @media (max-width: $dobbies-container-max-width-m) {
            margin-top: 2px;
            right: 30px;
        }
    }
}

.msc-product__text {
    display: none;
}

.ms-search-result-container__Products {
    clear: both;
    margin-bottom: $search-result-container-products-margin-bottom;
    padding-top: $search-result-container-products-margin-top;

    @media screen and (max-width: $dobbies-container-max-width-l) {
        padding-top: 0px;
    }

    .ms-search-result-container__no-results-message {
        h5.error-text {
            @include font-content(var(--msv-font-weight-normal), var(--msv-refiner-values-font-size), $dobbies-line-height-m);
            text-align: center;
            margin-left: -25%;
        }
    }

    .list-unstyled {
        display: grid;
        grid-column-gap: $search-result-container-product-search-result-item-grid-column-gap;
        grid-template-columns: repeat(auto-fill, minmax($search-result-container-product-search-result-item-width, 1fr));
        @media screen and (max-width: $msv-breakpoint-xl) {
            grid-column-gap: 18px;
        }
        @media screen and (max-width: $dobbies-container-max-width-s) {
            grid-template-columns: repeat(auto-fill, minmax($search-result-container-product-search-result-item-width-mobile, 1fr));
            grid-column-gap: $search-result-container-product-search-result-item-grid-column-gap-mobile;
        }

        .ms-product-search-result__item {
            > div,
           .msc-product {
               height: 100%;
           }
       }
    }
}

.ms-product-search-result__item {
    padding: $search-result-container-product-search-result-item-padding;

    .msc-product {
        display: block;
        // height: 100%;
        height: auto;
        overflow: hidden;
        border-bottom: 1px solid #111;
        position: relative;

        br {
            display: none;
        }

        &__title,
        &__details {
            color: var(--msv-search-result-font-color);
            width: 100%;
        }

        &__content {
            width: 100%;
        }

        .msc-rating {
            &__star {
                color: var(--msv-refiner-rating-star);
                font-size: var(--dobbies-plp-rating-icon);
                &:before {
                    @include dobbies-icon();
                    content: $dobbies-icon-star-filled;
                    font-size: var(--dobbies-icon-font-rating);
                    color: var(--dobbies-font-primary-color);
                }
            }

            &__half-star {
                font-size: var(--dobbies-plp-rating-icon);
                &:after {
                    color: var(--msv-refiner-rating-star);
                }
            }
            &__empty-star {
                font-size: var(--dobbies-plp-rating-icon);
                &:before {
                    @include dobbies-icon();
                    content: $dobbies-icon-star-filled;
                    font-size: var(--dobbies-icon-font-rating);
                    color: $dobbies-footer-grey;
                }
            }
            &__image {
                background-color: var(--dobbies-tile-bg);
            }
        }
        &:focus {
            outline: none;
        }
        &__image {
            a {
                border: none;
            }
            img {
                width: 100%;
                height: 352px;
                @media screen and (max-width: $dobbies-container-max-width-xl) {
                    height: 343px;
                }
                @media screen and (max-width: $dobbies-container-max-width-tablet) {
                    height: 330px;
                }
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    height: 260px;
                }
            }
        }
        .msc-empty {
            &_image {
                @include image-placeholder-rectangle(252px, 353px);
                background-size: contain;
                background-repeat: no-repeat;
                max-width: 100%;
                @media screen and (max-width: $dobbies-container-max-width-xl) {
                    @include image-placeholder-rectangle(240px, 336px);
                    max-width: 100%;
                }
                @media screen and (max-width: $dobbies-container-max-width-l) {
                    @include image-placeholder-rectangle(231px, 323px);
                    max-width: 100%;
                }
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    @include image-placeholder-rectangle(231px, 260px);
                    max-width: 100%;
                }
                @media screen and (max-width: $msv-breakpoint-mbl) {
                    @include image-placeholder-rectangle(181px, 253px);
                    max-width: 100%;
                }
                @media screen and (max-width: $dobbies-container-max-width-xs) {
                    @include image-placeholder-rectangle(169px, 236px);
                    max-width: 100%;
                }
            }
        }
    }

    .badges__icon {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.badges {
    z-index: 9;
    &.left-badge,
    &.both {
        > div {
            &:first-child {
                width: 114px;
                top: 15px;
                right: 0;
                left: auto;
                position: absolute;
                z-index: 9;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    width: auto;
                    min-width: 156px;
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    min-width: 162px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    min-width: 166px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    width: calc(100% + 20px);
                    height: 100%;
                    background-color: $dobbies-white;
                    -webkit-transform: skew(25deg);
                    box-shadow: -3px 3px 8px $dobbies-badge-shadow;
                    right: -25px;
                    z-index: -1;
                }
            }
        }
        .badges__top,
        .badges__oos {
            background: $dobbies-white;
            border: none;
            right: 0;
            font-size: 9px;
            font-weight: bold !important;
            color: $dobbies-black;
            padding: 5.5px 2px 5.5px 10px;
            background-color: transparent !important;
            color: $dobbies-black !important;
            border: none !important;
            position: relative;
            z-index: 9;
            height: 22px;
            line-height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            @media screen and (min-width: $dobbies-container-max-width-s) {
                font-size: 10px;
                padding: 5.5px 5px 5.5px 15px;
                height: 30px;
                line-height: 13px;
            }
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                font-size: 12px;
                height: 31px;
                line-height: 16px;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                height: 32px;
            }
        }

        .badges__icon {
            position: absolute;
            top: 14px;
            left: 14px;
            margin: 0;
        }
    }
}

.ms-product-collection {
    .badges__icon {
        width: 60px;
        height: 60px;
    }
}

.ms-product-search-result__item a {
    .msc-product__title {
        @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-s), $dobbies-line-height-m);
        color: var(--dobbies-font-primary-color);
        margin-bottom: $search-result-container-product-search-item-title-margin-bottom;
        margin-top: $search-result-container-product-search-item-title-margin-top;
        @include two-line();
        min-height: 16px;
        display: block;
        white-space: nowrap;

        @media screen and (max-width: $msv-breakpoint-sm) {
            margin-bottom: 5px;
        }
    }

    .msc-rating {
        float: right;
        margin-top: $search-result-container-product-search-item-rating-margin-top;
        top: 14px;
        @media screen and (min-width: $dobbies-container-max-width-s) {
            margin-right: 70px;
            top: 0px;
        }
        &__count {
            display: none;
        }
    }

    .msc-price {
        margin-bottom: 10px;
        display: inline-block;

        @media screen and (max-width: $msv-breakpoint-sm) {
            margin-bottom: 5px;
        }

        .msc-price__actual {
            @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-m), $dobbies-line-height-l);
            color: var(--dobbies-font-primary-color);
            display: inline-block;
        }
        .msc-price__salePrice {
            .msc-price {
                &__actual {
                    color: var(--dobbies-sale-price-color);
                    margin-left: 8px;
                }
            }
        }
        .msc-price__strikethrough {
            text-decoration: line-through;
            font-size: var(--dobbies-body-font-size-m);
        }
    }

    &:focus {
        @include basic-outline-offset(
            $product-search-result_link__item-outline-width,
            $product-search-result_link__item-outline-style,
            $dobbies-black,
            $product-search-result_link__item-outline-offset
        );
        .msc-product__title {
            text-decoration: underline;
        }
    }

    &:hover {
        text-decoration: none;
        .msc-product__details {
            .msc-product__title {
                text-decoration: underline;
            }
        }
    }
}

.ms-search-result-container__category-hierarchy {
    @include vfi();
}

nav.ms-search-result-container__pagination {
    width: 100%;
    margin-bottom: 20px;
}

.msc-pagination {
    @include font-content(var(--msv-font-weight-normal), var(--msv-refiner-values-font-size), $dobbies-line-height-m);
    display: flex;
    list-style: none;
    padding-left: $search-result-container-pagination-padding-left;
    width: 100%;
}

ul.msc-pagination {
    display: flex;
    justify-content: center;
}

.previous.msc-page-item {
    margin-right: auto;

    .msc-pagination__prev {
        padding-left: 4px;
        display: inline-block;
    }

    .msc-page-link {
        color: var(--msv-search-result-pagination-color);
        font-size: var(--msv-search-result-pagination-size);
        display: block;
        padding: $search-result-container-page-item-page-link-padding;
        line-height: $search-result-container-page-item-page-link-line-height;

        &:before {
            @include msv-icon();
            content: '';
        }

        .ms-search-result__pagination-left {
            @include add-icon($msv-ChevronLeft, before);
        }
    }
}

.msc-page-item {
    padding-right: $search-result-container-page-item-padding-right;
}

.msc-page-item.disabled {
    .msc-page-link {
        // color: $dobbies-secondary !important;
        color: var(--msv-search-result-pagination-color);
        pointer-events: none;
        cursor: auto;
    }
}

.out-of-stock-container {
    display: flex;
    width: 25%;
    justify-content: space-between;
    padding-right: 5px;
    align-items: center;
    padding-bottom: 15px;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    padding-top: 15px;
    margin-bottom: 15px;
}

.out-of-stock-mob-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border-top: 1px solid #d8d8d8;
    padding-top: 10px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 10px;
}

.out-of-stock-btn-container {
    margin-left: 10px;
    display: flex;
}

.out-of-stock-active {
    background-color: black;
    border-color: black;
    color: white;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 65px;
}

.out-of-stock-hide {
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: grey;
    min-width: 65px;
}

.out-of-stock-text {
    margin-top: 5px;
}

.next.msc-page-item {
    margin-left: auto;

    .msc-pagination__next {
        padding-right: 4px;
        display: inline-block;
    }

    .msc-page-link {
        color: var(--msv-search-result-pagination-color);
        font-size: var(--msv-search-result-pagination-size);
        display: block;
        padding: $search-result-container-page-item-page-link-padding;
        line-height: $search-result-container-page-item-page-link-line-height;
        padding-right: 4px;

        &:after {
            @include msv-icon();
            content: '';
        }

        .ms-search-result__pagination-right {
            @include add-icon($msv-ChevronRight, after);
        }
    }
}

.msc-page-link {
    color: var(--msv-search-result-pagination-color);
    font-size: var(--msv-search-result-pagination-size);
    display: block;
    padding: $search-result-container-page-item-page-link-padding;
    line-height: $search-result-container-page-item-page-link-line-height;
    @include vfi();
}

.active {
    text-decoration: underline;
}

.prev-text,
.next-text {
    padding: $search-result-prev-next-padding;
}
@media (max-width: $msv-breakpoint-xl) {
    .ms-search-result-container {
        &__category-hierarchy {
            margin-bottom: 15px;
            margin-top: 15px;
            a:last-child {
                font-weight: var(--dobbies-font-weight-bold);
            }
        }
    }
}

.ms-search-result-container {
    &__category-hierarchy {
        @media (max-width: $dobbies-container-max-width-m) {
            // display: none; Commented for the New Filters UI
        }
    }
}

@media (max-width: $dobbies-container-max-width-s) {
    .ms-search-result-container {
        .ms-search-result-container__category-hierarchy {
            display: none;
        }

        .msc-button {
            background: none;
            border-style: none;
            float: right;
            font-weight: var(--dobbies-font-weight-bold);
        }

        .msc-button:hover {
            text-decoration: underline;
        }

        .ms-product-search-result__item {
            display: inline-block;
            vertical-align: top;
        }
    }

    .ms-search-result-container__pagination {
        margin-bottom: $search-result-container-pagination-margin-bottom-mobile;
    }

    .ms-search-result-container__Products {
        margin-bottom: $search-result-container-products-margin-bottom-mobile;
    }

    .prev-text,
    .next-text {
        display: none;
    }
}

.dobbies-modal {
    .msc-modal__header {
        border: 0;
        text-align: right;
        justify-content: flex-end;
        .msc-modal__close-button {
            position: static;
            &:before {
                @include dobbies-icon();
                content: $dobbies-icon-close;
                font-size: var(--dobbies-icon-font-size-l);
            }
        }
    }
}

// modal
.msc-search-result-modal {
    .msc-modal__header {
        padding: 10px 0;
        margin: 0 0 10px;
        @media (max-width: $msv-breakpoint-m) {
            padding: 10px 15px;
        }
        .msc-modal__title {
            font-weight: var(--dobbies-font-weight-bold);
            line-height: 30px;
        }
    }

    .msc-modal__content {
        padding: $search-result-modal-content-padding;
        @media (max-width: $msv-breakpoint-m) {
            padding: $search-result-modal-content-padding-mobile;
        }
    }

    .msc-review-modal-body {
        height: $search-result-modal-body-height;
        overflow-y: auto;
        @media (max-width: $msv-breakpoint-m) {
            height: $search-result-modal-body-height-mobile;
        }
    }
    .msc-modal__footer {
        position: absolute;
        width: 100%;
        bottom: -48px;
        left: 0;
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        color: var(--dobbies-font-secondary-color);
        background-color: var(--dobbies-bg-color-primary);
        border-color: var(--dobbies-green-border-color);
        min-width: $search-result-modal-footer-button-min-width;
    }

    .ms-search-result-container__refiner-section {
        display: block;
        padding-right: $search-result-modal-refiner-section-padding-right;
        width: 100%;
    }
}

.ms-search-result-container {
    &__title {
        font-size: var(--msv-search-result-font-size);
        > * {
            margin-bottom: 10px;
            color: var(--msv-search-result-font-color);
            @media (max-width: $msv-breakpoint-l) {
                text-align: center;
                float: none;
                padding: 15px 0;
                border-bottom: solid 2px var(--dobbies-gray-border-color);
                margin-bottom: 15px;
            }
        }



        .ms-search-result__collection-title-prefix,
        .ms-search-result__collection-title-text {
            @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-heading-font-size), $dobbies-line-height-xl);
            color: var(--msv-search-result-heading-font-color);
        }
        .ms-search-result__collection-title-prefix {
            display: none;
        }
        .ms-search-result__collection-title-count {
            @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-font-size), $dobbies-line-height-m);
            color: var(--msv-search-result-font-color);
        }
    }

    .msc-button {
        @include font-content(var(--dobbies-font-weight-bold), var(--msv-search-result-sort-font-size), $dobbies-line-height-m);
        color: var(--msv-search-result-sort-font-color);
    }
}

@media (max-width: $msv-breakpoint-l) {
    .ms-search-result-container__Sort-by-category {
        float: none;
        margin: 0 0 10px;
        position: relative;
        top: $search-result-container-sort-by-category-top-mobile;
        right: auto;
        z-index: 1;
        background-color: var(--dobbies-bg-color);

        .msc-l-dropdown__label {
            margin-bottom: 5px;
            display: inline-block;
        }

        .msc-l-dropdown {
            width: 100%;
        }
    }

    .ms-search-result-container {
        padding-left: $search-result-container-padding-left-mobile;
        padding-right: $search-result-container-padding-right-mobile;
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        width: 100%;
    }
}
.ms-search-result-container {
    .ms-feature-search-result {
        .ms-feature-search-result__item {
            display: flex;
            height: auto;
            padding-bottom: 10px;

            .msc-feature-search-result__item__image {
                flex-grow: 1;
                height: auto;
                width: 315px;
            }
            .msc-feature-search-result__item__detail {
                flex-grow: 1;
                height: auto;
                position: relative;
                padding-left: 8%;
                text-align: left;
                width: 100%;
            }
            .msc-feature-search-result__item__detail h1 {
                @include font-content(var(--msv-font-weight-light), var(--msv-feature-title-desktop-size), $dobbies-line-height-xxl);
                color: var(--msv-feature-title-color);
                margin: 0;
                padding: 0;
                position: relative;
                top: 30%;
                margin-bottom: 8px;
            }
            .msc-feature-search-result__item__detail p {
                @include font-content(var(--dobbies-font-weight-bold), var(--msv-feature-heading-font-size), $dobbies-line-height-l);
                color: var(--msv-feature-heading-color);
                margin: 0;
                margin-bottom: 8px;
                padding: 0;
                position: relative;
                top: 30%;
            }
        }
    }
    @media (max-width: $msv-breakpoint-l) {
        .ms-feature-search-result {
            .ms-feature-search-result__item {
                .msc-feature-search-result__item__detail {
                    p,
                    h1 {
                        top: 20%;
                    }
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-feature-search-result {
            .ms-feature-search-result__item {
                display: block;

                .msc-feature-search-result__item__image,
                .msc-feature-search-result__item__detail {
                    width: 100%;
                }

                .msc-feature-search-result__item__image {
                    margin-bottom: 10px;
                }

                .msc-feature-search-result__item__detail {
                    padding-left: unset;

                    h1 {
                        @include font-content(var(--msv-font-weight-light), var(--msv-feature-title-mobile-size), $dobbies-line-height-xl);
                    }
                }
            }
        }
    }
}

.msc-price {
    .msc-price__actual {
        @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-m), $dobbies-line-height-l);
        color: var(--dobbies-font-primary-color);
        display: inline-block;
    }
    .msc-price__salePrice {
        .msc-price {
            &__actual {
                color: var(--dobbies-sale-price-color);
                margin-left: 8px;
            }
        }
    }
    .msc-price__strikethrough {
        text-decoration: line-through;
        font-size: var(--dobbies-body-font-size-m);
    }
}
@media (max-width: $dobbies-container-max-width-s) {
    .msc-modal__dialog.dobbies-modal {
        max-width: 100%;
        .msc-modal__content {
            background-color: var(--dobbies-bg-color);
        }
    }
}

.msc-tooltip-inner {
    color: $dobbies-grey;
}

.refiner-add-remove {
    overflow-y: auto !important;
}

.new-filter {

    &-ms-search-result-container {
        .category-list-mobile,
        #search-result-modal {
            display: none;
        }

        & + .category-banner {
            margin: 0;
            margin-top: 20px;
            .ms-content-block__details {
                .ms-content-block__title {
                    margin: 20px 0;
                }
            }
            .ms-content-block__text {
                p {
                    margin-bottom: 0;
                }
            }
        }

        .ms-search-result-container__title {
            text-align: center;
            border-bottom: 0;
            padding-top: 20px;
            > * {
                margin-bottom: 0;
                border-bottom: none;
                @media (max-width: $dobbies-container-max-width-s) {
                    border-bottom: 0;
                }
            }
        }

        .ms-search-result-container__Sort-by-category {
            width: 211px;
            padding: 0;
            margin: 20px auto 0;
            display: flex;
            
            @media screen and (min-width: $dobbies-container-max-width-s) {
                float: right;
                width: auto;
                display: initial;
                margin-bottom: 20px;
            }
            &:after {
                top: 3px;
                right: 15px;
                font-size: 12px;
                margin-top: 2px;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    top: 4px;
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    top: 5.5px;
                    margin-top: 0;
                }
            }
            .msc-l-dropdown {
                width: 145px;
                height: 20px;
                min-width: auto;
                font-size: 12px;
                padding: 0 7px;
                line-height: 15px;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    width: 150px;
                }
                &__label {
                    margin-right: 7px;
                    margin-bottom: 0;
                    font-size: 14px;
                }
            }
        }

        .msc-choice-summary {
            margin-top: 20px;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                width: calc(100% - 335px);
                float: left;
            }

            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                width: calc(100% - 300px);
                &::after {
                    top: 13.5px;
                }
            }

            &__item {
                padding-left: 6px;
                line-height: 18.4px;
            }
        }

        .ms-search-result-container__category-hierarchy {
            margin: 20px 0 0;
            display: flex;
            align-items: center;

            @media (max-width: $dobbies-container-max-width-s) {
                padding: 0 30px;
            }
            a {
                font-size: $dobbies-font-size-s;

                @media screen and (max-width: $msv-breakpoint-mbl-sm) {
                    font-size: $dobbies-font-size-xs;
                }
            }

            span {
                font-size: 0;
                display: flex;
                align-items: center;

                &::after {
                    @include dobbies-icon();
                    content: $dobbies-icon-arrow;
                    transform: rotate(90deg);
                    color: $dobbies-yellow;
                    position: relative;
                    font-size: 11px;
                    top: 0px;
                    margin: 0 5px;
                }
            }
        }
    }

    &__category-nav-section {
        padding: 0;
        float: none;
        width: 100%;
        > h1 {
            text-align: center;
        }
        > button {
            display: none;
        }
        @media screen and (min-width: $dobbies-container-max-width-s) {
            float: none;
            width: auto;
            margin: 0;
        }

        > .ms-search-result-container__title {
            padding: 20px 0 0;
        }
    }

    &__refine-product-section {
        display: block;
        overflow: initial;

        .ms-search-result-container__product-section {
            width: 100%;
        }
        > button {
            display: none;
        }

        .ms-search-result-container__Products {
            padding-top: 0;
            margin-top: 10px;
            .list-unstyled {
                display: grid;
                grid-column-gap: 26px;
                grid-template-columns: repeat(auto-fill, minmax(339px, 1fr));

                @media screen and (min-width: $dobbies-container-max-width-s) {
                    grid-template-columns: repeat(auto-fill, minmax(234px, 1fr));
                    grid-column-gap: 17px;
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
                    grid-column-gap: 26px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    grid-template-columns: repeat(auto-fill, minmax(339px, 1fr));
                }
            }
        }

        .ms-product-search-result__item {
            .msc-product__image img,
            .msc-empty_image {
                width: 100%;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    height: 328px;
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    height: 457px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    height: 475px;
                }
            }

            .msc-product__image {
                .badges__icon {
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .msc-choice-summary {
            .msc-choice-summary__item {
                line-height: 18px;
            }

            &__glyph {
                margin-right: 7px;
                margin-left: 12px;
            }
        }

        .msc-add-to-wishlist {
            display: flex;
            align-items: center;
        }

        .category-landing__product-carousel {
            padding: 0 9px;
        }
    }

    &__refiner-section {
        width: 100%;
        padding: 0;
        background-color: $dobbies-white;

        &.scrolled {
            position: fixed;
            top: 0;
            z-index: 999;
            max-width: calc(100% - 28px);

            .category-listing {
               > ul {
                    border-top: none;
                }
            }
            @media screen and (min-width: $msv-container-min-width-m) {
                max-width: calc(100% - 44px);
            }
            @media screen and (min-width: $msv-container-max-width-m) {
                max-width: calc(100% - 40px);
            }
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                max-width: 1028px;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                max-width: 1436px;
                top: 49px;
            }
        }

        .ms-refine-submenu__list {
            li.ms-refine-submenu-item {
                position: relative;
                &:not(.noColour) {
                    &::after {
                        content: '';
                        width: 15px;
                        height: 15px;
                        border-radius: 100%;
                        position: absolute;
                        background-color: $msv-gray-940;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
    
                &.blue {
                    &::after {
                        background-color: $dobbies-refiner-blue;
                    }
                }
                &.cream {
                    &::after {
                        background-color: $dobbies-refiner-cream;
                    }
                }
                &.green {
                    &::after {
                        background-color: $dobbies-refiner-green;
                    }
                }
                &.yellow {
                    &::after {
                        background-color: $dobbies-refiner-yellow;
                    }
                }
                &.orange {
                    &::after {
                        background-color: $dobbies-refiner-orange;
                    }
                }
                &.red {
                    &::after {
                        background-color: $dobbies-refiner-red;
                    }
                }
                &.purple {
                    &::after {
                        background-color: $dobbies-refiner-purple;
                    }
                }
                &.pink {
                    &::after {
                        background-color: $dobbies-refiner-pink;
                    }
                }
                &.black {
                    &::after {
                        background-color: $dobbies-refiner-black;
                    }
                }
                &.natural {
                    &::after {
                        background-color: $dobbies-refiner-natural;
                    }
                }
                &.gold {
                    &::after {
                        background-color: $dobbies-refiner-gold;
                    }
                }
                &.brown {
                    &::after {
                        background-color: $dobbies-refiner-brown;
                    }
                }
                &.silver {
                    &::after {
                        background-color: $dobbies-refiner-silver;
                    }
                }
                &.grey {
                    &::after {
                        background-color: $dobbies-refiner-grey;
                    }
                }
                &.beige {
                    &::after {
                        background-color: $dobbies-refiner-beige;
                    }
                }
                &.bronze {
                    &::after {
                        background-color: $dobbies-refiner-bronze;
                    }
                }
                &.copper {
                    &::after {
                        background-color: $dobbies-refiner-copper;
                    }
                }
                &.clear {
                    &::after {
                        border: 1px solid #989898;
                        background-color: $dobbies-refiner-clear;
                    }
                }
                &.white {
                    &::after {
                        border: 1px solid #DEDEDE;
                        background-color: $dobbies-white;
                    }
                }

                &.multi-colour {
                    &::after {
                       background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MDeqXF?pubver=0) center center no-repeat;
                    }
                }
                &.terracotta {
                    &::after {
                        background-color: $dobbies-refiner-terracotta;
                    }
                }
                &.ivory {
                    &::after {
                        border: 1px solid #DEDEDE;
                        background-color: $dobbies-refiner-ivory;
                    }
                }
            }
        }

        .category-listing {

            &-title {
                text-align: center;
            }

            & >ul>li {
                &:last-child>ul{
                    &.show-more-option > li {
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a.browse-active-link {
                            background: transparent;
                        }
                    }
                }
            }
        }
        .ms-search-result-container__refine-menu > ul,
        .category-listing > ul {
            display: flex;
            justify-content: center;
            padding-top: 20px;
            margin: 0;

            @media screen and (max-width: $dobbies-container-max-width-s) {
                margin: 0 -10px;
            }

            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                position: relative;
            }
            > li {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                position: relative;
                padding: 0 20px;
                min-width: 150px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    padding: 0 19px;
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    min-width: 160px;
                    padding: 0 37px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    min-width: 250px;
                }
                &::after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 12px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-right: 1px solid $dobbies-gray-100;
                }
                &:last-child {
                    position: relative;
                    border: none;
                    @media screen and (min-width: 700px) {
                        min-width: 190px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-xl) {
                        min-width: 340px;
                    }
                    @media screen and (max-width: 699px) {
                        position: static;
                    }
                    > button {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                    &::after {
                        display: none;
                    }
                    > ul {
                        position: absolute;
                        background: $dobbies-white;
                        width: 100%;
                        left: 0;
                        top: 25px;
                        padding: 30px;
                        z-index: 999;
                        display: block;
                        box-shadow: 0px 4px 6px #00000029;
                        max-height: none;
                        @media screen and (min-width: 700px) {
                            min-width: 190px;
                            padding: 20px 24px 20px 27px;
                        }
                        @media screen and (min-width: $dobbies-container-max-width-tablet) {
                            padding: 31px 24px 26px 27px;
                            min-width: 236px;
                        }
                        @media screen and (min-width: $dobbies-container-max-width-xl) {
                            padding: 30px;
                        }

                        @media screen and (max-width: 699px) {
                            margin: 0 -2px;
                            width: 100vw;
                            top: 40px;
                            padding: 15px;
                        }
                        &.hide-more-option {
                            display: none;
                        }
                        li {
                            margin-bottom: 0px;
                            height: auto;
                            a {
                                font-size: $dobbies-font-size-s;
                                font-weight: normal;
                                text-align: left;
                                line-height: 16px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        > li {
                            @media screen and (min-width: 700px) {
                                margin-bottom: 15px;
                            }
                            @media screen and (max-width: 699px) {
                                > a {
                                    font-weight: bold;
                                    padding: 15px 0;
                                    border-top: 1px solid $dobbies-grey;
                                    line-height: 18px;
                                }

                                &:last-child {
                                    > a {
                                        padding-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                
                > a {
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    @media screen and (max-width: 480px) {
                        max-width: 100px;
                    }
                    @media screen and (max-width: 420px) {
                        max-width: 90px;
                    }
                    @media screen and (max-width: 400px) {
                        max-width: 80px;
                    }
                }

                
                &.category-heading,
                &:only-child {
                    > a {
                        @media screen and (max-width: 480px), screen and (max-width: 420px), screen and (max-width: 400px) {
                            max-width: 180px;
                        }
                    }
                }
            }
            li {
                font-size: $dobbies-font-size-m;
                border-top: none;
                line-height: 16px;
                text-align: center;
                .dropdown-icon {
                    margin-left: 10px;
                    display: inline-block;
                    &::after {
                        @include dobbies-icon();
                        content: $dobbies-icon-arrow;
                        font-size: var(--dobbies-icon-font-size-s);
                        transform: rotate(180deg);
                        color: $dobbies-yellow;
                        position: relative;
                        top: 2px;
                        cursor: pointer;
                        @media screen and (max-width: $dobbies-container-max-width-tablet) {
                            font-size: 12px;
                            top: -1px;
                        }
                    }
                    &:has(+ .cat-dropdown-show) {
                        &::after {
                            top: 0px;
                            transform: rotate(0deg);
                            @media screen and (min-width: $dobbies-container-max-width-s) {
                                top: -2px;
                            }
                        }
                    }
                }
                a, button {
                    padding: 0;
                    border: none;
                    text-align: center;
                    font-size: 12px;
                    font-weight: $dobbies-font-weight-bold;

                    @media screen and (min-width: $msv-container-max-width-s) {
                        font-size: 14px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        font-size: 16px;
                    }
                    &:not(:disabled):not(.disabled):active,
                    &:focus {
                        outline: none;
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                        color: $dobbies-grey;
                    }
                    &.more-subcategories {
                        font-weight: normal;
                        background: none;
                        color: $dobbies-grey;
                        line-height: normal;
                        position: relative;
                        bottom: 1px;
                        @media screen and (max-width: $msv-container-max-width-s) {
                            font-size: $dobbies-font-size-s;
                        }

                        &::after {
                            @include dobbies-icon();
                            content: $dobbies-icon-arrow;
                            font-size: var(--dobbies-icon-font-size-s);
                            transform: rotate(180deg);
                            color: $dobbies-yellow;
                            position: relative;
                            top: 4px;
                            left: 15px;
                            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                                font-size: 12px;
                                right: -7px;
                                left: auto;
                                top: 2px;
                            }
                        }

                        &:has(+ .show-more-option) {
                            &::after {
                                top: 2px;
                                transform: rotate(0deg);
                            }
                        }
                    }
                    &:hover {
                        background-color: transparent;
                        color: $dobbies-grey;
                        text-decoration: underline;
                    }
                }
                
                &:only-child {
                    a, button {
                        &.more-subcategories {
                            font-weight: $dobbies-font-weight-bold;
                        }
                    }
                }
            }
        }

        .refiner-add-remove {
            height: auto;
            overflow: auto;
        }

        + div {
            width: 100%;
        }

        .refiner-add-remove {
            overflow: initial !important;
            z-index: 99;
        }

        .list-group-item {
            &.refine-submenu__item--range {
                background: transparent;
                padding: 0;
                padding-top: 8px;
            }
        }

        .ms-refine-submenu.list-group {
            .multi-select {
                span.ms-refine-submenu-item__label {
                    @media screen and (min-width: $msv-container-max-width-s) {
                        font-size: 12px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        font-size: 14px;
                    } 
                }
            }
        }

        .ms-search-result__collection-title-text {
            display: block;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    &__filter-section {
        display: flex;
        border: 1px solid $dobbies-black;
        margin-top: 20px;

        @media screen and (max-width: $msv-container-max-width-s) {
            display: block;
            padding: 12px 15px;
            padding-bottom: 0;
            background-color: $dobbies-header-grey;
        }
        @media screen and (min-width: $msv-container-max-width-s) {
            padding: 0;
            display: flex;
        }
        .filter-list {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            background-color: $dobbies-header-grey;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                border-right: none;
            }
            .ms-refine-submenu.list-group {
                width: 100%;
                border-radius: 0;
                position: relative;
                border-bottom: 1px solid $dobbies-navigation-border-color;

                @media screen and (min-width: $msv-container-max-width-s) {
                    max-width: 25%;
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    max-width: 177.5px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    max-width: 259px;
                }
                &:first-child {
                    button.ms-refine-submenu__toggle_collapsed, .ms-refine-submenu__toggle_expanded {
                        @media screen and (max-width: $msv-container-max-width-s) {
                            padding-top: 0;
                        }
                    }
                }
                
                &:last-child {
                    button.ms-refine-submenu__toggle_collapsed, .ms-refine-submenu__toggle_expanded {
                        border: none;

                        @media screen and (max-width: $msv-container-max-width-s) {
                            padding: 12px 0 10px;
                            &::before {
                                display: none;
                            }
                        }
                        @media screen and (min-width: $msv-container-max-width-s) {
                            padding-left: 15px;
                        }
                    }
                }

                @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                    &:nth-child(4n+4) {
                        button.ms-refine-submenu__toggle_collapsed, .ms-refine-submenu__toggle_expanded {
                            &::before {
                                opacity: 0;
                            }
                        }
                    }
                }
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    &:nth-child(-n+4) {
                        border-top: none;
                        padding-top: 0;
                        margin: 0;
                    }
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    &:nth-child(5n+5) {
                        button.ms-refine-submenu__toggle_collapsed, .ms-refine-submenu__toggle_expanded {
                            &::before {
                                opacity: 0;
                            }
                        }
                    }
                    &:nth-child(-n+5) {
                        border-top: none;
                        padding-top: 0;
                        margin: 0;
                    }
                }

                .slide-down {
                    overflow-y: auto;
                    z-index: 999;
                    @media screen and (max-width: $msv-container-max-width-s) {
                        max-height: none;
                    }
                    @media screen and (min-width: $msv-container-max-width-s) {
                        max-height: 258px;
                    }
                }

                .slide-down,
                .slide-up {
                    position: absolute;
                    top: 48px;
                    padding-top: 20px;
                    background: $dobbies-header-grey;
                    width: 100%;
                    left: 0;
                    transition: none;

                    @media screen and (max-width: $msv-container-max-width-s) {
                        position: static;
                        padding-top: 18px;
                    }
                    @media screen and (min-width: $msv-container-max-width-s) {
                        position: absolute;
                        padding-top: 20px;
                    }

                    .ms-refine-submenu__list {
                        border-top: 0;
                        padding: 0 10px;
                        padding-bottom: 18px;
                        @media screen and (max-width: $msv-container-max-width-s) {
                            padding-bottom: 15px;
                        }
                    }
                }

                .slide-up {
                    padding: 0;
                }
            }

            &.filter-less {
                .ms-refine-submenu.list-group {
                    border-bottom: none;
                    @media screen and (max-width: $msv-container-max-width-s) {
                        border-bottom: 1px solid $dobbies-navigation-border-color;
                    }
                    @media screen and (min-width: $msv-container-max-width-s) {
                        &:nth-last-child(-n+4) {
                            border-bottom: none;
                        }
                    }

                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        &:nth-last-child(-n+5) {
                            border-bottom: none;
                        }
                    }
                }
            }

            button.ms-refine-submenu__toggle_collapsed, .ms-refine-submenu__toggle_expanded {
                border: none;
                padding: 0;
                display: flex;
                text-align: left;
                justify-content: start;
                align-items: center;
                padding-left: 8px;
                font-size: 12px;
                height: auto;
                position: relative;

                @media screen and (max-width: $msv-container-max-width-s) {
                    padding: 12px 0 10px;
                    height: auto;
                    justify-content: space-between;
                    align-items: center;
                    &::before {
                        display: none;
                    }
                }
                @media screen and (min-width: $msv-container-max-width-s) {
                    height: 47px;
                    padding: 0;
                    padding-left: 15px;
                    &::before {
                        display: block;
                    }
                }

                &::after {
                    margin-left: 25px;
                    margin-top: 0;
                    font-size: 14px;
                    color: $dobbies-black;

                    @media screen and (min-width: $msv-container-max-width-s) {
                        right: 12px;
                        position: absolute;
                        margin-right: 0;
                    }

                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        position: static;
                    }

                    @media screen and (max-width: $msv-container-max-width-s) {
                        margin: 0;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 28px;
                    background: $dobbies-navigation-border-color;
                    top: 50%;
                    right: 0.1px;
                    transform: translateY(-50%);
                }
            }

            .ms-refine-submenu__toggle_expanded {
                @media screen and (max-width: $msv-container-max-width-s) {
                    background: transparent;
                }
                @media screen and (min-width: $msv-container-max-width-s) {
                    background: $dobbies-white;
                }
            }

            &:last-child {
                button.ms-refine-submenu__toggle_collapsed, .ms-refine-submenu__toggle_expanded {
                    border: none;
                }
            }
        }

        .more-filters-btn {
            background: none;
            color: $dobbies-grey;
            border: none;
            border-left: 1px solid $dobbies-black;
            border-radius: 0;
            padding: 0;
            width: 137px;
            outline: none;
            font-size: $dobbies-font-size-s;
            background-color: $dobbies-header-grey;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            @media screen and (max-width: $msv-container-max-width-s) {
                margin: 5px auto;
                font-size: $dobbies-font-size-xs;
                border: none
            }
            @media screen and (min-width: $msv-container-max-width-s) {
                border-left: 1px solid $dobbies-black;
                font-size: $dobbies-font-size-s;
                margin: 0;
            }
            
            span {
                width: 23px;
                height: 23px;
                border: 1px solid $dobbies-grey;
                border-radius: 100%;
                margin-left: 10px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &::before {
                    content: '';
                    width: 14px;
                    height: 1px;
                    border-bottom: 1px solid $dobbies-grey;
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 14px;
                    border-right: 1px solid $dobbies-grey;
                    right: 45%;
                    transform: translateX(-50%);
                }
            }


            &.filter-minus {
                span {
                    &::after {
                        display: none;
                    }
                }
            }

            &:not(:disabled):not(.disabled):active,
            &:focus:not(:focus-visible) {
                background-color: transparent;
                outline: none;
                color: $dobbies-grey;
                box-shadow: none;
            }
        }
    }

    &__clp {
        .ms-content-block.category-banner {
            margin-bottom: 0px;
            margin-top: 20px;

            .ms-content-block__image {
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    margin-bottom: 0;
                }
            }
        }
        .mobile-category-listing {
            margin-top: 0;
        }
        .category-listing-title {
            padding-bottom: 0;
        }
        .category-list-mobile {
            margin-top: 0;
            button {
                height: 25px;
                width: 103px;
                font-size: 12px;
                padding: 0px 16px 0px 8px;
            }
        }
        .category-listing>ul {
            border-top: none;
            >li {
            
                > ul {
                    position: absolute;
                    background-color: $dobbies-white;
                    width: 100%;
                    left: 0;
                    top: 25px;
                    padding: 20px 16px 25px 18px;
                    z-index: 999;
                    display: block;
                    max-height: 258px;
                    overflow-y: auto;
                    z-index: 999;
                    box-shadow: 0px 4px 6px #00000029;
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        top: 40px;
                        width: 99vw;
                        left: -11px;
                    }
                    &.cat-dropdown-hide {
                        display: none;
                    }
                    &.cat-dropdown-show {
                        display: block;
                    }
    
                    li {
                        margin-bottom: 18px;
                        line-height: 18px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            font-size: 14px;
                            font-weight: normal;
                            text-align: left;
                            line-height: 18px;
                        }
                    }
                }
                &:last-child {
                    @media screen and (min-width: $dobbies-container-max-width-xl) {
                        min-width: 280px;
                    }

                    @media screen and (max-width: $dobbies-container-max-width-tablet) {
                        position: static;
                    }
    
                   > ul {
                        &.show-more-option {
                            padding: 14.5px;
                        }
                        @media screen and (max-width: $dobbies-container-max-width-tablet) {
                            top: 40px;
                            width: 99vw;
                            margin: 0;
                            left: -12px;
                        }

                        &#more-cats-div {
                            > li {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-wrap: wrap;
                                position: relative;

                                > a {
                                    width: 100%;
                                }

                                ul {
                                    width: 100%;
                                }
                            }
                            .dropdown-icon {
                                position: absolute;
                                right: 0px;
                                top: 16px;
                                z-index: 999;
                            }
                        }

                        > li {
                            margin-bottom: 18px;
                            &.cat-parent {
                                margin-bottom: 0px;
                                > a {
                                    font-weight: bold;
                                    padding: 15px 0;
                                    border-top: 1px solid $dobbies-grey;
                                    line-height: 18px;
                                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                                        padding-right: 17px;
                                    }
                                }
                                ul {
                                    display: none;
                                    padding-top: 15px;
                                    border-top: 1px solid $dobbies-grey;
                                    li {
                                        margin-bottom: 15px;
                                        a {
                                            line-height: 20px;
                                        }
                                    }
                                }

                                .toggle-hide {
                                    &:last-child {
                                        a {
                                            border-bottom: 1px solid $dobbies-grey;
                                        }
                                    }
                                }

                                &:last-child {
                                    > a {
                                        padding-bottom: 0;
                                    }
                                    ul li {
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }

                            &.cat-toggle-show {
                                &:last-child {
                                    > a {
                                        padding-bottom: 15px;
                                    }
                                }
                                ul {
                                    display: block;
                                }

                                .dropdown-icon {
                                    &::after {
                                        transform: rotate(0deg)
                                    }
                                }
                            }

                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}