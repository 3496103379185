.select_c-select__1XKtQ {
    background-color: #fff;
    border: 1px solid #6b6b6b;
    border-radius: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #141414;
    font-family: inherit;
    font-size: 16px;
    font-weight: 200;
    height: 48px;
    letter-spacing: .1px;
    padding: 0 12px;
    -webkit-transition: border-color .4s;
    -moz-transition: border-color .4s;
    transition: border-color .4s;
    width: 100%
}

.select_c-select__1XKtQ:focus {
    border-color: #000;
    -webkit-box-shadow: inset 0 0 0 1px #000;
    box-shadow: inset 0 0 0 1px #000;
    outline: none
}

[disabled].select_c-select__1XKtQ {
    background-color: #f2f2f2;
    color: #d8d8d8
}

[readonly].select_c-select__1XKtQ {
    background-color: #f2f2f2;
    color: #6b6b6b
}

[class~=error].select_c-select__1XKtQ {
    border-color: #db003e
}

[class~=error].select_c-select__1XKtQ:focus {
    border-color: #db003e;
    -webkit-box-shadow: inset 0 0 0 1px #db003e;
    box-shadow: inset 0 0 0 1px #db003e
}

.select_joyfully-bold__2cxZZ {
    font-weight: 500;
    padding-top: 2px;
    color: #141414;
    background-color: #fff;
    border: 1px solid grey;
    border-radius: 2px
}

.select_joyfully-bold__2cxZZ:hover {
    border-color: #141414;
    border-radius: 4px;
    -webkit-transition: border-radius .12s ease-out, border-color .12s;
    -moz-transition: border-radius .12s ease-out, border-color .12s;
    transition: border-radius .12s ease-out, border-color .12s
}

.select_joyfully-bold__2cxZZ:focus {
    border-color: #141414;
    -webkit-box-shadow: inset 0 0 0 1px #141414;
    box-shadow: inset 0 0 0 1px #141414;
    outline: none;
    border-radius: 4px
}

[disabled].select_joyfully-bold__2cxZZ {
    color: #ccc;
    background-color: #f2f2f2;
    border: none
}

[class~=error].select_joyfully-bold__2cxZZ {
    border-color: #cc1427
}

[class~=error].select_joyfully-bold__2cxZZ:focus {
    border-color: #cc1427;
    -webkit-box-shadow: inset 0 0 0 1px #cc1427;
    box-shadow: inset 0 0 0 1px #cc1427
}

.select_c-select__1XKtQ {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(https://storage.googleapis.com/jl-design-system-assets-prod/2.4.0/icons/regular/chevron-down-regular.svg);
    background-position: right 16px center;
    margin: 0;
    min-width: 100px;
    padding-right: 40px
}

.select_c-select__1XKtQ,
.select_c-select__1XKtQ[disabled] {
    background-size: auto auto;
    background-size: initial;
    background-repeat: no-repeat
}

.select_c-select__1XKtQ[disabled] {
    background-image: url(https://storage.googleapis.com/jl-design-system-assets-prod/2.4.0/icons/regular/chevron-down-regular-mid-grey.svg)
}

.select_c-select__1XKtQ::-ms-expand {
    display: none
}

.select_c-select__1XKtQ:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #141414
}

@-moz-document url-prefix() {
    .select_c-select__1XKtQ {
        text-indent: -2px
    }
}

.select_c-select__1XKtQ option:not(:checked) {
    color: #141414
}

.select_joyfully-bold__2cxZZ,
.select_joyfully-bold__2cxZZ[disabled] {
    background-size: 100%;
    background-image: url(https://storage.googleapis.com/jl-design-system-assets-prod/2.4.0/icons-jb/chevron-down/chevron-down-24px-outlined.svg);
    background-repeat: no-repeat;
    background-size: 24px
}

.select_proto_c-select__2b3cp {
    background-color: #fff;
    border: 1px solid #6b6b6b;
    border-radius: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #141414;
    font-family: inherit;
    font-size: 16px;
    font-weight: 200;
    height: 48px;
    letter-spacing: .1px;
    padding: 0 12px;
    -webkit-transition: border-color .4s;
    -moz-transition: border-color .4s;
    transition: border-color .4s;
    width: 100%
}

.select_proto_c-select__2b3cp:focus {
    border-color: #000;
    -webkit-box-shadow: inset 0 0 0 1px #000;
    box-shadow: inset 0 0 0 1px #000;
    outline: none
}

[disabled].select_proto_c-select__2b3cp {
    background-color: #f2f2f2;
    color: #d8d8d8
}

[readonly].select_proto_c-select__2b3cp {
    background-color: #f2f2f2;
    color: #6b6b6b
}

[class~=error].select_proto_c-select__2b3cp {
    border-color: #db003e
}

[class~=error].select_proto_c-select__2b3cp:focus {
    border-color: #db003e;
    -webkit-box-shadow: inset 0 0 0 1px #db003e;
    box-shadow: inset 0 0 0 1px #db003e
}

.select_proto_c-select__2b3cp {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    min-width: 100px;
    padding-right: 40px
}

.select_proto_c-select__2b3cp,
.select_proto_c-select__2b3cp[disabled] {
    background-image: url(/auxiliary-pages-ui/_next/static/media/Down.29357cff04814a299af98cfb327bf803.svg);
    background-repeat: no-repeat;
    background-position: right 16px top 16px
}

.select_proto_c-select__2b3cp::-ms-expand {
    display: none
}

.select_proto_c-select__2b3cp:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #141414
}

@-moz-document url-prefix() {
    .select_proto_c-select__2b3cp {
        text-indent: -2px
    }
}

.select_proto_c-select__2b3cp option:not(:checked) {
    color: #141414
}

.Brands_brands__1jWtz {
    margin: 0 auto;
    width: 100%;
    max-width: 1232px
}

.Brands_brands__1jWtz h1 {
    color: #141414;
    line-height: 1.25rem;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0
}

.Brands_brands__1jWtz a {
    text-decoration: none
}

.Brands_brands-container__WtThQ {
    -webkit-align-self: flex-start;
    align-self: flex-start;
    -webkit-order: 2;
    -moz-box-ordinal-group: 3;
    order: 2;
    position: relative;
    right: 0;
    text-align: center
}

@media only screen and (min-width:768px) {
    .Brands_brands-container__WtThQ {
        position: relative;
        right: inherit;
        -webkit-order: 1;
        -moz-box-ordinal-group: 2;
        order: 1;
        -webkit-align-self: auto;
        align-self: auto;
        height: 148px
    }
}

.Brands_brands--header__3rhEO {
    max-width: 1232px;
    background: #fff;
    padding-bottom: 16px;
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    -webkit-transition: -webkit-transform .25s ease 0s;
    transition: -webkit-transform .25s ease 0s;
    -moz-transition: transform .25s ease 0s, -moz-transform .25s ease 0s;
    transition: transform .25s ease 0s;
    transition: transform .25s ease 0s, -webkit-transform .25s ease 0s, -moz-transform .25s ease 0s
}

.Brands_brands--header-title__3poMy {
    border-bottom: 1px solid #d8d8d8;
    padding: 31px 32px 29px
}

.Brands_brands--header-breadcrumbs__2qZdP {
    text-align: left
}

.Brands_brands--header-sort__2_hsq {
    padding: 31px 32px 15px
}

@media only screen and (max-width:768px) {
    .Brands_brands--header-sort__2_hsq {
        padding: 31px 0 15px
    }
}

.Brands_brands--header-sort-select__8PKBj {
    width: 340px
}

@media only screen and (max-width:768px) {
    .Brands_brands--header-sort-select__8PKBj {
        width: -webkit-calc(100% - 48px);
        width: -moz-calc(100% - 48px);
        width: calc(100% - 48px)
    }
}

.Brands_brands--header-filters__lspAk {
    list-style: none;
    margin: 0;
    -webkit-transition: -webkit-transform .25s ease 0s;
    transition: -webkit-transform .25s ease 0s;
    -moz-transition: transform .25s ease 0s, -moz-transform .25s ease 0s;
    transition: transform .25s ease 0s;
    transition: transform .25s ease 0s, -webkit-transform .25s ease 0s, -moz-transform .25s ease 0s;
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 12px 0 32px;

    & + .Brands_brands--section__1MCLm,
    & + .see-all-brands-btn {
        text-align: center;
        margin-top: 24px;
        margin-bottom: 20px;
        .see-all-brands {
            text-align: center;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media only screen and (max-width:629px) {
    .Brands_brands--header-filters__lspAk {
        padding-right: 10%;
        padding-left: 10%
    }
}

@media only screen and (max-width:360px) {
    .Brands_brands--header-filters__lspAk {
        padding-right: 2%;
        padding-left: 2%
    }
}

.Brands_brands--header-filters__lspAk li {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0 6px;
    text-align: center
}

ul.Brands_brands--header-filters__lspAk {
    padding: 30px;
}

@media only screen and (max-width:629px) {
    .Brands_brands--header-filters__lspAk li {
        font-size: .75rem;
        line-height: .8125rem
    }
}

.Brands_brands--section-letter__IH1kG > p {
    color: black;
}
ul.Brands_brands--section-values__112Uq > li > a:link {
    text-decoration: none;
    color: black;
}
.Brands_brands--header-filters__lspAk > li > a:link {
    text-decoration: none;
    color: black;
}
.Brands_brands--header-filters-disabled__Arfcy {
    color: #d8d8d8
}
.Brands_brands--section__1MCLm {
    padding-top: 0
}

@media only screen and (min-width:768px) {
    .Brands_brands--section__1MCLm {
        padding: 0 100px 24px;
        // max-width: 1230px
    }
}

@media only screen and (max-width:375px) {
    .Brands_brands--section__1MCLm {
        padding: 0
    }
}

.Brands_brands--section-letters__2hvMX {
    width: -webkit-calc(100% - 24px);
    width: -moz-calc(100% - 24px);
    width: calc(100% - 24px);
    -webkit-order: 1;
    -moz-box-ordinal-group: 2;
    order: 1;
    padding: 0
}

@media only screen and (max-width:767px) {
    .Brands_brands--section-letters__2hvMX {
        padding-left: 24px;
        width: -webkit-calc(100% - 36px);
        width: -moz-calc(100% - 36px);
        width: calc(100% - 36px)
    }
}

@media only screen and (min-width:768px) {
    .Brands_brands--section-letters__2hvMX {
        width: 100%;
        -webkit-order: 2;
        -moz-box-ordinal-group: 3;
        order: 2
    }
}

.Brands_brands--section-letter__IH1kG {
    border-top: 1px solid #d8d8d8
}

@media only screen and (min-width:768px) {
    .Brands_brands--section-letter__IH1kG {
        margin-top: 4px;
        margin-right: 0
    }

    .Brands_brands--section-letter__IH1kG:first-child {
        margin-top: 16px
    }
}

.Brands_brands--section-heading__3WoWd {
    font-weight: 400;
    scroll-margin-top: 120px
}

@media only screen and (max-width:768px) {
    .Brands_brands--section-heading__3WoWd {
        scroll-margin-top: 210px;
        padding-top: 8px;
        margin-left: 0;
        margin-bottom: 9px;
        font-size: large;
        margin-top: 9px;
    }
}

@media only screen and (min-width:768px) {
    .Brands_brands--section-heading__3WoWd {
        font-size: 1.375rem;
        padding-top: 183px;
        margin: -161px 0 24px
        
    }
}

@media only screen and (min-width:961px) {
    .Brands_brands--section-heading__3WoWd {
        padding-top: 155px;
        margin: -141px 0 24px
    }
}

.Brands_brands--section-heading-disabled__1WOHO {
    color: #d8d8d8
}

.Brands_brands--section-values__112Uq {
    list-style: none;
    padding-left: 0;
    -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2
}

@media only screen and (min-width:768px) {
    .Brands_brands--section-values__112Uq {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4
    }
}

.Brands_brands--section-values__112Uq li {
    line-height: 1.25rem;
    margin-bottom: 12px
}

.brands-container.new-brands-ui {
    width: 100%;
    padding: 0 16px;
    
    @media screen and (min-width: $dobbies-container-max-width-s) {
        max-width: 768px;
        margin: 0 auto;
        padding: 0 19px;
    }
    @media screen and (min-width: $dobbies-container-max-width-tablet) {
        width: 100%;
        max-width: none;
    }
    @media only screen and (min-width:$dobbies-container-max-width-xl) {
        max-width: 1476px;
    }

    > h1 {
        font-size: 25px;
        text-transform: uppercase;
        text-align: center;
        margin: 20px auto 0;

        @media only screen and (min-width:$dobbies-container-max-width-xl) {
            margin: 42px 0 26px;
        }
    }

    .Brands_brands--section__1MCLm {
        padding-top: 0;

        @media screen and (min-width: $dobbies-container-max-width-tablet) {
            padding: 0 0px 24px;
        }
    }

    .Brands_brands--section-letters__2hvMX {
        all: unset;
        display: block;
        margin: 0 -5px;
        margin-bottom: 20px;
        @media screen and (min-width: $dobbies-container-max-width-s) {
            margin: 0 -17.5px;
        }
        @media screen and (min-width: $dobbies-container-max-width-tablet) {
            margin: 0 -5px;
        }
        @media only screen and (min-width:$dobbies-container-max-width-xl) {
            margin: 0 -4px;
        }
    
        @media screen and (max-width: $dobbies-container-max-width-s) {
            &::before {
                content: '';
                width: calc(100% - 10px);
                height: 1px;
                border-top: 1px solid $dobbies-light-grey;
                position: relative;
                left: 5px;
            }                  
        }
    
        .brand-block {
            // width: 25%;
            padding: 0 5px;
            margin-bottom: -1px;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                padding: 0 17.5px;
            }
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                padding: 0 6.5px;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                padding: 0 8px;
            }
            .brand-content {
                > p {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 18px 0;
                    border-top: 1px solid $dobbies-light-grey;
                    a {
                        font-size: 14px;
                        font-weight: 500;
                        display: inline-block;
                        white-space: nowrap;
                        max-width: 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-transform: capitalize;
                    }
                    .brands-dropdown-btn {
                        padding: 0;
                        background: transparent;
                        border: none;
                        display: inline-flex;
                        outline: none;
                        &:hover,
                        &:focus,
                        &:not(:disabled):not(.disabled):active {
                            outline: none;
                            box-shadow: none;
                            background: none;
                            border: none;
                        }
                        &.showdropdown {
                            &::before {
                                transform: rotate(0deg);
                                top: -3px;
                            }
                        }
                        &::before {
                            @include dobbies-icon();
                            content: $dobbies-icon-arrow;
                            font-size: var(--dobbies-icon-font-size-s);
                            transform: rotate(180deg);
                            color: $dobbies-yellow;
                            position: relative;
                            height: 10px;
                            width: 20px;
                            top: 2px;
                        }
                    }
                }
    
                &:last-child:not(:first-child),
                &:only-child {
                    > p {
                        border-bottom: 1px solid $dobbies-light-grey;
                    }
                    .brands-detail-dropdown {
                        &.show-brands-dropdown {
                            border-top: none;
                        }
                        border-bottom: 1px solid $dobbies-light-grey;
                    }
                }
    
                // &:last-child:not(:only-child) {
                //     > p {
                //         border-bottom: 1px solid $dobbies-light-grey;
                //     }
                // }
    
                &:first-child {
                    p {
                        border-top: none;
                    }
                }
            }
            &-header {
                font-size: 25px;
                color: $dobbies-black;
                font-weight: bold;
                padding: 10px;
                border-bottom: 1px solid $dobbies-light-grey;
                line-height: 34px;
                margin: 0 5px;
                background-color: #F8F8F8;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    margin: 0 17.5px;
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    margin: 0 6.5px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    margin: 0 8px;
                }
            
            }
            &-inner {
               position: relative;
               &::after {
                content: '';
                width: 100%;
                height: 2px;
                background-color: $dobbies-white;
                position: absolute;
                border-bottom: 1px solid $dobbies-light-grey;
                margin: 0 5px;
                width: calc(100% - 10px);
                bottom: -1px;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    margin: 0 17.5px;
                    width: calc(100% - 35px);
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    margin: 0 6.5px;
                    width: calc(100% - 13px);
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    margin: 0 8px;
                    width: calc(100% - 16px);
                }
               }
            }
        }
    
        &.alphabet-selected {
            .brand-block-inner {
                &::after {
                    display: none;
                }
            }
        }
    
        .brands-detail-dropdown {
            padding: 20px 0 23px 0;
            .brand {
                &-logo {
                    display: flex;
                    gap: 5px;
                    flex-wrap: wrap;
                    margin: 0 -5px 17px;
                    img {
                        width: 50%;
                        max-height: 115px;
                        min-width: 50%;
                    }
                }
                &-products {
                    display: flex;
                    gap: 5px;
                    margin: 0 -5px 16px;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        flex-wrap: wrap;
                    }
    
                    img {
                        max-height: 101px;
                        @media screen and (min-width: $dobbies-container-max-width-s) {
                            max-height: 193px;
                        }
                        @media screen and (min-width: $dobbies-container-max-width-tablet) {
                            max-height: 164px
                        }
                        @media only screen and (min-width:$dobbies-container-max-width-xl) {
                            max-height: 189px;
                        }
                    }
                }
                &-description {
                    margin-bottom: 20px;
                    font-size: $dobbies-font-size-s;
                    color: $dobbies-black;
                    line-height: 18px;
                }
                &-link {
                    font-size: $dobbies-font-size-s;
                    color: $dobbies-black;
                    line-height: 18px;
                    text-decoration: underline;
                    font-weight: $dobbies-font-weight-bold;
                }
            }
    
            &.hide-brands-dropdown {
                display: none;
            }
            &.show-brands-dropdown {
                display: block;
                border-top: 1px solid $dobbies-light-grey;
            }
        }
    }

    .Brands_brands--section-letter__IH1kG {
        all: unset;
    }


    ul.Brands_brands--header-filters__lspAk {
        all: unset;
        display: block;
        width: 100%;
        list-style: none;
        margin: 20px 0 0;
        @media screen and (min-width: $msv-breakpoint-m) {
            margin: 20px auto 0;
        }
        @media screen and (min-width: $dobbies-container-max-width-tablet) {
            margin: 20px 0 0;
            text-align: center;
        }
        @media screen and (min-width: $dobbies-container-max-width-xl) {
            margin: 16px 0 0;
        }
        @media screen and (max-width: $dobbies-container-max-width-s) {
            text-align: center;
        }
        li {
            margin: 0;
            display: inline-block;
            font-size: 1rem;
            line-height: 1.25rem;
            padding: 0;
            border: 1px solid $dobbies-gray-100;
            text-align: center;
            border-left: none;
            height: 63.5px;
            width: 63.5px;
            border-top: none;
            &:hover,
            &.brand-selected {
                background-color: $dobbies-gray-100;
                a, a:link {
                    color: $dobbies-white;
                }
            }
    
            &:first-child {
                border-left: 1px solid $dobbies-gray-100;
            }
            @media screen and (min-width: 735px) and (max-width: 767px) {
                &:nth-child(-n+11) {
                    border-top: 1px solid $dobbies-gray-100;
                }
                &:nth-child(11n+1) {
                    border-left: 1px solid $dobbies-gray-100;
                }
            }
            @media screen and (min-width: 672px) and (max-width: 735px) {
                &:nth-child(-n+10) {
                    border-top: 1px solid $dobbies-gray-100;
                }
                &:nth-child(10n+1) {
                    border-left: 1px solid $dobbies-gray-100;
                }
            }
            @media screen and (min-width: 608px) and (max-width: 671px) {
                &:nth-child(-n+9) {
                    border-top: 1px solid $dobbies-gray-100;
                }
                &:nth-child(9n+1) {
                    border-left: 1px solid $dobbies-gray-100;
                }
            }
            @media screen and (min-width: 544px) and (max-width: 607px) {
                &:nth-child(-n+8) {
                    border-top: 1px solid $dobbies-gray-100;
                }
                &:nth-child(8n+1) {
                    border-left: 1px solid $dobbies-gray-100;
                }
            }
            @media screen and (min-width: 480px) and (max-width: 543px) {
                &:nth-child(-n+7) {
                    border-top: 1px solid $dobbies-gray-100;
                }
                &:nth-child(7n+1) {
                    border-left: 1px solid $dobbies-gray-100;
                }
            }
            @media screen and (min-width: 410px) and (max-width: 479px) {
                &:nth-child(-n+6) {
                    border-top: 1px solid $dobbies-gray-100;
                }
                &:nth-child(6n+1) {
                    border-left: 1px solid $dobbies-gray-100;
                }
            }
            @media screen and (min-width: 360px) and (max-width: 409px) {
                &:nth-child(-n+5) {
                    border-top: 1px solid $dobbies-gray-100;
                }
                &:nth-child(5n+1) {
                    border-left: 1px solid $dobbies-gray-100;
                }
            }
    
            // @media screen and (min-width: 608px) and (max-width: 735px) {
            //     border-top: 0;
            // }
    
            &:nth-last-child(-n + 13) {
                @media screen and (min-width: $msv-breakpoint-m) and (max-width: $dobbies-container-max-width-tablet) {
                    border-top: 0;
                }
            }
    
            &:nth-last-child(13) {
                @media screen and (min-width: $msv-breakpoint-m) and (max-width: $dobbies-container-max-width-tablet) {
                    border-left: 1px solid $dobbies-gray-100;
                }
            }
    
            a {
                display: block;
                font-size: 18px;
                font-weight: 500;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            @media screen and (min-width: $dobbies-container-max-width-s) {
                height: 52px;
                width: 52px;
                max-width: 52px;
                max-height: 52px;
                border-top: 1px solid $dobbies-gray-100;
            }
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                height: 46px;
                width: 46px;
                max-width: 46px;
                max-height: 46px;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                max-width: 53px;
                max-height: 53px;
                height: 53px;
                width: 53px;
            }
        }
    }
}

.brand {
    &-heading,
    &-cta  {
        .ms-content-block[data-m-layout="full-width"] {
            min-height: auto;
            .ms-content-block__details {
                height: auto;
                position: static;
                width: 100%;
            }
        }
    }
    &-heading {
        margin-top: 34px;
        .ms-content-block[data-m-layout="full-width"] {
            .ms-content-block__title {
                text-align: center;
                font-size: 25px;
                font-weight: $dobbies-font-weight-bold;
                max-width: 100%;
                width: 100%;
            }
        }
    }
    &-container {
        margin: 20px 0;
        padding: 30px 0;
        border-top: 1px solid $dobbies-grey;
        border-bottom: 1px solid $dobbies-grey;

        @media screen and (min-width: $dobbies-container-max-width-tablet) {
            padding: 26px 0;
        }

        &.carousel-container {
            background-color: $dobbies-white;
        }
        > .row {
            > .col-12 {
                &:first-child {
                    display: none;
                }
            }
        }
    }
    &-carousel {
        padding: 0 5px;
        @media screen and (min-width: $dobbies-container-max-width-s) {
            padding: 0 10px;
        }
        .brand-slide {
            display: inline-block;
            max-width: none;
            padding: 5px 10px 5px;
            text-align: left;
            vertical-align: top;
            width: 49.5%;
            min-width: 49.5%;
            position: relative;
            padding-top: 0;
            padding-bottom: 0;
            &:hover {
                background-color: transparent;
                border: none;
                border-radius: initial;
            }
            @media screen and (min-width: $dobbies-container-max-width-s) {
                width: 33.33%;
                min-width: 33.33%;
            }
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                width: 20%;
                min-width: 20%;
            }
            @media only screen and (min-width:$dobbies-container-max-width-xl) {
                width: 16.6666%;
                min-width: 16.6666%;
            }
    
            img {
                width: 100%;
            }
        }

        .msc-ss-carousel {
            .msc-ss-carousel__flipper {
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    margin-left: -25px;
                    &--next {
                        i {
                            position: relative;
                            right: -5px;
                        }
                    }
                }
            }
            .msc-ss-carousel-slide {
                display: flex;
                align-items: center;
            }
        }
    }

    &-cta {
        margin-bottom: 34px;
        .ms-content-block[data-m-layout="full-width"] {
            .ms-content-block__cta {
                text-align: center;
                width: 100%;

                a {
                    @include button-transparent;
                    min-height: 30px;
                    height: 30px;
                    width: 200px;
                    min-width: 200px;
                    line-height: 30px;
                    padding: 0;
                    font-size: 14px;
                    font-weight: 500;
                    border-color: $dobbies-grey;
                    margin: 0;
                }
            }
        }
    }
}