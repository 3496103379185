$msv-sign-in-social-accounts-border: 1px solid $dobbies-gray-300;
$msv-sign-up-border: 1px solid $dobbies-gray-300;
$msv-sign-in-font-size: var(--dobbies-body-font-size-s);
$msv-sign-in-line-height: 20px;
$msv-sign-in-link-font-size: var(--dobbies-body-font-size-xs);
$msv-sign-in-link-line-height: 26px;
$msv-sign-in-heading-font-size: 24px;
$msv-sign-in-heading-font-weight: normal;
$msv-sign-in-heading-line-height: 32px;
$msv-sign-in-button-margin-bottom: 10px;
$msv-sign-in-button-margin-top: 20px;
$msv-sign-input-height: 32px;
$msv-sign-in-account-item-margin-top: 20px;
$msv-sign-in-input-margin-top: 4px;
$msv-sign-in-input-padding: 8px;
$msv-sign-in-section-spacing: 90px;
$msv-sign-in-section-spacing-m: 40px;
$msv-sign-in-social-accounts-padding: 20px 0;
$msv-sign-in-social-button-padding: 8px 16px;
$msv-sign-in-social-accounts-button-margin-bottom: 20px;
$msv-sign-in-social-accounts-text-margin-left: 16px;
$msv-sign-in-link-margin-top: 8px;
$msv-sign-in-sign-up-description-margin-top: 20px;
$msv-sign-in-sign-up-description-margin-bottom: 16px;
$msv-sign-in-message-padding: 20px;
$msv-sign-in-message-margin-top: 20px;
$msv-sign-in-container-margin: 50px 0px;
$msv-sign-in-container-margin-m: 20px 0;
$sign-in-social-button-bg-color: #f0f0f2;
$form-btn-input-height: 46px;
$form-btn-input-height-md: 46px;
$form-btn-input-height-sm: 46px;

$dobbies-logo-path: '../../../';

//style presets
:root {
    --msv-sign-in-font-size: var(--msv-body-font-size-m);
    --msv-sign-in-font-color: var(--dobbies-font-primary-color);

    --msv-sign-in-link-size: var(--msv-body-font-size-s);
    --msv-sign-in-link-color: #{$dobbies-gray-500};

    // heading
    --msv-sign-in-heading-font-size: var(--msv-body-font-size-xl);
    --msv-sign-in-heading-font-color: var(--dobbies-font-primary-color);

    //error
    --msv-sign-in-error-alert-bg: var(--dobbies-error-color);
    --msv-sign-in-error-alert-font-color: var(--dobbies-font-secondary-color);
    --msv-sign-in-error-alert-border: var(--dobbies-error-color);

    --msv-sign-in-error-color: var(--dobbies-error-color);

    // primary button
    --msv-sign-in-primary-btn-bg: var(--dobbies-accent-brand-color);
    --msv-sign-in-primary-btn-font-color: var(--dobbies-font-secondary-color);
    --msv-sign-in-primary-btn-border: var(--dobbies-accent-brand-color);
}

$dobbies-image-path: '../../../';

// body {
//   position: relative !important;
//   top: 0 !important;
// }
// .msc-modal, .msc-modal__backdrop {
//   display: none !important;
// }

.sign-in-container {
    position: relative;
    padding-top: 140px;
    min-height: calc(100vh - 304px);
    &:before {
        content: '';
        //background-image: url("#{$dobbies-image-path}chevrons-top.svg");
        background-position: center top;
        background-repeat: no-repeat;
        width: 100%;
        display: block;
        height: 200px;
        position: absolute;
        top: 0;
        z-index: -1000;
    }
    &:after {
        content: '';
        //background-image: url("#{$dobbies-image-path}chevrons-bottom.svg");
        background-position: center top;
        background-repeat: no-repeat;
        width: 100%;
        display: block;
        height: 260px;
        position: absolute;
        bottom: 0;
        z-index: -1000;
        @media (max-width: $dobbies-container-max-width-l) {
            display: none;
        }
    }

    & > .row {
        margin: auto;
    }

    .inner-container {
        max-width: 544px;
        padding: 0;
        margin-bottom: 40px;
        @media (max-width: $dobbies-container-max-width-xl) {
            max-width: 586px;
        }
        @media (max-width: $dobbies-container-max-width-tablet) {
            max-width: 484px;
        }

        @media (max-width: $dobbies-container-max-width-s) {
            padding: 0 16px;
        }

        &_title {
            padding: 0;
        }
    }

    .ms-content-block {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &[data-m-layout='full-width'] {
            .ms-content-block__details {
                @media (min-width: $msv-breakpoint-mbl-sm) {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .ms-content-block__cta a {
                    display: block;
                    background: url('#{$dobbies-logo-path}logo.svg') no-repeat top left;
                    background-size: 100%;
                    width: 210px;
                    height: 55px;
                    margin: 40px auto;
                    border: 0;
                    min-width: auto;

                    @media (max-width: $msv-breakpoint-sm) {
                        width: 169px;
                        height: 46px;
                    }
                }
            }
        }

        @media (max-width: $msv-breakpoint-xl) {
            padding-top: 35px;
        }
    }

    @media (min-width: $msv-breakpoint-xl) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: $dobbies-container-max-width-l) {
        & > .row {
            width: 100%;
        }
    }

    @media (max-width: $dobbies-container-max-width-l) and (min-width: $msv-breakpoint-sm) {
        min-height: calc(100vh - 494px);
    }
}

.ms-sign-in {
    @include font-content(var(--msv-font-weight-normal), var(--msv-sign-in-font-size), $dobbies-line-height-m);
    color: var(--msv-sign-in-font-color);
    border: 1px solid #111;
    padding: 40px;
    background: #f8f8f8;

    @media (max-width: $dobbies-container-max-width-s) {
        padding: 20px;
    }

    &__container {
        display: block;
    }

    &__social-account-picture {
        display: inline-block;
        vertical-align: middle;
    }

    &__sign-in-heading,
    &__sign-up-heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-sign-in-heading-font-size), $dobbies-line-height-xl);
        color: var(--msv-sign-in-heading-font-color);

        @media (max-width: $dobbies-container-max-width-tablet) {
            font-size: $dobbies-font-size-ml;
        }
    }

    &__sign-in-section {
        flex: 1;
        .ms-sign-in {
            &__account-item-label {
                font-size: $dobbies-font-size-s;
                margin-bottom: 10px;
                display: inline-block;
            }
            &__forget-password {
                width: 100%;
                text-align: center;
                font-size: var(--dobbies-body-font-size-xs);
                margin-top: 0;
                color: $dobbies-grey;
            }
            &__sign-in-button {
                border-radius: 0;
                line-height: 0;
                font-size: $dobbies-font-size-m;
                font-weight: var(--dobbies-font-weight-bold);
            }
        }
        .ms-guest-user {
            font-size: $dobbies-font-size-m;
            font-weight: var(--dobbies-font-weight-bold);
            text-align: center;
            padding-top: 17px;
            padding-bottom: 20px;
            a {
                color: $dobbies-primary;
                text-decoration: underline;
            }
        }
        .migration-info {
            a {
                text-decoration: underline;
                &:hover {
                    font-weight: var(--dobbies-font-weight-bold);
                }
            }
        }
    }
    &__sign-up-section {
        display: flex;
        flex-wrap: wrap;
        .ms-sign-in__sign-up-link {
            border-radius: 0;
            font-size: $dobbies-font-size-m;
            font-weight: $dobbies-font-weight-bold;
            line-height: $dobbies-font-size-m;
        }
    }

    &__sign-up-heading {
        flex: 1;
    }

    &__account-item {
        margin-top: $msv-sign-in-account-item-margin-top;
        @media (max-width: $dobbies-container-max-width-l) {
            margin-top: 15px;
        }
        &-input {
            @include form-input-el();
            margin-top: 0;
            width: 100%;
            border: 1px solid $dobbies-gray-500;
            height: $form-btn-input-height;
            font-size: $dobbies-font-size-s;

            &:focus {
                outline: none;
            }
            @media (max-width: $dobbies-container-max-width-l) {
                height: $form-btn-input-height-md;
            }
            @media (max-width: $msv-breakpoint-mbl-sm) {
                height: $form-btn-input-height-sm;
            }
        }
    }
    &__forget-password {
        display: inline-block;
        color: var(--msv-sign-in-link-color);
        @include font-content(var(--msv-font-weight-normal), var(--msv-sign-in-link-size), $dobbies-line-height-s);
        margin-top: $msv-sign-in-link-margin-top;
        text-decoration: underline;
    }

    &__sign-in-button {
        @include primary-button(
            var(--msv-sign-in-primary-btn-bg),
            var(--msv-sign-in-primary-btn-font-color),
            var(--msv-sign-in-primary-btn-border)
        );
        margin-bottom: $msv-sign-in-button-margin-bottom;
        margin-top: $msv-sign-in-button-margin-top;
        width: 100%;
        height: $form-btn-input-height;
        position: relative;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        border: 0;

        @media (max-width: $dobbies-container-max-width-l) {
            height: $form-btn-input-height-md;
        }

        @media (max-width: $msv-breakpoint-mbl-sm) {
            height: $form-btn-input-height-sm;
        }
    }

    &__sign-up-link {
        @include primary-button(
            var(--msv-sign-in-primary-btn-bg),
            var(--msv-sign-in-primary-btn-font-color),
            var(--msv-sign-in-primary-btn-border)
        );
        width: 100%;
        height: $form-btn-input-height;
        position: relative;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        border: 0;

        @media (max-width: $dobbies-container-max-width-l) {
            height: $form-btn-input-height-md;
            line-height: 13px;
        }

        @media (max-width: $msv-breakpoint-mbl-sm) {
            height: $form-btn-input-height-sm;
            line-height: 5px;
        }
    }
    .msc-btn {
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-bg-color-primary);
            &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
        &:disabled,
        &[disabled] {
            cursor: default;
            border-color: $dobbies-gray-300;
            background-color: $dobbies-gray-300;
            color: var(--dobbies-font-secondary-color);
            &:hover {
                border-color: $dobbies-gray-300;
                background-color: $dobbies-gray-300;
                color: var(--dobbies-font-secondary-color);
                &:after {
                    background-color: $dobbies-gray-300;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                }
            }
        }
    }

    &__social-accounts {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap !important;
        padding: 0;
        margin: 20px 0px 40px;

        @media (max-width: $dobbies-container-max-width-s) {
            margin: 20px 0px 40px;
            flex-wrap: wrap !important;
        }

        .ms-sign-in__social-account-text {
            margin: 0 !important;
            margin-left: 6px !important;
        }

        button {
            padding: 8px 5px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 222px !important;
            height: 46px;
            margin: 0 !important;
            background: transparent !important;
            color: $dobbies-grey !important;
            border: 1px solid $dobbies-grey !important;
            font-size: $dobbies-font-size-m;
            font-weight: $dobbies-font-weight-bold;

            @media (max-width: $dobbies-container-max-width-tablet) {
                font-size: $dobbies-font-size-s;
            }

            @media (max-width: $dobbies-container-max-width-s) {
                width: 100% !important;
                margin: 0 !important;
            }

            &.ms-sign-in__social-account-FacebookExchange {
                @media (max-width: $dobbies-container-max-width-s) {
                    margin-bottom: 20px !important;
                }
            }

            &:nth-child(2) {
                margin-left: 12px;
                margin-right: 12px;
            }

            @media (min-width: $msv-breakpoint-xl) {
                max-width: 168px;
            }

            @media (max-width: $dobbies-container-max-width-l) {
                display: flow-root;
                height: auto;
                max-width: 100%;
            }
        }

        button:last-child {
            margin-bottom: 0px;
        }
    }

    &__social-account {
        position: relative;
        margin-bottom: $msv-sign-in-social-accounts-button-margin-bottom;
        width: 100%;

        &-FacebookExchange {
            background-color: $facebook-bg-color;
            color: var(--dobbies-font-secondary-color);
            padding: $msv-sign-in-social-button-padding;

            &:hover,
            &:focus {
                background-color: $facebook-hover-color;
            }
        }

        &-MicrosoftAccountExchange {
            background-color: $microsoft-bg-color;
            color: var(--dobbies-font-secondary-color);
            padding: $msv-sign-in-social-button-padding;

            &:hover,
            &:focus {
                background-color: $microsoft-hover-color;
                border: 1px solid $microsoft-hover-color;
            }
        }

        &-text {
            position: relative;

            @media (max-width: $dobbies-container-max-width-l) {
                margin-left: 0;
                position: initial;
                margin-right: 0;
                text-indent: 0;
                line-height: 20px;
            }

            @media (max-width: $msv-breakpoint-mbl-sm) {
                line-height: 16px;
            }
        }

        &-picture {
            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__sign-up-description {
        margin-top: $msv-sign-in-sign-up-description-margin-top;
        margin-bottom: $msv-sign-in-sign-up-description-margin-bottom;

        @media (max-width: $msv-breakpoint-mbl-sm) {
            margin-bottom: 20px;
        }
    }

    &__page-error {
        @include validation-error(
            var(--msv-sign-in-error-alert-bg),
            var(--msv-sign-in-error-alert-border),
            var(--msv-sign-in-error-alert-font-color)
        );
        display: none;
        margin-top: $msv-sign-in-message-margin-top;
        padding: $msv-sign-in-message-padding;
    }

    &__item-error {
        color: var(--msv-sign-in-error-color);
    }

    @media screen and (max-width: $dobbies-container-max-width-l) {
        &__container {
            display: block;
        }

        &__sign-in-section,
        &__sign-up-section {
            margin-left: auto;
            margin-right: auto;
        }

        &__sign-up-section {
            border-left: none;
            padding-left: 0px;
        }
    }

    &__sign-in-heading,
    &__sign-up-heading {
        font-size: $msv-font-size-l + 1;
        font-weight: var(--dobbies-font-weight-bold);
        padding-bottom: 10px;
        border-bottom: 1px solid var(--dobbies-font-primary-color);
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;

        @media (max-width: $dobbies-container-max-width-tablet) {
            font-size: $dobbies-font-size-ml;
        }
    }
}
.logo-container {
    .centered-header {
        position: absolute;
        width: 180px;
        height: 50px;
        z-index: 1;
        left: 50%;
        margin-left: -90px;
        margin-top: 50px;
        min-height: auto;
        > a {
            display: block;
            background: url('#{$dobbies-logo-path}logo.svg') no-repeat top left;
            background-size: 100%;
            width: 180px;
            height: 50px;
            border: 0;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 169px;
                height: 46px;
                margin: 20px auto;
            }
            &:hover {
                background-color: transparent;
            }
        }
        .ms-content-block__details {
            display: none;
        }
    }
}
.login-header-text {
    text-align: center;
    margin-bottom: 40px;
    display: block;

    h3 {
        margin-bottom: 10px;
        font-size: 30px;
        text-transform: uppercase;

        @media (max-width: $dobbies-container-max-width-tablet) {
            font-size: 24px;
        }
    }
}

.modal-open {
    position: static !important;
    overflow: initial;
}
