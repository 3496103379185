$msv-order-history-padding-bottom: 28px;
$msv-order-history-heading-margin-bottom: 12px;
$msv-order-history-header-padding-bottom: 13px;
$msv-order-history-header-padding-top: 38px;
$msv-order-history-order-count-margin-left: 5px;
$msv-order-history-sales-order-padding-top: 20px;
$msv-order-history-sales-order-padding-bottom: 20px;
$msv-order-history-order-information-sales-id-padding-right: 5px;
$msv-order-history-order-information-receipt-id-padding-right: 5px;
$msv-details-order-information-channel-name-margin-bottom: 4px;
$msv-order-history-sales-lines-margin-top: 8px;
$msv-order-history-sales-line-picture-margin-right: 12px;
$msv-order-history-sales-line-picture-margin-top: 12px;
$msv-order-history-sales-line-picture-width: 81px;
$msv-order-history-sales-line-picture-height: 81px;
$msv-order-history-empty-image-width: 81px;
$msv-order-history-btn-order-details-margin-top: 20px;
$msv-order-history-order-information-created-date-padding-right: 14px;
$msv-order-history-order-information-created-date-padding-left: 5px;
$msv-order-history-order-information-count-padding-right: 14px;
$msv-order-history-order-information-count-padding-left: 14px;
$msv-order-history-order-information-amount-padding-left: 5px;
$msv-container-padding-left: 60px;
$msv-container-padding-right: 60px;
$msv-container-width: 100%;
$msv-order-history-group-delivery-heading-margin-right: 3px;
$msv-order-history-btn-keep-shopping-margin-top: 20px;
$msv-order-history-btn-keep-shopping-margin-bottom: 20px;
$msv-order-history-btn-more-margin-top: 20px;
$msv-order-history-alert-margin-top: 20px;
$msv-order-history-alert-margin-bottom: 20px;
$msv-order-history-prior-info-separator: var(--dobbies-font-primary-color);
$msv-order-history-information-margin-bottom: 20px;
$dobbies-order-history-btn-order-details-height: 60px;
$dobbies-order-history-btn-order-details-width: 226px;
$dobbies-order-history-sales-line-picture-width: 105px;
$dobbies-order-history-sales-line-picture-height: 105px;

//style presets
:root {
    --msv-order-history-border: var(--dobbies-heading-primary-color);
    --msv-order-history-prior-info-separator: #{$msv-order-history-prior-info-separator};

    // heading
    --msv-order-history-heading-font-color: var(--dobbies-font-primary-color);
    --msv-order-history-heading-font-size: var(--dobbies-body-font-size-m);

    // title
    --msv-order-history-title-font-color: var(--dobbies-font-primary-color);
    --msv-order-history-title-font-size: var(--msv-body-font-size-l);

    // text
    --msv-order-history-text-font-color: var(--dobbies-font-primary-color);
    --msv-order-history-text-font-size: var(--dobbies-body-font-size-l);
    --msv-order-history-text-font-size-mbl: var(--dobbies-body-font-size-s);

    // secondary button
    --msv-order-history-secondary-btn-bg: var(--dobbies-bg-color);
    --msv-order-history-secondary-btn-font-color: var(--dobbies-font-primary-color);
    --msv-order-history-secondary-btn-border: var(--dobbies-accent-brand-color);

    // font-weight
    --msv-font-weight-normal: var(--dobbies-font-weight-normal);
    --msv-font-weight-bold: var(--dobbies-font-weight-bold);
    --msv-font-weight-heavy: var(--dobbies-font-weight-heavy);
}

.ms-order-history {
    &__heading {
        @include font-content(var(--msv-font-weight-bold), var(--dobbies-body-font-size-s), normal);
        color: var(--msv-order-history-heading-font-color);
        display: inline-block;
        border-bottom: 1px solid $dobbies-grey;
        @media screen and (min-width: $msv-breakpoint-m) {
            // padding-top: $msv-order-history-header-padding-top;
        }
        order: 1;
        display: block;
        /* flex-wrap: wrap; */
        width: 100%;
        padding-bottom: 15px;
        @media (max-width: $msv-breakpoint-l) {
            padding-bottom: 10px;
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
    }

    &__order-count {
        display: inline-block;
        margin-left: $msv-order-history-order-count-margin-left;
    }

    &__sales-order {
        @include font-content-l();
        padding-top: $msv-order-history-sales-order-padding-top;
        padding-bottom: $msv-order-history-sales-order-padding-bottom;
        position: relative;
        border-bottom: 1px solid $dobbies-grey;
        position: relative;
        line-height: normal;
        @media (max-width: $dobbies-container-max-width-xl) {
            font-size: var(--dobbies-body-font-size-m);
        }
        @media screen and (max-width: $msv-breakpoint-mbl-sm) {
            font-size: var(--msv-order-history-text-font-size-mbl);
            padding-top: 10px;
            padding-bottom: 20px;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
    &__sales-order-list {
        border-top: 1px solid $dobbies-grey;
    }
    &__order-information-channel-name {
        color: var(--msv-order-history-title-font-color);
        display: none;
        font-size: var(--msv-order-history-title-font-size);
        line-height: $dobbies-line-height-l;
        margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
    }

    &__order-information-sales-id {
        display: block;
    }

    &__order-information-receipt-id {
        display: block;
    }

    &__sales-lines {
        // margin-top: $msv-order-history-sales-lines-margin-top;
        display: flex;
        flex-wrap: wrap;
    }

    &__sales-line-picture {
        margin-right: $msv-order-history-sales-line-picture-margin-right;
        // margin-top: $msv-order-history-sales-line-picture-margin-top;
        width: $dobbies-order-history-sales-line-picture-width;
        margin-bottom: 20px;
        @media screen and (max-width: $dobbies-container-max-width-l) {
            margin-top: 0;
        }

        @media screen and (max-width: $dobbies-container-max-width-s) {
            margin-bottom: 10px;
        }
    }

    &__btn-more {
        &.is-busy {
            &:before {
                @include msv-icon();
                content: $msv-Spinner;
                margin-right: $msv-checkout-icon-margin-right;
                -webkit-animation: spin 1s steps(8) infinite;
                animation: spin 1s steps(8) infinite;
            }
            cursor: progress;
        }

        margin-top: $msv-order-history-btn-more-margin-top;
        @include primary-button();
    }

    &__btn-order-details,
    &__btn-more {
        @include primary-button();
        @include button-verticle-padding-zero();
        font-size: var(--dobbies-body-font-size-m);
        font-weight: var(--dobbies-font-weight-bold);
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 6px;
        height: 30px;
        line-height: 1.6;
        min-width: 224px;
        border: 0;
        padding: 1px 20px;
        transform: perspective(1px) translateZ(0);
        position: relative;
        transition-duration: 0.3s;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-bg-color-primary);
            &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }

    &__btn-more {
        width: 100%;
    }

    &__order-information-created-date {
        @media screen and (min-width: $dobbies-container-max-width-s) {
            padding-right: $msv-order-history-order-information-created-date-padding-right;
            border-right: 1px solid var(--msv-order-history-prior-info-separator);
        }
    }

    &__order-information-count {
        display: inline-block;
        @media screen and (min-width: $dobbies-container-max-width-s) {
            padding-right: $msv-order-history-order-information-count-padding-right;
            padding-left: $msv-order-history-order-information-count-padding-left;
            border-right: 1px solid var(--msv-order-history-prior-info-separator);
        }
    }

    &__order-information-amount {
        padding-left: $msv-order-history-order-information-amount-padding-left;
    }

    &__groups {
        .ms-order-history__group-deliveryType-pickUp,
        .ms-order-history__group-deliveryType-ship {
            margin-bottom: 30px;
            @media screen and (max-width: $dobbies-container-max-width-l) {
                margin-bottom: 15px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__empty-message,
    &__alert {
        display: block;
        margin-top: $msv-order-history-alert-margin-top;
        margin-bottom: $msv-order-history-alert-margin-bottom;
    }
    &__order-information-sales-id,
    &__order-information-receipt-id,
    &__order-information-channel-reference-id,
    &__order-information-created-date,
    &__order-information-count,
    &__order-information-amount {
        font-size: 14px;
        color: var(--msv-order-history-text-font-color);
        @media screen and (max-width: $dobbies-container-max-width-s) {
            margin-bottom: 5px;
        }
    }
    .prior-info {
        > * {
            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                flex: initial;
                width: auto;
            }
        }
        label {
            font-size: $msv-font-size-s;
            font-weight: var(--msv-font-weight-bold);
            margin-bottom: 0;
        }
        // .row {
        //     @media (max-width: $msv-breakpoint-sm) {
        //        display: inherit;
        //     }
        // }
        .order-total {
            padding-left: $msv-order-history-order-information-created-date-padding-right;

            .msc-price .msc-price__actual {
                font-weight: var(--msv-font-weight-normal);
                font-size: var(--dobbies-body-font-size-s);
            }
        }
    }

    .status-tracking-info {
        margin-bottom: $msv-order-history-information-margin-bottom;
        padding-left: 0px;
        @media screen and (max-width: $dobbies-container-max-width-tablet) {
            max-width: 100%;
            flex: 0 0 100%;
            padding: 0;
        }
        @media screen and (max-width: $dobbies-container-max-width-s) {
            margin-bottom: 10px;
        }
        label {
            font-weight: var(--msv-font-weight-heavy);
        }
    }
    .delivery-mode {
        padding-left: 0px;
        margin-bottom: $msv-order-history-information-margin-bottom;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            margin-top: 0px;
            margin-bottom: 10px;
        }
        .ms-order-history__group-delivery-total-items {
            margin-left: 5px;
            display: inline-block;
        }
    }
    .delivery-mode-hidden {
        display: none;
    }
    .order-number {
        padding-left: 0px;
        margin-bottom: 20px;
        @media screen and (max-width: $dobbies-container-max-width-tablet) {
            max-width: 100%;
            flex: 0 0 100%;
        }

        @media (max-width: $msv-breakpoint-sm) {
            margin-bottom: 10px;
        }
    }

    @media screen and (min-width: $msv-container-max-width-s) and (max-width: $dobbies-container-max-width-l) {
        &__sales-line-picture {
            width: 68px;
            height: 96px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    @media screen and (max-width: $dobbies-container-max-width-s) {
        &__btn-order-details {
            height: 30px;
            flex: 1;
            min-width: auto;
            width: auto;
        }

        &__btn-keep-shopping {
            width: 100%;
        }

        &__sales-line-picture {
            width: 68px;
            height: 96px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__order-information {
            .order-placed,
            .order-count,
            .prior-info .order-total {
                display: block;
                padding-left: 0;
                margin-bottom: 10px;
                .ms-order-history__order-information-created-date,
                .ms-order-history__order-information-count {
                    border-right: 0;
                    padding-left: 0;
                }
            }

            &-count {
                margin-bottom: 0;
            }
        }
    }

    .msc-empty_image {
        @include image-placeholder($msv-order-history-empty-image-width);
    }

    &__btn-keep-shopping {
        margin-top: $msv-order-history-btn-keep-shopping-margin-top;
        margin-bottom: $msv-order-history-btn-keep-shopping-margin-bottom;
        @include primary-button();
        padding-top: 10px;
        border: 0;
        transform: perspective(1px) translateZ(0);
        position: relative;
        transition-duration: 0.3s;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-bg-color-primary);
            &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }
    &__order-information {
        margin-bottom: 20px;
        @media (max-width: $msv-breakpoint-sm) {
            margin-bottom: 0px;
        }
    }
}
.ms-order-history__loading,
.order-history-top-text {
    display: block;
    font-weight: var(--dobbies-font-weight-bold);
    margin-bottom: 20px;
}

.ms-order-details-btns {
    display: flex;
    order: 2;
    /* display: flex; */
    width: 100%;
    margin: 20px 0;
    @media (max-width: $dobbies-container-max-width-m) {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .msc-btn {
        font-size: $msv-font-size-m;
        font-weight: $msv-font-weight-500;
    }
}

.ms-order-details__btn-white {
    flex: 1 1 auto;
    background-color: white;
    border: 1px solid black;
    text-decoration: none;
    line-height: 0;
    transition-duration: 0.3s;
    height: 35px;
    border-radius: 0;
    color: $dobbies-grey;
    @media (max-width: $dobbies-container-max-width-m) {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

.ms-order-details__btn-black {
    flex: 1 1 auto;
    background-color: black;
    color: white;
    text-decoration: none;
    line-height: 0;
    transition-duration: 0.3s;
    height: 35px;
    border-radius: 0;
    @media (max-width: $dobbies-container-max-width-m) {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}
.prior-info.row {
    @media (max-width: $msv-breakpoint-sm) {
        display: inherit;
    }
}

.status-tracking-info,
.order-number,
.delivery-mode {
    font-size: $msv-font-size-s;
    b {
        font-weight: $msv-font-weight-500;
    }
}

.orderhistory-cta {
    @media screen and (max-width: $dobbies-container-max-width-s) {
        display: flex;
        gap: 0 20px;
    }
}
.ms-order-history__btn-order-details,
.ms-order-history__btn-more {
    &:not(.buyitem) {
        background-color: transparent;
        border: 1px solid $dobbies-grey;
        color: var(--dobbies-font-primary-color);
        border-radius: 4px;
        text-decoration: none;
        font-weight: var(--dobbies-font-weight-bold);
        cursor: pointer;
        transform: perspective(1px) translateZ(0);
        position: relative;
        transition-duration: 0.3s;
        overflow: hidden;
        padding: 0px 20px;
        height: 30px;
        line-height: 1.6;
        margin-right: 15px;

        @media screen and (max-width: $dobbies-container-max-width-s) {
            height: 30px;
            flex: 1;
            margin-right: 0;
            line-height: 30px;
        }
    }
}

.ms-order-history__btn-more {
    margin-bottom: 20px;
    margin-top: 0;
}
