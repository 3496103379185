$msv-checkout-payment-instrument-min-height: 80px;
$msv-checkout-payment-instrument-card-title-margin-bottom: 8px;
$msv-checkout-payment-instrument-address-title-margin-bottom: 8px;
$msv-checkout-payment-instrument-address-title-margin-top: 20px;
$msv-checkout-payment-instrument-loading-dimension: 100%;
$msv-checkout-payment-instrument-border: 0;
$msv-checkout-payment-container-padding: 0px;
$msv-checkout-payment-container-margin-zero: 0px;
$msv-checkout-payment-ready-margin-bottom: 20px;
$dobbies-checkout-payment-ready-margin-bottom: 40px;
$msv-checkout-payment-ready-flex-basis: 100%;
$msv-checkout-payment-ready-margin-left: 10px;
$msv-checkout-payment-card-title-margin-bottom: 10px;
$msv-checkout-payment-instrument-mutation-observer-margin-top: 20px;
$msv-checkout-payment-instrument-heading-margin-bottom: 8px;
$dobbies-checkout-payment-instrument-heading-margin-bottom: 5px;

//style presets
:root {
    --msv-checkout-payment-instrument-bg: #{$dobbies-gray-100};
    --msv-checkout-payment-instrument-font-size: var(--msv-body-font-size-m);

    // heading
    --msv-checkout-payment-instrument-heading-font-size: var(--msv-body-font-size-s);
}

.checkout-payment-instrument {
    position: relative;
    min-height: $msv-checkout-payment-instrument-min-height;

    &__loading-background {
        display: flex;
        height: $msv-checkout-payment-instrument-loading-dimension;
        position: absolute;
        width: $msv-checkout-payment-instrument-loading-dimension;

        .waiting {
            display: block;
            margin: auto;
        }
    }

    &__iframe {
        border: $msv-checkout-payment-instrument-border;
        width: $msv-checkout-payment-instrument-loading-dimension;
    }

    &__show {
        &-card-title {
            font-weight: var(--msv-font-weight-bold);
            line-height: $dobbies-line-height-s;
            margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
        }

        &-billing-address-title {
            margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
            margin-top: $msv-checkout-payment-instrument-address-title-margin-top;
        }

        &-card-name,
        &-card-number,
        &-card-expiration,
        &-billing-address-1,
        &-billing-address-2,
        &-billing-address-3 {
            margin-bottom: $msv-checkout-payment-container-margin-zero;
        }
    }
}

.ms-checkout-section-container {
    padding-left: $msv-checkout-payment-container-padding;
    padding-right: $msv-checkout-payment-container-padding;
}

.ms-checkout__guided-card.ready {
    .ms-checkout-payment-instrument {
        margin-bottom: $dobbies-checkout-payment-ready-margin-bottom;
        &__show {
            display: flex;
            flex-flow: row wrap;
        }
        &__card-title {
            @include font-content(var(--dobbies-font-weight-heavy), var(--dobbies-body-font-size-m), $dobbies-line-height-m);
            flex-basis: $msv-checkout-payment-ready-flex-basis;
            margin-bottom: $dobbies-checkout-payment-instrument-heading-margin-bottom;
        }

        &__billing-address-title {
            font-weight: var(--dobbies-font-weight-heavy);
            font-size: $dobbies-checkout-font-size;
            line-height: $dobbies-checkout-line-height;
            flex-basis: $msv-checkout-payment-ready-flex-basis;
            margin-bottom: $msv-checkout-payment-instrument-address-title-margin-bottom;
            margin-top: $msv-checkout-payment-instrument-address-title-margin-top;
        }

        &__card-number,
        &__card-expiration {
            margin-left: $msv-checkout-payment-ready-margin-left;
        }

        &__card-name,
        &__card-number,
        &__card-expiration {
            @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-m), $dobbies-line-height-m);
            display: block;
            flex-basis: 100%;
            margin-left: 0;
        }

        &__billing-address-1,
        &__billing-address-2,
        &__billing-address-3 {
            @include font-content(
                $font-weight: var(--msv-font-weight-light),
                $font-size: var(--msv-checkout-payment-instrument-font-size),
                $line-height: $dobbies-line-height-m
            );
        }
    }
}

.ms-checkout__guided-card.updating {
    .ms-checkout-payment-instrument__mutation-observer {
        margin-top: $msv-checkout-payment-instrument-mutation-observer-margin-top;
    }
}