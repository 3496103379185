$cart-content-margin-right: 200px;
$cart-content-mobile-vp-margin-right: 100px;
$cart-remove-item-margin-right: 20px;
$cart-content-margin-left-zero: 0;

[dir="rtl"] {
 .ms-cart {
    .msc-cart-line {
        &__content,
        &__add-to-wishlist {
            margin-right: $cart-content-margin-right;
            margin-left: $cart-content-margin-left-zero;
        }

        &__remove-item {
            margin-left: unset;
            margin-right: $cart-remove-item-margin-right;
        }
     }
  }

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-cart {
      .msc-cart-line {
            &__content,
            &__bopis-container {
                margin-right: $cart-content-mobile-vp-margin-right;
                margin-left: $cart-content-margin-left-zero;
            }

            &__product-savings {
                margin-left: unset;
                margin-right: 0px;
                text-align: right;
            }

            &__remove-item {
                margin-left: unset;
                margin-right: $cart-remove-item-margin-right;
            }

            &__add-to-wishlist {
                margin-left: unset;
                margin-right: $cart-content-mobile-vp-margin-right;
            }
        }
     }
  }
}