.badges {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    &__top {
        background-color: var(--dobbies-header-grey);
        border: solid 1px $dobbies-bombay-grey;
        color: var(--dobbies-font-primary-color);
        font-size: var(--dobbies-body-font-size-l);
        text-align: center;
        padding: 2px;
        @media (max-width: $dobbies-container-max-width-xl) {
            font-size: var(--dobbies-body-font-size-s);
        }
        @media (max-width: $dobbies-container-max-width-l) {
            font-size: var(--dobbies-body-font-size-xs);
        }
    }
    &__oos {
        background-color: $dobbies-club-grey;
        border: solid 1px $dobbies-bombay-grey;
        color: var(--dobbies-font-secondary-color);
        font-size: var(--dobbies-body-font-size-l);
        text-align: center;
        padding: 2px;
        @media (max-width: $dobbies-container-max-width-xl) {
            font-size: var(--dobbies-body-font-size-s);
        }
        @media (max-width: $dobbies-container-max-width-l) {
            font-size: var(--dobbies-body-font-size-xs);
        }
    }
    &__icon {
        margin-top: 15px;
        margin-left: 15px;
        width: 80px;
        height: 80px;
        overflow: hidden;
        @media (max-width: $dobbies-container-max-width-xl) {
            width: 70px;
            height: 70px;
        }
        @media (max-width: $dobbies-container-max-width-l) {
            width: 60px;
            height: 60px;
        }
        @media (max-width: $dobbies-container-max-width-s) {
            width: 60px;
            height: 60px;
        }
    }
}
.promotionalBanner {
    position: absolute;
    top: 0;
    left: 0;
    &__top {
        background-color: var(--dobbies-header-grey);
        border: solid 1px $dobbies-bombay-grey;
        color: var(--dobbies-font-primary-color);
        font-size: var(--dobbies-body-font-size-l);
        text-align: center;
        width: 60%;
        padding: 2px;
        margin-left: 15px;
        margin-top: 15px;
        @media (max-width: $dobbies-container-max-width-xl) {
            font-size: var(--dobbies-body-font-size-s);
        }
        @media (max-width: $dobbies-container-max-width-l) {
            font-size: var(--dobbies-body-font-size-xs);
        }
        @media (max-width: $dobbies-container-max-width-m) {
            width: 75%;
            margin-left: 0px;
            margin-top: 0px;
        }
        @media (max-width: $dobbies-container-max-width-s) {
            width: 100%;
            margin-left: 0px;
            margin-top: 0px;
        }
    }
    
}

.exit-badge-style {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    align-content: flex-end;
    margin-right: 10px;
    font-size: 16px;
    @media (max-width: $dobbies-container-max-width-m) {
        font-size: 20px;
    }
}

.badge-heading {
    display: flex;
    margin-top: -14px;
    margin-left: 5px;
    font-size: 18px;
    @media (max-width: $dobbies-container-max-width-m) {
        font-size: 16px;
    }
}

.badge-description {
    display: flex;
    margin-left: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
    font-size: 14px;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
}



.ms-buybox {
    .badges {
        &__top {
            font-size: var(--dobbies-body-font-size-l);
            @media (max-width: $dobbies-container-max-width-xl) {
                font-size: var(--dobbies-body-font-size-s);
            }
            @media (max-width: $dobbies-container-max-width-l) {
                font-size: var(--dobbies-body-font-size-xs);
            }
        }
    }
}