.loading-message-container {
    height: calc(100vh - 135px);
    @media (max-width: $dobbies-container-max-width-s) {
        height: calc(100vh - 85px);
    }
    position: relative;

    &__innerbox {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300px;
        max-width: 100%;
        height: 200px;
        background: var(--dobbies-bg-color);
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px;

        span {
            @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-m),$dobbies-line-height-l);
            display: block;
            text-align: center;
            color: var(--dobbies-font-primary-color);
            width: 100%;
            margin-left: -15px;
        }
    }
}