$carousel-padding-left: 20px;
$thumbnail-height: 100px;
$thumbnail-flipper-height: 30px;
$carousel-tooltip-padding:4px;
$carousel-indicators-border: 1px;
$carousel-thumb-image-margin: 15px;

//style presets
:root {
    --msv-media-gallery-bg: #{$dobbies-black};

    // Flipper
    --msv-media-gallery-thumbnail-flipper-bg: var(--dobbies-bg-color);
    --msv-media-gallery-thumbnail-flipper-border: var(--dobbies-border-color);
    --msv-media-gallery-thumbnail-flipper-font-color: #{$dobbies-gray-500};
 }
 @media (max-width: $msv-breakpoint-sm) {
    .msc-modal {
        .ms-media-gallery__modal {
            .msc-carousel__item {
                &.active {
                    pointer-events: none;
                }
            }
        }
    }
}

.ms-media-gallery {
    display: block;
    
    &.vertical {
        display: block;

        .msc-ss-carousel-slide {
            display: block;
        }

        .ms-media-gallery__thumbnail-item:not(:last-child){
            margin-bottom: 10px;
            margin-right: 0;
        }

        .ms-media-gallery__carousel {
            margin-bottom: 0;
        }
    }

    .ms-media-gallery__item {

        @media (max-width: $msv-breakpoint-m) {
            cursor: context-menu;
        }
    }

    .ms-media-gallery__thumbnails-container {
        display: none;
        order: 0;
        flex-grow: 0;
    }
    
    .ms-media-gallery__thumbnail-item:not(:last-child){
        margin-right: 10px
    }

    .ms-media-gallery__carousel {
        margin-bottom: $carousel-padding-left;
        align-items: start;
        order: 1;
        flex-grow: 1;
        min-height: 730px;
        margin-top: 0px;
        @media (max-width: $dobbies-container-max-width-xl) {
            min-height: 578px;
        }
        @media (max-width: $dobbies-container-max-width-l) {
            min-height: 534px;
        }
        @media (max-width: $dobbies-container-max-width-m) {
            min-height: 500px;
        }
        @media (max-width: $dobbies-container-max-width-s) {
            min-height: 534px;
        }
        @media (max-width: 710px) {
            min-height: 510px;
        }
        @media (max-width: 670px) {
            min-height: 620px;
        }
        @media (max-width: 630px) {
            min-height: 570px;
        }
        @media (max-width: 580px) {
            min-height: 510px;
        }
        @media (max-width: 400px) {
            min-height: 430px;
        }
        picture {
            width: 100%;
            img {
                width: 100%;
            }
        }
    }

    .msc-ss-carousel-slide {
        display: flex;
    }

    .msc-flipper {
        background: var(--msv-media-gallery-thumbnail-flipper-bg);
        border: 1px solid var(--msv-media-gallery-thumbnail-flipper-border);
        border-radius: 0;
        color: var(--msv-media-gallery-thumbnail-flipper-font-color);
        flex: 0 1 $thumbnail-flipper-height;
        height: $thumbnail-flipper-height;
        left: auto;
        position: absolute;
        top: auto;
        transform: none;
        width: 100%;
    }

    .msc-ss-carousel__flipper {
        &.disabled {
            opacity: 0;
        }
    }

    .msc-ss-carousel-vert__flipper {
        &.disabled {
            opacity: 0;
        }
    }

    .msc-carousel__control__prev,
    .msc-carousel__control__next {
        background: var(--msv-media-gallery-thumbnail-flipper-bg);
        border: 1px solid var(--msv-media-gallery-thumbnail-flipper-border);
        color: var(--msv-media-gallery-thumbnail-flipper-font-color);
    }

    .msc-ss-carousel-vert__flipper--next {
        bottom: 0;
    }
    
    .msc-tooltip-inner {
        background-color: var(--msv-media-gallery-bg);
        color: var(--dobbies-font-secondary-color);
        padding: $carousel-tooltip-padding $carousel-tooltip-padding;
    }

    .msc-carousel__indicators li{
        &.active{
          background-color:  var(--msv-media-gallery-bg);
        }
        border: $carousel-indicators-border solid $dobbies-black;
        background-color: var(--dobbies-bg-color);
    }
    .position-relative {
        @media (max-width: $dobbies-container-max-width-s) {
            width: 100%;
        }
    }
}

@media (min-width: $msv-breakpoint-l) {
    .ms-media-gallery {
        &.vertical {
            padding-left: 0;
        }
        
        .msc-carousel__indicators {
            display: none;
        }
    
        .msc-carousel__control__prev {
            display: none;            
        }

        .msc-carousel__control__next {
            display: none;            
        }

        .ms-media-gallery__thumbnails-container {
            display: flex;
        }
    
        .ms-media-gallery__thumbnail-item {
            height: $thumbnail-height;
        }
    }
}

//mobile vpp
@media (max-width: $msv-breakpoint-sm) {
    .ms-media-gallery {
        width: 100%;
        height: auto;
        display: inline-flex;
        flex-wrap: nowrap;
        padding-top: 100%;
        max-height: 542px;
        .ms-media-gallery__carousel {
            width: 100%;
            object-position: top;
            object-fit: contain;
            position: absolute;
            //top: 14px;
            left: 0;
            align-items: center;
            .msc-carousel__inner {
                    width: 100%;
                    .ms-inline-zoom {
                        float: none;
                        img {
                            height: 100%;
                            width: 70%;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }


@media (max-width: $msv-breakpoint-sm) {
    .ms-media-gallery {
        padding-top: 0;
        max-height: inherit;
        .ms-media-gallery__carousel {
            position: relative;
            margin-bottom: 10px;
            margin-top: 0px;
        }
    }
}
.ms-containerZoom {   

    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        cursor: zoom-in;
    } 

    &__result {
        overflow: hidden; 
        position: fixed; 
        background-color: var(--dobbies-bg-color); 
        background-repeat: no-repeat;
        z-index: 1140; 
        box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 8px 2px; 
        border: 1px solid rgb(148, 148, 148);
        width: 580px;
        height: 772px;
        right: 10px;

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }

        @media (max-width: $msv-breakpoint-xl) {
            width: 390px;
            height: 400px;    
        }
    }
    
    &__image {
        border: none;
        left: 0;
        max-width: none;
        max-height: none;
        position: absolute;
        top: 0;
    }

    &__zoom-lens {
        position: absolute; 
        cursor: zoom-out;
        width: 200px;
        height: 180px;
        background-position: 0px 0px;
        top: -200px;
        right: -200px;
        float: right;
        overflow: hidden;
        zoom: 1;
        background-color: white;
        border: 1px solid #000;          
        opacity: 0;
        
        &__opacity {
            opacity: 0.4;
        }

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }
    }   
}

.ms-inline-zoom {
	float: left;
	overflow: hidden;
	cursor: zoom-in;
	display: block;
    position: relative;
    width: 100%;
    img {
        width: 100%;
    }
    &.zoomed {
        cursor: zoom-out;
    }

    &__zoomedImg {
        position: absolute;		
        top: 0;
        left: 0;
        opacity: 0;
        border: none;
        max-width: none;
        max-height: none;
        width: 0;
        height: 0;

        @media (max-width: $msv-breakpoint-m) {
            display: none;
            opacity: 0;
        }
    }
}

.ms-fullscreen-section {
    position: relative;
    display: inline-block;
  
    img {
      vertical-align: top;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
            
        /* for dark overlay on top of the image */
        background: transparent;
        opacity: 0;
        transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;

        &:hover { 
            opacity: 0.9; 
        }
      }

      &:hover { 
        opacity: 0.8;
      }

      &__magnifying-glass-icon {
        color: var(--dobbies-font-secondary-color);
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        padding: 20px;
        border: 1px solid #fff;
        line-height: 1;
    
        @include add-icon($msv-Add, after);
      
        &:hover {
          background: rgba(0, 0, 0, 0.8);
          color: var(--dobbies-font-secondary-color);
        }
      }
  } 
  
  @media only screen and (max-width: 400px) {
    .ms-fullscreen-section {
      display: block;
    }
  }

.msc-fullview-modal-body {
    display: flex;
    justify-content: center;
    
    .msc-carousel {
        align-items: unset;
        position: unset;
        width: 100%;

        &__inner {
            display: flex;
            justify-content: space-around;
        }
        &__item {
            .ms-inline-zoom {
                float: unset;
                position: unset;
            }
        }
    }
}