$dobbies-order-summary-font: $dobbies-primary-font-family;
$dobbies-order-summary-padding: 20px 20px;
$dobbies-order-summary-heading-padding-bottom: 7px;
$dobbies-order-summary-items-border-radius: 2px;
$dobbies-order-summary-label-line-height: 20px;
$dobbies-order-summary-line-padding: 5px 0;

//style presets
:root {
  --dobbies-order-summary-bg: var(--dobbies-bg-color);
  --dobbies-order-summary-font-color: var(--dobbies-font-primary-color);
  --dobbies-order-summary-font-size: var(--dobbies-body-font-size-l);
  --dobbies-order-summary-border: var(--dobbies-font-primary-color);

  // heading
  --dobbies-order-summary-heading-font-color: var(--dobbies-font-primary-color);
  --dobbies-order-summary-heading-font-size: var(--dobbies-body-font-size-xl);
}

.msc-order-summary {
  &-wrapper {
    align-items: center;
    display: flex;
    flex: 0 0 464px;
    flex-direction: column;
    margin: 0 auto 20px;
    height: fit-content;
    position: sticky;
    top: 50px;
    z-index: 9;

    .msc-alert-danger {
      margin-top: -15px;
    }

    .msc-promo-code {
      &-heading {
        display: none;
      }
      &__group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        width: 100%;
        @media (max-width: $msv-breakpoint-sm) {
          padding-bottom: 10px;
        }
      }
  
      &__input-box {
        font-weight: var(--dobbies-font-weight-bold);
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        margin-right: 10px;
        border: 1px solid $dobbies-grey;
        padding: 10px;
        color: $dobbies-grey;
        &::placeholder {
          color: $dobbies-grey;
          font-weight: 400;
        }
      }
    }
  }

  @media screen and (max-width: $msv-breakpoint-xl) {
    &-wrapper {
      flex: 0 0 330px;
    }
  }

  @media screen and (max-width: $msv-breakpoint-s) {
    margin: unset;
  }

  .ms-order-summary-container {
    width: 100%;
  }

  &__heading {
    font-size: var(--dobbies-body-font-size-ml);
    line-height: 30px;
    font-weight: var(--dobbies-font-weight-bold);
    color: var(--dobbies-font-primary-color);
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
    background-color: $dobbies-header-grey;
    padding: $dobbies-order-summary-padding;
    text-transform: uppercase;
    border-top: 1px solid $dobbies-grey;
    border-left: 1px solid $dobbies-grey;
    border-right: 1px solid $dobbies-grey;
    margin-bottom: 0;
    @media (max-width: $msv-breakpoint-sm) {
      font-size: var(--dobbies-body-font-size-l);
      line-height: 24px;
      padding: 10px;
    }
    @media (max-width: $msv-breakpoint-l) {
      font-size: var(--dobbies-body-font-size-l);
    }
  }

  &__items {
    p.msc-order-summary__line-sub-total {
      margin-bottom: 20px;
      font-size: 18px;
      @media (max-width: $dobbies-container-max-width-tablet) {
        font-size: 16px;
      }
      @media (max-width: $msv-breakpoint-sm) {
        margin-bottom: 10px;
      }

    }
    font-size: var(--dobbies-body-font-size-ml);
    line-height: 30px;
    padding: 0 20px;
    color: var(--dobbies-order-summary-font-color);
    border-radius: $dobbies-order-summary-items-border-radius;
    background-color: $dobbies-header-grey;
    width: 100%;
    border-bottom: 1px solid $dobbies-grey;
    border-left: 1px solid $dobbies-grey;
    border-right: 1px solid $dobbies-grey;
    @media (max-width:$msv-breakpoint-xl) {
      font-size: var(--dobbies-body-font-size-l);
      line-height: 22px;
    }
    @media (max-width: $msv-breakpoint-sm) {
      font-size: var(--dobbies-body-font-size-m);
      line-height: 22px;
      padding: 0 10px;
    }

    > div:first-child {
      display: flex;
      justify-content: space-between;
    }
    &-cards{
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      @media (max-width: $dobbies-container-max-width-s) {
        padding-bottom: 10px;
      }
      .card-img-container {
        width: 40px;
        display: flex;
        align-items: center;
        height: 40px;
        @media (max-width: $dobbies-container-max-width-xs) {
          width: 30px;
        }
          img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      .card-img-container.maestro{
        margin-bottom: -6px;
      }
      .card-img-container.google-pay{
        width: 44px;
      }
    }
  }

  &__line {
    &-net-price,
    &-tax-amount,
    &-loyalty,
    &-gift-card,
    &-other-charges,
    &-total-discounts {
      padding: 10px 0 10px;
      overflow: hidden;
      font-weight: var(--dobbies-font-weight-normal);
      @media (max-width: $msv-breakpoint-sm) {
        padding: 5px 0;
      }
    }

    &-shipping {
      padding: 20px 0;
      font-weight: var(--dobbies-font-weight-normal);
    }

    &-total {
      font-size: var(--dobbies-body-font-size-l);
      line-height: 30px;
      padding: 20px 0;
      border-top: 1px solid var(--dobbies-bombay-border-color);
      @media (max-width:$msv-breakpoint-xl) {
        font-size: var(--dobbies-body-font-size-l);
        line-height: 22px;
      }
      @media (max-width: $dobbies-container-max-width-tablet) {
        font-size: var(--dobbies-body-font-size-m);
      }
      @media (max-width: $msv-breakpoint-sm) {
        line-height: 22px;
        padding: 10px 0;
      }

      > .msc-order-summary__label {
        font-weight: bold;
        @media (max-width: $msv-breakpoint-sm) {
          font-size: var(--dobbies-body-font-size-m);
        }
      }
      .msc-price {
        &.msc-order-summary__value {
          .msc-price__actual {
            font-size: var(--dobbies-body-font-size-l);
            font-weight: bold;
            @media (max-width: $dobbies-container-max-width-xl) {
              font-size: var(--dobbies-body-font-size-l);
              font-weight: var(--dobbies-font-weight-bold);
            }

            @media (max-width: $dobbies-container-max-width-tablet) {
              font-size: var(--dobbies-body-font-size-m);
            }
          }
        }
      }
    }
  }

  &__label {
    display: inline-block;
    max-width: 165px;
  }

  &__value {
    float: right;
    text-align: right;
  }
}
.promptSpend {
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}