$cartline-lines-item-margin: 0 0 20px;
$cart-cartline-item-padding-bottom: 20px;
$cart-cartline-remove-button-margin: 20px;
$cart-cartline-product-width: 300px;
$cart-cartline-product-image-margin-right: 20px;
$cart-cartline-product-variant-name-padding-left: 5px;
$cartline-item-bopis-container-margin: 0;
$cart-cart-line-bopis-container-line-height: 20px;
$cartline-item-bopis-shipping-margin-bottom: 10px;
$cartline-item-bopis-changestore-padding: 0;
$cartline-item-bopis-fulfillment-store-margin-top: 3px;
$cartline-item-bopis-btn-margin-top: 8px;
$cartline-item-bopis-method-icon-margin-right: 8px;
$cartline-item-discount-price-margin-right: 5px;
$cartline-item-product-savings-margin-left: 20px;
$cartline-item-product-savings-width: 120px;
$cart-cartline-product-quantity-width: 192px;
$cart-cartline-product-quantity-input-width: 30px;
$cart-cartline-product-quantity-spacing: 4px;
$cart-cartline-product-quantity-button-padding: 11px 0;
$cart-cartline-product-quantity-button-width: 26px;
$cart-cartline-product-quantity-button-height: 26px;
$cart-quantity-input-padding: 5px;
$cart-empty-cart-width: 240px;
$cart-line-padding-top: 10px;
$cart-line-card-height: 328px;

//style presets
:root {
  --boddies-cart-border: #{$dobbies-grey};
  --dobbies-cart-font-color: var(--dobbies-font-primary-color);
  --dobbies-cart-font-size: var(--dobbies-body-font-size-m);

  // title
  --dobbies-cart-title-font-color: var(--dobbies-font-primary-color);
  --dobbies-cart-title-font-size: var(--dobbies-body-font-size-l);

  --dobbies-cart-discount-color: var(--dobbies-success-color);
  --dobbies-cart-discount-size: var(--dobbies-body-font-size-l);

  // link
  --dobbies-cart-btn-link-color: var(--dobbies-font-primary-color);
  --dobbies-cart-btn-link-size: var(--dobbies-body-font-size-m);

  --dobbies-cart-label-color: var(--dobbies-font-primary-color);
  --dobbies-cart-label-size: var(--dobbies-body-font-size-l);

  //button
  --dobbies-cart-quantity-btn-bg: #{$dobbies-white};
  --dobbies-cart-quantity-btn-border: var(--dobbies-border-color);

  --dobbies-cart-quantity-input-bg: #{$dobbies-white};
  --dobbies-cart-quantity-input-color: var(--dobbies-font-primary-color);
  --dobbies-cart-quantity-input-border: var(--dobbies-border-color);
  --dobbies-cart-quantity-input-size: var(--dobbies-body-font-size-l);

  // primary button
  --dobbies-cart-primary-btn-bg: var(--dobbies-accent-brand-color);
  --dobbies-cart-primary-btn-font-color: var(--dobbies-font-secondary-color);
  --dobbies-cart-primary-btn-border: var(--dobbies-accent-brand-color);

  // secondary button
  --dobbies-cart-secondary-btn-bg: var(--dobbies-bg-color);
  --dobbies-cart-secondary-btn-font-color: var(--dobbies-font-primary-color);
  --dobbies-cart-secondary-btn-border: var(--dobbies-accent-brand-color);
}

.msc-cart {
  &-lines-item{
      position: relative;
      border-bottom: 1px solid var(--dobbies-gray-border-color);
      margin: $cartline-lines-item-margin;
      padding-bottom: $cart-cartline-item-padding-bottom;
      min-height: $cart-line-card-height;
      @media (max-width: $msv-breakpoint-sm) {
        min-height: auto;
        display: flex;
        flex-wrap: wrap;
      }
  }

  &-line {
      display: flex;
      
      &__bopis__fullfilment {
        display: flex;
        padding-top: 10px;
        align-items: center;
        padding-left: 24px;
        max-width: calc(100% - 80px);
        @media (max-width: $dobbies-container-max-width-xs) {
          flex-direction: column;
          align-items: flex-start;
          padding-left: 0;
        }
        @media (max-width: $msv-breakpoint-sm) {
         padding-bottom: 10px;
         max-width: 100%;
         padding-top: 10px;
      }
      }

      &__bopis__fullfilment-store,
      &__bopis-changestore {
        font-size: var(--dobbies-body-font-size-s);
        line-height: $dobbies-line-height-s;
        color: var(--dobbies-font-primary-color);
      }
      &__bopis-changestore {
        border: 0;
        padding: 0;
        background: none;
        padding-left: 5px;
        cursor: pointer;
        text-decoration: underline;
        font-weight: var(--dobbies-font-weight-bold);
        &:hover {
          text-decoration: none;
        }
        @media (max-width: $dobbies-container-max-width-xs) {
          padding-left: 0;
        }
      }

      &__content {
        width: 100%;
        .msc-alert__header {
          font-size: var(--dobbies-body-font-size-l);
          line-height: 22px;
          font-weight: bold;
          margin: 0 0 10px;
          max-width: 350px;
          @media (max-width: $dobbies-container-max-width-xl) {
            font-size: var(--dobbies-body-font-size-m);
          }
          @media (max-width:$msv-breakpoint-sm) {
            font-size: var(--dobbies-body-font-size-s);
            line-height: $dobbies-line-height-s;
          }
        }
      }

      &-item {
        &-product-discount {
         font-size: 14px;

         @media (max-width: $msv-breakpoint-sm) {
           padding-bottom: 10px;
         }

         .msc-price__actual {
          font-size: 14px;
         }
         
        }
      }

      &__product {
        flex: 1 1 $cart-cartline-product-width;
        padding-right: 50px;
        &-inventory-label {
          @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-cart-font-size), $dobbies-line-height-m);
          color: var(--dobbies-cart-font-color);
          display: block;
        }
        // @media (max-width: $msv-breakpoint-sm) {
        //   margin-bottom: 10px;
        // }

        &-image {
          margin-right: $cart-cartline-product-image-margin-right;
        }

        &-variant {
          &-size,
          &-color,
          &-style,
          &-config{
            .name{
              padding-left: $cart-cartline-product-variant-name-padding-left;
              color: var(--dobbies-cart-font-color);
            }
          }
        }
      }

      &__promo-codes {
        display: inline-block;
        .msc-cart-line__discount-value {
          .msc-price__actual {
            font-size: $dobbies-font-size-s;
          }
        }
      }

      &__product-quantity {
        flex: 0 0 $cart-cartline-product-quantity-width;
        margin: 20px 0;
        @media (max-width: $msv-breakpoint-sm) {
          margin: 0;
        }

        &-label,
        .quantity-label {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: normal;
          color: var(--dobbies-font-primary-color);
          text-transform: uppercase;
          font-weight: var(--dobbies-font-weight-bold);
          @media (max-width: $dobbies-container-max-width-tablet) {
            font-size: var(--dobbies-body-font-size-s);
            line-height: normal;
          }
          @media (max-width: $msv-breakpoint-sm) {
            line-height: 20px;
          }
        }
        
        .quantity {
          .quantity-input {
            font-size: 24px;
            font-weight: var(--dobbies-font-weight-bold);
            border: 0;
            height: 20px;
            color: var(--dobbies-font-primary-color);
            width: $cart-cartline-product-quantity-input-width;
            display: inline-block;
            margin-left: $cart-cartline-product-quantity-spacing;
            margin-right: $cart-cartline-product-quantity-spacing;
            -moz-appearance: textfield;
            padding: 0;
            background: none;
            opacity: 1;
            -webkit-opacity: 1;
            -webkit-text-fill-color: var(--dobbies-font-primary-color);
            position: relative;
            top: 2px;

            @media (max-width: $dobbies-container-max-width-tablet) {
              font-size: 20px;
            }

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
          }

          .increment,
          .decrement {
            background: var(--dobbies-cart-quantity-btn-bg);
            width: $cart-cartline-product-quantity-button-width;
            height: $cart-cartline-product-quantity-button-height;
            font-size: var(--dobbies-body-font-size-s);
            border-radius: 50%;
            background: white;
            border: 1px solid black;
            color: var(--dobbies-font-primary-color);
            padding: 0;
            cursor: pointer;
            outline: none;
          }

          .increment {
            &:before {
              @include dobbies-icon();
              content: $dobbies-icon-plus;
              vertical-align: middle;
              font-weight: normal;
              font-size: 20px;
              position: relative;
              right: 0px;
              top: 0px;
              @media screen and (max-width: $msv-breakpoint-m) {
                top: auto;
                right: auto;
              }
            }
            &.transition {
              background: var(--dobbies-cart-quantity-btn-bg);
            }
            &.disabled {
              cursor: default;
              background-color: $dobbies-gray-1200;
              color: var(--dobbies-gray-border-color);
            }
          }

          .decrement {
            &:before {
              @include dobbies-icon();
              content: $dobbies-icon-minus;
              vertical-align: middle;
              font-weight: normal;
              font-size: var(--dobbies-body-font-size-l);
              position: relative;
              right: 0px;
              top: 0px;
              color: #111;
              @media screen and (max-width: $msv-breakpoint-m) {
                right: auto;
              }
            }
            &.transition {
              background: var(--dobbies-cart-quantity-btn-bg);
            }
            &.disabled {
              cursor: default;
              background-color: $dobbies-gray-1200;
              color: var(--dobbies-gray-border-color);
            }
          }
        }
      }

      &__product-price,
      &__product-savings {
        .msc-price {
            &__actual {
              @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-cart-discount-size), $dobbies-line-height-m);
            }

            &.discount-value {
              .msc-price__strikethrough {
                @include font-content-m-strikethrough();
                display: inline;
                margin-right: $cartline-item-discount-price-margin-right;
              }
            }
        }
      }

      // hide extra price component
      &__product-price {
        display: none;
      }

      &__product-savings {
        @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-cart-discount-size), $dobbies-line-height-m);
        color: var(--dobbies-cart-discount-color);
        text-align: right;
        margin-left: $cartline-item-product-savings-margin-left;
        width: $cartline-item-product-savings-width;

        &-actual {
          .msc-price {
            &__actual {
              @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-cart-label-size), $dobbies-line-height-l);
              display: block;
              color: var(--dobbies-cart-label-color);
            }

            &__strikethrough {
              color: var(--dobbies-cart-font-color);
              display: none;
            }
          }
        }
      }
  
      &__product-title {
        @include vfi();
        font-size: 25px;
        line-height: 34px;
        color: #111;
        margin-bottom: 10px;
        font-weight: 500;
        @media (max-width: $dobbies-container-max-width-xl) {
          font-size: 25px;
          line-height: 30px;
        }
        @media (max-width: $dobbies-container-max-width-l) {
          font-size: 20px;
        }
      }

      &__product-variants,
      &__other-charges-label,
      &__freight-label,
      .shipping-value,
      .other-charge-value {
        @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-cart-font-size), $dobbies-line-height-m);
        color: var(--dobbies-cart-font-color);
      }
  
      &__other-charges-label,
      &__freight-label {
        margin-right: $cart-cartline-product-variant-name-padding-left;
      }

      &__add-to-wishlist {
        @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-cart-btn-link-size), $dobbies-line-height-m);
        @include button-link(var(--dobbies-cart-btn-link-color));
        @include vfi();
        margin-right: $cart-cartline-remove-button-margin;          
        padding: 0;
        text-decoration: underline;

        &:after {
          content: '';
          margin-left: 10px;
          position: relative;
          top: 3px;
        }
      }
      &__remove-item {
        top: 0;
        right: 0;
        margin: 0;
        width: 26px;
        height: 26px;
        padding: 0;
        overflow: hidden;
        position: absolute;
        border-radius: 50%;
        font-size: 13px;
        line-height: 25px;
        text-align: center;
        background: white;
        border: 1px solid black;
        &:before {
          @include dobbies-icon();
          content: $dobbies-icon-close;
          color: #111;
          font-weight: var(--dobbies-font-weight-heavy);
          margin-left: 1px;
          position: relative;
          top: 1px;
        }
      }

      &__bopis-container {
        @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-cart-font-size), $dobbies-line-height-m);
        //margin: $cartline-item-bopis-container-margin;
        margin-bottom: 20px;
        @media (max-width: $msv-breakpoint-sm) {
          margin-bottom: 10px;
        }
      }

      

      &__bopis-method {
        @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-cart-label-size), $dobbies-line-height-l);
        .ship {
          @include add-icon($msv-StoreAlt, before);
          color: var(--dobbies-cart-label-color);
          
          &::before {
            margin-right: $cartline-item-bopis-method-icon-margin-right;
          }
        }

        .pick-up {
          @include add-icon($msv-DeliveryTruck, before);

          &::before {
            margin-right: $cartline-item-bopis-method-icon-margin-right;
          }
        }
      }

      &__bopis-shipping {
        align-self: baseline;
        display: flex;
        align-items: center;
        font-size: var(--dobbies-body-font-size-s);
        margin-bottom: $cartline-item-bopis-shipping-margin-bottom;
        overflow: hidden;

        input {
          @include vfi();
          width: 15px;
          height: 15px;
          margin: 2px 7px 0 0;
          float: left;
      }
    }

      &__bopis-store-pickup {
        align-self: baseline;
        font-size: var(--dobbies-body-font-size-s);
        overflow: hidden;
        margin: 0;
        display: flex;
        align-items: center;
        input {
          @include vfi();
          width: 15px;
          height: 15px;
          margin: 2px 7px 0 0;
          float: left;
        }
      }

      &__bopis-shipping,
      &__bopis-store-pickup {
        input {
          position: relative;
          margin-top: 0;
          &::before {
            width: 15px;
            height: 15px;
            top: 0px;
            left: 0px;
            position: absolute;
            background-color: $white;
            content: '';
            display: inline-block;
            border: 1px solid $dobbies-grey;
            border-radius: 100%;
          }
          &::after {
            content: '';
            display: none;
            width: 9px;
            height: 9px;
            top: 3px;
            left: 3px;
            position: absolute;
            background-color: $dobbies-grey;
            border-radius: 100%;
          }

          &:checked {
            &::after {
              display: inline-block;
            }
          }
        }
      }

      &__bopis-fullfilment {
        &-store {
          margin-top: $cartline-item-bopis-fulfillment-store-margin-top;
          @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-cart-font-size), $dobbies-line-height-m);         
          color: var(--dobbies-cart-font-color);
        }
      }

      &__bopis-btn {
        @include secondary-button(var(--dobbies-cart-secondary-btn-bg), var(--dobbies-cart-secondary-btn-font-color), var(--dobbies-cart-secondary-btn-border));
        margin-top: $cartline-item-bopis-btn-margin-top;
      }
  }

  &__empty-cart {
    .msc-cart-line {
      @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-cart-font-size), $dobbies-line-height-m);
      padding-top: $cart-line-padding-top;
    }

    width: $cart-empty-cart-width;
    margin: auto;
    min-height: 200px;
    .msc-cart-line {
      justify-content: center;
      font-weight: var(--dobbies-font-weight-bold);
    }
    .msc-cart__btn-backtoshopping {
      height: auto;
      font-weight: bold;
      @include primary-button(var(--dobbies-cart-primary-btn-bg), var(--dobbies-cart-primary-btn-font-color), var(--dobbies-cart-primary-btn-border));
      border: 0;
      position: relative;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      position: relative;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-secondary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover,
      &:focus,
      &:active {
        color: var(--dobbies-font-primary-color);
        background: var(--dobbies-bg-color-primary);
        &:after {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
  }
}