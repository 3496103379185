.ms-advice-media-container {
    padding-top: 37px;
    @media (max-width: $dobbies-container-max-width-xl) {
        padding-top: 22px;
    }
    @media (max-width: $dobbies-container-max-width-m) {
        padding-top: 13px;
    }
    @media (max-width: $dobbies-container-max-width-s) {
        padding-top: 9px;
    }
    .ms-template-sample-banner {
        min-height: auto;
        padding: 0;
        margin-bottom: 40px;
        overflow: hidden;
        @media (max-width: $dobbies-container-max-width-xl) {
            margin-bottom: 20px;
        }
        @media (max-width: $dobbies-container-max-width-s) {
            margin-bottom: 15px;
        }
        .ms-content-block {
            &__image {
                img {
                    float: right;
                    @media (max-width: $dobbies-container-max-width-s) {
                        float: none;
                        width: 100%;
                    }
                }
            }
            &__details {
                position: absolute;
                height: auto;
                padding: 0;
                bottom: 92px;
                right: 65%;
                @media (min-width: 1477px) and (max-width: 1599px) {
                    right: 45%;
                }
                @media (max-width: $dobbies-container-max-width-xl) {
                    bottom: 42px;
                }
                @media (min-width: $msv-breakpoint-l) and (max-width: 1199px) {
                    right: 40%;
                    bottom: 42px;
                }
                @media (max-width: $dobbies-container-max-width-m) {
                    right: 40%;
                    bottom: 42px;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    position: relative;
                    bottom: initial;
                    margin-top: 0;
                    margin-left: 0;
                    right: 0;
                }
            }
            &__title {
                background: rgba(255, 255, 255, 0.75);
                position: relative;
                padding: 12px 25px;
                max-width: 400px;
                line-height: initial;
                font-size: $dobbies-advice-heading-font-size;
                color: var(--dobbies-heading-primary-color);
                font-weight: var(--dobbies-font-weight-heavy);
                line-height: 36px;
                @media (max-width: $dobbies-container-max-width-l) {
                    padding: 12px 15px;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    margin: 10px 0 0;
                    padding: 0;
                    text-align: center;
                    max-width: 100%;
                    font-size: var(--dobbies-tile-heading);
                }
            }
        }
    }
    .quick-facts-container {
        > .row {
            justify-content: center;
            .ms-text-block {
                h2 {
                    font-size: var(--dobbies-body-font-size-xml);
                    color: var(--dobbies-heading-primary-color);
                    line-height: 30px;
                    margin-bottom: 10px;
                    font-weight: var(--dobbies-font-weight-bold);
                }
                p {
                    font-size: var(--dobbies-body-font-size-s);
                    margin-bottom: 16px;
                }
            }
            .col-12:first-child {
                .ms-text-block {
                    padding-right: 15px;
                }
            }
            .col-12:nth-child(2) {
                .ms-text-block {
                    border: solid 1px var(--dobbies-green-border-color);
                    margin-top: 10px;
                    h3 {
                        background: var(--dobbies-green-border-color);
                        color: var(--dobbies-font-secondary-color);
                        font-size: var(--dobbies-body-font-size-ml);
                        padding: 10px 15px;
                    }
                    ul {
                        padding: 10px 16px;
                        margin: 0;
                        list-style: none;
                        li {
                            overflow: hidden;
                            margin: 10px 0;
                            padding-left: 20px;
                            &:before {
                                @include dobbies-icon();
                                content: $dobbies-icon-arrow;
                                font-size: var(--dobbies-breadcrumb-icon);
                                transform: rotate(90deg);
                                color: var(--dobbies-breadcrumb-icon-color);
                                font-weight: var(--dobbies-font-weight-heavy);
                                font-size: var(--dobbies-body-font-size-xs);
                                margin: 3px 10px 5px -22px;
                                float: left;
                            }
                        }
                    }
                }
            }
        }
        &.contact-details {
            > .row {
                .col-12:nth-child(2) {
                    .ms-text-block {
                        margin-top: 0;
                        border: 0;
                    }
                }
            }
        }
    }
    .press-release-section {
        margin-left: -10px;
        margin-right: -10px;
        @media (max-width: $dobbies-container-max-width-s) {
            margin-left: 0;
            margin-right: 0;
        }
        > .row {
            justify-content: center;
            .col-12 {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
                padding-bottom: 40px;
                div[class*='imageplacement'] {
                    display: flex;
                    flex-wrap: wrap;
                    padding-bottom: 40px;
                    .ms-content-block {
                        &__link {
                            display: block;
                        }
                        &__image {
                            width: 100%;
                            padding-left: 10px;
                            padding-right: 16px;
                            justify-content: center;
                            @media (min-width: $dobbies-container-max-width-s) {
                                width: 50%;
                                display: flex;
                                flex-direction: column;
                            }
                            @media (max-width: $dobbies-container-max-width-s) {
                                padding-left: 0;
                                padding-right: 0;
                            }
                            img {
                                width: 100%;
                                @media (max-width: $dobbies-container-max-width-s) {
                                    margin: auto;
                                }
                            }
                        }
                        &__details {
                            margin-left: 0;
                            align-items: normal;
                            justify-content: center;
                            @media (min-width: $dobbies-container-max-width-s) {
                                width: 50%;
                                display: flex;
                                flex-direction: column;
                            }
                            .msc-cta__primary {
                                background-color: transparent;
                                border: 1px solid var(--dobbies-breadcrumb-icon-color);
                                color: var(--dobbies-font-primary-color);
                                min-height: 38px;
                                min-width: 215px;
                                line-height: initial;
                                border-radius: 4px;
                                text-decoration: none;
                                line-height: initial;
                                font-size: var(--dobbies-body-font-size-l);
                                font-weight: var(--dobbies-font-weight-normal);
                                cursor: pointer;
                                margin-top: 15px;
                                position: relative;
                                -webkit-transform: perspective(1px) translateZ(0);
                                transform: perspective(1px) translateZ(0);
                                position: relative;
                                -webkit-transition-duration: 0.3s;
                                transition-duration: 0.3s;
                                overflow: hidden;
                                div {
                                    margin: auto;
                                }
                                &:after {
                                    content: "";
                                    position: absolute;
                                    z-index: -1;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    background: var(--dobbies-bg-color-secondary);
                                    -webkit-transform: scaleX(0);
                                    transform: scaleX(0);
                                    -webkit-transform-origin: 0 50%;
                                    transform-origin: 0 50%;
                                    -webkit-transition-property: transform;
                                    transition-property: transform;
                                    -webkit-transition-duration: 0.3s;
                                    transition-duration: 0.3s;
                                    -webkit-transition-timing-function: ease-out;
                                    transition-timing-function: ease-out;
                                  }
                                  &:hover,
                                  &:focus,
                                  &:active {
                                    color: var(--dobbies-font-primary-color);
                                    background: var(--dobbies-bg-color);
                                    text-decoration: none;
                                    &:after {
                                      -webkit-transform: scaleX(1);
                                      transform: scaleX(1);
                                    }
                                }
                            }
                            @media (max-width: $dobbies-container-max-width-s) {
                                text-align: center;
                                margin-top: 20px;
                            }
                        }
                        &__title {
                            color: var(--dobbies-font-primary-color);
                            font-weight: var(--dobbies-font-weight-bold);
                            font-size: var(--dobbies-body-font-size-xml);
                            padding-bottom: 15px;
                            line-height: 36px;
                        }
                        &__text {
                            font-size: var(--dobbies-body-font-size-m);
                            margin-top: 0;
                        }
                    }
                }
                &:first-child {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-bottom: 0;
                }
                &:not(:first-child) {
                    .ms-content-block {
                        &__details {
                            position: initial;
                            height: auto;
                            padding: 0;
                            padding-top: 20px;
                            text-align: center;
                            max-width: 390px;
                            margin: auto;
                        }
                        &__title {
                            color: var(--dobbies-font-primary-color);
                            font-weight: var(--dobbies-font-weight-bold);
                            font-size: var(--dobbies-body-font-size-ml);
                            padding-bottom: 15px;
                            line-height: 28px;
                        }
                        &__text {
                            font-size: var(--dobbies-body-font-size-s);
                            margin-top: 0;
                        }
                    }
                }
                &:not(:first-child) {
                    padding-left: 10px;
                    padding-right: 10px;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                        .ms-content-block {
                            &__link {
                                display: block;
                            }
                            &__image {
                                img {
                                    margin: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .media-page-heading {
        h2 {
            color: var(--dobbies-font-primary-color);
            font-weight: var(--dobbies-font-weight-bold);
            border-bottom: solid 1px rgba(102,102,102,0.5);
            font-size: var(--dobbies-tile-heading);
            margin: 80px 0 30px;
            padding-bottom: 15px;
            line-height: 36px;
            @media (max-width: $dobbies-container-max-width-l) {
                margin-top: 40px;
            }
        }
    }
    .ms-content-block {
        &[data-m-layout='full-width'] {
            min-height: auto;
            .ms-content-block__details {
                padding: 0;
                width: 100%;
                display: block;
                height: 60px;
                text-align: center;
                position: relative;
                @media (max-width: $dobbies-container-max-width-s) {
                    margin-top: 0;
                    height: auto;
                }
            }
            .ms-content-block__cta {
                display: inline-block;
                margin-top: 0;
        
                a,
                .msc-cta__primary,
                .msc-cta__secondary {
                    min-height: 29px;
                    max-height: 29px;
                    line-height: initial;
                    min-width: 215px;
                    border: 1px solid var(--dobbies-breadcrumb-icon-color);
                    font-size: var(--dobbies-body-font-size-s);
                    font-weight: var(--dobbies-font-weight-normal);
                    border-radius: 4px;
                    background-color: transparent;
                    color: var(--dobbies-font-primary-color);
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
                    cursor: pointer;
                    padding: 0;
                    position: relative;
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    overflow: hidden;
        
                    @media (max-width: $msv-breakpoint-l) {
                        min-width: 173px;
                    }
        
                    @media (max-width: $msv-breakpoint-sm) and (min-width: $msv-breakpoint-mbl) {
                        min-width: 120px;
                    }
        
                    @media (max-width: $msv-breakpoint-mbl) {
                        min-width: 100%;
                        margin-left: 0;
                        margin-right: 0;
                    }
        
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: var(--dobbies-bg-color-secondary);
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                        -webkit-transform-origin: 0 50%;
                        transform-origin: 0 50%;
                        -webkit-transition-property: transform;
                        transition-property: transform;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        -webkit-transition-timing-function: ease-out;
                        transition-timing-function: ease-out;
                      }
                      &:hover,
                      &:focus,
                      &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                          -webkit-transform: scaleX(1);
                          transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }
}