$ms-refine-submenu-item-rating-line-height: 28px;
$ms-refine-submenu-item-rating-padding-left: 12px;
$refine-menu-rating-margin-bottom: 10px;
$refine-menu-submenu-item-line-height: 42px;
$refine-menu-submenu-item-multi-select-line-height: 42px;
$refine-menu-submenu-item-multi-select-padding-top: 1px;

.ms-refine-submenu__list
{
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

.ms-refine-submenu-item
{
    display: block;
    line-height: $refine-menu-submenu-item-line-height;
    .refine-submenu-item
    {
        &__rating
        {
            padding-left: $ms-refine-submenu-item-rating-padding-left;
            display: inline-block;
            @include font-content-m(var(--msv-font-weight-light));
        }
    }

    &__label {
        @include font-content-m(var(--msv-font-weight-light));
    }

    .multi-select,
    .multi-select-checked {
        line-height: $refine-menu-submenu-item-multi-select-line-height;
    }

    .msc-rating {
        @include font-content-m(var(--msv-font-weight-light));
        margin-bottom: $refine-menu-rating-margin-bottom;
    }
}

.ms-refine-submenu__toggle_collapsed,
.ms-refine-submenu__toggle_expanded {
    &:after {
        @include dobbies-icon();
        color: var(--dobbies-icon-color);
        font-size: var(--dobbies-icon-font-size-m);
        margin: 5px 0 0;
    }
}

.ms-refine-submenu__toggle_collapsed{
    @include add-icon($msv-ChevronDown, after);
}

.ms-refine-submenu__toggle_expanded{
    @include add-icon($msv-ChevronRight, after);
    @include font-content-l();
}

.ms-refine-submenu-item
{
    &.multi-select
    {
        @include add-icon($msv-Checkbox, before, 400);
        &:before {
            vertical-align: top;
            color: $dobbies-grey;
            padding-top: $refine-menu-submenu-item-multi-select-padding-top;
            font-size: var(--dobbies-body-font-size-m);
        }
    }
    &.multi-select-checked
    {
        @include add-icon($msv-Checkbox-Checked, before, 400);
        &:before {
            vertical-align: top;
            padding-top: $refine-menu-submenu-item-multi-select-padding-top;
            font-size: var(--dobbies-body-font-size-m);
            color: $dobbies-grey;
        }
    }
}
.ms-refine-submenu-item
{
    &.single-select
    {
        @include add-icon($msv-RadioBtnOff, before, 400);
        &:before {
            vertical-align: top;
            padding-top: $refine-menu-submenu-item-multi-select-padding-top;
            font-size: var(--dobbies-body-font-size-m);
            color: $dobbies-grey;
        }
    }
    &.single-select-checked
    {
        @include add-icon($msv-RadioBtnOn, before, 400);
        &:before {
            vertical-align: top;
            padding-top: $refine-menu-submenu-item-multi-select-padding-top;
            font-size: var(--dobbies-body-font-size-m);
            color: $dobbies-grey;
        }
    }
}

.msc-choice-summary__clear-all
{
    @include add-icon($msv-Cancel, after);
}

.ms-refine-submenu__range-refiner {
    .slider__labels-item {
        font-size:  var(--msv-body-font-size-s);
        line-height: $dobbies-line-height-s;
        color: $dobbies-gray-900;
    }
}