/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

body {
    font-family: $dobbies-primary-font-family;
    background: var(--dobbies-bg-color);
    color: var(--dobbies-font-primary-color);
    font-size: var(--dobbies-body-font-size-m);
    @include dobbies-font();
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.pixelp {
    top: 0;
    right: 0;
    opacity: 0.4;
    width: 100%;
    height: 500%;
    z-index: 999;
    position: absolute;
    pointer-events: none;
    //background-image: url('https://images-us-sb.cms.commerce.dynamics.com/cms/api/dzvbdzbmrz/imageFileData/SBkRL');
    //background-image: url('https://images-us-sb.cms.commerce.dynamics.com/cms/api/dzvbdzbmrz/imageFileData/SBkRD');
    //background-image: url('  https://images-us-sb.cms.commerce.dynamics.com/cms/api/dzvbdzbmrz/imageFileData/SBq3L');
    //background-image: url('https://images-us-sb.cms.commerce.dynamics.com/cms/api/dzvbdzbmrz/imageFileData/SBnq8');
}

/* Remove default margin */
body,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

h1 {
    font-size: 30px;
    @media (max-width: $msv-breakpoint-l) {
        font-size: 24px;
    }
}
h2 {
    font-size: 25px;
    @media (max-width: $msv-breakpoint-l) {
        font-size: 20px;
    }
}
h3,
h4,
h5,
h6 {
    font-size: 20px;
    @media (max-width: $msv-breakpoint-l) {
        font-size: 18px;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}

header {
    background: var(--dobbies-bg-color);
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

a:hover {
    text-decoration: underline;
    color: $dobbies-primary;
}

a {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--dobbies-font-primary-color);
    display: inline-flex;
    text-decoration: none;
}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

textarea {
    resize: none;
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
    -webkit-text-fill-color: var(--dobbies-font-primary-color);
    opacity: 1;
}

#renderPage {
    overflow: hidden;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
body .container {
    @media screen and (max-width: $dobbies-container-max-width-xl) {
        max-width: $dobbies-container-max-width-l;
        width: 100%;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        padding-left: 12px;
        padding-right: 12px;
    }
}

input[type='text'],
input[type='number'],
input[type='search'],
textarea,
button {
    font-size: var(--dobbies-body-font-size-m);
}
.textplacement {
    &__left {
        text-align: left;
    }
    &__center {
        text-align: center;
    }
    &__right {
        text-align: right;
    }
}
button:focus,
a:focus {
    outline: none;
}

@mixin ms-content-block-detail-reset {
    position: static;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 0;
}
.product-disabled {
    .msc-empty_image,
    .msc-product__details,
    .msc-product__image > picture img {
        opacity: 0.3;
    }
}
main#main {
    min-height: calc(100vh - 494px);
}
@media screen and (max-width: $msv-breakpoint-mbl-sm) {
    .ms-breadcrumb {
        display: none;
    }
    .ms-checkout {
        .ms-breadcrumb {
            display: block;
        }
    }
}
