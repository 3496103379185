:root {
    --msv-loyalty-signup-font-size: var(--msv-body-font-size-s);
    --msv-loyalty-signup-font-color: var(--dobbies-font-primary-color);

    // heading
    --msv-loyalty-signup-heading-font-size: var(--msv-body-font-size-xl);
    --msv-loyalty-signup-heading-font-color: var(--dobbies-font-primary-color);

    // link
    --msv-loyalty-signup-link-color: #{$dobbies-link-color};
    --msv-loyalty-signup-link-size: var(--msv-body-font-size-m);

    // primary button
    --msv-loyalty-signup-btn-bg: var(--dobbies-accent-brand-color);
    --msv-loyalty-signup-btn-font-color: var(--dobbies-font-secondary-color);
    --msv-loyalty-signup-btn-border: var(--dobbies-accent-brand-color);
}

$dobbies-logo-path: '../../../';

.loyalty-signup-custom-container {
    margin: 0 auto;
    box-sizing: content-box;
    padding: 100px 12px 0;
    @media screen and (max-width: $dobbies-container-max-width-s) {
        width: auto;
    }
    @media screen and (min-width: $msv-container-max-width-s) {
        max-width: 486px;
    }
    @media screen and (min-width: $dobbies-container-max-width-l) {
        max-width: 510px;
    }
    @media screen and (min-width: $dobbies-container-max-width-xl) {
        max-width: 470px;
    }

    .ms-content-block {
        min-height: 0;

        .ms-content-block__details {
            text-align: center;
            @include ms-content-block-detail-reset();
        }

        .ms-content-block__title {
            @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-xxl),$dobbies-line-height-55);
            color: var(--dobbies-club-primary-color);
            text-align: center;
            padding: 105px 0 20px;
            width: 100%;

            @media screen and (min-width: $dobbies-container-max-width-l) and (max-width: $dobbies-container-max-width-xl) {
                padding: 54px 0 24px;
            }
            @media screen and (min-width: $msv-container-max-width-s) and (max-width: $dobbies-container-max-width-l) {
                @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-title-font-size-40),$dobbies-line-height-55);
                padding: 50px 0 15px; 
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-tile-heading),$dobbies-line-height-45);
                padding: 36px 0 20px;
            }
        }
        .ms-content-block__text {
            margin: 0 auto;
            text-align: center;

            p {
                @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-ml),$dobbies-line-height-l);
                color: var(--dobbies-font-secondary-color);
                text-align: center;
    
                @media screen and (max-width: $dobbies-container-max-width-l) {
                    @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-l),$dobbies-line-height-m);
                }
        
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-m),$dobbies-line-height-s);
                    margin-bottom: 30px;
                }
            }
        }
    }

}

.ms-loyalty-signup {

    &__heading {
        @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-m),$dobbies-line-height-s);
        color: var(--dobbies-font-secondary-color);
        padding-bottom: 25px;

        @media screen and (max-width: $dobbies-container-max-width-l) {
            padding-bottom: 12px;
        }
    }

    &__text {
        @include font-content(var(--msv-font-weight-normal), var(--msv-loyalty-signup-font-size), $dobbies-line-height-s);
        color: var(--msv-loyalty-signup-font-color);
        margin-bottom: 10px;
    }

    &__terms {
        @include button-link(var(--msv-loyalty-signup-link-color));
        @include font-content(var(--msv-font-weight-normal), var(--msv-loyalty-signup-link-size), $dobbies-line-height-m);
        display: block;
        margin-bottom: 10px;
    }

    &__btn {
        @include primary-button(var(--msv-loyalty-signup-btn-bg), var(--msv-loyalty-signup-btn-font-color), var(--msv-loyalty-signup-btn-border));
        margin-bottom: 10px;

    }

    &__signin {
        @include primary-button(var(--msv-loyalty-signup-btn-bg), var(--msv-loyalty-signup-btn-font-color), var(--msv-loyalty-signup-btn-border));
    }

    .loyalty-signup-section {
        &__title {
            margin-bottom: 10px;
            margin-top: 47px;
            border-bottom: 4px solid var(--dobbies-club-primary-color);
            text-align: center;

            @media screen and (min-width: $dobbies-container-max-width-l) and (max-width: $dobbies-container-max-width-xl) {
                margin-top: 34px;
            }
            @media screen and (min-width: $msv-container-max-width-s) and (max-width: $dobbies-container-max-width-l) {
                @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-title-font-size-40),$dobbies-line-height-55);
                margin-top: 50px;
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-tile-heading),$dobbies-line-height-45);
                margin-top: 34px;
            }

        }

        &__input-holder {
            margin-top: 24px;

            @media screen and (max-width: $dobbies-container-max-width-s) {
                margin-top: 15px;
            }
    
            p {
                @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-s),$dobbies-line-height-s);
                color: var(--dobbies-font-secondary-color);
                margin-top: 10px;
                > a,
                .__term-and-conditions-link {
                    color: var(--dobbies-font-secondary-color);
                    font-weight: var(--dobbies-font-weight-bold);
                    text-decoration: underline;
                }
            }
    
            label {
                display: block;
                font-size: var(--dobbies-body-font-size-m);
                color: var(--dobbies-font-secondary-color);
                margin-bottom: 20px;
                @media (max-width: $dobbies-container-max-width-l) {
                    margin-bottom: 8px;
                }
            }
    
            input:not(.checkbox) {
                border:1px solid var(--dobbies-font-primary-color);
                color: var(--dobbies-font-header-color);
                font-size: var(--dobbies-body-font-size-m);
                height:60px;
                width: 100%;
                border-radius: 0;
                padding: 0 12px;
                line-height: 20px;
                @media (max-width: $dobbies-container-max-width-l) {
                    height:40px;
                }
                &:focus {
                    outline: 0;
                }
            }
    
            .select-holder {
                position: relative;
                &:after{
                    position: absolute;
                    @include dobbies-icon();
                    color: var(--dobbies-club-primary-color);
                    font-size: var(--dobbies-club-loyalty-link-icon-font-size-l);
                    font-weight: var(--dobbies-font-weight-heavy);
                    content: $dobbies-icon-arrow;
                    transform: rotate(180deg);
                    right: 4px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 15px;
                    background: var(--dobbies-bg-color);
                    text-align: right;
                    width: 22px;
                    pointer-events: none;
                }
            }
            select {
                @extend input;
                background: var(--dobbies-bg-color);
                -webkit-appearance: initial;
            }
            .age-restriction-notification,
            .invalid-input-dob-error {
                color: var(--dobbies-msg-alert-color);
                background: var(--dobbies-bg-color);
                padding: 10px;
                margin: 10px 0 0;
            }
            .invalid-input-dob-error {
                background-color: var(--dobbies-bg-color);
                color: var(--dobbies-font-primary-color);
                &:before {
                    background: var(--dobbies-sale-price-color);
                    width: 32px;
                    height: 32px;
                    min-width: 32px;
                    display: inline-block;
                    content: "18";
                    border-radius: 50%;
                    font-weight: var(--dobbies-font-weight-heavy);
                    color: var(--dobbies-font-secondary-color);
                    text-align: center;
                    line-height: 32px;
                    font-size: var(--dobbies-body-font-size-l);
                    margin-right: 8px
                }
            }
        }

        .gender-not-selected-error,
        .localstore-not-selected-error,
        .loyalty-validation-error {
            color: var(--dobbies-msg-alert-color);
            background: var(--dobbies-bg-color);
            padding: 10px;
            margin: 10px 0 0;
        }

        &__checkbox-holder {
            margin-top: 24px;
            display: flex;
            align-items: flex-start;

            input {
                display: inline-block;
                cursor: pointer;
                width: 14px;
                height: 14px;
                border-radius: 5px;
                margin: 4px 5px 0 0;
                flex: 0 0 14px;
                border-radius: 3px;
                background: var(--dobbies-bg-color);
            }
            a, label {
                display: inline-block;
                @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-s),$dobbies-line-height-xs);
                color: var(--dobbies-font-secondary-color);
                padding: 0;
                cursor: pointer;
                height: auto;
                margin-bottom: 0;
                text-align: left; 
                position: relative;
                top: 2px;
                @media (max-width: $dobbies-container-max-width-s) {
                    top: 2px;
                }
            }

            @media (min-width: $msv-breakpoint-xl) {
                a {
                    top: -2px;
                }
            }
        }

        &__multiple-checkbox {
            display: flex;
            flex-wrap: wrap;
            margin-top: 45px;
            
            .loyalty-signup-section__checkbox-holder {
                width: 50%;
                margin-top: 12px;
            }

            + div {
                padding-top: 15px;
                color: var(--dobbies-bg-color);
            }
        }

        &.contact-permission-section {
            h2 {
                border-bottom: 4px solid var(--dobbies-club-primary-color);
                font-weight: var(--dobbies-font-weight-heavy);
                font-size: var(--dobbies-body-font-size-m);
                color: var(--dobbies-font-secondary-color);
                text-align: center;
                padding: 0 0 25px;
                margin-top: 34px;
            }
            p {
                @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-s),$dobbies-line-height-s);
                color: var(--dobbies-font-secondary-color);
                margin-top: 15px;
                > a,
                .__term-and-conditions-link {
                    color: var(--dobbies-font-secondary-color);
                }
            }
            > .ms-text-block {
                margin-bottom: 30px;
            }
            > .preference-section-checkbox {
                display: flex;
                > .preference-section-checkbox {
                    width: 33.33%;
                    color: var(--dobbies-font-secondary-color);
                    margin-top: 5px;
                    input[type="checkbox"] {
                        width: 14px;
                        height: 14px;
                        border-radius: 0;
                        margin: 2px 5px 0 0;
                        float: left;
                    }
                }
            }
        }

        .ms-loyalty-signup__signin.msc-btn,
        .ms-loyalty-signup__btn.msc-btn {
            @include club-plus-primary-button();
            @include button-verticle-padding-zero();
            width: 100%;
            height: 60px;
            font-size: var(--dobbies-body-font-size-m);
            font-weight: var(--dobbies-font-weight-heavy);
            line-height: 60px;
            margin: 25px 0 30px;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            border: 0;
            @media (max-width: $dobbies-container-max-width-l) {
                max-width: 100%;
                min-width: 100%;
                height: 40px;
                line-height: 36px;
                font-size: var(--dobbies-body-font-size-l);
            }
            @media (min-width: $dobbies-container-max-width-l) {
                margin-bottom: 40px;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                margin-bottom: 100px;
            }
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-primary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-secondary-color);
                background: var(--dobbies-club-primary-color);
                &:before {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
                }
            }
            &:disabled,
            &[disabled] {
                cursor: default;
                border-color: $dobbies-gray-300;
                background-color: $dobbies-gray-300;
                color: var(--dobbies-font-secondary-color);
                &:hover {
                    border-color: $dobbies-gray-300;
                    background-color: $dobbies-gray-300;
                    color: var(--dobbies-font-secondary-color);
                    &:before {
                        background-color: $dobbies-gray-300;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                    }
                }
            }
        }
        .layalty-error-msg {
            color: var(--dobbies-msg-alert-color);
            background: var(--dobbies-bg-color);
            padding: 10px;
            margin: 15px 0 0px;
        }
        &__personal-information {
            &--first-name,
            &--last-name {
                @include font-content();
                color: var(--dobbies-font-secondary-color);
                font-weight: var(--dobbies-font-weight-bold);
                margin: 15px 0;
                span:first-child {
                    margin-right: 15px;
                    font-weight: var(--dobbies-font-weight-normal);
                }
            }
        }
        .ms-account-management-address {
            .msc-address-form {
                &__heading {
                    @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-m),$dobbies-line-height-s);
                    color: var(--dobbies-font-secondary-color);
                    padding-bottom: 25px;
                    margin-bottom: 25px;
                    border-bottom: 4px solid var(--dobbies-club-primary-color);
                    text-align: center;
                    @media screen and (max-width: $dobbies-container-max-width-l) {
                        padding-bottom: 12px;
                    }
                }
                &__input {
                    min-width: initial;
                    max-width: 100%;
                    width: 100%;
                    height: 60px;
                    min-height: 60px;
                    @media screen and (max-width: $dobbies-container-max-width-l) {
                        height: 40px;
                        min-height: 40px;
                    }
                }
                &__label {
                    display: block;
                    font-size: var(--dobbies-body-font-size-m);
                    color: var(--dobbies-font-secondary-color);
                    margin-bottom: 20px;
                    @media screen and (max-width: $dobbies-container-max-width-l) {
                        margin-bottom: 8px;
                    }
                }
                &__item-newline,
                &__item-phone {
                    width: 100%;
                    min-width: initial;
                    max-width: initial;
                    margin-top: 24px;
                }
                &__button-save {
                    width: 49%;
                    max-width: 49%;
                    max-width: initial;
                    min-width: auto;
                    line-height: 18px;
                    background: var(--dobbies-bg-color-secondary);
                    color: var(--dobbies-font-primary-color);
                    border-color: var(--dobbies-bg-color-secondary);
                    border-radius: 0;
                    &:after {
                        background: var(--dobbies-bg-color-primary);
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-secondary-color);
                        background: var(--dobbies-bg-color-secondary);
                    }
                }
                &__button-cancel {
                    border: solid 1px var(--dobbies-bg-color-secondary);
                    color: var(--dobbies-font-secondary-color);
                    width: 49%;
                    float: right;
                    min-height: 52px;
                    text-decoration: none;
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: var(--dobbies-bg-color-secondary);
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                        -webkit-transform-origin: 0 50%;
                        transform-origin: 0 50%;
                        -webkit-transition-property: transform;
                        transition-property: transform;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        -webkit-transition-timing-function: ease-out;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: transparent;
                        &:after {
                            -webkit-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                    }
                    @media screen and (max-width: $msv-breakpoint-mbl-sm) {
                        min-height: 44px;
                        max-height: 44px;
                    }
                }
            }
        }
    }
    &__marketing-consents {
        &--section {
            .checkbox-section {
                display: inline-block;
                width: 50%;
                margin: 5px 0;
                label {
                    @include font-content();
                    color: var(--dobbies-font-secondary-color);
                    margin-left: 3px;
                }
            }
        }
    }
    input[type='number'] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
}

.dobbies-club-free {
    background-color: var(--dobbies-club-background-color);
    background-image: url('#{$dobbies-club-bg-path}dobbies-club-free-bg.png');
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height: calc(100vh - 439px);
    @media screen and (max-width: $dobbies-container-max-width-s) {
        background-size: 1000px auto;
    }
    .centered-header {
        min-height: auto;
        > a {
            @include vfi();
            display: block;
            background: url('#{$dobbies-logo-path}logo.svg') no-repeat top left;
            background-size: 100%;
            width: 210px;
            height: 55px;
            margin: 40px auto;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 169px;
                height: 46px;
                margin: 20px auto;
            }
        }
        .ms-content-block__details {
            display: none;
        }
        &.loyalty-header {
            width: 100%;
            height: 130px;
            left: 0;
            margin-left: 0;
            margin-top: 0;
            background: var(--dobbies-bg-color);
            @media screen and (max-width: $dobbies-container-max-width-s) {
                height: 90px;
            }
        }
    }
}
.dobbies-club-plus {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    min-height: calc(100vh - 439px);
    &.club-signup-bg {
        background-color: var(--dobbies-club-background-color);
        background-image: url('#{$dobbies-club-bg-path}dobbies-club-plus-bg.png');
    }
    @media screen and (max-width: $dobbies-container-max-width-s) {
        background-size: 1000px auto;
    }
    .centered-header {
        min-height: auto;
        > a {
            @include vfi();
            display: block;
            background: url('#{$dobbies-logo-path}logo.svg') no-repeat top left;
            background-size: 100%;
            width: 210px;
            height: 55px;
            margin: 40px auto;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 169px;
                height: 46px;
                margin: 20px auto;
            }
        }
        .ms-content-block__details {
            display: none;
        }
    }
    .ms-loyalty-signup {
        .loyalty-signup-section {
            .ms-account-management-address {
                .msc-address-list {
                    &__button-add {
                        background: var(--dobbies-club-primary-color);
                        color: var(--dobbies-font-primary-color);
                        border-color: var(--dobbies-club-primary-color);
                        font-weight: var(--dobbies-font-weight-bold);
                        &:after {
                            background: var(--dobbies-bg-color-primary);
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            color: var(--dobbies-font-secondary-color);
                            background: var(--dobbies-club-primary-color);
                        }
                    }
                    &__heading {
                        text-align: center;
                        color: var(--dobbies-font-secondary-color);
                        border-bottom: 4px solid var(--dobbies-club-primary-color);
                        @media (max-width: $dobbies-container-max-width-l) {
                            padding: 12px;
                            line-height: 18px;
                        }
                    }
                    &__primary {
                        .msc-address-list {
                            &__primary-heading {
                                color: var(--dobbies-font-secondary-color);
                                font-weight: var(--dobbies-font-weight-bold);
                            }
                            &__primary-list {
                                .msc-address-detail {
                                    color: var(--dobbies-font-secondary-color);
                                }
                            }
                            &__button-primary,
                            &__button-edit,
                            &__button-remove {
                                color: var(--dobbies-font-secondary-color);
                                font-weight: var(--dobbies-font-weight-normal);
                            }
                        }
                    }
                }
            }
        }
    }
}

.dobbies-club-free {
    .loyalty-signup-custom-container.container .ms-content-block .ms-content-block__title {
        color: var(--dobbies-club-font-light-green);
    }
    .ms-content-block {
        .ms-content-block__title {
            color: var(--dobbies-club-font-light-green);
        }
    }
    .ms-loyalty-signup {
        .loyalty-signup-section {
            &__title {
                border-color: var(--dobbies-club-font-light-green);
            }

            .select-holder {
                &:after{
                    color: var(--dobbies-club-font-light-green);
                }
            }

            .ms-loyalty-signup__signin.msc-btn,
            .ms-loyalty-signup__btn.msc-btn {
                @include club-green-button();
                @include button-verticle-padding-zero();
                width: 100%;
                height: 60px;
                font-size: var(--dobbies-body-font-size-m);
                font-weight: var(--dobbies-font-weight-heavy);
                line-height: 60px;
                margin: 25px 0 30px;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                position: relative;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                overflow: hidden;
                border: 0;
                @media (max-width: $dobbies-container-max-width-l) {
                    max-width: 100%;
                    min-width: 100%;
                    height: 40px;
                    line-height: 36px;
                    font-size: var(--dobbies-body-font-size-l);
                }
                @media (min-width: $dobbies-container-max-width-l) {
                    margin-bottom: 40px;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    margin-bottom: 100px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--dobbies-club-loyalty-yellow);
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 0 50%;
                    transform-origin: 0 50%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                }
                &:hover,
                &:focus,
                &:active {
                    color: var(--dobbies-font-secondary-color);
                    background: var(--dobbies-accent-brand-alt-color);
                    &:before {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                    }
                }
                &:disabled,
                &[disabled] {
                    cursor: default;
                    border-color: $dobbies-gray-300;
                    background-color: $dobbies-gray-300;
                    color: var(--dobbies-font-secondary-color);
                    &:hover {
                        border-color: $dobbies-gray-300;
                        background-color: $dobbies-gray-300;
                        color: var(--dobbies-font-secondary-color);
                        &:before {
                            background-color: $dobbies-gray-300;
                            -webkit-transform: scaleX(0);
                            transform: scaleX(0);
                        }
                    }
                }
            }
            .preference-section-checkbox {
                color: var(--dobbies-font-secondary-color);
                margin-top: 5px;
            }
            &.contact-permission-section {
                h2 {
                    border-color: var(--dobbies-club-font-light-green);
                }
            }
        }
    }
    .ms-loyalty-signup {
        .loyalty-signup-section {
            .ms-account-management-address {
                .msc-address-list {
                    &__heading {
                        border-color: var(--dobbies-club-font-light-green);
                        @media (max-width: $dobbies-container-max-width-l) {
                            padding: 12px;
                            line-height: 18px;
                        }
                    }
                    &__button-add {
                        color: var(--dobbies-font-secondary-color);
                        background: var(--dobbies-accent-brand-alt-color);
                        border-color: var(--dobbies-accent-brand-alt-color);
                        &:after {
                            background: var(--dobbies-club-loyalty-yellow);
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            color: var(--dobbies-font-primary-color);
                            background: var(--dobbies-accent-brand-alt-color);
                        }
                    }
                }
                .msc-address-form {
                    &__heading {
                        border-color: var(--dobbies-club-font-light-green);
                        @media (max-width: $dobbies-container-max-width-s) {
                            margin-top: 25px;
                        }
                    }
                    &__button-add {
                        color: var(--dobbies-font-secondary-color);
                        background: var(--dobbies-accent-brand-alt-color);
                        border-color: var(--dobbies-accent-brand-alt-color);
                        &:after {
                            background: var(--dobbies-club-loyalty-yellow);
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            color: var(--dobbies-font-primary-color);
                            background: var(--dobbies-accent-brand-alt-color);
                        }
                    }
                    &__button-save {
                        background-color: var(--dobbies-accent-brand-alt-color);
                        border-color: var(--dobbies-accent-brand-alt-color);
                        color: var(--dobbies-font-secondary-color);
                        &:after {
                            background: var(--dobbies-club-loyalty-yellow);
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            color: var(--dobbies-font-primary-color);
                            border-color: var(--dobbies-accent-brand-alt-color);
                            background: var(--dobbies-accent-brand-alt-color);
                        }
                    }
                    &__button-cancel {
                        border-color: var(--dobbies-accent-brand-alt-color);
                        &:hover,
                        &:focus,
                        &:active {
                            border-color: var(--dobbies-accent-brand-alt-color);
                        }
                    }
                }
            }
        }
    }
}


.loyalty-bolton-modal {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    background-color: $dobbies-white;
    min-height: 400px;
    @media (min-width: $dobbies-container-max-width-s) {
        max-width: 728px;
        min-height: 360px;
    }
    @media (min-width: $dobbies-container-max-width-tablet) {
        max-width: 984px;
        min-height: 400px;
    }
    @media (min-width: $dobbies-container-max-width-xl) {
        max-width: 1145px;
    }


    margin: 0;
    background: $dobbies-white url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/MDahnz) center bottom 20px no-repeat;
    background-size: 132px;
    @media (min-width: $dobbies-container-max-width-tablet) {
        background-position: right 20px bottom 20px;
        background-size: 166px;
    }

    .msc-modal {
        &__close-button {
            top: 15px;
            right: 15px;
            padding: 0;
            @media (min-width: $dobbies-container-max-width-s) {
                top: 20px;
                right: 20px;
            }
            &::before {
                font-size: $dobbies-font-size-ml;
                font-weight: 600;
                @media (min-width: $dobbies-container-max-width-s) {
                    font-size: $dobbies-font-size-s;
                }
            }
        }
        &__content {
            all: unset;
            padding: 40px 0;
            max-width: 560px;
            margin: 0 auto;
            display: block;
            pointer-events: auto;
            padding-bottom: 95px;
            @media (min-width: $dobbies-container-max-width-tablet) {
                padding-bottom: 65px;
                max-width: 703px;
            }
        }
        &__header {
            padding: 0;
            border: none;
            text-align: center;
        }

        &__title {
            font-family: $dobbies-font-azosans-medium;
            font-size: $dobbies-title-font-size-xs;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 28px;
            margin-bottom: 20px;
            padding: 0 40px;
            padding-bottom: 18px;
            border-bottom: 3px solid $dobbies-club-yellow;
            @media (min-width: $dobbies-container-max-width-s) {
                padding: 0;
                padding-bottom: 20px;
                line-height: 40px;
            }
            @media (min-width: $dobbies-container-max-width-tablet) {
                font-size: $dobbies-title-font-size-30;
                margin-bottom: 40px;
                padding-bottom: 40px;
            }
        }

        &__body { 
            p {
                &:last-child,
                &:only-child {
                    margin: 0;
                }
            }
        }
    }
    
    &.already-purchased {

        .msc-modal {
            &__body {
                font-size: $dobbies-font-size-s;
                text-align: center;
                line-height: 18px;
                min-height: 175px;
                display: flex;
                align-items: center;
                @media (min-width: $dobbies-container-max-width-s) {
                    min-height: 168px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    font-size: $dobbies-font-size-l;
                    line-height: 24px;
                }
                p {
                    margin-bottom: 20px;
                    &:last-child {
                        margin: 0;
                    }
                    label {
                        margin: 0;
                        display: block;
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            display: inline-block;
                        }
                    }

                    a {
                        display: inline-block;
                        width: 200px;
                        padding: 5px;
                        text-align: center;
                        border: 1px solid $dobbies-gray-100;
                        background: $dobbies-club-yellow;
                        font-family: $dobbies-font-azosans-medium;
                        font-size: $dobbies-font-size-l;
                        color: $dobbies-black;
                        text-decoration: none;
                        border-radius: 5px;
                        margin-top: 27px;
                        line-height: 26px;
                        height: 36px;
                        @media (min-width: $dobbies-container-max-width-s) {
                            margin-top: 40px;
                        }
                        &:hover {
                            background: $dobbies-primary;
                            color: $white;
                        }
                    }
                }
            }
            &__content { 
                @media (max-width: $dobbies-container-max-width-s) {
                    max-width: 344px;
                }
            }
        }
    }

    &.not-purchased {

        .msc-modal {
            &__body {
                display: none;
            }

            &__header {
                padding: 0;
            }

            &__content {
                padding: 119px 0;
                max-width: 344px;
                @media (min-width: $dobbies-container-max-width-s) {
                    padding: 119px 0;
                    max-width: 543px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    padding: 139px 0;
                }
            } 
            &__title {
                font-size: $dobbies-title-font-size-xs;
                text-transform: none;
                line-height: 40px;
                padding: 0 24px;
                padding-bottom: 22px;
                border-bottom: 3px solid $dobbies-club-yellow;
                margin: 0;
                
                @media (min-width: $dobbies-container-max-width-s) {
                    padding: 0px;
                    padding-bottom: 40px;
                }
            }
        }
    }
}

.loyalty-bolton-btn {
    &[data-m-layout="full-width"] {
        .ms-content-block__details {
            margin: 0 auto;
            .ms-content-block__cta {
                gap: 20px;
            }
            a.msc-cta__primary,
            a.msc-cta__secondary  {
                background-color: transparent;
                border: 1px solid $dobbies-grey;
                color: var(--dobbies-font-primary-color);
                border-radius: 4px;
                text-decoration: none;
                line-height: initial;
                font-size: 16px;
                font-weight: var(--dobbies-font-weight-bold);
                cursor: pointer;
                position: relative;
                transform: perspective(1px) translateZ(0);
                transition-duration: 0.3s;
                overflow: hidden;
                padding: 5px 20px;
                margin: 0px;
                height: auto;
                width: auto;
                line-height: normal;
                min-width: auto;
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: initial;
                    margin: 0;
                }
                div {
                    margin: auto;
                }
                &:after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: $dobbies-yellow;
                    transform: scaleX(0);
                    transform-origin: 0 50%;
                    transition-property: transform;
                    transition-duration: 0.3s;
                    transition-timing-function: ease-out;
                }
                &:hover,
                &:focus,
                &:active {
                    color: var(--dobbies-font-primary-color);
                    background: var(--dobbies-bg-color);
                    text-decoration: none;
                    &:after {
                        transform: scaleX(1);
                    }
                }
                @media screen and (min-width: 768px) and (max-width: 992px) {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .ms-content-block__image {
        display: none;
    }

    &.bolton-content {
        &[data-m-layout="full-width"] {
            .ms-content-block__image {
                display: block;
            }
            .ms-content-block__details {
                position: static;
                height: auto;
                width: auto;
                text-align: center;
                margin-bottom: 40px;
                padding: 0;
            }

            *+.ms-content-block__cta {
                margin: 0 auto;
            } 
            .ms-content-block__title {
                color: var(--dobbies-font-primary-color);
                font-weight: var(--dobbies-font-weight-bold);
                line-height: normal;
                margin-bottom: 20px;
                text-transform: uppercase;
                margin-top: 20px;
                font-size: 30px;
                display: block;
                width: 100%;
                max-width: none;
                @media (max-width: $dobbies-container-max-width-l) {
                    font-size: 24px;
                }
            }
            .ms-content-block__text {
                font-size: var(--dobbies-body-font-size-s);
                font-weight: var(--dobbies-font-weight-normal);
                line-height: 21px;
                margin-bottom: 20px;
                width: 100%;
                max-width: none;
                margin: 0;
            }
        }
    }
}

.msc-modal.show {
    .loyalty-bolton-modal {
        transform: translate(-50%, -50%);
    }
}