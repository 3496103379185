$dobbies-vendor-input-width: 100%;
$dobbies-vendor-input-height: 48px;
$dobbies-vendor-input-background-color: #e8f0fe;

.ms-vendor-on-board-container {
    position: relative;
    padding-top: 140px;
    min-height: calc(100vh - 304px); 
    .ms-content-block {
        text-align: center;
        
        &__image img {
          max-width: 237px;
          margin: auto;
    
          @media (max-width: $dobbies-container-max-width-l) {
            max-width: 208px;
          }
    
          @media (max-width: $msv-breakpoint-mbl-sm) {
            max-width: 169px;
          }
        }
    
        @media (max-width: $msv-breakpoint-xl) {
          min-height: auto;
          padding-top: 35px;
        }
    }
}

.ms-vendor-on-board {
    margin-bottom: 25px;
    padding: 30px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: $msv-breakpoint-m) {
        padding-left: 12px;
        padding-right: 12px;
    }

    &.m-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .text-center {
        text-align: center;
        margin-bottom: 40px;
        padding-left: 12px;
        padding-right: 12px;

        h2 {
            font-weight: var(--dobbies-font-weight-heavy);
            padding-bottom: 16px;
            border-bottom: 3px solid var(--dobbies-accent-brand-color);
            text-align: center;
            margin-bottom: 20px;
        }

        p {
            font-size: inherit;
        }
    }

    &__description {
        overflow-wrap: break-word;
    }

    & > .row > .col-12:not(:first-child) {
        .row {
            margin-bottom: 18px;
        }

        h2 {
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 17px;
            font-size: larger;
        }
    }

    .col-sm-12 {
        padding-left: 12px;
        padding-right: 12px;
    }

    &__item-input {
        width: $dobbies-vendor-input-width;
        height: $dobbies-vendor-input-height;
        margin-bottom: 18px;
        padding-left: 10px;
        padding-right: 10px;
        border:1px solid $dobbies-gray-500;
    }

    &__item-companyInfo {
        height: 120px;
        font-size: inherit;
        padding-top: 8px;
    }

    &__item-label {
        margin-bottom: 10px;
        display: block;
    }

    &__button {
        @include primary-button(var(--msv-sign-in-primary-btn-bg), var(--msv-sign-in-primary-btn-font-color), var(--msv-sign-in-primary-btn-border));
        margin-left: 12px;
        height: 48px;
        border-radius: 0;
        border: 0;
        cursor: pointer;
        padding: 0 35px;
        font-weight: var(--dobbies-font-weight-bold);

        &[disabled] {
            color: white;
            opacity: 0.7;
            -webkit-text-fill-color: white;
            cursor: not-allowed;
        }
    }

    .ms-vendor-error {
        display: inline-block;
        position: relative;
        top: -14px;
        color: red;
        padding-bottom: 8px;
    }
    @media (max-width: $dobbies-container-max-width-s) {
        input[type='submit'] {
            background-color: var(--dobbies-bg-color-primary);
            background-image: none;
            width: calc(100% - 20px);
            padding: 0;
            margin: 0 10px;
        }
    }
}
@media (max-width: $dobbies-container-max-width-s) {
    .ms-vendor-on-board__button {
        background-color: var(--dobbies-bg-color-primary);
        background-image: none;
        width: calc(100% - 20px);
        padding: 0;
        margin: 0 10px;
    }
}
.logo-container {
    .centered-header {
      position: absolute;
      width: 180px;
      height: 50px;
      z-index: 1;
      left: 50%;
      margin-left: -90px;
      margin-top: 50px;
      min-height: auto;
      > a {
        display: block;
        background: url('#{$dobbies-logo-path}logo.svg') no-repeat top left;
        background-size: 100%;
        width: 180px;
        height: 50px;
        border: 0;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            width: 169px;
            height: 46px;
            margin: 20px auto;
        }
        &:hover {
          background-color: transparent;
        }
      }
      .ms-content-block__details {
        display: none
      }
      &.loyalty-header {
        width: 100%;
        height: 130px;
        left: 0;
        margin-left: 0;
        margin-top: 0;
        background: var(--dobbies-bg-color);
        @media screen and (max-width: $dobbies-container-max-width-s) {
            height: 90px;
        }
        }
    }

}