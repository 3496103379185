$msv-histogram-bar-height: 8px;
$msv-histogram-bar-width: 449px;
$msv-histogram-heading-margin-bottom: 34px;
$msv-histogram-progress-border-color: rgb(112, 112, 112);
$msv-progress-border-radius: 10px;

//style presets
:root {
    // heading
    --msv-ratings-histogram-heading-font-size: var(--msv-body-font-size-xl);
    --msv-ratings-histogram-heading-font-color: var(--dobbies-heading-primary-color);

    --msv-ratings-histogram-text-color: var(--dobbies-font-primary-color);
    
    // rating star
    --msv-ratings-histogram-star: var(--dobbies-font-primary-color);

    //progress bar
    --msv-ratings-histogram-progress-bg: $msv-histogram-progress-bg;
    --msv-ratings-histogram-progress-bar-bg: #{$dobbies-primary};
}
.ratings-container {
    .row {
        border-top: 1px solid $dobbies-gray-300;
        padding-top: 20px;

        .col-12:nth-child(1), .col-12:nth-child(2) {
           flex: 1;
           @media screen and ($msv-breakpoint-sm) {
               flex: 0 0 100%;
           }
       }

        @media ( max-width: $dobbies-container-max-width-s)  {
            position: relative;
            border-top: none;

            .col-12:nth-child(2) {
                position: absolute;
                right: 0;
                top: 0;
                text-align: right;
                display: flex;
                justify-content: flex-end;

                .ms-write-review {
                    width: 100%;
                   margin-top: 55px;
                   //margin-right: 215px;
                    > p {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
       
}

.ms-ratings-histogram {
    &__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-ratings-histogram-heading-font-size));
        color: $dobbies-grey;
        display: block;
        margin-bottom: 20px;
        font-size: 25px;
        font-weight: var(--dobbies-font-weight-bold);
        text-transform: uppercase;
        line-height: normal;
        @media ( max-width: $dobbies-container-max-width-s)  {
            margin-bottom: 85px;
            font-size: 20px;
        }
        @media (min-width: 768px) and  (max-width: 992px) {
            font-size: 20px;
        }
    }
    
    // margin-bottom: 20px;

    .msc-progress {
        position: relative;
        height: $msv-histogram-bar-height;
        width: $msv-histogram-bar-width;
        margin: 0 0 0 10px;
        border: 1px solid rgba($msv-histogram-progress-border-color, 0.4);
        border-radius: $msv-progress-border-radius;

        @media (max-width: $msv-breakpoint-l) {
            display: block;
            width: 100%;
            margin-right: 0;
        }
        @media (max-width: $msv-breakpoint-sm) {
            width: 100%;
            margin-right: 0;
        }

        &__bar {
            background-color: #facc00;
            height: $msv-histogram-bar-height;
            position: absolute;
            top: -1px;
            left: 0;
            border-radius: $msv-progress-border-radius;
        }
    }

    .msc-rating {
        display: inline-block;
    }

    .average-rating-text {
        margin-left: 7px;
        font-size: $dobbies-font-size-s;
    }

    &__histogram {
       //margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        @media (max-width: $dobbies-container-max-width-s) {
            margin-right: 0;
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    &__average {
        display: inline-block;
    }

    &__total{
        margin-top: 12px;
    }

    &__item {
        background-color: transparent;
        border-color: transparent;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
        &:last-child {
            margin-bottom: 0;
        }
        @media (max-width: $msv-breakpoint-l) {
            width: 100%;
        }
        @media (max-width: $dobbies-container-max-width-s) {
            padding-right: 0;
            margin-right: 0;
            width: 100%;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__star {
        @include add-icon($msv-FavoriteStarFill, after);
        color: var(--msv-ratings-histogram-star);
        display: flex;
        line-height: 1;
        font-size: var(--dobbies-body-font-size-s);

        &:after {
            margin-left: 4px;
            display: none;
        }
    }

    &__percentage {
        color: var(--msv-ratings-histogram-text-color);
        width: 32px;
        text-align: right;
    }

    &__count {
        position: absolute;
        right: 0;
        top: -12px;
        font-size: 10px;
        color: $dobbies-grey;

        .ms-ratings-histogram__star-count{
            margin-right: 2px;
        }
    }
}