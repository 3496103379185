$msv-store-selector-phone-section-padding-top: 5px;
$msv-store-selector-location-line-shop-address-padding-top: 2px;
$msv-store-selector-location-line-shop-address-padding-right: 15px;
$msv-store-selector-input-height: 48px;
$msv-store-selector-button-border-radius: 0px;
$msv-store-selector-input-border-radius: 2px 0 0 2px;
$msv-store-selector-input-padding-left: 12px;
$msv-store-selector-button-height: 52px;
$msv-store-selector-button-width: 52px;
$msv-store-selector-search-form-margin-bottom: 5px;
$msv-store-selector-location-line-item-margin-top: 10px;
$msv-breakpoint-modal-m: 500px;
$msv-store-selector-msc-modal-content-padding: 40px;
$msv-store-selector-search-form-padding: 0 0 30px 0;

//style presets
:root {
  --dobbies-store-select-heading-font-color: var(--dobbies-font-primary-color);
  --dobbies-store-select-heading-font-size: var(--dobbies-heading-font-size-s);

  --msv-store-select-form-bg: #{$dobbies-gray-100};
  --msv-store-select-form-input-size: var(--msv-body-font-size-l);
  --msv-store-select-form-input-color: var(--dobbies-font-primary-color);
  --msv-store-select-form-input-border: transparent;

  //body title
  --msv-store-select-title-font-size: var(--msv-body-font-size-l);
  --msv-store-select-title-font-color: var(--dobbies-accent-brand-color);

  //body text
  --dobbies-store-select-text-font-size: var(--dobbies-body-font-size-s);
  --dobbies-store-select-text-font-size-m: var(--dobbies-body-font-size-m);
  --dobbies-store-select-text-font-color: var(--dobbies-font-primary-color);

  --msv-store-select-distance-size: var(--msv-body-font-size-s);
  --msv-store-select-distance-color: var(--dobbies-font-primary-color);

  //Link
  --msv-store-select-link-size: var(--msv-body-font-size-s);
  --msv-store-select-link-color: var(--dobbies-font-primary-color);
  
  --msv-store-select-divider-color: #{$dobbies-gray-300};

  // primary button
  --msv-store-select-primary-btn-bg: var(--dobbies-accent-brand-color);
  --msv-store-select-primary-btn-font-color: var(--dobbies-font-secondary-color);
  --msv-store-select-primary-btn-border: var(--dobbies-accent-brand-color);
}

.ms-store-select {
  &__modal-header{
    .msc-modal__title {
      color: var(--dobbies-store-select-heading-font-color);
      font-size: $dobbies-title-font-size-30;
    }
  }

  &__found-locations {
    @include font-content(var(--msv-font-weight-light), var(--dobbies-store-select-text-font-size), $dobbies-line-height-m);
    color: var(--msv-store-select-text-font-color);
    margin-bottom: 20px;
  }

  &__no-locations {
    @include font-content(var(--msv-font-weight-light), var(--dobbies-store-select-text-font-size), $dobbies-line-height-m);
    color: var(--msv-store-select-text-font-color);
  }

  &__search {
    display: flex;
    display: block;
    padding: $msv-store-selector-search-form-padding;
    &-form {
      flex: auto;
      display: flex;
      margin-bottom: $msv-store-selector-search-form-margin-bottom;
      position: relative;

      .MicrosoftMap {
        .as_container_search {
          left: 0;
          top: 43px;
          width: 100%;
        }
      }
    }

    &-see-all-stores {
      cursor: pointer;
      margin: 8px 0;
      text-decoration: underline;
      font-weight: var(--dobbies-font-weight-bold);
    }

    &-input {
      @include vfi();
      flex: auto;
      border: 1px solid var(--msv-store-select-form-input-border);
      border-right: 0;
      border-radius: $msv-store-selector-input-border-radius;
      padding-left: $msv-store-selector-input-padding-left;
      height: $msv-store-selector-input-height;
      background: var(--msv-store-select-form-bg);
      color: var(--msv-store-select-form-input-color);
      font-size: var(--msv-store-select-form-input-size);
    }

    &-button {
      @include primary-button(var(--msv-store-select-primary-btn-bg), var(--msv-store-select-primary-btn-font-color), var(--msv-store-select-primary-btn-border));
      @include vfi();
      align-items: center;
      border-radius: $msv-store-selector-button-border-radius;
      height: $msv-store-selector-button-height;
      min-width: unset;
      order: 2;
      width: $msv-store-selector-button-width;
      display: flex;

      &:before {
        @include msv-icon();
        content: $msv-magnifying-glass;
        width: $msv-store-selector-button-width;
        text-align: center;
      }
    }

    .search-btn-disabled {
      background-color: $dobbies-gray-300;
    }
  }

  &__toggle-view {
    display: none;
    cursor: pointer;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  &__store-hours-details {
    @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-store-select-text-font-size), $dobbies-line-height-m);
    display: flex;
    flex-wrap: wrap;

    .ms-store-select__store-hours-day {
      flex: 0 0 40%;
    }

    .ms-store-select__store-hours-time {
      flex: 0 0 60%;
    }
  }

  &__location {
    padding: 16px 0;
    border-bottom: 1px solid $dobbies-light-grey;

    &-line-item-store-availability {
      text-align: center;
    }
    &-line-item {
      color: var(--msv-store-select-text-font-color);

      &-set-as-preferred-store {
        display: block;
        border: none;
        padding: 0;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        width: fit-content;
        margin-top: 15px;

        &:hover {
            text-decoration: underline;
        }
      }

      &-preferred-store {
          font-weight: var(--msv-font-weight-bold);
          display: block;
          margin-top: 15px;
          @include add-icon($msv-Checkbox-Circle-Checked, before);
          cursor: pointer;
          border: none;
          padding: 0;
          outline: none;
          background-color: transparent;
          &:hover {
              text-decoration: underline;
          }

          &:before {
              margin-right: 10px;
          }
      }
    }

    &-line-item-header,
    &-line-header,
    &-line-stock-status {
      margin-bottom: 10px;
    }

    &-line-stock-status {
      font-weight: var(--dobbies-font-weight-heavy);
    }

    &-line-item-store-name {
      font-size: var(--dobbies-body-font-size-ml);
      font-weight: var(--dobbies-font-weight-heavy);
    }

    &-line-item-store-index {
      color: var(--msv-store-select-title-font-color);
      @include font-content(var(--msv-font-weight-bold),var(--msv-store-select-title-font-size), $dobbies-line-height-l);
      margin-right: 5px;
      &:after {
        content:'.'
      }
    }

    &-line-item-store-distance {
      font-size: var(--dobbies-body-font-size-s);
      font-weight: var(--dobbies-font-weight-normal);
      color: var(--msv-store-select-distance-color);
      
    }

    &-line-header {
      @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-store-select-text-font-size-m), $dobbies-line-height-m);
      margin-left: '1%';
    }

    &-line-store-distance {
      color: $dobbies-gray-500;
    }

    &-line-item-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &-line-shop-address {
      &-section {
        display: flex;
      }
      &-glyph-icon {
        display: none;
        padding-top: $msv-store-selector-location-line-shop-address-padding-top;
        padding-right: $msv-store-selector-location-line-shop-address-padding-right;
        @include add-icon($msv-Shop-Address);
      }
      &-text {
        max-width: 127px;
        @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-store-select-text-font-size), $dobbies-line-height-m);
      }
    }

    &-line-shop-phone {
      &-section {
        display: flex;
        padding-top: $msv-store-selector-phone-section-padding-top;
      }
      &-icon {
        padding-top: $msv-store-selector-location-line-shop-address-padding-top;
        padding-right: $msv-store-selector-location-line-shop-address-padding-right;
        @include add-icon($msv-Phone);
      }
    }

    &-line-select-store {
      @include primary-button(var(--msv-store-select-primary-btn-bg), var(--msv-store-select-primary-btn-font-color), var(--msv-store-select-primary-btn-border));
      @include vfi();
      min-width: 200px;
      padding: 7px 26px;
      font-size: var(--dobbies-body-font-size-s);
      font-weight: var(--dobbies-font-weight-heavy);
      border-radius: 10px;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      position: relative;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      overflow: hidden;
      border: 0;
      cursor: pointer;

      &.clc-btn-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        font-weight: $msv-font-weight-bold;
        .msc-delivery__isCnC-active, 
        .msc-delivery__isCnC{
          width: 23px;
          height: 23px;
        }
      }
      @media screen and (max-width: $dobbies-container-max-width-m) {
        min-width: 170px;
      }
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-secondary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover,
      &:focus,
      &:active {
        color: var(--dobbies-font-primary-color);
        background: var(--dobbies-bg-color-primary);
        &:after {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
  }

  &__terms-link {
    @include font-content(var(--msv-font-weight-normal),var(--msv-store-select-link-size), $dobbies-line-height-s);
    @include vfi();
    color: var(--msv-store-select-link-color);
  }
}
.ms-store-select__location {
  &-line-stock-status {
    &.clc-btn-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #{$dobbies-gray-100};
      font-weight: $msv-font-weight-bold;
      &::before {
        content: '';
        width: 23px;
        height: 23px;
        background-image: url("../../../C&C_Grey_new.png");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 5px;
      }
    }
  }
}

@media screen and (min-width: $msv-breakpoint-m) {

  .ms-store-select__location {
    &-line-item-content {
      flex-direction: row;
      flex-wrap: wrap;
    }
    &-line-item-contact-info,
    &-line-item-store-hours {
      width: 33%;
      margin-right: 1%;
    }
    &-line-item-store-availability {
      width: 32%;
      text-align: center;
    }
    &-line-stock-status {
      position: relative;
      font-size: var(--dobbies-body-font-size-m);
      font-weight: var(--dobbies-font-weight-heavy);
      line-height: $dobbies-line-height-m;
    }
  }
}

@media screen and (max-width: $msv-breakpoint-m) {
  .ms-store-select {
    &__toggle-view {
      display: block;
      background-color: transparent;
      float: right;
      border: none;
      outline: none;
    }

    &__modal-header{
      .msc-modal__title {
        font-size: var(--dobbies-body-font-size-xml);
      }
    }
    .msc-modal__content {
      margin: 30px 0;
    }
  }
}

@media screen and (max-width: $dobbies-container-max-width-s) {
  .ms-store-select {
    .msc-modal__content {
      padding: $msv-store-selector-msc-modal-content-padding;
    }

    &__location {
      padding: 16px 0 0;
      &-line-select-store {
        min-width: 150px;
      }
      &-line-item {
        &-store-hours,
        &-store-availability,
        &-contact-info {
          margin-bottom: 15px;
          min-width: 50%;
        }
        &-contact-info {
          order: 1;
        }
        &-store-hours {
          order: 3;
          width: 100%;
        }
        &-store-availability {
          order: 2;
          text-align: center;
        }
      }
    }
  }
}