.carousel .control-prev:before,
.carousel.carousel-slider .control-prev:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    // content: $dobbies-icon-arrow !important;

    font-family: '$dobbies-icon-font-family';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
    content: $dobbies-icon-arrow;
    font-size: var(--dobbies-icon-font-size-m);
    transform: rotate(268deg);
    //font-weight: var(--dobbies-font-weight-heavy);
    font-size: 26px;
    margin-left: -4px;
    color: darkgray;
}

.carousel .control-prev.control-arrow {
    left: 0;
    opacity: 1;
    display: inline-block;
}
.carousel .control-prev.control-arrow::before {
    background-color: #f8f8f8 !important;
}
.carousel .control-next.control-arrow {
    right: 0;
    opacity: 1;
    display: inline;
}
.carousel .control-next.control-arrow:before {
    background-color: #f8f8f8 !important;
}
//Remove double Carousel view.
.ms-cta-section .carousel:nth-child(2) {
    display: none !important;
}
.carousel .control-next:before,
.carousel.carousel-slider .control-next:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    // content: $dobbies-icon-arrow !important;

    font-family: '$dobbies-icon-font-family';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
    content: $dobbies-icon-arrow;
    font-size: var(--dobbies-icon-font-size-m);
    transform: rotate(90deg);
    //font-weight: var(--dobbies-font-weight-heavy);
    font-size: 26px;
    margin-left: -4px;
    color: darkgray;
}
// .carousel .control-disabled.control-arrow {
//     opacity: inherit !important;
//     display: block !important;
//     filter: alpha(opacity=0);
// }
.carousel.carousel-slider .control-arrow:hover {
    background: none;
}
.ms-order-details__sales-line-btn-buy-it-again.msc-btn {
    font-size: 16px;
    padding: 6px;
    min-width: 140px;
    height: 40px;
    width: 0px;
}

//VSI customization
//Remove White margins left/right and at the top.
//Task 11024: Header & Mega menu changes
@media (max-width: 992px) {
    .ms-header {
        box-shadow: none;
        padding-left: 0px;
        padding-right: 0px;
    }
}

//UI further Improvements
.refiner-add-remove {
    position: sticky !important;
    // width: 18%;
    // z-index: 2;
    top: 0 !important;
    height: 100vh;
    overflow-y: scroll;
    // max-height: calc(100vh - 2px);
    // overflow: auto;
}
#renderPage {
    overflow: initial;
}

#renderPage:after {
    display: block;
    clear: both;
    content: '';
}

//fade in plp

.fade-in-plp {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.8s;
    z-index: 9;
}

//marketing consent checkboxes spacing
.CheckboxMultiSelect label:not(:first-child) {
    padding: 10px;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// giftcard landing page
// .ms-content-block__text {
//     height: 133px;
//     text-align: justify;
//     width: 95%;
//     margin-top: 16px;
// }

// a.msc-cta__primary {
//     border-radius: 1px solid black;
//     border: 1px solid;
//     width: 96%;
//     text-align: center;
//     align-items: center;
//     justify-content: center;
//     border-radius: 5px;
//     height: 30px;
//     margin-left: 9px;
// }
// h4.ms-content-block__title {
//     margin-left: 17px;
// }
// p {
//     margin-left: 16px;
// }
.col-auto.col-sm-4.col-md-4.col-lg-4 {
    height: 450px;
}

// dropdown in giftcards

.postcode-validation {
    margin-bottom: 10px;
    position: relative;
    .post-code-errorbox {
        > .post-code {
            border: 0;
        }
        .post-code-error {
            color: var(--dobbies-msg-alert-color);
            font-size: var(--dobbies-body-font-size-s);
            font-weight: var(--dobbies-font-weight-bold);
            background: $dobbies-club-more-room;
            margin-top: 5px;
            padding: 5px;
            &:before {
                margin-right: $msv-alert-icon-margin;
                @include msv-icon();
                content: $msv-IncidentTriangle;
            }
        }
    }
    .auto-suggestion {
        > div {
            border: 1px solid var(--dobbies-font-primary-color);
            background-color: var(--dobbies-bg-color);
            border-top: 0;
            height: 232px;
            overflow: auto;
            //position: absolute;
            left: 0;
            width: 100%;
            > div {
                padding: 5px 10px;
                cursor: pointer;
                &:hover {
                    background: var(--dobbies-header-grey);
                }
            }
        }
    }
    .address-link {
        display: inline-block;
        padding: 17px;
        font-weight: var(--dobbies-font-weight-bold);
        text-decoration: underline;
        cursor: pointer;
        @include add-dobbies-icon($dobbies-icon-arrow, after);
        &:after {
            color: var(--dobbies-bg-color-secondary);
            font-weight: var(--dobbies-font-weight-bold);
            margin-left: 5px;
            transform: rotate(180deg);
        }
        @media (max-width: $dobbies-container-max-width-m) {
            padding: 10px 0;
        }
    }
}
.postcode-validation {
    .auto-suggestion {
        background: var(--dobbies-bg-color);
        color: var(--dobbies-font-primary-color);
        margin: -10px 1px 0;
        max-height: 173px;
        overflow-y: auto;
        > div {
            > div {
                padding: 10px 15px;
                line-height: 18px;
                transition: all 0.3s;
                border-bottom: 1px solid var(--dobbies-bombay-border-color);
                &:hover {
                    background-color: $dobbies-vlight-grey;
                }
            }
        }
    }
    .msc-address-form__input {
        margin-bottom: 10px;
    }
}

//prompt message ui as requested in task: 11142

//new home page slider
.homepage-slider {
    .ms-content-block__image {
        &::before {
            content: '';
            background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/MD3Wxj?pubver=1);
            width: 180px;
            height: 180px;
            position: absolute;
            display: block;
            right: 80px;
            top: 240px;
            background-size: cover;
            background-repeat: no-repeat;
            @media (max-width: $msv-breakpoint-l) {
                display: none;
            }

            @media screen and (min-width: 991.98px) {
                width: 150px;
                height: 150px;
                position: absolute;
                display: block;
                right: 50px;
                top: 175px;
                background-size: cover;
                background-repeat: no-repeat;
            }

            @media screen and (min-width: 1199.98px) {
                width: 200px;
                height: 200px;
                position: absolute;
                display: block;
                right: 50px;
                top: 175px;
                background-size: cover;
                background-repeat: no-repeat;
            }

            @media screen and (min-width: 1399.98px) {
                width: 230px;
                height: 230px;
                position: absolute;
                display: block;
                right: 90px;
                top: 250px;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
        @media (max-width: $msv-breakpoint-sm) {
            img {
                height: 550px;
            }
        }
    }

    .ms-content-block__details {
        height: 100% !important;
        justify-content: center !important;
        display: flex !important;
        position: absolute !important;
        top: 0 !important;
        flex-direction: column !important;
        width: 50vw !important;
        margin-left: 100px !important;

        .ms-content-block__text {
            font-weight: 500;
            font-size: 60px;
            line-height: 60px;
            width: 45vw;
            font-family: inherit;

            p {
                margin-left: 16px;
                color: #e8c307;
                text-align: left;

                @media (max-width: $msv-breakpoint-sm) {
                    color: #e8c307;
                    text-align: left;
                }
            }
            @media (max-width: $msv-breakpoint-sm) {
                font-weight: 500;
                font-size: 45px;
                line-height: 50px;
                width: 350px !important;
                font-family: inherit;
                height: 80% !important;
                margin-top: 100px;
                margin-left: -10px !important;
            }
            @media screen and (min-width: $msv-breakpoint-l) {
                font-weight: 500;
                font-size: 40px;
                line-height: 1.2;
                width: 50vw;
                font-family: inherit;
            }
            @media (min-width: 768px) and (max-width: 991px) {
                font-weight: 500;
                font-size: 50px;
                line-height: 50px;
                width: 75vw;
            }
            @media screen and (min-width: 1200px) {
                font-size: 50px;
            }
            @media screen and (min-width: 1550px) {
                font-size: 60px;
            }

            @media screen and (min-width: 1855px) {
                font-size: 70px;
            }
        }
        .ms-content-block__cta {
            margin-left: 10px;
            margin-top: 90px;

            a.msc-cta__primary {
                background-color: #e8c307 !important;
                display: inline-block !important;
                margin: 8px !important;
                min-width: 160px !important;
                text-align: center !important;
                align-items: center !important;
                justify-content: center !important;
                text-decoration: none !important;
                width: 96% !important;
                line-height: 2px !important;
                border-radius: 4px !important;
                font-weight: 500 !important;
                border: none !important;
                color: black !important;
                height: 35px;
                transition: 0s !important;

                @media (max-width: $msv-breakpoint-sm) {
                    margin-left: 0px !important;
                    width: 200px !important;
                    height: 40px !important;
                    text-align: center !important;
                    align-items: center !important;
                    justify-content: center !important;
                    font-weight: 500 !important;
                    transition: 0s !important;
                }
                @media screen and (min-width: $msv-breakpoint-l) {
                    margin-left: 0px !important;
                    width: 200px !important;
                    height: 40px !important;
                    text-align: center !important;
                    align-items: center !important;
                    justify-content: center !important;
                    font-weight: 500 !important;
                    transition: 0s !important;
                    line-height: 10px !important;
                }
                @media screen and (min-width: 1550px) {
                    margin-top: 80px !important;
                }
                @media screen and (min-width: 1920px) {
                    margin-top: 40px !important;
                }
            }
            @media (max-width: $msv-breakpoint-sm) {
                margin-top: 10px;
            }
            @media screen and (min-width: $msv-breakpoint-l) {
                margin-top: 50px;
            }
            @media (min-width: 769px) and (max-width: 991px) {
                margin-top: 120px;
            }
            @media screen and (min-width: 1200px) {
                margin-top: 80px;
            }
            @media screen and (min-width: 1920px) {
                margin-top: 150px;
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            margin-top: -80px;
        }

        @media (max-width: $msv-breakpoint-sm) {
            margin-left: 15px !important;
            height: 80% !important;
            justify-content: center !important;
            display: flex !important;
            position: absolute !important;
            top: 0 !important;
            flex-direction: column !important;
            width: 100% !important;
            padding: 10px !important;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            margin-left: 0px !important;
        }
    }
}

//VSI CUSTOMIZATION -START - Task 11167: PLP hover image flip
.msc-product__image > picture:nth-child(2) {
    transition: all 0.5s;
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
}
 
.msc-product__image:hover > picture:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    opacity: 1;
}
 
.msc-product__image {
    position: relative;
}
//VSI CUSTOMIZATION -END - Task 11167: PLP hover image flip

// balance check gift card
button.ms-gift-card-balance-check__btn-apply.msc-btn.disabled{
    border: 1px black solid !important;
    border-radius: 5px;
    height: 36px;
    margin-top: 12px;
    background-color: #006338;
    color: #fff;
    font-size: 15px;
    line-height: 13px !important;
}
button.ms-gift-card-balance-check__btn-apply.msc-btn {
    border: 1px black solid;
    border-radius: 5px;
    height: 36px;
    margin-top: 20px;
    background-color: #006338;
    color: #fff;
    font-size: 15px;
    line-height: 13px !important;
}
input.ms-gift-card-balance-check__input-text.form-control {
    border: 2px black solid;
}
// Customization end for gift card balance check

// gift card method for checkout
input.ms-checkout-gift-card__input-text.form-control {
    background: white;
    border: 1px solid grey;
}
form.ms-checkout-gift-card__form {
    background-color: #efeef3;
}
