$msc-account-welcome-title-margin-bottom: 30px;
$msc-account-welcome-title-margin-top: 32px;
$msc-account-welcome-title-border-bottom-color: $dobbies-gray-500;
$msc-account-welcome-title-border-bottom-style: solid;
$msc-account-welcome-title-border-bottom-thickness: 1px;
$msc-account-welcome-title-links-padding-top: 12px;
$msc-account-tile-heading-icon-padding: 12px;
$msc-generic-tile-heading-margin-bottom: 0.5rem;
$msc-link-padding-right: 10px;
$dobbies-account-header-fill: $dobbies-gray-400;
$dobbies-account-header-font: 45px;
$dobbies-account-menu-border: #121111;
//$dobbies-account-welcome-title-margin-top: 20px;
$dobbies-account-welcome-title-margin-top: 48px;
$dobbies-account-welcome-title-margin-bottom: 25px;
$dobbies-account-icons-padding-right: 10px;
$dobbies-account-icons-font-size: var(--dobbies-body-font-size-ml);
$dobbies-account-header-breakpoint-l: 1200px;
$dobbies-account-header-breakpoint-xs: 576px;
$dobbies-account-breakpoint-m-2: 769px;

// style presets
:root {
    --msv-account-landing-title-font-color: var(--dobbies-font-primary-color);

    // title
    --msv-account-landing-title-font-size: var(--msv-body-font-size-xl);
    --msv-account-landing-title-desktop-font-size: var(--msv-body-font-size-xxl);

    // tiles
    --msv-account-landing-tile-heading-size: var(--msv-body-font-size-l);
    --msv-account-landing-tile-heading-color: var(--dobbies-font-primary-color);
    --msv-account-landing-tile-text-size: var(--msv-body-font-size-m);
    --msv-account-landing-tile-text-color: var(--dobbies-font-primary-color);

    // link
    --msv-account-landing-link-color: #{$dobbies-link-color};
    --msv-account-landing-link-size: var(--dobbies-body-font-size-m);
}

.ms-account {
    &-header-container {
        background-color: $dobbies-account-header-fill;
        margin: 20px 0 40px 0;
        @media (max-width: $dobbies-container-max-width-tablet) {
            margin-bottom: 20px;
        }

        .ms-text-block {
            // height: 129px;
            display: flex;
            align-items: center;
            max-width: 1445px;
            width: 100%;
            margin: auto;
            padding: 20px 0 20px 15px;
            // padding-left: 20px;
            // padding-right: 20px;

            h1 {
                font-size: 30px;
                text-transform: uppercase;
                font-weight: var(--dobbies-font-weight-bold);
            }

            @media screen and (min-width: 1280px) and (max-width: 1476px) {
                height: 116px;
                max-width: 1068px;
            }

            @media screen and (min-width: 993px) and (max-width: 1049px) {
                height: 116px;
                max-width: 1030px;
            }

            @media screen and (min-width: 1050px) and (max-width: 1280px) {
                height: 116px;
                max-width: 1030px;
            }

            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                // max-width: 1140px;
                h1 {
                    font-size: 24px;
                    padding: 0 15px;
                }
            }

            @media screen and (max-width: $msv-container-max-width-m) {
                // max-width: 960px;
            }

            @media screen and (max-width: $msv-container-max-width-s) {
                padding-left: 12px;
                padding-right: 12px;
            }

            @media screen and (max-width: $dobbies-account-header-breakpoint-xs) {
                justify-content: center;
            }
        }
    }

    &-body-container {
        .default-container {
            padding-left: 15px;
            padding-right: 0;

            @media screen and (max-width: $msv-container-max-width-s) {
                padding-left: 0;
                padding-right: 0;
            }

            @media (max-width: $msv-breakpoint-m) {
                .ms-wishlist-items__heading {
                    margin-top: 0;
                }
            }
        }

        .ms-account-button-links {
            @media (max-width: $msv-breakpoint-l) {
                padding-left: 0;
            }

            div[data-m-layout='my-account-banner'] {
                &.ms-content-block {
                    min-height: unset;
                    padding: 30px 0;
                    margin-bottom: 40px;
                    border-bottom: $msc-account-welcome-title-border-bottom-thickness $msc-account-welcome-title-border-bottom-style
                        var(--dobbies-green-border-color);
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-welcome-tile {
        border-bottom: $msc-account-welcome-title-border-bottom-thickness $msc-account-welcome-title-border-bottom-style $dobbies-grey;
        color: var(--msv-account-landing-title-font-color);
        display: block;
        // margin-top: $dobbies-account-welcome-title-margin-top;
        @media (max-width: $dobbies-container-max-width-tablet) {
            margin-top: 0;
            margin-bottom: 10px;
        }

        &__heading {
            @include font-content(var(--msv-font-weight-normal), var(--msv-account-landing-title-font-size), $dobbies-line-height-xl);
        }

        @media (min-width: $msv-breakpoint-m) {
            &__heading {
                @include font-content(var(--dobbies-font-weight-heavy), var(--dobbies-body-font-size-m), $dobbies-line-height-xxl);
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            margin-top: 0;
        }
    }

    &-generic-tile {
        &__link {
            @include vfi();
            color: var(--msv-account-landing-link-color);
            text-decoration: underline;
            font-size: var(--dobbies-body-font-size-s);
        }
        &.order-tile {
            .ms-account-generic-tile__heading {
                @include add-dobbies-icon($dobbies-icon-cart, before, $icon-weight: $dobbies-outline-icon-weight);
                &:before {
                    padding-right: $dobbies-account-icons-padding-right;
                    font-size: var(--dobbies-icon-font-size-m);
                    color: #111111;
                }
            }
        }

        &.profile-tile {
            .ms-account-generic-tile__heading {
                @include add-dobbies-icon($dobbies-icon-account, before, $icon-weight: $dobbies-outline-icon-weight);
                &:before {
                    padding-right: $dobbies-account-icons-padding-right;
                    font-size: var(--dobbies-icon-font-size-m);
                    color: #111111;
                }
            }
        }

        &:not(.order-tile):not(.profile-tile) {
            .ms-account-generic-tile__heading {
                @include add-dobbies-icon($dobbies-icon-dobbies, before, $icon-weight: $dobbies-outline-icon-weight);
                &:before {
                    padding-right: $dobbies-account-icons-padding-right;
                    font-size: $dobbies-account-icons-font-size;
                }
            }
        }
    }

    &-wishlist-tile__heading {
        @include add-dobbies-icon($dobbies-icon-favourite, before, $icon-weight: $dobbies-outline-icon-weight);
        &:before {
            padding-right: $dobbies-account-icons-padding-right;
            font-size: var(--dobbies-icon-font-size-m);
            color: #111111;
        }
    }

    &-order-templates-tile__heading {
        @include add-dobbies-icon($dobbies-icon-dobbies, before, $icon-weight: $dobbies-outline-icon-weight);
        &:before {
            padding-right: $dobbies-account-icons-padding-right;
            font-size: var(--dobbies-icon-font-size-s);
            color: #111111;
        }
    }

    &-address-tile__heading {
        @include add-dobbies-icon($dobbies-icon-home, before, $icon-weight: $dobbies-outline-icon-weight);
        &:before {
            padding-right: $dobbies-account-icons-padding-right;
            font-size: var(--dobbies-icon-font-size-m);
            color: #111111;
        }
    }

    &-loyalty-tile__heading {
        @include add-dobbies-icon($dobbies-icon-club, before);
        &:before {
            padding-right: $dobbies-account-icons-padding-right;
            font-size: var(--dobbies-icon-font-size-s);
            color: #111111;
        }
    }

    &-organization-users-tile__heading {
        @include add-icon($msv-OrganizationUsers, before);
        &:before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-customer-balance-tile__heading {
        @include add-icon($msv-StoreAlt, before);
        &:before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-generic-tile,
    &-wishlist-tile,
    &-order-templates-tile,
    &-address-tile,
    &-loyalty-tile,
    &-organization-users-tile,
    &-customer-balance-tile {
        margin-top: 20px;
        margin-bottom: 20px;

        @media (max-width: $msv-breakpoint-l) {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        &__heading {
            @include font-content(var(--msv-font-weight-bold), var(--msv-account-landing-tile-heading-size), normal);
            color: var(--msv-account-landing-tile-heading-color);
            margin-bottom: 10px;
            font-size: var(--dobbies-body-font-size-s);
            font-weight: var(--dobbies-font-weight-bold);
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        &__links,
        .ms-dobbies-link {
            @include font-content-underline(var(--msv-font-weight-normal), var(--msv-account-landing-link-size), $dobbies-line-height-m);
            // padding-top: $msc-account-welcome-title-links-padding-top;
            text-decoration: underline;
            //padding-left: 30px;
            //text-decoration: underline;
        }

        &__link {
            @include vfi();
            color: var(--msv-account-landing-tile-heading-color);
            padding-right: $msc-link-padding-right;
            text-decoration: unset;
            font-size: var(--dobbies-body-font-size-s);
        }

        &__link-button {
            @include vfi();
            color: var(--msv-account-landing-link-color);
            cursor: pointer;
        }

        &__description {
            @include font-content(var(--msv-font-weight-normal), var(--msv-account-landing-tile-text-size), $dobbies-line-height-m);
            color: var(--msv-account-landing-tile-text-color);
            //display: none;
            //padding-left: 30px;
        }

        &__value {
            @include font-content(var(--msv-font-weight-bold), var(--msv-account-landing-tile-text-size), $dobbies-line-height-m);
            color: var(--msv-account-landing-tile-text-color);

            &:before,
            &:after {
                content: ' ';
            }
        }
    }

    &-wishlist-tile {
        margin-bottom: 40px;

        @media (max-width: $dobbies-container-max-width-tablet) {
            margin-bottom: 20px;
        }
    }

    &-menu {
        // padding-top: 38px;
        // padding-bottom: 30px;

        @media (max-width: $msv-breakpoint-l) {
            padding-bottom: 0px;
        }

        @media (max-width: $msv-breakpoint-l) and (min-width: $dobbies-account-breakpoint-m-2) {
            // margin-bottom: -15px;
            //padding-left: 10px;
            //padding-right: 10px;
        }

        & > h2 {
            font-size: var(--dobbies-body-font-size-s);
            font-weight: var(--dobbies-font-weight-bold);
            border-bottom: 1px solid $dobbies-account-menu-border;
            padding: 0 0 15px 15px;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        .ms-dobbies-account__cta {
            display: grid;

            .account-menu__list--item {
                text-decoration: none;

                &.active {
                    font-weight: var(--dobbies-font-weight-bold);
                    pointer-events: none;
                }
                &:hover {
                    font-weight: normal;
                }
            }

            a {
                font-size: var(--dobbies-body-font-size-s);
                border-bottom: 1px solid $dobbies-account-menu-border;
                // line-height: 44px;
                width: 100%;
                padding: 15px 0;
                text-decoration: none;
                padding-left: 15px;
            }
        }

        @media (max-width: $msv-breakpoint-l) {
            & > h2 {
                border-bottom: 0;
                padding-bottom: 20px;
                padding-left: 0;
            }

            .ms-dobbies-account__cta {
                display: none;
            }

            .account-menu {
                position: relative;
                min-height: 46px;
                border: 1px solid var(--dobbies-border-color);
                display: flex;
                align-items: center;
                padding-left: 12px;
                font-size: var(--dobbies-body-font-size-m);
                margin-bottom: 20px;
                background-color: var(--dobbies-bg-color);

                &:after {
                    @include dobbies-icon();
                    content: $dobbies-icon-arrow;
                    transform: rotate(-180deg);
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    margin-top: -9px;
                    background: var(--dobbies-bg-color);
                    font-weight: var(--dobbies-font-weight-heavy);
                    font-size: var(--dobbies-body-font-size-l);
                    color: #facc00;
                    z-index: 1;
                    pointer-events: none;
                }

                > p {
                    width: 100%;
                }

                &__list--hide,
                &__list--show {
                    position: absolute;
                    top: 44px;
                    left: 0px;
                    font-size: var(--dobbies-body-font-size-m);
                }

                &__list--hide {
                    display: none;
                }

                &__list--show {
                    display: block;
                    width: 100%;
                    background: var(--dobbies-bg-color);
                    z-index: 2;
                    border-top: 0;
                    outline: var(--dobbies-border-color) solid 1px;

                    .account-menu__list--item {
                        padding-bottom: 0px;
                        padding-left: 0px;
                        padding-top: 0px;

                        &:hover,
                        &:focus {
                            background-color: var(--dobbies-font-primary-color);
                            color: var(--dobbies-font-secondary-color);
                            outline: unset;
                            text-decoration: none;

                            a {
                                color: var(--dobbies-font-secondary-color);
                                border: 0;
                                text-decoration: none;
                            }
                        }

                        &:first-child {
                            padding-top: 0px;
                        }

                        &.active {
                            display: none;
                        }
                        > a {
                            display: block;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
.ms-loyalty {
    .ms-account-menu {
        > h2 {
            color: var(--dobbies-font-secondary-color);
            border-bottom-color: #8d8e91;
        }
        .ms-dobbies-account__cta {
            a {
                color: $dobbies-grey;
                border-bottom-color: #8d8e91;
            }
        }
    }
    .ms-account-welcome-tile {
        border-bottom-color: $dobbies-grey;
        &__heading {
            color: var(--dobbies-font-secondary-color);
        }
    }
}

.ms-loyalty-club-premium {
    background-color: #2b2e35;
    .ms-account-menu {
        > h2 {
            color: var(--dobbies-font-secondary-color);
            border-bottom-color: #8d8e91;
        }
        .ms-dobbies-account__cta {
            a {
                color: var(--dobbies-font-secondary-color);
                border-bottom-color: #8d8e91;
            }
        }
    }
    .ms-account-welcome-tile {
        border-bottom-color: $dobbies-grey;
        &__heading {
            color: var(--dobbies-font-secondary-color);
        }
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .ms-dobbies-account__cta.account-menu {
        > p {
            display: none;
        }
    }
}
.ms-content-block {
    &[data-m-layout='account-page-banner'] {
        min-height: initial;
        margin-bottom: 30px;
        picture,
        img {
            width: 100%;
        }
    }
}

.ms-account-banner-container {
    padding-bottom: 20px;
}

.ms-account-body-container {
    .ms-wishlist-items {
        &__product-price {
            .msc-price__actual {
                font-weight: var(--dobbies-font-weight-bold);
            }
        }

        &__product-add-button {
            font-weight: var(--dobbies-font-weight-bold);
        }
    }
}

.ms-account-body-container {
    padding-top: 20px;
}
