$msv-checkout-billing-address-margin: 5px;
$dobbies-checkout-billing-address-margin: 10px;
$msv-checkout-input-margin-left: 0px;
$msv-checkout-input-dimensions: 16px;
$msv-checkout-shipping-address-label-margin-bottom: 12px;
$msv-checkout-shipping-address-checkbox-margin-left: 8px;
$msv-checkout-address-detail-margin-left: 0px;
$msv-checkout-address-detail-padding-bottom: 20px;
$dobbies-checkout-address-detail-margin-bottom: 20px;

//style presets
:root {
  --msv-checkout-address-font-size: var(--msv-body-font-size-m);
  --msv-checkout-address-font-color: var(--dobbies-font-primary-color);

  // heading
  --msv-checkout-address-heading-font-color: var(--dobbies-font-primary-color);
  --msv-checkout-address-heading-font-size: var(--msv-body-font-size-s);
  
  // secondary button
  --msv-checkout-address-secondary-btn-bg: var(--dobbies-bg-color);
  --msv-checkout-address-secondary-btn-font-color: var(--dobbies-font-primary-color);
  --msv-checkout-address-secondary-btn-border: var(--dobbies-accent-brand-color);

}

.ms-checkout-billing-address {
  &__heading {
    color: var(--msv-checkout-address-heading-font-color);
    margin-bottom: $dobbies-checkout-billing-address-margin;
    font-weight: var(--dobbies-font-weight-heavy);
    @include font-content(var(--dobbies-font-weight-heavy), var(--dobbies-body-font-size-m), $dobbies-line-height-m);
  }

  .msc-address-detail {
    @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-m), $dobbies-line-height-m);
    color: var(--msv-checkout-address-font-color);
    margin-left: $msv-checkout-address-detail-margin-left;
    margin-bottom: $dobbies-checkout-address-detail-margin-bottom;
    @media (max-width: $dobbies-container-max-width-s) {
      margin-bottom: 0;
    }

    &__item-address-detail_Phone {
      padding-top: 0;
      overflow: hidden;
    }
    &__item-phone {
      transform: rotate(90deg);
      float: left;
      margin-top: 4px;
    }
  }

  &__shipping-address-label {
    display: flex;
    margin-bottom: $msv-checkout-shipping-address-label-margin-bottom;
  }

  &__input {
    min-width: $msv-checkout-input-dimensions;
    min-height: $msv-checkout-input-dimensions;
    margin-left: $msv-checkout-input-margin-left;
  }

  &__shipping-address-checkbox-text {
    @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-m), $dobbies-line-height-m);
    margin-left: $msv-checkout-shipping-address-checkbox-margin-left;
    align-self: center;
  }
}

.ms-checkout-shipping-address {
  .msc-address-select__button-add {
    @include secondary-button(var(--msv-checkout-address-secondary-btn-bg),var(--msv-checkout-address-secondary-btn-font-color), var(--msv-checkout-address-secondary-btn-border));
    background: var(--dobbies-bg-color);
    border: 0;
    padding: 5px 15px;
    min-width: auto;
    text-decoration: underline;
    margin: 0 0 15px 33px;
    cursor: pointer;
    &:hover {
      background: var(--dobbies-bg-color);
      color: var(--dobbies-heading-primary-color);
    }
  }

  .msc-address-detail {
    @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-m), $dobbies-line-height-m);
    color: var(--msv-checkout-address-font-color);
    padding-bottom: $msv-checkout-address-detail-padding-bottom;

    &__item-address-detail_Phone {
      padding-top: 0;
      overflow: hidden;
    }
    &__item-phone {
      transform: rotate(90deg);
      float: left;
      margin-top: 4px;
    }
  }
}