$carousel-width: 100%;
$carousel-strip-width: 100%;
$flipper-color: var(--dobbies-font-primary-color);
$flipper-top-position: -22px;
$flipper-right-position: 56px;
$flipper-next-right-position: 0.5px;
$flipper-width: 48px;
$flipper-disabled-opacity: 0.4;
$flipper-outline-offset: 2px;
$flipper-border-radius: 50%;
$flipper-border-color: transparent;

$vert-carousel-height: 100%;
$vert-carousel-strip-width: 100%;
$vert-flipper-height: 40px;
$carousel-control-hover-opacity: 0.9;
$top-picks-slide-width-desktop: 33.33%;
$top-picks-slide-width-desktop-plp: 33.33%;
$top-picks-slide-width-desktop-mini: 33.33%;
$top-picks-slide-width-tablet: 50%;
$top-picks-slide-width-mobile: 100%;
$negative-spacing-left: -24px;
$negative-spacing-right: -19px;
$negative-spacing-top: -74px;
$carousel-spacing: 0 10px;
$carousel-outer-spacing: 20px 0 20px;
$carousel-inner-spacing: 0 10px;
$top-picks-spacing-outer: 24px 0 0;
$carousel-heading-outer-spacing: 0 0 27px;
$carousel-item-heading-outer-spacing: 12px 0 16px;
$carousel-link-outer-spacing: 15px;

.msc-ss-carousel {
    position: relative;
    touch-action: pan-y pinch-zoom;
    width: $carousel-width;
    height: auto;
    padding: $carousel-inner-spacing;
    @media (max-width: $dobbies-container-max-width-s) {
        padding: 0;
    }

    .msc-flipper {
        position: absolute;
        .msi-chevron-left {
            &:before {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                font-size: var(--dobbies-icon-font-size-m);
                transform: rotate(-90deg);
                font-weight: var(--dobbies-font-weight-heavy);
            }
        }

        .msi-chevron-right {
            &:before {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                font-size: var(--dobbies-icon-font-size-m);
                transform: rotate(90deg);
                font-weight: var(--dobbies-font-weight-heavy);
            }
        }

        &:not(.disabled) {
            @include hover-focus {
                color: $flipper-color;
                outline: 1px dashed $dobbies-black;
                opacity: $carousel-control-hover-opacity;
                text-decoration: none;
                outline: none;
            }
        }
    }

    &__flipper {
        color: $flipper-color;
        background: inherit;
        border: $flipper-border-color;
        border-radius: $flipper-border-radius;
        left: auto;
        outline-offset: $flipper-outline-offset;
        right: $flipper-right-position;
        top: $flipper-top-position;
        width: $flipper-width;
        height: $flipper-width;
    }

    &__flipper:disabled {
        opacity: $flipper-disabled-opacity;
    }

    &__flipper {
        z-index: 1;
        transform: translate(0, -50%);
    }

    &__flipper--next {
        right: $flipper-next-right-position;
    }

    &-strip {
        width: $carousel-strip-width;
        height: auto;
        overflow: hidden;
    }

    &-slide {
        display: inline-block;
        left: 0;
        padding-inline-start: 0;
        position: relative;
        transition: left cubic-bezier(0.16, 1, 0.29, 0.99) 0.667s;
        white-space: nowrap;
    }
}

.msc-ss-carousel-vert {
    position: relative;
    width: auto;
    height: $vert-carousel-height;
    display: inline-block;
    touch-action: pan-x pinch-zoom;

    &-slide {
        white-space: nowrap;
        height: auto;
        position: relative;
    }

    &-item {
        display: block;
        white-space: normal;
    }

    &-strip {
        height: $vert-carousel-strip-width;
        width: auto;
        overflow: hidden;
        img {
            max-width: initial;
        }
    }

    &__flipper {
        color: $flipper-color;
        background: inherit;
        border: $flipper-border-color;
        border-radius: $flipper-border-radius;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, 0);
        top: -($vert-flipper-height / 2);
        @include add-icon($msv-ChevronUp);

        &:disabled {
            opacity: $flipper-disabled-opacity;
        }
    }

    &__flipper--next {
        top: auto;
        bottom: -($vert-flipper-height / 2);
        @include add-icon($msv-ChevronDown);
    }
}
.dobbies-top-picks-carousel {
    padding: $top-picks-spacing-outer;
    margin: $carousel-heading-outer-spacing;
    max-width: 1196px;
    margin: auto;
    &.top-picks-plp {
        margin: 0;
        padding: 0;
        border-top: 0;
        max-width: 100%;
        border-bottom: solid 2px $dobbies-light-grey;
        .msc-ss-carousel {
            padding: 0;
        }
        .msc-ss-carousel__flipper {
            background: rgba(255, 255, 255, 0.6);
            border-radius: 0;
            &.disabled {
                display: none;
            }
        }

        .msc-ss-carousel-strip {
            width: auto;
            margin: 0 -10px;
        }
        .dobbies-top-picks-carousel__item {
            width: $top-picks-slide-width-desktop-plp;
            @media screen and (max-width: $dobbies-container-max-width-l) {
                width: $top-picks-slide-width-desktop-mini;
            }
            @media screen and (max-width: $dobbies-container-max-width-m) {
                width: $top-picks-slide-width-tablet;
            }
            @media screen and (max-width: $msv-breakpoint-mbl-sm) {
                width: $top-picks-slide-width-mobile;
            }
        }
    }
    .msc-ss-carousel-slide {
        width: 100%;
        margin: $carousel-outer-spacing;
        text-align: center;
    }
    &__item {
        display: inline-block;
        max-width: none;
        padding: $carousel-spacing;
        overflow: hidden;
        text-align: left;
        vertical-align: top;
        width: $top-picks-slide-width-desktop;
        @media screen and (max-width: $dobbies-container-max-width-l) {
            width: $top-picks-slide-width-desktop-mini;
        }
        @media screen and (max-width: $dobbies-container-max-width-m) {
            width: $top-picks-slide-width-tablet;
        }
        @media screen and (max-width: $dobbies-container-max-width-s) {
            width: $top-picks-slide-width-mobile;
            padding: 0 20px;
        }
        > a {
            display: block;
            overflow: hidden;
            text-align: center;
            text-decoration: none;
            > picture {
                width: 100%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        &__heading {
            @include tile-heading();
            margin: 20px 0;
            font-weight: var(--dobbies-font-weight-bold);

            // @media screen and (min-width: $msv-container-max-width-s) and (max-width: $msv-container-max-width-l) {
            //   font-size: 20px;
            // }
        }
        &__paragraph {
            @include tile-paragraph();
            min-height: 0;
            margin-bottom: 20px;
        }
        &__link {
            @include tile-link();
            margin: $carousel-link-outer-spacing;
            margin-top: 0;
            font-weight: $msv-font-weight-500;
        }
    }
    &__heading {
        @include tile-heading();
        text-align: center;
        //margin-bottom: 10px;
        line-height: normal;
        text-transform: uppercase;
        font-weight: var(--dobbies-font-weight-bold);
        // @media (max-width: $dobbies-container-max-width-xl) {
        //   margin-top: 5px;
        // }
        //   @media (max-width: $msv-breakpoint-l) {
        //     font-size: 20px;
        // }
    }
    .dobbies-top-picks-carousel__item__link:hover {
        color: #5a9e33;
    }
    .msc-ss-carousel {
        &__flipper {
            position: absolute;
            top: 50%;
            left: -15px;
            z-index: 2;
            width: 30px;
            //margin-top: $negative-spacing-top;
            padding: 0;
            cursor: pointer;
            &--next {
                left: inherit;
                right: -15px;
                margin-left: inherit;
            }
            :before {
                font-size: 26px;
                color: var(--dobbies-icon-color);
            }
            @media (max-width: $dobbies-container-max-width-s) {
                padding: 0;
                left: -5px;
                &--next {
                    right: -5px;
                    left: inherit;
                }
            }
            &.disabled {
                display: none;
            }
        }
    }

    @media (max-width: $msv-breakpoint-xl) {
        padding: 17px 0 0;
        .msc-ss-carousel-slide {
            //margin: 10px 0 11px;
        }
        &__item {
            width: 33.3%;
            &__heading {
                //margin: 10px 0 12px;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        margin: 0 0 25px;
        &__item {
            width: 50%;
        }
    }

    @media (max-width: $dobbies-container-max-width-s) {
        padding: 22px 0 0;
        &__item {
            width: 100%;
            &__heading {
                //margin: 6px 0 15px;
            }
        }
        .msc-ss-carousel-slide {
            margin: 10px 0 17px;
        }
    }
}
@mixin quantity {        
    .quantity {
        width: 100px;
        height: 24px;
        text-align: center;
        border: 1px solid;
        padding: 0 13px;
        border: 1px solid $dobbies-gray-100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            width: 70px;
            height: 16px;
            padding: 0 5px;
        }
        // @media screen and (max-width: 400px) {
        //     width: 80px;
        //     padding: 0 8px;
        // }
        &-input {
            height: 100%;
            width: 30px;
            padding: 0;
            font-size: $dobbies-font-size-s;
            font-weight: $dobbies-font-weight-normal;
            outline: none;
            margin: 0 auto;
            border: none;
            -moz-appearance:textfield;
            background: transparent;
            top: 0;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                font-size: 10px;
            }
        }
    
        .increment,
        .decrement {
            width: auto;
            height: 10px;
            border: none;
            background: transparent;
            display: inline-flex;
            padding: 0;
            color: $dobbies-grey;

            &::before {
                @include dobbies-icon();
                font-size: 16px;
                line-height: 14px;
                margin-top: -2px;

                @media screen and (max-width: $dobbies-container-max-width-s) {
                    font-size: $dobbies-font-size-s;
                }
            }
        }

        .increment {
            font-size: 17px;
            &::before {
                content: $dobbies-icon-plus;
                margin-top: -1.3px;
            }

            &.disabled {
                &::before {
                    color: $dobbies-light-grey;
                }
            }
        }
        
        .decrement {
            &::before {
                content: $dobbies-icon-minus;
                margin-top: -2px;
            }

            &.disabled {
                &::before {
                    color: $dobbies-light-grey;
                }
            }
        }
    }
}

.msc-active-image-dobbies-product-carousel.msc-active-image.trending_now {
    margin-bottom: 50px;
    max-width: 302px;
    margin: 0 auto;
    @media (min-width: 400px) {
        max-width: 340px;
    }
    @media (min-width: $dobbies-container-max-width-s) {
        max-width: 760px;
    }
    @media (min-width: $dobbies-container-max-width-tablet) {
        max-width: 695px;
        margin: 0;
        margin-bottom: 50px;
    }
    @media (min-width: $dobbies-container-max-width-xl) {
        max-width: 972px;
    }

    .msc-active-image-wrapper {
        display: none;
    }
}

.trending_now {
    .ms-product-collection {
        @media (min-width: $dobbies-container-max-width-tablet) {
            padding: 0;
        }
        @media (min-width: $dobbies-container-max-width-xl) {
            padding: 0 30px;
        }

        &__heading {
            margin-top: 30px;
        }

        .msc-ss-carousel {
            .msc-ss-carousel__flipper {
                @media (max-width: $dobbies-container-max-width-s) {
                    margin-left: -27px;
                }
                &--next {
                    @media (max-width: $dobbies-container-max-width-s) {
                        margin-right: -30px;
                    }
                }
                :before {
                    color: $dobbies-primary;
                }
                @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s){
                    margin-left: -10px;
                    :before {

                        margin-left: -8px;
                    }
                }
            }
            .msc-ss-carousel-slide { 
                .ms-product-collection__item {
                    border: none;
                    @media (max-width: $dobbies-container-max-width-s) {
                        width: 170px;
                        padding: 0 10px;
                        min-height: 306px;
                    }
                    @media (max-width: 400px) {
                        width: 150px;
                        padding: 0 5px;
                        min-height: 306px;
                    }
                    @media (min-width: $dobbies-container-max-width-s) {
                        width: 243px;
                        padding: 0 20px;
                        min-height: 422px;
                    }
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        min-height: 412px;
                        width: 223px;
                        padding: 0 10px
                    }
                    @media (min-width: $dobbies-container-max-width-xl) {
                        min-height: 412px;
                    }

                    &:hover {
                        border: none;
                    }
                }
            }
        }
    
        .msc-product__product-quantity {
            @media (max-width: $dobbies-container-max-width-s) {
                padding-top: 6px;
                margin-bottom: 6px;
            }
            .quantity {
                @media (max-width: $dobbies-container-max-width-s) {
                    width: 80px;
                    height: 20px;
                }
                
                .decrement{
                    &::before {
                        font-size: 12px;
                        margin-top: 0;
                    }
                }

                .increment {
                    &::before {
                       font-size: 13px; 
                    }
                }

                &-label {
                    font-size: 10px;
                }
            }
        }

        .msc-product__checkbox {
            @media (max-width: $dobbies-container-max-width-tablet) {
                width: 18px;
                height: 18px;
            }
            &:checked+svg {
                @media (max-width: $dobbies-container-max-width-s) {
                    right: 12px;
                    top: 8px;
                    width: 8px;
                }
            }
        }

        .msc-product {
            display: block;
            border-bottom: none;

            &__details {
                padding: 0;
                min-height: initial;
                width: 100%;
            }

            a {
                border: none;
                &:hover {
                    text-decoration: none;
                    .msc-product__title {
                        text-decoration: underline;
                    }
                }
                .msc-product__title {
                    margin-top: 11px;
                    margin-bottom: 13px;
                    min-height: initial;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 19px;
                    width: 150px;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        margin-top: 10px;
                        margin-bottom: 11px;
                        width: 200px;
                    }

                    @media screen and (min-width: $dobbies-container-max-width-xl) {
                        margin-top: 6px;
                        margin-bottom: 8px;
                    }
                    @media screen and (max-width: 400px) {
                        width: 145px;
                    }
                }
            }
    
            .msc-price {
                display: block;
                margin-bottom: 12px;
                span {
                    font-weight: bold;
                    line-height: 14px;
                    color: $dobbies-grey;
                }

                .msc-price__salePrice {
                    .msc-price {
                        &__actual {
                            color: $dobbies-sale-price;
                        }
                    }
                }
            }
    
            &__product-quantity {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 7px;
                padding-top: 7px;
                border-top: 0.5px solid $dobbies-gray-100;
                margin-top: 0;
                .quantity-label {
                    text-transform: capitalize;
                    font-size: $dobbies-font-size-s;
                    font-weight: $dobbies-font-weight-normal;
                    margin: 0;
                }

                @include quantity;
            }

            .msc-empty_image,
            .msc-product__image a .msc_image {
                height: 210px;
                width: 100%;
                @media (min-width: $dobbies-container-max-width-s) {
                    height: 283px;
                }
            }
        }

        .ms-wishlist-items__product-add-button {
            max-height: 30px;
            margin-top: 0;
            padding: 0;
            border: none;
            font-size: $dobbies-font-size-s;
            @media (min-width: $dobbies-container-max-width-s) {
                max-height: 40px;
            }
            @media (min-width: $dobbies-container-max-width-tablet) {
                max-height: 30px;
            }
            &::before {
                display: none;
            }
        }
    }
}

.cart_empty {
    .msc-active-image-dobbies-product-carousel.msc-active-image.trending_now {
        margin: 0 auto;
        margin-bottom: 50px;
    }
}