.msc-modal {
    &.dobbies-club-renewal-popup {
        &.show .dobbies-club-renewal-popup__dialog {
                transform: translate(-50%, -40%);
                transition: transform 0.3s ease-out;
                @include breakpoint(md) {
                    transform: translate(-50%, -50%);
                }
        }
    }

    &__dialog {
        &.dobbies-club-renewal-popup__dialog {
            width: 383px;
            max-width: inherit;
            position: absolute;
            left: 50%;
            top: 40%;
            margin: 0;
            transition: 0.3s ease-in;
            @include breakpoint(md) {
                width: 516px;
                top: 50%;
            }
            @include breakpoint(lg) {
                width: 643px;
            }
            @include breakpoint(xxl) {
                width: 773px;
            }

            .msc-modal {
                &__header {
                    justify-content: flex-start;
                    padding: 0 0 12px;
                    border: 0;
                    position: relative;
                    @include breakpoint(lg) {
                        padding: 0 0 24px;

                    }
                    h5.msc-modal__title {
                        .dobbies-club-renewal-popup__title {
                            color: $dobbies-club-grey;
                            font-weight: bold;
                            font-size: $dobbies-font-size-ml;
                            text-align: center;
                            @include breakpoint(md) {
                                font-size: $dobbies-title-font-size-25;
                            }
                            @include breakpoint(lg) {
                                font-size: $dobbies-title-font-size-30;
                            }
                        }
                    }

                    .msc-modal__close-button {
                        width: 27px;
                        height: 27px;
                        position: absolute;
                        top: -30px;
                        right: -15px;
                        padding: 0;
                        cursor: pointer;
                        text-align: center;

                        &:before {
                            @include dobbies-icon();
                            content: $dobbies-icon-close;
                            font-weight: bold;
                            font-size: var(--dobbies-icon-font-size-s);
                        }
                        @include breakpoint(md){
                            top: -45px;
                            right: -25px;
                        }
                        @include breakpoint(lg) {
                            top: -45px;
                            right: -45px;
                            font-size: var(--dobbies-icon-font-size-l);
                        }
                    }
                }

                &__content {
                    padding: 39px 20px 28px 20px;
                    border-radius: 5px;
                    background: var(--dobbies-bg-color);
                    @include breakpoint (md) {
                        padding: 60px 36px 39px 35px;
                    }
                    @include breakpoint (lg) {
                        padding: 49px 45;
                    }

                    @include breakpoint (xl) {
                        padding: 59px 54px;
                    }

                    & .dobbies-club-renewal-popup__body {
                        display: flex;
                        flex-direction: column;
                        gap: 32px;
                        align-items: center;
                        margin-bottom: 20px;

                        & .dobbies-club-renewal__message {
                            font-size: 15px;
                            font-weight: 500;
                            font-style: normal;
                            text-align: center;
                            color: $dobbies-club-grey;  
                            @include breakpoint (md) {
                                font-size: $dobbies-font-size-ml;
                            }
                            @include breakpoint (lg) {
                                font-size: $dobbies-title-font-size-25;
                            }
                        }
                        & .dobbies-club-renewal__cta {
                            width: 248px;
                            font-size: 15px;
                            font-weight: 500;
                            border-radius: 5px;
                            @include club-plus-primary-button(var(--dobbies-club-loyalty-yellow), $dobbies-dark-grey , var(--dobbies-club-loyalty-yellow));
                            padding: 6px;
                            border: 0;
                            -webkit-transform: perspective(1px) translateZ(0);
                            transform: perspective(1px) translateZ(0);
                            position: relative;
                            -webkit-transition-duration: 0.3s;
                            transition-duration: 0.3s;
                            overflow: hidden;
                            justify-content: center;
                            &:after {
                              content: "";
                              position: absolute;
                              z-index: -1;
                              top: 0;
                              left: 0;
                              right: 0;
                              bottom: 0;
                              background: var(--dobbies-bg-color-primary);
                              -webkit-transform: scaleX(0);
                              transform: scaleX(0);
                              -webkit-transform-origin: 0 50%;
                              transform-origin: 0 50%;
                              -webkit-transition-property: transform;
                              transition-property: transform;
                              -webkit-transition-duration: 0.3s;
                              transition-duration: 0.3s;
                              -webkit-transition-timing-function: ease-out;
                              transition-timing-function: ease-out;
                            }
                            &:hover,
                            &:focus,
                            &:active {
                              color: var(--dobbies-font-secondary-color);
                              background: var(--dobbies-bg-color-secondary);
                              &:after {
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                              }
                            }
                                
                            @include breakpoint (md) {
                                font-size: 20px;
                                padding: 10px;
                            }
                            @include breakpoint (lg) {
                                width: 309px;
                                font-size: 25px;
                            }
                            @include breakpoint (xxl) {
                                width: 371px;
                            }
                        }
                    }
                }

                &__footer {
                    .homepopup-btn {
                        background-color: transparent;
                        border: 1px solid $dobbies-grey;
                        color: var(--dobbies-font-primary-color);
                        border-radius: 4px;
                        text-decoration: none;
                        line-height: initial;
                        font-size: 16px;
                        font-weight: var(--dobbies-font-weight-bold);
                        cursor: pointer;
                        position: relative;
                        transform: perspective(1px) translateZ(0);
                        transition-duration: 0.3s;
                        overflow: hidden;
                        padding: 5px 20px;
                        margin: 0px;
                        height: auto;
                        width: auto;
                        line-height: normal;
                        // @media (max-width: $dobbies-container-max-width-s) {
                        //     flex: initial;
                        //     margin: 0;
                        // }
                        // div {
                        //     margin: auto;
                        // }
                        &:after {
                            content: '';
                            position: absolute;
                            z-index: -1;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: $dobbies-yellow;
                            transform: scaleX(0);
                            transform-origin: 0 50%;
                            transition-property: transform;
                            transition-duration: 0.3s;
                            transition-timing-function: ease-out;
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            color: var(--dobbies-font-primary-color);
                            background: var(--dobbies-bg-color);
                            text-decoration: none;
                            &:after {
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
    }

    &.dobbies-homepage-popup {
        .dobbies-club-renewal-popup__dialog {
            @media (max-width: $dobbies-container-max-width-s) { 
                width: 460px;
            }
            @media (max-width: $msv-breakpoint-mbl-sm) { 
                max-width: 80%;
            }
            .msc-modal__header {
                .msc-modal__close-button {
                    top: -25px;
                    right: -5px;
                    @include breakpoint(md){
                        top: -30px;
                        right: -20px;
                    }
                    &::before {
                        font-size: $msv-font-size-ml;
                    }
                }
            }
            .msc-modal__content {
                @include breakpoint (lg) {
                    padding: 45px 40px;
                }
    
                @include breakpoint (xl) {
                    padding: 45px 40px;
                }
            }
        }
    }
}