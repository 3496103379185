$product-collection-item-width: 25%;
$product-collection-carousel-padding-top: 15px;
$product-collection-margin-bottom-desktop: 0px;
$product-collection-heading-padding-right: 100px;
$product-collection-heading-margin-top: 20px;
$product-collection-heading-margin-bottom: 20px;
$product-collection-item-padding: 5px 10px 5px;
$product-collection-price-strikethrough-line-height: $dobbies-line-height-m;
$product-collection-tooltip-left: 0px;
$product-collection-tooltip-top: 0px;
$product-collection-tooltip-inner-margin-top: -28px;
$product-collection-tooltip-inner-max-width: 200px;
$product-collection-tooltip-inner-padding: 4px 8px;
$product-collection-transparent: transparent;
$product-rating-font: 12px;

@mixin quantity {        
    .quantity {
        width: 100px;
        height: 24px;
        text-align: center;
        border: 1px solid;
        padding: 0 13px;
        border: 1px solid $dobbies-gray-100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            width: 95px;
            padding: 0 10px;
        }
        @media screen and (max-width: 400px) {
            width: 80px;
            padding: 0 8px;
        }
        &-input {
            height: 100%;
            width: 30px;
            padding: 0;
            font-size: $dobbies-font-size-s;
            font-weight: $dobbies-font-weight-normal;
            outline: none;
            margin: 0 auto;
            border: none;
            -moz-appearance:textfield;
            background: transparent;
            top: 0;
        }
    
        .increment,
        .decrement {
            width: auto;
            height: 10px;
            border: none;
            background: transparent;
            display: inline-flex;
            padding: 0;
            color: $dobbies-grey;

            &::before {
                @include dobbies-icon();
                font-size: 16px;
                line-height: 14px;
                margin-top: -2px;
            }
        }

        .increment {
            font-size: 17px;
            &::before {
                content: $dobbies-icon-plus;
                margin-top: -1.3px;
            }

            &.disabled {
                &::before {
                    color: $dobbies-light-grey;
                }
            }
        }
        
        .decrement {
            &::before {
                content: $dobbies-icon-minus;
                margin-top: -2px;
            }

            &.disabled {
                &::before {
                    color: $dobbies-light-grey;
                }
            }
        }
    }
}

//style presets
:root {
    --msv-product-collection-font-family: #{$dobbies-primary-font-family};

    // heading
    --msv-product-collection-heading-font-size: var(--msv-heading-font-size-s);
    --msv-product-collection-heading-font-color: var(--dobbies-font-primary-color);

    --msv-product-collection-text-size: var(--msv-body-font-size-m);
    --msv-product-collection-text-color: var(--dobbies-font-primary-color);

    // rating star
    --msv-product-collection-rating-star: var(--dobbies-font-primary-color);

    //flipper
    --msv-product-collection-flipper-bg: #{$product-collection-transparent};
    --msv-product-collection-flipper-font-color: var(--dobbies-font-primary-color);
    --msv-product-collection-flipper-border: #{$product-collection-transparent};

    // Tooltip
    --msv-product-collection-tooltip-bg: var(--dobbies-font-primary-color);
    --msv-product-collection-tooltip-font-color: var(--dobbies-font-secondary-color);
}

.ms-product-collection {
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;

    @media screen and (max-width: $dobbies-container-max-width-m) {
        padding-left: 0px;
        padding-right: 0px;
    }

    &__heading {
        color: var(--msv-product-collection-heading-font-color);
        margin-top: $product-collection-heading-margin-top;
        margin-bottom: $product-collection-heading-margin-bottom;
        text-transform: uppercase;
        text-align: center;
    }

    &__item {
        display: inline-block;
        max-width: none;
        padding: $product-collection-item-padding;
        text-align: left;
        vertical-align: top;
        width: $product-collection-item-width;
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        @media screen and (max-width: $dobbies-container-max-width-l) {
            width: $top-picks-slide-width-desktop-mini;
        }
        @media screen and (max-width: $dobbies-container-max-width-m) {
            width: $top-picks-slide-width-tablet;
        }
        @media screen and (max-width: $dobbies-container-max-width-s) {
            width: $top-picks-slide-width-mobile;
        }
        .msc-empty_image {
            width: 100%;
            background-size: 100%;
            background-repeat: no-repeat;
            @include image-placeholder-rectangle(261px, 366px);
            max-width: 100%;
            @media screen and (max-width: $dobbies-container-max-width-xl) {
                @include image-placeholder-rectangle(174px, 244px);
                max-width: 100%;
            }
            @media screen and (max-width: $dobbies-container-max-width-m) {
                @include image-placeholder-rectangle(216px, 302px);
                max-width: 100%;
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                @include image-placeholder-rectangle(298px, 417px);
                max-width: 100%;
            }
            @media screen and (max-width: $msv-breakpoint-mbl-sm) {
                @include image-placeholder-rectangle(212px, 297px);
                max-width: 100%;
            }
            @media screen and (max-width: $msv-breakpoint-mbl) {
                @include image-placeholder-rectangle(139px, 195px);
                max-width: 100%;
            }
            @media screen and (max-width: $dobbies-container-max-width-xs) {
                @include image-placeholder-rectangle(100%, 171px);
                max-width: 100%;
            }
        }
    }

    .msc-ss-carousel {
        //padding: 10px;
        margin: auto;

        .msc-ss-carousel-slide {
            width: 100%;
            .ms-product-collection {
                &__item {
                    width: 20%;

                    @media screen and (max-width: $dobbies-container-max-width-xl) {
                        width: 25%;
                    }

                    @media screen and (max-width: $dobbies-container-max-width-m) {
                        width: 33.33%;
                    }
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        width: 50%;
                    }
                }
            }
        }

        .msc-ss-carousel {
            &__flipper {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                width: 30px;
                margin-left: -20px;
                // margin-top: $negative-spacing-top;
                padding: 0;
                text-align: right;
                &--next {
                    left: inherit;
                    right: 0;
                    margin-left: inherit;
                    margin-right: -19px;
                    text-align: left;
                }
                :before {
                    font-size: 20px;
                    margin-left: -4px;
                    color: #facc00;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    padding: 0;
                }
            }
        }
    }
    .msc-ss-carousel-item {
        border: solid 2px #00000000;
        &:hover,
        &:focus {
            text-decoration: none;
            border: solid 2px $dobbies-gray-200;
            border-radius: 10px;
            .msc-product__title {
                text-decoration: none;
            }
        }
    }

    .msc-product {
        @include vfi();
        overflow: hidden;
        display: block;
        border-bottom: 1px solid var(--dobbies-gray-border-color);
        position: relative;

        .msc-product__title {
            @include font-content(var(--msv-font-weight-normal), var(--msv-product-collection-text-size), $dobbies-line-height-m);
            color: var(--msv-product-collection-text-color);
            text-decoration: none;
            min-height: 42px;
            margin-bottom: 10px;
            margin-top: 10px;
            @media (max-width: $msv-breakpoint-sm) {
                min-height: 64px;
            }
            @media (max-width: $msv-breakpoint-l) {
                margin-bottom: 10px;
            }
        }
        &__details {
            min-height: 76px;
            padding-left: 10px;
            padding-right: 10px;
        }
        .msc-product__image {
            img {
                width: 100%;
            }
        }
    }

    .msc-price {
        color: var(--msv-product-collection-text-color);
        display: inline-block;
        font-family: var(--msv-product-collection-font-family);
        font-size: var(--msv-body-font-size-l);
        line-height: normal;
        text-transform: none;
        margin-bottom: 10px;

        &__strikethrough {
            font-size: var(--msv-body-font-size-m);
            line-height: $product-collection-price-strikethrough-line-height;
        }

        &__actual {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    .msc-rating {
        &__star,
        &__count {
            color: #facc00;
            border-bottom: none;
        }

        &__half-star {
            &:after {
                color: #facc00;
            }
        }
    }

    .msc-tooltip {
        display: block;
        left: $product-collection-tooltip-left;
        position: absolute;
        top: $product-collection-tooltip-top;

        .msc-tooltip-inner {
            background-color: $dobbies-black;
            color: var(--msv-product-collection-tooltip-font-color);
            font-size: var(--msv-body-font-size-s);
            margin-top: $product-collection-tooltip-inner-margin-top;
            max-width: $product-collection-tooltip-inner-max-width;
            padding: $product-collection-tooltip-inner-padding;
            text-align: center;
        }

        &.msc-bs-tooltip-bottom {
            padding: $tooltip-arrow-height 0;

            .msc-tooltip-inner {
                margin-top: 0px;
            }
        }
    }
    &:after {
        clear: both;
        content: '';
        display: block;
    }

    &.product-bundles {

        // @media screen and (min-width: $dobbies-container-max-width-s) {
        //     width: 442px;
        // }
        // @media screen and (min-width: $dobbies-container-max-width-tablet) {
        //     width: 434px;
        // }
        // @media screen and (min-width: $dobbies-container-max-width-xl) {
        //     width: auto;
        // }
        .ms-product-collection {
            padding-left: 60px;
            padding-right: 60px;
            &__heading {
                display: none;
            }
        }

        .msc-ss-carousel {
            padding: 0;
            .msc-ss-carousel-slide {
                // display: flex;
                // gap: 0 20px;
                // margin: 0 -5px;
                .ms-product-collection__item {
                    width: 174px;
                    padding: 0 5px;
                    min-height: 371.8px;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        width: 195px;
                        padding: 0 10px;
                        min-height: 387.2px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        width: 195px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-xl) {
                        width: 220px;
                        min-height: 422.2px;
                    }

                    @media (max-width: 400px) {
                        width: 160px;
                        min-height: 352.7px;
                    }
                        
                    .msc-empty_image {
                        @media screen and (max-width: 400px) {
                            max-height: 210.5px;
                        }
                    }
                }
            }

            .msc-ss-carousel-item {
                border: none;

                &:hover {
                    border: none;
                }
            }

            .msc-ss-carousel__flipper {
                :before {
                    color: $dobbies-primary;
                }
            }
        }
    }
}

.product-bundle {
    padding: 20px;
    .badges  {
        display: none;
    }
    &-wrapper {
        background-color: $dobbies-header-grey;
        margin-bottom: 40px;
    }
    
    @media screen and (max-width: 400px) {
        padding: 15px;
    }
    @media screen and (min-width: $dobbies-container-max-width-s) {
        padding: 40px 50px;
        max-width: 744px;
        margin: 0 auto;
    }
    @media screen and (min-width: $dobbies-container-max-width-tablet) {
        padding: 20px;
        padding-top: 32px;
        max-width: none;
    }
    @media screen and (min-width: $dobbies-container-max-width-xl) {
        padding: 40px;
    }

    .ms-product-collection {
        @media screen and (max-width: $dobbies-container-max-width-s) {
            padding-bottom: 0px;
            width: 348px;
        }
        @media screen and (max-width: 400px) {
            width: 322px;
        }
        @media screen and (min-width: $dobbies-container-max-width-s) {
            padding: 0;
            width: 390px;
            position: relative;
            left: 27px;
        }
        @media screen and (min-width: $dobbies-container-max-width-tablet) {
            width: 400px;
            left: 10px;
            min-height: auto;
        }
        @media screen and (min-width: $dobbies-container-max-width-xl) {
            padding-left: 45px;
            padding-right: 35px;
            width: 745px;
        }
    }

    &__heading {
        font-size: 25px;
        margin-bottom: 20px;
        color: $dobbies-grey;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            max-width: 350px;
            margin: 0 auto;
            margin-bottom: 20px;
            font-size: 20px;
        }
    }

    &-left {
        display: flex;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            flex-wrap: wrap;
        }
        @media screen and (min-width: $dobbies-container-max-width-tablet) {
            flex-wrap: nowrap;
        }
    }

    &-container {
        @media screen and (max-width: $dobbies-container-max-width-s) {
            max-width: 350px;
            margin: 0 auto;
        }
        @media screen and (min-width: $dobbies-container-max-width-s) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
        @media screen and (min-width: $dobbies-container-max-width-tablet) {
            flex-wrap: nowrap;
        }
        .productAdded {
            .msc-product__image {
                > a {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        pointer-events: none;
                        border: 3px solid $dobbies-primary;
                        z-index: 9;
                    }
                }
            }
        }
    
        .msc-empty_image {
            height: 229.6px;
            width: auto;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                height: 245px;
            }

            @media screen and (min-width: $dobbies-container-max-width-xl) {
                height: 280px;
            }

            @media screen and (max-width: 400px) {
                max-height: 214.5px;
            }
        }
    
        .msc-rating {
            position: absolute;
            right: 0;
            bottom: 48px;
            &__group {
                margin-right: 0;
            }
            &__star {
                &:before {
                    font-size: $product-rating-font;
                    color: $dobbies-grey;
                }
            }
    
            &__empty-star {
                &:before {
                    font-size: $product-rating-font;
                    color: $dobbies-footer-grey;
                }
            }
        }
    
        .msc-product__image {
            > a {
                border: none;
                position: relative;
            }
        }
    
        div {
            .msc-product {
                display: block;
                border-bottom: none;

                &__details {
                    padding: 0;
                    min-height: initial;
                    width: 100%;
                }
    
                a {
                    border: none;
                    &:hover {
                        text-decoration: none;
                        .msc-product__title {
                            text-decoration: underline;
                        }
                    }
                    .msc-product__title {
                        margin-top: 11px;
                        margin-bottom: 13px;
                        min-height: initial;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 19px;
                        width: 175px;
                        
                        @media screen and (min-width: $dobbies-container-max-width-xl) {
                            width: 200px;
                        }

                        @media screen and (max-width: $dobbies-container-max-width-s) {
                            width: 170px;
                        }
                        @media screen and (max-width: 400px) {
                            width: 157px;
                        }
                    }
                }
        
                .msc-price {
                    display: block;
                    margin-bottom: 12px;
                    span {
                        font-weight: bold;
                        line-height: 14px;
                        color: $dobbies-grey;
                    }

                    .msc-price__salePrice {
                        .msc-price {
                            &__actual {
                                color: $dobbies-sale-price;
                            }
                        }
                    }
                }
        
                &__checkbox {
                    position: absolute;
                    z-index: 9;
                    right: 7px;
                    top: 7px;
                    width: 26px;
                    height: 26px;
                    cursor: pointer;
                    -moz-appearance:none;
                    -webkit-appearance:none;
                    -o-appearance:none;
                    &:before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: $dobbies-white;
                        border-radius: 5px;
                        border: 1px solid $dobbies-gray-100;
                    }
        
                    & + svg {
                        display: none;
                    }
                    &:checked {
                        &:before {
                            background-color: $dobbies-primary;
                            border-color: $dobbies-primary;
                        }
                        & + svg {
                            display: inline-block;
                            position: absolute;
                            z-index: 9;
                            right: 13px;
                            top: 11px;
                            pointer-events: none;
                        }
                    }
                }
        
                &__product-quantity {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 7px;
                    padding-top: 7px;
                    border-top: 0.5px solid $dobbies-gray-100;
                    margin-top: 0;
                    .quantity-label {
                        text-transform: capitalize;
                        font-size: $dobbies-font-size-s;
                        font-weight: $dobbies-font-weight-normal;
                        margin: 0;
                    }

                    @include quantity;
                }
            }
        }
    
        .price-summary {
            margin-top: 26px;
            padding: 10px 13px;
            width: 100%;
            position: relative;
            background-color: $dobbies-white;

            @media screen and (max-width: $dobbies-container-max-width-s) {
                margin-top: 20px;
            }
            @media screen and (min-width: $dobbies-container-max-width-s) {
                padding-right: 27px;
            }
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                width: 332px;
                padding: 22px 20px 17px;
                margin: 0;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                width: 358px;
            }
        
            &::before {
                content: '';
                width: 100%;
                height: 1px;
                border-top: 1px solid $dobbies-gray-100;
                position: absolute;
                top: -10px;
                left: 0;
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    width: 335px;
                    left: 12px;
                }
                @media screen and (max-width: 400px) {
                    width: 308px;
                    top: -10px;
                }
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    width: 627px;
                    top: -12px;
                    left: 14px;
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    display: none;
                }
            }

            &-wrapper {
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    margin: 0 -5px;
                }
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    margin: 0 -13px;
                    width: calc(100% + 32px);
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    position: absolute;
                    right: 20px;
                    width: auto;
                    margin: 0;
                    margin-top: -60px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    right: 40px;
                    margin-top: -50px;
                }
            }

            button#up-arrow-btn.msc-btn,
            button#down-arrow-btn.msc-btn,
            button#up-arrow-btn-modal.msc-btn,
            button#down-arrow-btn-modal.msc-btn {
                border: none;
                background: none;
                height: 6px;
                width: 20px;
                padding: 0;
                position: absolute;
                z-index: 9;
                right: 15px;
                @media screen and (max-width: $dobbies-container-max-width-tablet) {
                    display: none;
                }
                &::before {
                    @include dobbies-icon();
                    color: $dobbies-primary;
                    font-size: 20px;
                    line-height: 6px;
                    font-weight: var(--dobbies-font-weight-heavy);
                }
            }

            button#up-arrow-btn.msc-btn,
            button#up-arrow-btn-modal.msc-btn {
                top: 30px;
                &:before {
                    content: $dobbies-icon-arrow;
                    transform: rotate(0deg);
                }
            }

            button#down-arrow-btn.msc-btn,
            button#down-arrow-btn-modal.msc-btn {
                bottom: 140px;
                &:before {
                    content: $dobbies-icon-arrow;
                    transform: rotate(180deg);
                }
            }

            h3 {
                font-size: 16px;
                margin-bottom: 20px;
                display: none;
                line-height: 18px;
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    display: block;
                }
            }

            &.empty {
                h3 {
                    margin-bottom: 10px;
                    padding-bottom: 0px;
                    border-bottom: 0;
                }
            }
            &__content {
                display: flex;
                position: relative;
                align-items: center;
    
                .product {
                    &-name {
                        margin-bottom: 13px;
                        width: 200px;
                        overflow: hidden;
                        display: block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
    
                    &-price {
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                }

                .msc-price {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 10px;
                    display: inline-block;
                    .strikethrough,
                    .msc-price__actual {
                        font-weight: bold;
                        line-height: 18px;
                    }
                    .msc-price__salePrice {
                        .msc-price__actual{
                            line-height: 18px;
                            font-weight: bold;
                        }
                    }
                }
            }
            &__product {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid $dobbies-gray-100;
                &:last-child {
                    border: none;
                    padding-bottom: 0;
                }
                &-list {
                    display: none;
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        display: block;
                        max-height: 220px;
                        overflow: auto;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-xl) {
                        max-height: 336px;
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    scrollbar-width: none
                }
            }
            .product-remove {
                position: absolute;
                top: -1px;
                right: 0;
                padding: 0;
                height: auto;
                background: none;
                line-height: 8px;
                font-size: 14.2px;
                color: $dobbies-grey;
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    font-size: 15.3px;
                    line-height: 9px;
                }
                &::before {
                    display: inline-block;
                    content: "\00d7";
                }
            }

            .product-quantity {
                display: none;
            }

            @include quantity;
    
            .msc-cart-line__product-image {
                margin-right: 10px;
                img, .msc-empty_image {
                    max-width: 68px;
                    height: 95px;
                    width: 68px;
                }
            }

            .quantity-error-msg {
                color: $dobbies-alert-red;
                padding-left: 55px;
                padding-left: 40px;
                position: relative;
                line-height: 12px;
                margin-top: 10px;
                font-size: 12px;
                font-weight: bold;
                line-height: 14px;
                padding-right: 15px;

                span {
                    display: block;
                }

                &::before {
                    content: '!';
                    left: 0px;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    color: $white;
                    text-align: center;
                    font-size: 24px;
                    transform: translateY(-50%);
                    background: $dobbies-alert-red;
                    font-size: 20px;
                }
            }
    
            &__total-price {
                margin-bottom: 10px;
                font-weight: $msv-font-weight-500;
                line-height: 17px;
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    padding-top: 10px;
                    border-top: 1px solid $dobbies-gray-100;
                }
                span {
                    font-size: 16px;
                    font-weight: bold;
                    margin-left: 10px;
                    display: inline-block;
                }
            }
    
            .msc-add-to-cart {
                height: 30px;
                margin-bottom: 10px;
                padding: initial;
                font-weight: bold;
                font-size: 16px;
                border-radius: 5px;
                border: none;
                width: 100%;

                &:disabled {
                    background-color: transparent;
                    border:1px solid $dobbies-grey;
                    color: $dobbies-grey;
                }
                
                & + p {
                    font-size: 14px;
                    text-align: center;
                    line-height: 17px;
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        letter-spacing: -0.5px;
                    }
                }
            }
        }
    
        .ms-wishlist-items__product-add-button {
            max-height: 30px;
            margin-top: 0;
            padding: 0;
            border: none;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                font-size: 16px;
            }
            &::before {
                display: none;
            }
        }
    
        .plus {
            font-size: 0;
            width: 27px;
            height: 27px;
            position: relative;
            margin: 20px auto;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                margin: 0;
                margin-left: 20px;
                top: 129px;
            }
            &:before,
            &:after{
                content: "";
                position: absolute;
                background-color: $dobbies-primary;
                transition: transform 0.25s ease-out;
            }
        
            /* Vertical line */
            &:before{
                top: 0;
                left: 50%;
                width: 3px;
                height: 100%;
                margin-left: -2px;
            }
        
            /* horizontal line */
            &:after{
                top: 50%;
                left: 0;
                width: 100%;
                height: 3px;
                margin-top: -2px;
            }
        }

        .msc-ss-carousel {

            &-slide {
                
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    padding-left: 10px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    padding: 0;
                }
            }
            .msc-ss-carousel__flipper {
                top: 185px;
                left: -9px;
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    left: 5px;
                    top: 210px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    left: -13px;
                    top: 240px;
                }
                @media screen and (max-width: 400px) {
                    left: -5px;
                    top: 175px;
                }

                &--next {
                    right: -14px;
                    @media screen and (max-width: 400px) {
                        right: -10px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        right: -10px;
                    }

                    @media screen and (min-width: $dobbies-container-max-width-xl) {
                        right: -12px;
                    }
                }
            }

            .msc-tooltip {
                display: none !important;
            }
        }
    }
}

.pdpCurrentProd {
    position: relative;
    .msc-product__image {
        img,
        .msc-empty_image {
            min-width: 164px;
            max-width: 164px;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                width: 175px;
                min-width: auto;
                max-width: none;
            }

            @media screen and (max-width: 400px) {
                min-width: 144px;
                max-width: 144px;
                max-height: 201.6px;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                width: 200px;
            }
        }
    }

    .ms-wishlist-items__product-add-button {
        @media screen and (max-width: $dobbies-container-max-width-s) {
            min-width: calc(99% - 190px);
            position: absolute;
            left: 174px;
            bottom: 46px;
        }

        @media screen and (max-width: 400px) {
            left: 155px;
            min-width: calc(99% - 170px);
            bottom: 32px
        }
    }
    div {
        .msc-product {
            @media screen and (max-width: $dobbies-container-max-width-s) {
                border: none;
                display: flex;
                align-items: center;
            }
            a {
                .msc-product__title {
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        margin-top: 0;
                        margin-bottom: 11px;
                        max-width: 100%;
                        width: auto;
                        padding-right: 25px;
                        width: 191px;
                    }
                    
                }
            }

            &__details {
                width: calc(100% - 20px);
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    margin: 0;
                    width: auto;
                }
                @media screen and (max-width: 400px) {
                    width: calc(95% - 25px);
                    bottom: 20px;
                }
            }

            &__product-quantity {
                width: calc(100% - 20px);
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    width: auto;
                }
            }

            &__content {
                position: relative;
                bottom: 10px;
                margin-left: 10px;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    position: static;
                    margin: 0;
                }
            }
            .msc-price {
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    margin-bottom: 11px;
                    padding-right: 25px;
                }
            }
        }
    }
}

.product-bundle-modal {
    margin: 100px auto;
    margin-bottom: 0;
    width: auto;
    max-width: 380px;
    @media screen and (min-width: $dobbies-container-max-width-s) {
        max-width: 740px;
    }
    @media screen and (min-width: $dobbies-container-max-width-tablet) {
        max-width: 984px;
    }
    @media screen and (min-width: $dobbies-container-max-width-xl) {
        max-width: 1145px;
    }
    @media screen and (max-width: 400px) {
        max-width: 360px;
    }
    .product-bundle {
        padding: 20px;
        position: relative;
        @media screen and (min-width: $dobbies-container-max-width-s) {
            padding: 22px 26px 25px 40px;
        }
        @media screen and (min-width: $dobbies-container-max-width-tablet) {
            padding: 32px 20px 20px 36px;
        }
        @media screen and (min-width: $dobbies-container-max-width-xl) {
            padding: 40px 30px 40px 58px;
        }
        &__heading {
            @media screen and (max-width: $dobbies-container-max-width-s) {
                font-size: 20px;
            }
        }
        .close-modal {
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 0;
            height: auto;
            background: none;
            line-height: 12px;
            border: none;
            color: $dobbies-grey;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                top: 15px;
                right: 15px;
            }
            &::before {
                display: inline-block;
                content: "\00d7";
                font-size: 20px;
            }
        }
        &-left {
            position: relative;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                left: -5px;
            }
            @media screen and (max-width: 400px) {
                left: 0px;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                left: -10px;
            }
        }

        &-wrapper {
            @media screen and (min-width: $dobbies-container-max-width-s) {
                min-height: 483px;
            }
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                min-height: 483px;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                min-height: 536px;
            }
        }
        &-container {
            @media screen and (min-width: $dobbies-container-max-width-s) {
                flex-wrap: nowrap;
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                max-width: 360px;
            }
            div {
                .msc-product {
                    a {
                        .msc-product__title {
                            @media screen and (min-width: $dobbies-container-max-width-s) {
                                margin-top: 10px;
                                margin-bottom: 11px;
                            }
                            @media screen and (min-width: $dobbies-container-max-width-xl) {
                                margin-top: 6px;
                                margin-bottom: 8px;
                            }
                        }
                    }

                    .msc-price {
                        margin-bottom: 6px;
                    }
                }
            }
            .price-summary {
                h3 {
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        display: block;
                    }
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        display: none;
                    }
                }
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    width: 272px;
                    margin-top: 0;
                    padding: 20px;
                    padding-top: 22px;
                }
                @media screen and (min-width: $dobbies-container-max-width-tablet) {
                    width: 332px;
                }
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    width: 358px;
                }

                button#up-arrow-btn-modal.msc-btn,
                button#down-arrow-btn-modal.msc-btn {
                    @media screen and (max-width: $dobbies-container-max-width-tablet) {
                        display: inline-block;
                    }
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        display: none;
                    }
                }

                button#down-arrow-btn-modal.msc-btn {
                    @media screen and (max-width: $dobbies-container-max-width-tablet) {
                        bottom: 155px;
                    }
                }

                &__total-price {
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        padding-top: 10px;
                        border-top: 1px solid $dobbies-gray-100;
                    }

                }

                .msc-price {
                    margin-bottom: 10px;
                    display: block;
                    .msc-price__salePrice {
                        .msc-price__actual {
                            line-height: normal;
                            font-weight: bold;
                        }
                    }
                }
                &__content {
                    .product-name {
                        margin-bottom: 12px;
                        @media screen and (min-width: $dobbies-container-max-width-s) {
                            width: 140px;
                        }
                        @media screen and (min-width: $dobbies-container-max-width-tablet) {
                            width: 200px;
                        }
                    }
                }
                &__product-list {
                    display: block;
                    max-height: 220px;
                    overflow: auto;
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        display: none;
                    }
                }
                &::before {
                    display: none;
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        display: block;
                        left: 6px;
                    }
                    @media screen and (max-width: 400px) {
                        left: 11px;
                    }
                }
                .msc-cart-line__product-image {
                    img, .msc-empty_image {
                        max-width: 68px;
                        height: 95px;
                        width: 68px;
                        object-fit: cover;
                    }
                }
                &-wrapper {
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        width: auto;
                        margin: 0 -5px;
                        margin-top: -35px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        width: auto;
                        margin: 0 -5px;
                        margin-top: -35px;
                    }
                }

                .product-remove {
                    &::before {
                        font-size: 18px;
                        line-height: 10px;
                    }
                }
            }
        }
        .ms-product-collection {
            @media screen and (min-width: $dobbies-container-max-width-s) {
                left: -10px;
            }
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                padding: 0;
                left: 0;
                width: 585px;
                margin: 0 -10px;
            }

            @media screen and (min-width: $dobbies-container-max-width-xl) {
                margin: 0;
                width: 660px;
            }

            .msc-ss-carousel {
                .msc-ss-carousel-slide {
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        padding-left: 0;
                    }
                }

                .msc-ss-carousel__flipper {
                    left: -6px;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        left: -7px;
                        top: 210px;
                    }
                    @media screen and (max-width: 400px) {
                        top: 180px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        left: -6px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-xl) {
                        left: -18px;
                        top: 235px;
                    }
                    @media screen and (max-width: 400px) {
                        left: -5px;
                    }
                    &--next {
                        right: -12px;
                        @media screen and (max-width: 400px) {
                            right: -10px;
                        }
                        @media screen and (min-width: $dobbies-container-max-width-s) {
                            right: -10px;
                        }

                        @media screen and (min-width: $dobbies-container-max-width-xl) {
                            right: -20px;
                        }
                    }
                }
            }
        }
        .msc-rating {
            bottom: 44px;
        }
    }

    .pdpCurrentProd {
        .msc-product__image {
            img {
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    min-width: 164px;
                    max-width: 164px;
                }
                @media screen and (max-width: 400px) {
                    min-width: 144px;
                    max-width: 144px;
                    max-height: 201.6px;
                }
            }
        }
    }
}