$transition-collapse: height .35s ease ;

.collapse {
    &:not(.show) {
        display: none;
        transition: all 0.4s ease-out;
    }
    &.show{
        overflow: hidden;
        // transition: all .5s ease-in-out;
        transition: all 1s ease-in;
        min-height: 0 !important;
        position: relative;

    }
}

.slide-down {
	overflow: hidden;
	transition: all 0.4s ease-in;
}

.slide-up {
	overflow: hidden;
	transition: all 0.4s ease-out;
	max-height: 0;
}

.slide-down-animation {
	overflow: hidden;
	animation: slide-down 0.5s ease-in;
	min-width: 225px;
}


.collapsing {
    @include transition($transition-collapse);
    height: 0;
    overflow: hidden;
    position: relative;
}