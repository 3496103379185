$name-top-margin: 4px;
$rating-top-margin: 4px;
$price-top-margin: 4px;
$original-price-color: $dobbies-gray-500;
$original-price-right-margin: 4px;

$product-font: $dobbies-primary-font-family;

$product-title-color: var(--dobbies-font-primary-color);
$product-title-line-height: 21px;
$product-title-margin-bottom: 10px;
$product-title-margin-top: 20px;
$dobbies-bg-path: '../../../';

.msc-product-card {
    font-size: var(--msv-body-font-size-s);

    .msc-product-card__image {
        width: 100%;
    }

    .msc-product-card__name {
        margin-top: $name-top-margin;
    }

    .msc-product-card__price-container {
        margin-top: $price-top-margin;
    }

    .msc-product-card__original-price {
        color: $original-price-color;
        margin-right: $original-price-right-margin;
    }

    .msc-product-card__rating {
        margin-top: $rating-top-margin;
    }
}


.msc-add-to-wishlist {
    border: 0;
    padding: 0;
    font-size: 30px;
    line-height: normal;
    background: none;
    color: $dobbies-grey;
    margin: 0;
    width: 32px;
    height: 32px;
    border: 0;
    justify-content: center;
    background: $dobbies-white;
    border-radius: 100%;
    @media (max-width: $msv-breakpoint-sm) {
        order: 3;
        line-height: normal;
        margin-top: 0;
        margin-bottom: 10px;
    }
    &:after {
        content: $dobbies-icon-favourite;
        font-family: '$dobbies-icon-font-family';
        color: $dobbies-grey;
        font-size: 21px;
        font-weight: bold;
        position: relative;
        top: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $msv-breakpoint-sm) {
            margin-top: 1.5px;
        }
    }
    &__removing {
        @include add-dobbies-icon($dobbies-icon-favourites-icon-filled, after);
    }
}


.ms-wishlist-items {
      &__product-status {
        display: none;
        .msc-alert-success {
          background: transparent;
          border: 0;
          padding: 0;
          width: 100%;
          margin: 0;
        }
      }
    }

.msc-wishlist-button{
    position: absolute;
    left: 15px;
    bottom: 15px;
}

.ms-quickView{
    position: absolute;
    right: 15px;
    bottom: 15px; 
    z-index: 999;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.8s;
    
    &__image{ 
        width: 32px;
        height: 32px;
        background: $dobbies-white url('#{$dobbies-bg-path}quick-view.png') center center no-repeat;
        background-size: 26px;
        cursor: pointer;
        margin: 0s;
        border-radius: 100%;
    }
}

.msc-product__isHD {
    width: 30px;
    height: 30px;
    background-image: url('#{$dobbies-bg-path}Home_Delivery_Grey_new.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 12px;
    margin-top: 2px;
    margin-top: -30px;
}

.msc-product__isCnC {
    width: 22px;
    height: 30px;
    background-image: url('#{$dobbies-bg-path}C&C_Grey_new.png');
    background-repeat: no-repeat;
    background-size: contain;
    //margin-bottom: 1px;
    margin-top: -35px;
}

.msc-product__isHD-active {
    width: 30px;
    height: 30px;
    background-image: url('#{$dobbies-bg-path}HD_color.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 12px;
    margin-top: -30px;
}

.msc-product__isCnC-active {
    width: 22px;
    height: 30px;
    background-image: url('#{$dobbies-bg-path}CnC_icon_green.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -35px;
}

.msc-delivery__isCnC {
    width: 20px;
    height: 20px;
    background-image: url('#{$dobbies-bg-path}svgs/CnC_icon_white.svg');
    background-repeat: no-repeat;
    margin-right: 5px;
    color: white;
    background-position: center;
    //margin-top: -4px;
}

.msc-delivery__isCnC-active {
    width: 20px;
    height: 20px;
    background-image: url('#{$dobbies-bg-path}svgs/CnC_icon_black.svg');
    background-repeat: no-repeat;
    margin-right: 5px;
    color: white;
    background-position: center;
    //margin-top: -4px;
}

.msc-delivery__isCnC-text {
    margin-top: 0;
    font-size: var(--dobbies-body-font-size-m);
}

.msc-product__delivery {
    justify-content: flex-end;
}

.msc-product__title {
    color: $product-title-color;
    font-family: $product-font;
    font-weight: normal;
    font-size: var(--msv-body-font-size-m);
    line-height: $product-title-line-height;
    margin-bottom: $product-title-margin-bottom;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: pre-wrap;
}

.product-card-club-plus-badge-collapse {
    background-color: #facc00;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    margin-top: -60px;

    @media (max-width: $msv-breakpoint-m) {
        margin-top: -80px;
    }

    @media (max-width: $msv-breakpoint-sm) {
        margin-top: -110px;
    }

    @media (max-width: $msv-breakpoint-xs) {
        margin-top: -60px;
    }

    @media screen and (max-width: 426px) and (min-width: 380px) {
        margin-top: -100px;
    }

    @media screen and (max-width: 320px) {
        margin-top: -60px;
    }
}


.product-card-club-plus-discount {
    background-color: #facc00;
    border-radius: 5px;
    padding: 5px;
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.product-card-club-plus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // background-color: #facc00;
    color: black;
    font-weight: bold;
    // border-radius: 5px;
    // padding: 5px;
}

.product-card-club-plus-price {
    margin-left: 20px;

    @media (max-width: $msv-breakpoint-m) {
        margin-left: 5px;
    }

    @media (max-width: $msv-breakpoint-sm) {
        margin-left: 5px;
    }
}

.product-club-plus-badge-info:hover {
    text-decoration: auto !important;
}

.product-card-club-plus-icon-close {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
}

.product-card-club-plus-icon {
    margin-right: 10px;
    border: 2px solid black;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;

    @media (max-width: $msv-breakpoint-m) {
        margin-right: 5px;
        margin-left: 10px;
    }

    @media (max-width: $msv-breakpoint-sm) {
        margin-right: 0px;
        margin-left: 10px;
    }
}

.msc-product {
    .msc-empty_image {
        @include image-placeholder(244px);
    }
} 
.product {
    &-card-club-plus {
        background: linear-gradient(45deg, $dobbies-club-grey 55%, $dobbies-club-yellow 55%);
        padding: 6px 10px;
        align-items: center;
        margin-bottom: 10px;

        @media (min-width: $msv-container-max-width-s) {
            background: linear-gradient(45deg, $dobbies-club-grey  58%, $dobbies-club-yellow 55%);
        }

        @media (min-width: $dobbies-container-max-width-l) {
            background: linear-gradient(45deg, $dobbies-club-grey 55%, $dobbies-club-yellow 55%);
        }

        @media (max-width: 485px) {
            background: linear-gradient(45deg, $dobbies-club-grey 45%, $dobbies-club-yellow 45%);
            padding: 6px 12px;
        }

        > p:not(.product-card-club-plus-price) {
            color: $dobbies-club-yellow;
            line-height: 16px;
            font-weight: 500;
            text-transform: uppercase;
            @media (min-width: $msv-container-max-width-s) {
                width: 60%;
            }
            @media (min-width: $msv-breakpoint-l) {
                font-size: 13px;
            }
            @media (min-width: $dobbies-container-max-width-l) {
                font-size: 14px;
            }
            @media (max-width: $msv-breakpoint-mbl-sm) {
                width: 60px;
            }
        }

        &-badge {
            position: relative;
            margin-bottom: 10px;
            @media (max-width: $dobbies-container-max-width-tablet) {
                width: 100%;
            }
        }

        &-icon,
        &-cross {
            position: absolute;
            right: 0px;
        }

        &-icon {
            right: 10px;
            margin: 0;
            width: 16px;
            height: 16px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            p {
                position: relative;
                font-weight: 500;
                font-size: 12px;
                top: 0;
                width: 16px;
                height: 16px;
                bottom: 0;
                line-height: 14.5px;
                border: 1px solid black;
                border-radius: 100%;
                padding-top: 0.5px;
                padding-left: 0.5px;
            }
        }

        &-cross {
            right: 7px;
            top: 7px;
            position: relative;
            width: 8px;
            height: 8px;
            float: right;
            cursor: pointer;
            @media (max-width: $dobbies-container-max-width-xl) {
                width: 5px;
		        height: 5px;
            }
            &::after,
            &::before {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                right: 0;
                height: 1px;
                border-radius: 4px;
                background-color: $dobbies-white;
                @media (max-width: $dobbies-container-max-width-xl) {
                    top: 4px;
		            left: -2px;
                }
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }

        &-price {
            width: 100px;
            padding-right: 5px;
            text-align: center;
            color: $dobbies-grey;
            font-size: 16px;
            font-weight: 500;
            @media (min-width: $msv-container-max-width-s) {
                margin: 0;
            }
            @media (max-width: 485px) {
                width: 47%;
                padding-right: 15px;
            }
        }

        &-discount {
            background: linear-gradient(45deg, $dobbies-club-grey 53%, $dobbies-club-yellow 50%);
            border-radius: 0;
            .product-card-club-plus-price {
                padding: 0;
                width: 60px;
                padding-left: 10px;
            }
        }
    }

    &-club-plus {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: $dobbies-club-yellow;
        font-size: 18px;

        &-container-info {
            bottom: 30px;
            background: $dobbies-club-grey;
            z-index: 9;
            position: absolute;
            @media (max-width: $msv-breakpoint-mbl-sm) {
                bottom: 60px;
            }
        }

        &-info {
            color: $dobbies-white;
            padding: 7px 10px;
            padding-right: 15px;
            line-height: 17px;
            font-size: 12px;

            > span {
                display: block;
                a {
                    color: $dobbies-white;
                    text-decoration: underline;
                    border: 0;
                }
            }
        }
    }

    &-badge {
        // display: flex;
        justify-content: flex-end;
        width: 350px;
        height: 45px;
        margin-top: 24px;

        @media (max-width: $msv-breakpoint-m) {
            justify-content: flex-start;
            width: 100%;
            margin-top: 22px;
        }

        @media (max-width: $msv-breakpoint-sm) {
            justify-content: flex-start;
            width: 100%;
            margin-top: 12px;
        }
    }

    &-badge-container {
        margin-bottom: 20px;

        @media (max-width: $msv-breakpoint-m) {
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }

        @media (max-width: $msv-breakpoint-sm) {
            display: block;
            width: 100%;
        }
    }
}


.ms-product-search-result {
    &__item {
        &:nth-child(1),
        &:nth-child(5n-5) {
            .product-card-club-plus-icon {
                p {
                    padding-left:0
                }
            }
        }
    }
}