.msc-add-to-wishlist {
    @include add-dobbies-icon($dobbies-icon-favourite, after);
    @include vfi();

    &__removing {
        @include add-dobbies-icon($dobbies-icon-favourites-icon-filled, after);
    }

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
} 