.clubplus-landingpage {

    &__top-banner {
        background-color: $dobbies-club-grey;
        //  Commented to keep the top banner at it's relative position
        // position: fixed;
        // top: 0;
        width: 100%;
        z-index: 99999;
        &--body {
            &.ms-content-block[data-m-layout='tile'] {
                min-height: auto;
                width: auto;
                @media (max-width: $dobbies-container-max-width-tablet) {
                    padding: 0;
                }
                .ms-content-block__cta {
                    padding: 24px 19px 20px;
                    display: flex;
                    justify-content: space-between;
                    @media (min-width: $dobbies-container-max-width-s) {
                        padding: 9px 20px;
                        display: flex;
                        justify-content: start;
                    }
                
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        padding: 7.5px 0;
                    }

                    @media (min-width: $dobbies-container-max-width-xl) {
                        padding: 27px 0 23px;
                    }

                    a.msc-cta__primary,
                    a {
                        min-width: 94px;
                        height: 30px;
                        padding: 4.5px 9.5px;
                        font-size: $dobbies-font-size-m;
                        line-height: 18px;
                        background: transparent;
                        border: 1px solid $msv-white;
                        color: $msv-white;
                        text-decoration: none;
                        border-radius: 5px;
                        text-align: center;
                        font-weight: 500;
                        line-height: 16px;
                        display: inline-block;

                        @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                            height: 24px;
                            line-height: 14px;
                            min-width: 75px;
                        }
                        
                        &:hover {
                            background: $dobbies-green;
                            color: $msv-white;
                            border-color: $dobbies-green;
                        }              
                    }

                    a.msc-cta__secondary {
                        margin-left: 50px;
                        background: $dobbies-club-yellow;
                        color: $dobbies-grey;
                        border-color: $dobbies-club-yellow;
                        text-decoration: none;
                        &:hover {
                            background: $dobbies-green;
                            color: $msv-white;
                            border-color: $dobbies-green;
                        }
                    }
                }
            }

        }

        &.fixed {
            position: fixed;
            width: 100%;
            top: 0;
            @media (min-width: $dobbies-container-max-width-xl) {
                top: 49px;
            }
        }
    }

    &__title {
        min-height: auto;
        margin-bottom: 20px;
        padding: 0 20px;
        &[data-m-layout='tile'] {
            margin: auto;
            margin-bottom: 20px;
        }
        @media (min-width: $dobbies-container-max-width-s) {
            padding: 0;
        }
        .ms-content-block__title {
            font-family: $dobbies-font-azosans-medium;
            font-size: $dobbies-title-font-size-xs;
            font-weight: 500;
            color: $dobbies-grey;
            text-align: center;
            padding-top: 60px;

            @media (min-width: $dobbies-container-max-width-s) {
                font-size: $dobbies-font-size-ml;
                padding-top: 0;
                border-top: none;
            }
    
            @media (min-width: $dobbies-container-max-width-tablet) {
                font-size: $dobbies-title-font-size-25;
            }
        }
    }

    &-banner {
        // margin-top: 74px;
        margin-top:0;
        @media (min-width: $dobbies-container-max-width-s) {
            margin-bottom: 40px;
            // margin-top: 42px;
            height: 300px;
        }

        @media (min-width: $dobbies-container-max-width-tablet) {
            height: 360px;
            margin-bottom: 66px;
            // margin-top: 45px;
        }

        @media (min-width: $dobbies-container-max-width-xl) {
            // margin-top: 80px;
        }

        @media (min-width: 2100px) {
            height: 460px;
        }

        > .row {
            height: 100%;
        }

        .ms-content-block {
            padding: 0;
            &__image {
                margin-bottom: 20px;
                height: 228px;
                position: relative;
                width: 150%;
                left: -50%;

                @media (min-width: 400px) {
                    width: 130%;
                    left: -30%;   
                }

                @media (min-width: $dobbies-container-max-width-s) {
                    width: 100%;
                    height: 300px;
                    position: absolute;
                    margin: 0;
                    width: 100%;
                    left: 0%;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    position: absolute;
                    height: 360px;
                    width: 140%;
                    left: -40%;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    width: 120%;
                    left: -20%;
                }
                @media (min-width: 1600px) {
                    width: 100%;
                    left: 0;
                }
    
                @media (min-width: 2100px) {
                    height: 460px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__details {
                padding: 0 20px;
                @media (min-width: $dobbies-container-max-width-s) {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    max-width: 270px;
                    padding: 0 20px;
                    padding-right: 20px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    max-width: 445px;
                    padding-right: 0px;
                }
            }

            &__title {
                font-family: $dobbies-font-azosans-medium;
                color: $dobbies-black;
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 20px;
                max-width: 68%;
                @media (max-width: 400px) {
                    max-width: 85%;
                }
                @media (min-width: $dobbies-container-max-width-s) {
                    font-size: 25px;
                    line-height: 25px;
                    margin-bottom: 3px;
                    max-width: none;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    font-size: 48px;
                    line-height: 56px;
                    margin-bottom: 7px;
                }
            }

            &__text {
                padding-bottom: 28px;
                border-bottom: 1px solid $dobbies-club-grey;
                padding-right: 15px;
                @media (min-width: $dobbies-container-max-width-xs) {
                    padding-right: 25px;
                }
                @media (min-width: $dobbies-container-max-width-s) {
                    padding-bottom: 0px;
                    border-bottom: 0;
                    padding-right: 0px;
                }
                p {
                    font-size: 14px;
                    line-height: 18px;
                    margin: 0;

                    @media (min-width: $dobbies-container-max-width-s) {
                        font-size: 9px;
                        line-height: 12px;
                        max-width: 357px;
                    }
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        font-size: $dobbies-font-size-s;
                        line-height: 18px;
                    }
                    b {
                        font-weight: $dobbies-font-weight-bold;
                    }
                    a {
                        font-weight: $dobbies-font-weight-bold;
                        text-decoration: underline;
                    }
                }

                div {
                    a {
                        @include primary-button( var(--msv-write-review-primary-btn-font-color), var(--msv-write-review-primary-btn-border));
                        min-width: 162px;
                        margin: 30px 0 12px 0;
                        padding: 10px 25px;
                        border-radius: 4px;
                        height: auto;
                        font-size: 16px;
                        font-weight: 500;
                        transition: none;
                        position: relative;
                        overflow: hidden;
                        background-color: $dobbies-club-yellow;       
                        color: $dobbies-grey;
                        line-height: 20px;
                        border: none;
                        justify-content: center;
                        transform: matrix(1, 0, 0, 1, 0, 0);
                        text-transform: uppercase;
                        line-height: 20px;
                        height: 40px;
                        
                        @media (max-width: $dobbies-container-max-width-s) {
                            margin: 20px 0;
                        }
                        @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                            padding: 6px 25px;
                            line-height: 12px;
                            font-size: 9px;
                            min-width: 98px;
                            margin: 15.5px 0 10px 0;
                            line-height: 12px;
                            height: 24px;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            z-index: -1;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: $dobbies-green;
                            transform: scaleX(0);
                            transform-origin: 0 50%;
                            transition-property: transform;
                            transition-duration: 0.3s;
                            transition-timing-function: ease-out;
                            }
                            &:hover,
                            &:focus,
                            &:active {
                            color: $dobbies-white;
                            background: $dobbies-club-yellow;
                            &:after {
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
    }

    &__benefits {
        padding-bottom: 20px;
        @media (min-width: $dobbies-container-max-width-s) {
            margin-bottom: 35px;
            padding-bottom: 0px;
            margin-top: 0px;
        }
    
        @media (min-width: $dobbies-container-max-width-tablet) {
            margin-bottom: 63px;
        }

        &--container {
            border: 3px solid $dobbies-club-loyalty-yellow;
            border-radius: 5px;
            .col-12 {
                padding: 5px 0;
                border-bottom: 1px solid #cbcbcd;
                &:last-child {
                    border-bottom: 0;
                }
            }

            .ms-content-block {
                display: flex;
                flex-wrap: nowrap;
                min-height: auto;
                align-items: center;
                width: auto;
                position: relative;
                padding: 0 18px 0 20px;
                @media (max-width: 400px) {
                    padding: 0 0px 0 15px;
                }
                @media (min-width: $dobbies-container-max-width-s) {
                    max-width: 92%;
                    padding: 0;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    max-width: 785px;
                }
    
                &:not(.benefit-no-tick) {
                    &::before {
                        content: "";
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        transform: scale(var(--ggs, 1));
                        width: 38px;
                        height: 38px;
                        border: 2px solid transparent;
                        border-radius: 100px;
                        background-color: #FFF1C8;
                        position: absolute;
                        right: 50px;
                        @media (max-width: 400px) {
                            right: 30px
                        }
                        @media (min-width: $dobbies-container-max-width-s) {
                            right: 40px;
                        }
                    }
        
                    &::after {
                        content: "";
                        display: block;
                        box-sizing: border-box;
                        position: absolute;
                        top: 5px;
                        width: 10.5px;
                        height: 15.5px;
                        border-width: 0 3.5px 3.5px 0;
                        border-style: solid;
                        transform-origin: bottom left;
                        transform: rotate(45deg);
                        right: 67px;
                        @media (max-width: 400px) {
                            right: 47px
                        }
                        @media (min-width: $dobbies-container-max-width-s) {
                            right: 57px;
                        }
                    }
                }

                &[data-m-layout='tile'] {
                    .ms-content-block__image img {
                        min-width: 40px;
                        width: 40px;
                        height: 40px;
                    }
                }
    
                &__image { 
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        margin-right: 70px;
                    }
                }
    
                &__details {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                }
    
                &__text {
                    text-align: center;
                    p {
                        margin: 0;
                        font-size: $dobbies-font-size-xs;
                        width: 104px;
                        line-height: 14px;
                        @media (max-width: 400px) {
                            width: 100px;
                        }
                        @media (min-width: $dobbies-container-max-width-s) {
                            font-size: $dobbies-font-size-s;
                            line-height: 16px;
                            width: auto;
                        }
                    }
                }
    
                &__title {
                    font-weight: normal;
                    font-size: $dobbies-font-size-xs;
                    width: 135px;
                    text-align: center;
                    position: relative;
                    left: 23%;
                    transform: translateX(-23%);
                    @media (min-width: 414px) {
                        left: 25%;
                    }
                    @media (min-width: 460px) {
                        left: 30%;
                    }
                    @media (min-width: 500px) {
                        left: 27%;
                        width: 215px;
                    }
                    @media (max-width: 400px) {
                        left: 25%;
                    }
                    
                    @media (min-width: $dobbies-container-max-width-s) {
                        font-size: $dobbies-font-size-s;
                        width: 328px;
                        left: 20%;
                        transform: none;
                    }

                    @media (min-width: 1024px) {
                        left: 24%;
                    }
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        width: auto;
                        text-align: left;
                        margin-left: 0;
                        position: static;
                    }
                }
            }
        }

        &--links {
            margin-top: 20px;
            min-height: auto;
            &.ms-content-block[data-m-layout='tile'] {
                .ms-content-block__details {
                    .ms-content-block__cta {
                        min-height: auto;
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 20px;
                        a[class^='msc-cta__'] {
                            font-family: $dobbies-font-azosans-medium;
                            font-weight: 500;
                            font-size: $dobbies-font-size-s;
                            margin: 0;
                            display: block;
                            width: 100%;
                            text-align: center;
                            padding-right: 17px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;
                            margin-bottom: 17px;
                            @media (min-width: $dobbies-container-max-width-s) {
                                margin-bottom: 20px;
                            }
                            &:last-child {
                                margin: 0;
                            }
                            &:hover {
                                color: $dobbies-green;
                            }

                            &::after {
                                @include dobbies-icon();
                                content: $dobbies-icon-arrow;
                                font-size: var(--dobbies-icon-font-size-m);
                                font-weight: var(--dobbies-font-weight-heavy);
                                color: $dobbies-secondary;
                                transform: rotate(90deg);
                                margin-left: 15px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    &__works  {

        &--header-container {
            @media (min-width: $dobbies-container-max-width-s) {
                max-width: 800px;
                margin: 0 auto;
            }
            .col-12 {
                flex: 0 0 33.33333%;
                max-width: 33.33333%;
            }
            .ms-content-block {
                min-height: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                max-width: 100px;
                padding-bottom: 20px;
                text-decoration: none;
                cursor: pointer;
                &.works-create-account-selected,
                &.works-member-card-selected,
                &.works-welcome-pack-selected {
                    position: relative;
                    text-decoration: none;
                    &::after {
                        content: "";
                        background: $dobbies-white;
                        width: 25px;
                        height: 25px;
                        position: absolute;
                        border: solid $dobbies-club-grey;
                        border-width: 1px 1px 0 0;
                        top: 100%;
                        left: 0;
                        right: 0;
                        margin: -12px auto 0;
                        transform: rotate(135deg);
                        z-index: 999;
                    }
                    .ms-content-block__image {
                        background-color: $dobbies-secondary-hover;
                    }
                }

                &:hover {
                    .ms-content-block__image {
                        background-color: $dobbies-secondary-hover;
                    }
                }

                @media (min-width: $dobbies-container-max-width-s) {
                    max-width: none;
                    flex-wrap: nowrap;
                    align-items: center;
                }

                &__image {
                    width: 46px;
                    height: 46px;
                    margin-bottom: 5px;
                    border-radius: 100%;
                    @media (min-width: $dobbies-container-max-width-s) {
                        margin-bottom: 0px;
                        margin-right: 11px;
                    }
                    img {
                        height: auto;
                    }
                }

                &__title {
                    text-align: center;
                    font-size: $dobbies-font-size-s;
                    font-weight: normal;
                    @media (min-width: $dobbies-container-max-width-s) {
                        text-align: left;
                    }
                }
            }
        } 

        &--body-container {
            @media (min-width: $dobbies-container-max-width-tablet) {
                padding: 0;
            }
            .ms-content-block {
                background-color: #F7F7F7;
                padding: 20px 31px 24px;
                border-top: 1px solid $dobbies-club-grey;
                border-bottom: 1px solid $dobbies-club-grey;
                position: relative;
                min-height: 496px;
                @media (min-width: $dobbies-container-max-width-s) {
                    display: flex;
                    align-items: center;
                    padding: 35px 52px 23px 60px;
                    margin-bottom: 40px;
                    min-height: 260px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    padding: 32px 86px 27px;
                    margin-bottom: 63px;
                    min-height: 360px;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    padding: 32px 250px 26px;
                }

                &__image {
                    max-width: 220px;
                    min-height: 220px;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    text-align: center;
                    @media (min-width: $dobbies-container-max-width-s) {
                        margin-bottom: 0px;
                        max-width: 180px;
                        max-height: 180px;
                        margin-right: 44px;
                    }
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        max-width: 250px;
                        max-height: 250px;
                        margin-right: 58px;
                    }

                    @media (min-width: $dobbies-container-max-width-xl) {
                        margin-right: 110px;
                    }

                    img {
                        height: auto;
                    }
                }

                &__title {
                    font-family: $dobbies-font-azosans-medium;
                    font-size: $dobbies-title-font-size-xs;
                    text-align: center;
                    margin-bottom: 20px;
                    @media (min-width: $dobbies-container-max-width-s) {
                        font-size: $dobbies-font-size-l;
                        text-align: left;
                        margin-bottom: 11px;
                    }
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        font-size: $dobbies-title-font-size-xs;
                        margin-bottom: 14px;
                    }
                }

                &__text {
                    font-size: $dobbies-font-size-s;
                    text-align: center;
                    @media (min-width: $dobbies-container-max-width-s) {
                        text-align: left;
                    }
                    p {
                        margin: 0;
                    }
                }
            }
            .ms-content-block[data-m-layout='tile'] {
                .ms-content-block__cta {
                    a {
                        position: absolute;
                        height: 21px;
                        width: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;

                        &::before {
                            @include dobbies-icon();
                            content: $dobbies-icon-arrow;
                            font-size: var(--dobbies-icon-font-size-m);
                            font-weight: var(--dobbies-font-weight-heavy);
                            color: $dobbies-secondary;
                            position: absolute;
                        }
                        &.msc-cta__primary {
                            left: 7px;
                            @media (min-width: $dobbies-container-max-width-s) {
                                left: 12px;
                            }
                            @media (min-width: $dobbies-container-max-width-tablet) {
                                left: 20px;
                            }
                            @media (min-width: $dobbies-container-max-width-xl) {
                                left: 100px;
                            }
                            &:before {
                                transform: rotate(270deg);
                            }
                        }
                        &.msc-cta__secondary {
                            right: 8px;
                            margin: 0;
                            @media (min-width: $dobbies-container-max-width-s) {
                                right: 16px;
                            }
                            @media (min-width: $dobbies-container-max-width-tablet) {
                                right: 24px;
                            }
                            @media (min-width: $dobbies-container-max-width-xl) {
                                right: 100px;
                            }
                            &:before {
                                transform: rotate(90deg);
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &__member-card {
        padding: 0;
        margin-top: 40px;
        margin-bottom: 127px;
        @media (min-width: $dobbies-container-max-width-s) {
            margin-bottom: 87px;
            margin-top: 60px;
        }
    
        @media (min-width: $dobbies-container-max-width-tablet) {
            margin-bottom: 165px;
            margin-top: 115px;
        }
        .ms-content-block {
            background: $dobbies-club-yellow;
            color: $dobbies-black;
            width: 100%;
            min-height: auto;
            padding: 27px 20px 20px 20px;
            display: flex;
            align-items: center;
            @media (min-width: $dobbies-container-max-width-s) {
                padding: 23px 32px;
            }
            @media (min-width: $dobbies-container-max-width-tablet) {
                padding: 30px 43px;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                padding: 68px 76px;
            }
            &::after {
                content:"";
                position: absolute;
                background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MDiKWF) center center no-repeat;
                background-size: contain;
                height: 302px;
                width: 149px;
                right: 20px;
                top: 135px;
                @media (min-width: $dobbies-container-max-width-s) {
                    right: 50px;
                    height: 268px;
                    width: 132px;
                    top: initial;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    right: 70px;
                    height: 363px;
                    width: 180px;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    right: 114px;
                    height: 540px;
                    width: 268px;
                }
            }

            &__image {
                display: none;
                @media (min-width: $dobbies-container-max-width-s) {
                    width: 118px;
                    height: 118px;
                    margin-right: 32px;
                    border-radius: 10px;
                    overflow: hidden;
                    display: inline-block;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    width: 160px;
                    height: 160px;
                    margin-right: 44px;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    width: 189px;
                    height: 189px;
                    margin-right: 50px;
                }
                    
                img {
                    height: auto;
                }
            }

            &__details {
                width: auto;
                @media (min-width: $dobbies-container-max-width-s) {
                    width: 331px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    width: 450px;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    width: 526px;
                }
            }

            &__title {
                font-size: 30px;
                margin-bottom: 30px;
                text-transform: uppercase;
                line-height: 36px;
                @media (min-width: 400px) {
                    font-size: 32px;
                    padding-right: 20px;
                    line-height: 40px;
                }
                @media (min-width: $dobbies-container-max-width-s) {
                    font-size: 20px;
                    margin-bottom: 7.5px;
                    padding-right: 60px;
                    margin-bottom: 6px;
                    line-height: 24px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    font-size: 27px;
                    line-height: 30px;
                    margin-bottom: 10px;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    font-size: 32px;
                    line-height: 40px;
                    margin-bottom: 6px;
                }
            }
            &__text {
                font-size: $dobbies-font-size-s;
                font-weight: $dobbies-font-weight-bold;
                width: 50%;
                
                @media (min-width: $dobbies-container-max-width-s) {
                    font-size: $dobbies-font-size-xs;
                    line-height: 14px;
                    width: auto;
                }

                @media (min-width: $dobbies-container-max-width-xl) {
                    font-size: $dobbies-font-size-m;
                    line-height: 24px;
                }

                p {
                    margin: 0;

                    a {
                        width: 120px;
                        height: 35px;
                        font-size: 0;
                        margin-top: 10px;
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            margin-top: 20px;
                        }
                        &:first-child {
                            margin-top: 30px;
                            @media (min-width: $dobbies-container-max-width-s) {
                                margin-top: 0px;
                            }
                        }
                        &.app-store-link {
                            background: $dobbies-black url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/ME8gug?pubver=0) center center no-repeat;
                            background-size: contain;
                            background-size: 123px 37px;
                            border-radius: 5px;
                            margin-right: 15px;
                        }
            
                        &.google-app-link {
                            background: $dobbies-black url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/ME8gud?pubver=0) center center no-repeat;
                            background-size: 140px 55px;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
}

body {
    .clubplus-landingpage__benefits,
    .clubplus-landingpage__member-card,
    .clubplus-landingpage__works--body-container {
        @media (min-width: $dobbies-container-max-width-tablet) {
            padding: 0;
        }
    }
}

.why-club-plus {

    & + .trustpilot-container {
        margin-top: 0;
        margin-bottom: 0px;
        @media (min-width: $dobbies-container-max-width-s) {
            padding: 0;
            margin-bottom: 60px;
        }

        .clubplus-landingpage__title {
            @media (max-width: $dobbies-container-max-width-s) {
                padding: 0 10px;
            }
            .ms-content-block__title {
                @media (max-width: $dobbies-container-max-width-s) {
                    max-width: 72%;
                    margin: 0 auto;
                }
            }
        }
    
        .trustpilot-widget {
            border-top: 1px solid $dobbies-club-grey;
            border-bottom: 1px solid $dobbies-club-grey;
            padding: 20px 0;
            &::before {
                display: none;
                @media (max-width: $dobbies-container-max-width-s) {
                    width: 150px;
                    left: 0;
                }
            }
        }
    }

    .ms-content-block[data-m-layout='tile'] {
        min-height: auto;
    }

    .clubplus-landingpage__title {
        @media (max-width: $dobbies-container-max-width-s) {
            margin-bottom: 40px;
        }
    }

    @media (min-width: $dobbies-container-max-width-s) {
        // height: 160px;
        margin-bottom: 35px;
        margin-top: 0px;
    }

    @media (min-width: $dobbies-container-max-width-tablet) {
        margin-bottom: 103px;
    }

    &__container {
        padding: 0;
        > .row {
            > .col-12 {
                margin-bottom: 55px;
                &:last-child {
                    margin: 0;
                }
                @media (min-width: $dobbies-container-max-width-s) {
                    flex: 0 0 25%;
                    max-width: 25%;
                    margin: 0;
                }
            }
        }
    }

    &__content {
        min-height: auto;
        max-width: 170px;
        margin: 0 auto;
        position: relative;
        transition: transform 0.6s;

        @media (min-width: $dobbies-container-max-width-s) {
            max-width: 132px;
        }
        @media (min-width: $dobbies-container-max-width-tablet) {
            max-width: 190px;
        }

        &::last-child {
            margin-bottom: 0;
        }

        .ms-content-block__title {
            font-family: $dobbies-primary-font-family;
            font-size: $dobbies-font-size-l;
            font-weight: 500;
            color: $dobbies-black;
            margin: 0 auto;
            margin-top: 34px;
            text-align: center;

            @media (min-width: $dobbies-container-max-width-s) {
                font-size: $dobbies-font-size-s;
                margin-top: 16px;
            }
    
            @media (min-width: $dobbies-container-max-width-tablet) {
                font-size: $dobbies-font-size-ml;
                margin-top: 42px;
            }
        }

        &--drinks {
            .ms-content-block__image {
                width: 155px;
                @media (min-width: $dobbies-container-max-width-s) {
                    width: 103px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    width: 155px;
                }
            }
        }

        &--off {
            .ms-content-block__image {
                width: 160px;
                @media (min-width: $dobbies-container-max-width-s) {
                    width: 107px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    width: 160px;
                }
            }
        }

        &--two-points {
            .ms-content-block__image {
                width: 156px;
                @media (min-width: $dobbies-container-max-width-s) {
                    width: 105px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    width: 156px;
                }
            }

            .ms-content-block__title {
                margin-left: -10px;
                margin-right: -10px;
                @media (min-width: $dobbies-container-max-width-s) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        &--birthday {
            .ms-content-block__image {
                width: 135px;
                @media (min-width: $dobbies-container-max-width-s) {
                    width: 90px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    width: 134px;
                }
            }
        }

        .ms-content-block__image {
            margin: 0 auto;
            @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
                width: 80%;
            }
            img {
                height: 100%;
                width: 100%;
            }
        }

        > .row {
            .col-12 {
                &:last-child {
                    .ms-content-block__image {
                        display: none;
                    }
                }
            }
        }
    }
}

.flip-animation {
    background-color: transparent;
    width: auto;
    height: 160px;
    perspective: 1000px;
    @media (min-width: $dobbies-container-max-width-s) {
        width: 134px;
        height: 120px;
    }
    @media (min-width: $dobbies-container-max-width-tablet) {
        width: 200px;
        height: 170px;
    }

    &:hover {
        > .row {
            transform: rotateY(180deg);
        }
    }

    > .row {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;

        .col-12 {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            &:last-child {
                transform: rotateY(180deg);
                .ms-content-block__image {
                    display: none;
                }
            }
        }
    }

    &__back {
        .ms-content-block__title {
            width: 188px;
            height: 188px;
            background: $dobbies-club-yellow;
            border-radius: 85px;
            font-size: $dobbies-font-size-ml;
            font-weight: bold;
            line-height: $msv-line-height-m;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            text-align: center;
            color: $dobbies-club-grey;
            padding: 0 21px;
            margin: 0;
            bottom: 0;

            @media (min-width: $dobbies-container-max-width-s) {
                width: 126px;
                height: 126px;
                font-size: 13px;
                line-height: 16px;
                border-radius: 55px;
                bottom: -10px;
            }
    
            @media (min-width: $dobbies-container-max-width-tablet) {
                width: 190px;
                height: 190px;
                font-size: $dobbies-font-size-ml;
                line-height: $msv-line-height-m;
                border-radius: 85px;
                bottom: -4px;
            }
        }
    }
}
