$msv-account-profile-edit-width: 40%;
$msv-account-profile-edit-width-m: 80%;
$msv-account-profile-edit-message-margin-top: 20px;
$msv-account-profile-edit-message-padding: 20px;
$msv-account-profile-edit-account-item-margin-top: 20px;
$msv-account-profile-edit-input-margin-top: 4px;
$msv-account-profile-edit-button-group-spacing: 32px;
$msv-account-profile-edit-button-margin-top: 20px;
$dobbies-account-profile-edit-button-margin-top: 30px;

//style presets
:root {
    --msv-account-profile-edit-font-size: var(--msv-body-font-size-m);

    // heading
    --msv-account-profile-edit-heading-font-size: var(--msv-body-font-size-xl);
    --msv-account-profile-edit-heading-font-color: var(--dobbies-font-primary-color);

    //error
    --msv-account-profile-edit-error-font-color: #{$dobbies-red};
    --msv-account-profile-edit-error-font-size: var(--msv-body-font-size-m);

    // alert
    --msv-account-profile-alert-bg: #{$dobbies-gray-300};
    --msv-account-profile-alert-font-color: var(--dobbies-font-primary-color);
    --msv-account-profile-alert-border: #{$dobbies-gray-900};

    // primary button
    --msv-account-profile-edit-primary-btn-bg: var(--dobbies-accent-brand-color);
    --msv-account-profile-edit-primary-btn-font-color: var(--dobbies-font-secondary-color);
    --msv-account-profile-edit-primary-btn-border: var(--dobbies-accent-brand-color);

    // secondary button
    --msv-account-profile-edit-secondary-btn-bg: var(--dobbies-bg-color);
    --msv-account-profile-edit-secondary-btn-font-color: var(--dobbies-font-primary-color);
    --msv-account-profile-edit-secondary-btn-border: var(--dobbies-accent-brand-color);

    //input color
    --msv-account-profile-edit-input-bg: #{$dobbies-white};
}

.ms-account-profile-edit {
    @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-edit-font-size), $dobbies-line-height-m);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 68vh;
    position: relative;
    padding-top: 130px;
    padding-bottom: 50px;

    @media (max-width: $msv-breakpoint-xl) and (min-width: $dobbies-container-max-width-l) {
        min-height: 90vh;
    }

    &__container {
        width: $msv-account-profile-edit-width;
        max-width: 358px;
    }

    &__heading {
        @include font-content(var(--dobbies-font-weight-heavy), var(--dobbies-body-font-size-m), $dobbies-line-height-xl);
        color: var(--msv-account-profile-edit-heading-font-color);
        padding-bottom: 18px;
        border-bottom: 3px solid var(--dobbies-green-border-color);
        text-align: center;
        margin-bottom: 25px;
    }

    &__account-item {
        margin-top: $msv-account-profile-edit-account-item-margin-top;

        &-input {
            @include form-input-el(var(--msv-account-profile-edit-input-bg));
            margin-top: $msv-account-profile-edit-input-margin-top;
            width: 100%;
            height: 60px;
            border: 1px solid var(--dobbies-icon-color-header);

            @media screen and (max-width: $msv-breakpoint-m) {
                height: 40px;
            }
        }
    }

    &__page-success {
        @include validation-success(
            var(--msv-account-profile-alert-bg),
            var(--msv-account-profile-alert-border),
            var(--msv-account-profile-alert-font-color)
        );
        display: none;
        margin-top: $msv-account-profile-edit-message-margin-top;
        padding: $msv-account-profile-edit-message-padding;
    }

    &__page-error {
        @include validation-error(
            var(--msv-account-profile-alert-bg),
            var(--msv-account-profile-alert-border),
            var(--msv-account-profile-alert-font-color)
        );
        display: none;
        margin-top: $msv-account-profile-edit-message-margin-top;
        padding: $msv-account-profile-edit-message-padding;
    }

    &__item-error {
        @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-edit-error-font-size), $dobbies-line-height-m);
        color: var(--msv-account-profile-edit-error-font-color);
    }

    &__save-button {
        @include primary-button(
            $bg-color: var(--msv-account-profile-edit-primary-btn-bg),
            $color: var(--msv-account-profile-edit-primary-btn-font-color),
            $border-color: var(--msv-account-profile-edit-primary-btn-border)
        );
        margin-top: $dobbies-account-profile-edit-button-margin-top;
        width: 100%;
        border-radius: 0;
        line-height: inherit;
        font-size: var(--dobbies-body-font-size-m);
        font-weight: var(--dobbies-font-weight-heavy);
        height: 40px;
        position: relative;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        border: 0;
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-bg-color-primary);
            text-decoration: none;
            &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }

    &__cancel-button {
        @include secondary-button(
            $bg-color: var(--msv-account-profile-edit-secondary-btn-bg),
            $color: var(--msv-account-profile-edit-secondary-btn-font-color),
            $border-color: var(--msv-account-profile-edit-secondary-btn-border)
        );
        margin-top: $dobbies-account-profile-edit-button-margin-top;
        width: 100%;
        display: none;
    }

    &__verifying-modal,
    &__verifying-modal-message {
        display: none;
    }

    @media screen and (min-width: $msv-container-max-width-l) {
        &__container {
            min-width: 538px;
            max-width: 538px;
        }
    }

    @media screen and (min-width: $msv-container-max-width-m) {
        &__container {
            min-width: 504px;
            max-width: 504px;
        }

        &__save-button {
            height: 60px;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: $msv-account-profile-edit-width-m;
        }
    }
}
.ms-account-profile {
    &__attributes,
    &__attributes__section {
        select {
            border: solid 1px var(--dobbies-nav-border-color);
            padding: 5px 20px;
            border-radius: 0;
            min-width: 150px;
            margin: 10px 0;
            height: 30px;
            -webkit-appearance: none;
            -moz-appearance: none;
            color: $dobbies-grey;
            background-color: $white;
        }
        .personal-information {
            margin-top: 10px;
            &__first-name {
                margin-bottom: 20px;
            }
            &__last-name {
                margin-bottom: 20px;
            }
            label {
                font-weight: var(--dobbies-font-weight-bold);
                margin-bottom: 0;
                margin-right: 5px;
            }
            &__change-name {
                margin-bottom: 20px;
                .__change-name-text {
                    display: inline-block;
                    .__change-name-link {
                        font-weight: var(--dobbies-font-weight-bold);
                        text-decoration: underline;
                        margin-left: 4px;
                    }
                }
            }
        }
        &.ms-account-profile__attributes-Gender,
        &.ms-account-profile__attributes-Local_Store {
            position: relative;
            margin-bottom: 15px;
            max-width: 500px;
            &:after {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                transform: rotate(-180deg);
                position: absolute;
                top: 36px;
                right: 22px;
                background: var(--dobbies-bg-color);
                font-weight: var(--dobbies-font-weight-heavy);
                font-size: var(--dobbies-body-font-size-l);
                color: var(--dobbies-bg-color-secondary);
                z-index: 1;
                pointer-events: none;
            }
            select {
                width: calc(100% - 10px);
                margin-top: 0;
            }
            h3 {
                margin-bottom: 10px;
                margin-top: 20px;
            }
        }
        &.ms-account-profile__attributes-Mobile_Telephone {
            position: relative;
            margin-bottom: 20px;
            max-width: 500px;
            h3 {
                margin-bottom: 0;
                margin-top: 20px;
            }
            input[type='number'] {
                border: solid 1px var(--dobbies-nav-border-color);
                padding: 15px 20px;
                border-radius: 0;
                min-width: 150px;
                margin: 5px 0;
                width: calc(100% - 10px);
            }
        }
        .__customer-service-text {
            ~ div {
                display: inline-block;
                .__customer-service-link {
                    background: transparent;
                    color: var(--dobbies-font-primary-color);
                    font-size: var(--dobbies-body-font-size-s);
                    padding: 0;
                    margin: 0;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        background: transparent;
                        text-decoration: underline;
                    }
                }
            }
        }
        .customer-service-info {
            &__email {
                margin-bottom: 0;
                label {
                    font-weight: var(--dobbies-font-weight-bold);
                    margin-bottom: 0;
                    margin-right: 5px;
                }
            }
            &__service-link {
                margin-bottom: 8px;
                .__customer-service-text {
                    .__customer-service-link {
                        font-size: var(--dobbies-body-font-size-s);
                        margin: 0 0 0 4px;
                    }
                }
            }
            &__password-link {
                margin-bottom: 15px;
                .__change-password-text {
                    .__change-password-link {
                        font-size: var(--dobbies-body-font-size-s);
                        font-weight: var(--dobbies-font-weight-bold);
                        text-decoration: underline;
                    }
                }
            }
        }
        &-save-button {
            background: var(--dobbies-bg-color-primary);
            color: var(--dobbies-font-secondary-color);
            padding: 10px 30px 12px;
            margin: 10px 0;
            height: 46px;
            min-width: 180px;
            border-radius: 4px;
            font-size: var(--dobbies-body-font-size-m);
            font-weight: var(--dobbies-font-weight-bold);
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            border: 0;
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-secondary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                background: var(--dobbies-bg-color-primary);
                &:after {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }
        &-cancel-button {
            background-color: transparent;
            color: var(--dobbies-font-primary-color);
        }
        &-error {
            color: var(--dobbies-error-color);
            font-weight: var(--dobbies-font-weight-bold);
            margin: 0 0 15px;
        }
    }
    &__club-plus-consent-attributes {
        overflow: hidden;
    }
}
.ms-profile-attribute {
    &__title-attribute {
        .ms-account-profile__attributes-Title {
            display: inline-block;
            position: relative;
            &:after {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                transform: rotate(-180deg);
                position: absolute;
                top: 40px;
                right: 12px;
                background: var(--dobbies-bg-color);
                font-weight: var(--dobbies-font-weight-heavy);
                font-size: var(--dobbies-body-font-size-l);
                color: var(--dobbies-bg-color-secondary);
                z-index: 1;
                pointer-events: none;
            }
        }
    }
}
.ms-profile-attributes {
    &__dob-section--edit-mode {
        //margin: 15px 0;
        overflow: hidden;
        max-width: 500px;
        > span {
            display: block;
        }
        h3.dob-heading {
            margin: 0;
            font-weight: var(--dobbies-font-weight-bold);
            font-size: 16px;
        }
        > div {
            float: left;
            width: calc(33.33% - 10px);
            margin-right: 10px;
            position: relative;
            &:after {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                transform: rotate(-180deg);
                position: absolute;
                top: 18px;
                right: 12px;
                background: var(--dobbies-bg-color);
                font-weight: var(--dobbies-font-weight-heavy);
                font-size: var(--dobbies-body-font-size-l);
                color: var(--dobbies-bg-color-secondary);
                z-index: 1;
                pointer-events: none;
            }
            select {
                width: 100%;
                margin-bottom: 10px;
                min-width: auto;
            }
        }
    }
}
