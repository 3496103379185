$msv-account-profile-heading-margin-bottom: 32px;

$msv-account-profile-section-margin-bottom: 30px;
$msv-account-profile-section-heading-margin-bottom: 4px;
$msv-account-profile-section-description-margin-bottom: 6px;

$msv-account-profile-preference-margin-top: 20px;
$msv-account-profile-preference-heading-margin-bottom: 10px;
$msv-account-profile-preference-description-margin-bottom: 0px;

$msv-account-profile-toggle-button-icon-size: 28px;
$msv-account-profile-toggle-button-padding: 0 5px;
$bobbies-profile-title-line-height: 50px;

//style presets
:root {
    --msv-account-profile-font-size: var(--msv-body-font-size-m);
    --msv-account-profile-font-color: var(--dobbies-font-primary-color);

    // heading
    --msv-account-profile-heading-font-color: var(--dobbies-font-primary-color);
    --msv-account-profile-heading-font-size: var(--msv-body-font-size-l);

    // title
    --msv-account-profile-title-font-size: var(--msv-body-font-size-xl);

    // button
    --msv-account-profile-toggle-btn-font-color: var(--dobbies-accent-brand-color);
    --msv-account-profile-toggle-btn-font-size: var(--msv-body-font-size-m);
    --msv-account-profile-toggle-size: #{$msv-account-profile-toggle-button-icon-size};
}

.ms-account-profile {
    @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-font-size), normal);
    color: var(--msv-account-profile-font-color);
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ms-account-loyalty-tile__heading {
        @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-s));
        color: var(--msv-account-profile-heading-font-color);
        margin-bottom: 20px;
        //margin-top: 33px; //for order page
        border-bottom: 1px solid $dobbies-grey;
        text-decoration: none;
        width: 100%;
        padding-bottom: 15px;
        //line-height: normal;

        @media (max-width: $msv-container-max-width-s) {
            margin-top: 0;
        }
        @media (max-width: $msv-breakpoint-l) {
            margin-top: 0;
            padding-bottom: 10px;
        }

        &::before {
            display: none;
        }
    }

    &__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-title-font-size), $dobbies-line-height-xl);
        margin-bottom: $msv-account-profile-heading-margin-bottom;
    }

    &__section {
        margin-bottom: $msv-account-profile-section-margin-bottom;

        &-heading {
            @include font-content(var(--msv-font-weight-heavy), var(--msv-account-profile-heading-font-size), $dobbies-line-height-l);
            color: var(--msv-account-profile-heading-font-color);
            margin-bottom: $msv-account-profile-section-heading-margin-bottom;
        }

        &-description {
            margin-bottom: $msv-account-profile-section-description-margin-bottom;
        }

        &-links {
            text-decoration: underline;
        }

        &-link {
            @include vfi();
            color: var(--msv-account-profile-font-color);
        }
    }

    &__preference {
        margin-top: $msv-account-profile-preference-margin-top;

        &-heading {
            @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-font-size), $dobbies-line-height-m);
            color: var(--msv-account-profile-font-color);
            margin-bottom: $msv-account-profile-preference-heading-margin-bottom;
        }

        &-description {
            margin-bottom: $msv-account-profile-preference-description-margin-bottom;
        }
    }

    &__toggle-wrapper {
        font-size: var(--msv-account-profile-toggle-btn-font-size);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }

    &__toggle-button {
        font-size: var(--msv-account-profile-toggle-size);
        background-color: transparent;
        padding: $msv-account-profile-toggle-button-padding;
        color: var(--msv-account-profile-toggle-btn-font-color);
        &.ms-account-profile__toggle-disable-button {
            color: $dobbies-club-grey;
        }
        &:focus {
            outline: none;
        }
    }

    &__toggle-enable {
        .ms-account-profile__toggle-enable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-disable {
        .ms-account-profile__toggle-disable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-enable-button {
        @include add-icon($msv-ToggleRight);

        &::before {
            transform: scale(-1, 1);
        }
    }

    &__toggle-disable-button {
        @include add-icon($msv-ToggleRight);
    }

    &__section-email,
    &__section-name {
        display: flex;
        align-items: flex-end;
        margin-bottom: 15px;

        .ms-account-profile__section-heading {
            font-size: var(--dobbies-body-font-size-m);
            font-weight: var(--dobbies-font-weight-heavy);
            margin-right: 4px;
            &:after {
                content: ':';
            }
        }

        .ms-account-profile__section-description {
            font-size: var(--dobbies-body-font-size-m);
        }
    }

    &__section-name {
        position: relative;
        padding-bottom: 30px;

        .ms-account-profile__section-links {
            font-size: var(--dobbies-body-font-size-s);
            font-weight: var(--dobbies-font-weight-bold);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &__section-preferences {
        width: 100%;
        margin-bottom: 15px;

        .ms-account-profile__section-heading {
            font-weight: var(--dobbies-font-weight-heavy);
            font-size: var(--dobbies-body-font-size-m);
            line-height: $bobbies-profile-title-line-height;
            color: var(--msv-account-profile-heading-font-color);
            margin-bottom: 28px;
            margin-bottom: 20px;
            border-bottom: 1px solid var(--dobbies-green-border-color);
        }
        .ms-account-profile__preference-heading {
            font-weight: var(--dobbies-font-weight-heavy);
            font-size: var(--dobbies-body-font-size-m);
            margin-bottom: 20px;
        }
    }
    .__preferences-error-message {
        color: var(--dobbies-msg-alert-color);
        font-size: var(--dobbies-body-font-size-s);
        font-weight: var(--dobbies-font-weight-bold);
        margin-bottom: 15px !important;
    }
    &-wrapper {
        width: 100%;
        margin-bottom: 20px;
        // padding-bottom: 30px;
        //border-bottom: 1px solid var(--dobbies-green-border-color);
        .makreting-text-block {
            .ms-text-block {
                padding-bottom: 0;
                border-bottom: 0;
            }
        }
        .__customer-service-text {
            font-weight: var(--dobbies-font-weight-normal);
            //margin-bottom: 8px;
            > a {
                margin: 0 0 0 4px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                background: transparent;
                padding: 0;
                color: var(--dobbies-font-primary-color);
                &:hover,
                &:focus {
                    text-decoration: underline;
                    color: var(--dobbies-font-primary-color);
                    background: transparent;
                }
                &:after {
                    display: none;
                }
            }
        }
        .__customer-service-link,
        .ms-account__forgot-password-button {
            background-color: var(--msv-address-primary-btn-bg);
            border: 1px solid var(--msv-address-primary-btn-border);
            color: var(--msv-address-primary-btn-font-color);
            padding: 13px 26px;
            border-radius: 4px;
            text-decoration: none;
            transition: all 0.3s;
            font-size: var(--dobbies-body-font-size-m);
            font-weight: var(--dobbies-font-weight-heavy);
            line-height: initial;
            margin: 5px 0 20px;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            border: 0;
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-secondary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                // background: var(--dobbies-bg-color-primary);
                &:after {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }
    }
    .preference-section-checkbox {
        > .msc-btn {
            background-color: var(--msv-address-primary-btn-bg);
            border: 1px solid var(--msv-address-primary-btn-border);
            color: var(--msv-address-primary-btn-font-color);
            padding: 13px 26px;
            min-width: 223px;
            border-radius: 4px;
            text-decoration: none;
            transition: all 0.3s;
            font-size: var(--dobbies-body-font-size-m);
            font-weight: var(--dobbies-font-weight-heavy);
            line-height: initial;
            margin: 15px 0 30px;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            border: 0;
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-secondary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                //background: var(--dobbies-bg-color-primary);
                &:after {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }
    }
    &-wrapper__name {
        margin-bottom: 20px;
        text-transform: capitalize;
        label {
            font-weight: var(--dobbies-font-weight-bold);
            margin-right: 4px;
            margin-bottom: 0px;
            text-transform: capitalize;
        }
    }
    &__attributes {
        &__section-heading {
            font-weight: var(--dobbies-font-weight-bold);
            font-size: 16px;
            text-transform: uppercase;
        }
        > p {
            margin-bottom: 20px;
            @media screen and (max-width: $dobbies-container-max-width-xl) {
                margin-bottom: 10px;
            }
            .__privacy-policy-link {
                font-weight: var(--dobbies-font-weight-bold);
                text-decoration: underline;
                margin-left: 4px;
            }
        }
        &__dob-section {
            &--view-mode {
                //margin-bottom: 40px;

                &:first-child {
                    margin-bottom: 20px;
                }

                > p {
                    &:not(:first-child) {
                        margin-top: 20px;
                    }

                    &.__customer-service-text {
                        margin-top: 10px;
                    }
                }
                label {
                    font-weight: var(--dobbies-font-weight-bold);
                    margin-right: 4px;
                    margin-bottom: 0;
                }
                .__customer-service-link {
                    font-size: var(--dobbies-body-font-size-s);
                }
            }
        }
        h3 {
            font-size: 16px;
            margin-top: 40px;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
    }
    &__simple-consent-attributes,
    &__club-plus-consent-attributes {
        overflow: hidden;
        > div {
            width: 33.33%;
            float: left;
            margin: 0 0 20px;
            font-weight: var(--dobbies-font-weight-normal);
            input[type='checkbox'] {
                margin: 3px 10px 0 0;
                float: left;
                position: relative;
                &:checked {
                    &::after {
                        content: '';
                        position: absolute;
                        width: 4.5px;
                        height: 9.5px;
                        border: solid $dobbies-grey;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        left: 5px;
                        top: 1px;
                    }
                }

                &::before {
                    content: '';
                    width: 15px;
                    height: 15px;
                    border: 1px solid $dobbies-grey;
                    background-color: $white;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    border-radius: 2px;
                }
            }

            span {
                position: relative;
            }
            @media (max-width: $msv-breakpoint-m) {
                width: 50%;
                margin: 0 0 10px;
            }
        }
    }
    &__attributes-edit-button {
        // background: var(--dobbies-bg-color-primary);
        color: var(--dobbies-font-secondary-color);
        padding: 5px 20px;
        margin-bottom: 20px;
        height: 35px;
        min-width: 200px;
        border-radius: 4px;
        font-size: var(--dobbies-body-font-size-m);
        font-weight: var(--dobbies-font-weight-bold);
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        background-color: white;
        border: 1px solid black;
        color: #111;
        @media (max-width: $dobbies-container-max-width-xl) {
            margin-top: 10px;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: var(--dobbies-font-primary-color);
            //background: var(--dobbies-bg-color-primary);
            &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }

    .ms-account-profile__attributes__section.ms-account-profile__attributes-Mobile_Telephone {
        input[type='number'] {
            padding: 5px 20px;
        }
    }
}

.default-container-2-slots .default-container {
    .ms-text-block {
        .ms-account-profile__preference-heading {
            font-weight: var(--dobbies-font-weight-heavy);
            font-size: var(--dobbies-body-font-size-m);
        }
    }
}
.ms-account {
    &__forgot-password {
        &-label {
            display: block;
            margin-top: 15px;
        }
        &-button {
            text-decoration: underline;
            font-size: var(--dobbies-body-font-size-s);
            font-weight: var(--dobbies-font-weight-bold);
        }
    }
}
