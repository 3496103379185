$content-block-min-height: 150px;
$hero-body-max-length: 30em;
$hero-body-top-margin: 8px;
$hero-content-text-margin-top: 16px;
$hero-content-button-margin-top: 32px;
$hero-content-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
$hero-heading-color: $black;
$hero-heading-font-size: 32px;
$hero-heading-line-height: 43px;
$hero-heading-max-length: 30em;
$hero-link-to-link-spacing: 16px;
$hero-links-top-margin: 32px;

:root {
	// heading
	--msv-content-block-heading-font-size: #{$hero-heading-font-size};
	--msv-content-block-heading-font-color: var(--dobbies-font-primary-color);

	// Text theme
	--msv-content-block-textheme-dark: var(--dobbies-font-primary-color);
	--msv-content-block-textheme-light: #{$dobbies-gray-100};

	--msv-content-block-text-font-size: var(--msv-body-font-size-m);

	// primary button
	--msv-content-block-primary-btn-bg: var(--dobbies-accent-brand-color);
	--msv-content-block-primary-btn-font-color: var(--dobbies-font-secondary-color);
	--msv-content-block-primary-btn-border: var(--dobbies-accent-brand-color);
}

@mixin overlay-text-on-image {
	.ms-content-block__details {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: flex-start;
		left: 0;
		//padding: 20px 60px 20px 60px;
		position: absolute;
		right: 0;
		top: 0;
	}
}
.ms-content-block {
	min-height: $content-block-min-height;
	//margin-bottom: 40px;

	&.category-banner {
		padding: 32px 0 0;
		max-height: 350px;
		min-height: 322px;
		overflow: hidden;
		@media screen and (max-width: $msv-breakpoint-m) {
			padding: 0;
			max-height: 300px;
			min-height: 220px;
			margin-bottom: 0px;

			// &::before{
			//   content: " ";
			//   width: calc(100% - 10px);
			//   height: 150px;
			//   background: $dobbies-homepage-grey;
			//   position: absolute;
			//   top: 12px;
			//   left: 5px;

			//   @media screen and (max-width: $msv-breakpoint-mbl-sm){
			//     background: $dobbies-homepage-grey;
			//     height: 125px;
			//     width: calc(100% - 4px);
			//     left: 2px;
			//   }

			//   @media screen and (max-width: $msv-breakpoint-mbl){
			//     background: $dobbies-homepage-grey;
			//     height: 110px;
			//     width: 100%;
			//     left: 0;
			//   }
			// }
		}
		@media screen and (max-width: $msv-breakpoint-mbl-sm) {
			max-height: 250px;
			min-height: 250px;
			// margin-bottom: -80px;
		}
		// @media screen and (max-width: $msv-breakpoint-mbl){
		//   margin-bottom: -50px;
		// }
		.ms-content-block__image {
			img {
				width: 100%;
			}
		}
	}

	&[data-m-layout="full-width"] {
		position: relative;

		.ms-content-block__image,
		.ms-content-block__image img {
			width: 100%;
		}

		.ms-content-block__details,
		&.textplacement__left .ms-content-block__details {
			text-align: left;
			align-items: flex-start;
		}

		&.textplacement__center .ms-content-block__details {
			align-items: center;
			text-align: center;
		}

		&.textplacement__right .ms-content-block__details {
			text-align: right;
			align-items: flex-end;
		}

		.ms-content-block__title {
			color: var(--msv-content-block-heading-font-color);
			font-weight: var(--msv-font-weight-normal);
			font-size: var(--msv-content-block-heading-font-size);
			max-width: $hero-heading-max-length;
			line-height: $hero-heading-line-height;
		}

		.ms-content-block__text {
			@include font-content(
				var(--msv-font-weight-normal),
				var(--msv-content-block-text-font-size),
				$dobbies-line-height-m
			);
			color: var(--msv-content-block-textheme-dark);
			max-width: $hero-body-max-length;
		}

		&.texttheme__light .ms-content-block__title,
		&.texttheme__light .ms-content-block__text {
			color: var(--msv-content-block-textheme-light);
			text-shadow: $hero-content-shadow;
		}

		&.texttheme__dark .ms-content-block__title,
		&.texttheme__dark .ms-content-block__text {
			color: var(--msv-content-block-textheme-dark);
		}

		/* body should have top margin only when there's something above it */
		* + .ms-content-block__text {
			margin-top: $hero-content-text-margin-top;
		}

		.ms-content-block__cta {
			display: flex;
			align-items: flex-end;
			justify-content: center;
		}

		/* links should have top margin only when there's something above it */
		* + .ms-content-block__cta {
			margin-top: $hero-content-button-margin-top;
		}

		.ms-content-block__details {
			@include msv-layout-page-side-padding;
			display: flex;
			flex-direction: column;
			align-content: center;
			justify-content: center;

			.msc-cta__primary,
			.msc-cta__secondary {
				@include button-default();
				@include primary-button(
					var(--msv-content-block-primary-btn-bg),
					var(--msv-content-block-primary-btn-font-color),
					var(--msv-content-block-primary-btn-border)
				);
				margin: $hero-link-to-link-spacing / 2;
				display: inline-block;
			}

			.msc-cta__secondary {
				margin: $hero-link-to-link-spacing / 2;
			}
		}

		/**
    * Text over image by default only in modern browsers.
    * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
    */
		@supports (object-fit: cover) {
			.ms-content-block__image {
				object-fit: cover;
			}

			@include overlay-text-on-image;
		}

		/**
    * When the image is tall enough for IE 11 to display text on top of it. 
    */
		@media screen and (min-width: $msv-breakpoint-l) {
			@include overlay-text-on-image;
		}

		@media screen and (min-width: $msv-breakpoint-m) {
			.ms-content-block__details,
			&.textplacement__left .ms-content-block__details,
			&.textplacement__center .ms-content-block__details,
			&.textplacement__right .ms-content-block__details {
				width: max-content;
				height: 0;
				overflow: inherit;
			}
			&.textplacement__center .ms-content-block__details {
				margin: 0 auto;
			}
			&.textplacement__right .ms-content-block__details {
				margin-left: auto;
			}
		}
	}
	&-sale-banner {
		img {
			margin: auto;
		}
	}
	&[data-m-layout="add-banner"] {
		min-height: auto;
		margin: 40px 0;
		> a {
			display: block;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}
