$dobbies-font-path: '../../../webfonts';

$dobbies-primary-font-family: 'azo-sans-web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
$dobbies-secondary-font-family: 'turbinado-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
    $dobbies-secondary-font-family-light: 'turbinado-pro-light', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
$dobbies-font-azosans-medium: 'azo-sans-medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
'Helvetica Neue', sans-serif;
$dobbies-font-assistant: 'azo-sans-web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
$dobbies-font-allerta: 'azo-sans-web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
$dobbies-font-cinzel: 'azo-sans-web', Times, 'Times New Roman', serif;

$msv-font-style-normal: normal;
$msv-font-weight-bold: bold;
$msv-font-weight-normal: normal;
$msv-font-weight-light: 300;
$msv-font-weight-400: 400;
$msv-font-weight-500: 500;
$msv-font-weight-heavy: 600;
$msv-font-weight-700: 700;
$msv-text-size: 14px;

// Generic font sizes for adventure works theme
$msv-font-size-xs: 12px;
$msv-font-size-s: 14px;
$msv-font-size-m: 16px;
$msv-font-size-ml: 20px;
$msv-font-size-l: 24px;
$msv-font-size-xl: 36px;
$msv-font-size-xxl: 48px;

// Generic box shadow depths for adventure works theme
$msv-depth4: 0 0.3px 0.9px rgba(0, 0, 0, 0.1), 0 1.6px 3.6px rgba(0, 0, 0, 0.13);
$msv-depth8: 0 0.6px 1.8px rgba(0, 0, 0, 0.1), 0 3.2px 7.2px rgba(0, 0, 0, 0.13);
$msv-depth16: 0 1.2px 3.6px rgba(0, 0, 0, 0.1), 0 6.4px 14.4px rgba(0, 0, 0, 0.13);
$msv-depth24: 0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22);

// Generic line heights for adventure works theme
$msv-line-height-xs: 16px;
$msv-line-height-s: 20px;
$msv-line-height-m: 24px;
$msv-line-height-l: 28px;
$msv-line-height-xl: 44px;
$msv-line-height-xxl: 63px;
$msv-line-height-xxxl: 80px;

$msv-heading-text-size: 20px;
$msv-heading-line-height: 28px;

$dobbies-font-weight-light: 300;
$dobbies-font-weight-normal: 400;
$dobbies-font-weight-bold: 500;
$dobbies-font-weight-heavy: 700;

$dobbies-heading-text-size: 20px;
$dobbies-heading-line-height: 28px;

$dobbies-font-size-xs: 12px;
$dobbies-font-size-s: 14px;
$dobbies-font-size-m: 16px;
$dobbies-font-size-l: 18px;
$dobbies-font-size-ml: 20px;
$dobbies-font-size-xl: 22px;
$dobbies-font-size-xxl: 48px;
$dobbies-font-size-xxxl: 50px;
$dobbies-title-font-size-xs: 24px;
$dobbies-title-font-size-25: 25px;
$dobbies-title-font-size-30: 30px;
$dobbies-title-font-size-s: 36px;
$dobbies-title-font-size-40: 40px;
$dobbies-title-font-size-m: 100px;
$dobbies-title-font-size-l: 160px;
$dobbies-line-height-xxs: 14px;
$dobbies-line-height-xs: 16px;
$dobbies-line-height-s: 18px;
$dobbies-line-height-m: 21px;
$dobbies-line-height-l: 24px;
$dobbies-line-height-xl: 31px;
$dobbies-line-height-45: 45px;
$dobbies-line-height-55: 55px;
$dobbies-line-height-60: 60px;
$dobbies-line-height-xxl: 63px;
$dobbies-title-line-height-xs: 31px;
$dobbies-title-line-height-s: 46px;
$dobbies-title-line-height-m: 134px;
$dobbies-title-line-height-l: 215px;

$dobbies-checkout-font-size: 20px;
$dobbies-checkout-line-height: 26px;

$dobbies-icon-size-small: 16px;
$dobbies-icon-size-medium: 22px;
$dobbies-icon-size-large: 28px;
$dobbies-icon-size-xlarge: 32px;
$dobbies-icon-size: 24px;
$dobbies-text-size: $dobbies-font-size-m;
$dobbies-text-line-height: 20px;
$dobbies-plp-rating: 10px;
$dobbies-tile-heading: 35px;
$dobbies-tile-heading-s: 30px;
$dobbies-breadcrumb-icon-size: 10px;

@import url(//use.typekit.net/nhg7kde.css);
@font-face {
    font-family: 'turbinado-pro-light';
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src: url('#{$dobbies-font-path}/Turbinado-Pro-Light.otf') format('opentype'),
    url('#{$dobbies-font-path}/Turbinado-Pro-Light.woff') format('woff'),
    url('#{$dobbies-font-path}/Turbinado-Pro-Light.woff2') format('woff2'),
    url('#{$dobbies-font-path}/Turbinado-Pro-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'azo-sans-medium';
    font-style: normal;
    font-display: auto;
    src: url('#{$dobbies-font-path}/AzoSans-Medium.otf') format('opentype');
}
@mixin dobbies-font(
    $font-weight: $dobbies-font-weight-normal,
    $font-size: $dobbies-font-size-s,
    $line-height: $dobbies-line-height-s,
    $text-transform: none,
    $font-family: $dobbies-font-assistant
) {
    font-family: $font-family;
    font-weight: $font-weight;
    font-size: $font-size;
    line-height: $line-height;
    text-transform: $text-transform;
}

@mixin font-content(
    $font-weight: $dobbies-font-weight-normal,
    $font-size: $dobbies-font-size-s,
    $line-height: $dobbies-line-height-s,
    $text-transform: none
) {
    @include dobbies-font($font-weight, $font-size, $line-height, $text-transform);
}

@mixin font-content-underline(
    $font-weight: $dobbies-font-weight-normal,
    $font-size: $dobbies-font-size-s,
    $line-height: $dobbies-line-height-s
) {
    text-decoration-line: underline;
    @include font-content($font-weight, $font-size, $dobbies-line-height-m);
}

@mixin font-content-s($font-weight: $dobbies-font-weight-normal) {
    @include font-content($font-weight);
}

@mixin font-content-s-uppercase($font-weight: $dobbies-font-weight-normal) {
    @include font-content($font-weight, $dobbies-font-size-s, $dobbies-line-height-s, uppercase);
}

@mixin font-content-s-height-l($font-weight: $dobbies-font-weight-normal) {
    @include font-content($font-weight, $dobbies-font-size-s, $dobbies-line-height-l);
}

@mixin font-content-m($font-weight: $dobbies-font-weight-normal) {
    @include font-content($font-weight, $dobbies-font-size-m, $dobbies-line-height-m);
}

@mixin font-content-m-underline($font-weight: $dobbies-font-weight-normal) {
    text-decoration-line: underline;
    @include font-content($font-weight, $dobbies-font-size-m, $dobbies-line-height-m);
}

@mixin font-content-m-strikethrough($font-weight: $dobbies-font-weight-normal) {
    text-decoration-line: line-through;
    @include font-content($font-weight, $dobbies-font-size-m, $dobbies-line-height-m);
}

@mixin font-content-l($font-weight: $dobbies-font-weight-normal) {
    @include font-content($font-weight, $dobbies-font-size-l, $dobbies-line-height-l);
}

@mixin font-content-xl($font-weight: $dobbies-font-weight-normal) {
    @include font-content($font-weight, $dobbies-font-size-xl, $dobbies-line-height-xl);
}

@mixin font-content-xxl($font-weight: $dobbies-font-weight-normal) {
    @include font-content($font-weight, $dobbies-font-size-xxl, $dobbies-line-height-xxl);
}

@mixin dobbies-display(
    $font-size: $dobbies-title-font-size-xs,
    $line-height: $dobbies-title-line-height-xs,
    $font-family: $dobbies-font-allerta
) {
    @include dobbies-font($font-weight-normal, $font-size, $line-height, uppercase, $font-family);
}

@mixin font-display-xs() {
    @include dobbies-display();
}

@mixin font-display-s() {
    @include dobbies-display($dobbies-title-font-size-s, $dobbies-title-line-height-s);
}

@mixin font-display() {
    letter-spacing: 0.05em;
    @include dobbies-display($dobbies-title-font-size-m, $dobbies-title-line-height-m, $dobbies-font-cinzel);
}

@mixin font-display-l() {
    letter-spacing: 0.05em;
    @include dobbies-display($dobbies-title-font-size-l, $dobbies-title-line-height-l, $dobbies-font-cinzel);
}

@mixin font-glyph-standard() {
    font-size: $dobbies-icon-size;
}

@mixin font-glyph-small() {
    font-size: $dobbies-icon-size-small;
}

@mixin font-content-heading($font-weight: $dobbies-font-weight-heavy) {
    @include font-content($font-weight, $dobbies-heading-text-size, $dobbies-heading-line-height);
}

@mixin tile-heading {
    // font-size: var(--dobbies-heading-font-size-25);
    color: var(--dobbies-font-primary-color);
    line-height: $dobbies-line-height-xl;
    font-weight: var(--dobbies-font-weight-normal);
    overflow: hidden;
    text-overflow: ellipsis;
}
@mixin tile-paragraph {
    font-size: var(--dobbies-body-font-size-s);
    color: var(--dobbies-font-primary-color);
    font-weight: var(--dobbies-font-weight-normal);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
    min-height: 58px;
}
@mixin tile-paragraph-oneline {
    font-size: var(--dobbies-body-font-size-s);
    color: var(--dobbies-font-primary-color);
    font-weight: var(--dobbies-font-weight-normal);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 35px;
}
@mixin tile-link {
    font-size: var(--dobbies-body-font-size-s);
    color: var(--dobbies-font-primary-color);
    font-weight: var(--dobbies-font-weight-normal);
    text-decoration: underline;
    display: inline-block;
    transition: all 0.3s;
    &:hover {
        font-weight: var(--dobbies-font-weight-bold);
    }
}
@mixin cat-link {
    font-size: var(--dobbies-body-font-size-m);
    color: var(--dobbies-heading-primary-color);
    font-weight: var(--dobbies-font-weight-normal);
    text-transform: uppercase;
}

// Body REGULAR small, font-size = 14px & line-height = 20px

@mixin font-body-regular-s() {
    @include msv-rubik-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-s),
        $line-height: var(--msv-body-line-height-s)
    );

    color: var(--msv-body-font-color);
}

// Body REGULAR extra small, font-size = 12px & line-height = 16px

@mixin font-body-regular-xs() {
    @include msv-rubik-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-xs),
        $line-height: var(--msv-body-line-height-xs)
    );

    color: var(--msv-body-font-color);
}

// H4 (desktop) MEDIUM, font-size = 24px & line-height = 28px

@mixin font-heading-h4-l() {
    @include msv-rubik-medium(
        $font-weight: $msv-font-weight-500,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h4-font-size-l),
        $line-height: var(--msv-h4-line-height-l)
    );
}

@mixin font-body-regular-m() {
    @include msv-rubik-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-m),
        $line-height: var(--msv-body-line-height-m)
    );

    color: var(--msv-body-font-color);
}

// Style presets
:root {
    // Font
    --dobbies-icon-font-size-s: #{$dobbies-icon-size-small};
    --dobbies-icon-font-size-m: #{$dobbies-icon-size-medium};
    --dobbies-icon-font-size-l: #{$dobbies-icon-size-large};
    --dobbies-icon-font-size-xl: #{$dobbies-icon-size-xlarge};
    --dobbies-icon-font-rating: #{$dobbies-plp-rating};
    --dobbies-plp-rating-icon: #{$dobbies-plp-rating};
    --dobbies-breadcrumb-icon: #{$dobbies-breadcrumb-icon-size};

    // Headings
    --dobbies-heading-font-size-s: #{$dobbies-title-font-size-xs};
    --dobbies-heading-font-size-m: #{$dobbies-title-font-size-s};
    --dobbies-heading-font-size-l: #{$dobbies-title-font-size-m};
    --dobbies-heading-font-size-25: #{$dobbies-title-font-size-25};
    --dobbies-heading-font-size-30: #{$dobbies-title-font-size-30};
    --dobbies-heading-font-size-xl: #{$dobbies-title-font-size-l};
    --dobbies-title-font-size-40: #{$dobbies-title-font-size-40};
    --dobbies-heading-cart-banner: #{$dobbies-font-size-xxxl};

    // Body
    --dobbies-body-font-size-xs: #{$dobbies-font-size-xs};
    --dobbies-body-font-size-s: #{$dobbies-font-size-s};
    --dobbies-body-font-size-m: #{$dobbies-font-size-m};
    --dobbies-body-font-size-l: #{$dobbies-font-size-l};
    --dobbies-body-font-size-ml: #{$dobbies-font-size-ml};
    --dobbies-body-font-size-xml: #{$dobbies-title-font-size-25};
    --dobbies-body-font-size-xl: #{$dobbies-font-size-xl};
    --dobbies-body-font-size-xxl: #{$dobbies-font-size-xxl};
    --dobbies-tile-heading: #{$dobbies-tile-heading};
    --dobbies-tile-heading-s: #{$dobbies-tile-heading-s};

    // Weight
    --dobbies-font-weight-bold: #{$dobbies-font-weight-bold};
    --dobbies-font-weight-normal: #{$dobbies-font-weight-normal};
    --dobbies-font-weight-light: #{$dobbies-font-weight-light};
    --dobbies-font-weight-heavy: #{$dobbies-font-weight-heavy};
}
