$msv-details-text-margin-right: 5px;
$msv-details-order-information-padding-bottom: 8px;
$msv-details-order-information-padding-top: 38px;
$msv-details-order-information-spacing: 5px;
$msv-details-order-information-channel-name-margin-bottom: 12px;
$msv-details-order-information-channel-address-margin-bottom: 12px;
$msv-details-order-information-font-size: var(--dobbies-body-font-size-l);
$msv-details-order-information-text-bottom-space: 15px;
$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-details-group-padding-top: 20px;
$msv-details-group-delivery-width: 340px;
$msv-details-group-delivery-padding-bottom: 28px;
$msv-details-sales-status-top-desktop: -120px;
$msv-details-sales-status-top-mobile: -90px;
$msv-details-sales-lines-width: 770px;
$msv-details-sales-lines-margin-right: 120px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-padding: 10px 0;
$msv-details-sale-line-image-size: 147px;
$msv-details-sale-line-image-size-mobile: 80px;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-details-sale-line-buy-again-btn-left: 167px;
$msv-details-sale-line-buy-again-btn-bottom: 0px;
$msv-details-sale-line-buy-again-btn-padding-left: 33px;
$msv-details-sale-line-buy-again-btn-padding-right: 33px;
$msv-details-order-summary-line-earned-points-padding-top: 20px;
$msv-details-order-summary-payment-menthods-help-margin-top-mobile: 20px;
$msv-order-empty-image-width: 147px;
$msv-details-sales-line-padding-bottom-mobile: 0;
$msv-details-sales-line-btn-buy-it-again-margin-left-mobile: 100px;
$msv-details-order-summary-margin-bottom: 20px;
$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-font-weight: 600;
$msv-details-order-summary-line-spacing: 10px;
$msv-details-order-summary-line-total-amount-margin-top: 20px;
$msv-details-order-summary-line-total-amount-margin-bottom: 15px;
$msv-details-order-summary-line-earned-points-padding-top: 20px;
$msv-details-order-summary-label-price-font-size: var(--dobbies-body-font-size-ml);
$msv-details-button-margin-top: 12px;
$msv-details-help-margin-bottom: 28px;
$msv-details-help-heading-margin-bottom: 8px;
$msv-details-help-heading-margin-top: 20px;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 8px;
$msv-details-payment-methods-line-margin-bottom: 20px;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-details-footer-block-width: 768px;
$msv-details-footer-block-margin-right: 120px;
$msv-details-footer-block-padding-bottom: 20px;
$msv-details-help-width: 100%;
$msv-details-address-spacing: 20px;
$msv-msc-cart-line-width: 100%;
$msv-smaller-port-m: 320px;
$msv-order-details-order-summary-container-padding: 20px;
$msv-details-address-padding: 20px 0;
$msv-details-address-icon-padding-right: 5px;
$msc-order-detail-title-line-height: 32px;
$msc-order-detail-title-border-bottom-thickness: 1px;
$msc-order-detail-title-border-bottom-style: solid;
$msv-order-summary-padding: 25px;
$msv-order-summary-padding-mobile: 18px;
$msc-order-detail-cart-line-border-bottom-thickness: 1px;
$msc-order-detail-cart-line-border-bottom-style: solid;
$msc-order-detail-cart-line-padding-top: 10px;
$msc-order-detail-cart-line-padding-bottom: 35px;
$msc-order-detail-help-font-size: var(--dobbies-body-font-size-l);
$msc-order-detail-font-size-mbl: var(--dobbies-body-font-size-s);
$msc-order-prior-info-separator: var(--dobbies-font-primary-color);

//style presets
:root {
    --msv-order-details-border: #{$dobbies-gray-300};
    --msc-order-prior-info-separator: #{$msc-order-prior-info-separator};
    // heading
    --msv-order-details-heading-font-color: var(--dobbies-font-primary-color);
    --msv-order-details-heading-font-size: var(--msv-body-font-size-xl);

    // title
    --msv-order-details-title-font-color: var(--dobbies-heading-primary-color);
    --msv-order-details-title-font-size: var(--dobbies-body-font-size-l);
    --msv-order-details-title-weight-heavy: var(--dobbies-font-weight-heavy);
    --msv-order-details-title-line-height: #{$msc-order-detail-title-line-height};

    // text
    --msv-details-order-information-font-size: #{$msv-details-order-information-font-size};
    --msv-order-details-text-font-size: var(--msv-body-font-size-s);
    --msv-order-details-text-font-color: var(--dobbies-font-primary-color);

    // secondary button
    --msv-order-details-secondary-btn-bg: var(--dobbies-bg-color);
    --msv-order-details-secondary-btn-font-color: var(--dobbies-font-primary-color);
    --msv-order-details-secondary-btn-border: var(--dobbies-accent-brand-color);

    // Order Summary
    --msv-order-summary-padding: #{$msv-order-summary-padding};
    --msv-order-summary-padding-mobile: #{$msv-order-summary-padding};
    //title
    --msv-order-summary-title-font-size: var(--dobbies-heading-font-size-25);
    --msv-order-summary-title-font-size-mbl: var(--dobbies-body-font-size-ml);
    --msv-order-summary-title-font-color: var(--dobbies-font-primary-color);

    //label & price
    --msv-order-summary-label-weight-heavy: var(--dobbies-font-weight-heavy);
    --msv-details-order-summary-label-price-font-size: #{$msv-details-order-summary-label-price-font-size};

    // font-weight
    --msv-font-weight-normal: var(--dobbies-font-weight-normal);
    --msv-font-weight-bold: var(--dobbies-font-weight-bold);
    --msv-font-weight-heavy: var(--dobbies-font-weight-heavy);

    //help
    --msc-order-detail-help-font-size: #{$msc-order-detail-help-font-size};

    --msc-order-detail-help-font-size: #{$msc-order-detail-font-size-mbl};

    // mobile font size
    --msc-order-detail-font-size-mbl: #{$msc-order-detail-font-size-mbl};
}

.ms-order-details {
    margin-bottom: 30px;

    .msc-empty_image {
        width: 100%;
        height: 168px;

        @media screen and (max-width: $dobbies-container-max-width-xl) {
            height: 152px;
        }

        @media screen and (max-width: $dobbies-container-max-width-tablet) {
            height: 146px;
        }

        @media screen and (max-width: $dobbies-container-max-width-s) {
            height: 151px;
        }
    }

    &__heading {
        display: none;
    }

    &__groups {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .prior-info {
            @include font-content(var(--msv-font-weight-normal), var(--msv-details-order-information-font-size), $dobbies-line-height-m);
            color: var(--msv-order-details-text-font-color);
            @media screen and (min-width: $dobbies-container-max-width-m) and (max-width: $dobbies-container-max-width-xl) {
                font-size: var(--dobbies-body-font-size-m);
            }
            label {
                font-weight: var(--msv-font-weight-bold);
                margin-right: 5px;
            }
        }
    }

    &__order-information {
        //padding-bottom: $msv-details-order-information-padding-bottom;
        @include font-content(var(--msv-font-weight-normal), $dobbies-font-size-s, $dobbies-line-height-m);
        color: var(--msv-order-details-text-font-color);
        @media screen and (min-width: $dobbies-container-max-width-m) and (max-width: $dobbies-container-max-width-xl) {
            font-size: var(--dobbies-body-font-size-s);
        }
        &-channel-name {
            @include font-content(var(--msv-order-details-title-weight-heavy), var(--dobbies-body-font-size-m), 22px);
            color: var(--msv-order-details-text-font-color);
            display: block;
            margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
            padding-top: 0;
            padding-bottom: $msv-details-order-information-channel-name-margin-bottom;
            border-bottom: $msc-order-detail-title-border-bottom-thickness $msc-order-detail-title-border-bottom-style $dobbies-grey;
        }

        .prior-info {
            label {
                margin-bottom: 0;
                font-weight: var(--msv-font-weight-bold);
            }
            & > span:not(.prior-info__order-placed) {
                display: inline-block;
                margin-right: 5px;
                line-height: 16px;
                padding-left: 0;
                @media screen and (max-width: $msv-breakpoint-sm) {
                    width: 100%;
                    line-height: 24px;
                }
            }
            &__order-placed {
                margin-bottom: $msv-details-order-information-text-bottom-space;
                @media screen and (max-width: $msv-breakpoint-sm) {
                    display: block;
                    margin-bottom: 10px;
                }
                @media screen and (min-width: $dobbies-container-max-width-m) and (max-width: $dobbies-container-max-width-xl) {
                    display: inline-block;
                }

                @media screen and (max-width: $msv-breakpoint-sm) {
                    margin-bottom: 10px;
                }
            }
            &__order-count {
                margin-bottom: $msv-details-order-information-text-bottom-space;
                @media screen and (min-width: $dobbies-container-max-width-m) and (max-width: $dobbies-container-max-width-xl) {
                    display: block;
                }

                @media screen and (max-width: $msv-breakpoint-sm) {
                    margin-bottom: 10px;
                }
            }
            &__order-total {
                margin-bottom: $msv-details-order-information-text-bottom-space;
                padding-left: $msv-details-order-information-spacing;
                @media screen and (max-width: $msv-breakpoint-sm) {
                    margin-bottom: 10px;
                }
                .msc-price .msc-price__actual {
                    font-weight: var(--msv-font-weight-normal);
                    font-size: $dobbies-font-size-s;
                }
                @media screen and (min-width: $dobbies-container-max-width-m) {
                    display: block;
                    padding-left: 0;
                }
            }
            .order-information-sales-id {
                margin-bottom: 15px;
                @media screen and (max-width: $msv-breakpoint-sm) {
                    margin-bottom: 10px;
                }
            }
        }
        &-channel-address {
            display: none;
            font-size: $dobbies-heading-text-size;
            font-weight: var(--msv-font-weight-heavy);
            line-height: $dobbies-heading-line-height;
            margin-bottom: $msv-details-order-information-channel-address-margin-bottom;
            margin-top: $msv-details-order-information-channel-name-margin-bottom;
        }

        &-channel-reference-id,
        &-sales-id,
        &-receipt-id,
        &-receipt-email {
            display: block;
        }

        &-channel-reference-id {
            display: none;
        }
        &-sales-id {
            margin-bottom: $msv-details-order-information-text-bottom-space;
            @media screen and (max-width: $msv-breakpoint-sm) {
                margin-bottom: 10px;
            }
        }

        &-created-date {
            padding-right: $msv-details-order-information-spacing;
            padding-left: $msv-details-order-information-spacing;
            border-right: 1px solid var(--msc-order-prior-info-separator);
            display: inline-block;
            line-height: 16px;

            @media screen and (max-width: $msv-breakpoint-sm) {
                border-right: 0;
            }
        }

        &-count {
            display: inline-block;
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
            border-right: 1px solid var(--msc-order-prior-info-separator);

            @media screen and (max-width: $msv-breakpoint-sm) {
                border-right: 0;
                padding: 0;
            }
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }

        &-number {
            padding-left: $msv-details-order-information-spacing;
        }
    }

    &__btn-keep-shopping {
        margin-bottom: $msv-details-keep-shopping-btn-margin-bottom;
        @include secondary-button(
            var(--msv-order-details-secondary-btn-bg),
            var(--msv-order-details-secondary-btn-font-color),
            var(--msv-order-details-secondary-btn-border)
        );
    }

    &__sales-lines {
        flex: 4;

        &:first-child {
            border-top: none;
        }
    }

    &__sales-status {
        color: $dobbies-grey;
    }

    .ms-order-details-status {
        position: relative;
        bottom: 82px;
        left: 135px;
        span:not(.ms-order-details__sales-status) {
            font-weight: $dobbies-font-weight-bold;
        }

        @media screen and (max-width: $dobbies-container-max-width-xl) {
            bottom: 102px;
            left: 125px;
        }

        @media screen and (max-width: $dobbies-container-max-width-tablet) {
            bottom: 76px;
            left: 120px;
        }

        @media screen and (max-width: $dobbies-container-max-width-xl) and (min-width: $msv-container-max-width-m) {
            bottom: 100px;
        }

        @media screen and (max-width: $dobbies-container-max-width-s) {
            bottom: 86px;
            left: 125px;
        }
    }

    &__groups {
        @include font-content(var(--msv-font-weight-normal), var(--msv-order-details-text-font-size), $dobbies-line-height-m);
        //border-bottom: 1px solid var(--msv-order-details-border);

        .prior-info {
            &__delivery-address {
                font-size: $dobbies-font-size-s;
                display: flex;

                > span {
                    flex: 1;
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__group {
        flex-wrap: wrap;
        padding-top: $msv-details-group-padding-top;

        &-delivery {
            //padding-bottom: $msv-details-group-delivery-padding-bottom;
            padding-bottom: 0;
            padding-right: $msv-details-sale-line-spacing;

            &-heading {
                @include font-content(var(--msv-font-weight-bold), var(--msv-order-details-heading-font-size), $dobbies-line-height-xl);
                color: var(--msv-order-details-heading-font-color);
                margin-right: $msv-details-text-margin-right;
                display: none;
            }

            &-total-items {
                @include font-content(var(--msv-font-weight-normal), var(--msv-order-details-text-font-size), $dobbies-line-height-m);
                color: var(--msv-order-details-heading-font-color);
                margin-right: $msv-details-text-margin-right;
                display: none;
            }
        }

        &:first-child {
            border-top: none;

            .ms-order-details__sales-lines {
                border-top: none;
            }
        }
    }

    &__tracking-link {
        text-decoration: underline;
    }

    &__tracking-info {
        color: var(--msv-order-details-text-font-color);
        display: block;
        @include font-content(var(--msv-font-weight-normal), var(--dobbies-body-font-size-m), $dobbies-line-height-m);
        margin-top: 10px;
    }

    &__sales-line {
        padding: $msv-details-sale-line-padding;
        position: relative;
        border-top: 1px $msc-order-detail-cart-line-border-bottom-style $dobbies-grey;
        padding-bottom: 0;

        @media screen and (max-width: $dobbies-container-max-width-xl) and (min-width: $msv-container-max-width-m) {
            padding-bottom: 20px;
        }

        @media screen and (min-width: $dobbies-container-max-width-xl) {
            margin-bottom: -15px;
        }

        @media screen and (max-width: $dobbies-container-max-width-s) {
            margin-bottom: -15px;
            padding-bottom: 25px;
        }

        &:first-of-type {
            margin-top: 0px;
        }
        .msc-cart-line {
            width: $msv-msc-cart-line-width;
            padding-top: $msc-order-detail-cart-line-padding-top;
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                padding-top: 0px;
            }
            &__product {
                flex: none;
                padding-right: 0;
                font-weight: $dobbies-font-weight-bold;
                > label {
                    display: inline-block;
                    margin-right: 5px;
                    float: left;
                    position: relative;
                    top: 2px;
                }
                &-image {
                    width: 155px;
                    margin-right: 15px;
                    @media screen and (max-width: 1199px) {
                        width: 150px;
                    }
                    @media screen and (max-width: $dobbies-container-max-width-m) {
                        width: 125px;
                    }
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        max-width: 108px;
                        width: 100%;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

            &__content {
                display: flex;
                flex: auto;
                flex-direction: column;
                position: relative;
            }

            &__product-title {
                @include font-content(var(--msv-font-weight-normal), $dobbies-font-size-s, $dobbies-line-height-l);
                color: $dobbies-grey;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                margin: 0;
                margin-bottom: 5px;

                @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-container-max-width-m) {
                    width: 21.5vw;
                }

                @media screen and (max-width: $dobbies-container-max-width-xl) and (min-width: $dobbies-container-max-width-tablet) {
                    width: 17vw;
                }

                @media screen and (max-width: $dobbies-container-max-width-s) {
                    width: 84%;
                }

                @media screen and (max-width: 414px) {
                    width: 49vw;
                }
            }

            &__product-variants {
                color: var(--msv-order-details-text-font-color);
                font-size: var(--msv-order-details-text-font-size);
            }

            &__quantity {
                color: var(--msv-order-details-text-font-color);
                font-size: var(--msv-order-details-text-font-size);
                margin-left: 0;
                left: 0;
                bottom: 0;

                .quantity-label {
                    font-size: var(--dobbies-body-font-size-s);
                    line-height: $dobbies-text-line-height;
                    font-weight: $dobbies-font-weight-bold;
                    margin-bottom: 10px;
                }
                .quantity-value {
                    margin-left: $msv-details-text-margin-right;
                    display: inline;
                    font-weight: normal;
                }
            }
            // hide extra price component
            &__product-savings {
                display: none;
            }

            &__product-price {
                float: right;
                display: block;
                position: relative;
                top: 46px;

                @media screen and (max-width: $dobbies-container-max-width-xl) and (min-width: $msv-container-max-width-m) {
                    top: 90px;
                }

                @media screen and (max-width: $dobbies-container-max-width-m) {
                    top: 52px;
                }

                @media screen and (max-width: $dobbies-container-max-width-s) {
                    top: 85px;
                }

                .msc-price__actual {
                    color: var(--msv-order-details-text-font-color);
                    @include font-content(var(--dobbies-font-weight-bold), 14px, $dobbies-line-height-l);
                }
                .msc-price {
                    &__salePrice {
                        .msc-price__actual {
                            margin-right: 0;
                            color: $dobbies-sale-price;
                            font-size: 14px;
                        }
                    }

                    &.discount-value {
                        .msc-price__strikethrough {
                            font-size: 14px;
                            margin: 0;
                            margin-left: 5px;
                        }
                    }
                }
            }

            &-item-product-discount {
                display: none;
            }
        }

        .msc-cart-line ~ span {
            position: absolute;
            top: 35px;
            right: 0;
            font-size: var(--dobbies-body-font-size-ml);
            font-weight: var(--dobbies-font-weight-bold);
        }

        &-btn-buy-it-again {
            display: block !important;
            //  left: $msv-details-sale-line-buy-again-btn-left;
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            position: relative;
            bottom: $msv-details-sale-line-buy-again-btn-bottom;
            width: $msv-details-sale-line-buy-again-btn-width;
            @include secondary-button(
                $bg-color: var(--msv-order-details-secondary-btn-bg),
                $color: var(--msv-order-details-secondary-btn-font-color),
                $border-color: var(--msv-order-details-secondary-btn-border)
            );

            &.msc-btn {
                padding-left: $msv-details-sale-line-buy-again-btn-padding-left;
                padding-right: $msv-details-sale-line-buy-again-btn-padding-right;
                display: none;
            }
        }
    }

    &__order-summary {
        @include font-content(
            var(--msv-font-weight-normal),
            var(--dobbies-body-font-size-l),
            $dobbies-line-height-l
        );
        flex: 4;
        background: $dobbies-header-grey;
        padding: 15px;
        padding-bottom: 0;
        border: 1px solid $dobbies-gray-100;
        border-bottom: 0;
        @media screen and (max-width: $dobbies-container-max-width-l) {
            font-size: var(--dobbies-body-font-size-l);
        }
        @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
            margin-top: 15px;
        }

        &-heading {
            @include font-content($msv-font-weight-500, 20px, $dobbies-line-height-l);
            color: var(--dobbies-font-primary-color);
            display: block;
            text-align: center;
            margin-bottom: $msv-details-order-summary-margin-bottom;
            text-transform: uppercase;
            @media screen and (max-width: $dobbies-container-max-width-l) {
                font-size: var(--dobbies-body-font-size-ml);
            }
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: $msv-details-order-summary-line-spacing;
            padding-top: $msv-details-order-summary-line-spacing;
        }
        &-line-subtotal {
            padding-top: 0;
            margin-bottom: 30px;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                margin-bottom: 38px;
            }
        }

        &-line-total-amount {
            //margin-bottom: $msv-details-order-summary-line-spacing;
            // margin-top: $msv-details-order-summary-line-total-amount-margin-top;
            padding-top: $msv-details-order-summary-line-spacing;
            padding-bottom: $msv-details-order-summary-line-spacing;
            border-bottom: 1px $msc-order-detail-cart-line-border-bottom-style $dobbies-bombay-grey;
            border-top: 1px $msc-order-detail-cart-line-border-bottom-style $dobbies-bombay-grey;
            margin-top: 30px;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                margin-top: 20px;
            }
            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                padding: 15px 0;
            }
            @media screen and (max-width: $msv-breakpoint-sm) {
                padding: 10px 0;
            }
            .msc-price__actual {
                font-size: var(--msv-body-font-size-xl);
                line-height: $dobbies-line-height-xl;
                font-weight: $msv-font-weight-500;
            }

            span,
            .ms-order-details__order-summary-price.msc-price .msc-price__actual {
                font-weight: bold;
            }
        }

        &-line-earned-points {
            border-top: 1px solid $dobbies-divider-color;
            padding-top: $msv-details-order-summary-line-earned-points-padding-top;

            .ms-order-details__order-summary-label {
                font-weight: var(--msv-order-summary-label-weight-normal);
            }
        }

        &-label {
            font-weight: var(--dobbies-font-weight-normal);
            font-size: 16px;
        }

        &-price {
            float: right;
            font-weight: var(--msv-font-weight-bold);

            &.msc-price {
                .msc-price__actual {
                    @include font-content(var(--msv-font-weight-normal), 16px, $dobbies-line-height-l);
                    @media screen and (max-width: $dobbies-container-max-width-l) {
                        font-size: var(--dobbies-body-font-size-l);
                    }
                }
            }
        }

        &-line-home-delivery {
            .ms-order-details__order-summary-price {
                font-weight: var(--msv-font-weight-normal);
                font-size: 16px;
            }
        }
    }

    &__help {
        @include font-content(var(--msv-font-weight-normal), var(--msc-order-detail-help-font-size), $dobbies-line-height-m);
        color: var(--msv-order-details-text-font-color);
        float: left;
        margin-bottom: $msv-details-help-margin-bottom;
        width: $msv-details-help-width;

        @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
            padding-left: 20px;
            margin-top: 15px;
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            @include font-content(var(--msv-font-weight-normal), var(--msc-order-detail-font-size-mbl), $dobbies-line-height-s);
        }

        &-title {
            font-weight: var(--msv-font-weight-heavy);
            margin-top: $msv-details-help-heading-margin-top;
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-label:after {
            content: ' ';
        }

        &-content-number {
            @include vfi();
            text-decoration: underline;
            display: inline-block;
            margin-right: 5px;
        }

        .ms-text-block p a {
            font-weight: var(--dobbies-font-weight-heavy);
        }
    }

    &__order-summary-container {
        color: var(--msv-order-details-text-font-color);
        padding: $msv-order-details-order-summary-container-padding;
        background: var(--dobbies-gray-cart-banner);
    }

    &__payment-methods {
        @include font-content(var(--msv-font-weight-heavy), var(--msv-order-details-title-font-size), $dobbies-line-height-l);
        color: var(--msv-order-details-text-font-color);
        display: flex;
        padding: 15px;
        background: $dobbies-header-grey;
        border: 1px solid $dobbies-gray-100;
        border-top: 0;

        @media screen and (max-width: $msv-breakpoint-m) {
            @include font-content(var(--msv-font-weight-heavy), var(--msc-order-detail-font-size-mbl), $dobbies-line-height-s);
        }

        &-title {
            @include font-content(var(--msv-font-weight-normal), 16px, $dobbies-line-height-l);
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
        }

        &-line {
            @include font-content(var(--msv-font-weight-normal), 16px, $dobbies-line-height-l);
            margin-left: 5px;
            line-height: $dobbies-line-height-m;
            text-align: right;
            flex: 1;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                font-size: var(--dobbies-body-font-size-m);
            }
        }
        .msc-price {
            .msc-price__actual {
                font-weight: normal;
            }
        }

        &-loyalty-amount:before,
        &-cash-amount:before,
        &-gift-card-amount:before,
        &-customer-account-amount:before {
            content: ' ';
        }
    }

    &__address {
        color: var(--msv-order-details-text-font-color);
        display: block;
        padding: $msv-details-address-padding;
        flex: 1;
        display: none;

        &-header {
            font-weight: var(--dobbies-font-weight-heavy);
        }

        &-name,
        &-phone {
            display: block;
            &-label {
                display: none;
            }

            &-icon {
                padding-right: $msv-details-address-icon-padding-right;
                @include add-icon($msv-Phone);
            }
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

    .msc-empty_image {
        @include image-placeholder-rectangle(73px, 102px);
        max-width: 100%;
        @media screen and (max-width: 1599px) {
            @include image-placeholder-rectangle(51px, 71px);
        }
        @media screen and (max-width: 1199px) {
            @include image-placeholder-rectangle(71px, 98px);
        }
        @media screen and (max-width: $dobbies-container-max-width-m) {
            @include image-placeholder-rectangle(92px, 128px);
        }
        @media screen and (max-width: $msv-container-max-width-s) {
            @include image-placeholder-rectangle(70px, 98px);
        }
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        .ms-order-details__products {
            padding-right: $msv-order-details-order-summary-container-padding;
        }

        &__order-summary-container {
            display: flex;
            justify-content: space-between;
        }

        &__sales-lines_container {
            display: flex;
            justify-content: space-between;
        }
    }

    @media screen and (max-width: $msv-breakpoint-l) {
        &__sales-lines {
            width: 100%;
        }

        &__sales-line .msc-cart-line {
            width: 100%;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__group {
            display: block;

            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: 100%;
        }

        &__sales-status {
            top: $msv-details-sales-status-top-mobile;
        }

        &__order-summary,
        &__payment-methods,
        &__help {
            float: none;
            margin-top: $msv-details-order-summary-payment-menthods-help-margin-top-mobile;
            width: 100%;
            @include font-content(var(--msv-font-weight-heavy), var(--msc-order-detail-font-size-mbl), $dobbies-line-height-s);

            &-price.msc-price .msc-price__actual {
                @include font-content(var(--msv-font-weight-normal), var(--msc-order-detail-font-size-mbl), $dobbies-line-height-s);
            }
        }
        &__order-summary {
            margin-top: 0;
            &-heading {
                font-size: var(--msv-order-summary-title-font-size-mbl);
            }
        }
        &__help {
            font-weight: var(--msv-font-weight-normal);
        }
        &__payment-methods {
            margin-top: 0;

            &-title {
                @include font-content(var(--msv-font-weight-normal), $dobbies-font-size-m, $dobbies-line-height-s);
                margin-bottom: 0;
                font-size: $dobbies-font-size-m !important;
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    font-size: var(--dobbies-body-font-size-l);
                }
            }
            &-line {
                @include font-content(var(--msv-font-weight-normal), $dobbies-font-size-m, $dobbies-line-height-s);
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    font-size: $dobbies-font-size-m;
                }
            }
        }
        &__btn-keep-shopping {
            width: 100%;
        }

        &__sales-line {
            &-btn-buy-it-again {
                display: none;
                left: 0px;
                margin-left: $msv-details-sales-line-btn-buy-it-again-margin-left-mobile;
                top: 0px;
                width: auto;
            }
        }

        .msc-cart-line {
            width: 100%;
        }

        &__order-information {
            font-size: var(--dobbies-body-font-size-s);
            &-channel-name {
                padding-top: 0px;
            }
            .msc-price .msc-price__actual {
                font-size: var(--dobbies-body-font-size-s);
            }
        }
        &__tracking-info {
            font-size: var(--dobbies-body-font-size-s);
        }
        .col-xs-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    
    @media screen and (max-width: $msv-breakpoint-sm) {
        &__order-information {
            border-right: 0;
        }
    }

    @media screen and (max-width: $msv-breakpoint-mbl) {
        .ms-order-details__sales-status {
            top: 70px;
        }

        &__order-information {
            &-channel-name {
                padding-top: 0px;
            }
            &-created-date {
                border-right: 0;
            }
            &-count {
                border-right: 0;
                padding-left: 0;
            }
            .msc-price .msc-price__actual {
                font-size: var(--dobbies-body-font-size-s);
            }
        }

        .prior-info {
            & > span {
                display: block;
            }
            &__order-total {
                padding-left: 0;
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        .row > .col-lg-7 {
            padding-bottom: 20px;
        }
    }
}
.default-container-2-slots .ms-order-details__help .ms-text-block {
    border-bottom: 0;
    display: inline-block;
    margin-bottom: 0;
    padding-bottom: 0;
    > div {
        display: inline-block;
    }
}

.ms-order-details {
    &__products {
        .ms-order-history__btn-order-details,
        .msc-add-to-cart {
            width: 100%;
            min-width: 100%;
            padding: 0;
            height: 30px;
            font-size: 16px;
            border-radius: 5px;
        }
    }
}

.order-details-btns-group {
    border-top: 1px solid $dobbies-grey;
    padding-top: 20px;
}

// .ms-order-details__sales-line-btn-buy-it-again-link {

// }
.ms-order-details__sales-line-btn-buy-it-again-link {
    @include primary-button();
    @include vfi();
    display: flex;
    min-width: 220px;
    align-content: center;
    justify-content: center;
    border-radius: 6px;
    transform: perspective(1px) translateZ(0);
    position: absolute;
    transition-duration: 0.3s;
    overflow: hidden;
    border: 0;
    height: 30px;
    padding: 5px 20px;
    font-size: 16px;
    line-height: 18px;
    margin-top: 15px;
    border: 1px solid transparent;
    left: 135px;
    bottom: 30px;

    @media screen and (max-width: $dobbies-container-max-width-xl) {
        bottom: 30px;
        left: 125px;
    }

    @media screen and (max-width: $dobbies-container-max-width-tablet) {
        left: 120px;
        bottom: 5px;
    }

    @media screen and (max-width: $dobbies-container-max-width-xl) and (min-width: $msv-container-max-width-m) {
        bottom: 35px;
    }

    @media screen and (max-width: $dobbies-container-max-width-s) {
        bottom: 35px;
        left: 125px;
        margin: 0;
    }

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-secondary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
        color: var(--dobbies-font-primary-color);
        background: var(--dobbies-bg-color-primary);
        border: 1px solid $dobbies-grey;
        &:after {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}

.msc-cart-line__product {
    > label {
        display: none;
    }
}

.ms-order-details {
    .msc-cart-line {
        .msc-empty_image {
            width: 100%;
            height: 168px;

            @media screen and (max-width: $dobbies-container-max-width-xl) {
                height: 152px;
            }

            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                height: 146px;
            }

            @media screen and (max-width: $dobbies-container-max-width-s) {
                height: 151px;
            }
        }
    }

    &__order-summary-price {
        &.msc-price {
            .msc-price__actual {
                @media screen and (max-width: $dobbies-container-max-width-tablet) {
                    font-size: 16px;
                }
            }
        }
    }
}

.guest-order-lookup {
    .order-lookup-main-heading {
        margin-top: 20px;
        background-color: $dobbies-header-grey;
        .ms-text-block {
            padding-left: 20px;
            padding-right: 20px;
            margin: 0 auto;
          
            @media screen and (min-width: $msv-breakpoint-s) {
              max-width: $msv-breakpoint-xl;
              width: 100%;
            }

            > div > * {
                text-transform: uppercase;
                padding: 20px 0;
                font-size: 24px;
            }
        }
    }
    .guest-order-details-form {
        padding-top: 38px;
        padding-bottom: 40px;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            padding-top: 62px;
        }
        .row {
            > .col-auto {
                @media screen and (max-width: $dobbies-container-max-width-tablet) {
                    width: 100%;
                }
            }
        }
    }

    .ms-order-lookup {
        &__details {
            &__heading {
                display: none;
            }
            &__form {
                text-align: left;
                margin-bottom: 20px;
                &__fields {
                    margin-bottom: 11px;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        margin-bottom: 17px;
                    }
                    &:last-of-type {
                        margin: 0;
                    }
                    &__order-number-label,
                    &__email-label {
                        font-size: 14px;
                        margin-bottom: 1px;
                        @media screen and (min-width: $dobbies-container-max-width-s) {
                            margin-bottom: 8px;
                        }
                    }
                    input {
                        height: 46px;
                        width: 100%;
                        padding: 15px 10px;
                        @media screen and (min-width: $dobbies-container-max-width-s) {
                            height: 60px;
                            padding: 20px 26px;
                        }
                        @media screen and (min-width: $dobbies-container-max-width-tablet) {
                            width: 80%;
                        }
                    }
                }

                &__submit {
                    color: $dobbies-white;
                    background: $dobbies-green;
                    width: 180px;
                    height: 30px;
                    padding: 0;
                    font-size: 14px;
                    font-weight: $dobbies-font-weight-bold;
                    line-height: normal;
                    border: none;
                    border-radius: 3px;
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    margin-top: 20px;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        width: 200px;
                        height: 46px;
                        font-size: 18px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: var(--dobbies-bg-color-secondary);
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }

        &__registered-user {
            display: none;
        }
    }
}

.normal-order-details {
    .ms-order-details-status {
        bottom: 105px;
        left: 160px;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            bottom: 48px;
            left: 114px;
        }
    }
    .ms-order-details__sales-line-btn-buy-it-again-link {
        left: 160px;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            left: 114px;
            bottom: 3px;
        
        }
    }

    .ms-order-details__sales-line {
        @media screen and (max-width: $dobbies-container-max-width-s){
            padding-bottom: 35px;
        }
    }
}

.guest-order-details,
.normal-order-details {
    padding-top: 0;
    @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-s) {
        margin-bottom: 20px;
    }

    .prior-info {
        &.d-flex {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: $dobbies-container-max-width-s){
                flex-direction: column;
            }
        } 
        &__count-total {
            display: flex;
            font-size: $dobbies-font-size-s;
            font-weight: bold;
            align-items: center;
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                padding-right: 25%;
            }
            .prior-info__order-total {
                margin: 0;
            }

            .ms-order-details {
                &__order-information-count {
                    line-height: 14px;
                    border-width: 2px;
                    margin-right: 5px;
                }
            }
        }
    }
    .ms-order-details__order-information {
        margin-bottom: 30px;
        &-created-date {
            border: none;
            padding-right: 0;
        }
        &-number {
            font-weight: bold;
        }
        .prior-info {
            label {
                font-weight: bold;
            }
            .order-information-sales-id {
                margin-bottom: 22.5px;
                font-size: $dobbies-font-size-m;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    margin-bottom: 25px;
                }
            }
            &__order-placed {
                margin-bottom: 22.5px;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    margin-bottom: 0;
                }
            }
            &__order-total {
                .msc-price {
                    .msc-price__actual {
                        font-weight: bold;
                    }
                }
            }
            &__order-count {
                margin: 0;
            }
        }
    }

    .order-delivery__wrap {
        margin-bottom: 25px;
        h5 {
            font-weight: bold;
        }
        h5,
        p   {
            font-size: $dobbies-font-size-s;
        }
    }

    .delivery-dispatched-details {
        padding: 18px 10px;
        display: flex;
        justify-content: space-between;
        background-color: $dobbies-header-grey;

        &__count {
            span {
                font-size: $dobbies-font-size-s;
                text-transform: uppercase;
                font-weight: $dobbies-font-weight-bold;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    font-size: $dobbies-font-size-m;
                }
            }
        }

        &__right {
            * {
                font-size: $dobbies-font-size-xs;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    font-size: $dobbies-font-size-s;
                }
            }
        }
        &::after {
            display: none;
        }
    }
    .need-help-block {
        margin-top: 20px;
        @media screen and (max-width: $dobbies-container-max-width-tablet) {
            text-align: center;
        }
        h3 {
          font-size: $dobbies-font-size-s;
        }
        p {
            font-size: $dobbies-font-size-s;
            margin: 0;
            margin-top: 5px;
          a {
            text-decoration: underline;
          }
        }
    }
    .credit-availability-banner {
        padding: 0;
        margin-top: 20px;
    }
    .ms-order-details__sales-line {
        border-color: $dobbies-gray-100;

        &:first-child {
            padding-top: 20px;
            border: none;
            margin-top: 0px;
        }

        .msc-cart-line {
            &__product {
                > label {
                    display: none;
                }
                > a {
                    font-size: $dobbies-font-size-s;
                    font-weight: $dobbies-font-weight-bold;
                    margin-bottom: 0;
                    & ~ .msc-cart-line__product-price {
                            top: 20px;
                    }
                    &[href] {
                        margin-bottom: 5px;

                        & ~ .msc-cart-line__product-price {
                            top: 0;
                        }
                    }

                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        font-size: $dobbies-font-size-ml;
                    }
                }
                &-price {
                    top: 0px;
                    float: left;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        position: absolute;
                        top: 0px;
                        right: 10px;
                    }
                    .msc-price.discount-value {
                        * {
                            font-size: $dobbies-font-size-s;
                            font-weight: bold;
                            @media screen and (min-width: $dobbies-container-max-width-s) {
                                font-size: $dobbies-font-size-ml;
                            }
                        }
                    }
                }

                &-image {
                    min-width: 88.5px;
                    max-width: 88.5px;
                    margin-right: 25px;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        min-width: 139px;
                        max-width: 139px;
                        margin-right: 20px;
                    }
                    .msc-empty_image {
                        height: 124px;
                        @media screen and (min-width: $dobbies-container-max-width-s) {
                            height: 194px;
                        }
                    }
                }
            }

            &__quantity {
                width: 100%;
                .quantity-label,
                .quantity-value {
                    font-size: $dobbies-font-size-s;
                    font-weight: normal;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        font-size: $dobbies-font-size-l;
                    }
                }
            }
        }
    }

    .ms-order-details {
        margin-bottom: 10px;
    }
} 

.guest-order-details {
    .heading {
        display: none;
    }
    .default-container {
        padding: 0;
    }
    .ms-order-details__sales-line {
        margin-top: 20px;
        .msc-cart-line {
            &__product {
                > label {
                    display: none;
                }
                > a {
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        width: 60vw;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        width: 30vw;
                    }
                }
            }
        }
    }

}