$footer-ms-footer-padding-xl: 20px 0 0;
$footer-ms-footer-margin-left-mobile: 0;
$footer-category-max-width: 200px;
$footer-category-padding-top: 19px;
$footer-category-padding-bottom: 48px;
$footer-item-padding-desktop: 0 0 6px;
$footer-item-padding-right-mobile: 28px;
$footer-heading-title-line-height: 24px;
$footer-heading-title-margin-bottom: 0.5rem;
$footer-list-item-icon-left: 10px;
$footer-list-item-icon-number: 3;
$footer-list-item-icon-top: 40px;
$footer-row-padding-top-mobile: 32px;
$footer-back-to-top-line-height: 24px;
$footer-back-to-top-height: 48px;
$footer-back-to-top-width: 48px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 24px;
$footer-back-to-top-border-radius: 50%;
$social-icon-height: 28px;
$social-icon-height-mobile: 22px;
$footer-title-spacing-mobile: 9px 10px;
$footer-container-margin-negative: 0 -20px;
$footer-container-inside-spacing: 0 20px;
$footer-container-laft-space: 12px;
$footer-category-top-spacing-desktop: 10px;
$footer-category-top-spacing-mobile: 0px;

:root {
    --msv-footer-bg: var(--dobbies-bg-color);
    // Headings 
    --msv-footer-heading-font-color: var(--dobbies-font-primary-color);
    --msv-footer-heading-font-size: var(--dobbies-body-font-size-m);

    //Links
    --msv-footer-link-font-color: var(--dobbies-font-primary-color);
    --msv-footer-link-font-size: var(--dobbies-body-font-size-m);
}

footer{
    background-color: var(--dobbies-header-grey);
    position: relative;
    overflow: hidden;

    .ms-footer {
        &__list{
            flex: initial;
            width: auto;
            margin: $footer-container-margin-negative;
            padding-left: $footer-container-laft-space;
        }

        .ms-back-to-top {
            @include vfi();
            background: var(--dobbies-header-grey);
            border: 1px solid var(--dobbies-font-primary-color);
            border-radius: $footer-back-to-top-border-radius;
            bottom: $footer-back-to-top-bottom;
            cursor: pointer;
            display: none;
            height: $footer-back-to-top-height;
            min-width: auto;
            opacity: 0;
            padding: 0;
            position: fixed;
            right: $footer-back-to-top-right;
            text-align: center;
            vertical-align: middle;
            width: $footer-back-to-top-width;

            .fa-arrow-up {
                color: var(--dobbies-font-primary-color);
                font-size: var(--msv-body-font-size-xl);
                left: 50%;
                line-height: 0;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                &:before {
                    @include dobbies-icon();
                    content: $dobbies-icon-arrow;
                    font-size: $dobbies-font-size-m;
                    margin-left: 1px;
                }
            }
            &:hover {
                background: var(--dobbies-bg-color-primary);
                .fa-arrow-up {
                    color: var(--dobbies-bg-color)
                }
            }
        }

        &__item {
            padding: $footer-item-padding-desktop;
        }

        &__heading__title {
            color: var(--dobbies-footer-font-color);
            font-size: var(--msv-footer-heading-font-size);
            font-weight: $dobbies-font-weight-bold;
            line-height: $dobbies-line-height-l;
        }
    
        &__link__text {
            color:var(--msv-footer-link-font-color);
            font-size: var(--msv-footer-link-font-size);
            font-weight: var(--msv-font-weight-normal);
            line-height: $dobbies-line-height-m;
        }

        &__link{ 
            @include vfi();
            font-size: $dobbies-font-size-s;
            display: inline-flex;
            color: var(--dobbies-footer-font-color);
            img {
                @include vfi();
                height: $social-icon-height;
                width: auto;
                margin: 0 5px;
                @media (max-width: $dobbies-container-max-width-s) {
                    height: $social-icon-height-mobile;
                    margin: 10px 0 0;
                }
            }
        }

        & > * {

            > * {
                max-width: 25%;
                &:last-child,
                &:nth-child(2), 
                &:first-child {
                    max-width: 100%;
                }
                &:nth-child(7) {
                    max-width: 100%;
                    text-align: center;
                    padding-top: 20px;
                    @media(max-width: $msv-breakpoint-m) {
                        padding-top: 0;
                    }
                    ul {
                        padding: 0;
                        li {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .ms-dobbies-footer-newsletter{
        text-align: center;
        max-width: 400px;
        margin: 5px auto 30px;
        &__heading {
            font-size: var(--dobbies-body-font-size-xl);
            color: var(--dobbies-footer-font-color);
            font-weight: var(--dobbies-font-weight-bold);
            text-transform: uppercase;
            margin: 10px 0 15px;
        }
        &__content {
            padding: 15px;
            border: 1px solid $dobbies-dark-grey;
            margin-top: 30px;
            @media (max-width: $msv-breakpoint-m) {
                margin-top: 0;
            }
        }
        &__description{
            margin-bottom: 10px;
        }
        h4 {
            color: var(--dobbies-heading-primary-color);
            line-height: 1.5;
            font-size: var(--dobbies-body-font-size-m);
            font-weight: var(--dobbies-font-weight-bold);
        }
        .msc-btn {
            height: auto;
            padding: 4px 20px;
            font-size: var(--dobbies-body-font-size-m);
            font-weight: var(--dobbies-font-weight-bold);
            color: var(--dobbies-footer-font-color);
            background-color: transparent;
            text-decoration: underline;
        }
    }

    .ms-dobbies-footer-copyrights{
        padding: 20px 16px;
        @media(min-width: $dobbies-container-max-width-xl) {
            padding: 20px 0px;
        }

        .copyright-text {
            small {
                > div {
                    @media(min-width: $dobbies-container-max-width-xl) {
                        max-width: 1550px;
                        margin: 0 auto;
                        padding-left: 3%;
                    }
                    
                    > div {
                        display: flex;
                        @media(max-width: $dobbies-container-max-width-xl) {
                            flex-wrap: wrap;
                            > div {
                                &:first-child {
                                    order: 1;
                                }
                            }
                        }
                        @media(min-width: $dobbies-container-max-width-tablet) {
                            justify-content: center;
                        }
                        @media(min-width: $dobbies-container-max-width-xl) {
                            justify-content: initial;
                        }
                        p {
                            font-size: $dobbies-font-size-s;
                            @media(min-width: $dobbies-container-max-width-m) {
                                font-size: $dobbies-font-size-xs;
                            }
                            @media(max-width: 395px) {
                                font-weight: 13px;
                            }
                        }
                        @media(max-width: $dobbies-container-max-width-tablet) {
                            justify-content: center;
                        }
                        ul {
                            list-style: none;
                            margin-bottom: 0;
                            padding: 0;
                            display: none;
                            @media (min-width: $dobbies-container-max-width-m) {
                                display: block;
                                margin-bottom: 16.5px;
                            }
                            @media(min-width: $dobbies-container-max-width-xl) {
                                padding: 0;
                                position: relative;
                                left: 115px;
                                margin-bottom: 0;
                            }

                            li {
                                display: inline-block;
                                padding-right: 11.5px;
                                margin-right: 8px;
                                border-right: 1px solid $dobbies-black;

                                a {
                                    @media (min-width: $dobbies-container-max-width-m) {
                                        font-size: 11px;
                                    }
                                    @media (min-width: $dobbies-container-max-width-tablet) {
                                        font-size: $dobbies-font-size-xs;
                                    }
                                }

                                &:last-child {
                                    border-right: 0;
                                }
                                &:last-child {
                                    padding-right: 0;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
            
            .dobbies-footor-logo {
                width: 100%;
                text-align: center;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                margin-top: 25px;
                @media (min-width: $dobbies-container-max-width-m) {
                    position: absolute;
                    right: 33px;
                    bottom: 120px;
                    margin: 0;
                    display: inline-block;
                    width: auto;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    bottom: 100px;
                }

                img {
                    &.logo-green {
                        @media (min-width: $dobbies-container-max-width-m) {
                            display: none;
                        }
                    }
                    &.logo-white {
                        display: none;
                        @media (min-width: $dobbies-container-max-width-m) {
                            display: block;
                        }
                    }
                    width: 175px;
                    @media (max-width: $dobbies-container-max-width-m) {
                        width: 140px;
                    }
                }
            }
        }
    }

    @media (max-width: $dobbies-container-max-width-m) {
        .ms-footer {
            margin-left: $footer-ms-footer-margin-left-mobile;
            &__list-item{
                position: relative;
                &:after{
                    top: 12px;
                    right: 11px;
                    position: absolute;
                    @include dobbies-icon();
                    color: var(--dobbies-font-primary-color);
                    font-size: var(--dobbies-icon-font-size-s);
                    font-weight: var(--dobbies-font-weight-bold);
                }
                &.hide:after{
                    content: $dobbies-icon-arrow;
                    transform: rotate(180deg);
                }
                &.Show:after{
                    content: $dobbies-icon-arrow;
                    transform: rotate(0deg);
                }
            }
            &__item {
                position: relative;
                padding: $footer-title-spacing-mobile;
            }
            &__collapse {
                &.collapse.show {
                    height: fit-content !important;
                }
            }
            > * {
                > * {
                    max-width: 100%;
                    &:last-child,
                    &:nth-last-child(2) {
                        .ms-footer {
                            &__list {
                                border: 0;
                                .slide-down {
                                    margin-bottom: 20px;
                                }
                                &-item {
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .ms-dobbies-footer-newsletter{
            margin-bottom: 15px;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .ms-footer {
            .ms-back-to-top[aria-hidden="false"] {
                display: block;
                opacity: 1;
                transform: translateX(0%);
                z-index: 9;
                // position: absolute;
            }
            &__list {
                margin: 0;
                border-top: 0;
                padding-left: 0;
            }
        }
    }
    .ms-container-outer {
        max-width: 100%;
        padding: 0;

        ul.ms-footer-links-mobile {
            @media (min-width: $dobbies-container-max-width-m) {
                display: none;
            }

            @media (max-width: $msv-container-max-width-m) {
                padding: 0 106px 0 97px;
                margin: 0 auto;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                padding: 0px 16px;
            }

            > li {
                border-bottom: 1px solid $dobbies-grey;
                padding: 20px 0;
            }

            p {
                margin: 0;
                font-size: $dobbies-font-size-ml;
                text-transform: uppercase;
            }

            .slide-down {
                .ms-footer__item {
                    padding-left: 0;
                }
            }
            .slide-up {
                display: none;
            }

            li.ms-footer-links-mobile {
                & ~ div {
                    margin-top: 10px;
                }
            }

            .ms-footer__list-item:after {
                top: 25px;
                right: 9px;
                @media (min-width: $dobbies-container-max-width-s) {
                    right: 0px;
                }
            }
        }
    }

    .ms-footer {
        &__list-item{
            &:after{
                @media (max-width: $dobbies-container-max-width-s) {
                    right: 9px;
                }
            }
        }
    }
    // custom class
    .ms-footer-redesign {
        max-width: 100%;
        padding: 0;

        // custom class
        .ms-footer-body {
            background-color: $dobbies-dark-grey;
            max-width: 100%;
            padding: 40px 16px;
            @media (min-width: $dobbies-container-max-width-s) {
                padding: 40px 106px 37px 97px;
            }

            @media (min-width: $dobbies-container-max-width-xl) {
                padding: 40px 0 37px;
            }

            .row {
                > .col-12 {
                    position: initial;
                    // custom class
                    .back-to-top-container {
                        position: initial;

                        &::after {
                            display: none;
                        }
                    }
                }
            }

            &-text-content {
                .col-12 {
                    width: auto;
                    max-width: none;
                    @media (min-width: $dobbies-container-max-width-m) {
                        max-width: 25%;
                        width: 25%;
                    }
                }
            }

            .ms-footer__heading__title {
                margin-bottom: 20px;
                text-transform: uppercase;
            }

            .footer-app-text,
            .ms-footer__heading__title {
                color: $dobbies-white;
            }

            .ms-footer__list-item {

                @media (max-width: $dobbies-container-max-width-tablet) { 
                    position: relative;
                    &:after{
                        top: 5px;
                        right: 0px;
                        position: absolute;
                        @include dobbies-icon();
                        color: $dobbies-white;
                        font-size: var(--dobbies-icon-font-size-s);
                        font-weight: var(--dobbies-font-weight-bold);
                    }
                    &.hide:after{
                        content: $dobbies-icon-arrow;
                        transform: rotate(180deg);
                    }
                    &.Show {
                        .ms-footer__heading__title {
                            margin-bottom: 10px;
                        }
                        .ms-text-block, .ms-content-block__text {
                            p {
                                margin-bottom: 10px;
                            }
                        }
                        &:after{
                            content: $dobbies-icon-arrow;
                            transform: rotate(0deg);
                        }
                    }
                }
                @media (max-width: $dobbies-container-max-width-m) { 
                    &:after{
                        right: 10px;
                    }
                }

                .ms-footer__list {
                    display: flex;
                    align-items: center;
                }

                .ms-footer__item {
                    padding: 0;
                    .ms-footer__link {
                        color: $dobbies-white;
                        line-height: 24px;
                    }
                }
            }

            .ms-footer__list {
                .slide-down,
                .slide-up {
                    transition: none;
                }
            }

            // custom class
            .social-media-inner-container {
                border-top: 1px solid $dobbies-white;
                padding-top: 20px;
                margin-top: 20px;

                @media (min-width: $dobbies-container-max-width-m) {
                    border-right: 1px solid $dobbies-white;
                    margin-right: 150px;
                    padding-top: 0px;
                    margin-top: 0px;
                    border-top: none;
                }
                @media (max-width: $dobbies-container-max-width-tablet) and (min-width: $dobbies-container-max-width-m) {
                    margin-right: 18%;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    margin-right: 50px;
                    padding-right: 64px;
                }
                .footer-app-buttons {
                    display: block;
                    p {
                        margin-bottom: 0;
                        @media (max-width: $dobbies-container-max-width-s) {
                            display: flex;
                            justify-content: space-between;

                            a {
                                margin: 0;
                            }
                        }
                    }

                    a {
                        width: 115px;
                        height: 35px;
                        font-size: 0;
                    }

                    a.google-app-link {
                        background-size: 118% 163%;
                    }
                    a.app-store-link {
                        background-size: 105% 110%;
                        @media (max-width: $dobbies-container-max-width-s) {
                            margin-right: 5%;
                        }
                    }

                    @media (max-width: $dobbies-container-max-width-tablet) {
                        a.google-app-link {
                            width: 161px;
                        }

                        a.app-store-link {
                            margin-right: 21%;
                        }

                        a {
                            width: 145px;
                            height: 47px;
                        }
                    }
                }
    
                .footer-qr-code {
                    margin-right: 27px;
                    img {
                        top: 0;
                        min-width: 86px;
                        height: 86px;
                        margin: 0;
                    }
                }

                .col-12 {
                    .ms-footer__list {
                        margin-bottom: 40px;
                        @media (min-width: $dobbies-container-max-width-m) {
                            margin-bottom: 45px;
                        }
                    }

                    &:last-child {
                        .ms-footer__list {
                            margin-bottom: 0px;
                        }
                    }
                }

                // custom css class
                ul.footer-social-icons-list {
                    justify-content: space-between;
                    li {
                        &:last-child {
                            margin-right: 0;
                        }
                        .ms-footer__link {
                            img {
                                width: 40px;
                                height: 40px;
                                margin: 0;
                                @media (min-width: $dobbies-container-max-width-m) {
                                    width: 42px;
                                    height: 42px;
                                }
                            }
                        }
                    }
                }
            }
            // custom css class
            ul.ms-footer-paymentmethods-list {
                justify-content: space-between;
                li {
                    &:last-child {
                        margin-right: 0;
                    }
                    .ms-footer__link {
                        img {
                            width: 40px;
                            height: auto;
                            margin: 0;
                        }
                    }
                }
                .icon_aPay {
                    .ms-footer__link {
                        img {
                            width: 43px;
                        }
                    }
                }
                .icon_gPay {
                    .ms-footer__link {
                        img {
                            width: 48px;
                        }
                    }
                }
                .icon_maestro {
                    .ms-footer__link {
                        img {
                            margin-top: 6px;
                        }
                    }
                }
                .icon_mastercard {
                    .ms-footer__link {
                        img {
                            width: 38px
                        }
                    }
                }
            }
            // custom css class
            ul.ms-footer-app-download {
                display: flex;

                > li {
                    &:first-child {
                        @media (max-width: $dobbies-container-max-width-tablet) {
                            order: 1;
                        }
                    }
                    &:last-child {
                        width: 100%;
                    }
                }

                .footer-qr-code {
                    @media (max-width: $dobbies-container-max-width-tablet) {
                        margin: 0;
                    }
                }
            }

            &.scrolledToFooter {
                .ms-back-to-top {
                    &[aria-hidden="false"] {
                        display: block;
                        opacity: 1;
                        transform: translateX(0%);
                        z-index: 9;
                        position: absolute;
                    }
                    bottom: 5px;
                    right: 10px;
                    @media (min-width: $dobbies-container-max-width-s) {
                        bottom: 20px;
                        right: 25px;
                    }
                    @media (min-width: $dobbies-container-max-width-m) {
                        bottom: 200px;
                        right: 40px;
                    }
                    @media (min-width: $dobbies-container-max-width-xl) {
                        bottom: auto;
                        right: 33px;
                        top: 46px;
                    }
                }
            }
        }
        
        .footer-app-buttons {
            p {
                margin: 0;
            }
            a.app-store-link {
                margin-right: 20px;
            }
        }
        // custom css class
        .new-footer-container {
            @media (max-width: $dobbies-container-max-width-tablet) {
                padding: 0;
            }
           > .row {
                > div {
                    @media(max-width: $dobbies-container-max-width-xl) and (min-width: $dobbies-container-max-width-m) {
                        flex: 1;
                        max-width: 100%;
                    }
                    &:first-child {
                        order: 1;
                        @media(max-width: $dobbies-container-max-width-xl) and (min-width: $dobbies-container-max-width-m) {
                            display: none;
                        }
                        @media (min-width: $dobbies-container-max-width-xl) {
                            order: 0;
                        }
                    }
                }
            }
        }

        .footer-container-mid {
            display: none;
            .footer-app-buttons {
                display: block;
            }


            @media(max-width: $dobbies-container-max-width-xl) and (min-width: $dobbies-container-max-width-m) {
                display: block;
                padding: 0;
                > .row {
                    margin-top: 30px;
                    padding-top: 30px;
                    border-top: 1px solid $dobbies-white;
                    .col-lg-6 {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }
            .footer-qr-code {
                margin-right: 52px;
                img {
                    top: 0;
                    min-width: 86px;
                    height: 86px;
                    margin: 0;
                }
            }

            .social-media-inner-container {
                border: none;
                .col-12 {
                    .ms-footer__list {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        // custom css class 
        .signup-redesign {
            background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MDhuRO) center center no-repeat;
            background-size: cover;
            margin-bottom: -2px;
            @media (min-width: 530px) {
                background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MDhpEA) center center no-repeat;
                background-size: cover;
            }

            @media (min-width: $dobbies-container-max-width-tablet) {
                background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MDhpED) center center no-repeat;
                background-size: cover;
            }

            @media (min-width: $dobbies-container-max-width-xl) {
                background: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MDhn2W) center center no-repeat;
                background-size: cover;
            }
            .newsletter-bg {
                height: 330px;
                position: absolute;
                img {
                    height: 100%;
                    width: 100%;
                }
            }
            > div {
                background-size: 100% 100%;
                max-height: none;
                min-height: auto !important;
                padding: 0 16px;
            }

            .ms-dobbies-footer-newsletter {
                max-width: none;
                margin: 0;
                padding: 40px 0;
                @media (min-width: $dobbies-container-max-width-s) {
                    padding: 50px 0 56px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    padding: 67px 0 60px;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    padding: 72px 0 56px;
                }
                &__content {
                    border: none;
                    padding: 0;
                    margin: 0;
                }

                &__description {
                    padding-right: 0;
                    margin-bottom: 25px;
                    @media (max-width: 530px) {
                        padding-right: 25%;
                    }
                    @media (max-width: 460px) {
                        padding-right: 13%;
                    }
                    h2 {
                        font-size: 40px;
                        color: $dobbies-white;
                        margin-bottom: 15px;
                        text-align: left;
                        font-weight: normal;
                        @media (max-width: 530px) {
                            max-width: 235px;
                        }
                        @media (min-width: $dobbies-container-max-width-s) {
                            font-size: 45px;
                            text-align: center;
                            margin-bottom: 30px;
                        }
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            font-size: 65px;
                            margin-bottom: 25px;
                        }
                        span {
                            font-family: $dobbies-secondary-font-family-light;
                            font-size: 72px;
                            line-height: 30px;
                            position: relative;
                            top: 6px;
                            margin-bottom: 15px;
                            @media (min-width: $dobbies-container-max-width-s) {
                                font-size: 107px;
                                line-height: 50px;
                                top: 15px;
                                margin-right: 10px;
                                margin-bottom: 0;
                            }
                            @media (min-width: $dobbies-container-max-width-tablet) {
                                font-size: 150px;
                                margin-right: 17px;
                            }
                        }
                    }
                    p {
                        font-size: 16px;
                        color: $dobbies-white;
                        text-align: left;
                        font-weight: 100;
                        @media (min-width: $dobbies-container-max-width-s) {
                            font-size: 20px;
                            text-align: center;
                            line-height: 25px;
                        }
                    }
                }

                .newslatter-signup {
                    width: 100%;
                    margin: 0 auto;
                    position: relative;
                    margin-top: 20px;
                    @media (min-width: $dobbies-container-max-width-s) {
                        max-width: 565px;
                        margin-top: 30px;
                    }
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        max-width: 820px;
                        margin-top: 20px;
                    }
                    .newslatter-signup-email {
                        font-size: $msv-font-size-s;
                        width: 100%;
                        padding: 16px 15px;
                        padding-right: 135px;
                        height: 42px;
                        border-radius: 7px;
                        border: none;
                        @media (min-width: $dobbies-container-max-width-s) {
                            height: 46px;
                        }
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            height: 53px;
                            padding-right: 215px;
                            font-size: $msv-font-size-m;
                        }
                        &:focus-within {
                            outline: none;
                            border: none;
                        }
                        &::placeholder {
                            color: $dobbies-grey;
                        }
                    }
                }

                &__btn-join-now {
                    position: absolute;
                    height: 100%;
                    border-radius: 5px;
                    width: 134px;
                    background-color: $dobbies-primary;
                    border: 3px solid $dobbies-white;
                    right: 0;
                    top: 0;
                    color: $dobbies-white;
                    padding: 0;
                    box-shadow: none;
                    @media (min-width: $dobbies-container-max-width-s) {
                    }
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        width: 212px;
                    }
                    &:hover {
                        background-color: $dobbies-primary-hover;
                        border-color: $dobbies-white;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .ms-back-to-top {
            width: 47px;
            height: 47px;
            right: 10px;
            top: auto;
            bottom: 40px;
            right: 30px;
            @media (min-width: $dobbies-container-max-width-s) {
                right: 43px;
            }
            @media (min-width: $dobbies-container-max-width-m) {
                right: 40px;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                right: 33px;
            }
        }
    }
}
.signup-redesign {
    &-newsletter-popup {
        &.ms-dobbies-newsletter-popup {
            height: auto;
            margin: 0;
            top: 0;
            margin-top: 100px;
            position: absolute;
            max-width: none;
            left: 50%;
            transform: translateX(-50%) !important;
            @media (min-width: $dobbies-container-max-width-s) {
                width: 740px;
            }
            @media (min-width: $dobbies-container-max-width-tablet) {
                width: 984px;
            }
            @media (min-width: $dobbies-container-max-width-xl) {
                width: 1145px;
            }

            .msc-modal__content {
                padding: 20px;
                position: relative;
                @media (min-width: $dobbies-container-max-width-tablet) {
                    padding: 25px 41px 40px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    padding: 30px 36px 40px;
                }
                @media (min-width: $dobbies-container-max-width-xl) {
                    padding: 40px 56px;
                }
            }
            .msc-modal__header {
                margin-bottom: 20px;
                padding: 0;
                padding-bottom: 10px;
                .popup__heading {
                    font-size: $msv-font-size-ml;
                    line-height: 25px;
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        font-size: $dobbies-title-font-size-25;
                    }
                }
                .newsletter-popup-cross {
                    width: 9px;
                    height: 9px;
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    display: flex;
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        right: 20px;
                        top: 20px;
                        width: 15px;
                        height: 15px;
                    }
                    &::before {
                        font-size: 9px;
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            font-size: 15px;
                        }
                    }
                }
            }
            .msc-modal__body {
                .first-and-last-name-input .last-name-input,
                .first-and-last-name-input .first-name-input {
                    @media (max-width: $dobbies-container-max-width-s) {
                        width: 100%;
                    }
                }

                .newsletter-subscription-div {
                    padding: 0;
                    > p {
                        margin-bottom: 20px;
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            font-size: $msv-font-size-m;
                        }
                    }

                    input[type="text"],
                    input[type="email"] {
                        margin-bottom: 20px;
                        height: 50px;
                        padding-left: 20px;
                        border-color: $dobbies-gray-100;
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            height: 56px;
                        }
                    }

                    .email-address-input {
                        margin-top: 0;
                        margin-bottom: 20px;
                    }

                    .checkbox-privacy-policy {
                        &-content {
                            display: flex;
                            input {
                                width: 20px;
                                height: 20px;
                                margin-right: 15px;
                            }
                        }

                        input[type="checkbox"] {
                            appearance: none;
                            position: relative;
                            cursor: pointer;
                            &:before {
                                position: absolute;
                                content: '';
                                top: 0px;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                border: 1px solid $dobbies-gray-100;
                                border-radius: 5px;
                                background: $dobbies-white;
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                display: none;
                            }
            
                            &:checked {
                                &::before {
                                    background-color: $dobbies-gray-100;
                                }
                                &:after {
                                    display: block;
                                    left: 8px;
                                    top: 2.5px;
                                    width: 5px;
                                    height: 12px;
                                    border: solid $dobbies-white;
                                    border-width: 0 2px 2px 0;
                                    transform: rotate(45deg);
                                }
                            }
                        }

                        &-text {
                            a {
                                text-decoration: underline;
                            }
                        }

                        & ~ div {
                            @media (max-width: 390px) {
                                display: inline-block;
                            }
                            button {
                                width: 227px;
                                height: 30px;
                                padding: 0;
                                font-size: $dobbies-font-size-m;
                                margin-right: 40px;
    
                                &:disabled {
                                    background-color: #70707099;
                                    color: $dobbies-white;
                                    &::after {
                                        display: none;
                                    }
                                }
    
                                @media (max-width: 390px) {
                                    margin-right: 25px;
                                }
                            }
                        }
                    }

                    .msc-btn.close-popup {
                        text-decoration: underline;
                        padding: 0;
                        height: auto;
                        font-size: $dobbies-font-size-s;
                        color: $dobbies-grey;
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            font-size: $dobbies-font-size-m;
                        }
                        @media (max-width: 390px) {
                            display: inline-block;
                            width: auto;
                        }
                    }
                }
                .response-message {
                    padding-left: 0;
                }
            }
        }
    }
}