$msv-reviews-card-border-width: 1px;
$msv-reviews-list-reviews-width: 100%;
$msv-reviews-list-refiners-margin-bottom: 12px;
$msv-reviews-list-refiners-margin-top-mobile-viewport: 5px;
$msv-reviews-list-card-header-max-width: 160px;
$msv-reviews-list-card-header-margin-right: 20px;
$msv-rating-padding-top: 15px;
$msv-msc-l-dropdown-label-margin-right: 5px;
$msv-msc-l-dropdown-label-margin-left: 20px;
$msv-msc-l-dropdown-label-margin-left-mobile-viewport: 2px;
$msv-msc-l-dropdown-padding: 6px 5px;
$msv-msc-l-dropdown-padding-mobile-viewport: 0px;
$msv-reviewslist-help-icon-margin-right: 0px;
$msv-reviewslist-like-icon-margin-right: 4px;
$msv-reviews-list-controls-spacing: 16px;
$msv-reviews-list-controls-padding-left: 5px;
$msv-reviews-list-controls-spacing-m: 8px;
$msv-reviews-list-card-padding: 20px 10px 20px 10px;
$msv-reviews-response-margin: 10px 0;
$msv-reviews-response-width: calc(100% - 70px);
$msv-reviews-response-width-m: 500px;
$msv-reviews-response-icon-width: 8px;
$msv-reviews-response-icon-left: -20px;
$msv-reviews-response-text-margin-top: 4px;
$msv-reviews-list-pagination-icon-spacing: 8px;
$msv-reviews-list-empty-message-padding: 24px;
$msv-smaller-port-m: 320px;
$refiners-width: 271px;
//style presets
:root {
    --msv-reviews-list-font-size: var(--msv-body-font-size-m);
  
    // heading
    --msv-reviews-list-heading-font-size: var(--msv-body-font-size-xl);
    --msv-reviews-list-heading-font-color: var(--dobbies-font-primary-color);

    // text
    --msv-reviews-list-text-font-size: var(--msv-body-font-size-m);
    --msv-reviews-list-text-font-color: var(--dobbies-font-primary-color);
  
    // button-link-color
    --msv-reviews-list-btn-link-color: #{$dobbies-link-color};

    // rating star
    --msv-reviews-list-rating-star: var(--dobbies-font-primary-color);

    // primary button
    --msv-reviews-list-primary-btn-bg: var(--dobbies-accent-brand-color);
    --msv-reviews-list-primary-btn-font-color: var(--dobbies-font-secondary-color);
    --msv-reviews-list-primary-btn-border: var(--dobbies-accent-brand-color);
  
     // secondary button
     --msv-reviews-list-secondary-btn-bg: var(--dobbies-bg-color);
     --msv-reviews-list-secondary-btn-font-color: var(--dobbies-font-primary-color);
     --msv-reviews-list-secondary-btn-border: var(--dobbies-accent-brand-color);
  }

.ms-reviews-list {
    @include font-content(var(--msv-font-weight-light), var(--msv-reviews-list-font-size), $dobbies-line-height-m);
    margin-bottom: 20px;

    &__empty-message {
        padding-bottom: $msv-reviews-list-empty-message-padding;
    }

    &__refiners {
        float: right;
        margin: -60px 0 0;
        position: relative;
        &:after {
            @include dobbies-icon();
            content: $dobbies-icon-arrow;
            transform: rotate(-180deg);
            position: absolute;
            top: 50%;
            right: 15px;
            margin-top: -9px;
            background: var(--dobbies-bg-color);
            font-weight: var(--dobbies-font-weight-heavy);
            font-size: var(--dobbies-body-font-size-l);
            z-index: 1;
            pointer-events: none;
            color: #facc00;
        }

        .msc-l-dropdown__label {
            color:var(--msv-reviews-list-text-font-color);
            display: inline-block;
            font-weight: var(--msv-font-weight-light);
            //margin-left: $msv-msc-l-dropdown-label-margin-left;
            margin-right: 10px;
           // vertical-align: middle;
        }

        .msc-l-dropdown{
            background-color: transparent;
            border-color: transparent;
            color:var(--msv-reviews-list-text-font-color);
            display: inline-block;
            font-weight: var(--msv-font-weight-bold);
            padding: 5px 20px;
            vertical-align: middle;
            border: 1px solid $dobbies-gray-800;
            width: 180px;
            height: 35px;
            font-size: 14px;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
            font-weight: normal;
            z-index: 2;
            position: relative;
            @media (max-width: $dobbies-container-max-width-l) {
                width: 180px;
                height: 35px;
            }
        }
        &:after {
            @include dobbies-icon();
            content: $dobbies-icon-arrow;
            transform: rotate(-180deg);
            position: absolute;
            top: 50%;
            right: 15px;
            margin-top: -9px;
            background: var(--dobbies-bg-color);
            font-weight: var(--dobbies-font-weight-heavy);
            font-size: var(--dobbies-body-font-size-l);
            color: var(--dobbies-icon-color);
            z-index: 1;
            color: #facc00;
            @media (max-width: $dobbies-container-max-width-m) {
                margin-top: -9px;
            }
        }

        label.msc-l-dropdown__label:not(:first-child) {
            display: none;
        }

        select.msc-l-dropdown:not(#reviewsListSortBy){
            display: none;
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            .msc-l-dropdown__label {
                margin-left: $msv-msc-l-dropdown-label-margin-left-mobile-viewport;
            }
        }

        @media (max-width: $msv-breakpoint-sm) {
            margin: 0 0 20px;
        }

        @media (max-width: $msv-breakpoint-mbl) {
            select.msc-l-dropdown {
                height: 36px;
            }
        }
    }

    &__reviews {
        clear: both;
        flex-basis: $msv-reviews-list-reviews-width;
       // margin-bottom: 20px;

        .ms-reviews-list__rating {
            text-align: left;
        }

        .ms-reviews-list__name {
            font-size: $dobbies-font-size-s;
            font-weight: var(--dobbies-font-weight-bold);
        }
    }

    &__user-review {
        background: var(--dobbies-bg-color);

        .ms-reviews-list__like,
        .ms-reviews-list__dislike {
            &.disabled {
                &:before {
                    font-weight: $msv-outline-icon-weight;
                }
            }
        }
    }

    &__like,
    &__dislike,
    &__report,
    &__edit {
        @include button-link(var(--msv-reviews-list-btn-link-color));
        @include font-content(var(--msv-font-weight-normal), var(--msv-reviews-list-font-size), $dobbies-line-height-m);
        @include vfi();
        vertical-align: middle;
        padding: 12px;
        color: $dobbies-grey;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &__submit,
    &__success {
        @include primary-button(var(--msv-reviews-list-primary-btn-bg), var(--msv-reviews-list-primary-btn-font-color), var(--msv-reviews-list-primary-btn-border));
        position: relative;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        border: 0;
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-bg-color-primary);
            &:after {
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
            }
        }
    }

    &__success {
        display: none;
    }

    &__cancel {
        @include secondary-button(var(--msv-reviews-list-secondary-btn-bg), var(--msv-reviews-list-secondary-btn-font-color), var(--msv-reviews-list-secondary-btn-border));
    }

    &__like {
        @include add-dobbies-icon($dobbies-icon-thumbs-up, before, $icon-weight: $dobbies-outline-icon-weight);
        
        &.disabled {
            &:before {
                font-weight: $msv-solid-icon-weight;
            }
        }

        &:before {
            color: var(--msv-reviews-list-primary-btn-bg);
            margin-right: $msv-reviewslist-like-icon-margin-right;
            font-size: 13px;
        }
    }

    &__dislike {
        @include add-dobbies-icon($dobbies-icon-thumbs-down, before, $icon-weight: $dobbies-outline-icon-weight);
        padding-right: $msv-reviews-list-controls-spacing;
        padding-left: $msv-reviews-list-controls-padding-left;
        border-right: $msv-reviews-card-border-width solid var(--msv-reviews-list-primary-btn-border);

        &.disabled {
            &:before {
                font-weight: $msv-solid-icon-weight;
            }
        }

        &:before {
            color: var(--msv-reviews-list-primary-btn-bg);
            margin-right: $msv-reviewslist-like-icon-margin-right;
            font-size: 13px;
        }
    }

    &__date {
        color: var(--msv-reviews-list-text-font-color);
    }

    &__card {
        border-top: $msv-reviews-card-border-width solid rgba($dobbies-gray-800,  0.5);
        padding: $msv-reviews-list-card-padding;

        &-header {
            margin-right: $msv-reviews-list-card-header-margin-right;
            flex-shrink: 0;
            width: $msv-reviews-list-card-header-max-width;
            @media (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }

        &-body {
            flex-grow: 1;
            margin-top: 20px;
            @media (max-width: $dobbies-container-max-width-s) {
                margin-top: 10px;
            }
        }

        &:last-child {
            border-bottom: $msv-reviews-card-border-width solid rgba($dobbies-gray-800,  0.5);
        }

        @media (min-width: $msv-breakpoint-m) {
            display: flex;

            &-controls {
                text-align: left;
                font-weight: var(--msv-font-weight-normal);
            }
        }

        &-controls {
            .ms-reviews-list__dislike{
                padding-right: 5px;
                border: 0;
            }
            .ms-reviews-list__reported {
                padding-left: 12px;
            }
            .ms-reviews-list__edit {
                text-decoration: underline;
                padding: 0;
            }

            @media (max-width: $msv-breakpoint-mbl) {
                display: flex;
            }
            a, button {
                padding: 0 0 0 15px;
                height: auto;
                margin-top: 0;
            }
        }
    }

    &__name {
        color: var(--msv-reviews-list-heading-font-color);
        margin: 20px 0;
        font-weight: var(--msv-font-weight-bold);
        @media (max-width: $msv-breakpoint-sm) {
            margin: 10px 0;
        }
    }

    &__title {
        @include font-content(var(--msv-font-weight-bold), var(--msv-reviews-list-heading-font-size), $dobbies-line-height-xl);
        color: var(--msv-reviews-list-heading-font-color);
        margin-bottom: 20px;
        line-height: initial;
        width: 100%;
    }

    &__helpful {
        color: var(--msv-reviews-list-heading-font-color);
        display: block;
        margin-bottom: 5px;;
        margin-right: $msv-reviews-list-controls-spacing;
        line-height: 25px;
    }

    &__review {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__text {
        @include font-content(var(--msv-font-weight-normal), var(--msv-reviews-list-text-font-size), $dobbies-line-height-m);
        color: var(--msv-reviews-list-text-font-color);
        flex-basis: 100%;
        word-break: break-word;
        white-space: break-spaces;
        &:after {
            content: '';
        }
    }

    &__response {
        margin: $msv-reviews-response-margin;
        padding-left: 70px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: $msv-reviews-response-width;

        &:before {
            content: '';
            width: $msv-reviews-response-icon-width;
            height: 100%;
            width: 8px;
            position: absolute;
            border-radius: 110px;
            left: $msv-reviews-response-icon-left;
            background-color: var(--dobbies-accent-brand-color);
        }

        &-name {
            font-weight: var(--msv-font-weight-bold);
        }

        &-text {
            flex-basis: 100%;
            margin-top: $msv-reviews-response-text-margin-top;
        }

        &-date {
            color: var(--msv-reviews-list-text-font-color);
        }

        @media (min-width: $msv-breakpoint-m) {
            width: $msv-reviews-response-width-m;
        }
    }

    &__page-controls {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
    }

    &__next {
        margin-left: auto;
    }

    &__next,
    &__previous {
        @include vfi();
        @include font-content-l();
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: var(--dobbies-icon-color);

        &:hover {
            text-decoration: underline;
        }
    }

    &__previous:before {
        @include msv-icon();
        content: $msv-ChevronLeft;
        margin-right: $msv-reviews-list-pagination-icon-spacing;
    }

    &__next:after {
        @include msv-icon();
        content: $msv-ChevronRight;
        margin-left: $msv-reviews-list-pagination-icon-spacing;
    }

    &__report-error {
        margin-bottom: 0;
    }

    &__report-message {
        @include font-content(var(--msv-font-weight-light), var(--msv-reviews-list-font-size), $dobbies-line-height-m);
        margin-bottom: 0px;
    }

    &__reported {
        color: var(--dobbies-success-color);
        display: inline-block;
        line-height: 28px;
        font-weight: var(--dobbies-font-weight-bold);
    }

    
    .msc-rating {
        &__count,
        &__star {
            font-size: $dobbies-font-size-m;
            color: #facc00;
        }

        &__half-star {
            &:after {
                color: #facc00;
            }
        }

        &__empty-star{
            font-size: $dobbies-font-size-m;
        }
    }

    @media screen and (min-width: $msv-breakpoint-m) {
        &__helpful {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
            margin-right: $msv-reviewslist-help-icon-margin-right;
        }

        &__card {
            &-body {
                margin-top: 0;
            }
        }
    }

    &__heading{
        display: none;

        & ~ div.msc-rating{
            visibility: hidden;
        }
    }

    &__report{
        text-decoration: underline;
    }

    .positive-votes {
        .ms-reviews-list {
            &__like {
                @include add-dobbies-icon($dobbies-icon-thumbs-up-filled, before, $icon-weight: $dobbies-outline-icon-weight);
            }
        }
    }
    .positive-no-votes {
        .ms-reviews-list {
            &__like {
                @include add-dobbies-icon($dobbies-icon-thumbs-up, before, $icon-weight: $dobbies-outline-icon-weight);
            }
        }
    }
    .negative-votes {
        .ms-reviews-list {
            &__dislike {
                @include add-dobbies-icon($dobbies-icon-thumbs-down-filled, before, $icon-weight: $dobbies-outline-icon-weight);
            }
        }
    }
    .negative-no-votes {
        .ms-reviews-list {
            &__dislike {
                @include add-dobbies-icon($dobbies-icon-thumbs-down, before, $icon-weight: $dobbies-outline-icon-weight);
            }
        }
    }
    &__like.disabled {
        @include add-dobbies-icon($dobbies-icon-thumbs-up-filled, before, $icon-weight: $dobbies-outline-icon-weight);
    }
    &__dislike.disabled {
        @include add-dobbies-icon($dobbies-icon-thumbs-down-filled, before, $icon-weight: $dobbies-outline-icon-weight);
    }
    
}

.ms-report-review-modal {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin: auto;

    .msc-modal{
        &__content{
            padding: 40px;
            @media (max-width: $dobbies-container-max-width-s) {
                padding: 15px;
            }
        }

        &__header{
            border: 0;
            padding: 0 0 8px 0;

            .msc-modal__close-button{
                width: 34px;
                height: 34px;
                position: absolute;
                top: 30px;
                right: 40px;
                cursor: pointer;
                text-align: center;
                padding: 0;
                &:before {
                    @include dobbies-icon();
                    content: $dobbies-icon-close;
                    font-size: var(--dobbies-icon-font-size-xl);
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    top: 10px;
                    right: 20px;
                }
            }
        }

        &__footer {
            .ms-reviews-list__submit{
                font-size: var(--dobbies-body-font-size-m);
                font-weight: var(--dobbies-font-weight-bold);
                line-height: var(--dobbies-body-font-size-m);
                @media (max-width: $msv-breakpoint-m) {
                    width: auto;
                    min-width: 50%;
                }
            }

            .ms-reviews-list__cancel{
                border: 0;
                text-decoration: underline;
                font-size: var(--dobbies-body-font-size-m);;
                font-weight: var(--dobbies-font-weight-bold);
                background: unset;
                @media (max-width: $msv-breakpoint-m) {
                    width: auto;
                    min-width: 46%;
                }

                &:hover, &:active {
                    background-color: unset;
                    color: var(--msv-reviews-list-secondary-btn-font);
                }
            }
        }
    }
    

    fieldset {
        border-color: transparent;
        padding: 13px 0;
        margin: 0;
        margin-left: -3px;
    }

    &-pair {
        margin-bottom: 7px;

        .msc-radio-input{
            position: relative;
            height: 17px !important;
            width: 17px;

            &:checked:after {
                width: 11px;
                height: 11px;
                border-radius: 50%;
                top: 0;
                left: 0;
                position: absolute;
                background-color: var(--msv-reviews-list-primary-btn-bg);
                content: '';
                margin-left: 6px;
                margin-top: 3px;
                @media (max-width: $dobbies-container-max-width-s) {
                    display: none;
                }
            }
        }
    }

    .msc-radio-label {
        @include form-input-radio-label();
        font-size: var(--dobbies-body-font-size-s);
        font-weight: normal;
        margin-left: 10px;
    }

    .msc-radio-input {
        @include form-input-radio();
    }
}