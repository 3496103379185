$homepage-hero-title-line-height: 90px;
$homepage-hero-title-line-height-mobile: $dobbies-line-height-xl;
$top-spacing-button: 15px;
$hero-banner-mobile-padding: 20px 0 50px;

:root {
    //Text theme
    --msv-hero-textheme-dark: var(--dobbies-font-secondary-color);
    --msv-hero-textheme-light: var(--dobbies-font-primary-color);

    //Body
    --msv-hero-body-size: #{$dobbies-font-size-m};

    //Cta
    --msv-hero-cta-color: var(--dobbies-accent-brand-color);
}

.hero.ms-content-block {
    height: 100%;
    overflow: hidden;
    min-height: 330px;
    @media screen and (min-height: $msv-breakpoint-mbl-sm) and (max-width: $dobbies-container-max-width-tablet) {
        max-height: calc(100vh - 172px);
    }
    @media screen and (max-height: $msv-breakpoint-mbl-sm) and (max-width: $dobbies-container-max-width-l) {
        max-height: 565px;
    }
    @media screen and (max-height: $msv-breakpoint-mbl-sm) and (max-width: $dobbies-container-max-width-s) {
        max-height: 350px;
    }
    .ms-content-block__details {
        align-items: flex-start;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        width: 1068px;
        height: 450px;
        margin: 0 auto;
        padding: 0 20px;
        gap: 20px 0;
        @media screen and (min-width: $msv-breakpoint-l) and (max-width: $dobbies-container-max-width-tablet) {
            height: 370px;
        }
        @media screen and (max-width: $dobbies-container-max-width-m) {
            max-width: 650px;
        }
        @media screen and (max-width: $dobbies-container-max-width-s) {
            width: 400px;
            height: 260px;
        }
        @media screen and (max-width: $dobbies-container-max-width-xs) {
            width: 90%;
        }
        .ms-content-block__title {
            @include dobbies-font(var(--msv-font-weight-normal), none, $dobbies-font-cinzel);
            font-size: 65px;
            line-height: 65px;
            color: var(--msv-hero-textheme-dark);
            text-transform: uppercase;
            margin: 10px 0;
            max-width: 700px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            max-height: 260px;
            text-align: left;
            color: #111;
            margin: 0;
            @media screen and (min-width: $msv-breakpoint-l) and (max-width: $dobbies-container-max-width-tablet) {
                margin: 0;
                max-height: 300px;
                line-height: 80px;
            }
        }
        .ms-content-block__text {
            color: var(--msv-hero-textheme-dark);
            font-size: var(--dobbies-body-font-size-ml);
            line-height: $dobbies-line-height-xl;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal;
            max-height: 98px;
            text-align: left;
            margin-top: 0;

            @media screen and (min-width: $msv-breakpoint-l) and (max-width: $dobbies-container-max-width-tablet) {
                -webkit-line-clamp: 2;
            }

            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                font-size: var(--dobbies-body-font-size-m);
            }
        }
        .msc-cta__primary {
            font-size: 14px;
            text-decoration: underline;
            font-weight: var(--dobbies-font-weight-bold);
            display: inline-block;
            cursor: pointer;
            padding: 0;
            height: auto;
            width: auto;
            background: none;
            border: none;
            min-width: auto;
            margin: 0;
            color: #111;

            &:hover {
                background-color: none;
                border: none;
            }

            @media (max-width: $dobbies-container-max-width-tablet) {
                padding: 0;
                margin: 0;
            }
        }
        .ms-content-block__cta {
            margin-top: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            gap: 20px;
        }
    }
    &.texttheme__light .ms-content-block__title,
    &.texttheme__light .ms-content-block__text {
        color: var(--msv-hero-textheme-light);
        text-shadow: $hero-content-shadow;
    }

    &.texttheme__dark .ms-content-block__title,
    &.texttheme__dark .ms-content-block__text {
        color: var(--msv-hero-textheme-dark);
    }
}

.cta-button {
    .hero.ms-content-block {
        .ms-content-block__details {
            .msc-cta__primary {
                @include secondary-button();
                @include dobbies-font();
                font-size: var(--dobbies-body-font-size-xl);
                transform: perspective(1px) translateZ(0);
                position: relative;
                transition-duration: 0.3s;
                overflow: hidden;
                border: 0;
                margin-left: 0;
                &:before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--dobbies-bg-color-secondary);
                    transform: scaleX(0);
                    transform-origin: 0 50%;
                    transition-property: transform;
                    transition-duration: 0s;
                    transition-timing-function: ease-out;
                }
                &:hover,
                &:focus,
                &:active {
                    color: var(--dobbies-font-primary-color);
                    background: var(--dobbies-bg-color);
                    &:before {
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $dobbies-container-max-width-m) {
    .hero.ms-content-block {
        height: auto;
        .ms-content-block__details {
            .ms-content-block__title {
                margin: 0;
                font-size: 58px;
                line-height: 60px;
                max-height: 150px;
            }
        }
        &[data-m-layout='full-width'] {
            .ms-content-block__image {
                img {
                    height: auto;
                }
            }
        }
    }
}
@media screen and (max-width: $dobbies-container-max-width-s) {
    .hero.ms-content-block .ms-content-block__details {
        .ms-content-block__title {
            font-size: 34px;
            line-height: 40px;
            -webkit-line-clamp: 3;
            max-height: 170px;
            br {
                display: none;
            }
        }
        .msc-cta__primary {
            padding: 7px 12px 10px;
            min-width: 130px;
            font-size: var(--dobbies-body-font-size-ml);
            height: auto;
        }
    }
}
@media screen and (max-width: $dobbies-container-max-width-xs) {
    .hero.ms-content-block .ms-content-block__details {
        .ms-content-block__title {
            font-size: 34px;
            line-height: 40px;
        }
    }
}

// 404 banner styling
.error-banner {
    .hero {
        position: relative;
        &.ms-content-block {
            .ms-content-block__details {
                position: absolute;
                padding-left: 90px;
                @media (max-width: $msv-breakpoint-l) {
                    padding-left: 20px;
                }
                .ms-content-block__title {
                    color: var(--dobbies-font-primary-color);
                    font-size: 36px;
                    text-transform: unset;
                    @media (max-width: $msv-breakpoint-m) {
                        font-size: 32px;
                    }
                }
                .ms-content-block__text {
                    color: var(--dobbies-font-primary-color);
                    font-size: 18px;
                    margin: 16px 0;
                    @media (max-width: $msv-breakpoint-m) {
                        display: block;
                        font-size: 16px;
                    }
                }
            }
            .msc-cta__primary {
                background: none;
                padding: 0;
                margin: 0;
                height: unset;
                width: unset;
                min-width: unset;
                text-decoration: underline;
                font-size: 16px;
                &:hover,
                &:active,
                &:focus {
                    background: none;
                    font-weight: bold;
                    text-decoration: underline;
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    img {
        height: auto;
        width: 100%;
    }
}
