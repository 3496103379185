.cookie-info-container {
	margin-bottom: 30px;
	.full-width-text-block {
		margin-bottom: 0;
		line-height: 21px;
	}
	.ms-accordion-item__drawer .drawer__button {
		height: fit-content;
		border-bottom: solid 1px rgba(102,102,102,0.5);
	}
	.ms-accordion-header-section {
		display: none;
	}

	.cookie-information-table {
		margin-top: 20px;
	}

	.ms-accordion {
		.ms-accordion-item__drawer .drawer__button[aria-expanded="false"]:after {
			color: var(--dobbies-breadcrumb-icon-color);
		}
		.ms-accordion-item__drawer .drawer__button[aria-expanded="true"]:after {
			color: var(--dobbies-breadcrumb-icon-color);
		}
	}

	.ms-accordion-item-content {
		line-height: $dobbies-line-height-m;
	}
}