$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;

.msc-add-to-cart {
	@include primary-button();
	@include vfi();
	display: flex;
	width: 192px;
	align-content: center;
	justify-content: center;
	border-radius: 6px;
	transform: perspective(1px) translateZ(0);
	position: relative;
	transition-duration: 0.3s;
	overflow: hidden;
	border: 0;
	height: 30px;
	padding: 5px 20px;
	min-width: 0;
	font-size: 16px;
	font-weight: $dobbies-font-weight-bold;

	@media (max-width: $dobbies-container-max-width-tablet) {
		height: 30px;
		margin-top: 0;
    }

	@media (min-width: 1900px) {
		height: 30px;
	}

	&:after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: var(--dobbies-bg-color-secondary);
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	}
	&:hover,
	&:focus,
	&:active {
		color: var(--dobbies-font-primary-color);
		background: var(--dobbies-bg-color-primary);
		&:after {
			-webkit-transform: scaleX(1);
			transform: scaleX(1);
		}
	}
	&:not(:disabled) {
		&:hover {
			cursor: pointer;
		}
	}

	// @media (max-width: $msv-breakpoint-m) {
	// 	height: 48px;
	// 	width: 200px;
	// }

	// @media (max-width: $msv-breakpoint-s) {
	// 	height: 48px;
	// 	width: 190px;
	// }
}
.msc-notify-me-container {
	display: flex;
	align-items: center;
}

.msc-notify-me {
	@extend .msc-add-to-cart;
	border-radius: 8px;
	height: 40px;
	margin-bottom: 2px;
}

.msc-notify-me-text {
	color: red;
	margin-bottom: 4px;
}

.msc-modal {
	.msc-add-to-cart {
		&__dialog {
			max-width: $msv-dialog-width;

			.msc-modal__content {
				padding: $msv-dialog-modal-content-padding;
			}

			&__header {
				.msc-modal__title {
					@include font-content-l();

					span {
						&:nth-child(1) {
							font-weight: var(--msv-font-weight-bold);
						}
					}
				}
			}

			&__body {
				@include font-content-l();
				text-align: center;

				.msc-empty_image {
					@include image-placeholder($msv-dialog-msc-empty_image-size);
					padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
				}
			}

			&__image {
				margin-right: auto;
				margin-left: auto;
			}

			&__product-title {
				padding: $msv-dialog-modal-content-body-text-padding;
			}

			&__product-variants {
				@include font-content-m();
				padding: $msv-dialog-modal-content-body-text-padding;
			}

			&__product-price {
				font-weight: var(--msv-font-weight-bold);
				padding: $msv-dialog-modal-content-body-text-padding;
			}

			&__go-to-cart {
				@include primary-button();
				margin: $msv-dialog-modal-content-button-margin;
				width: $msv-dialog-button-width;
			}

			&__back-to-shopping {
				@include secondary-button();
				margin: $msv-dialog-modal-content-button-margin;
				width: $msv-dialog-button-width;
			}
		}
	}
}
