.ms-advice-media-container {
    padding-top: 57px;
    @media (max-width: $dobbies-container-max-width-xl) {
        padding-top: 22px;
    }
    @media (max-width: $dobbies-container-max-width-m) {
        padding-top: 13px;
    }
    @media (max-width: $dobbies-container-max-width-s) {
        padding-top: 9px;
    }


    &.product-recall-container {

        .ms-template-sample-banner {
            min-height: auto;
            padding: 0;
            margin-bottom: 40px;
            @media (max-width: $dobbies-container-max-width-s) {
                margin-bottom: 23px;
            }
            .ms-content-block {
                &__image {
                    width: 80%;
                    margin-left: auto;
                    overflow: hidden;
                    @media (max-width: $dobbies-container-max-width-s) {
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
                &__details {
                    position: relative;
                    height: auto;
                    padding: 0;
                    right: 0;
                    bottom: 0;
                    margin-left: 0;
                    
                    @media (min-width: $msv-container-max-width-m) {
                        margin-top: -151px;
                    }
                    @media (min-width: $dobbies-container-max-width-xl) {
                        margin-top: -120px;
                    }
                    
                    @media (max-width: $dobbies-container-max-width-m) {
                        margin-top: -110px;
                    }
                    @media (max-width: $dobbies-container-max-width-s) {
                        margin-top: 0;
                    }
                }
                &__title {
                    margin-bottom: 100px;
                    max-width: 360px;
                    background: rgba(255, 255, 255, 0.75);
                    width: 100%;
                    position: relative;
                    padding: 12px;
                    text-align: right;
                    line-height: 45px;
                    margin-bottom: 85px;
                    max-width: 400px;
                    font-size: $dobbies-advice-heading-font-size;
                    color: var(--dobbies-heading-primary-color);
                    line-height: 50px;
                    font-weight: var(--dobbies-font-weight-heavy);
                    
                    @media (min-width: $msv-container-max-width-m) {
                        margin-bottom: 50px;
                    }
                    @media (min-width: $dobbies-container-max-width-xl) {
                        margin-bottom: 85px;
                    }
                    
                    @media (min-width: $msv-container-max-width-m) and (max-width: $dobbies-container-max-width-xl) {
                        max-width: 300px;
                    }

                    @media (max-width: $dobbies-container-max-width-s) {
                        margin: 14px 0 22px;
                        padding: 0;
                        text-align: center;
                        max-width: 100%;
                        font-size: var(--dobbies-tile-heading);
                    }
                }
                &__text{
                    text-align: center;
                    p {
                        line-height: 22px;
                    }
                }
                @media (min-width: $msv-breakpoint-sm) and (max-width: $dobbies-container-max-width-l) {
                    &__details {
                        margin-top: -95px;
                    }
                    &__title {
                        margin-bottom: 50px;
                        max-width: 370px;
                    }
                }

            }

            @media (max-height: $msv-breakpoint-mbl) and (orientation: landscape) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .quick-facts-container {
            > .row {
                justify-content: center;
                h2 {
                    font-size: var(--dobbies-body-font-size-xml);
                    color: var(--dobbies-heading-primary-color);
                    line-height: 30px;
                    margin-bottom: 10px;
                    font-weight: var(--dobbies-font-weight-heavy);
                }
                .ms-text-block {
                    p {
                        font-size: var(--dobbies-body-font-size-s);
                        margin-bottom: 16px;
                    }
                }
                .col-12 {
                    margin-bottom: 45px;
                }
                .col-12:first-child {
                    @media (min-width: $msv-breakpoint-mbl-sm), (max-height: $msv-breakpoint-mbl) and (orientation: landscape) {
                        padding-right: 20px;
                    }
                }
                .col-12:nth-child(2) {
                    .ms-text-block {
                        border: solid 1px var(--dobbies-green-border-color);
                        margin-top: 10px;
                        h3 {
                            background: var(--dobbies-green-border-color);
                            color: var(--dobbies-font-secondary-color);
                            font-size: var(--dobbies-body-font-size-ml);
                            padding: 10px 15px;
                        }
                        ul {
                            padding: 10px 16px;
                            margin: 0;
                            list-style: none;
                            li {
                                overflow: hidden;
                                margin: 10px 0;
                                &:before {
                                    @include dobbies-icon();
                                    content: $dobbies-icon-arrow;
                                    font-size: var(--dobbies-breadcrumb-icon);
                                    transform: rotate(90deg);
                                    color: var(--dobbies-breadcrumb-icon-color);
                                    font-weight: var(--dobbies-font-weight-heavy);
                                    font-size: var(--dobbies-body-font-size-xs);
                                    margin: 3px 10px 5px 0;
                                    float: left;
                                }
                            }
                        }
                    }
                }
                .msc-cta__primary {
                    text-align: center;
                    margin-top: 20px;
                    @include secondary-button-bordered;
                    position: relative;
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    div {
                        display: block;
                        width: 100%;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: var(--dobbies-bg-color-secondary);
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                        -webkit-transform-origin: 0 50%;
                        transform-origin: 0 50%;
                        -webkit-transition-property: transform;
                        transition-property: transform;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        -webkit-transition-timing-function: ease-out;
                        transition-timing-function: ease-out;
                      }
                      &:hover,
                      &:focus,
                      &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                          -webkit-transform: scaleX(1);
                          transform: scaleX(1);
                        }
                    }
                }

                .ms-content-block {
                    @media (max-width: $dobbies-container-max-width-s) {
                        min-height: 0;
                    }
                }
            }
            &.contact-details {
                > .row {
                    .col-12:nth-child(2) {
                        .ms-text-block {
                            margin-top: 0;
                            border: 0;
                        }
                    }
                }
            }
        }
        .product-being-recalled {
            margin-left: -10px;
            margin-right: -10px;
            @media (max-width: $dobbies-container-max-width-s) {
                margin-left: 0;
                margin-right: 0;
            }
            > .row {
                justify-content: center;
                .col-12 {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding-bottom: 40px;
                    div[class*='imageplacement'] {
                        display: flex;
                        flex-wrap: wrap;
                        padding-bottom: 40px;
                        .ms-content-block {
                            &__image {
                                width: 100%;
                                padding-left: 10px;
                                padding-right: 10px;
                                justify-content: center;
                                @media (min-width: $dobbies-container-max-width-s) {
                                    width: 50%;
                                    display: flex;
                                    flex-direction: column;
                                }
                                @media (max-width: $dobbies-container-max-width-s) {
                                    padding-left: 0;
                                    padding-right: 0;
                                }
                                img {
                                    @media (max-width: $dobbies-container-max-width-s) {
                                        margin: auto;
                                    }
                                }
                            }
                            &__details {
                                margin-left: 0;
                                align-items: normal;
                                justify-content: center;
                                @media (min-width: $dobbies-container-max-width-s) {
                                    width: 50%;
                                    display: flex;
                                    flex-direction: column;
                                }
                                .msc-cta__primary {
                                    background-color: transparent;
                                    border: 1px solid var(--dobbies-breadcrumb-icon-color);
                                    color: var(--dobbies-font-primary-color);
                                    min-height: 38px;
                                    min-width: 215px;
                                    line-height: initial;
                                    border-radius: 4px;
                                    text-decoration: none;
                                    line-height: initial;
                                    font-size: var(--dobbies-body-font-size-l);
                                    font-weight: var(--dobbies-font-weight-normal);
                                    cursor: pointer;
                                    margin-top: 15px;
                                    div {
                                        margin: auto;
                                    }
                                    &:after {
                                        content: "";
                                        position: absolute;
                                        z-index: -1;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        background: var(--dobbies-bg-color-secondary);
                                        -webkit-transform: scaleX(0);
                                        transform: scaleX(0);
                                        -webkit-transform-origin: 0 50%;
                                        transform-origin: 0 50%;
                                        -webkit-transition-property: transform;
                                        transition-property: transform;
                                        -webkit-transition-duration: 0.3s;
                                        transition-duration: 0.3s;
                                        -webkit-transition-timing-function: ease-out;
                                        transition-timing-function: ease-out;
                                      }
                                      &:hover,
                                      &:focus,
                                      &:active {
                                        color: var(--dobbies-font-primary-color);
                                        background: var(--dobbies-bg-color);
                                        text-decoration: none;
                                        &:after {
                                          -webkit-transform: scaleX(1);
                                          transform: scaleX(1);
                                        }
                                    }
                                }
                                @media (max-width: $dobbies-container-max-width-s) {
                                    text-align: center;
                                    margin-top: 20px;
                                }
                            }
                            &__title {
                                color: var(--dobbies-heading-primary-color);
                                font-weight: var(--dobbies-font-weight-heavy);
                                font-size: var(--dobbies-tile-heading-s);
                                padding-bottom: 15px;
                                line-height: 36px;
                            }
                            &__text {
                                font-size: var(--dobbies-body-font-size-m);
                                margin-top: 0;
                            }
                        }
                    }
                    &:first-child {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding: 0;

                        @media (min-width: $msv-breakpoint-mbl-sm){
                            padding: 0 10px;
                        }

                        @media (max-height: $msv-breakpoint-mbl) and (orientation: landscape) {
                            padding-bttom: 0;
                        }
                    }
                    &:not(:first-child) {
                        .ms-content-block {
                            &__details {
                                position: initial;
                                height: auto;
                                padding: 0;
                                padding-top: 20px;
                                text-align: center;
                            }
                            &__title {
                                color: var(--dobbies-heading-primary-color);
                                font-weight: var(--dobbies-font-weight-heavy);
                                font-size: var(--dobbies-body-font-size-ml);
                                padding-bottom: 15px;
                                line-height: 32px;
                            }
                            &__text {
                                font-size: var(--dobbies-body-font-size-s);
                                margin-top: 0;
                            }
                        }
                    }
                    &:not(:first-child) {
                        padding-left: 10px;
                        padding-right: 10px;
                        @media (max-width: $dobbies-container-max-width-s) {
                            flex: 0 0 100%;
                            max-width: 100%;
                            padding-left: 0;
                            padding-right: 0;
                            .ms-content-block {
                                &__image {
                                    img {
                                        margin: auto;

                                        @media (max-width: $msv-breakpoint-mbl-sm){
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }

                        @media (max-height: $msv-breakpoint-mbl) and (orientation: landscape) {
                            flex: 0 0 33.3333%;
                            max-width: 33.3333%;
                            padding-bottom: 0px;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
            }

            .media-page-heading {
                h2 {
                    margin-top: 30px;

                    @media (max-width: $dobbies-container-max-width-s) {
                        line-height: 45px;
                    }
                }
            }
        }
        .ms-content-block {
            &[data-m-layout='full-width'] {
                min-height: auto;
                .ms-content-block__details {
                    padding: 0;
                    width: 100%;
                    display: block;
                    height: auto;
                    text-align: center;
                    margin:0;
                    position: relative;
                    @media (max-width: $dobbies-container-max-width-s) {
                        margin-top: 0;
                        height: auto;
                    }
                }
                .ms-content-block__cta {
                    display: inline-block;
                    margin-top: 0;
            
                    a,
                    .msc-cta__primary,
                    .msc-cta__secondary {
                        min-height: 29px;
                        max-height: 29px;
                        line-height: initial;
                        min-width: 215px;
                        border: 1px solid var(--dobbies-breadcrumb-icon-color);
                        font-size: var(--dobbies-body-font-size-s);
                        font-weight: var(--dobbies-font-weight-normal);
                        border-radius: 4px;
                        background-color: transparent;
                        color: var(--dobbies-font-primary-color);
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                        cursor: pointer;
                        padding: 0;
            
                        @media (max-width: $msv-breakpoint-l) {
                            min-width: 173px;
                        }
            
                        @media (max-width: $msv-breakpoint-sm) and (min-width: $msv-breakpoint-mbl) {
                            min-width: 130px;
                        }
            
                        @media (max-width: $msv-breakpoint-mbl) {
                            min-width: 150px;
                        }
            
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .what-to-do {
            .media-page-heading {
                h2 {
                    color: var(--dobbies-heading-primary-color);
                    font-weight: var(--dobbies-font-weight-heavy);
                    border-bottom: solid 1px rgba(102,102,102,0.5);
                    font-size: var(--dobbies-tile-heading);
                    line-height: 36px;
                    margin: 30px 0 30px;
                    padding-bottom: 15px;
                    @media (max-width: $dobbies-container-max-width-xl) {
                        margin-top: 20px;
                    }
                    @media (max-width: $dobbies-container-max-width-m) {
                        margin-top: 10px;
                    }
                    @media (max-width: $dobbies-container-max-width-m) {
                        margin-top: 40px;
                    }
                }
            }

            @media (max-height: $msv-breakpoint-mbl) and (orientation: landscape) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}