// generic classes

.m-t-20 {
    margin-top: 20px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-t-25 {
    margin-top: 25px !important;
}

.full-width-banner {
    margin: 0px;
    margin-top: 40px;
    @media (max-width: $msv-breakpoint-l) {
        margin-top: 40px;
    }

    @media (max-width: $dobbies-container-max-width-xl) {
        //margin: 20px 0;
    }
    @media (max-width: $dobbies-container-max-width-m) {
        margin-top: 40px;
    }
    @media (max-width: $dobbies-container-max-width-s) {
        margin-top: 40px;
    }
    .ms-content-block {
        &__image {
            img {
                width: 100%;
                height: auto;
            }
        }
        &__details {
            //padding: 20px 60px 20px 60px;
            text-align: center;
            margin-bottom: 40px;
            // @media (max-width: $msv-breakpoint-mbl-sm){
            //     padding: 14px 10px 10px;
            // }
        }
        &__title {
            color: var(--dobbies-font-primary-color);
            font-weight: var(--dobbies-font-weight-bold);
            line-height: normal;
            margin-bottom: 20px;
            text-transform: uppercase;
            margin-top: 20px;
        }
        &__text {
            font-size: var(--dobbies-body-font-size-s);
            font-weight: var(--dobbies-font-weight-normal);
            line-height: $dobbies-line-height-m;
            margin-bottom: 20px;
            p {
                margin-bottom: 20px;
            }
        }

        &__link {
            display: block;
        }

        &__cta {
            @media (max-width: $dobbies-container-max-width-s) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                max-width: 80%;
                gap: 20px 0;
                margin: 0 auto;
            }
            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                margin: 0px 10px;
                cursor: pointer;

                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 50%;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    &.cta-button {
        .ms-content-block {
            &__cta {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                justify-content: center;

                @media (max-width: $dobbies-container-max-width-s) {
                    padding: 0 15%;
                }

                > a {
                    background-color: transparent;
                    border: 1px solid $dobbies-grey;
                    color: var(--dobbies-font-primary-color);
                    border-radius: 4px;
                    text-decoration: none;
                    line-height: initial;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    cursor: pointer;
                    position: relative;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    overflow: hidden;
                    padding: 5px 20px;
                    margin: 0px;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                        margin: 0;
                    }
                    div {
                        margin: auto;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                    @media screen and (min-width: 768px) and (max-width: 992px) {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
.half-banner-with-title {
    min-height: auto;
    padding: 0;
    margin: 40px 0;
    .ms-content-block {
        &__image {
            overflow: hidden;
            img {
                width: 100%;
                height: auto;
            }
        }
        &__details {
            position: relative;
            height: auto;
        }
        &__title {
            width: 100%;
            position: relative;
            text-align: center;
            color: var(--dobbies-font-primary-color);
            font-weight: var(--dobbies-font-weight-bold);
            text-transform: uppercase;
            line-height: normal;
            margin-top: 20px;
            margin-bottom: 20px;
            @media (max-width: $dobbies-container-max-width-s) {
                // margin: 14px 0 0;
                padding: 0;
                text-align: center;
                max-width: 100%;
            }
        }
        &__text {
            font-size: var(--dobbies-body-font-size-s);
            font-weight: var(--dobbies-font-weight-normal);
            line-height: $dobbies-line-height-m;
            text-align: center;
            p {
                margin-bottom: 20px;
            }
        }

        &__cta {
            text-align: center;
            @media (max-width: $dobbies-container-max-width-s) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 0 25%;
                gap: 20px 0;
            }
            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                margin: 0px 10px;
                cursor: pointer;
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 50%;
                    padding: 0;
                    margin: 0;
                }
            }
        }

        &__link {
            display: block;
        }
    }

    &.cta-button {
        .ms-content-block {
            &__cta {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                justify-content: center;

                @media (max-width: $dobbies-container-max-width-s) {
                    padding: 0 5%;
                    padding-bottom: 1px;
                }

                > a {
                    background-color: transparent;
                    border: 1px solid $dobbies-grey;
                    color: var(--dobbies-font-primary-color);
                    border-radius: 4px;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    cursor: pointer;
                    position: relative;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    overflow: hidden;
                    padding: 5px 20px;
                    margin: 0px;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                        margin: 0;
                    }
                    div {
                        margin: auto;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                    @media screen and (min-width: 768px) and (max-width: 992px) {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
.half-banner-contact-button {
    .ms-content-block__cta {
        text-align: center;
        .msc-cta__primary,
        .msc-cta__secondary {
            @include club-plus-primary-button();
            @include button-verticle-padding-zero();
            background: var(--dobbies-bg-color-primary);
            width: 100%;
            height: 45px;
            border-radius: $dobbies-btn-border-radius;
            font-size: var(--dobbies-body-font-size-ml);
            line-height: 40px;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            border: 0;
            min-width: 150px;
            width: 185px;
            margin: 20px 0;
            color: $white;
            font-weight: normal;
            @media (max-width: $dobbies-container-max-width-l) {
                max-width: 145px;
                min-width: 145px;
                height: 37px;
                line-height: 34px;
                font-size: var(--dobbies-body-font-size-l);
            }
            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-club-primary-color);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                background: var(--dobbies-club-primary-color);
                &:before {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }
    }
    .contact-us-btn {
        @include club-plus-primary-button();
        @include button-verticle-padding-zero();
        background: var(--dobbies-bg-color-primary);
        justify-content: center;
        text-decoration: none;
        width: 100%;
        height: 45px;
        border-radius: $dobbies-btn-border-radius;
        font-size: var(--dobbies-body-font-size-ml);
        line-height: 40px;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        border: 0;
        min-width: 150px;
        width: 185px;
        margin: 20px 0;
        color: $white;
        font-weight: normal;
        @media (max-width: $dobbies-container-max-width-l) {
            max-width: 145px;
            min-width: 145px;
            height: 37px;
            line-height: 34px;
            font-size: var(--dobbies-body-font-size-l);
        }
        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-club-primary-color);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-club-primary-color);
            &:before {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }
}
.ms-content-block {
    .ms-content-block__link {
        display: block;
    }
    &.filter-navigation {
        &[data-m-layout='full-width'] {
            min-height: auto;
            margin-bottom: 30px;
            .ms-content-block {
                &__details {
                    display: block;
                    padding: 0;
                    position: relative;
                    width: 100%;
                    height: auto;
                    text-align: center;
                }
                &__title {
                    font-weight: var(--dobbies-font-weight-heavy);
                    color: var(--dobbies-font-primary-color);
                    max-width: 100%;
                }
                &__cta {
                    margin-top: 0;
                    display: block;
                    a,
                    .msc-cta__primary,
                    .msc-cta__secondary {
                        min-height: 29px;
                        max-height: 29px;
                        line-height: initial;
                        min-width: 215px;
                        border: 1px solid var(--dobbies-breadcrumb-icon-color);
                        font-size: 16px;
                        font-weight: var(--dobbies-font-weight-normal);
                        border-radius: 4px;
                        background-color: transparent;
                        color: var(--dobbies-font-primary-color);
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                        cursor: pointer;
                        padding: 0;
                        position: relative;
                        -webkit-transform: perspective(1px) translateZ(0);
                        transform: perspective(1px) translateZ(0);
                        position: relative;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        overflow: hidden;
                        @media (max-width: $msv-breakpoint-l) {
                            min-width: 173px;
                        }
                        @media (max-width: $msv-breakpoint-sm) and (min-width: $msv-breakpoint-mbl) {
                            min-width: 120px;
                        }
                        @media (max-width: $msv-breakpoint-mbl) {
                            min-width: 100%;
                            margin-left: 0;
                            margin-right: 0;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            z-index: -1;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: var(--dobbies-bg-color-secondary);
                            -webkit-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transform-origin: 0 50%;
                            transform-origin: 0 50%;
                            -webkit-transition-property: transform;
                            transition-property: transform;
                            -webkit-transition-duration: 0.3s;
                            transition-duration: 0.3s;
                            -webkit-transition-timing-function: ease-out;
                            transition-timing-function: ease-out;
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            color: var(--dobbies-font-primary-color);
                            background: var(--dobbies-bg-color);
                            text-decoration: none;
                            &:after {
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
    }

    &.category-banner.white-text {
        .ms-content-block__title,
        .ms-content-block__text {
            @media (max-width: $dobbies-container-max-width-s) {
                color: $dobbies-white;
            }
        }
    }

    &.no-image-categories {
        position: static;
        height: auto;
        min-height: auto;
        margin: 0;
        @media (max-width: $dobbies-container-max-width-s) {
            background: transparent;
        }
        .ms-content-block {
            &__details {
                position: relative;
                margin: 0;
                width: 100%;
                @media (max-width: $dobbies-container-max-width-s) {
                    margin-top: 0px;
                    top: 0px;
                    width: 100%;
                }
            }
            &__title {
                padding: 20px 0 10px 0;
                border-bottom: 1px solid #1e1e1e;
                text-align: center;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 30px;
                margin: 0;
                @media (max-width: $dobbies-container-max-width-tablet) {
                    font-size: 24px;
                }

                @media (max-width: $dobbies-container-max-width-m) {
                    margin-bottom: 20px;
                }

                @media (max-width: $dobbies-container-max-width-s) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.category-banner {
        @media (max-width: $dobbies-container-max-width-tablet) {
            min-height: 200px;
        }
	}
}
.two-column-layout {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0;
    @media screen and (min-width: 768px) and (max-width: 992px) {
        margin: 40px 0 20px;
    }

    @media (max-width: $dobbies-container-max-width-s) {
        display: block;
    }
    .ms-content-block {
        &__image {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @media (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        &__details {
            width: 40%;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: normal;
            justify-content: center;
            @media (max-width: $dobbies-container-max-width-s) {
                width: 100%;
                text-align: center;
                padding-left: 0;
            }
        }
        &__title {
            text-transform: uppercase;
            color: var(--dobbies-font-primary-color);
            font-weight: var(--dobbies-font-weight-bold);
            //font-size: 25px;
            margin-bottom: 20px;
            line-height: normal;
            @media screen and (min-width: 768px) and (max-width: 992px) {
                margin-top: 0;
            }

            @media (max-width: $dobbies-container-max-width-s) {
                margin-top: 20px;
            }
        }
        &__text {
            font-size: var(--dobbies-body-font-size-s);
            line-height: $dobbies-line-height-m;
            h4 {
                // color: var(--dobbies-heading-primary-color);
                font-weight: var(--dobbies-font-weight-bold);
                line-height: 24px;
                margin-bottom: 20px;
                @media (max-width: $msv-breakpoint-l) {
                    font-size: 18px;
                }
            }
            ul {
                padding-left: 20px;
                margin-bottom: 20px;
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    text-align: left;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:before {
                        @include dobbies-icon();
                        content: $dobbies-icon-arrow;
                        font-size: var(--dobbies-breadcrumb-icon);
                        transform: rotate(90deg);
                        color: var(--dobbies-breadcrumb-icon-color);
                        font-weight: var(--dobbies-font-weight-heavy);
                        font-size: var(--dobbies-body-font-size-xs);
                        margin: 3px 10px 5px -22px;
                        float: left;
                    }
                }
            }
            ol {
                padding-left: 20px;
                margin-bottom: 20px;
                li {
                    margin-bottom: 10px;
                    text-align: left;
                }
            }
            p {
                line-height: $dobbies-line-height-m;
                margin-bottom: 20px;
            }
        }
        &__cta {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            @media (max-width: $dobbies-container-max-width-s) {
                justify-content: center;
                padding: 0 25%;
                gap: 20px 0;
                text-align: center;
            }
            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                cursor: pointer;
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 50%;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
    &.imageplacement__right {
        .ms-content-block {
            &__image {
                order: 2;
            }
            &__details {
                order: 1;
                padding-left: 0;
                padding-right: 20px;
                @media (max-width: $dobbies-container-max-width-s) {
                    padding-right: 0;
                }
            }
        }
    }
    &.portrait {
        .ms-content-block {
            &__image,
            &__details {
                width: calc(50% - 10px);
                @media (max-width: $dobbies-container-max-width-s) {
                    width: 100%;
                }
            }
            &__cta {
                @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                    display: flex;
                    gap: 20px;
                    flex-wrap: wrap;
                }

                > a {
                    @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                        margin: 0;
                    }
                }
            }
        }
    }
    &.small-image {
        .ms-content-block {
            &__image {
                width: calc(33.33% - 10px);
                @media (max-width: $dobbies-container-max-width-s) {
                    width: 100%;
                }
            }
            &__details {
                width: calc(66.66% - 10px);
                @media (max-width: $dobbies-container-max-width-s) {
                    width: 100%;
                }
            }
            &__text {
                ul {
                    padding-left: 20px;
                    @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    li {
                        display: inline-block;
                        margin-right: 50px;
                        width: calc(50% - 30px);
                        &:nth-child(even) {
                            margin-right: 0;
                        }
                        @media (max-width: $dobbies-container-max-width-s) {
                            width: 100%;
                            margin-right: 0;
                        }
                    }
                }
            }

            &__cta {
                max-width: 65%;
                @media (max-width: $dobbies-container-max-width-s) {
                    max-width: 100%;
                }
            }
        }
    }
    &.store-column {
        margin-top: 30px;
        h1 {
            text-transform: uppercase;
            font-weight: $dobbies-font-weight-normal;
        }
        h4 {
            color: $dobbies-grey;
            font-size: $dobbies-font-size-m;
            font-weight: $dobbies-font-weight-bold;
            text-transform: uppercase;
        }

        .ms-content-block {
            &__link,
            &__details {
                width: calc(50% - 10px);
                @media (max-width: $dobbies-container-max-width-s) {
                    width: 100%;
                }
                .ms-content-block__image {
                    width: 100%;
                }
            }
            @media (max-width: $dobbies-container-max-width-s) {
                &__link {
                    order: 2;
                }
                &__details {
                    order: 1;
                }
            }
        }
        @media (max-width: $dobbies-container-max-width-s) {
            display: flex;
            .ms-content-block {
                &__details {
                    @media (max-width: $dobbies-container-max-width-s) {
                        text-align: left;
                        padding-left: 0;
                    }
                }
            }
        }
    }

    &.cta-button {
        .ms-content-block {
            &__cta {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;

                @media (max-width: $dobbies-container-max-width-s) {
                    justify-content: center;
                    padding: 0 5%;
                    padding-bottom: 1px;
                }

                > a {
                    background-color: transparent;
                    border: 1px solid $dobbies-grey;
                    color: var(--dobbies-font-primary-color);
                    border-radius: 4px;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    cursor: pointer;
                    position: relative;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    overflow: hidden;
                    padding: 5px 20px;
                    margin: 0px;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                    }
                    div {
                        margin: auto;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                    @media screen and (min-width: 768px) and (max-width: 992px) {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.six-column-layout {
    .row {
        justify-content: center;
        .col-12 {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 10px;
            margin-bottom: 20px;
            @media (max-width: $dobbies-container-max-width-s) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    .heading {
        text-transform: uppercase;
        color: var(--dobbies-font-primary-color);
        font-weight: var(--dobbies-font-weight-bold);
        border-bottom: solid 1px rgba(102, 102, 102, 0.5);
        //margin: 30px 10px;
        padding-bottom: 10px;
        line-height: normal;
        width: 100%;
        margin: 40px 10px 20px 10px;
        text-align: center;
        margin-top: 0;
        @media (max-width: $dobbies-container-max-width-s) {
            margin: 30px 0;
            text-align: center;
        }
        @media (max-width: $msv-breakpoint-l) {
            font-size: 20px;
        }
    }
    .ms-content-block {
        h1 {
            font-size: 25px;
            @media (max-width: $msv-breakpoint-l) {
                font-size: 20px;
            }
        }
        h2 {
            font-size: 20px;
            @media (max-width: $msv-breakpoint-l) {
                font-size: 18px;
            }
        }
        h3,
        h4,
        h5,
        h6 {
            font-size: 16px;
        }
        margin-right: 10px;
        &__image {
            img {
                margin: auto;
                height: auto;
            }
        }
        &__details {
            text-align: center;
            //margin-bottom: 30px;
        }
        &__title {
            text-transform: uppercase;
            color: $dobbies-grey;
            font-weight: var(--dobbies-font-weight-bold);
            // margin: 10px 0;
            line-height: 26px;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        &__text {
            font-size: var(--dobbies-body-font-size-s);
            line-height: $dobbies-line-height-m;
            margin-bottom: 20px;
            ul {
                padding-left: 25px;
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    text-align: left;
                    &:before {
                        @include dobbies-icon();
                        content: $dobbies-icon-arrow;
                        font-size: var(--dobbies-breadcrumb-icon);
                        transform: rotate(90deg);
                        color: var(--dobbies-breadcrumb-icon-color);
                        font-weight: var(--dobbies-font-weight-heavy);
                        font-size: var(--dobbies-body-font-size-xs);
                        margin: 3px 10px 5px -22px;
                        float: left;
                    }
                }
            }
            ol {
                padding-left: 16px;
                li {
                    margin-bottom: 10px;
                    text-align: left;
                }
            }
        }
        &__cta {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            justify-content: center;
            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                cursor: pointer;
                margin: 0;

                @media (max-width: $dobbies-container-max-width-tablet) {
                    flex: initial;
                    padding: 0;
                    margin: 0;
                }
            }
        }
        &__link {
            display: block;
        }
    }
    .row {
        @media (min-width: $dobbies-container-max-width-s) {
            margin: 0 -10px;
        }
        .col-auto {
            @media (min-width: $dobbies-container-max-width-s) {
                padding: 0 10px;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }
    }
    .imageplacement__left {
        .ms-content-block {
            &__details {
                text-align: center;
            }
        }
    }
    &.six-column-layout {
        margin-top: 40px;
        .row {
            .col-12 {
                flex: 0 0 16.66667%;
                max-width: 16.66667%;
                padding: 0 10px;
                @media (max-width: $dobbies-container-max-width-l) {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding: 0;
                }
            }
        }
    }
    &.two-column-container {
        margin-top: 40px;
        .row {
            .col-12 {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 10px;
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
                }
            }
        }
    }
    &.cta-button {
        .ms-content-block {
            &__cta {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                justify-content: center;

                > a {
                    background-color: transparent;
                    border: 1px solid $dobbies-grey;
                    color: var(--dobbies-font-primary-color);
                    border-radius: 4px;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    cursor: pointer;
                    position: relative;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    overflow: hidden;
                    padding: 5px 20px;
                    margin: 0px;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                    }
                    div {
                        margin: auto;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                    @media screen and (min-width: 768px) and (max-width: 992px) {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
.three-column-layout {
    margin-top: 40px;
    .row {
        justify-content: center;
        .col-12 {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 10px;
            //margin-bottom: 40px;
            @media (max-width: $dobbies-container-max-width-s) {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 0;
            }
        }
    }
    .heading {
        text-transform: uppercase;
        color: var(--dobbies-font-primary-color);
        font-weight: var(--dobbies-font-weight-bold);
        border-bottom: solid 1px rgba(102, 102, 102, 0.5);
        //margin: 30px 10px;
        padding-bottom: 10px;
        line-height: normal;
        width: 100%;
        margin: 0 10px 20px 10px;
        text-align: center;
        @media (max-width: $dobbies-container-max-width-s) {
            margin: 0 0px 20px 0px;
            text-align: center;
        }
    }
    .ms-content-block {
        h1 {
            font-size: 25px;
            @media (max-width: $msv-breakpoint-l) {
                font-size: 20px;
            }
        }
        h2 {
            font-size: 20px;
            @media (max-width: $msv-breakpoint-l) {
                font-size: 18px;
            }
        }
        h3,
        h4,
        h5,
        h6 {
            font-size: 16px;
        }
        margin-bottom: 40px;
        &__image {
            img {
                margin: auto;
                height: auto;
            }
        }
        &__details {
            text-align: center;
            //margin-bottom: 30px;
        }
        &__title {
            text-transform: uppercase;
            color: $dobbies-grey;
            font-weight: var(--dobbies-font-weight-bold);
            // margin: 10px 0;
            line-height: normal;
            margin-bottom: 20px;
            margin-top: 20px;

            @media screen and (max-width: $msv-breakpoint-xl) and (min-width: $msv-breakpoint-l) {
                margin-top: 10px;
            }
        }
        &__text {
            font-size: var(--dobbies-body-font-size-s);
            line-height: $dobbies-line-height-m;
            margin-bottom: 20px;

            @media screen and (max-width: $msv-breakpoint-xl) and (min-width: $msv-breakpoint-l) {
                margin-bottom: 10px;
            }

            ul {
                padding-left: 25px;
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    text-align: left;
                    &:before {
                        @include dobbies-icon();
                        content: $dobbies-icon-arrow;
                        font-size: var(--dobbies-breadcrumb-icon);
                        transform: rotate(90deg);
                        color: var(--dobbies-breadcrumb-icon-color);
                        font-weight: var(--dobbies-font-weight-heavy);
                        font-size: var(--dobbies-body-font-size-xs);
                        margin: 3px 10px 5px -22px;
                        float: left;
                    }
                }
            }
            ol {
                padding-left: 16px;
                li {
                    margin-bottom: 10px;
                    text-align: left;
                }
            }

            p {
                @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                    margin-bottom: 20px;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
        &__cta {
            @media (max-width: $dobbies-container-max-width-tablet) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 0 5%;
                gap: 20px;
            }

            .msc-cta__primary,
            .msc-cta__secondary {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-normal);
                display: inline-block;
                cursor: pointer;

                @media (max-width: $dobbies-container-max-width-tablet) {
                    padding: 0;
                    margin: 0;
                }
            }

            > a {
                margin: 0 10px;
            }
        }
        &__link {
            display: block;
        }
    }
    .row {
        @media (min-width: $dobbies-container-max-width-s) {
            margin: 0 -10px;
        }
        .col-auto {
            @media (min-width: $dobbies-container-max-width-s) {
                padding: 0 10px;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }
    }
    .imageplacement__left {
        .ms-content-block {
            &__details {
                text-align: center;
            }
        }
    }
    &.six-column-layout {
        margin-top: 40px;
        .row {
            .col-12 {
                flex: 0 0 16.66667%;
                max-width: 16.66667%;
                padding: 0 10px;
                @media (max-width: $dobbies-container-max-width-l) {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding: 0;
                }
            }
        }
    }
    &.two-column-container {
        margin-top: 40px;
        .row {
            .col-12 {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 10px;
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
                }
            }
        }
    }

    &.cta-button {
        .ms-content-block {
            &__cta {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                justify-content: center;

                @media (max-width: $dobbies-container-max-width-s) {
                    padding: 0 5%;
                    padding-bottom: 1px;
                }

                > a {
                    background-color: transparent;
                    border: 1px solid $dobbies-grey;
                    color: var(--dobbies-font-primary-color);
                    border-radius: 4px;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    cursor: pointer;
                    position: relative;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    overflow: hidden;
                    padding: 5px 20px;
                    margin: 0px;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                    }
                    div {
                        margin: auto;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                    @media screen and (min-width: 768px) and (max-width: 992px) {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.two-column-container {
    .row {
        justify-content: center;
        .col-12 {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 10px;
            //margin-bottom: 40px;
            @media (max-width: $dobbies-container-max-width-s) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    .heading {
        text-transform: uppercase;
        color: var(--dobbies-font-primary-color);
        font-weight: var(--dobbies-font-weight-bold);
        border-bottom: solid 1px rgba(102, 102, 102, 0.5);
        //margin: 30px 10px;
        padding-bottom: 10px;
        line-height: normal;
        width: 100%;
        margin: 40px 10px 20px 10px;
        text-align: center;
        @media (max-width: $dobbies-container-max-width-s) {
            margin: 30px 0;
            text-align: center;
        }
    }
    .ms-content-block {
        margin-bottom: 40px;
        &__image {
            img {
                margin: auto;
                height: auto;
            }
        }
        &__details {
            text-align: center;
            //margin-bottom: 30px;
        }
        &__title {
            text-transform: uppercase;
            color: $dobbies-grey;
            font-weight: var(--dobbies-font-weight-bold);
            // margin: 10px 0;
            line-height: 26px;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        &__text {
            font-size: var(--dobbies-body-font-size-s);
            line-height: $dobbies-line-height-m;
            margin-bottom: 0px;
            p {
                margin-top: 20px;
                margin-bottom: 0;
            }
            ul {
                padding-left: 25px;
                margin-bottom: 0;
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    text-align: left;
                    &:before {
                        @include dobbies-icon();
                        content: $dobbies-icon-arrow;
                        font-size: var(--dobbies-breadcrumb-icon);
                        transform: rotate(90deg);
                        color: var(--dobbies-breadcrumb-icon-color);
                        font-weight: var(--dobbies-font-weight-heavy);
                        font-size: var(--dobbies-body-font-size-xs);
                        margin: 3px 10px 5px -22px;
                        float: left;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            ol {
                padding-left: 16px;
                li {
                    margin-bottom: 10px;
                    text-align: left;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &__cta {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 20px;
            @media (max-width: $dobbies-container-max-width-tablet) {
                padding: 0 5%;
            }
            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                cursor: pointer;

                @media (max-width: $dobbies-container-max-width-tablet) {
                    flex: initial;
                }
            }
        }
        &__link {
            display: block;
        }
    }
    .row {
        @media (min-width: $dobbies-container-max-width-s) {
            margin: 0 -10px;
        }
        .col-auto {
            @media (min-width: $dobbies-container-max-width-s) {
                padding: 0 10px;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }
    }
    .imageplacement__left {
        .ms-content-block {
            &__details {
                text-align: center;
            }
        }
    }
    &.six-column-layout {
        margin-top: 40px;
        .row {
            .col-12 {
                flex: 0 0 16.66667%;
                max-width: 16.66667%;
                padding: 0 10px;
                @media (max-width: $dobbies-container-max-width-l) {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding: 0;
                }
            }
        }
    }
    &.two-column-container {
        margin-top: 40px;
        .row {
            .col-12 {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 10px;
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
                }
            }
        }
    }

    &.cta-button {
        .ms-content-block {
            &__cta {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                justify-content: center;

                > a {
                    background-color: transparent;
                    border: 1px solid $dobbies-grey;
                    color: var(--dobbies-font-primary-color);
                    border-radius: 4px;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    cursor: pointer;
                    position: relative;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    overflow: hidden;
                    padding: 5px 20px;
                    margin: 0px;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                    }
                    div {
                        margin: auto;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                    @media screen and (min-width: 768px) and (max-width: 992px) {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.two-column-text {
    margin-bottom: 40px;
    line-height: normal;
    margin-top: 40px;
    .heading {
        text-transform: uppercase;
        color: var(--dobbies-font-primary-color);
        font-weight: var(--dobbies-font-weight-bold);
        border-bottom: solid 1px rgba(102, 102, 102, 0.5);
        line-height: normal;
        margin: 0 10px 20px;
        padding-bottom: 10px;
        width: 100%;
        text-align: center;
        @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
            margin: 0 10px 20px;
        }

        @media (max-width: $msv-breakpoint-sm) {
            margin: 0;
            margin-bottom: 20px;
        }
    }
    .ms-content-block {
        // &__details {
        //     //padding-top: 20px;
        // }
        &__title {
            text-transform: uppercase;
            color: var(--dobbies-font-primary-color);
            line-height: normal;
            margin-bottom: 20px;
            border-bottom: solid 1px rgba(102, 102, 102, 0.5);
            font-weight: var(--dobbies-font-weight-bold);
            padding-bottom: 10px;
            //margin-left: 20px;
        }
        &__text {
            font-size: var(--dobbies-body-font-size-s);
            line-height: $dobbies-line-height-m;
            //margin-bottom: 20px;
            ul {
                padding-left: 20px;
                @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                    margin-bottom: 0;
                }
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    text-align: left;
                    &:last-child {
                        @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                            margin: 0;
                        }
                    }
                    &:before {
                        @include dobbies-icon();
                        content: $dobbies-icon-arrow;
                        font-size: var(--dobbies-breadcrumb-icon);
                        transform: rotate(90deg);
                        color: var(--dobbies-breadcrumb-icon-color);
                        font-weight: var(--dobbies-font-weight-heavy);
                        font-size: var(--dobbies-body-font-size-xs);
                        margin: 3px 10px 5px -22px;
                        float: left;
                    }
                }
            }
        }
        &__cta {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            justify-content: start;
            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                cursor: pointer;

                @media (max-width: $dobbies-container-max-width-tablet) {
                    flex: initial;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
    .row {
        @media (min-width: $dobbies-container-max-width-s) {
            margin: 0 -10px;
        }
        .col-12 {
            @media (min-width: $dobbies-container-max-width-s) {
                padding: 0 10px;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }
    }
    ul {
        padding-left: 20px;
        margin-bottom: 0;
        li {
            list-style: none;
            margin-bottom: 10px;
            text-align: left;
            &:last-child {
                margin-bottom: 0;
            }
            &:before {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                font-size: var(--dobbies-breadcrumb-icon);
                transform: rotate(90deg);
                color: var(--dobbies-breadcrumb-icon-color);
                font-weight: var(--dobbies-font-weight-heavy);
                font-size: var(--dobbies-body-font-size-xs);
                margin: 3px 10px 5px -22px;
                float: left;
            }
        }
    }

    ol {
        padding-left: 16px;
        @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
            margin-bottom: 20px;
        }
        li {
            margin-bottom: 10px;
            text-align: left;
        }
    }
    .grey-bg {
        background-color: #f8f8f8;
        padding: 20px;

        @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
            margin-top: 0;
            margin-bottom: 20px;
        }

        @media screen and (max-width: $msv-breakpoint-sm) {
            margin-top: 40px;
            ol {
                margin-bottom: 0;
            }
        }

        .ms-content-block__cta {
            margin-top: 20px;
        }
    }
    &.grey-bg {
        background-color: #f8f8f8;
        padding: 20px;
        border: 1px solid $dobbies-grey;
        @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
            margin-bottom: 40px;
        }
        //margin: 40px 0;
        .heading {
            margin: 0 20px 20px 10px;
            text-align: left;
        }
    }
    p {
        line-height: $dobbies-line-height-m;
        margin-bottom: 20px;
        text-align: left;
    }

    &.underlined {
        @media (max-width: $dobbies-container-max-width-s) {
            margin-bottom: 0px;
        }
        .ms-content-block {
            @media (max-width: $dobbies-container-max-width-s) {
                margin-bottom: 40px;
            }
            &__cta {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;

                @media (max-width: $dobbies-container-max-width-s) {
                    justify-content: start;
                    padding: 0;
                }

                a {
                    margin: 0;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                    }
                }
            }
        }
    }

    &.cta-button {
        .ms-content-block {
            &__cta {
                > a {
                    text-align: center;
                    font-size: $msv-font-size-m;
                    font-weight: var(--dobbies-font-weight-bold);
                    @include secondary-button-bordered;
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    text-decoration: none;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    padding: 5px 20px;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                    }
                    div {
                        display: block;
                        width: 100%;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }

    &:not(.grey-bg):not(.underlined) {
        .ms-content-block {
            &:not(.grey-bg) {
                margin-top: 20px;
                @media screen and (max-width: $dobbies-container-max-width-tablet) {
                    margin-top: 0px;
                }
                ol {
                    margin-bottom: 0;
                }

                .ms-content-block__cta {
                    margin-top: 20px;
                }
            }
        }

        .col-12:first-child {
            .ms-content-block {
                @media screen and (max-width: $msv-breakpoint-mbl-sm) {
                    margin-bottom: 20px;
                }
            } 
        }
    }
}
.full-width-text-block {
    margin-bottom: 20px;
    line-height: normal;
    margin-top: 20px;
    @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .heading {
        text-transform: uppercase;
        color: var(--dobbies-font-primary-color);
        font-weight: var(--dobbies-font-weight-bold);
        border-bottom: solid 1px rgba(102, 102, 102, 0.5);
        line-height: normal;
        margin: 40px 10px 20px 10px;
        padding-bottom: 10px;
        width: 100%;
        text-align: center;
    }
    .ms-content-block {
        //margin-bottom: 40px;
        // &__details {
        //     //padding-top: 20px;
        // }
        &__title {
            text-transform: uppercase;
            color: var(--dobbies-font-primary-color);
            line-height: normal;
            margin-bottom: 20px;
            font-weight: var(--dobbies-font-weight-bold);
            //margin-left: 20px;
        }
        &__text {
            font-size: var(--dobbies-body-font-size-s);
            line-height: $dobbies-line-height-m;
            margin-bottom: 20px;
            ul {
                padding-left: 20px;
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    text-align: left;
                    &:before {
                        @include dobbies-icon();
                        content: $dobbies-icon-arrow;
                        font-size: var(--dobbies-breadcrumb-icon);
                        transform: rotate(90deg);
                        color: var(--dobbies-breadcrumb-icon-color);
                        font-weight: var(--dobbies-font-weight-heavy);
                        font-size: var(--dobbies-body-font-size-xs);
                        margin: 3px 10px 5px -22px;
                        float: left;
                    }
                }
            }

            p {
                margin-bottom: 20px;
            }
        }
        &__cta {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                cursor: pointer;
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: initial;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
    .row {
        @media (min-width: $dobbies-container-max-width-s) {
            margin: 0 -10px;
        }
        .col-12 {
            @media (min-width: $dobbies-container-max-width-s) {
                padding: 0 10px;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }
    }
    ul {
        padding-left: 20px;
        margin-bottom: 20px;
        li {
            list-style: none;
            margin-bottom: 10px;
            text-align: left;
            line-height: 21px;
            &:before {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                font-size: var(--dobbies-breadcrumb-icon);
                transform: rotate(90deg);
                color: var(--dobbies-breadcrumb-icon-color);
                font-weight: var(--dobbies-font-weight-heavy);
                font-size: var(--dobbies-body-font-size-xs);
                margin: 5px 10px 5px -22px;
                float: left;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: uppercase;
        color: var(--dobbies-font-primary-color);
        line-height: normal;
        margin-bottom: 20px;
        font-weight: var(--dobbies-font-weight-bold);
    }
    ol {
        padding-left: 16px;
        margin-bottom: 20px;
        li {
            margin-bottom: 10px;
            text-align: left;
        }
    }
    &.grey-bg,
    .grey-bg {
        background-color: #f8f8f8;
        padding: 20px;
    }
    &.grey-bg {
        padding: 20px;
        margin: 40px 0;
    }

    &.ms-text-block {
        p {
            // display: flex; comment out while doing static page testing (kids)
            flex-wrap: wrap;
            gap: 20px;
            line-height: 22px;
            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                cursor: pointer;
                &:hover {
                    color: #5a9e33;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: initial;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    &.cta-button {
        .ms-content-block {
            &__cta {
                > a {
                    text-align: center;
                    font-size: $msv-font-size-m;
                    font-weight: var(--dobbies-font-weight-bold);
                    @include secondary-button-bordered;
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    text-decoration: none;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    padding: 5px 20px;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                    }
                    div {
                        display: block;
                        width: 100%;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
        p {
            a {
                background-color: transparent;
                border: 1px solid $dobbies-grey;
                color: var(--dobbies-font-primary-color);
                border-radius: 4px;
                text-decoration: none;
                line-height: initial;
                font-size: 16px;
                font-weight: var(--dobbies-font-weight-bold);
                cursor: pointer;
                position: relative;
                transform: perspective(1px) translateZ(0);
                transition-duration: 0.3s;
                overflow: hidden;
                padding: 5px 20px;
                margin: 0;
                height: auto;
                width: auto;
                line-height: normal;
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: initial;
                    margin: 0;
                }
                div {
                    margin: auto;
                }
                &:after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: $dobbies-yellow;
                    transform: scaleX(0);
                    transform-origin: 0 50%;
                    transition-property: transform;
                    transition-duration: 0.3s;
                    transition-timing-function: ease-out;
                }
                &:hover,
                &:focus,
                &:active {
                    color: var(--dobbies-font-primary-color);
                    background: var(--dobbies-bg-color);
                    text-decoration: none;
                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    &.underlined {
        margin-top: 40px;
        h1,
        h2,
        h3 h4,
        h5,
        h6 {
            border-bottom: solid 1px rgba(102, 102, 102, 0.5);
            padding-bottom: 10px;
            text-align: center;
        }
    }

    &.center {
        h1,
        h2,
        h3 h4,
        h5,
        h6,
        p {
            text-align: center;
        }
    }

    &.last {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
.quick-facts {
    margin-bottom: 40px;
    line-height: normal;
    margin-top: 40px;
    .ms-text-block {
        h1,
        h2,
        h3 h4,
        h5,
        h6 {
            text-transform: uppercase;
            color: var(--dobbies-font-primary-color);
            line-height: normal;
            margin-bottom: 20px;
            font-weight: var(--dobbies-font-weight-bold);
        }
        p {
            font-size: var(--dobbies-body-font-size-s);
            margin-bottom: 20px;
            @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .col-12:first-child {
        .ms-text-block {
            padding-right: 10px;
        }
    }
    .col-12:nth-child(2) {
        .ms-text-block {
            border: solid 1px $dobbies-grey;
            margin-left: 10px;
            @media (max-width: $msv-breakpoint-sm) {
                margin: 20px 0;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-transform: uppercase;
                background: $dobbies-grey;
                color: var(--dobbies-font-secondary-color);

                font-weight: var(--dobbies-font-weight-bold);
                padding: 20px 20px 20px 20px;
                margin-bottom: 0;
            }
            ul {
                padding: 20px 20px;
                margin: 0;
                list-style: none;
                li {
                    overflow: hidden;
                    margin: 10px 0;
                    padding-left: 20px;
                    text-align: left;
                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:before {
                        @include dobbies-icon();
                        content: $dobbies-icon-arrow;
                        font-size: var(--dobbies-breadcrumb-icon);
                        transform: rotate(90deg);
                        color: var(--dobbies-breadcrumb-icon-color);
                        font-weight: var(--dobbies-font-weight-heavy);
                        font-size: var(--dobbies-body-font-size-xs);
                        margin: 3px 10px 5px -22px;
                        float: left;
                    }
                }
            }
        }
    }

    .ms-text-block {
        p {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                cursor: pointer;
                &:hover {
                    color: #5a9e33;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: initial;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    &.cta-button {
        .ms-text-block {
            p {
                a {
                    background-color: transparent;
                    border: 1px solid $dobbies-grey;
                    color: var(--dobbies-font-primary-color);
                    border-radius: 4px;
                    text-decoration: none;
                    line-height: initial;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    cursor: pointer;
                    position: relative;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    overflow: hidden;
                    padding: 5px 20px;
                    margin: 0;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                        margin: 0;
                    }
                    div {
                        margin: auto;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }
}
.ms-accordion {
    &.accordion-panel {
        margin-top: 40px;
        margin-bottom: 40px;
        .drawer__button {
            padding: 0px 30px 10px 0;
            height: auto;
            border-bottom: solid 1px $dobbies-light-grey;
            color: var(--dobbies-font-primary-color);
            font-weight: var(--dobbies-font-weight-bold);
            font-size: var(--dobbies-body-font-size-s);
            position: relative;
            text-align: left;
            &:after {
                display: none;
            }
            &:before {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                transform: rotate(180deg);
                color: var(--dobbies-breadcrumb-icon-color);
                font-weight: var(--dobbies-font-weight-heavy);
                font-size: var(--dobbies-body-font-size-xs);
                position: absolute;
                right: 0px;
                top: 8px;
            }
            &[aria-expanded='true'] {
                &:before {
                    transform: rotate(0deg);
                }
            }
        }
        .ms-accordion-item-content {
            border-bottom: solid 1px $dobbies-light-grey;
            .ms-text-block {
                margin-top: 0;
                p {
                    line-height: $dobbies-line-height-m;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                ul {
                    padding: 10px 16px;
                    margin: 0;
                    list-style: none;
                    li {
                        overflow: hidden;
                        margin: 10px 0;
                        padding-left: 20px;
                        text-align: left;
                        &:before {
                            @include dobbies-icon();
                            content: $dobbies-icon-arrow;
                            font-size: var(--dobbies-breadcrumb-icon);
                            transform: rotate(90deg);
                            color: var(--dobbies-breadcrumb-icon-color);
                            font-weight: var(--dobbies-font-weight-heavy);
                            font-size: var(--dobbies-body-font-size-xs);
                            margin: 3px 10px 5px -22px;
                            float: left;
                        }
                    }
                }
                ol {
                    padding-left: 16px;
                    li {
                        margin-bottom: 10px;
                        text-align: left;
                    }
                }
            }
        }
        .ms-accordion-header-section {
            display: none;
        }
    }
    &-item {
        &__drawer {
            border-top: 0;
        }
    }
}
.accordion-panel {
    &.fluid {
        margin: 40px 0;
    }

    .col-auto {
        @media (max-width: $msv-breakpoint-sm) {
            flex: 1;
        }
    }

    div[class^='col'] {
        @media (max-width: $dobbies-container-max-width-tablet) {
            padding: 0 15px;
        }

        @media (max-width: $msv-breakpoint-sm) { 
            padding: 0 10px;
        }
    }

    @media (max-width: $dobbies-container-max-width-tablet) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @media (max-width: $msv-breakpoint-sm) {
        margin-top: 20px;
    }

    .ms-accordion {
        // margin-top: 40px;
        // @media (max-width: $msv-breakpoint-sm) {
        //     margin-top: 0;
        // }
        .drawer__button {
            padding: 0px 20px 10px 0;
            height: auto;
            border-bottom: solid 1px $dobbies-light-grey;
            color: var(--dobbies-font-primary-color);
            font-weight: var(--dobbies-font-weight-bold);
            font-size: var(--dobbies-body-font-size-s);
            position: relative;
            text-align: left;
            margin-top: 10px;
            @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                margin-top: 0;
            }
            &:after {
                display: none;
            }
            &:before {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                transform: rotate(180deg);
                color: var(--dobbies-breadcrumb-icon-color);
                font-weight: var(--dobbies-font-weight-heavy);
                font-size: var(--dobbies-body-font-size-xs);
                position: absolute;
                right: 0px;
                top: 8px;
            }
            &[aria-expanded='true'] {
                &:before {
                    transform: rotate(0deg);
                }
            }
        }
        .ms-accordion-item-content {
            border-bottom: solid 1px $dobbies-light-grey;
            .ms-text-block {
                margin-top: 0;
                p {
                    line-height: $dobbies-line-height-m;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                ul {
                    padding: 10px 16px;
                    margin: 0;
                    list-style: none;
                    li {
                        overflow: hidden;
                        margin: 10px 0;
                        padding-left: 20px;
                        text-align: left;
                        &:before {
                            @include dobbies-icon();
                            content: $dobbies-icon-arrow;
                            font-size: var(--dobbies-breadcrumb-icon);
                            transform: rotate(90deg);
                            color: var(--dobbies-breadcrumb-icon-color);
                            font-weight: var(--dobbies-font-weight-heavy);
                            font-size: var(--dobbies-body-font-size-xs);
                            margin: 3px 10px 5px -22px;
                            float: left;
                        }
                    }
                }
                ol {
                    padding-left: 16px;
                    li {
                        margin-bottom: 10px;
                        text-align: left;
                    }
                }
            }
        }
        .ms-accordion-header-section {
            display: none;
        }
    }
    &-item {
        &__drawer {
            border-top: 0;
        }
    }
    .col-12:first-child {
        padding-right: 10px;
        @media (max-width: $dobbies-container-max-width-s) {
            padding-right: 0;
        }
    }
    .col-12:last-child {
        padding-left: 10px;
        @media (max-width: $dobbies-container-max-width-s) {
            padding-left: 0;
            margin-top: 0px;
        }
    }
    &.default-container-3-slots {
        margin-bottom: 30px;
        .heading {
            text-transform: uppercase;
            color: $dobbies-grey;
            font-weight: var(--dobbies-font-weight-normal);
            border-bottom: solid 1px rgba(102, 102, 102, 0.5);
            font-size: var(--dobbies-tile-heading-s);
            margin: 15px 0 5px;
            padding-bottom: 10px;
            line-height: 36px;
            width: 100%;
        }
        .ms-accordion {
            margin-bottom: 0;
            .drawer {
                &__button {
                    color: $dobbies-grey;
                    font-size: var(--dobbies-body-font-size-m);
                    font-weight: var(--dobbies-font-weight-bold);
                    float: left;
                    width: auto;
                    clear: both;
                    border: 0;
                    padding: 0 30px 5px 0;
                }
                .collapse,
                .collapsing {
                    overflow: hidden;
                    width: 100%;
                }
            }
            .ms-accordion-item-content {
                border: 0;
                padding: 5px 0;
            }
        }
        @media (min-width: $dobbies-container-max-width-m) {
            .col-auto {
                &:nth-child(1) {
                    padding-right: 16px;
                }
                &:nth-child(2) {
                    padding: 0 8px;
                }
                &:nth-child(3) {
                    padding-left: 16px;
                }
            }
        }
    }
}
.listing-style {
    margin-bottom: 20px;
    margin-top: 40px;
    .heading {
        text-transform: uppercase;
        color: var(--dobbies-font-primary-color);
        font-weight: var(--dobbies-font-weight-bold);
        border-bottom: solid 1px rgba(102, 102, 102, 0.5);
        line-height: 36px;
        padding-bottom: 10px;
        width: 100%;
        margin-bottom: 0;
        @media (max-width: $msv-breakpoint-sm) {
            padding-bottom: 10px;
        }
    }
    .ms-text-block {
        margin-top: 20px;
        p {
            line-height: $dobbies-line-height-m;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 0;
            li {
                overflow: hidden;
                margin: 0 0 20px 0;
                padding-left: 20px;
                text-align: left;
                display: inline-block;
                width: calc(33.33% - 3px);
                @media (max-width: $dobbies-container-max-width-s) {
                    width: calc(50% - 2px);
                    float: left;
                }

                a {
                    color: var(--dobbies-font-primary-color);
                    font-weight: var(--dobbies-font-weight-bold);
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &:before {
                    @include dobbies-icon();
                    content: $dobbies-icon-arrow;
                    font-size: var(--dobbies-breadcrumb-icon);
                    transform: rotate(90deg);
                    color: var(--dobbies-breadcrumb-icon-color);
                    font-weight: var(--dobbies-font-weight-heavy);
                    font-size: var(--dobbies-body-font-size-xs);
                    margin: 3px 10px 5px -22px;
                    float: left;
                }
            }
        }
        ol {
            padding-left: 16px;
            li {
                margin-bottom: 10px;
                text-align: left;
                a {
                    color: var(--dobbies-font-primary-color);
                    font-weight: var(--dobbies-font-weight-bold);
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
.carousel-container {
    background-color: $dobbies-dark-blue;
    margin: 40px 0;
    .dobbies-top-picks-carousel {
        border: 0;
        &__heading {
            font-family: $dobbies-secondary-font-family;
            color: var(--dobbies-club-primary-color);
            font-size: 25px;
            font-weight: var(--dobbies-font-weight-bold);
            line-height: initial;
            margin: 20px 0;
            text-transform: uppercase;
            @media (max-width: $dobbies-container-max-width-m) {
                font-size: 75px;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                font-size: 65px;
            }
        }
        &__item {
            &__heading {
                font-size: var(--dobbies-body-font-size-ml);
                color: var(--dobbies-club-primary-color);
                margin-bottom: 10px;
                text-transform: uppercase;
            }
            &__paragraph {
                font-size: var(--dobbies-body-font-size-l);
                color: var(--dobbies-font-secondary-color);
                line-height: 20px;
                margin-bottom: 10px;
            }
            &__link {
                font-size: var(--dobbies-body-font-size-s);
                color: var(--dobbies-font-secondary-color);
                font-weight: var(--dobbies-font-weight-normal);
                margin-top: 10px;
                cursor: pointer;
            }
        }
        .msc-ss-carousel {
            @media screen and (min-width: $dobbies-container-max-width-l) {
                padding: 0;
            }
            &__flipper {
                @media screen and (min-width: $dobbies-container-max-width-l) {
                    left: -20px;
                    &--next {
                        right: -20px;
                        left: initial;
                    }
                }
                :before {
                    color: var(--dobbies-club-primary-color);
                }
            }
        }
    }
    .ms-text-block {
        text-align: center;
        padding: 20px;
        margin-top: -50px;
        a {
            color: var(--dobbies-font-secondary-color);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.content-with-image {
    margin-top: 40px;
    margin-bottom: 40px;
    .row {
        align-items: center;
    }
    .ms-content-block {
        &__details {
            padding: 0;
            display: block;
            position: relative;
            height: auto;
            //margin: 20px 0 40px;
            width: 100%;
            .ms-content-block__cta {
                display: flex;
                flex-wrap: wrap;
                justify-content: start;
                gap: 20px;
                max-width: 70%;
                @media (max-width: $dobbies-container-max-width-s) {
                    max-width: 100%;
                }
                a {
                    font-size: 14px;
                    text-decoration: underline;
                    font-weight: var(--dobbies-font-weight-bold);
                    display: inline-block;
                    cursor: pointer;

                    @media (max-width: $dobbies-container-max-width-tablet) {
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
        &__title {
            text-transform: uppercase;
            color: $dobbies-grey;
            font-weight: var(--dobbies-font-weight-bold);
            border-bottom: solid 1px rgba(102, 102, 102, 0.5);
            padding-bottom: 10px;
            margin-bottom: 20px;
            line-height: normal;
        }
        &__text {
            font-size: var(--dobbies-body-font-size-s);
            line-height: $dobbies-line-height-m;
            > p {
                line-height: $dobbies-line-height-m;
                margin-bottom: 20px;
                strong {
                    font-weight: 500;
                }
            }
        }
    }
    .col-12:nth-child(2) {
        .ms-content-block {
            text-align: center;
            // margin-top: 40px;
            img {
                margin: auto;
                height: auto;
                @media (max-width: $dobbies-container-max-width-s) {
                    margin-top: 40px;
                }
            }
            &__title {
                display: none;
            }
            &__text {
                margin-top: 10px;
            }
        }
    }

    &.cta-button {
        .ms-content-block {
            &__cta {
                @media (max-width: $dobbies-container-max-width-s) {
                    justify-content: start;
                    padding: 0;
                }
                > a {
                    text-align: center;
                    font-size: $msv-font-size-m;
                    font-weight: var(--dobbies-font-weight-bold);
                    @include secondary-button-bordered;
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    text-decoration: none;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    padding: 5px 20px;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                    }
                    div {
                        display: block;
                        width: 100%;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }
}
.video-container {
    margin-top: 40px;
    margin-bottom: 40px;
    .col-12 {
        max-width: 952px;
        margin: auto;
        text-align: center;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6a {
        text-transform: uppercase;
        color: $dobbies-grey;
        font-weight: var(--dobbies-font-weight-bold);
        line-height: normal;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .ms-iframe {
        // margin-top: 40px;
        // margin-bottom: 40px;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            border: 0;
            width: 100%;
            height: 100%;
        }
    }
    .ms-text-block {
        p {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: center;
            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                cursor: pointer;
                &:hover {
                    color: #5a9e33;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: initial;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    &.cta-button {
        .ms-text-block {
            p {
                a {
                    background-color: transparent;
                    border: 1px solid $dobbies-grey;
                    color: var(--dobbies-font-primary-color);
                    border-radius: 4px;
                    text-decoration: none;
                    line-height: initial;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    cursor: pointer;
                    position: relative;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    overflow: hidden;
                    padding: 5px 20px;
                    margin: 0;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                        margin: 0;
                    }
                    div {
                        margin: auto;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }
}
.promo-banner {
    margin-bottom: 30px;
    text-align: center;
    .ms-content-block {
        &__image {
            img {
                margin: 0 auto 20px;
                width: 100%;
                height: auto;
            }
        }
        &__details {
            p {
                line-height: $dobbies-line-height-m;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__text {
            p {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: center;
                a {
                    font-size: 14px;
                    text-decoration: underline;
                    font-weight: var(--dobbies-font-weight-bold);
                    display: inline-block;
                    cursor: pointer;
                    &:hover {
                        color: #5a9e33;
                    }
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }

    &.cta-button {
        .ms-content-block__text {
            p {
                a {
                    background-color: transparent;
                    border: 1px solid $dobbies-grey;
                    color: var(--dobbies-font-primary-color);
                    border-radius: 4px;
                    text-decoration: none;
                    line-height: initial;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    cursor: pointer;
                    position: relative;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    overflow: hidden;
                    padding: 5px 20px;
                    margin: 0;
                    height: auto;
                    width: auto;
                    line-height: normal;
                    @media (max-width: $dobbies-container-max-width-s) {
                        flex: initial;
                        margin: 0;
                    }
                    div {
                        margin: auto;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-yellow;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }
}
.text-with-link {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $dobbies-grey;
    border-bottom: 1px solid $dobbies-grey;
    margin-bottom: 40px;
    margin-top: 40px;
    min-height: auto;
    .ms-content-block {
        &__details {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-top: 0;
            margin-left: auto;
            margin-right: auto;
            @media screen and (max-width: $dobbies-container-max-width-m) {
                display: block;
                text-align: center;
            }
        }
        &__text {
            margin-right: 20px;
            margin-top: 0;
            font-size: var(--dobbies-body-font-size-ml);
            font-weight: var(--dobbies-font-weight-bold);
            text-transform: uppercase;
            @media screen and (max-width: $dobbies-container-max-width-m) {
                margin-right: 0;
                margin-bottom: 15px;
                font-size: 14px;
            }

            p {
                margin-bottom: 0;
            }
        }
        &__title {
            margin-right: 20px;
            margin-bottom: 0px;
            text-transform: uppercase;
            @media screen and (max-width: $dobbies-container-max-width-m) {
                margin-bottom: 20px;
            }

            @media screen and (max-width: $dobbies-container-max-width-s) {
                margin-right: 0;
            }
        }
        &__cta {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;

            a {
                font-size: 14px;
                text-decoration: underline;
                font-weight: var(--dobbies-font-weight-bold);
                display: inline-block;
                cursor: pointer;

                @media (max-width: $dobbies-container-max-width-tablet) {
                    padding: 0;
                    margin: 0;
                }
            }
        }
        @media (max-width: $msv-breakpoint-mbl-sm) {
            .ms-content-block {
                &__details {
                    flex-direction: column;
                }
                &__text {
                    font-size: var(--dobbies-body-font-size-ml);
                    margin-right: 0;
                    margin-bottom: 20px;
                }
                &__cta a {
                    margin: auto;
                    margin-right: 10px;
                }
            }
        }
    }

    &.cta-button {
        .ms-content-block {
            &__cta {
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 20px;
                }
                a {
                    min-width: 215px;
                    border: 1px solid $dobbies-grey;
                    font-size: 16px;
                    font-weight: var(--dobbies-font-weight-bold);
                    border-radius: 4px;
                    background-color: transparent;
                    color: var(--dobbies-font-primary-color);
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
                    cursor: pointer;
                    padding: 5px 20px;
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    width: auto;
                    height: auto;
                    line-height: normal;
                    text-decoration: none;
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: var(--dobbies-bg-color-secondary);
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-bg-color);
                        text-decoration: none;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                    @media (max-width: $msv-breakpoint-l) {
                        min-width: 173px;
                    }
                    @media (max-width: $msv-breakpoint-sm) and (min-width: $msv-breakpoint-mbl) {
                        min-width: 130px;
                    }
                    @media (max-width: $msv-breakpoint-mbl) {
                        min-width: 150px;
                    }
                }
            }
        }
    }
}
.default-container-3-slots {
    &.time-fragment-col-spacing {
        [class^='col'] {
            padding-right: 30px;
            &:last-child {
                padding-right: 0;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                width: 100%;
                padding-right: 0;
            }
        }
    }
}
.time-slots {
    h3 {
        text-transform: uppercase;
        color: $dobbies-grey;
        font-weight: var(--dobbies-font-weight-bold);
        border-bottom: solid 1px rgba(102, 102, 102, 0.3);
        font-size: $dobbies-font-size-m;
        line-height: 28px;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 15px 0 30px;
        li {
            overflow: hidden;
            margin-bottom: 7px;
            padding-bottom: 7px;
            border-bottom: solid 1px rgba(102, 102, 102, 0.2);
            span {
                float: right;
                width: calc(100% - 82px);
                text-align: right;
            }
            &:last-child {
                border-bottom: 0;
                margin-bottom: 30px;
            }
        }
    }
}
.restaurant-msg {
    display: flex;
    align-items: center;
    background: var(--dobbies-bg-color-primary);
    color: var(--dobbies-font-secondary-color);
    min-height: 236px;
    padding: 25px;
    text-align: center;
    margin-bottom: 30px;
    h3 {
        margin-bottom: 15px;
    }
    .white-filled-btn {
        text-decoration: none;
    }
}
.safety-section {
    text-align: center;
    margin-bottom: 30px;
    h2 {
        text-transform: uppercase;
        color: $dobbies-grey;
        font-weight: var(--dobbies-font-weight-normal);
        font-size: var(--dobbies-body-font-size-ml);
        line-height: 32px;
        width: 100%;
    }
    h3 {
        color: var(--dobbies-heading-primary-color);
        font-weight: var(--dobbies-font-weight-bold);
        line-height: 28px;
        width: 100%;
    }
    .ms-content-block {
        &__image {
            img {
                margin: auto;
                height: auto;
            }
        }
        &__cta {
            > a {
                font-weight: var(--dobbies-font-weight-bold);
                display: block;
                padding: 5px;
                margin-top: 5px;
                cursor: pointer;
            }
        }
    }
}
.filter-navigation {
    .ms-tab-tabs-section {
        display: flex;
        justify-content: center;
        @media (max-width: $dobbies-container-max-width-s) {
            display: block;
        }
        &-li {
            padding: 0;
            margin-right: 15px;
            cursor: pointer;
            @media (max-width: $dobbies-container-max-width-s) {
                text-align: center;
                margin-right: 0;
            }
            &:focus {
                border: 0;
                outline: 0;
            }
            .ms-tab-item-header {
                background-color: transparent;
                border: 1px solid var(--dobbies-breadcrumb-icon-color);
                color: var(--dobbies-font-primary-color);
                border-radius: 4px;
                text-decoration: none;
                font-size: 16px;
                font-weight: var(--dobbies-font-weight-normal);
                padding: 5px 30px;
                cursor: pointer;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                position: relative;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                overflow: hidden;
                div {
                    margin: auto;
                }
                &:before {
                    display: none;
                }
                &:after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--dobbies-bg-color-secondary);
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 0 50%;
                    transform-origin: 0 50%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                }
                &:hover,
                &:focus,
                &:active {
                    color: var(--dobbies-font-primary-color);
                    background: var(--dobbies-bg-color);
                    text-decoration: none;
                    &:after {
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            }
            &[aria-selected='true'] {
                .ms-tab-item-header {
                    background: var(--dobbies-bg-color-secondary);
                }
            }
        }
    }
}
.container.restraunt-location {
    padding-left: 0;
    padding-right: 0;
    margin-top: 40px;
    h2 {
        text-transform: uppercase;
        font-weight: var(--dobbies-font-weight-bold);
        padding-bottom: 10px;
        margin-bottom: 0;
    }
}
.line-button {
    background-color: transparent;
    border: 1px solid $dobbies-grey;
    color: var(--dobbies-font-primary-color);
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    font-weight: var(--dobbies-font-weight-normal);
    padding: 5px 30px;
    margin-top: 5px;
    cursor: pointer;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    overflow: hidden;
    div {
        margin: auto;
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-secondary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
        color: var(--dobbies-font-primary-color);
        background: var(--dobbies-bg-color);
        text-decoration: none;
        &:after {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
}
.white-filled-btn {
    background-color: var(--dobbies-font-secondary-color);
    border-radius: 4px;
    display: inline-block;
    padding: 2px 12px;
    text-decoration: none;
    margin-top: 10px;
}
.iframe-block {
    h2 {
        font-weight: var(--dobbies-font-weight-bold);
        margin: 20px 0;
        line-height: 42px;
        width: 100%;
        @media (max-width: $msv-breakpoint-l) {
            font-size: 18px;
        }
    }
    iframe {
        border: 0;
        height: 100vh;
    }
}
.category-shop {
    margin-top: 40px;
    margin-bottom: 20px;
    @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
        margin-top: 20px;
        margin-bottom: 40px;
    }
    padding: 0;
    .col-12 {
        @media (min-width: $dobbies-container-max-width-s) {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }
    .heading {
        @include tile-heading();
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    .ms-content-block {
        &__title {
            @include cat-link();
        }
        &__image {
            margin: 10px;
            img {
                margin: auto;
                height: auto;
            }
        }
        &__details {
            text-align: center;
            .msc-cta__primary {
                color: var(--dobbies-heading-primary-color);
            }
        }
    }
    @media (max-width: $msv-breakpoint-xl) {
        // padding-top: 5px;
        // padding-bottom: 46px;
        .heading {
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .ms-content-block {
            &__image {
                // margin: 0 0 4px;
            }
        }
    }
    @media (max-width: $msv-breakpoint-m) {
        padding-bottom: 36px;
        .heading {
            margin-bottom: 42px;
        }
        .ms-content-block {
            &__image {
                margin: 0 auto 5px;
                width: 100px;
            }
        }
    }
    @media (max-width: $dobbies-container-max-width-m) {
        .col-12 {
            &:nth-child(6),
            &:nth-child(7) {
                display: none;
            }
        }
    }
    @media (max-width: $dobbies-container-max-width-s) {
        padding-top: 3px;
        padding-bottom: 19px;
        .heading {
            margin-bottom: 20px;
            margin-top: 0;
        }
        .ms-content-block {
            min-height: auto;
        }
        .col-12 {
            max-width: 50%;
        }
    }
    .col-md:last-child {
        .ms-content-block[data-m-layout='tile']:last-child {
            margin-right: auto;
        }
    }
}
.store-temporarily-closed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
    .ms-content-block {
        background: var(--dobbies-bg-color);
        width: 600px;
        height: 220px;
        margin: auto;
        position: absolute;
        top: 50vh;
        left: 50%;
        margin-top: -50px;
        margin-left: -300px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @media (max-width: $dobbies-container-max-width-s) {
            width: 90%;
            left: 0;
            margin-left: 5%;
            padding: 30px;
            height: auto;
        }
        &__title {
            margin-bottom: 15px;
        }
        &__text {
            > p {
                margin-bottom: 10px;
            }
        }
        &__cta {
            text-align: center;
            > a {
                background-color: var(--dobbies-bg-color-secondary);
                height: 44px;
                font-size: var(--dobbies-body-font-size-m);
                font-weight: var(--dobbies-font-weight-heavy);
                line-height: 44px;
                margin: 10px 5px 0;
                padding: 0 25px;
                text-align: center;
                border-radius: 4px;
                cursor: pointer;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                position: relative;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                overflow: hidden;
                border: 0;
                &:before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--dobbies-bg-color-primary);
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 0 50%;
                    transform-origin: 0 50%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                }
                &:hover,
                &:focus,
                &:active {
                    color: var(--dobbies-font-secondary-color);
                    background: var(--dobbies-bg-color-secondary);
                    text-decoration: none;
                    &:before {
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
                > div {
                    width: 100%;
                }
            }
        }
    }
}
.text-center {
    text-align: center;
}
.seprator {
    background-color: var(--dobbies-font-primary-color);
    height: 1px;
    //margin-bottom: 40px;
}
.full-width-banner-color {
    margin-top: 40px;
    margin-bottom: 40px;
    .ms-content-block[data-m-layout='add-banner'] {
        margin-top: 0;
        img {
            height: auto;
            max-height: fit-content;
            width: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .ms-content-block__details {
        display: none;
    }
}
.mt-0 {
    margin-top: 0;
}
.mb-0 {
    margin-bottom: 0;
}

.remove-uppercase {
    h1,
    h2,
    h3,
    h4 {
        text-transform: uppercase !important;
    }
}

//________________START offers class ______________________________________________________________________________________________________________________________________________

.offer-1.ms-content-block {
    .ms-content-block__details {
        &::before {
            background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/ME6d1x');
        }
    }
}
.offer-2.ms-content-block {
    .ms-content-block__details {
        &::before {
            background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/ME6ie4');
        }
    }
}
.offer-3.ms-content-block {
    .ms-content-block__details {
        &::before {
            background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/ME6d1w');
        }
    }
}
.offer-4.ms-content-block {
    .ms-content-block__details {
        &::before {
            background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/ME6fD5');
        }
    }
}

.offer-1.ms-content-block,
.offer-2.ms-content-block,
.offer-3.ms-content-block,
.offer-4.ms-content-block {
    .ms-content-block__details {
        height: fit-content;
        // transform: translateY(5px);
        align-items: flex-start;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        right: 0;
        top: -5px;
        width: 100%;
        width: 1068px;
        margin: 0 auto;
        padding: 0 20px;
        @media screen and (max-width: 1599px) {
            top: 0;
        }
        @media screen and (max-width: 1380px) {
            width: 1080px;
            height: 470px;
        }
        // @media screen and (max-width: $dobbies-container-max-width-tablet){
        //     // width: 1080px;
        //     height: 445px;
        // }
        @media screen and (max-width: 1150px) {
            width: 1030px;
            height: 430px;
        }
        @media screen and (max-width: $dobbies-container-max-width-l) {
            width: 900px;
            height: 430px;
        }
        @media screen and (max-width: $dobbies-container-max-width-m) {
            width: 900px;
            height: 452px;
        }
        @media screen and (max-width: $msv-container-max-width-s) {
            width: 600px;
            height: 400px;
            align-items: center;
            top: -6px;
        }
        @media screen and (max-width: $msv-breakpoint-mbl-sm) {
            width: 450px;
            height: 320px;
        }
        @media screen and (max-width: $msv-breakpoint-mbl) {
            width: 420px;
            height: 315px;
        }
        @media screen and (max-width: $dobbies-container-max-width-xs) {
            width: 390px;
            height: 300px;
        }
        @media screen and (max-width: 320px) {
            width: 320px;
            height: 265px;
        }

        &::before {
            background-size: 100%;
            display: block;
            width: 400px;
            height: 400px;
            z-index: 10;
            content: '';
            background-repeat: no-repeat;
            position: relative;
            top: 0;
            @media screen and (max-width: 1380px) {
                width: 300px;
                height: 300px;
                top: -28px;
            }
            @media screen and (max-width: 1150px) {
                top: -8px;
            }
            @media screen and (max-width: $dobbies-container-max-width-m) {
                top: -36px;
            }
            @media screen and (max-width: $msv-container-max-width-s) {
                width: 350px;
                height: 350px;
                top: 0;
            }
            @media screen and (max-width: $msv-breakpoint-mbl-sm) {
                width: 280px;
                height: 280px;
            }
            @media screen and (max-width: $msv-breakpoint-mbl) {
                width: 260px;
                height: 260px;
            }
            @media screen and (max-width: 320px) {
                width: 210px;
                height: 210px;
            }
        }

        .ms-content-block__title {
            display: none;
        }

        .ms-content-block__text {
            color: var(--msv-hero-textheme-dark);
            font-size: var(--dobbies-body-font-size-xl);
            line-height: $dobbies-line-height-m;
            overflow: visible;
            // text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal;
            max-height: 98px;
            text-align: left;
            margin-top: 0px;
            transform: translateY(-20px);
            @media screen and (min-width: $msv-breakpoint-l) and (max-width: $dobbies-container-max-width-tablet) {
                -webkit-line-clamp: 2;
            }
            @media screen and (max-width: $dobbies-container-max-width-m) {
                transform: translateY(-40px);
                margin-bottom: -40px;
            }
            @media screen and (max-width: $msv-container-max-width-s) {
                display: none;
            }
        }
    }
}

.ms-text-block,
.ms-content-block__text {
    p {
        margin-bottom: 20px;
    }
}

.active-image-container {
    .msc-active-image {
        &__details {
            max-width: 600px;
            top: 50%;
            transform: translateY(-50px);
            padding: 0;
            margin-left: 8%;
            display: block;
            height: auto;

            @media screen and (max-width: $msv-breakpoint-xl) {
                margin-left: 4%;
            }

            @media screen and (max-width: $msv-breakpoint-sm) {
                position: static;
                margin: 0;
                transform: none;
                padding: 0;
            }

            &.vertical-align {
                @media screen and (max-width: $msv-breakpoint-xl) {
                    margin-left: 4%;
                }
                @media only screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-breakpoint-m) {
                    background: transparent !important;
                    position: absolute;

                    > * {
                        justify-content: start;
                    }
                }
                @media screen and (max-width: $msv-breakpoint-sm) {
                    padding: 0;
                    margin: 0;
                }
                .msc-active-image__details__title {
                    margin: 0;
                    margin-bottom: 20px;
                    @media only screen and (max-width: $dobbies-container-max-width-tablet) {
                        font-size: 30px;
                        line-height: 38px;
                    }
                    @media screen and (max-width: $msv-breakpoint-sm) {
                        padding: 0;
                        font-size: 20px;
                        line-height: 25px;
                        margin: 20px 0;
                    }
                }
                .msc-active-image__details__additional-content__paragraph {
                    @media screen and (max-width: $msv-breakpoint-sm) {
                        margin: 0;
                    }
                }
            }

            &__title {
                text-align: left;
                font-size: 65px;
                color: $white;
                width: auto;
                margin-top: 0;
                @media screen and (max-width: $msv-breakpoint-xl) {
                    font-size: 40px;
                    line-height: 50px;
                }
                @media screen and (max-width: $dobbies-container-max-width-tablet) {
                    font-size: 30px;
                    line-height: 38px;
                }
                @media screen and (max-width: $msv-breakpoint-sm) {
                    color: $dobbies-grey;
                    font-size: 20px;
                    line-height: 25px;
                }
            }

            .msc-active-image__details__additional-content__paragraph {
                font-size: 16px;
                line-height: 20px;
                color: $white;
                margin: 0;
                width: 100%;
                @media screen and (max-width: $dobbies-container-max-width-tablet) {
                    font-size: 14px;
                    line-height: 18px;
                }
                @media screen and (max-width: $msv-breakpoint-sm) {
                    color: $dobbies-grey;
                    opacity: 1;
                }
            }
        }
    }
}

.in-store {
    &-container {
        .col-auto {
            .ms-content-block {
                margin-bottom: 40px;
            }
            &:last-child {
                @media (max-width: $dobbies-container-max-width-s) {
                    .ms-content-block {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.ms-carousel {
    margin-top: 35px;
    margin-bottom: 40px;
}

.dobbies-top-picks-carousel {
    &.cta-button {
        .dobbies-top-picks-carousel__item__link {
            background-color: transparent;
            border: 1px solid $dobbies-grey;
            color: var(--dobbies-font-primary-color);
            border-radius: 4px;
            text-decoration: none;
            font-size: 16px;
            font-weight: var(--dobbies-font-weight-bold);
            cursor: pointer;
            position: relative;
            transform: perspective(1px) translateZ(0);
            transition-duration: 0.3s;
            overflow: hidden;
            padding: 5px 20px;
            margin: 0px;
            height: auto;
            width: auto;
            line-height: normal;
            @media (max-width: $dobbies-container-max-width-s) {
                flex: initial;
            }
            div {
                margin: auto;
            }
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: $dobbies-yellow;
                transform: scaleX(0);
                transform-origin: 0 50%;
                transition-property: transform;
                transition-duration: 0.3s;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                background: var(--dobbies-bg-color);
                text-decoration: none;
                &:after {
                    transform: scaleX(1);
                }
            }
        }
    }
}

.cookie-info-container {
    .ms-accordion-item__drawer {
        .drawer__button {
            text-align: left;
        }
    }
}

.three-column-layout,
.six-column-layout,
.two-column-container {
    .ms-content-block__cta {
        > a {
            font-weight: var(--dobbies-font-weight-normal);
            text-decoration: underline;
        }
    }
} 

.three-column-layout, 
.two-column-container {
    .ms-content-block__cta {
        >a {
            margin: 0 10px;
        }
    }
}
//_________________END offers class ____________________________________________________________________________________________________

.resturent-club-plus {
    .ms-content-block__link {
        margin: 0;
        padding: 0 !important;
        border-radius: 0 !important;
    }
    .ms-content-block__details {
        display: none !important;
    }
}