$msv-address-form-item-margin-bottom: 10px;
$msv-address-detail-item-margin-right: 5px;
$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-icon-margin: 8px;
$msv-address-input-min-height: 32px;
$msv-address-select-item-radio-height: 24px;
$msv-address-select-item-radio-width: 24px;
$msv-address-select-item-address-detail-padding-left: 48px;
$msv-address-button-save-margin-right: 20px;
$msv-account-management-address-margin-bottom: 20px;
$msv-account-management-address-list-button-add-empty-margin-bottom: 16px;
$msv-account-management-address-list-primary-margin-top: 20px;
$msv-account-management-address-list-primary-heading-margin-bottom: 12px;
$msv-account-management-address-list-primary-list-border-color: $dobbies-gray-300;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 5px;
$msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right: 0px;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 5px;
$msv-account-management-address-list-button-primary-margin-top: 15px;
$msv-account-management-address-textbox-height: 60px;
$msv-account-management-address-textbox-height-m: 46px;
$bobbies-address-title-line-height: 63px;

//style presets
:root {
    --msv-address-font-size: var(--msv-body-font-size-l);
    --msv-address-font-color: var(--dobbies-font-primary-color);
    --msv-address-border: #{$dobbies-gray-300};
    --msv-address-font-family: #{$dobbies-primary-font-family};

    //heading
    --msv-address-heading-font-size: var(--msv-body-font-size-xl);
    --msv-address-heading-font-color: var(--dobbies-font-primary-color);

    --msv-address-primary-heading-font-size: var(--msv-body-font-size-m);
    --msv-address-primary-heading-font-color: var(--dobbies-font-primary-color);

    --msv-address-primary-text-font-size: var(--msv-body-font-size-s);
    --msv-address-primary-text-font-color: var(--dobbies-font-primary-color);

    // error
    --msv-address-error-bg: var(--dobbies-error-color);
    --msv-address-error-border: var(--dobbies-error-color);
    --msv-address-error-font-color: var(--dobbies-font-secondary-color);
    --msv-address-error-font-size: var(--msv-body-font-size-m);

    // link
    --msv-address-link: var(--dobbies-font-primary-color);
    --msv-address-link-size: var(--msv-body-font-size-m);

    // primary button
    --msv-address-primary-btn-bg: var(--dobbies-accent-brand-color);
    --msv-address-primary-btn-font-color: var(--dobbies-font-secondary-color);
    --msv-address-primary-btn-border: var(--dobbies-accent-brand-color);

    // secondary button
    --msv-address-secondary-btn-bg: var(--dobbies-bg-color);
    --msv-address-secondary-btn-font-color: var(--dobbies-font-primary-color);
    --msv-address-secondary-btn-border: var(--dobbies-accent-brand-color);
}

.msc-address-form {
    &__item {
        margin-bottom: $msv-address-form-item-margin-bottom;
    }
    &__item-name,
    &__item-zipcode {
        max-width: 100%;
        @media (min-width: $dobbies-container-max-width-tablet) {
            max-width: 50%;
        }
    }

    &__item-isprimary {
        display: flex;

        .msc-address-form__label {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 10px;

            &:hover {
                cursor: pointer;
            }
        }

        .msc-address-form__input-checkbox {
            width: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__item-county {
        display: none;
    }

    &__label {
        color: var(--msv-address-font-color);
        display: block;
        font-size: var(--msv-address-font-size);
        line-height: $dobbies-line-height-l;
        margin-bottom: 5px;
    }

    &__input-text {
        padding: 6px 12px;
    }

    &__input,
    &__dropdown {
        @include vfi();
        color: var(--msv-address-font-color);
        display: block;
        width: 100%;
        box-sizing: border-box;
        border-radius: 0;
        font-size: var(--msv-address-font-size);
        line-height: $dobbies-line-height-l;
        font-weight: var(--msv-font-weight-normal);
        height: $msv-account-management-address-textbox-height-m;
        border: 1px solid $dobbies-black;
        padding: 6px 12px;
        @media (min-width: $dobbies-container-max-width-s) {
            height: $msv-account-management-address-textbox-height;
        }
    }

    &__alert {
        display: block;
    }

    &__button-save {
        @include primary-button(
            var(--msv-address-primary-btn-bg),
            var(--msv-address-primary-btn-font-color),
            var(--msv-address-primary-btn-border)
        );
        margin-right: $msv-address-button-save-margin-right;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        border: 0;
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-bg-color-primary);
            &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }

    &__button-cancel {
        @include secondary-button(
            var(--msv-address-secondary-btn-bg),
            var(--msv-address-secondary-btn-font-color),
            var(--msv-address-secondary-btn-border)
        );
    }

    .address-form__item-invalid &__alert {
        @include font-content(var(--msv-font-weight-bold), var(--msv-address-error-font-size), $dobbies-line-height-m);
        font-family: var(--msv-address-font-family);
        text-transform: none;
        border-radius: $msv-alert-border-radius;
        position: relative;
        padding: $msv-alert-padding;
        margin-bottom: $msv-alert-margin-bottom;
        border: 1px solid var(--msv-address-error-border);
        color: var(--msv-address-error-font-color);
        background-color: var(--msv-address-error-bg);
        margin-top: $msv-alert-line-margin-top;
        background-color: transparent;
        border: 0;
        color: var(--dobbies-msg-alert-color);
        padding: 0;
        margin-bottom: 5px;
        &:before {
            margin-right: $msv-alert-icon-margin;
            @include msv-icon();
            content: $msv-IncidentTriangle;
        }
    }
    .address-form {
        &__item-invalid {
            display: flex;
            flex-direction: column;
            .msc-address-form__alert {
                order: 3;
                color: var(--dobbies-msg-alert-color);
                background: var(--dobbies-bg-color);
                font-weight: var(--dobbies-font-weight-normal);
                padding: 10px;
                margin: 10px 0 0;
            }
        }
    }
    .postcode-validation {
        margin-bottom: 10px;
        position: relative;
        .post-code-errorbox {
            > .post-code {
                border: 0;
            }
            .post-code-error {
                color: var(--dobbies-msg-alert-color);
                font-size: var(--dobbies-body-font-size-s);
                font-weight: var(--dobbies-font-weight-bold);
                background: $dobbies-club-more-room;
                margin-top: 5px;
                padding: 5px;
                &:before {
                    margin-right: $msv-alert-icon-margin;
                    @include msv-icon();
                    content: $msv-IncidentTriangle;
                }
            }
        }
        .auto-suggestion {
            > div {
                border: 1px solid var(--dobbies-font-primary-color);
                background-color: var(--dobbies-bg-color);
                border-top: 0;
                height: 232px;
                overflow: auto;
                position: absolute;
                left: 0;
                width: 100%;
                > div {
                    padding: 5px 10px;
                    cursor: pointer;
                    &:hover {
                        background: var(--dobbies-header-grey);
                    }
                }
            }
        }
        .address-link {
            display: inline-block;
            padding: 17px;
            font-weight: var(--dobbies-font-weight-bold);
            text-decoration: underline;
            cursor: pointer;
            @include add-dobbies-icon($dobbies-icon-arrow, after);
            &:after {
                color: var(--dobbies-bg-color-secondary);
                font-weight: var(--dobbies-font-weight-bold);
                margin-left: 5px;
                transform: rotate(180deg);
            }
            @media (max-width: $dobbies-container-max-width-m) {
                padding: 10px 0;
            }
        }
    }
    .postcode-validation {
        .auto-suggestion {
            background: var(--dobbies-bg-color);
            color: var(--dobbies-font-primary-color);
            margin: -10px 1px 0;
            max-height: 173px;
            overflow-y: auto;
            > div {
                > div {
                    padding: 10px 15px;
                    line-height: 18px;
                    transition: all 0.3s;
                    border-bottom: 1px solid var(--dobbies-bombay-border-color);
                    &:hover {
                        background-color: $dobbies-vlight-grey;
                    }
                }
            }
        }
        .msc-address-form__input {
            margin-bottom: 10px;
        }
    }
}

.msc-address-detail {
    &__item {
        margin-right: $msv-address-detail-item-margin-right;
    }

    &__item-newline {
        margin-right: 0px;
        word-wrap: break-word;

        &:after {
            content: '\A';
            white-space: pre;
        }
    }
    &__item-address-detail_Phone {
        display: block;
        font-size: var(--dobbies-body-font-size-s);
        line-height: normal;
        padding-bottom: 20px;

        .msc-address-detail__item-phone {
            margin-right: 0 !important;
        }
    }
    &__item-phone {
        @include add-icon($msv-Phone);

        &-label {
            @include visually-hidden();
        }

        &:before {
            margin-right: $msv-account-management-address-list-primary-list-phone-icon-margin-right;
            display: none;
        }

        &.msc-address-detail__item-empty {
            display: none;
        }
    }
}

.msc-address-select {
    &__input-radio {
        height: $msv-address-select-item-radio-height;
        width: $msv-address-select-item-radio-width;
        position: absolute;
        top: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: $msv-address-select-item-margin-top;
        margin-bottom: $msv-address-select-item-margin-bottom;
        position: relative;

        .msc-address-detail {
            padding-left: $msv-address-select-item-address-detail-padding-left;
        }
    }

    &__input {
        @include vfi();
        @include form-input-checkbox();
    }

    &__button-add {
        @include vfi();
        @include primary-button(
            var(--msv-address-primary-btn-bg),
            var(--msv-address-primary-btn-font-color),
            var(--msv-address-primary-btn-border)
        );
    }
}

// mixin
@mixin address-button {
    cursor: pointer;
}
.ms-account-management-address {
    margin-bottom: $msv-account-management-address-margin-bottom;
    .ms-address-list {
        margin-bottom: $msv-account-management-address-margin-bottom;
        display: flex;
        flex-flow: column;

        & > div.msc-address-list__primary:nth-of-type(1) {
            border-top: none;
            margin-top: 0;
            .msc-address-list__primary-list {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }

    .msc-address-list__heading {
        @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-s), normal);
        color: var(--msv-address-heading-font-color);
        padding-bottom: 15px;
        // margin-top: 20px;
        border-bottom: 1px solid var(--dobbies-font-primary-color);
        margin-bottom: 0;

        @media (max-width: $dobbies-container-max-width-tablet) {
            padding-bottom: 10px;
        }

        @media (max-width: $msv-container-max-width-s) {
            margin-top: 0;
        }
    }

    .msc-address-list__add-empty {
        margin-bottom: $msv-account-management-address-list-button-add-empty-margin-bottom;
        color: var(--dobbies-font-secondary-color);
    }

    .msc-address-list__button-add {
        @include primary-button(
            var(--msv-address-primary-btn-bg),
            var(--msv-address-primary-btn-font-color),
            var(--msv-address-primary-btn-border)
        );
        font-size: var(--dobbies-body-font-size-m);
        font-weight: var(--dobbies-font-weight-bold);
        min-width: 224px;
        line-height: inherit;
        border: 0;
        transform: perspective(1px) translateZ(0);
        position: relative;
        transition-duration: 0.3s;
        overflow: hidden;
        order: 1;
        max-width: max-content;
        background: #fff;
        border: 1px solid #111111;
        color: #111111;
        padding: 5px 10px;
        height: 30px;
        font-size: 16px;

        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            transform: scaleX(0);
            transform-origin: 0 50%;
            transition-property: transform;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-bg-color-primary);
            &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }

    .msc-address-list__primary {
        border-top: 1px solid var(--dobbies-font-primary-color);
        // margin-top: $msv-account-management-address-list-primary-margin-top;
        padding: 20px 0px;

        .msc-address-list__primary-heading {
            @include font-content(var(--dobbies-font-weight-bold), $dobbies-font-size-l, normal);
            color: var(--msv-address-primary-heading-font-color);
            text-transform: uppercase;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--dobbies-font-primary-color);
            margin-bottom: 0;
            // margin-bottom: $msv-account-management-address-list-primary-heading-margin-bottom;

            @media (max-width: $dobbies-container-max-width-tablet) {
                font-size: $dobbies-font-size-m;
            }
        }
        .msc-address-list__primary-list {
            padding: 20px 0px;
            border-bottom: 1px solid var(--dobbies-font-primary-color);

            &::nth-child(2) {
                padding-top: 0px;
            }

            &::last-child {
                padding-bottom: 0;
            }
            .msc-address-detail {
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-primary-text-font-size), $dobbies-line-height-s);
                color: var(--msv-address-primary-text-font-color);

                .msc-address-detail__item {
                    margin-right: $msv-account-management-address-list-primary-list-address-detail-item-margin-right;
                }
                .msc-address-detail__item-newline {
                    margin-right: $msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right;
                    font-size: var(--dobbies-body-font-size-s);
                    line-height: 22px;
                    display: block;

                    &:nth-last-of-type(2) {
                        padding-bottom: 10px;
                    }

                    @media (max-width: $dobbies-container-max-width-tablet) {
                        font-size: 14px;
                    }

                    &::after {
                        content: '\A';
                        white-space: pre;
                    }
                }
            }
            .msc-address-list__button-primary {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-s), $dobbies-line-height-m);
                text-transform: none;
                border-radius: 0px;
                text-decoration: underline;
                // display: block;
                height: auto;
                padding: 0;
            }

            .msc-address-list__button-edit {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-s), $dobbies-line-height-m);
                text-transform: none;
                border-radius: 0px;
                text-decoration: underline;
                padding: 0;
            }

            .msc-address-list__button-remove {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-s), $dobbies-line-height-m);
                text-transform: none;
                border-radius: 0px;
                text-decoration: underline;
                padding: 0;
            }

            button {
                margin-right: 40px;
                @media (max-width: $msv-breakpoint-sm) {
                    margin-right: 30px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .msc-address-form {
        padding-top: 40px;

        &__input,
        &__dropdown {
            max-height: 54px;
        }

        &__item-name,
        &__item-zipcode,
        &__item-threeletterisoregionname,
        &__item-phone {
            max-width: 100%;
        }
        &__item-invalid {
            display: flex;
            flex-direction: column;
            .msc-address-form__alert {
                order: 3;
            }
        }

        &__button-save,
        &__button-cancel {
            margin-top: 15px;

            @media (max-width: $msv-breakpoint-mbl-sm) {
                min-width: 50%;
            }
        }
        &__button-save {
            margin-right: 0;
        }

        &__button-cancel {
            @include button-link(var(--msv-address-link));
            @include font-content(var(--dobbies-font-weight-heavy), var(--dobbies-body-font-size-m), $dobbies-line-height-m);
            text-transform: none;
            border-radius: 0px;
            padding: 6px 12px 6px 0px;
            text-decoration: underline;
        }

        @media (max-width: $msv-breakpoint-l) {
            padding-top: 20px;

            &__item-zipcode,
            &__item-threeletterisoregionname,
            &__item-phone,
            &__button-save {
                min-width: 330px;
                max-width: 330px;
            }

            &__button-save {
                min-height: 52px;
                line-height: initial;
                padding: 0;
            }
        }

        @media (max-width: $msv-breakpoint-mbl-sm) {
            &__button-save {
                min-width: 170px;
                max-width: 170px;
                min-height: 44px;
                max-height: 44px;
                line-height: initial;
                padding: 0;
            }

            &__item-zipcode,
            &__item-threeletterisoregionname,
            &__item-phone {
                max-width: 100%;
            }
        }

        @media (min-width: $msv-breakpoint-l) {
            &__item-zipcode,
            &__item-threeletterisoregionname,
            &__item-phone {
                max-width: 50%;
            }
        }

        @media (min-width: $dobbies-container-max-width-xl) {
            &__input {
                max-width: 710px;
            }

            &__item-zipcode,
            &__item-threeletterisoregionname,
            &__item-phone,
            &__button-save {
                max-width: 345px;
            }
            &__button-cancel {
                width: auto;
            }

            &__button-save {
                min-height: 52px;
                min-width: 347px;
                max-width: 347px;
            }
        }

        @media (max-width: $dobbies-container-max-width-xl) and (min-width: $dobbies-container-max-width-l) {
            &__input {
                min-width: 767px;
            }

            &__item-zipcode,
            &__item-threeletterisoregionname,
            &__item-phone,
            &__button-save {
                min-width: 332px;
                max-width: 332px;

                input {
                    min-width: 332px;
                    max-width: 332px;
                }
            }
            &__button-cancel {
                width: auto;
            }

            &__button-save {
                min-height: 52px;
            }
        }
    }
}
