$msv-modal-width: 500px;
$msv-modal-padding: 40px 60px;
$msv-modal-margin: 20px;
$msv-modal-button-padding: 5px;
$msv-modal-horizontal-alignment-right: 1.75rem 1.5rem auto auto;
$msv-modal-horizontal-alignment-center: 1.75rem auto;
$msv-modal-horizontal-alignment-left: 1.75rem auto auto 1.5rem;
$msv-modal-vertical-alignment-top: flex-start;
$msv-modal-vertical-alignment-center: center;
$msv-modal-vertical-alignment-bottom: flex-end;
$msv-modal-z-index: 1050;
$dobbies-modal-width-xl: 952px;
$dobbies-modal-width-l: 680px;
$dobbies-modal-width-m: 548px;
$dobbies-modal-width-s: 90%;
$msv-size-m: 360px;
$dobbies-textbox-height: 60px;
$dobbies-textbox-height-m: 48px;

.modal-open .msc-modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.msc-modal {
	display: none;
	height: 100%;
	left: 0;
	overflow: hidden;
	outline: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: $msv-modal-z-index;

	&.show .msc-modal__dialog {
		transform: none;
	}

	&.fade .msc-modal__dialog {
		transition: transform 0.3s ease-out;
	}

	&__dialog {
		display: block;
		align-items: $msv-modal-vertical-alignment-top;
		max-width: $dobbies-modal-width-xl;
		//margin: $msv-modal-horizontal-alignment-center;
		pointer-events: none;
		position: relative;
		width: auto;

		&:focus {
			outline: 0;
		}

		@media (max-width: $dobbies-container-max-width-l) {
			max-width: $dobbies-modal-width-l;
		}
		@media (max-width: $dobbies-container-max-width-m) {
			max-width: $dobbies-modal-width-m;
		}
		@media (max-width: $dobbies-container-max-width-s) {
			max-width: $dobbies-modal-width-s;
		}

		&.ms-header__mobile-hamburger {
			.msc-modal__content {
				@media (max-width: $msv-breakpoint-l) {
					height: 100vh;
					min-height: 100%;
				}
			}
		}

		&.ms-store-select {
			width: 950px;
			height: 300px;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -475px;
			margin-top: -150px;
			max-width: inherit;
			@media (max-width: $msv-breakpoint-xl) {
				width: 855px;
				margin-left: -427px;
			}
			@media (max-width: $dobbies-container-max-width-m) {
				width: 736px;
				margin-left: -368px;
			}
			@media (max-width: $dobbies-container-max-width-s) {
				width: 90%;
				margin-left: 5%;
				left: 0;
			}
			.msc-modal {
				&__content {
					padding: 40px 40px 15px;
					background: var(--dobbies-bg-color);
					@media (max-width: $dobbies-container-max-width-l) {
						padding: 25px;
					}
					@media (max-width: $dobbies-container-max-width-s) {
						padding: 10px 15px;
					}
				}
				&__header {
					justify-content: flex-start;
					padding: 0 0 30px;
					border: 0;
					position: relative;
					h5.msc-modal__title {
						font-weight: normal;
						font-size: var(--dobbies-body-font-size-xl);
						h2 {
							font-weight: normal;
						}
					}
					.msc-modal__close-button {
						width: 34px;
						height: 34px;
						position: absolute;
						top: 0;
						right: 0;
						padding: 0;
						cursor: pointer;
						text-align: center;
						&:before {
							@include dobbies-icon();
							content: $dobbies-icon-close;
							font-size: var(--dobbies-icon-font-size-xl);
						}
					}
					@media (max-width: $dobbies-container-max-width-s) {
						position: static;
						padding: 0 30px 20px 0;
						h5.msc-modal__title {
							font-weight: normal;
							font-size: var(--dobbies-body-font-size-xl);
							h2 {
								font-size: 20px;
								line-height: 24px;
							}
						}
						.msc-modal__close-button {
							width: 42px;
							height: 42px;
							padding: 0;
							background: $dobbies-grey;
							font-weight: normal;
							color: $white;
							&:before {
								font-size: 26px;
							}
						}
					}
				}
				&__body {
					font-size: 16px;
					line-height: 22px;
					.ms-store-select {
						&__search {
							padding: 0 0 24px;
							border-bottom: 1px solid $dobbies-light-grey;
						}
						&__search-form {
							margin: 0;
							background: $white;
							border-radius: 6px;
							border: 1px solid $dobbies-light-grey;
						}
						&__search-input {
							height: 40px;
							border-radius: 6px;
							background: none;
							border: 0;
							font-size: 18px;
						}
						&__search-button {
							border: 0;
							padding: 0;
							height: 40px;
							background: none;
							color: $dobbies-grey;
							cursor: pointer;
							&:before {
								@include dobbies-icon();
								font-size: 22px;
								font-weight: bold;
								content: $dobbies-icon-search;
							}
						}
						&__no-locations {
							padding: 20px 0;
						}
					}
				}
				&__footer {
					.age-restriction-footer {
						display: flex;
						flex-direction: row-reverse;
						.msc-add-to-cart {
							font-weight: var(--dobbies-font-weight-bold);
							font-size: var(--dobbies-body-font-size-m);
							border-radius: 10px;
							position: relative;
							padding: 0 80px 0 90px;
							@media (max-width: $dobbies-container-max-width-m) {
								padding: 0 50px 0 70px;
							}
							@media (max-width: $dobbies-container-max-width-s) {
								padding: 0 10px 0 50px;
							}
							&:before {
								@include dobbies-icon();
								content: $dobbies-icon-cart;
								font-weight: $dobbies-outline-icon-weight;
								font-size: var(--dobbies-header-icon-size);
								position: absolute;
								top: 3px;
								left: 10px;
							}
						}
						.msc-btn.add-to-cart__age-restriction {
							background: transparent;
							width: 240px;
							font-weight: 500;
							text-decoration: underline;
							@media (max-width: $dobbies-container-max-width-s) {
								width: 100px;
							}
						}
					}
				}
			}
		}

		&.msc-search-result-modal {
			margin: 0;
			@media (max-width: $dobbies-container-max-width-s) {
				max-width: 100%;
			}
			.msc-modal__content {
				//height: 100vh;
				min-height: 100%;
				background-color: var(--dobbies-bg-color);
				width: 0;
				overflow: hidden;
				transition: all 0.3s;
				.msc-modal__close-button {
					@media (max-width: $dobbies-container-max-width-s) {
						right: 10px;
					}
				}
			}
		}

		&.dobbies-modal {
			&.msc-dobbies-modal {
				&__add-to-cart-modal {
					width: 1250px;
					height: 300px;
					position: absolute;
					left: 50%;
					top: 30%;
					margin-left: -630px;
					margin-top: -150px;
					max-width: inherit;
					@media (max-width: $msv-breakpoint-xl) {
						width: 878px;
						margin-left: -439px;
					}
					@media (max-width: $dobbies-container-max-width-m) {
						top: 40%;
						width: 546px;
						margin-left: -273px;
					}
					@media (max-width: $dobbies-container-max-width-s) {
						width: 90%;
						margin-left: 5%;
						left: 0;
					}
					.msc-modal {
						&__content {
							padding: 40px;
							background: var(--dobbies-modal-bg-color);
							@media (max-width: $msv-breakpoint-xl) {
								padding: 35px;
							}
							@media (max-width: $dobbies-container-max-width-m) {
								padding: 25px;
							}
							@media (max-width: $dobbies-container-max-width-s) {
								padding: 10px 15px;
							}
						}
						&__header {
							justify-content: flex-start;
							padding: 0;
							border: 0;
							text-align: left;
							position: relative;
							.msc-modal__title {
								text-transform: none;
								padding: 0px;
								h3 {
									font-weight: var(--dobbies-font-weight-bold);
								}
							}
							.age-restriction-subheading {
								font-size: var(--dobbies-body-font-size-xl);
								max-width: calc(100% - 60px);
								@media (max-width: $dobbies-container-max-width-s) {
									font-size: var(--dobbies-body-font-size-l);
								}
								@media (max-width: $dobbies-container-max-width-xs) {
									padding-top: 10px;
									margin-bottom: 10px;
									line-height: 20px;
								}
								&:before {
									background: var(--dobbies-bg-color-primary);
									width: 42px;
									height: 42px;
									display: inline-block;
									content: "18";
									border-radius: 50%;
									color: var(--dobbies-font-secondary-color);
									text-align: center;
									line-height: 42px;
									font-size: var(--dobbies-body-font-size-xl);
									margin-right: 5px;
									@media (max-width: $dobbies-container-max-width-xs) {
										float: left;
										margin-top: -4px;
									}
								}
							}
							.addtocart-cross {
								width: 32px;
								height: 32px;
								position: absolute;
								top: 0px;
								right: 0px;
								cursor: pointer;
								text-align: center;
								line-height: 32px;
								&:after {
									@include dobbies-icon();
									content: $dobbies-icon-close;
									font-weight: var(--dobbies-font-weight-bold);
									font-size: var(--dobbies-icon-font-size-xl);
								}
							}
						}
						&__body {
							line-height: $dobbies-line-height-m;
							margin: 5px 0;
							.age-restriction_confirmation {
								margin-bottom: 12px;
								display: inline-block;
								input[type="checkbox"] {
									margin: 4px 5px 0 0;
									float: left;
								}
							}
							p {
								margin-bottom: 12px;
							}
						}
						&__footer {
							.age-restriction-footer {
								display: flex;
								flex-direction: row-reverse;
								.msc-add-to-cart {
									font-weight: var(--dobbies-font-weight-heavy);
									font-size: var(--dobbies-body-font-size-m);
									border-radius: 10px;
									position: relative;
									padding: 0 80px 0 90px;
									@media (max-width: $dobbies-container-max-width-m) {
										padding: 0 50px 0 70px;
									}
									@media (max-width: $dobbies-container-max-width-s) {
										padding: 0 10px 0 50px;
									}
									&:before {
										@include dobbies-icon();
										content: $dobbies-icon-cart;
										font-weight: $dobbies-outline-icon-weight;
										font-size: var(--dobbies-header-icon-size);
										position: absolute;
										top: 6px;
										left: 20px;
									}
								}
								.msc-btn.add-to-cart__age-restriction {
									background: transparent;
									width: 240px;
									font-weight: 500;
									color: var(--dobbies-font-primary-color);
									text-decoration: underline;
									@media (max-width: $dobbies-container-max-width-s) {
										width: 150px;
									}
								}
							}
						}
					}
					.ms-product-collection {
						margin-top: 10px;
						margin-bottom: 0;
						padding-bottom: 0;
						border: 0;
						&__heading {
							display: none;
						}
						.msc-ss-carousel {
							width: 100%;
							float: none;
							padding: 0 10px;
							margin-top: 0;
							&:before {
								display: none;
							}
							&__flipper--next {
								margin-right: -15px;
								text-align: right;
							}
						}
						.msc-product {
							&__title {
								min-height: auto;
							}
						}
						&__item {
							padding-bottom: 0;
						}
					}
				}
			}
		}
		&.ms-dobbies-browse {
			margin: 0;
			.msc-modal__content {
				background-color: var(--dobbies-bg-color);
				width: 0;
				overflow: hidden;
				.msc-modal__header {
					h5 {
						text-align: left;
						.view-all-popup-header {
							.header-close-button {
								float: right;
							}
						}
					}
				}
				.dobbies-modal-body {
					height: calc(100vh - 54px);
					overflow-y: auto;
				}
			}
		}
	}

	&__content {
		background-clip: padding-box;
		background-color: var(--dobbies-header-grey);
		color: var(--dobbies-modal-font-color);
		display: flex;
		flex-direction: column;
		outline: 0;
		pointer-events: auto;
		position: relative;
		width: 100%;
	}

	&__header {
		display: flex;
		padding: 10px 15px;
		align-items: flex-start;
		justify-content: space-between;
		border-bottom: 1px solid $dobbies-light-grey;

		.msc-modal__title {
			@include font-content-l();
			margin: 0;
			font-weight: var(--dobbies-font-weight-bold);
			width: 100%;

			@media screen and (max-width: $msv-size-m) {
				font-size: 22px;
			}
			.ms-reviews-list__report-header {
				max-width: calc(100% - 40px);
			}
		}

		.msc-modal__close-button {
			@include vfi();
			background-color: transparent;
			border: 0;
			color: var(--dobbies-font-primary-color);
			font-size: 20px;
			position: absolute;
			right: 20px;
			cursor: pointer;
			text-shadow: 0 1px 0 $dobbies-white;
			&:before {
				@include dobbies-icon();
				content: $dobbies-icon-close;
				font-size: var(--dobbies-icon-font-size-l);
			}
		}
	}

	.msc-fullview-modal {
		@media (min-width: map-get($grid-breakpoints, "md")) {
			max-width: 100%;
			max-height: 772px;
			margin: 40px;
		}

		.msc-modal {
			&__content {
				padding: 0px;
				background-color: $dobbies-white;
				background-clip: border-box;
			}

			&__close-button {
				right: 10px;
				top: 15px;
			}
		}

		.msc-fullview-modal-body.msc-modal__body {
			margin-top: 20px;
		}

		.msc-carousel__item {
			img {
				display: block;
				min-height: 772px;

				@media (max-width: $msv-breakpoint-m) {
					min-height: 0;
				}
			}
		}
	}

	&__footer {
		display: flex;
		justify-content: flex-start;

		@media (max-width: map-get($grid-breakpoints, "md")) {
			flex-wrap: wrap;
			.btn-primary {
				margin-bottom: 12px;
			}

			.btn-primary,
			.btn-secondary {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	&__backdrop {
		background-color: rgba(0, 0, 0, 0);
		height: 100vh;
		left: 0;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: $msv-modal-z-index - 10;

		&.show {
			background-color: rgba(0, 0, 0, 0.5);
		}
	}
	.ms-write-review {
		@media (max-width: $dobbies-container-max-width-s) {
			float: none;
		}
	}
	&.show {
		.msc-modal__dialog.msc-search-result-modal,
		.msc-modal__dialog.ms-dobbies-browse {
			.msc-modal__content {
				width: auto;
				overflow: initial;
			}
		}
	}
}

@media (prefers-reduced-motion: reduce) {
	.msc-modal.fade .msc-modal__dialog {
		transform: none;
	}
}
.msc-modal__dialog {
	@media (max-height: $dobbies-container-max-height) {
		margin-top: 50px;
		margin-bottom: 50px;
	}
}
.dobbies-modal {
	&.ms-dobbies-mobile-content {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 700px;
		height: 340px;
		margin-top: -170px;
		margin-left: -350px;
		@media (max-width: $dobbies-container-max-width-m) {
			width: 600px;
			margin-left: -300px;
		}
		@media (max-width: $dobbies-container-max-width-s) {
			width: 90%;
			left: 5%;
			margin-left: 0;
		}
		.msc-modal__content {
			border-radius: 15px;
		}
		.msc-modal-container {
			text-align: center;
			margin: 20px;
		}
		.msc-modal-image-container {
			display: flex;
			justify-content: center;
			margin-bottom: 10px;
		}
		.msc-modal-image {
			height: 200px;
		}
		.msc-modal-text {
			margin-top: 10px;
		}
		.msc-button-container {
			display: flex;
			justify-content: center;
			margin: 10px;
		}
		.msc-modal-icon {
			width: 120px;
		}
	}

	&.ms-dobbies-generic-popup {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: none;

		.msc-modal__content {
			margin: 20px;
			border-radius: 15px;
			width: 600px;
			@media (max-width: $dobbies-container-max-width-m) {
				width: 600px;
			}
			@media (max-width: $dobbies-container-max-width-s) {
				width: 90%;
			}
		}

		.msc-modal-cta {
			display: flex;
			justify-content: space-evenly;
			margin: 10px;
			flex-direction: row;
			margin-top: 30px;
			margin-bottom: 30px;
			@media (max-width: $dobbies-container-max-width-s) {
				flex-direction: column;
				align-items: center;
				margin-bottom: 0px;
				margin-top: 8px;
			}
		}

		.msc-modal-description {
			margin-top: 10px;
			padding: 10px;
			@media (max-width: $dobbies-container-max-width-s) {
				margin-top: 2px;
				padding: 8px;
			}
		}
	}

	&.ms-dobbies-newsletter-popup {
		display: block;
		overflow: hidden;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 700px;
		height: 380px;
		margin-top: -170px;
		margin-left: -350px;
		@media (max-width: $dobbies-container-max-width-m) {
			width: 600px;
			margin-left: -300px;
		}
		@media (max-width: $dobbies-container-max-width-s) {
			width: 90%;
			left: 5%;
			margin-left: 0;
		}
		.msc-modal {
			&__header {
				padding: 15px 28px;
				text-align: left;
				margin-bottom: 25px;
				border-bottom: 1px solid var(--dobbies-gray-border-color);
				.popup__heading {
					padding-right: 30px;
					margin: 0;
				}
				.newsletter-popup-cross {
					width: 30px;
					height: 30px;
					float: right;
					cursor: pointer;
					&:before {
						content: $dobbies-icon-close;
						@include dobbies-icon();
						font-size: var(--dobbies-icon-font-size-xl);
					}
				}
			}
			&__body {
				.response-message {
					color: var(--dobbies-heading-primary-color);
					font-size: var(--dobbies-body-font-size-s);
					font-weight: var(--dobbies-font-weight-bold);
					margin-bottom: 5px;
					padding-left: 30px;
					&.response-error {
						color: var(--dobbies-msg-alert-color);
					}
				}
				.msc-email-address-text {
					margin-top: 8px;
					margin-bottom: 8px;
				}

				.email-address-input {
					margin-top: 8px;
					margin-bottom: 8px;
				}
				.newsletter-subscription-div {
					padding: 0 30px 30px;
					input[type="text"],
					input[type="email"] {
						@include vfi();
						color: var(--msv-address-font-color);
						display: block;
						width: 100%;
						box-sizing: border-box;
						border-radius: 0;
						font-size: var(--msv-address-font-size);
						line-height: $dobbies-line-height-l;
						font-weight: var(--msv-font-weight-normal);
						height: $dobbies-textbox-height;
						border: 1px solid var(--dobbies-font-primary-color);
						padding: 6px 12px;
						margin-bottom: 15px;
					}
					.checkbox-privacy-policy {
						margin-bottom: 15px;
						input {
							margin: 0 5px 0 0;
						}
						.checkbox-privacy-policy-text {
							display: inline-block;
						}
						~ div {
							display: inline-block;
							button {
								height: auto;
								@include primary-button(
									var(--msv-checkout-primary-btn-bg),
									var(--msv-checkout-primary-btn-font-color),
									var(--msv-checkout-primary-btn-border)
								);
								min-width: 180px;
								padding: 11px 20px;
								margin-right: 10px;
								font-weight: var(--dobbies-font-weight-bold);
								position: relative;
								-webkit-transform: perspective(1px) translateZ(0);
								transform: perspective(1px) translateZ(0);
								position: relative;
								-webkit-transition-duration: 0.3s;
								transition-duration: 0.3s;
								overflow: hidden;
								border: 0;
								@media screen and (max-width: $dobbies-container-max-width-m) {
									width: 100%;
									margin-right: 0;
								}
								&:after {
									content: "";
									position: absolute;
									z-index: -1;
									top: 0;
									left: 0;
									right: 0;
									bottom: 0;
									background: var(--dobbies-bg-color-secondary);
									-webkit-transform: scaleX(0);
									transform: scaleX(0);
									-webkit-transform-origin: 0 50%;
									transform-origin: 0 50%;
									-webkit-transition-property: transform;
									transition-property: transform;
									-webkit-transition-duration: 0.3s;
									transition-duration: 0.3s;
									-webkit-transition-timing-function: ease-out;
									transition-timing-function: ease-out;
								}
								&:hover,
								&:focus,
								&:active {
									color: var(--dobbies-font-primary-color);
									background: var(--dobbies-bg-color-primary);
									&:after {
										-webkit-transform: scaleX(1);
										transform: scaleX(1);
									}
								}
							}
							@media (max-width: $dobbies-container-max-width-xs) {
								display: block;
							}
						}
						&__alert {
							color: var(--dobbies-msg-alert-color);
							font-size: var(--dobbies-body-font-size-s);
							font-weight: var(--dobbies-font-weight-bold);
							margin-bottom: 15px;
							display: inline-block;
						}
					}
					.msc-btn.close-popup {
						background: transparent;
						@media (max-width: $dobbies-container-max-width-xs) {
							display: block;
							text-align: center;
							width: 100%;
						}
						&:hover {
							text-decoration: underline;
						}
					}
					.name-input-error {
						color: var(--dobbies-msg-alert-color);
						font-size: var(--dobbies-body-font-size-s);
						font-weight: var(--dobbies-font-weight-bold);
						margin-top: 0;
						margin-bottom: 5px;
					}
				}
				.first-and-last-name-input {
					overflow: hidden;
					.first-name-input,
					.last-name-input {
						width: 49%;
						float: left;
						@media (max-width: $dobbies-container-max-width-xs) {
							width: 100%;
							float: none;
						}
					}
					.last-name-input {
						float: right;
					}
				}
				.preferences-not-selected-text-block {
					padding: 0 30px 15px;
					.my-profile-link {
						text-decoration: underline;
					}
					.preferences-alert-text-line2 {
						display: block;
						margin-top: 5px;
					}
				}
			}
			&__footer {
				.dont-show-again-message {
					padding: 0 30px 15px;
					overflow: hidden;
					&-checkbox {
						margin: 0 5px 0 0;
						float: left;
					}
					.dont-show-again-text {
						float: left;
						margin-top: -3px;
					}
				}
			}
		}
	}
}
