// .ms-cta-section {
//     display: flex;
//     flex-wrap: wrap;
//     background: var(--dobbies-homepage-grey);
//     border: 1px solid var(--dobbies-promo-banner-border);
//     line-height: 20px;
//     text-align: center;
//     width: 100%;
//     padding: 10px;
// }

* {
  box-sizing: border-box;
}

/* Create three equal columns that floats next to each other */
.cta-column {
  float: left;
  // width: 33.28%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F8F8F8;
  flex: 1;
  //border-right: 1px solid;
  /* Should be removed. Only for demonstration */
}

// .cta-column:last-child {
//   //border-right: none;
// }
.cta-border{
  border-left: 1px solid #73848c;
  height: auto;
  width: 1.2px;
  margin-top: 7px;
  margin-bottom: 7px;

}

.cta-details {
  margin-left: 1%;
  margin-top: -8px;
}

//mobile view
.cta-m-column {
  padding: 10px;
  display: flex;
  justify-content: center;
  // flex-direction: column;
  align-items: center;
  background-color: #F8F8F8;
  min-height: 80px;
  /* Should be removed. Only for demonstration */
  @media (max-width: $msv-breakpoint-l) {
    padding: 0;
}
}


// changes requested on 8 august 2022
.cta-m-details {
  margin-left: 3%;
  line-height: 1vh;
}

.cta-details > p {
  margin-left: 0;
  color: black;
}

.cta-details > a:link {
  text-decoration: none;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.cta-row {
  line-height: 0;
  background-color: #F8F8F8;
  // margin-top: 2px;
}
.ms-cta-section > .row > .carousel-root > div.carousel.carousel-slider > div > ul > .slide {
  min-width: 100% !important;
}
.ms-cta-section {
  @media  (min-width: 450px)  {
    //s
    .cta-column {
      // width: 24.80% ;
      font-size: 0.6rem;

      h6 {
        font-size: 0.8rem;
      }

    }
  }

  .carousel {
    .slide {
      img {
        @media (max-width: $dobbies-container-max-width-s) {
          width: 60px;
        }
      }

      iframe {
        @media (max-width: $dobbies-container-max-width-s) {
          margin: 0;
        }
      }
    }
  }

  @media (min-width: 700px) {
    //m
    .cta-column {
      // width: 24.87% !important;
      font-size: 0.9rem;

      h6 {
        font-size: 0.9rem;
      }

    }

  }


  @media (min-width: 1000px) {
    //l
    .cta-column {
      // width: 24.91%  !important;
      font-size: 0.9rem;

      h6 {
        font-size: 1rem;
      }

    }
  }
  @media (min-width: 1400px) {
    //l
    .cta-column {
      // width: 24.93% !important;
      font-size: 1rem;

      h6 {
        font-size: 1rem;
      }

    }
  }
  @media (min-width: 2000px) {
    //xl
    .cta-column {
      // width: 33.27% !important;
      font-size: 1.2rem;

      h6 {
        font-size: 1.2rem;
      }

    }
  }
}
@media  (min-width: 450px)  {
  //s
  .ms-header {
    padding-left: 0px !important ;
    padding-right: 0px !important;

  }
  

}

//top trustpilot-widget

.cta-column, 
.cta-m-column {
  @media screen and (min-width: 768px) and (max-width: 1210px) {
    position: relative;
  }
  > .trustpilot-widget {
      top: 4px;
      right: 35px;

      @media screen and (min-width: 768px) and (max-width: 1210px) {
        top: 16px;
        right: 30px;
        width: 275px;
        position: absolute !important;
			}

      @media screen and (min-width: 768px) {
        right: 30px;
			}

      @media screen and (min-width: 810px) {
        right: 45px;
			}

      @media screen and (min-width: 992px) {
        right: 55px;
			}
  }
}

.pc {
  .cta-column {
    > .trustpilot-widget {
        iframe {
          @media (max-width: 618px) {
            height: 45px !important;
            top: -12px;
            left: 15%;
          }
        }
    }
  }
}

//padding trustpilot
.trustpilot_padding {
  padding-top: 20px;
  padding-bottom: 20px;
}
//footer trustpilot css
.col-2-trustpilot{
  display: flex;
  justify-content: center;
}

footer {
  .col-2-trustpilot {
    @media  (max-width: 458px)  {
      padding-left: 50px;
      width: 200px;
      margin: 0 auto;
    }

    .ms-footer__list {
      @media  (max-width: 767px)  {
        position: relative;
        right: 32px;
      }
      @media  (max-width: 458px)  {
        right: 12px;
      }
    }
  }

  .trustpilot-widget {
    @media  (min-width: 992px)  {
      position: relative;
      bottom: 9px;
    }
    @media  (max-width: 767px)  {
      position: relative;
      width: 220px;
      right: 0;
      top: 5px;
    }
    @media  (max-width: 458px)  {
      top: auto;
      right: 20px;
    }
  }
}
// remove 'great' word from trustpilot at end of the homepage.
.trustpilot-container{
  .trustpilot-widget{
    position: relative;
    &::before{
      content: '';
      width: 61px;
      height: 27px;
      background: white;
      display: inline-block;
      position: absolute;
      left: 70px;
      z-index: 9;
      @media (max-width: 560px) {
        left: 0;
        width: 100%;
      }

    }
  }
    // adjust trustpilot margin at end of the homepage
  margin-bottom: 20px;
  @media (max-width: $msv-breakpoint-l) {
    margin-top: 10px;
}
}