
$promo-banner-line-height: 20px;
$promo-banner-bottom-padding: 5px;
$promo-banner-top-padding: 8px;
$promo-banner-width-mobile: calc(100% + 24px);

//style presets
:root {
    --dobbies-promo-banner-bg: #{$dobbies-header-grey};
    --dobbies-promo-banner-border: #{$dobbies-header-grey};
    --dobbies-promo-banner-font-color: var(--dobbies-grey);
    --dobbies-promo-banner-font-size: var(--dobbies-body-font-size-m);
 }

.ms-promo-banner {
    display: flex;
    flex-wrap: wrap; 
    background: var(--dobbies-homepage-grey);
    border: 1px solid var(--dobbies-promo-banner-border);
    padding-bottom: $promo-banner-bottom-padding;
    text-align: center;
    width: 100%;
    padding: 10px;
    @media screen and (max-width: $dobbies-container-max-width-s) {
        margin: 0 -12px 10px;
        width: $promo-banner-width-mobile;
        .ms-promo-banner__text,
        .ms-promo-banner__link {
            font-size: var(--dobbies-body-font-size-s);
        }
    }
    &.alignment__center {
      text-align: center;
    }
    &.alignment__left {
        text-align: left;
    }
    &.alignment__right {
        text-align: right;
    }

    .msc-carousel__indicators {
        display: none;
    }
    .ms-promo-banner__content {
        @media screen and (min-width: $msv-container-max-width-s) {
            height: 22px;
            overflow: hidden;
        }
    }
    .ms-promo-banner__text,
    .ms-promo-banner__link {
        @media screen and (min-width: $msv-container-max-width-s) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 22px;
        }
        @media screen and (max-width: $dobbies-container-max-width-s) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            padding: 0;
        }
    }
    .ms-promo-banner__text,
    .ms-promo-banner__link {
        color: var(--dobbies-promo-banner-font-color);
        font-size: var(--dobbies-promo-banner-font-size);
        font-style: normal;
        font-weight: var(--msv-font-weight-normal);
    }

    .ms-promo-banner__link {
        text-decoration: none;
    }

    .msc-carousel__inner {
        .msc-carousel__item {
            &.active {
                text-decoration: none;
            }
        }
        color: var(--dobbies-font-primary-color);
    }

    .ms-promo-banner__close-button {
        color: var(--dobbies-font-secondary-color);
        background-color: transparent;
        border: none;
        display: none;
        cursor: pointer;
        &:after {
            @include msv-icon();
            content: $msv-x-shape;
        }
        .ms-promo-banner__close-button__label {
            display: none;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-promo-banner .ms-promo-banner__carousel {
        width: 98%;
        margin: 0 1%;
    }
}
