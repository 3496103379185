$msv-checkout-button-margin: 15px auto;
$msv-checkout-button-text-align: center;
$msv-checkout-button-align-items: center;
$msv-checkout-button-justify-content: center;
$msv-checkout-button-width: 100%;
$msv-checkout-button-border-radius: 2px;
$divider-vertical-margin: 20px;
$cart-items-right-margin: 40px;
$cart-empty-image-width: 183px;
$cart-empty-image-width-m: 80px;
$summary-min-width: 320px;
$summary-max-width: 480px;
$cart-heading-bottom: 20px;
$cart-heading-top: 32px;
$cart-cartline-wrapper-margin-right: 20px;
$cart-cartline-wrapper-margin-bottom: 20px;
$cart-cartline-line-padding-right: 20px;
$cart-content-margin-left: 195px;
$cart-cartline-line-product-padding-bottom: 10px;
$cart-cartline-line-product-price-padding-top: 10px;
$cart-cartline-line-product-price-margin-left: 0px;
$cart-cartline-item-content-margin-left-m: 195px;
$cart-promo-code-input-width-m: 170px;
$cart-order-summary-padding-m: 20px;
$cart-promo-code-input-height: 46px;

//style presets
:root {
    // heading
    --msv-cart-heading-font-size: var(--msv-heading-font-size-s);
    --msv-cart-heading-font-color: var(--dobbies-font-primary-color);
}

.free-delivery-banner {
    margin-bottom: 20px;
    background: $dobbies-header-grey;
    padding: 18px 0;
    font-size: var(--dobbies-body-font-size-xml);
    line-height: 30px;
    margin-top: 20px;
    @media (max-width: $dobbies-container-max-width-xl) {
        font-size: var(--dobbies-body-font-size-ml);
        line-height: 24px;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    h2 {
        font-size: 30px;
        //line-height: 1.85;
        text-transform: uppercase;
        margin-bottom: 0;
        @media (max-width: $dobbies-container-max-width-s) {
            font-size: 24px;
        }
    }

    p, p strong {
        font-size: 16px;
        font-weight: 500;
        @media (max-width: $dobbies-container-max-width-s) {
            font-size: 14px;
        }
    }
    p strong {
        font-weight: bold;
    }

    @media (max-width: $msv-breakpoint-sm) {
        height: auto;
        padding: 20px 0;
        
        .container {
            //height: 75px;
            justify-content: center;
        }
        p {
            display: none;
        }
    }
}

.ms-text-block {
    .free-delivery-banner {
        p {
            margin-bottom: 0;
        }
    }
}

.ms-cart-checkout-button {
    width: 100%;
    span {
        display: block;
        text-align: center;
        margin: 8px 0;
        font-size: $dobbies-font-size-l;
        font-weight: $dobbies-font-weight-bold;
        line-height: 26px;
    }
}

.credit-availability-banner {
    text-align: center;
    background: $white;
    padding-top: 10px;
    width: 100%;
    .promo-container {
        padding: 10px;
        font-size: var(--dobbies-body-font-size-l);
        color: var(--dobbies-font-secondary-color);
        background: var(--dobbies-font-primary-color);
    }
    .credit-info {
        padding-bottom: 15px;
    }
    h2 {
        margin: 0;
        font-size: var(--dobbies-body-font-size-xl);
        line-height: $dobbies-line-height-xl;
    }
    .ms-text-block a {
        display: inline-block;
        margin-bottom: 5px;
        text-decoration: underline;
        color: var(--dobbies-font-secondary-color);
        font-weight: var(--dobbies-font-weight-heavy);
        &:hover {
            text-decoration: underline;
        }
    }
    .terms-conditions p {
        font-size: 11px;
    }

    @media (max-width: $msv-breakpoint-l) {
        .promo-container {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
    }

    @media (max-width: $msv-breakpoint-sm) {
        .promo-container {
            display: block;
        }
    }
}

.msc-price.msc-order-summary__value {
    .msc-price__actual {
        font-size: var(--dobbies-body-font-size-l);
        font-weight: var(--dobbies-font-weight-normal);
        @media (max-width: $dobbies-container-max-width-tablet) {
            font-size: var(--dobbies-body-font-size-m);
            line-height: 22px;
        }
    }
}

.msc-cart-line-item-subtotal-amount {
    right: 0;
    bottom: 20px;
    position: absolute;
    
    .msc-price {
        .msc-price__actual {
            font-size: 24px;
            font-weight: bold;
            @media (max-width: $dobbies-container-max-width-l) {
                font-size: $dobbies-font-size-ml;
                line-height: 22px;
            }
            @media (max-width: $msv-breakpoint-sm) {
                font-size: $dobbies-font-size-l;
            }
        }
        .msc-price__strikethrough {
            font-size: 24px;
            font-weight: var(--dobbies-font-weight-bold);
            @media (max-width: $dobbies-container-max-width-l) {
                font-size: $dobbies-font-size-ml;
                line-height: 22px;
            }
            @media (max-width: $msv-breakpoint-sm) {
                font-size: $dobbies-font-size-l;
            }
        }

        .msc-price__strikethrough {
            font-weight: 500;
        }
    }

    @media (max-width: $msv-breakpoint-sm) {
        //bottom: 22px;
        position: unset;
        width: 100%;
        margin-left: 105px;
        order: 7;
    }
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-promo-code__apply-btn {
    @include button-default();
    @include primary-button(
        var(--dobbies-cart-primary-btn-bg),
        var(--dobbies-cart-primary-btn-font-color),
        var(--dobbies-cart-primary-btn-border)
    );
    align-items: $msv-checkout-button-align-items;
    display: flex;
    justify-content: $msv-checkout-button-justify-content;
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-cart__btn-backtoshopping {
    margin: $msv-checkout-button-margin;
    width: $msv-checkout-button-width;
   // border-radius: unset;
}

.msc-cart__btn-checkout {
    margin: 0;
    //height: 78px;
    font-size: var(--dobbies-body-font-size-xml);
    font-weight: var(--dobbies-font-weight-bold);
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    overflow: hidden;
    border: 0;
    padding: 20px;
    border-radius: 0;
   // margin: 20px 0;
   border-radius: 0;
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-secondary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
        color: var(--dobbies-font-primary-color);
        background: var(--dobbies-bg-color-primary);
        &:after {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
    &:disabled,
    &[disabled] {
        cursor: default;
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:hover {
            border-color: $dobbies-gray-300;
            background-color: $dobbies-gray-300;
            color: var(--dobbies-font-secondary-color);
            &:after {
                background-color: $dobbies-gray-300;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
            }
        }
    }
    @media (max-width: 1950px) {
        height: 57px;
        font-size: var(--dobbies-body-font-size-ml);
    }
    @media (max-width: $dobbies-container-max-width-tablet) {
        &::after {
            background: none;
        }

        &:hover,
        &:focus,
        &:active {
            &::after {
                background: var(--dobbies-bg-color-secondary);
            }
        }
    }
    @media (max-width: $msv-breakpoint-m) {
        height: 52px;
    }
    @media (max-width: $msv-breakpoint-sm) {
        height: 46px;
        font-size: var(--dobbies-body-font-size-l);
        margin: 0 0 10px 0;
        padding: 10px 10px 10px 10px;
    }
}

.msc-promo-code__apply-btn {
    @media (max-width: $dobbies-container-max-width-s) {
        font-size: var(--dobbies-body-font-size-l);
    }
}

.msc-cart__btn-guestcheckout {
    margin: 0;
    font-size: $dobbies-font-size-l;
    font-weight: bold;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition-duration: 0.3s;
    overflow: hidden;
    border: 0;
    padding: 0px;
    border-radius: 0;
    height: 46px;
    border-radius: 5px;
    background: transparent;
    color: $dobbies-black;
    border: 1px solid $dobbies-grey;
    @media (min-width: $dobbies-container-max-width-s) {
        max-width: 382px;
        margin: 0 auto;
    }
    @media (min-width: $dobbies-container-max-width-tablet) {
        max-width: 100%;
    }

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-secondary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
        color: var(--dobbies-font-primary-color);
        background: transparent;
        &:after {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
    &:disabled,
    &[disabled] {
        cursor: default;
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:hover {
            border-color: $dobbies-gray-300;
            background-color: $dobbies-gray-300;
            color: var(--dobbies-font-secondary-color);
            &:after {
                background-color: $dobbies-gray-300;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
            }
        }
    }
}

.msc-cart__btn-backtoshopping {
    @include button-link(var(--msv-cart-btn-link-color));
}

.ms-cart {
    display: flex;
    flex-wrap: wrap;

    .ms-cart-header {
        display: none;
    }

    .msc-cart__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-cart-heading-font-size), $dobbies-title-line-height-xs);
        color: var(--msv-cart-heading-font-color);
        flex: 0 0 100%;
        margin-bottom: $cart-heading-bottom;
        margin-top: $cart-heading-top;
    }

    .msc-cartline-wraper {
        border-top: 1px solid var(--msv-cart-border);
        flex: 1;
        margin-bottom: $cart-cartline-wrapper-margin-bottom;
        margin-right: $cart-cartline-wrapper-margin-right;
        @media (max-width: $msv-breakpoint-l) {
            margin-right: 0;
        }
    }

    .msc-cart-lines-item .msc-cart-line__add-to-wishlist ~ .msc-cart-line__remove-item {
        margin-left: auto;
    }

    .msc-cart-line {
        position: relative;

        &__product-image {
            position: absolute;

            .msc-empty_image {
                border: 1px solid var(--dobbies-gray-border-color);
                @include image-placeholder($cart-empty-image-width);
                background-size: 100%;
                width: 175px;
                background-position: center;
            }
        }

        &__content,
        &__bopis-container,
        &__add-to-wishlist,
        &__remove-item {
            margin-left: $cart-content-margin-left;
            font-size: 14px;
        }

        &__add-to-wishlist {
            width: auto;
            display: flex;
            align-items: center;
        }

        &__bopis-container {
            margin-left: 197px;
        }
    }

    .msc-cart-lines-item {
        .restriction-note {
            margin-left: $cart-content-margin-left;
            margin-bottom: 10px;
            margin-top: 10px;
            //padding-right: 120px;
            font-weight: var(--dobbies-font-weight-bold);
            @media (max-width: $msv-breakpoint-l) {
                margin-bottom: 20px;
            }

            &:last-child {
                @media (max-width: $msv-breakpoint-sm) { 
                    margin-bottom: 10px;
                }
            }
        }
    }

    .msc-promo-code {
        &__apply-btn {
           // min-width: auto;
            //width: 123px;
            height: 46px;
            padding: 10px;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            border: 0;
            color: $dobbies-grey;
            background: white;
            border: 1px solid $dobbies-grey;
            border-radius: 6px;
            min-width: 120px;
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-secondary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                &:after {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
            &:disabled,
            &[disabled] {
                cursor: default;
                border-color: $dobbies-grey;
                background-color: white;
                color: $dobbies-grey;
                min-width: 120px;
                font-weight: 700px;
                opacity: 1;

                &:hover {
                    border-color: $dobbies-grey;
                    background-color: #facc00;
                    color: $dobbies-grey;
                    //font-weight: var(--dobbies-font-weight-bold);
                    &:after {
                        //background-color: $dobbies-gray-300;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    .msc-promo-code {
        &__input-box {
            height: $cart-promo-code-input-height;
        }
    }

    .delivery-dispatched-details {
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 10px 20px 10px;
        background: $dobbies-header-grey;
        margin-bottom: 20px;

        &__count {
            width: 200px;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }
        &__right {
            width: calc(100% - 200px);
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }

        & > div {
            > p {
                display: inline-block;
                margin-left: 5px;
            }
        }

        &__count {
            @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-m), $dobbies-line-height-s);
            text-transform: uppercase;
            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                font-size: var(--dobbies-body-font-size-l);
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-l), $dobbies-line-height-xs);
            }
        }
        &__right {
            text-align: right;
            @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-s), $dobbies-line-height-s);
            color: var(--dobbies-font-primary-color);
            @media screen and (max-width: $dobbies-container-max-width-tablet) {
                font-size: $msv-font-size-s;
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                text-align: left;
                margin-top: 10px;
                @include font-content(var(--dobbies-font-weight-normal), $dobbies-line-height-xs);
                font-size: 14px;
            }
            & > div {
                & + div {
                    margin-top: 5px;
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .store-pickup-description {
        margin-left: $cart-content-margin-left;
        width: calc(100% - 435px);
        font-weight: var(--dobbies-font-weight-bold);
        margin-top: 10px;
    }

    .msc-cart-line-item-subtotal-amount {
        .msc-price {
            .msc-price__actual {
                margin-left: 12px;
                @media (max-width: $dobbies-container-max-width-tablet) {
                    margin-left: 0px;
                }
            }

            .msc-price__salePrice {
                .msc-price__actual { 
                    @media (max-width: $dobbies-container-max-width-tablet) {
                        margin-left: 10px;
                    }
                    @media (max-width: $msv-breakpoint-sm) {
                        margin-left: 9px;
                    }
                }
            }
        }
    }

    .msc-order-summary__items {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
    }
    .cart-need-help {
        text-align: center;
        margin-bottom: 10px;
        h3 {
          font-size: $dobbies-font-size-s;
          margin-bottom: 6px;
        }
        p {
          a {
            text-decoration: underline;
          }
        }
      }
}
.join-banner__wrap {
    background: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/MD7NSs') center center no-repeat;
    height: 248px;
    background-size: 100% 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: $dobbies-container-max-width-s) {
        height: 100px;
        justify-content: space-between;
        flex-direction: row;
        padding: 0 53px 0 22px;
    }
    @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-container-max-width-s) {
        background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/MD7T4S);
    }
    @media screen and (min-width: $dobbies-container-max-width-tablet) {
        height: 225px;
        justify-content: center;
        flex-direction: column;
        padding: 0 30px;
    }
    @media screen and (min-width: $dobbies-container-max-width-xl) {
        height: 300px;
        justify-content: center;
        flex-direction: column;
        padding: 0 30px;
    }

    @media screen and (min-width: $dobbies-container-max-width-xl) {
        padding: 0 53px;
    }

    .img-club__wrap {
        img {
            @media screen and (max-width: $dobbies-container-max-width-xl) {
                width: 146px;
            }
        }
    }

    .club-inner__content {
        padding: 30px 0 25px 0;

        @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-container-max-width-s) {
            padding: 0;
            text-align: left;
        }
        @media screen and (min-width: $dobbies-container-max-width-tablet) {
            padding: 25px 0 20px 0;
        }
        @media screen and (min-width: $dobbies-container-max-width-xl) {
            padding: 40px 0 30px 0;
        }
        h2 {
            color: $dobbies-club-yellow;
            font-size: 20px;
            line-height: 24px;
            @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-container-max-width-s) {
                font-size: 16px;
            }
        }
        p {
           max-width: 210px;
            color: $dobbies-white;
            margin: 0 auto;
            @media screen and (max-width: $dobbies-container-max-width-tablet) and (min-width: $msv-container-max-width-s) {
                max-width: 225px;
                margin: 0;
            }
        }
    }

    .inner-signup__button {
        button {
            background: $white;
            width: 132px;
            height: 24px;
            font-size: 12px;
            font-weight: $dobbies-font-weight-bold;
            line-height: normal;
            border: none;
            border-radius: 3px;
            transform: perspective(1px) translateZ(0);
            position: relative;
            transition-duration: 0.3s;
            overflow: hidden;
            @media screen and (min-width: $msv-container-max-width-s) {
                width: 105px;
                height: 24px;
                font-size: 14px;
            }
            @media screen and (min-width: $dobbies-container-max-width-tablet) {
                width: 115px;
                height: 22px;
                font-size: 12px;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                width: 160px;
                height: 30px;
                font-size: 14px;
            }

            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-secondary);
                transform: scaleX(0);
                transform-origin: 0 50%;
                transition-property: transform;
                transition-duration: 0.3s;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                &:after {
                    transform: scaleX(1);
                }
            }
        }
    }
}
// upto 991px
@media screen and (max-width: $dobbies-container-max-width-m) {
    .ms-cart {
        display: block;

        .ms-cart-header {
            // display: flex;
            display: none;
            align-items: center;
            flex-direction: row;
            padding-bottom: 12px;
            border-bottom: 1px solid var(--dobbies-gray-border-color);
           // margin-bottom: 15px;
            .msc-order-summary__line-total {
                border: 0;
                width: 100%;
                display: flex;
                padding-right: 18%;
                justify-content: space-between;
                @media (max-width: $msv-breakpoint-sm) {
                    padding: 0 0 10px 0;
                }
            }
            .msc-cart__btn-checkout {
                width: 50%;
            }
        }

        .msc-order-summary-wrapper {
            width: 100%;
            display: block;
            margin-bottom: 10px;
        }

        .msc-order-summary__items {
            + div {
                width: 100%;
            }
        }
        .store-pickup-description {
            width: calc(100% - 410px);
        }
    }
}
// upto 768px;
@media screen and (max-width: $msv-breakpoint-m) {
    .ms-cart {
        display: block;

        .msc-cartline-wraper {
            margin-right: 0px;
            width: 100%;
            margin-bottom: 0px
        }

        .msc-cart-lines-item {
            .msc-cart-line {
                &__product-image {
                    .msc-empty_image {
                        @include image-placeholder($cart-empty-image-width-m);
                        width: 175px;
                        height: 245px;
                        background-size: 100%;
                        background-position: center;
                    }
                }

                &__content {
                    display: block;
                    margin-left: $cart-cartline-item-content-margin-left-m;
                }

                &__bopis-container,
                &__add-to-wishlist {
                    margin-left: $cart-cartline-item-content-margin-left-m;
                }
                &__remove-item {
                    margin-left: $cart-cartline-item-content-margin-left-m;
                    order: 2;
                }

                &__product {
                    &-quantity {
                        padding: 0;
                        margin-bottom: 10px;
                        padding-right: 50px;
                    }
                    &-savings {
                        margin-left: 0px;
                        text-align: left;
                    }
                    &-price {
                        margin-left: $cart-cartline-line-product-price-margin-left;
                        padding-top: $cart-cartline-line-product-price-padding-top;
                        text-align: left;
                    }
                }
            }
            .restriction-note {
                margin-left: $cart-cartline-item-content-margin-left-m;
            }
        }
        .store-pickup-description {
            margin-left: $cart-cartline-item-content-margin-left-m;
        }
    }
}
@media (max-width: $msv-breakpoint-sm) {
    .ms-cart {
        .msc-order-summary-wrapper {
            // padding-top: 157px;
           // position: relative;
        }

        // .credit-availability-banner {
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   margin: 0;
        //   padding: 0 0 20px;
        //   position: absolute;
        //   .credit-info {
        //     padding-bottom: 10px;
        //   }
        // }

        .ms-cart-header {
            border: 0;
            padding: 0;
            display: block;
            .msc-cart__btn-checkout {
                width: auto;
            }
        }

        .msc-order-summary__line-total {
            padding-right: 0;
        }

        .msc-cart-lines-item {
            .msc-cart-line {
                position: relative;
                padding-right: 30px;
                order: 1;

                &__product-image {
                    position: absolute;

                    .msc-empty_image {
                        width: 88px;
                        height: 124px;
                    }
                }

                &__content,
                &__remove-item {
                    margin-left: 103px;
                }

                &__add-to-wishlist {
                    margin-left: 105px;
                }

                &__bopis-container{
                    margin-left: 105px;
                    order: 5;
                    width: 100%;
                }


                &__product-title {
                    font-size: var(--dobbies-body-font-size-l);
                    line-height: 22px;
                }
            }
            .restriction-note {
                margin-left: 105px;
                padding-right: 70px;
                display: flex;
                order: 4;
                margin-bottom: 10px;
            }
        }
        .store-pickup-description {
            margin-left: 110px;
            width: calc(100% - 200px);
            order: 5;
            margin-bottom: 10px;
            margin-top: 0;
        }
    }
}
.msc-cart-line__product-image img {
    width: 175px;

    @media screen and (max-width: $msv-breakpoint-sm) {
        width: 88px;
    }
}
