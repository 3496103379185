//spacing
$header-container-spacing-desktop: 0;
$header-container-spacing-mobile-left: 12px;
$header-container-spacing-mobile-right: 12px;
$header-container-spacing-mobile: 0px;
$signin-info-spacing-left: 8px;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 20px;
$signin-info-padding: 12px 14px;
$header-height: 32px;
$header-icon-height: 30px;
$header-search-icon-width: 36px;
$header-mobile-height: 102px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$account-links-min-width: 250px;
$signin-link-padding: 13px 11px;
$signin-link-height: 48px;
$header_container-max-width: $msv-breakpoint-xl;
$header-myaccount-margin-left: 8px;
$header-logo-margin-tablet: 13px 0 0 62px;
$header-logo-margin-xs: 15px 0 0 60px;
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 15px;
$header-cart-icon-margin-bottom: 12px;
$header-list-item-link-padding: 0 20px;
$popover-menu-item-height: 48px;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 20px;
$divider-margin-m: 20px 0;
$search-box-height: 32px;
$search-box-height-inside: 30px;
$search-box-padding: 5px 15px;
$header-icon-width: 46px;
$header-icon-width-mobile: 44px;
$cart-count-width: 30px;
$header-sidebar-width: 230px;
$search-container-width: 400px;
$header-sidebar-margin-right: 12px;
$search-suggestion-width: 413px;
$search-suggestion-width-mobile: 100%;
$search-suggestion-top-spacing: 0;
$expanded-search-width: 319px;
$hamburger-icon-margin: -1px 0 0 -4px;
$logo-width-s: 170px;
$logo-width-xs: 150px;

$dobbies-logo-path: "../../../";
//style presets
:root {
	--msv-header-bg: var(--dobbies-bg-color);
	--msv-header-border: var(--dobbies-border-color);
	--msv-header-font-color: var(--dobbies-font-header-color);
	--msv-header-font-size: var(--msv-body-font-size-m);
	--dobbies-header-icon-size: var(--dobbies-icon-font-size-xl);
}

header {
	background-color: var(--msv-header-bg);
}

.logo-container {
	border-top: 1px solid rgba(0, 0, 0, 0.4);
}

.ms-header {
	z-index: auto;
	position: relative;
	background-color: var(--dobbies-bg-color);
	padding-left: $header-container-spacing-mobile;
	padding-right: $header-container-spacing-mobile;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);

	&__modal {
		.msc-modal {
			&__dialog {
				margin: 0;
				max-width: 100%;
			}

			&__content {
				border: 0;
				padding: 0;
				@media screen and (min-width: $msv-container-max-width-s) {
					max-width: calc(100% - 200px);
				}
			}

			&__header {
				border: 0;
				height: 50px;
				margin-bottom: 0;
			}

			&__close-button {
				top: 0;
				right: 0;
				padding: 0;
				width: 52px;
				height: 50px;
				text-shadow: none;
				color: var(--dobbies-bg-color);
				background: var(--dobbies-font-primary-color);
				&:before {
					content: $dobbies-icon-close;
					@include dobbies-icon();
					font-size: var(--dobbies-icon-font-size-xl);
				}
			}
		}
	}

	&__mobile-hamburger-menu-links {
		top: 0;
		left: 0;
		z-index: 2;
		right: 52px;
		position: absolute;

		.ms-signin-info__account-link-button {
			padding: 9px 16px;
			display: block;
			font-weight: var(--dobbies-font-weight-heavy);
			font-size: var(--dobbies-body-font-size-m);
		}

		.msc-btn,
		.ms-signin-info__account-link-button {
			border: 0;
			width: 50%;
			height: auto;
			text-align: left;
			font-size: var(--dobbies-body-font-size-m);
			line-height: 20px;
			padding: 12px 20px;
			background: var(--dobbies-bg-color);
			@media screen and (max-width: $msv-breakpoint-mbl) {
				font-size: var(--dobbies-body-font-size-s);
				padding: 12px 10px;
			}
			&:before {
				font-size: 26px;
				margin-right: 7px;
				@include dobbies-icon();
				vertical-align: middle;
				content: $dobbies-icon-account;
			}
			> div {
				display: inline-block;
			}
			&:first-child {
				border-right: 1px solid var(--dobbies-nav-border-color);
			}
		}
	}

	&__container {

		> .container {
			@media (max-width: $msv-breakpoint-sm) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.ms-header__logo {
			margin-left: 5px;
			a {
				@include vfi();
				display: block;
				background: url("#{$dobbies-logo-path}logo.svg") no-repeat top left;
				background-size: 100%;
				width: 180px;
				height: 50px;
				&:focus {
					outline-offset: -2px;
				}
				@media screen and (max-width: $dobbies-container-max-width-s) {
					width: 140px;
					height: 40px;
					margin-top: 14px;
				}
				picture {
					display: none;
				}
			}
		}

		.ms-header__collapsible-hamburger {
			.ms-header__divider {
				border-bottom: 1px solid var(--dobbies-gray-border-color);
				margin: $divider-margin-m;
			}
		}
	}

	.ms-search__icon,
	.ms-header__signin-button,
	.msc-wishlist-icon,
	.msc-cart-icon,
	.ms-header__profile-button,
	.ms-search__form-cancelSearch {
		color: var(--dobbies-font-header-color);
		@include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $dobbies-line-height-m);
		line-height: 20px;
		justify-content: center;

		&:focus {
			outline-offset: 0px;
		}
	}

	// hiding the header icons from login page
	&.hide-header-options {
		.ms-header__nav-icon,
		.ms-header__account-info.account-desktop,
		.ms-header__account-info.account-mobile,
		.msc-cart-icon,
		.ms-header__divider,
		.msc-wishlist-icon {
			display: none;
		}
	}

	.ms-nav.mobile-vp {
		display: none;
	}

	.msc-btn {
		height: $header-height;
	}

	.ms-header__topbar {
		align-items: center;
		display: flex;
		height: $header-mobile-height;
		justify-content: space-between;
		@media screen and (max-width: $dobbies-container-max-width-m) {
			align-items: start;
		}
	}

	.ms-search {
		display: none;
	}

	.ms-header__desktop-view {
		display: none;
	}

	.ms-header__greeting-text {
		color: $dobbies-grey;
		font-weight: var(--msv-font-weight-bold);
		line-height: 18px;
		padding: 5px 0px;
		padding-top: 15px;
		font-size: var(--dobbies-body-font-size-m);
		border-bottom: 1px solid $dobbies-grey;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0 15px;
		margin-bottom: 5px;

		span {
			margin-left: 3px;
			text-transform: uppercase;
		}
	}

	.ms-header__account-info {
		@include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $dobbies-line-height-m);
		height: $header-height;
		justify-content: flex-end;
		margin-left: $signin-info-spacing-left;
		@media screen and (max-width: $dobbies-container-max-width-s) {
			width: $header-icon-width-mobile;
			margin-left: -6px;
		}
		@media screen and (max-width: $dobbies-container-max-width-xs) {
			margin-left: -10px;
		}

		&__account-link-container {
			height: $account-link-height;
		}

		a {
			align-items: center;
			display: flex;
			font-size: var(--dobbies-body-font-size-s);
			height: 100%;
			padding: 5px 15px;
			height: auto;
			color: $dobbies-grey;

			&:last-of-type(2) {
				border-bottom: 0;
				margin-bottom: 5px;
			}

			&:hover {
				text-decoration: none;
				background-color: var(--dobbies-header-grey);
				color: $dobbies-green;
			}
		}
		.ms-header__sign-up-button {
			font-weight: var(--dobbies-font-weight-bold);
		}

		.ms-header__signin-button {
			background-color: var(--msv-header-bg);
			color: var(--dobbies-font-header-color);
			font-weight: var(--msv-font-weight-normal);

			&:focus {
				outline-offset: -1px;
			}
		}

		.ms-header__user-options-button {
			width: $header-icon-width;
			height: 38px;
			text-decoration: none;
			text-align: center;
			display: block;
			padding: 0;
			background-color: transparent;
			color: var(--dobbies-font-primary-color);
			&:before {
				@include dobbies-icon();
				content: $dobbies-icon-account;
				font-weight: $dobbies-outline-icon-weight;
				font-size: var(--dobbies-header-icon-size);
				margin-top: 1px;
			}
			.ms-profile-button-text {
				display: none;
			}
		}

		.msc-popover {
			background-color: var(--msv-header-bg);
			background-clip: padding-box;
			border: 0.5px solid $dobbies-grey;
			display: block;
			left: 0;
			max-width: $account-links-max-width;
			min-width: $account-links-min-width;
			position: absolute;
			top: 0;
			word-wrap: break-word;
			z-index: 1060;
			margin-top: 0;
			padding-bottom: 5px;

			.msc-btn {
				color: var(--dobbies-font-primary-color);
				font-weight: var(--msv-font-weight-normal);
				justify-content: left;
				&:hover {
					color: $dobbies-green;
				}
			}

			.msc-arrow {
				display: block;
				height: 0.5rem;
				margin: 0 0.3rem;
				position: absolute;
				top: calc((0.5rem + 1px) * -1);
				width: 1rem;

				&::before,
				&::after {
					border-color: transparent;
					border-style: solid;
					border-width: 0 9px 9px 9px;
					content: "";
					display: block;
					position: absolute;
				}

				&::before {
					border-bottom-color: $dobbies-grey;
					top: 0;
				}

				&::after {
					border-bottom-color: var(--dobbies-white-bg);
					top: 1px;
				}
			}
			.msc-popover-inner {
				display: flex;
				flex-direction: column;
				.ms-header__account-link-button {
					border-top: solid 1px var(--dobbies-gray-border-color);
					font-weight: var(--dobbies-font-weight-bold);
				}
				.ms-header__sign-up-button {
					border-bottom: 0;
				}
			}
		}
	}

	.msc-wishlist-icon {
		&:hover {
			text-decoration: none;
		}
	}

	.ms-header__wishlist-desktop {
		align-items: center;
		display: none;
		height: $header-height;
		width: $header-icon-width;
		color: var(--dobbies-font-primary-color);

		@include add-dobbies-icon($dobbies-icon-favourite, before, 400);

		&:before {
			font-size: var(--dobbies-header-icon-size);
			text-align: center;
			vertical-align: text-bottom;
			width: $header-icon-width;
		}

		.msc-wishlist-icon__text {
			display: none;
		}
	}

	.ms-header__nav-icon {
		position: absolute;
		top: 4px;
		left: 0;
		padding: 0;
		align-items: center;
		background: var(--msv-header-bg);
		display: flex;
		height: $header-height;
		justify-content: center;
		width: $header-icon-width;
		@media screen and (max-width: $dobbies-container-max-width-m) {
			top: 10px;
		}
		@media screen and (max-width: $dobbies-container-max-width-s) {
			position: absolute;
			left: 10px;
			top: 18px;
		}

		&:focus {
			outline-offset: -1px;
		}

		&:before {
			@include dobbies-icon();
			content: $dobbies-icon-hamburger;
			font-size: var(--dobbies-header-icon-size);
			width: $header-icon-width;
			margin: $hamburger-icon-margin;
			color: var(--dobbies-font-primary-color);
		}

		&:hover {
			text-decoration: none;
		}
	}

	.msc-cart-icon {
		width: $header-icon-width;
		height: $header-height;
		display: flex;
		align-items: center;
		position: relative;

		&:before {
			@include dobbies-icon();
			content: $dobbies-icon-cart;
			font-size: var(--dobbies-header-icon-size);
			width: $header-icon-width;
			vertical-align: text-bottom;
			text-align: center;
		}

		&:hover {
			text-decoration: none;
		}

		&__count {
			@include font-content-s();
			position: absolute;
			bottom: $header-cart-icon-margin-bottom;
			left: $header-cart-icon-margin-left;
		}
	}

	@media (min-width: $msv-breakpoint-xs) {
		.msc-cart-icon {
			&:before {
				width: $header-icon-width / 2;
			}
		}
	}

	@media (max-width: $msv-breakpoint-xl) {
		.ms-header__logo {
			margin-left: 0;
			a img {
				width: 196px;
			}
		}

		.ms-header__topbar {
			height: 96px;
		}

		.ms-header__header-sidebar {
			margin-right: 4px;
		}

		&__icon-store,
		&__icon-account,
		&__icon-favourite,
		&__icon-cart {
			margin-left: 8px;
		}
	}

	@media (max-width: $msv-breakpoint-l) {
		box-shadow: none;
		padding-left: $header-container-spacing-mobile-left;
		padding-right: $header-container-spacing-mobile-right;

		.ms-header__logo a img {
			width: 206px;
		}

		&__header-sidebar__group-icons {
			margin-right: -4px;
			margin-top: 5px;
		}

		&__container {
			.ms-header__topbar {
				height: 100px;
				padding: 5px 0;
				margin: 0 -20px;
				flex-wrap: wrap;
			}
			.ms-header__collapsible-hamburger {
				.ms-nav {
					.ms-nav__drawer {
						.drawer__button {
							@include font-content(
								var(--msv-font-weight-normal),
								var(--msv-header-font-size),
								$dobbies-line-height-m
							);
							color: var(--dobbies-font-header-color);
							background: transparent;
							text-align: left;
							width: 100%;

							.ms-nav__drawer-open {
								@include add-icon($msv-ChevronUp, after);
							}
							.ms-nav__drawer-close {
								@include add-icon($msv-ChevronDown, after);
							}
							.drawer__glyph__end {
								position: absolute;
								right: 10px;
							}
						}
						.msc-btn {
							outline-offset: -1px;
						}
					}
					.ms-nav__list__item__link {
						@include font-content(
							var(--msv-font-weight-normal),
							var(--msv-header-font-size),
							$dobbies-line-height-m
						);
						align-items: center;
						display: flex;
						padding: $header-list-item-link-padding;
					}
				}
			}
		}
		.ms-header__account-info.account-mobile {
			display: unset;
			margin-left: 0;

			.drawer__button {
				@include font-content(
					var(--msv-font-weight-normal),
					var(--msv-header-font-size),
					$dobbies-line-height-m
				);
				@include add-icon($msv-Contact, before, 400);
				&:before {
					padding-right: $header-icon-padding-right;
				}
				background: transparent;
				text-align: left;
				width: 100%;

				.ms-header__drawer-open {
					@include add-icon($msv-ChevronUp, after);
				}
				.ms-header__drawer-close {
					@include add-icon($msv-ChevronDown, after);
				}
				.drawer__glyph__end {
					position: absolute;
					right: $header-icon-padding-right;
				}
			}
			.ms-header__signin-button {
				display: flex;
				justify-content: flex-start;

				@include add-icon($msv-Contact, before, 400);
				&:before {
					padding-right: $header-icon-padding-right;
				}
			}
			.msc-btn {
				outline-offset: -1px;
			}
		}

		.ms-header__collapsible-hamburger {
			background: var(--msv-header-bg);
			left: 0px;
			margin-top: 2px;
			position: absolute;
			width: 100%;
			z-index: 1002;

			.ms-header__wishlist-mobile {
				text-align: left;
				width: 100%;
			}

			.ms-search {
				justify-content: flex-start;
				display: flex;

				.ms-search__label {
					display: flex;
					width: 100%;

					.ms-search__icon {
						display: flex;
						justify-content: flex-start;
						width: 100%;
					}

					.ms-search__icon-text {
						display: unset;
						padding-left: $header-icon-padding-left;
					}
				}
			}
		}
	}

	.ms-header__nav-icon {
		display: none;
		@media (max-width: $dobbies-container-max-width-m) {
			display: block;
		}
	}

	@media (max-width: $msv-breakpoint-m) {
		.ms-header__topbar {
			height: 114px;
		}
		.ms-header__header-sidebar {
			margin-right: 0;
		}
		.ms-header__nav-icon {
			display: block;
		}
		&__header-sidebar__group-icons {
			margin-right: -12px;
		}
	}

	@media (min-width: $msv-breakpoint-m) {
		padding-left: $header-container-spacing-desktop;
		padding-right: $header-container-spacing-desktop;

		.ms-header__desktop-view {
			display: block;
		}

		.ms-header__topbar {
			margin: 0;
		}

		&__profile-button {
			color: var(--dobbies-font-header-color);
			font-weight: var(--msv-font-weight-normal);
			background: var(--msv-header-bg);
			&:after {
				@include msv-icon();
				content: $msv-ChevronDown;
				font-size: var(--msv-header-font-size);
				margin-left: $header-myaccount-margin-left;
				text-align: center;
				vertical-align: text-bottom;
			}
		}

		&__profile-button[aria-expanded="true"] {
			&:after {
				content: $msv-ChevronUp;
			}
		}

		&__profile-button[aria-expanded="false"] {
			&:after {
				content: $msv-ChevronDown;
			}
		}

		&__divider {
			background: var(--dobbies-gray-border-color);
			height: $divider-margin-height;
			margin-left: $divider-margin-left;
			margin-right: $divider-margin-right;
			width: $divider-margin-width;
		}

		.ms-header__account-info.account-desktop {
			display: block;
		}
		.ms-header__collapsible-hamburger {
			display: none;
			.ms-header__wishlist-mobile {
				text-align: left;
				width: 100%;
			}
		}

		.ms-search {
			display: flex;
			align-items: center;
			&__icon {
				align-items: center;
				font-size: var(--dobbies-body-font-size-m);
				color: var(--dobbies-font-header-color);
				font-weight: normal;
				height: 34px;
				padding: $search-box-padding;
				justify-content: left;
				position: relative;
				border-radius: 4px;
				min-width: 400px;
				width: calc(100% - 12px);
				margin: 0 0 0 4px;
				border: solid 1px var(--dobbies-font-header-color);
				@media screen and (max-width: $dobbies-container-max-width-s) {
					width: 100%;
				}

				&:focus {
					outline: none;
				}
				&:before {
					position: absolute;
					top: 4px;
					right: 7px;
					font-size: var(--dobbies-icon-font-size-m);
					font-weight: var(--dobbies-font-weight-heavy);
				}
			}
			&__icon-text {
				display: block;
			}
			&__searchForm {
				border: 1px solid var(--dobbies-font-header-color);
				height: 34px;
				border-radius: 4px;
				.ms-search {
					&__form-control {
						height: 32px;
						border: 0;
						padding-left: 15px;
						outline: none;
						font-size: var(--dobbies-body-font-size-m);
						background-color: transparent;
						@media screen and (max-width: $dobbies-container-max-width-m) {
							padding-left: 15px;
						}
						@media screen and (max-width: $dobbies-container-max-width-s) {
							max-width: calc(100% - 105px);
						}
					}
				}
				.msc-btn {
					height: $header-icon-height;
					width: $header-search-icon-width;
				}
			}
			&__label {
				@media screen and (max-width: $dobbies-container-max-width-s) {
					width: 100%;
				}
			}
		}

		.ms-header__wishlist-desktop {
			display: block;
			padding: 0;
			margin-left: 11px;
			~ div {
				display: none;
			}
		}

		.ms-cart-icon {
			padding-left: $cart-icon-spacing-left;
			width: unset;

			&:before {
				width: unset;
				margin-right: 4px;
			}
		}
	}

	// Start Our Styling
	&__brand-logo {
		position: relative;
		width: 230px;
		@media screen and (max-width: $dobbies-container-max-width-s) {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			padding-left: 0;
			.ms-header {
				&__logo {
					margin: $header-logo-margin-tablet;
				}
			}
		}
		@media screen and (max-width: $dobbies-container-max-width-m) {
			padding-left: 63px;
			order: 1;
		}
		@media screen and (max-width: $dobbies-container-max-width-s) {
			padding-left: 0;
			.ms-header {
				&__logo {
					max-width: $logo-width-s;
				}
			}
		}
		@media screen and (max-width: $dobbies-container-max-width-xs) {
			.ms-header {
				&__logo {
					max-width: $logo-width-xs;
					margin: $header-logo-margin-xs;
				}
			}
		}
	}
	&__search-container {
		width: $search-container-width;
		position: relative;
		@media screen and (max-width: $dobbies-container-max-width-m) {
			width: 100%;
			order: 3;
		}
		.ms-search {
			&__label {
				@media screen and (max-width: $dobbies-container-max-width-m) {
					width: 100%;
				}
			}
		}
	}
	&__header-sidebar {
		display: flex;
		position: relative;
		width: $header-sidebar-width;
		justify-content: flex-end;
		@media screen and (max-width: $dobbies-container-max-width-m) {
			order: 2;
		}
		@media screen and (max-width: $dobbies-container-max-width-s) {
			flex-direction: column-reverse;
			width: 100%;
		}
		@media screen and (max-width: $dobbies-container-max-width-s) {
			margin-right: 0;
		}
		&__group-icons {
			display: flex;
			@media screen and (max-width: $dobbies-container-max-width-s) {
				margin-top: 7px;
				margin-right: 3px;
				justify-content: flex-end;
			}
		}
		.ms-search {
			&__form {
				position: absolute;
				right: 0;
				top: 1px;
				z-index: 1002;
				width: 100%;
				max-width: 413px;
				@media screen and (max-width: $dobbies-container-max-width-m) {
					max-width: 388px;
					left: initial;
				}
				@media screen and (max-width: $dobbies-container-max-width-s) {
					max-width: 100%;
				}
				&-control {
					max-width: $expanded-search-width;
					&:focus {
						outline: none;
					}
				}
				@media screen and (max-width: $dobbies-container-max-width-s) {
					top: 0;
					background-color: transparent;
				}
				.ms-search {
					&__searchForm {
						height: 34px;
						border: 1px solid var(--dobbies-font-header-color);
						border-radius: 4px;
						background: var(--dobbies-bg-color);
					}
				}
			}
			&__autoSuggest {
				top: 33px;
				left: 7px;
				max-width: $search-suggestion-width;
				margin-top: $search-suggestion-top-spacing;
				@media screen and (max-width: $dobbies-container-max-width-m) {
					left: 0;
				}
				@media screen and (max-width: $dobbies-container-max-width-s) {
					max-width: $search-suggestion-width-mobile;
					margin-top: -2px;
				}
				&__product {
					ul li:first-child {
						font-weight: var(--dobbies-font-weight-heavy);
						font-size: var(--dobbies-body-font-size-m);
					}
				}
				.msc-autoSuggest {
					&__keywordResults,
					&__productResults,
					&__categoryResults {
						&-item {
							border-bottom: solid 1px var(--dobbies-header-grey);
							:hover {
								background-color: var(--dobbies-header-grey);
							}
						}
					}
					&__productResults {
						&-item {
							> a {
								padding: 7px 12px 5px 20px;
							}
						}
					}
				}
			}
		}
	}
	&__icon-store,
	&__icon-account,
	&__icon-favourite,
	&__icon-cart {
		width: $header-icon-width;
		text-decoration: none;
		text-align: center;
		display: block;
		margin-left: 8px;
		@media screen and (max-width: $dobbies-container-max-width-s) {
			width: $header-icon-width-mobile;
			margin-left: 3px;
		}
		@media screen and (max-width: $dobbies-container-max-width-xs) {
			margin-left: -3px;
		}
		@media (min-width: $msv-breakpoint-l) {
			margin-left: 12px;
		}
		&:before {
			@include dobbies-icon();
			font-weight: $dobbies-outline-icon-weight;
			font-size: var(--dobbies-header-icon-size);
		}
	}
	&__icon-store {
		&:before {
			content: $dobbies-icon-store;
		}
	}
	&__icon-account {
		&:before {
			content: $dobbies-icon-account;
		}
	}
	&__icon-favourite {
		@media screen and (max-width: $dobbies-container-max-width-s) {
			display: none;
		}
		&:before {
			content: $dobbies-icon-favourite;
		}
	}
	&__icon-cart {
		position: relative;
		@media screen and (width: $msv-container-max-width-s) {
			margin-right: 8px;
		}
		&.fill {
			.ms-header__icon-cart__count {
				color: var(--dobbies-bg-color);
				top: 11px;
			}
			&:before {
				content: $dobbies-icon-cart-filled;
				color: var(--dobbies-bg-color-primary);
				font-size: calc(var(--dobbies-header-icon-size) - 2px);
				margin-top: 2px;
			}
		}
		&:before {
			content: $dobbies-icon-cart;
		}
		&__count {
			font-size: var(--dobbies-icon-font-size-s);
			position: absolute;
			top: 13px;
			left: 7px;
			width: $cart-count-width;
			@media screen and (max-width: $dobbies-container-max-width-s) {
				left: 6px;
			}
		}
	}

	.ms-search {
		@media screen and (max-width: $dobbies-container-max-width-s) {
			display: flex;
			height: $search-box-height;
			margin: 15px 8px 0 8px;
			position: relative;
			flex-basis: auto;
			border: 0;
			border-radius: 4px;
			border: solid 1px var(--dobbies-footer-font-color);
			&__searchForm {
				height: $search-box-height-inside;
				.msc-btn {
					height: $search-box-height-inside;
					width: $header-search-icon-width;
				}
				.ms-search {
					&__form-control {
						max-width: 100%;
						border: 0;
						height: $search-box-height-inside;
						background-color: transparent;
						padding-left: 11px;
						font-size: var(--dobbies-body-font-size-m);
					}
					.msc-btn {
						height: $header-icon-height;
						width: $header-search-icon-width;
					}
				}
			}
		}
		&__icon {
			@media screen and (max-width: $dobbies-container-max-width-s) {
				&:before {
					position: absolute;
					top: 4px;
					right: 6px;
					font-size: var(--dobbies-icon-font-size-m);
				}
				width: 100%;
				position: relative;
				padding: $search-box-padding;
				justify-content: left;
				.ms-search__icon-text {
					display: block;
					margin-top: -2px;
					font-size: var(--dobbies-body-font-size-m);
				}
			}
		}
		&__label {
			@media screen and (max-width: $dobbies-container-max-width-s) {
				width: 100%;
			}
		}
	}
}

.ms-events-iframe iframe {
	height: 65vh;
}

.ms-promo-banner__slot {
	@media (max-width: $msv-breakpoint-sm) {
		overflow: hidden;
	}
}
