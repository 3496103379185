$tile-padding-bottom-mobile: 48px;
$tile-first-margin-left: 0px;
$tile-last-margin-right: 0px;
$tile-last-padding-bottom-mobile: 0px;
$tile-link-margin-left-right: 24px;
$tile-link-padding-top: 10px;
$featured-content-spacing-inside: 0 8px;
$featured-content-spacing: 0;

$homepage-images-path: '../../../';

:root {
    // link
    --msv-content-block-link-color: var(--dobbies-font-primary-color);
    --msv-content-block-link-font-size: var(--msv-body-font-size-m);
}

.ms-content-block {
    &[data-m-layout='tile'] {
        margin: auto;
        width: fit-content;

        .ms-content-block__link {
            display: block;
        }

        .msc-cta__primary {
            @include button-link(var(--msv-content-block-link-color));
            @include font-content(var(--msv-font-weight-normal), var(--msv-content-block-link-font-size), $dobbies-line-height-m);
        }
        a:hover {
            font-weight: var(--dobbies-font-weight-bold);
        }
        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            text-align: left;

            .ms-content-block__cta {
                a[class^='msc-cta__'] {
                    margin-left: $tile-link-margin-left-right;
                    text-decoration: underline;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }
                }
            }
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;

            .ms-content-block__cta {
                a[class^='msc-cta__'] {
                    margin-right: $tile-link-margin-left-right;

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        &.textplacement__center .ms-content-block__details {
            text-align: center;

            .ms-content-block__cta {
                a[class^='msc-cta__'] {
                    margin-left: $tile-link-margin-left-right / 2;
                    margin-right: $tile-link-margin-left-right / 2;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            padding-bottom: 0;
            width: 100%;

            & .ms-content-block__image img {
                width: 100%;
            }
        }
    }
}

.col-sm:first-child .ms-content-block[data-m-layout='tile']:first-child,
.col-md:first-child .ms-content-block[data-m-layout='tile']:first-child {
    margin-left: $tile-first-margin-left;
}

.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child,
.col-md:last-child .ms-content-block[data-m-layout='tile']:last-child {
    margin-right: $tile-last-margin-right;
}

@media (max-width: $msv-breakpoint-m) {
    &.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child {
        padding-bottom: 0;
    }
}

.dobbies-featured-content,
.in-store {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px 0;
    .heading {
        // @include tile-heading();
        width: 100%;
        text-align: center;
        line-height: initial;
    }
    .ms-content-block {
        width: 100%;
        padding: $featured-content-spacing-inside;
        margin: $featured-content-spacing;
        &__image {
            picture {
                width: 100%;
                > img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        &__details {
            text-align: center;
        }
        &__title {
            @include tile-heading();
            font-weight: var(--dobbies-font-weight-bold);
            margin: 20px 0;

            //   @media (max-width: $msv-breakpoint-l) {
            //     font-size: 20px;
            // }
        }
        &__text {
            > p {
                @include tile-paragraph-oneline();
                min-height: 22px;
                white-space: initial !important;
                margin-bottom: 20px;
                @media (max-width: $dobbies-container-max-width-tablet) {
                    min-height: initial;
                    white-space: initial;
                    margin-bottom: 0;
                }
            }
        }
        &__cta {
            @include tile-link();
        }
        &__link,
        &__image {
            width: 100%;
        }
    }

    @media (max-width: $msv-breakpoint-xl) {
        &:before {
            width: 157px;
            height: 194px;
            background-size: 100%;
        }
        &:after {
            width: 143px;
            height: 136px;
            background-size: 100%;
        }
        .heading {
            margin-bottom: 44px;
        }
        .ms-content-block {
            padding: 0 15px;
            margin: 0;
            &__title {
                line-height: 40px;
                margin: 20px 0 20px;
            }
        }
    }

    @media (max-width: $dobbies-container-max-width-s) {
        .ms-content-block {
            // margin: 21px 0 10px;
            padding: 0;
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.dobbies-featured-content {
    .col-12:first-child {
        .ms-content-block {
            padding-left: 0;
            @media (max-width: $dobbies-container-max-width-s) {
                margin-bottom: 20px;
            }
        }
    }
    .col-12:last-child {
        .ms-content-block {
            padding-right: 0;
            @media (max-width: $dobbies-container-max-width-s) {
                margin-bottom: 0;
            }
            // margin-top: 40px;
        }
    }
}
body .container.dobbies-featured-content,
body .container.in-store-container,
body .container.homepage-container,
body .shop-by-category .container,
body .shop-by-brand .container {
    @media (min-width: $dobbies-container-max-width-xl) {
        max-width: 1200px;
    }
    @media (max-width: $dobbies-container-max-width-s) {
        .col-auto {
            width: 100%;
        }
    }
}
body .container.homepage-container {
    @media (max-width: $dobbies-container-max-width-s) {
        padding-left: 0;
        padding-right: 0;
    }
}

.dobbies-featured-content {
    .heading {
        margin-bottom: 30px;
    }
    .ms-content-block {
        &__title {
            text-transform: uppercase;
            margin: 20px 0;
        }
        &__text {
            margin-bottom: 20px;
            @media (max-width: $dobbies-container-max-width-tablet) {
                margin-bottom: 0px;
            }
        }
    }
    @media (max-width: $msv-breakpoint-xl) {
        .ms-content-block {
            &__title {
                margin: 20px 0 20px;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        padding-top: 0;
        padding-bottom: 50px;
        .ms-content-block {
            // margin: 16px 0 0;
            &__title {
                margin: 10px 0 9px;
            }
        }
    }

    @media (max-width: $dobbies-container-max-width-s) {
        padding-bottom: 0;
        .ms-content-block {
            // margin: 12px 0 38px;
            &__title {
                margin: 13px 0;
            }
        }
    }
}

.shop-by-category {
    background-color: var(--dobbies-homepage-grey);
    padding-top: 35px;
    padding-bottom: 50px;
    .heading {
        @include tile-heading();
        font-size: $dobbies-title-font-size-25;
        width: 100%;
        text-align: center;
        margin-bottom: 21px;
        line-height: 40px;
        text-transform: uppercase;
        font-weight: var(--dobbies-font-weight-bold);
    }
    .default-container {
        padding: 0 10px;
        @media (max-width: $msv-breakpoint-m) {
            padding: 0 12px;
        }
    }
    .ms-content-block {
        margin: 0 10px;
        &__title {
            @include cat-link();
        }
        &__image {
            margin: 0 0 10px;
            img {
                margin: auto;
            }
        }
        &__details {
            text-align: center;
            .msc-cta__primary {
                color: var(--dobbies-font-primary-color);
            }
        }
    }
    .col-md {
        &:last-child {
            .ms-content-block {
                // margin-right: 0;
            }
        }
    }
    @media (max-width: $msv-breakpoint-xl) {
        padding-top: 5px;
        padding-bottom: 46px;
        .heading {
            margin-bottom: 43px;
            margin-top: 30px;
        }
        .ms-content-block {
            &__image {
                margin: 0 0 10px;
            }
        }
    }
    @media (max-width: $msv-breakpoint-m) {
        padding-bottom: 36px;
        .heading {
            margin-bottom: 42px;
        }
        .ms-content-block {
            &__image {
                margin: 0 auto 5px;
                width: 100px;
                display: none;
            }
        }
    }
    // @media (max-width: $dobbies-container-max-width-m) {
    //   .col-md {
    //     &:nth-child(6),
    //     &:nth-child(7) {
    //       display: none;
    //     }
    //   }
    // }
    @media (max-width: $dobbies-container-max-width-s) {
        padding-top: 19px;
        padding-bottom: 34px;
        .heading {
            margin-bottom: 30px;
        }
        .ms-content-block {
            min-height: unset;
            padding-bottom: 10px;
            border-bottom: 1px solid $dobbies-footer-font-color;
            font-size: 14px;
            color: $dobbies-footer-font-color;
            margin-right: 0;
            margin-left: 0;
        }
        .col-md {
            max-width: 100%;
            &:last-child {
                .ms-content-block {
                    border-bottom: 0;
                }
            }
        }
    }
    .col-md:last-child {
        .ms-content-block[data-m-layout='tile']:last-child {
            margin-right: auto;
        }
    }
}

.shop-by-brand {
    padding-top: 35px;
    padding-bottom: 50px;
    .heading {
        @include tile-heading();
        font-size: $dobbies-title-font-size-30;
        width: 100%;
        text-align: center;
        margin-bottom: 21px;
        line-height: 40px;
        text-transform: uppercase;
    }
    .default-container {
        padding: 0 10px;
        @media (max-width: $msv-breakpoint-m) {
            padding: 0 12px;
        }
    }
    .ms-content-block {
        margin: 0 10px;
        &__title {
            @include cat-link();
        }
        &__image {
            margin: 0 0px 10px;
            img {
                margin: auto;
            }
        }
        &__details {
            text-align: center;
            display: none;
            @media (max-width: $msv-breakpoint-m) {
                display: block;
            }
            .msc-cta__primary {
                color: var(--dobbies-font-primary-color);
            }
        }
    }
    .col-md {
        &:last-child {
            .ms-content-block {
                // margin-right: 0;
            }
        }
    }
    @media (max-width: $msv-breakpoint-xl) {
        padding-top: 5px;
        padding-bottom: 46px;
        .heading {
            margin-bottom: 43px;
            margin-top: 30px;
        }
        .ms-content-block {
            &__image {
                margin: 0 0 4px;
            }
        }
    }
    @media (max-width: $msv-breakpoint-m) {
        padding-bottom: 36px;
        .heading {
            margin-bottom: 42px;
        }
        .ms-content-block {
            &__image {
                margin: 0 auto 5px;
                width: 100px;
                display: none;
            }
        }
    }
    @media (max-width: $dobbies-container-max-width-s) {
        padding-top: 19px;
        padding-bottom: 34px;
        .heading {
            margin-bottom: 30px;
        }
        .ms-content-block {
            min-height: 49px;
            padding: 0;
            border-bottom: 1px solid $dobbies-footer-font-color;
            font-size: 14px;
            color: $dobbies-footer-font-color;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .col-md {
            max-width: 100%;
            &:last-child {
                .ms-content-block {
                    border-bottom: 0;
                }
            }
        }
    }
    .col-md:last-child {
        .ms-content-block[data-m-layout='tile']:last-child {
            margin-right: auto;
        }
    }
}

.in-store {
    background-color: var(--dobbies-instore-bg);
    position: relative;
    &:before {
        background-image: url('#{$homepage-images-path}instore-top.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 208px;
        height: 260px;
        @media (max-width: $msv-breakpoint-xl) {
            width: 156px;
            height: 190px;
        }
        @media (max-width: $dobbies-container-max-width-l) {
            width: 156px;
            height: 195px;
        }
        @media (max-width: $dobbies-container-max-width-m) {
            width: 104px;
            height: 130px;
        }
        @media (max-width: $dobbies-container-max-width-s) {
            width: 60px;
            height: 75px;
        }
    }
    &:after {
        background-image: url('#{$homepage-images-path}instore-bottom.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 242px;
        height: 231px;
        @media (max-width: $msv-breakpoint-xl) {
            width: 145px;
            height: 139px;
        }
        @media (max-width: $dobbies-container-max-width-l) {
            width: 142px;
            height: 135px;
        }
        @media (max-width: $dobbies-container-max-width-m) {
            display: none;
        }
    }
    // padding-top: 44px;
    // padding-bottom: 51px;
    .heading {
        margin-bottom: 40px;
    }
    .ms-content-block {
        &__title {
            //margin: 18px 0 10px;
            text-transform: uppercase;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        padding-top: 38px;
        padding-bottom: 20px;
        .heading {
            margin-bottom: 40px;
        }
        .ms-content-block {
            &__title {
                margin: 15px 0 2px;
            }
        }
    }

    @media (max-width: $dobbies-container-max-width-s) {
        padding-top: 38px;
        .heading {
            margin-bottom: 30px;
            padding: 0 50px;
        }
        .ms-content-block {
            &__title {
                margin: 17px 0 9px;
            }
        }
    }
}
