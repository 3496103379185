$msv-checkout-guest-profile-input-margin-top: 4px;
$msv-checkout-guest-profile-input-height: 32px;
$msv-checkout-guest-profile-input-label-width: 100%;
$msv-checkout-guest-profile-input-text-max-width: 610px;
$msv-checkout-guest-profile-error-icon-margin-right: 8px;

.ms-checkout-guest-profile {
  &__add-contact-info
  {
    width: 100%;
  }

  &__input {
    display: flex;
    flex-wrap: wrap;
    &.is-invalid {
      .ms-checkout-guest-profile__input-error {
        order: 3;
        color: var(--dobbies-msg-alert-color);
        font-size: var(--dobbies-body-font-size-s);
        margin: 5px 0;
      }
    }
  }

  &__selected-email {
    @include font-content-m(var(--msv-font-weight-heavy));
  }

  &__input-text {
    @include vfi();
    @include form-input-el($dobbies-white);
    display: block;
    flex-grow: 1;
    height: 60px;
    border: 1px solid var(--dobbies-font-primary-color);
  }

  &__input-label {
    @include font-content-s();
    width: $msv-checkout-guest-profile-input-label-width;
    margin-bottom: $msv-checkout-guest-profile-input-margin-top;
  }

  &__input-error {
    @include form-input-error-message();
    display: block;
    width: 100%;
  }
}
