// desktop
$cookie-compliance_container-padding-l: 60px;
$cookie-compliance_container-padding-m: 20px;
$cookie-compliance_container-padding-s: 16px;
$cookie-compliance_container-max-width: $msv-breakpoint-xl;
$cookie-compliance_content-width:100%;
$cookie-compliance_accept-btn-margin-left: 48px;
$cookie-compliance_accept-btn-width: 160px;

// mobile 
$cookie-compliance_accept-btn-width-mobile: 243px;
$cookie-compliance_accept-content-margin-bottom-mobile:24px;

// style preset
:root {
  // background
  --msv-cookie-compliance-bg-color:var(--dobbies-bg-color);

  // font
  --msv-cookie-compliance-font-color:var(--dobbies-font-primary-color);
  --msv-cookie-compliance-font-size:var(--msv-body-font-size-m);

  // link
  --msv-cookie-compliance-link-color:var(--dobbies-font-primary-color);

   // primary button
   --msv-cookie-compliance-primary-btn-bg: var(--dobbies-accent-brand-color);
   --msv-cookie-compliance-primary-btn-font-color: var(--dobbies-font-secondary-color);
   --msv-cookie-compliance-primary-btn-border: var(--dobbies-accent-brand-color);
}

.ms-cookie-compliance {
  background-color: var(--msv-cookie-compliance-bg-color);
  color: var(--msv-cookie-compliance-font-color);
  font-size: var(--msv-cookie-compliance-font-size);

  &__container {
    display: flex;
	justify-content: center;
	align-items: center;
    font-weight: normal;
    margin-bottom: 0;
    max-width: $cookie-compliance_container-max-width;
    margin: 0 auto;

    @media screen and (min-width: $msv-breakpoint-s) {
        padding-left: $cookie-compliance_container-padding-s;
        padding-right: $cookie-compliance_container-padding-s;
    }

    @media (min-width: $msv-breakpoint-m) {
        padding-left: $cookie-compliance_container-padding-m;
        padding-right: $cookie-compliance_container-padding-m;
    }
  }

     &__checkboxes {
      display: flex;
      margin-top: 10px;
    }
        &__checkbox{
          margin-right: 16px;
          &__label{
            margin-right: 4px;
          }
        }
  &__content {
    width:$cookie-compliance_content-width;
    h2 {
      display: block;
      color: var(--dobbies-heading-primary-color);
      font-weight: var(--dobbies-font-weight-heavy);
      font-size: var(--dobbies-body-font-size-ml);
      margin-bottom: 5px;
    }
  }

  &__accept-button {
    float: right;
    margin-left: $cookie-compliance_accept-btn-margin-left;
    width: $cookie-compliance_accept-btn-width;
    @include primary-button(var(--dobbies-bg-color-secondary), var(--msv-cookie-compliance-primary-btn-font-color));
    padding: 0;
    border: 0;
    color: var(--dobbies-font-primary-color);
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    overflow: hidden;
    border: 0;
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--dobbies-bg-color-primary);
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      transform-origin: 0 50%;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
      color: var(--dobbies-font-secondary-color);
      background: var(--dobbies-bg-color-secondary);
      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }

  &__cta-layer .link {
    color:var(--msv-cookie-compliance-link-color);
    font-weight: var(--dobbies-font-weight-bold);
    margin-top: 10px;
  }

  &__text{
    font-size: var(--msv-cookie-compliance-font-size);
    display: inline-block;
    @media (min-width: $dobbies-container-max-width-s) {
      padding-right: 14px;
    }
    @media (min-width: $dobbies-container-max-width-tablet) {
      max-width: 984px;
      padding-right: 14px;
    }

    br {
      @media (min-width: $dobbies-container-max-width-s) {
        display: none;
      }
    }
  }

  &.cookie-redesign {
    background: $dobbies-dark-grey;
    color: $dobbies-white;
    .ms-cookie-compliance {
      &__container {
        flex-wrap: wrap;
        justify-content: start;
        padding: 40px 0;
        max-width: 1028px;
        margin: 0 auto;
        position: initial;
        @media (max-width: $dobbies-container-max-width-tablet) {
          padding: 40px;
          flex-direction: initial;
        }
        @media (max-width: $dobbies-container-max-width-s) {
          padding: 30px 16px;
        }
        @media (min-width: $dobbies-container-max-width-xl) {
          max-width: 1355px;
        }
      }
      &__close {
        position: absolute;
        display: inline-block;
        width: 14px;
        height: 14px;
        overflow: hidden;
        background-color: transparent;
        border: none;
        right: 15px;
        top: 15px;
        @media (min-width: $dobbies-container-max-width-s) {
          right: 40px;
          top: 40px;
        }
        @media (min-width: $dobbies-container-max-width-tablet) {
          width: 16px;
          height: 16px;
        }
        &::before, 
        &::after {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            top: 50%;
            left: 0;
            margin-top: 0px;
            background: $dobbies-white;
        }

        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
      &__content {
        @media (max-width: $dobbies-container-max-width-tablet) {
          margin-bottom: 0;
        }
        @media (max-width: $dobbies-container-max-width-s) {
          margin: 0;
        }
        h2 {
          font-family: $dobbies-font-azosans-medium;
          color: $dobbies-white;
          font-size: $dobbies-title-font-size-25;
          margin-bottom: 20px;
          font-weight: normal;
          @media (max-width: $dobbies-container-max-width-s) {
            font-size: $dobbies-font-size-ml;
          }
        }
        p {
          font-size: $dobbies-font-size-s;

          span {
            display: none;
            @media (min-width: $dobbies-container-max-width-tablet) {
              display: inline-block;
            }
          }
        }
      }
      &__cta {
        margin-top: 20px;
        @media (max-width: $dobbies-container-max-width-s) {
          flex: 1;
        }
        a {
          font-family: $dobbies-font-azosans-medium;
          color: $dobbies-white;
          text-decoration: underline;
          &:hover {
            color: $dobbies-yellow;
          }
        }
        .msc-btn {
          margin-left: 55px;
          height: 34px;
          width: 184px;
          font-family: $dobbies-font-azosans-medium;
          font-size: $dobbies-font-size-m;
          font-weight: $msv-font-weight-500;
          color: $dobbies-dark-grey;
          padding: 0;
          border-radius: 5px;
          min-width: 184px;
          line-height: 36px;
          border: none;
          float: none;
          transform: matrix(1, 0, 0, 1, 0, 0);
          @media (max-width: $dobbies-container-max-width-s) {
            margin-left: 0;
            margin-top: 20px;
            width: 100%;
            height: 30px;
            line-height: 30px;
          }
          &.ms-cookie-compliance__accept-button {
            color: $dobbies-black;
            &:hover {
              color: $dobbies-white;
            }
          }
          &.reject-button {
            background-color: $dobbies-white;
          }
        }
      }
    }
  }
}

.cookie-modal {
  position: absolute;
  left: 50%;
	top: 0px;
  padding: 100px 0;
  background: none;
  width: calc(100% - 20px);
  max-width: none;
  background-color: $dobbies-white;
  max-width: 384px;
  @media (min-width: $dobbies-container-max-width-s) {
    width: 100%;
    max-width: 740px;
    padding: 0;
  }
  @media (min-width: $dobbies-container-max-width-tablet) {
    max-width: 984px;
  }
  @media (min-width: $dobbies-container-max-width-xl) {
    max-width: 1145px;
  }
  .msc-modal {
    &__header {
      padding: 0;
      border: none;
    }
    &__close-button {
      padding: 0;
      line-height: 9px;
      top: 15px;
      right: 15px;
      @media (min-width: $dobbies-container-max-width-s) {
        top: 20px;
        right: 20px;
      }
      @media (min-width: $dobbies-container-max-width-tablet) {
        top: 37px;
        right: 40px;
      }
      &::before {
        font-size: 14px;
        @media (min-width: $dobbies-container-max-width-tablet) {
          font-size: 16px;
        }
      }
    }
    &__content {
      padding: 20px;
      @media (min-width: $dobbies-container-max-width-s) {
        padding: 30px;
      }
      @media (min-width: $dobbies-container-max-width-tablet) {
        padding: 30px 50px;
      }
      @media (min-width: $dobbies-container-max-width-xl) {
        padding: 40px 80px;
      }
    }
  }

  &_head {
    h2 {
      font-size: $dobbies-font-size-ml;
      margin-bottom: 25px;
      @media (min-width: $dobbies-container-max-width-s) {
        margin-bottom: 24px;
        text-align: center;
        font-size: $dobbies-title-font-size-25;
      }
      @media (min-width: $dobbies-container-max-width-xl) {
        margin-bottom: 30px;
      }
    }
  }

  &__cta {
    margin-bottom: 25px;
    display: flex;
    @media (min-width: $dobbies-container-max-width-s) {
      margin-bottom: 30px;
      justify-content: center;
    }
    @media (min-width: $dobbies-container-max-width-xl) {
      margin-bottom: 34px;
    }
    .msc-btn {
      margin: 0;
      height: 40px;
      width: 184px;
      font-family: $dobbies-font-azosans-medium;
      font-size: $dobbies-font-size-m;
      font-weight: $msv-font-weight-500;
      color: $dobbies-white;
      padding: 0;
      border-radius: 5px;
      min-width: 184px;
      line-height: 18px;
      border: none;
      line-height: 42px;
      transform: matrix(1, 0, 0, 1, 0, 0);
      @media (max-width: $dobbies-container-max-width-s) {
        margin-left: 0;
        margin-top: 0px;
        max-width: 160px;
        min-width: 160px;
        height: 30px;
        line-height: 32px;
      }
      @media (max-width: 400px) {
        max-width: 145px;
        min-width: 145px;
        font-size: 14px;
      }
      &.ms-cookie-compliance__accept-button {
        color: $dobbies-black;
        &:hover {
          color: $dobbies-white;
        }
      }
      &.reject-button {
        background-color: $dobbies-gray-100;
        color: $dobbies-white;
        margin-right: 22px;
        @media (min-width: $dobbies-container-max-width-s) {
          margin-right: 40px;
        }
      }
    }
  }

  &__description {
    p {
      font-size: $dobbies-font-size-s;
      margin-bottom: 20px;
      padding-right: 0px;

      a {
        text-decoration: underline;
      }
      span {
        display: none;
        @media (min-width: $dobbies-container-max-width-tablet) {
          display: inline-block;
        }
      }

      div {
        height: 20px;
        display: block;
        @media (min-width: $dobbies-container-max-width-tablet) {
          display: none;
        }
      }
    }
  }

  &__checkboxes {
    margin-bottom: 20px;
    @media (min-width: $dobbies-container-max-width-s) {
      margin-bottom: 30px;
    }
    @media (min-width: $dobbies-container-max-width-xl) {
      margin-bottom: 40px;
    }
    h3 {
      font-size: $dobbies-font-size-s;
      font-weight: $dobbies-font-weight-bold;
      color: $dobbies-grey;
      margin-bottom: 16px;
    }
  }

  .ms-cookie-compliance {
    &__checkboxes {
      flex-direction: column;
    }

    &__checkbox {
      display: flex;
      align-items: start;
      margin-bottom: 20px;
      margin-right: 0;
      &:last-child {
        margin-bottom: 0px;
      }

      &.checked {
        opacity: 0.5;
      }

      &__label {
        min-width: 26px;
        height: 26px;
        margin-right: 10px;
        appearance: none;
        position: relative;
        cursor: pointer;
        &:before {
            position: absolute;
            content: '';
            top: 0px;
            left: 0;
            height: 100%;
            width: 100%;
            border: 1px solid $dobbies-gray-100;
            border-radius: 5px;
        }
        &:after {
            content: '';
            position: absolute;
            display: none;
        }
        &:checked {
            &::before {
                background-color: $dobbies-gray-100;
            }
            &:after {
                display: block;
                left: 10px;
                top: 4.5px;
                width: 7px;
                height: 14px;
                border: solid $dobbies-white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        & + div {
          margin-top: 5px;
        }
      }

      label {
        font-size: $dobbies-font-size-s;
        font-weight: $dobbies-font-weight-bold;
        color: $dobbies-grey;
        margin-bottom: 5px;
      }
      p {
        font-size: $dobbies-font-size-xs;
        color: $dobbies-grey; 
        @media (min-width: $dobbies-container-max-width-tablet) {
          font-size: $dobbies-font-size-s;
        }
      }
    }
  }

  &__save-settings {
    text-align: center;
    button {
      background: none;
      border: none;
      outline: none;
      text-decoration: underline;
      font-family: $dobbies-font-azosans-medium;
      font-size: $dobbies-font-size-s;
      font-weight: $dobbies-font-weight-bold;
      color: $dobbies-grey;
      @media (min-width: $dobbies-container-max-width-tablet) {
        font-size: $dobbies-font-size-m;
      }
      &:hover {
        color: $dobbies-green;
      }
    }
  }
}
.msc-modal.show {
  .cookie-modal {
    transform: translateX(-50%);
    transition: none;
  }
}

@media (max-width: $msv-breakpoint-m) {
  .ms-cookie-compliance {

    &__content {
      margin-bottom: $cookie-compliance_accept-content-margin-bottom-mobile;
    }
    
    &__accept-button {
      float: none;
      margin: 0;
      width: $cookie-compliance_accept-btn-width-mobile;
    }

    &__container {
      align-items: center;
      flex-direction: column;
    }

  }
}