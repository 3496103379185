$msv-write-review-msc-btn-margin: 10px 0;

//style presets
:root {
    --msv-write-review-font-size: var(--msv-body-font-size-s);
    --msv-write-review-font-color: var(--dobbies-font-primary-color);
  
    // heading
    --msv-write-review-heading-font-size: var(--msv-body-font-size-xl);
    --msv-write-review-heading-font-color: var(--dobbies-font-primary-color);

    --msv-write-review-link-size: var(--msv-body-font-size-m);
    --msv-write-review-link-color: var(--dobbies-font-primary-color);

    --msv-write-review-star: var(--dobbies-font-primary-color);
    --msv-write-review-star-size: var(--msv-body-font-size-m);

    //form
    --msv-write-review-form-input-bg: #{$dobbies-white};
    --msv-write-review-form-input-font-color: var(--dobbies-font-primary-color);
    --msv-write-review-form-input-border: transparent;
  
    // primary button
    --msv-write-review-primary-btn-bg: var(--dobbies-accent-brand-color);
    --msv-write-review-primary-btn-font-color: var(--dobbies-font-secondary-color);
    --msv-write-review-primary-btn-border: var(--dobbies-accent-brand-color);
  
    // secondary button
    --msv-write-review-secondary-btn-bg: var(--dobbies-bg-color);
    --msv-write-review-secondary-btn-font-color: var(--dobbies-font-primary-color);
    --msv-write-review-secondary-btn-border: var(--dobbies-accent-brand-color);
}

.ms-write-review {
    margin-bottom: 20px;
    text-align: right;
    //margin-top: -11px;

    @media (max-width: $msv-breakpoint-sm) {
        text-align: left;
        margin-top: 0;
        float: right;
    }
    
    &__sign-in-btn,
    &__toggle{
        @include primary-button(var(--msv-write-review-primary-btn-bg), var(--msv-write-review-primary-btn-font-color), var(--msv-write-review-primary-btn-border));
        margin: $msv-write-review-msc-btn-margin;
        padding: 5px 20px;
        border-radius: 5px;
        height: 30px;
        font-size: 16px;
        font-weight: var(--dobbies-font-weight-bold);
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        border: 0;
        min-width: 150px;
        background-color: white;
        border: 1px solid black;
        color: #111111;
        line-height: 20px;
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
          &:hover,
          &:focus,
          &:active {
            color: var(--dobbies-font-primary-color);
           // background: var(--dobbies-bg-color-primary);
           background: transparent;
            &:after {
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
            }
        }
        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            max-width: 223px;
        }

        // @media (max-width: $dobbies-container-max-width-s) {
        //     // min-width: auto;
        //     // padding: 10px;
        //     // max-width: unset;
        //     float: left;
        //     margin-top: 5px;
        // }
        @media (max-width: $msv-breakpoint-sm) {
            margin: 0;
            font-size: 14px;
            max-width: 150px;
            height: 30px;
            line-height: normal;
        }
    }

    &__toggle{
        margin: 0;
    }
}

.ms-ratings-histogram {
    .msc-rating__empty-star,
    .msc-rating__star,
    .msc-rating__half-star {
        font-size: var(--dobbies-body-font-size-m);
    }
}

.ms-review-modal {
    &-submit {
        @include primary-button(var(--msv-write-review-primary-btn-bg), var(--msv-write-review-primary-btn-font-color), var(--msv-write-review-primary-btn-border));
        margin-right: 16px;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 12px;
        }
    }

    &-cancel {
        @include secondary-button(var(--msv-write-review-secondary-btn-bg), var(--msv-write-review-secondary-btn-font-color), var(--msv-write-review-secondary-btn-border));

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 12px;
        }
    }
    &-header {
        .msc-modal__title {
            @include font-content(var(--msv-font-weight-normal), var(--msv-write-review-heading-font-size), $dobbies-line-height-xl);
            color: var(--msv-write-review-heading-font-color);
        }
    }

    &-rating-label {
        @include font-content(var(--msv-font-weight-light), var(--msv-write-review-font-size), $dobbies-line-height-s);
        color: var(--msv-write-review-font-color);
    }

    &-text,
    &-title {
        @include form-input-el(var(--msv-write-review-form-input-bg), var(--msv-write-review-form-input-font-color));
        @include vfi();
        border: 1px solid  var(--msv-write-review-form-input-border);
        width: 100%;
    }

    &-text {
        height: 162px;
    }

    &-text-label,
    &-title-label {
        @include font-content(var(--msv-font-weight-normal), var(--msv-write-review-heading-font-size), $dobbies-line-height-xl);
        color: var(--msv-write-review-heading-font-color);
        display: block;
        margin-bottom: 4px;
    }

    &-policy {
        @include font-content(var(--msv-font-weight-normal), var(--msv-write-review-font-size), $dobbies-line-height-s);
        color: var(--msv-write-review-font-color);
        
        .msc-link {
            @include font-content(var(--msv-font-weight-light), var(--msv-write-review-link-size), $dobbies-line-height-m);
            color: var(--msv-write-review-link-color);
        }
    }

    &-row {
        margin-bottom: 20px;
        .msc-rating {
            display: inline-block;
            margin-right: 8px;
    
            &__star {
                color: var(--msv-write-review-star);
                font-size: var(--msv-write-review-star-size);
            }
        }
    }

    &-error {
        margin-top: 20px;
        margin-bottom: 0;
    }

    .msc-link {
        @include vfi();
        color: var(--msv-write-review-font-color);
        text-decoration: underline;
    }
}

.msc-modal__dialog.ms-write-review,  .msc-modal__dialog.ms-review-modal{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: left;

    .msc-modal{

        &__title{
            font-size: 22px;
            line-height: 31px;
            text-transform: none;
            margin: 0;
            font-weight: var(--dobbies-font-weight-heavy);
        }

        &__content{
            padding: 40px;
            @media (max-width: $dobbies-container-max-width-s) {
                padding: 15px 20px;
            }
        }

        &__header{
            border: 0;
            padding: 0 0 15px 0;

            .msc-modal__close-button{
                width: 34px;
                height: 34px;
                position: absolute;
                top: 30px;
                right: 40px;
                cursor: pointer;
                text-align: center;
                padding: 0;
                &:before {
                    @include dobbies-icon();
                    content: $dobbies-icon-close;
                    font-size: 34px;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    top: 15px;
                    right: 20px;
                }
            }
        }

        &__body {
            .msc-rating__empty-star {
                font-size: $dobbies-font-size-s;
            }

            .ms-review-modal-title, .ms-review-modal-text {
                border: 1px solid $dobbies-gray-800;
            }

            .ms-review-modal-text-label, .ms-review-modal-title-label {
                font-size: var(--dobbies-body-font-size-m);
                font-weight: var(--dobbies-font-weight-heavy);
            }

            .ms-review-modal-title {
                height: 34px;
            }

            .ms-review-modal-text {
                height: 119px;
            }

            .ms-review-modal-policy {
                padding-bottom: 10px;

                a {
                    text-decoration: underline;
                }
            } 
        }

        &__footer {
            .ms-review-modal-submit{
                font-size: var(--dobbies-body-font-size-m);
                font-weight: var(--dobbies-font-weight-bold);
                line-height: var(--dobbies-body-font-size-m);
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                position: relative;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                overflow: hidden;
                border: 0;
                &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--dobbies-bg-color-secondary);
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 0 50%;
                    transform-origin: 0 50%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                  }
                  &:hover,
                  &:focus,
                  &:active {
                    color: var(--dobbies-font-primary-color);
                    background: var(--dobbies-bg-color-primary);
                    &:after {
                      -webkit-transform: scaleX(1);
                      transform: scaleX(1);
                    }
                }
                &:disabled,
                &[disabled] {
                    cursor: default;
                    border-color: $dobbies-gray-300;
                    background-color: $dobbies-gray-300;
                    color: var(--dobbies-font-secondary-color);
                    &:hover {
                        border-color: $dobbies-gray-300;
                        background-color: $dobbies-gray-300;
                        color: var(--dobbies-font-secondary-color);
                        &:after {
                            background-color: $dobbies-gray-300;
                            -webkit-transform: scaleX(0);
                            transform: scaleX(0);
                        }
                    }
                }
                @media (max-width: $msv-breakpoint-m) {
                    width: auto;
                    min-width: 50%;
                }
            }

            .ms-review-modal-cancel{
                border: 0;
                text-decoration: underline;
                font-size: var(--dobbies-body-font-size-m);
                font-weight: var(--dobbies-font-weight-heavy);
                background: unset;

                &:hover, &:active {
                    background-color: unset;
                    color: var(--msv-reviews-list-secondary-btn-font);
                }

                @media (max-width: $msv-breakpoint-m) {
                    width: auto;
                    min-width: 50%;
                }
            }
        }
    }
}