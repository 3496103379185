@keyframes slide-down {
	from {
		max-height: 0;
	}
	to {
		max-height: 100vh;
	}
}

@keyframes slide-left {
	from {
		left: -100vw;
	}
	to {
		left: 0;
	}
}

.overlay-module-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.75);
	opacity: 0;
	&.fade-in {
		opacity: 1;
		transition: all 0.3s ease-in;
		pointer-events: all;
	}
	&.fade-out {
		opacity: 0;
		transition: all 0.3s ease-in;
		pointer-events: none;
	}
}

.slide-down {
	overflow: hidden;
	transition: all 0.4s ease-in;
}

.slide-up {
	overflow: hidden;
	transition: all 0.4s ease-out;
	max-height: 0;
}

.slide-down-animation {
	overflow: hidden;
	animation: slide-down 0.5s ease-in;
}
