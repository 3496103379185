$navigation-parent-menu-margin-left: 24px;
$navigation-parent-menu-margin-right: 24px;
$navigation-parent-menu-margin-top: 20px;
$navigation-menu-list-margin-top: 12px;
$navigation-menu-list-min-width: 250px;
$navigation-sub-menu-padding-left: 32px;
$navigation-sub-menu-padding-right: 32px;
$navigation-menu-active-item-font-weight: 600;
$navigation-menu-item-height: 48px;
$navigation-menu-item-line-height: 45px;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-icon-line-height: 40px;
$navigation-menu-item-underline: 6px;

//style presets
:root {
	--dobbies-nav-bg: var(--dobbies-bg-color);
	--dobbies-nav-border: transparent;
	--dobbies-nav-dropdown-border: #{$dobbies-gray-300};
	--dobbies-nav-carot-color: var(--dobbies-icon-color);
	--dobbies-nav-font-color: var(--dobbies-font-primary-color);
	--dobbies-nav-font-size: var(--msv-body-font-size-m);
	--dobbies-nav-font-family: #{$dobbies-primary-font-family};
	--dobbies-nav-title-font-size: var(--msv-body-font-size-l);
}

@mixin sticky-nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

.ms-nav {
	background: var(--dobbies-nav-bg);
	color: var(--dobbies-nav-font-color);
	width: 100%;

	&.desktop-vp {
		display: flex;

		&.sticky-nav {
			@include sticky-nav();

			.ms-header__search-container {
				width: 800px;

				.ms-search {
					width: 100%;

					&__label {
						width: 100%;
					}
				}
			}
		}
	}

	.ms-nav__list__item__button {
		display: flex;
		justify-content: space-between;
	}

	&.parent {
		border-top: 1px solid var(--dobbies-nav-border-color);
	}

	&.mobile-vp {
		height: 100vh;

		>.ms-nav__list {
			.ms-nav__list__item {
				position: relative;
				animation: slide-left 0.3s ease-in;
			}
		}
	}

	&.heading {
		padding: 16px 20px;
	}

	.search-icon:before {
		@include dobbies-icon();
		content: $dobbies-icon-search;
		vertical-align: text-bottom;
		height: 100%;
		position: relative;
		font-size: var(--dobbies-icon-font-size-m);
		font-weight: var(--dobbies-font-weight-heavy);
		display: flex;
		align-items: center;
		margin-left: 15px;
	}

	.search-cross-icon:before {
		@include msv-icon();
		content: $msv-Cancel;
		text-align: center;
		height: 100%;
		margin-left: 15px;
		line-height: 3.1;
	}

	.submenu {
		top: 100%;
		left: 0;
		right: 0;
		z-index: 2;
		width: 100%;
		display: none;
		position: absolute;
		background: $white;
		padding: 0 calc((100% - 1200px) / 2);
		box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
		overflow: hidden;
		transition: 2s all;
		transition-delay: 2s;
		// overflow-y: auto;
		// height: calc(100vh - 345px);
	}

	.extra-menu-items {
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
		z-index: -9;
		display: none;
		top: 102px;
		pointer-events: none;
	}

	&.sticky-nav {
		.extra-menu-items {
			top: 49px;
		}
	}

	// .submenu:hover~.extra-menu-items {
	// 	display: block;
	// }

	&__list {
		&__item {
			@media screen and (min-width: $msv-breakpoint-l) {
				@include font-content(var(--dobbies-font-weight-bold),
				var(--dobbies-body-font-size-m),
				18px);
				font-style: normal;
				white-space: nowrap;
			}

			&__image {
				position: absolute;
				right: 0;
			}
		}
	}

	&__list {
		&.level-2 {
			width: 100%;
		}
	}

	>.ms-nav__list {
		background: var(--dobbies-nav-bg);
		align-items: center;

		>.ms-nav__list__item {
			background: var(--dobbies-nav-bg);

			&:hover {
				> .ms-nav__list__item__button {
					color: $white;
					background: $dobbies-green;

					&::after {
						color: $dobbies-white;
					}
				}

				.submenu {
					display: block;
					animation: slide-down 0.3s ease-in;

					& ~ .extra-menu-items {
						display: block;
					}
				}
			}

			.submenu {
				@media screen and (min-width: $msv-breakpoint-l) {
					padding: 20px;
					width: 100%;
					height: auto !important;
					max-width: 1028px;
					margin: 0 auto;
				}
				@media screen and (min-width: $msv-breakpoint-xl) {
					max-width: 1436px;
				}
				.ms-nav__feature {
					>ul {
						overflow-y: auto;
						overflow-x: hidden;
						@media screen and (min-width: $msv-breakpoint-l) {
							height: 100%;
						}
						>li {
							@media screen and (min-width: $msv-breakpoint-l) {
								margin-right: 16px;
								white-space: initial;
								border-top: none;
							}

							&:last-child {
								@media screen and (min-width: $msv-breakpoint-l) {
									margin-right: 0px;
								}
							}
							>ul {
								width: auto;
								min-width: auto;
								padding: 0;
								position: initial;

								>li {
									>div {
										>ul {
											width: auto;
											min-width: auto;
											flex-wrap: wrap;
											display: block;
											@media screen and (min-width: $msv-breakpoint-l) {
												padding: 0;
												position: static !important;
											}

											li {
												margin: 0;
												@media screen and (min-width: $msv-breakpoint-l) {
													border: none;
													color: $dobbies-grey;
												}

												a {
													@media screen and (min-width: $msv-breakpoint-l) {
														border: none;
														margin-top: 15px;
														padding: 0;
														@include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-s), 16px);
													}

													&:hover {
														@media screen and (min-width: $msv-breakpoint-l) {
															text-decoration: underline;
														}
													}
												}

												&:first-child {
													a {
														@media screen and (min-width: $msv-breakpoint-l) {
															border: none;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				> .ms-nav__feature {
					> ul {
						@media screen and (min-width: $msv-breakpoint-l) {
							display: flex;
							padding: 0;
						}

						> li {
							@media screen and (min-width: $msv-breakpoint-l) {
								flex: 1;
							}

							> a {
								@media screen and (min-width: $msv-breakpoint-l) {
									height: 50.8px;
									display: flex;
									align-items: flex-end;
								}
								@media screen and (min-width: $msv-breakpoint-xl) {
									display: block;
									height: auto;
								}
							}
						 }
					}
				}
			}
			

			>.ms-nav__list {
				background: var(--dobbies-nav-bg);
				margin-top: -1px;
				min-width: $navigation-menu-list-min-width;
			}
		}
	}
}

@media screen and (min-width: $msv-breakpoint-xs) {
	.ms-nav {
		&__list {
			display: block;
			flex-wrap: nowrap;

			&__mobile__container {
				padding: 15px 20px;
				display: flex;

				&__button {
					@include vfi();
					background-color: var(--dobbies-nav-bg);
					border: none;
					color: var(--dobbies-icon-color);
					font-family: var(--dobbies-nav-font-family);
					padding-left: 0;
					padding-right: 0;

					&:after {
						top: 2px;
						float: right;
						position: relative;
						@include dobbies-icon();
						color: var(--dobbies-nav-carot-color);
						content: $dobbies-icon-arrow;
						transform: rotate(-90deg);
						-webkit-transform: rotate(-90deg);
						font-size: var(--dobbies-icon-font-size-s);
						font-weight: var(--dobbies-font-weight-heavy);
					}

					&:focus {
						outline-offset: -1px;
					}
				}

				&__span {
					margin-left: 21px;
					font-weight: 600;
					color: var(--dobbies-nav-font-color);
					font-family: var(--dobbies-nav-font-family);
					font-size: var(--dobbies-nav-font-size);
				}
			}

			&__item {
				border-top: 1px solid var(--dobbies-nav-border-color);

				&__image {
					display: block;
				}

				&__button {
					color: var(--dobbies-nav-font-color);
					padding-left: 0;
					padding-right: 0;
					text-align: left;
					width: 100%;

					&:after {
						top: 2px;
						float: right;
						position: relative;
						@include dobbies-icon();
						color: var(--dobbies-nav-carot-color);
						content: $dobbies-icon-arrow;
						transform: rotate(90deg);
						-webkit-transform: rotate(90deg);
						font-size: var(--dobbies-icon-font-size-s);
						font-weight: var(--dobbies-font-weight-heavy);
					}
				}

				&__button,
				&__link {
					padding: 0;
					background-color: var(--dobbies-nav-bg);
					border: none;
					color: var(--dobbies-nav-font-color);
					display: block;
					font-family: var(--dobbies-nav-font-family);
					@include vfi();
					padding: 16px 20px;
					font-size: var(--dobbies-body-font-size-m);

					&:focus {
						outline-offset: -1px;
					}
				}
			}
		}

		>.ms-nav__list {
			outline: none;
			width: 100%;

			.ms-nav__list {
				height: auto;
			}

			>.ms-nav__list__item {
				>.ms-nav__list__item__button {
					color: var(--dobbies-nav-font-color);
				}
			}
		}
	}

	.ms-nav.child {
		position: absolute;
		top: 50px;
		background: var(--dobbies-font-secondary-color);
		border-top: 1px solid $dobbies-vlight-grey;
	}
}

@media screen and (min-width: $msv-breakpoint-l) {
	.ms-nav {
		display: none;

		&.parent {
			//make borders for navigation bar
			// border-top: 1px solid;
			border-bottom: 0;
		}

		&__list {

			//position: absolute;
			&__mobile__container {
				display: none;
			}
		}

		&__list {
			ul {
				position: relative;

				>.ms-nav__list__item {
					border-top: 1px solid $dobbies-vlight-grey;

					&:first-child {
						border: 0;
					}
				}

				.ms-nav__list {
					position: relative;

					.ms-nav__list__item {
						font-weight: normal;
						margin-left: 0;

						.menu-item-image {
							display: none;
						}
					}
				}
			}

			ul li ul {
				top: 0;
				left: 50%;
				right: auto;
				position: absolute !important;
			}
		}

		>.ms-nav__list {
			display: flex;
			flex-wrap: wrap;
			outline: none;
			justify-content: center;
			width: auto;
			margin: 0 auto;

			.ms-nav__list {
				height: auto;
				float: left;
				width: 100%;
				padding: 15px 8px;
				min-width: 330px;
				min-height: 10px;
				display: flex;

				.level-3 {
					display: block;
					position: relative !important;
					left: 0;

					> .ms-nav__list {
						flex-wrap: wrap;
					}
				}
			}

			.menu-item-image {
				float: left;
				margin-top: 20px;

				img {
					height: auto;
				}
			}

			>.ms-nav__list__item:first-child {
				margin-left: 0;
			}

			>.ms-nav__list__item {
				border: 0;

				>.ms-nav__list__item__button,
				>.ms-nav__list__item__link {
					@include vfi();
					@include font-content(var(--msv-font-weight-normal),
						var(--dobbies-nav-title-font-size),
						$dobbies-line-height-l);
					align-items: flex-end;
					background-color: var(--dobbies-nav-bg);
					border: none;
					color: var(--dobbies-nav-font-color);
					display: flex;
					font-weight: var(--dobbies-font-weight-normal);
					font-family: var(--dobbies-nav-font-family);
					justify-content: center;
					position: relative;
					padding: 12px 9px;

					&:hover,
					&:focus {
						color: $white;
						background: $dobbies-green;
					}

					&:hover {
						cursor: pointer;
					}
				}

				>.ms-nav__list__item__button {
					&:after {
						top: 0;
						@include msv-icon();
						color: var(--dobbies-nav-carot-color);
						content: $msv-ChevronDown;
						font-size: var(--dobbies-nav-font-size);
						margin-left: $navigation-menu-button-icon-margin-left;
						display: none;
					}
				}

				.ms-nav__list__item__link {
					height: auto;
					line-height: 20px;
					color: var(--dobbies-nav-font-color);
					padding: 0px;
					justify-content: flex-start;
					width: 100%;
					background: none;
					position: relative;
					border-bottom: 1px solid rgba(17, 17, 17, 0.5); // #111111 with 0.5 opacity
					padding-bottom: 10px;

					&:hover {
						text-decoration: none;
					}
				}

				.ms-expand-arrow {
					display: none;
					&:after {
						@include dobbies-icon();
						content: $dobbies-icon-arrow;
						font-size: var(--dobbies-icon-font-size-s);
						transform: rotate(90deg);
						color: var(--dobbies-icon-color);
						position: absolute;
						top: 12px;
						right: 5px;
					}
				}

				>.ms-nav__list__item__link {
					display: flex;
					padding-left: 0;
					padding-right: 0;
					justify-content: center;
					line-height: $navigation-menu-item-line-height;

					&:hover,
					&:focus {
						background: transparent;
						text-decoration: none;

						&:before {
							width: 100%;
						}
					}
				}

				>.ms-nav__list__item__button:first-child {
					margin-left: 0;
				}

				>.ms-nav__list {
					border: 1px solid var(--dobbies-nav-dropdown-border);
					box-shadow: 0px 8px 15px $dobbies-box-shadow-color;
				}
			}
		}
	}
}

@media screen and (min-width: $dobbies-container-max-width-m) and (max-width: $dobbies-container-max-width-tablet) {
	.ms-nav {
		>.ms-nav__list {
			.menu-item-image {
				width: calc(50% - 131px);
			}
		}
	}
}

@media screen and (min-width: $msv-breakpoint-l) {
	.ms-nav {
		>.ms-nav__list {
			.menu-item-image {
				width: 100%
			}
		}
	}
}

@media screen and (min-width: $msv-container-max-width-m) and (max-width: $dobbies-container-max-width-tablet) {
	.ms-header .ms-nav.mobile-vp {
		display: block;
		height: auto;
	}

	.ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__link {
		display: flex;
		padding-left: 0;
		padding-right: 0;
		justify-content: center;
		line-height: 24px;
		padding: 12px;
	}
}

//VSI Customization - Ticket 11138
.static-menu {
	text-align: center;
}

.msc-menu-dobbies {
	display: inline-block;
	vertical-align: top;
}
.ms-nav.desktop-vp.sticky-nav{
	z-index: 1000
}

.ms-nav .submenu {
	z-index: 1000
}

.mobile.dobbies {
	.ms-header {
		@media screen and (min-width: $dobbies-container-max-width-m) {
			.ms-header__nav-icon {
				display: inline-block;
				top: 10px;
			}
	
			&__brand-logo {
				.ms-header__logo {
					margin: 0px 0 0 62px;
				}
			}
		}
		&__container {
			> div {
				> .ms-nav.mobile-vp {
					@media screen and (min-width: $dobbies-container-max-width-m) {
						display: none;
					}
				} 
			}
		}
	}
	.ms-header__modal {
		@media screen and (min-width: $dobbies-container-max-width-m) {
			.ms-nav.mobile-vp {
				display: inline-block;
	
				.ms-nav__list {
					display: block;
					text-align: left;
	
					&__mobile__container {
						display: inline-block;
					}
	
					.ms-nav__list__item {
						border-top: 1px solid $dobbies-navigation-border-color;
						font-weight: normal;
						.ms-nav__list__item__button {
							justify-content: space-between;
							padding: 16px 20px;
							&:after {
								@include dobbies-icon();
								display: inline-block;
								content: $dobbies-icon-arrow;
							}
								
						}
					}
				}
	
				&>.ms-nav__list {
					&>.ms-nav__list__item {
						.ms-nav__list__item__link {
							padding: 16px 20px;
							border-bottom: none;
							justify-content: start;
							line-height: normal;
						}

						&:hover {
							> .ms-nav__list__item__link {
								background: $dobbies-green;
								color: $white;
							}
						}

						> .ms-nav__list__item__link {
							&:hover {
								background: $dobbies-green;
							}
						}
					}
					.ms-nav__list {
						padding: 0;
					}
				}
			}
	
			.ms-nav.heading {
				display: block;
			}
		}

		.msc-modal__content {
			@media screen and (min-width: $dobbies-container-max-width-s) {
				max-width: 50%;
			}
		}
	}
}