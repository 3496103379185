.app-landing-container {
    .full-width-banner {
        position: relative;
        
        .ms-content-block {
            &__details {
                width: 45%;
                position: absolute;
                top: 0;
                margin: 0;
                text-align: left;
                top: 50%;
                transform: translateY(-50%);
                padding: 0 20px;

                @media (max-width: $msv-breakpoint-m) { 
                    width: 100%;
                    height: 100%;
                }
            }

            &__text {
                @media (max-width: $msv-breakpoint-m) {
                    position: absolute;
                    bottom: 65px;
                    text-align: center;
                    left: 0;
                    font-size: 14px;
                    padding: 0 22px;
                    line-height: 17px;
                    margin-bottom: 0;

                    p {
                        margin-bottom: 0;
                    }
                    
                }

                @media (max-width: 400px) {
                    font-size: 12px;
                    line-height: 18px;
                    padding: 0 20px;
                }
            }

            &__title {
                font-size: 36px;
                margin-top: 0;
                font-weight: $dobbies-font-weight-normal;

                @media (max-width: $dobbies-container-max-width-tablet) { 
                    font-size: 30px;
                }

                @media (max-width: $msv-breakpoint-m) { 
                    font-size: 24px;
                    text-align: center;
                    padding: 0 23%;
                    margin-top: 5%;
                    margin-bottom: 0;
                }
            }

            &__cta {
                display: flex;
                @media (max-width: $msv-breakpoint-m) { 
                    position: absolute;
                    bottom: 20px;
                    width: 100%;
                    justify-content: space-evenly;
                    left: 0;
                    gap: 0;
                    padding: 0;
                    flex-wrap: nowrap;
                    display: block;
                    text-align: center;
                }
                a {
                    width: 135px;
                    height: 42px;
                    font-size: 0;

                    @media (max-width: $msv-breakpoint-m) { 
                        width: 120px;
                        height: 35px;
                    }
                }

                .msc-cta__primary {
                    background: $dobbies-black url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/ME8gug?pubver=0) center center no-repeat;
                    background-size: contain;
                    font-size: 0;
                    background-size: 142px 44px;
                    border-radius: 5px;
                    margin: 0;
                    margin-right: 15px;
                    @media (max-width: $msv-breakpoint-m) { 
                        background-size: 117px 37px;
                        margin-right: 30px;
                    }
                }
                .msc-cta__secondary {
                    background: $dobbies-black url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/ME8gud?pubver=0) center center no-repeat;
                    background-size: 160px 64px;
                    font-size: 0;
                    border-radius: 5px;
                    margin: 0;
                    padding: 0;

                    @media (max-width: $msv-breakpoint-m) { 
                        background-size: 140px 55px;
                    }
                }
            }
        }
    }
    
    .text-with-link {
        margin-top: 0;
        border-top: 2px solid $msv-gray-940;
        border-bottom: 2px solid $msv-gray-940;

        .ms-content-block {
            &__text {
                font-size: 20px;
                h4 {
                    @media (max-width: $msv-breakpoint-m) { 
                        font-size: 18px;
                    }
                }

                p {
                    margin-bottom: 0;
                }
            }

            &__cta {
                display: flex;
                gap: 0;
                @media (max-width: $msv-breakpoint-m) { 
                    width: 100%;
                    justify-content: center;
                }
                a {
                    width: 135px;
                    height: 42px;
                    font-size: 0;
                    min-width: auto;
                    border: none;

                    &::after {
                        display: none;
                    }

                    @media (max-width: $msv-breakpoint-m) { 
                        width: 120px;
                        height: 35px;
                        margin: 0 10px;
                    }
                }

                .msc-cta__primary {
                    background: $dobbies-black url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MEqbjT?pubver=0) center center no-repeat;
                    background-size: contain;
                    font-size: 0;
                    background-size: 142px 44px;
                    border-radius: 5px;
                    margin: 0;
                    margin-right: 15px;
                    @media (max-width: $msv-breakpoint-m) { 
                        background-size: 117px 37px;
                    }
                }
                .msc-cta__secondary {
                    background: $dobbies-black url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MEqbjQ?pubver=0) center center no-repeat;
                    background-size: 160px 63px;
                    font-size: 0;
                    border-radius: 5px;
                    margin: 0;
                    padding: 0;

                    @media (max-width: $msv-breakpoint-m) { 
                        background-size: 135px 55px;
                    }
                }
            }
        }
    }

    .dobbies-top-picks-carousel {
        margin-bottom: 0;

        .msc-ss-carousel {
            &__flipper {
              &,  &--next {
                    top: 38%;

                    @media (max-width: $msv-breakpoint-m) { 
                        top: 35%;
                    }
                }
            }
        }

        .dobbies-top-picks-carousel__item__heading {
            font-size: 20px;
            margin: 10px 0;
        }

        &__item__paragraph {
            margin-bottom: 0;
            padding: 0 45px;

            @media (max-width: $msv-breakpoint-m) { 
                font-size: 18px;
                padding: 0 35px;
            }
        }
    }
}

footer {
    .footer-app-buttons {
        display: none;
        @media (max-width: $dobbies-container-max-width-s) { 
            display: inline-block;
        }
        p {
            display: flex;
        }
        a {
            width: 120px;
            height: 35px;
            font-size: 0;

            &.app-store-link {
                background: $dobbies-black url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MEqbjT?pubver=0) center center no-repeat;
                background-size: contain;
                background-size: 122px 35px;
                border-radius: 5px;
                margin: 0;
                margin-right: 15px;
            }

            &.google-app-link {
                background: $dobbies-black url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/MEqbjQ?pubver=0) center center no-repeat;
                background-size: 140px 55px;
                border-radius: 5px;
            }
        }
    }

    .footer-qr-text {
        float: right;
        margin: 0;
        font-size: 10px;
        margin-bottom: 3px;
        @media (max-width: $dobbies-container-max-width-s) { 
            display: none;
        }
    }

    .footer-qr-code {
        @media (max-width: $dobbies-container-max-width-s) { 
            display: none;
        }
        img {
            width: 38px;
            height: 38px;
            position: relative;
            top: 5px;
        }
    }
}