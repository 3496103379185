$search-autoSuggest-max-width: 400px;
$search-autoSuggest-categoryResults-item-height: 48px;
$search-autoSuggest-categoryResults-item-padding: 0 24px;
$search-autoSuggest-categoryResults-title-color: $dobbies-gray-500;
$search-autoSuggest-categoryResults-title-padding: 16px 24px 8px 12px;
$search-autoSuggest-categoryResults-item-price-color: $dobbies-gray-500;
$search-autoSuggest-keywordResults-item-height: 48px;
$search-autoSuggest-keywordResults-item-padding: 13px 12px 14px 12px;
$search-autoSuggest-picture-height: 50px;
$search-autoSuggest-input-text-height: 45px;
$search-autoSuggest-picture-margin-right: 12px;
$search-autoSuggest-productResults-item-height: 72px;
$search-autoSuggest-productResults-item-grid-columns-width: 52px;
$search-autoSuggest-productResults-item-padding-left: 20px;
$search-autoSuggest-productResults-item-padding-right: 12px;
$search-autoSuggest-productResults-title-color: $dobbies-gray-500;
$search-autoSuggest-productResults-title-padding: 16px 12px 14px 12px;
$search-searchForm-min-width: 240px;
$search-searchForm-height: 48px;
$search-autoSuggest-border-width: 1px;
$search-autoSuggest-top: $search-searchForm-height;
$search-autoSuggest-min-width: $search-searchForm-min-width;
$search-autoSuggest-hover-color: $dobbies-gray-200;
$search-placeholder-text-padding-left: 10px;
$search-form-control-max-width: 306px;
$search-form-cancelBtn-height: $search-searchForm-height;
$search-form-cancelBtn-width: 48px;
$search-form-icon-height: $search-searchForm-height;
$search-form-submitBtn-height: $search-searchForm-height;
$search-form-submitBtn-width: 48px;
$search-form-submitBtn-border-radius: 0px;
$search-form-inactive-width-desktop: 186px;
$search-form-inactive-width-mobile: 48px;

//style presets
:root {
	//Search
	--msv-search-font-color: var(--dobbies-font-primary-color);
	--msv-search-font-size: var(--dobbies-body-font-size-m);
	--msv-search-font-family: #{$dobbies-primary-font-family};

	//Search form
	--msv-search-form-bg: #{$dobbies-gray-100};
	--msv-search-form-border: #{$dobbies-gray-100};
	--msv-search-form-search-btn-color: var(--dobbies-accent-brand-color);
	--msv-search-form-cancel-btn-color: #{$dobbies-gray-100};

	//AutoSuggest
	--msv-autoSuggest-bg: var(--dobbies-bg-color);
	--msv-autoSuggest-border: #{$dobbies-gray-300};
}

.ms-search {
	display: flex;
	justify-content: flex-end;
	flex-basis: 0;
	flex-grow: 1;
	z-index: 100;
	position: relative;

	.bx-hide {
		display: none;
	}

	button {
		border: 0;
	}

	picture {
		grid-column: 1 / 2;
		grid-row-end: span 2;
		height: $search-autoSuggest-picture-height;
		margin-bottom: auto;
		margin-right: $search-autoSuggest-picture-margin-right;
		margin-top: 0;
	}

	&__icon {
		align-items: center;
		background: transparent;
		cursor: pointer;
		display: inline-flex;
		font-family: var(--msv-search-font-family);
		font-size: var(--msv-search-font-size);
		color: var(--msv-search-font-color);
		height: $search-form-icon-height;
		justify-content: center;
		line-height: $dobbies-line-height-m;
		width: $search-form-inactive-width-mobile;

		&:before {
			@include dobbies-icon();
			content: $dobbies-icon-search;
			vertical-align: text-bottom;
		}

		&-text {
			display: none;
		}
	}

	&__form {
		left: 0;
		position: absolute;
		width: 100%;
	}

	&__form-cancelSearch {
		align-items: center;
		background-color: transparent;
		height: $search-form-cancelBtn-height;
		order: 1;
		width: $search-form-cancelBtn-width;
		display: flex;
		padding: 0;

		&:before {
			@include msv-icon();
			content: $msv-Cancel;
			width: $search-form-cancelBtn-width;
			text-align: center;
		}
	}

	&__form-submitSearch {
		align-items: center;
		background-color: transparent;
		border-radius: $search-form-submitBtn-border-radius;
		color: var(--dobbies-font-header-color);
		height: $search-form-submitBtn-height;
		order: 2;
		width: $search-form-submitBtn-width;
		display: flex;
		padding: 0;

		&:before {
			@include dobbies-icon();
			content: $dobbies-icon-search;
			vertical-align: text-bottom;
			width: $search-form-submitBtn-width;
			font-size: var(--dobbies-body-font-size-xl);
			font-weight: var(--dobbies-font-weight-heavy);
		}
	}

	&__autoSuggest {
		background: var(--msv-autoSuggest-bg);
		box-sizing: border-box;
		justify-content: flex-end;
		left: 0;
		top: 0;
		margin-top: 17px;
		position: absolute;
		width: calc(100% - 15px);
		z-index: 101;
		@media screen and (max-width: $dobbies-container-max-width-s) {
			margin-top: 32px;
			width: calc(100% - 2px);
		}
		&__keyword,
		&__product,
		&__category {
			border: $search-autoSuggest-border-width solid var(--msv-autoSuggest-border);
			border-top: 0;
		}
	}

	&__searchForm {
		display: flex;
		height: $search-searchForm-height;
		justify-content: flex-end;
		min-width: $search-searchForm-min-width;
		background-color: white;
		position: relative;
		z-index: 101;
		@media screen and (max-width: $dobbies-container-max-width-m) {
			margin: 0 0.5px;
		}
	}

	&__form-control {
		@include vfi();
		border: 1px solid var(--dobbies-font-header-color);
		font-size: var(--msv-search-font-size);
		color: var(--msv-search-font-color);
		order: 0;
		padding-left: $search-placeholder-text-padding-left;
		width: 100%;
		height: $search-autoSuggest-input-text-height;

		&::-ms-clear {
			display: none;
		}
	}

	@media (min-width: $msv-breakpoint-l) {
		&__form {
			left: unset;
			position: unset;
			top: unset;
		}

		&__autoSuggest {
			width: 100%;
			position: absolute;
			top: 17px;

			&__category {
				border-bottom: $search-autoSuggest-border-width solid var(--msv-autoSuggest-border);
			}
		}

		&__icon {
			width: $search-form-inactive-width-desktop;

			&-text {
				display: unset;
			}
		}
	}
}

a.msc-autoSuggest__productResults-item {
	display: grid;
	grid-template-columns: $search-autoSuggest-productResults-item-grid-columns-width;
	padding-left: $search-autoSuggest-productResults-item-padding-left;
	padding-right: $search-autoSuggest-productResults-item-padding-right;
	width: 100%;
}

.msc-autoSuggest {
	&__categoryResults-item {
		display: flex;
		font-size: var(--msv-search-font-size);
		height: $search-autoSuggest-categoryResults-item-height;

		a {
			@include vfi();
			color: var(--msv-search-font-color);
			display: flex;
			height: $search-autoSuggest-categoryResults-item-height;
			line-height: $search-autoSuggest-categoryResults-item-height;
			padding: $search-autoSuggest-categoryResults-item-padding;
			width: 100%;
		}

		:hover {
			background-color: $search-autoSuggest-hover-color;
		}
	}

	&__categoryResults-title {
		color: $search-autoSuggest-categoryResults-title-color;
		font-size: var(--dobbies-body-font-size-m);
		font-weight: var(--dobbies-font-weight-heavy);
		padding: $search-autoSuggest-categoryResults-title-padding;
		text-transform: none;
	}

	&__keywordResults-item {
		display: flex;
		font-size: var(--msv-search-font-size);
		height: $search-autoSuggest-keywordResults-item-height;

		a {
			@include vfi();
			color: var(--msv-search-font-color);
			display: flex;
			height: $search-autoSuggest-keywordResults-item-height;
			padding: $search-autoSuggest-keywordResults-item-padding;
			width: 100%;
		}

		:hover {
			background-color: $search-autoSuggest-hover-color;
		}
	}

	&__productResults-item {
		@include vfi();
		display: flex;
		font-size: var(--msv-search-font-size);
		color: var(--msv-search-font-color);
		height: $search-autoSuggest-productResults-item-height;

		&-price {
			color: $search-autoSuggest-categoryResults-item-price-color;
			grid-column: 2 / 2;
		}

		&-text {
			grid-column: 2 / 2;
		}

		:hover {
			background-color: $search-autoSuggest-hover-color;
		}
	}

	&__productResults-title {
		color: $search-autoSuggest-productResults-title-color;
		font-size: var(--msv-body-font-size-s);
		font-weight: var(--msv-font-weight-bold);
		line-height: $dobbies-line-height-s;
		padding: $search-autoSuggest-productResults-title-padding;
		text-transform: none;
	}

	&__screen-reader {
		border: 0;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}
}
