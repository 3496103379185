$buybox-margin-top: 36px;
$buybox-margin-top-mobile: 0;
$buybox-bottom-spacing: 40px;
$buybox-label-margin-bottom: 5px;
$buybox-box-top-margin: 15px;
$buybox-box-bottom-margin: 15px;
$buybox-store-description-margin-bottom: 8px;
$buybox-wishlist-width: 48px;
$buybox-wishlist-width-with-padding: 60px;
$buybox-product-status-padding-top: 18px;
$buybox-quantity-input: 5px;
$buybox-incr-decr: 5px;
$buybox-wishlist-top-postion: 2px;
$gutter-width: 20px;
$media-gallery-width: 50%;
$media-gallery-width-lg: 42%;
$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$dobbies-container-max-width-custom: 1600px;
$dobbies-bg-path: '../../../';

//style presets
:root {
    --msv-buybox-btn-bg: #{$dobbies-white};
    --msv-buybox-btn-font-color: var(--dobbies-font-primary-color);
    --msv-buybox-btn-border-color: var(--dobbies-border-color);
    --msv-buybox-btn-font-size: var(--msv-body-font-size-l);

    //title
    --msv-buybox-title-font-size: var(--dobbies-body-font-size-xl);
    --msv-buybox-title-font-color: var(--dobbies-font-primary-color);

    // rating star
    --msv-buybox-rating-star: $dobbies-yellow;

    --msv-buybox-btn-wishlist-color: var(--dobbies-accent-brand-color);

    //text
    --msv-buybox-text-font-size: var(--msv-body-font-size-m);
    --msv-buybox-text-font-color: var(--dobbies-font-primary-color);

    // primary button
    --msv-buybox-primary-btn-bg: var(--dobbies-accent-brand-color);
    --msv-buybox-primary-btn-font-color: var(--dobbies-font-secondary-color);
    --msv-buybox-primary-btn-border: var(--dobbies-accent-brand-color);

    // secondary button
    --msv-buybox-secondary-btn-bg: var(--dobbies-bg-color);
    --msv-buybox-secondary-btn-font-color: var(--dobbies-font-primary-color);
    --msv-buybox-secondary-btn-border: var(--dobbies-accent-brand-color);
}

.privacy-link-underline {
    text-decoration: underline;
}

.ms-buybox {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    margin-bottom: 20px;
    //padding-bottom: $buybox-bottom-spacing;

    @media (max-width: $dobbies-container-max-width-s) {
        margin-top: $buybox-margin-top-mobile;
        margin-bottom: 0;
    }
    .msc-price {
        .msc-price__actual,
        .msc-price__strikethrough {
            font-size: var(--dobbies-heading-font-size-25);
            line-height: normal;
            //margin-right: 18px;
            @media (max-width: $dobbies-container-max-width-m) {
                font-size: 20px;
            }
        }

        .msc-price__salePrice {
            .msc-price__actual {
                margin-right: 18px;
                @media (max-width: $dobbies-container-max-width-tablet) {
                    margin-right: 14px;
                }
            }
        }
    }

    .msc-add-to-cart {
        .msc-delivery__isHD {
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            margin-right: 5px;
            background-position: center;
            background-image: url('#{$dobbies-bg-path}svgs/home-delivery.svg');
            margin-top: 2px;
        }

        &:hover {
            .msc-delivery__isHD.active {
                background-image: url('#{$dobbies-bg-path}svgs/home-delivery-black.svg');
            }
        }

        &.click-collect-button {
            padding: 0;

            .ms-buybox__find-in-store-button {
                padding: 5px 20px;
                width: 100%;
                justify-content: center;
                font-weight: var(--dobbies-font-weight-bold);
            }
        }
        &.click-collect-disabled-button {
            padding: 0;
            margin-top: 20px;
            margin-bottom: 20px;
            @media (max-width: $msv-breakpoint-l) {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .ms-buybox__find-in-store-button {
                padding: 5px 20px;
                width: 100%;
                justify-content: center;
                font-weight: var(--dobbies-font-weight-bold);
            }
        }
    }

    .msc-delivery__isHD {
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        margin-right: 5px;
        background-image: url('#{$dobbies-bg-path}svgs/home-delivery.svg');
        background-position: center;
        margin-top: 2px;
    }

    .msc-delivery__isHD-active {
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        margin-right: 5px;
        background-image: url('#{$dobbies-bg-path}svgs/home-delivery-black.svg');
        background-position: center;
    }

    .buttons-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: $msv-breakpoint-l) {
            gap: 20px;
        }
    }

    .ms-custom-tabs {
        padding-top: 20px;
    }

    .ms-buybox-custom-tabs {
        border-bottom: 1px solid #d3d3d3;
        cursor: pointer;
        li {
            &:last-child {
                border: none;
            }
        }
    }

    .ms-buybox-attribute-name {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 8px;
        padding: 5px;
        border-right: 1px solid #d3d3d3;
    }

    .ms-buybox-attribute-name-collapsed {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 8px;
        padding: 5px;
        color: var(--dobbies-bg-color-primary);
        &:after {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
        border-right: 1px solid var(--dobbies-bg-color-primary);
    }

    .ms-buybox-attribute-text {
        padding: 10px 0;
        border-top: 2px solid $dobbies-light-grey;
        height: fit-content !important;
        line-height: $dobbies-line-height-m;
        white-space: break-spaces;
    }

    .ms-tabs__list {
        display: flex;
        border-bottom: 0.5px solid $dobbies-grey;

        li {
            padding: 2px 20px;
            position: relative;
            &:first-child {
                &:before {
                    display: none;
                }
                padding-left: 0;
            }
            &:before {
                top: 0;
                left: 0;
                width: 1px;
                bottom: 0;
                content: '';
                position: absolute;
                background: $dobbies-light-grey;
            }
            .ms-tab__list-button {
                font-size: var(--dobbies-body-font-size-s);
                line-height: 18px;
                font-weight: var(--dobbies-font-weight-heavy);
                cursor: pointer;
                text-decoration: none;
                text-transform: capitalize;
                &:hover,
                &.active {
                    color: $dobbies-green;
                }
            }
        }

        border: 0;
        display: block;
        border-top: 0.5px solid $dobbies-grey;
        li {
            padding: 0;
            border-bottom: 0.5px solid $dobbies-grey;
            &:before {
                display: none;
            }
            &:first-child {
                padding: 0;
            }
        }

        .ms-collapse-name_expanded,
        .ms-collapse-name_collapsed {
            padding: 15px 0;
            cursor: pointer;
            position: relative;
            > strong {
                font-weight: var(--dobbies-font-weight-bold);
            }
            &:after {
                top: 1px;
                float: right;
                position: relative;
                @include dobbies-icon();
                transform: rotate(180deg);
                color: #facc00;
                content: $dobbies-icon-arrow;
                font-size: var(--dobbies-icon-font-size-s);
                font-weight: var(--dobbies-font-weight-heavy);
            }
        }

        .ms-collapse-name_expanded {
            &:after {
                transform: rotate(0deg);
            }
        }

        .ms-collapse-text {
            padding-top: 20px;
            border-top: 0.5px solid $dobbies-grey;
            height: fit-content !important;
            line-height: normal;
            white-space: break-spaces;
            margin-bottom: 20px;
            ul,
            ol {
                > li {
                    border: 0;
                    margin: 5px 0;
                }
            }
            p {
                margin-bottom: 20px;
                &:nth-last-child(2),
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        @media (max-width: $dobbies-container-max-width-m) {
            li {
                > div:first-child {
                    //padding: 6px 0;
                    &:first-child {
                        //padding: 6px 0;
                    }
                }
            }
        }
    }

    .ms-tabs_tab-content {
        padding: 15px 0;
    }

    &__inventory-info {
        margin-top: $buybox-box-top-margin;
    }

    &__product-title {
        font-size: 25px;
        font-weight: 500;
        line-height: normal;
        color: #111111;
        margin-bottom: 20px;
        @media (max-width: $dobbies-container-max-width-m) {
            font-size: 20px;
        }
        @media (max-width: $dobbies-container-max-width-s) {
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }

    &__add-to-wishlist-container {
        margin-left: 20px;
        .msc-add-to-wishlist {
            border: 0;
            padding: 0;
            font-size: 30px;
            line-height: 32px;
            background: none;
            color: $dobbies-green;
            margin: 0;
            &:after {
                content: $dobbies-icon-favourite;
                font-family: '$dobbies-icon-font-family';
            }
            &__removing {
                @include add-dobbies-icon($dobbies-icon-favourites-icon-filled, after);
            }
        }
        .ms-wishlist-items {
            &__product-status {
                display: none;
                .msc-alert-success {
                    background: transparent;
                    border: 0;
                    padding: 0;
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    &__inventory-label {
        @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-l), $dobbies-line-height-m);
    }

    &__product-description {
        @include font-content(var(--msv-font-weight-light), var(--msv-buybox-text-font-size), $dobbies-line-height-m);
        color: var(--msv-buybox-text-font-color);
        margin-top: $buybox-box-top-margin;
        margin-bottom: $buybox-box-bottom-margin;
    }

    .email-address-input {
        padding: 15px;
        margin: 2px;
    }

    .email-address-input-text {
        width: 100%;
        height: 40px;
    }
    .product-card-club-plus-icon-pdp {
        border: 2px solid $dobbies-black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;

        @media (max-width: $msv-breakpoint-m) {
            margin-right: 5px;
            margin-left: 10px;
        }

        @media (max-width: $msv-breakpoint-sm) {
            margin-right: 0px;
            margin-left: 10px;
        }
    }
    .product {
        &-card-club-plus {
            &-badge-pdp {
                min-width: 382px;
                position: relative;
                @media (max-width: $dobbies-container-max-width-tablet) {
                    width: 100%;
                    min-width: auto;
                }
            }
    
            &-icon-pdp,
            &-cross-pdp {
                position: absolute;
                right: 0px;
            }

            &-icon-pdp {
                right: 10px;
                border-width: 1px;
                top: 50%;
                transform: translateY(-50%);
                p {
                    position: relative;
                    top: 0px; 
                    font-weight: 500;
                }
            }

            &-cross-pdp {
                right: 8px;
                top: 7px;
                position: relative;
                width: 8px;
                height: 8px;
                float: right;
                cursor: pointer;
                &::after,
                &::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    right: 0;
                    height: 1px;
                    border-radius: 4px;
                    background-color: $dobbies-white;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }

            &-price-pdp {
                width: 160px;
                padding-right: 25px;
                text-align: center;
                color: $dobbies-grey;
                font-size: $dobbies-title-font-size-25;
                font-weight: $dobbies-font-weight-bold;
        
                @media (max-width: $dobbies-container-max-width-m) {
                    font-size: $dobbies-font-size-ml;
                }
            }
    
            &-pdp {
                background: linear-gradient(45deg, #76777B 50%, #FFD047 50%);
                padding: 13.5px 10px;
            }

            &-discount-pdp {
                padding: 13.5px 10px;
                border-radius: 0 !important;
                .product-card-club-plus-price-pdp {
                    padding: 0;
                }
            }
        }

        &-club-plus {
            display: flex;
            justify-content: space-between;
            width: 100%;
            color: $dobbies-club-yellow;
            font-size: 18px;
            text-transform: uppercase;
    
            &-container-info-pdp {
                bottom: 45px;
                background: $dobbies-club-grey;
                z-index: 9;
                position: absolute;
                width: 100%;
            }
    
            &-info-pdp {
                color: $dobbies-white;
                padding: 7px 9px;
                max-width: 250px;
                line-height: 17px;
                font-size: 12px;
                > span {
                    display: block;
                    a {
                        color: $dobbies-white;
                        text-decoration: underline;
                        border: 0;
                    }
                }
            }
        }

        &-badge-pdp {
            // display: flex;
            justify-content: flex-end;
            width: 350px;
            height: 45px;
            margin-top: 24px;
    
            @media (max-width: $dobbies-container-max-width-tablet) {
                justify-content: flex-start;
                width: 100%;
                margin-top: 22px;
            }
    
            @media (max-width: $msv-breakpoint-sm) {
                margin-top: 12px;
            }
        }

        &-badge-pdp-container {
            margin-bottom: 20px;
    
            @media (max-width: $msv-breakpoint-m) {
                display: block;
                width: 100%;
                margin-bottom: 10px;
            }
    
            @media (max-width: $msv-breakpoint-sm) {
                display: block;
                width: 100%;
            }
        }
    }

    .product-card-club-plus-product-price {
        margin-left: 10px;
    }

    .product-card-club-plus-pdp {
        display: flex;
        flex-direction: row;
        color: black;
        font-weight: bold;
        position: relative;
        // border-radius: 5px;
        // padding: 5px;
    }

    .product-card-club-plus-cross-pdp {
        cursor: pointer;
    }

    .rating-holder {
        display: flex;
        text-align: left;
        margin-bottom: 20px;
        align-items: center;
        @media (max-width: $dobbies-container-max-width-s) {
            margin-bottom: 10px;
        }

        .ms-buybox__reviews-count-section {
            .reviews-count {
                margin-right: 5px;
            }
        }
        .msc-rating__star,
        .msc-rating__empty-star,
        .msc-rating__half-star {
            font-size: var(--dobbies-body-font-size-m);
        }
    }

    .msc-rating {
        &__count,
        &__star {
            color: #facc00;
        }

        &__half-star {
            &:after {
                color: #facc00;
            }
        }
    }

    .msc-price__actual {
        font-size: 30px;
        line-height: normal;
        font-weight: var(--dobbies-font-weight-heavy);
        color: var(--msv-buybox-text-font-color);
    }
    .msc-price__strikethrough {
        font-size: 30px;
        line-height: 34px;
        text-transform: none;
    }

    .age-restricted-notification {
        padding: 8px;
        margin-bottom: 22px;
        min-height: 58px;
        display: flex;
        align-items: center;
        background: $dobbies-header-grey;
        span {
            display: inline-block;
            &:first-child {
                margin-right: 3px;
            }
        }
        &:before {
            background: var(--dobbies-bg-color-primary);
            width: 42px;
            height: 42px;
            min-width: 42px;
            display: inline-block;
            content: '18';
            border-radius: 50%;
            font-weight: var(--dobbies-font-weight-heavy);
            color: var(--dobbies-font-secondary-color);
            text-align: center;
            line-height: 42px;
            font-size: var(--dobbies-body-font-size-xl);
            margin-right: 8px;
        }
    }

    &__configure {
        @include font-content-l(var(--msv-font-weight-normal));
        display: none;
    }

    &__dropdown {
        margin-top: 20px;

        &-quantity-label {
            margin-bottom: 5px;
            display: inline-block;
            @include font-content-m(var(--msv-font-weight-light));
            color: var(--msv-buybox-title-font-color);
        }
    }

    &__media-gallery {
        display: block;
        flex-basis: $media-gallery-width;
        min-width: $media-gallery-width;
        flex-grow: 1;
        flex-wrap: nowrap;
        padding-right: 20px;
        padding-right: $gutter-width;
        @media (min-width: $msv-container-max-width-m) {
            flex-basis: $media-gallery-width-lg;
            min-width: $media-gallery-width-lg;
        }
    }

    .ms-media-gallery {
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        .msc-carousel__control__prev,
        .msc-carousel__control__next {
            display: none;
        }
        .msc-carousel__indicators {
            bottom: 10px;
            @media (max-width: $msv-breakpoint-l) {
                bottom: -30px;
                min-width: 350px;
            }
            @media (max-width: $msv-breakpoint-s) {
                min-width: 100%;
            }
            li {
                width: 14px;
                height: 14px;
                margin-right: 3px;
                margin-left: 3px;
                border-color: var(--dobbies-icon-color-header);
                &.active {
                    background-color: var(--dobbies-icon-color-header);
                }
            }
        }
        .msc-ss-carousel-slide {
            display: flex;
            flex-direction: row;
            white-space: nowrap;
            margin: 20px 10px 0 10px;
        }
        .ms-media-gallery__thumbnails-container {
            width: 100%;
        }
        .msc-ss-carousel {
            max-width: 100%;
            padding: 0;
        }
        .ms-media-gallery__thumbnail-item {
            height: auto;
            width: calc(33.33% - 10px);
            img {
                width: 100%;
            }
        }
        .msc-flipper {
            border: 0;
            width: 30px;
            height: 40px;
            flex: auto;
            top: 50%;
            left: 0;
            right: auto;
            padding: 0;
            background: rgba(255, 255, 255, 0.5);
            color: $dobbies-green;
            transform: translateY(-50%);
            &.msc-ss-carousel__flipper--next {
                left: auto;
                right: 0;
            }
            > i {
                color: $dobbies-green;
            }
        }
        .both {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
        }

        .badges {
            height: 100%;
            pointer-events: none;
            &.left-badge,
            &.both {
                .badges__top-style {
                    min-width: 240px;
                    top: 15px;
                    right: 0;
                    left: auto;
                    width: auto;
                    position: absolute;
                    z-index: 9;
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        min-width: 284px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-xl) {
                        min-width: 320px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        width: calc(100% + 20px);
                        height: 100%;
                        background-color: $dobbies-white;
                        -webkit-transform: skew(25deg);
                        box-shadow: -3px 3px 8px $dobbies-badge-shadow;
                        right: -20px;
                    }
                  

                    & + .promotionalBanner__top {
                        margin-top: 70px;
                    }

                    .badges__top,
                    .badges__oos {
                        background: $dobbies-white;
                        border: none;
                        right: 0;
                        font-size: $dobbies-font-size-s;
                        font-weight: bold !important;
                        color: $dobbies-black;
                        padding: 5.5px 10px;
                        background-color: transparent !important;
                        color: $dobbies-black !important;
                        border: none !important;
                        position: relative;
                        z-index: 9;
                        height: 40px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        @media screen and (min-width: $dobbies-container-max-width-xl) {
                            height: 45px;
                            font-size: $dobbies-font-size-m;
                        }
                    }
                }
            }

            &__icon {
                width: 100px;
                height: 100px;
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    width: 140px;
                    height: 140px;
                }
            }
            
            .promotionalBanner__top {
                position: absolute;
                margin: 0;
                bottom: 20px;
                width: calc(100% - 40px);
                left: 50%;
                transform: translateX(-50%);
                background: $dobbies-white;
                padding: 12px 16px;
                box-shadow:  0px 2px 8px 0px $dobbies-badge-shadow;
                z-index: 999;
                pointer-events: all;
                border: none;
                @media (min-width: $dobbies-container-max-width-s) {
                    padding: 14px 16px;
                }
                .exit-badge-style {
                    position: absolute;
                    right: 7.2px;
                    top: 9.2px;
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    margin: 0;
                    cursor: pointer;
                    &::before {
                        content: $dobbies-icon-close;
						@include dobbies-icon();
                        font-size: 9px;
                        font-weight: bold;
                    }
                }
                .badge-heading {
                    font-size: $msv-font-size-xs;
                    font-weight: bold;
                    margin: 0;
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        font-size: $msv-font-size-s;
                    }
                }
                .badge-description {
                    font-size: $msv-font-size-xs;
                    margin: 0;
                    padding: 0;
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        font-size: $msv-font-size-s;
                    }
                }
            }
        }
    }

    &__content {
        min-width: 48%;
        @media (min-width: $msv-container-max-width-m) {
            min-width: 58%;
        }
        &__description {
            //margin-bottom: 20px;
            > p {
                margin-bottom: 20px;
                margin-left: 0px;
            }
        }
    }

    .msc-dropdown__select {
        margin-bottom: 5px;
        max-width: 345px;
        @include form-control();
        border-color: $dobbies-grey;

        @media (max-width: $msv-breakpoint-xl) {
            max-width: 248px;
            height: 42px;
        }
    }

    .quantity {
        .quantity-input {
            @include form-control();
            width: 80px;
            display: inline-block;
            margin-left: 8px;
            margin-right: 8px;
            -moz-appearance: textfield;
            padding: $buybox-quantity-input;
            vertical-align: bottom;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }

        .quantity__controls {
            padding: $buybox-incr-decr;
            padding-left: 0px;
            padding-right: 0px;
            background: var(--msv-buybox-btn-bg);
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid var(--msv-buybox-btn-border-color);
            min-width: auto;
            width: 48px;
            height: 48px;
            cursor: pointer;

            &:disabled {
                color: $dobbies-gray-300;
            }
            &:focus {
                outline: none;
            }
        }

        .increment {
            color: var(--msv-buybox-btn-font-color);
            &:before {
                @include dobbies-icon();
                content: $dobbies-icon-plus;
                vertical-align: middle;
                font-weight: $dobbies-outline-icon-weight;
                font-size: var(--dobbies-body-font-size-l);
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-font-color);
            }
        }

        .decrement {
            color: var(--msv-buybox-btn-font-color);
            &:before {
                @include dobbies-icon();
                content: '';
                background: var(--dobbies-font-primary-color);
                width: 8px;
                height: 1px;
                vertical-align: middle;
                font-weight: $dobbies-outline-icon-weight;
                font-size: var(--dobbies-body-font-size-l);
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-font-color);
            }
        }
    }

    .add-to-cart-container {
        background-color: #f8f8f8;
        margin-top: 20px;
        padding: 20px;
        margin-bottom: 20px;
        // @media (max-width: $msv-breakpoint-l) {
        // 	margin-top: 30px;
        // }
    }
    .add-to-cart-heading {
        font-size: 16px;
        font-weight: 500;
    }

    .add-to-cart-divider {
        height: 1px;
        background-color: rgb(15, 15, 15);
        margin-top: 10px;
    }

    .modal-links {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: start;
        //padding-top: 10px;
    }

    .price-quantity {
        overflow: hidden;
        display: flex;
        margin: 20px 0;
        gap: 20px;
        @media (max-width: $dobbies-container-max-width-tablet) {
            padding-right: 40px;
        }

        @media (max-width: $msv-breakpoint-l) {
            padding-right: 0px;
        }
    }

    &__quantity {
        input[type='number'],
        input[type='text'] {
            height: 40px;
            width: 75px;
            border-radius: 0;
            border: 1px solid $dobbies-dark-grey;
            text-align: center;
            padding: 5px;
        }

        .quantity {
            // width: 105px;
            border: 1px solid $dobbies-grey;
            //margin: 9px;
            width: 110px;
            display: flex;
            .quantity__controls {
                border: 0;
                padding: 0;
                //width: 17px;
                height: 29px;
                @media (min-width: 1900px) {
                    width: 48px;
                }
            }
            .quantity-input {
                margin: 0;
                width: 39px;
                height: 29px;
            }

            @media (max-width: $msv-breakpoint-xl) {
                //width: 76px;
                .quantity__controls {
                    width: 34px;
                    height: 29px;
                }
                .quantity-input {
                    width: 39px;
                    height: 29px;
					color: $dobbies-grey;
					outline: none;
                }
            }
        }
        ~ div {
            &.__add-to-cart-error-alert {
                .msc-add-to-cart-notification {
                    margin-top: 80px;
                }
            }
        }
    }

    &__product-quantity-label {
        @include font-content-l(var(--msv-font-weight-light));
        color: var(--msv-buybox-title-font-color);
        display: block;
        margin-bottom: $buybox-label-margin-bottom;
    }

    &__add-to-cart-container {
        .msc-alert-danger {
            width: 100%;
            padding-left: 58px;
            margin-top: 55px;
            margin-bottom: 0;
            @media (max-width: $dobbies-container-max-width-xl) {
                margin-top: 40px;
            }
            > div {
                max-width: 365px;
            }
            .msi-exclamation-triangle {
                margin: 0;
                left: 8px;
                width: 42px;
                height: 42px;
                line-height: 42px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                color: $white;
                text-align: center;
                font-size: 24px;
                transform: translateY(-50%);
                background: var(--dobbies-msg-alert-color);
                &:before {
                    content: '!';
                }
            }
            .msc-alert__line {
                margin: 0;
            }
        }

        .msc-add-to-cart {
            display: flex;
            align-items: center;
            justify-content: center;
            //font-weight: var(--dobbies-font-weight-bold);
            font-size: 16px;
            border-radius: 6px;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            height: 30px;
            margin-top: 0px;
            padding: 5px 20px;
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-secondary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                background: var(--dobbies-bg-color-primary);
                &:after {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }

            @media (max-width: $msv-breakpoint-l) {
                //padding: 8px 25px 8px 25px;
                font-size: var(--dobbies-body-font-size-m);
                //min-width: 160px;
                margin-top: 0;
                &:before {
                    font-size: 22px;
                    margin-right: 7px;
                }
            }

            @media (max-width: $msv-breakpoint-sm) {
                padding: 8px 10px 8px 10px;
                min-width: 160px;
            }
            @media (min-width: 1900px) {
                height: 30px;
                font-size: 16px;
            }

            &:disabled,
            &[disabled] {
                cursor: default;
                border-color: $dobbies-gray-300;
                background-color: $dobbies-gray-300;
                color: var(--dobbies-font-secondary-color);
                &:hover {
                    border-color: $dobbies-gray-300;
                    background-color: $dobbies-gray-300;
                    color: var(--dobbies-font-secondary-color);
                    &:after {
                        background-color: $dobbies-gray-300;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                    }
                }
            }
            &.product-delivery-out-of-stock {
                font-size: var(--dobbies-body-font-size-m);
                @media (max-width: $msv-breakpoint-xl) {
                    font-size: var(--dobbies-body-font-size-s);
                }
            }
        }
    }
    .msc-alert-danger {
        width: 100%;
        padding-left: 55px;
        > div {
            max-width: 365px;
        }
        .msi-exclamation-triangle {
            margin: 0;
            left: 2px;
            width: 42px;
            height: 42px;
            line-height: 42px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            color: $white;
            text-align: center;
            font-size: 24px;
            transform: translateY(-50%);
            background: var(--dobbies-msg-alert-color);
            &:before {
                content: '!';
            }
        }
        .msc-alert__line {
            margin: 0;
        }
    }

    .msc-in-stock-red {
        width: 30px;
        height: 25px;
        margin-right: 10px;
        background-image: url('#{$dobbies-bg-path}svgs/email.svg');
        background-repeat: no-repeat;
    }

    .msc-in-stock {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background-image: url('#{$dobbies-bg-path}svgs/in-store.svg');
        background-repeat: no-repeat;
    }

    .notify-me-button {
        font-weight: bold;
        border: 0;
        padding: 0;
        background: none;
        color: $dobbies-grey;
        font-size: 16px;
        line-height: $dobbies-line-height-l;

        @media (max-width: 1066px) and (min-width: 978px) {
            top: -36px;
        }

        &:hover {
            cursor: pointer;
            color: $dobbies-green;
        }
        &:focus {
            outline: none;
        }
    }

    .msc-add-to-cart-notification,
    .msc-added-to-cart-notification {
        height: 80px;
        border: 1px solid $dobbies-grey;
        display: flex;
        padding: 20px 20px;
        margin: 20px 0;
        justify-content: space-between;
        align-items: center;
        @media (max-width: $msv-breakpoint-xl) {
            margin: 20px 0 20px;
        }
        @media (max-width: $msv-breakpoint-l) {
            margin: 20px 0;
        }
        &__title {
            font-size: var(--dobbies-body-font-size-m);
            line-height: 22px;
        }

        &__button a {
            padding: 5px 20px;
            min-width: 225px;
            font-weight: var(--dobbies-font-weight-normal);
            font-size: var(--dobbies-body-font-size-m);
            line-height: 26px;
            border-radius: 6px;
            position: relative;
            text-align: center;
            display: inline-block;
            text-decoration: none;
            background-color: var(--dobbies-accent-brand-color);
            border: 1px solid var(--dobbies-accent-brand-color);
            color: var(--dobbies-font-secondary-color);
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            height: 35px;
            border: 0;
            @media (max-width: $msv-breakpoint-m) {
                padding: 3px 20px;
            }
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-secondary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                background: var(--dobbies-bg-color-primary);
                &:after {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }

        @media (max-width: $msv-breakpoint-l) {
            height: auto;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
            &__button,
            &__title {
                flex-basis: 100%;
            }
            &__title {
                margin-bottom: 12px;
            }
        }
    }
    .instore-product {
        .msc-add-to-cart-notification {
            @media (max-width: $dobbies-container-max-width-l) {
                margin-top: 93px;
            }
        }
        .__add-to-cart-error-alert {
            .msc-alert.msc-alert-danger {
                @media (max-width: $dobbies-container-max-width-l) {
                    margin-top: 80px;
                }
            }
        }
    }

    .ms-text-block {
        margin-top: 0px;
        margin-bottom: 20px;
        @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $dobbies-line-height-m);

        &.ms-buybox__promo-text-block {
            padding-bottom: 15px;
            border-bottom: 0.5px solid $dobbies-grey;
            @media (max-width: $msv-breakpoint-l) {
                margin-bottom: 20px;
            }
        }

        .promo-container {
            display: flex;
            align-items: center;
            color: $white;
            padding: 12px 20px;
            text-align: center;
            background: $dobbies-grey;
            justify-content: space-around;
        }

        .credit-info {
            font-size: var(--dobbies-body-font-size-m);
            line-height: 20px;
            p {
                margin-bottom: 0;
            }
            h2 {
                font-size: 22px;
                line-height: 26px;
            }
        }

        .terms-conditions {
            a {
                color: $white;
                font-size: var(--dobbies-body-font-size-l);
                font-weight: var(--dobbies-font-weight-heavy);
                line-height: 22px;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }

            p {
                font-size: 10px;
                line-height: initial;
            }
        }

        @media (max-width: $msv-breakpoint-l) {
            .promo-container {
                height: auto;
                flex-wrap: wrap;
                justify-content: center;
            }
            .credit-info {
                padding-bottom: 10px;
            }
            .terms-conditions {
                flex-basis: 100%;
            }
        }
    }

    .product-add-to-cart {
        margin-top: 20px;

        .buttons {
            display: block;
            width: 100%;
        }

        .msc-add-to-cart {
            @include primary-button(
                var(--msv-buybox-primary-btn-bg),
                var(--msv-buybox-primary-btn-font-color),
                var(--msv-buybox-primary-btn-border)
            );
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            width: calc(100% - #{$buybox-wishlist-width-with-padding});
            font-size: var(--msv-buybox-btn-font-size);
            line-height: $dobbies-line-height-l;
        }

        .ms-wishlist-items__product-status {
            width: 100%;
            padding-top: $buybox-product-status-padding-top;
        }

        .msc-add-to-wishlist {
            @include font-glyph-standard();
            @include secondary-button(
                var(--msv-buybox-secondary-btn-bg),
                var(--msv-buybox-secondary-btn-font-color),
                var(--msv-buybox-secondary-btn-border)
            );
            color: var(--msv-buybox-btn-wishlist-color);
            min-width: auto;
            padding-left: 0px;
            padding-right: 0px;
            position: relative;
            top: $buybox-wishlist-top-postion;
            width: $buybox-wishlist-width;

            &:not(:disabled):hover,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-wishlist-color);
                border-color: transparent;
            }
        }
    }

    &__find-in-store {
        margin-top: 0;
        h4,
        p {
            display: none;
        }
    }

    &__find-in-store-heading {
        @include font-content-l(var(--msv-font-weight-light));
    }

    &__find-in-store-description {
        margin-top: 8px;
        margin-bottom: $buybox-store-description-margin-bottom;
        @include font-content-s();
    }

    &__find-in-store-button {
        display: flex;
        align-items: center;
        font-weight: var(--dobbies-font-weight-normal);
        border: 0;
        padding: 0;
        background: none;
        position: relative;
        font-size: var(--dobbies-body-font-size-l);
        // width: 154px;

        @media (max-width: $dobbies-container-max-width-m) {
            font-size: var(--dobbies-body-font-size-m);
            // width: 125px;
        }
    }

    &__find-in-store-local-container {
        justify-content: center;
        display: flex;
        align-items: center;
        font-weight: normal;
        line-height: 24px;
        text-decoration: underline;
    }

    &__not-HD-available {
        display: flex;
        align-items: center;
        font-weight: 500;
        line-height: 24px;
        font-size: var(--dobbies-body-font-size-m);
    }

    &__find-in-store-local-button {
        font-weight: var(--dobbies-font-weight-normal);
        border: 0;
        padding: 0;
        background: none;
        color: $dobbies-grey;
        line-height: 20px;
        text-decoration: none;
        &:hover {
            cursor: pointer;
            color: $dobbies-green;
        }
        &:disabled,
        &[disabled] {
            text-decoration: none;
            &:hover {
                cursor: default;
                color: $dobbies-dark-grey;
            }
        }
    }

    &__back-in-stock-button {
        font-weight: bold;
        border: 0;
        padding: 0;
        background: none;
        position: relative;
        color: $dobbies-grey;
        font-size: 16px;
        line-height: $dobbies-line-height-l;
        text-decoration: underline;
        padding-left: 35px;
        @include add-icon($dobbies-icon-cart, before);
        &:before {
            font-size: 30px;
            margin-right: 3px;
            @include dobbies-icon();
            vertical-align: middle;
            content: $dobbies-icon-cart;
            font-weight: var(--dobbies-font-weight-normal);
            color: var(--dobbies-icon-color);
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:hover {
            cursor: pointer;
            color: $dobbies-green;
            text-decoration: none;
        }
        &:focus {
            outline: none;
        }
    }

    &__shop-similar-looks-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border)
        );
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $dobbies-line-height-l;
    }

    &__dialog {
        max-width: $msv-dialog-width;

        .msc-modal__content {
            padding: $msv-dialog-modal-content-padding;
        }

        &__header {
            .msc-modal__title {
                @include font-content-l();

                span {
                    &:nth-child(1) {
                        font-weight: var(--msv-font-weight-bold);
                    }
                }
            }
        }

        &__body {
            @include font-content-l();
            text-align: center;

            .msc-empty_image {
                @include image-placeholder($msv-dialog-msc-empty_image-size);
                padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
            }
        }

        &__image {
            margin-right: auto;
            margin-left: auto;
        }

        &__product-title {
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-variants {
            @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $dobbies-line-height-m);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-price {
            font-weight: var(--msv-font-weight-bold);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__go-to-cart {
            @include primary-button(
                var(--msv-buybox-primary-btn-bg),
                var(--msv-buybox-primary-btn-font-color),
                var(--msv-buybox-primary-btn-border)
            );
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }

        &__back-to-shopping {
            @include secondary-button();
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        &__media-gallery {
            display: block;
            flex-basis: $media-gallery-width;
            min-width: $media-gallery-width;
            flex-grow: 1;
            flex-wrap: nowrap;
            padding-right: $gutter-width;
        }

        .ms-media-gallery {
            padding-bottom: 30px;
            padding-top: 0;

            .msc-carousel {
                &__control {
                    &__prev,
                    &__next {
                        bottom: -40px;
                        height: auto;
                        top: auto;
                        width: auto;
                    }

                    &__prev__icon {
                        @include add-icon($msv-ChevronLeft);
                        margin-left: 0;
                    }

                    &__next__icon {
                        @include add-icon($msv-ChevronRight);
                        margin-right: 0;
                    }
                }
            }
        }
    }

    //mobile vp
    @media (max-width: $msv-breakpoint-sm) {
        flex-flow: column;
        &__media-gallery {
            padding: 0;
        }
    }
}
.pdp-breadcrumb {
    margin-top: 20px;
    @media (min-width: 1900px) {
        margin-top: 15px;
    }

    .ms-breadcrumb {
        .ms-breadcrumb_list {
            margin-bottom: 15px;
        }
        .ms-breadcrumb_item {
            &.breadcrumb_item-current {
                pointer-events: none;
            }
        }
    }
}

.planta-container {
    display: flex;
    align-items: center;
    border: 1px solid #111;
    margin: 8px;
    padding: 12px;
}

.planta-app-btn {
    margin-right: 8px;
    width: 30%;

    @media (max-width: $msv-breakpoint-l) {
        width: 40%;
    }
}
.ms-buybox__content__description {
    padding-bottom: 20px;
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
// .product-item-id{
// 	margin-bottom: 20px;
// 	@media (max-width: $msv-breakpoint-m) {
// 		margin-bottom: 10px;
// 	}
// }
.buttons-containers {
    margin: 20px 0;
}

.msc-modal {
    .backinstock-modal.dobbies-modal {
        width: 100%;
        left: 50%;
        height: auto;
        max-width: 90%;
        margin: 0 auto;
        transform: translate(-50%, -50%);
        @media (min-width: $dobbies-container-max-width-tablet) {
            max-width: 740px;
        }
        @media (min-width: $dobbies-container-max-width-tablet) {
            max-width: 984px;
        }
    
        @media (min-width: $dobbies-container-max-width-xl) {
            max-width: 1145px;
        }
        
        .msc-modal {
    
            &__header {
                padding: 0;
                margin-bottom: 20px;
                border-color: $dobbies-gray-100;
                .popup__heading {
                    padding-bottom: 10px;
                    font-size: 20px;
                    font-weight: normal;
                    @media (min-width: $dobbies-container-max-width-tablet) {
                        font-size: 25px;
                        padding-bottom: 12px;
                    }
                }
    
                .newsletter-popup-cross {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    width: 15px;
                    height: 15px;
                    &::before {
                        font-size: 15px;
                        display: flex;
                    }
                }
            }
    
            &__content {
                padding: 20px;
                @media (min-width: $dobbies-container-max-width-s) {
                    padding: 40px;
                    padding-top: 27px;
                }
                @media (min-width: $dobbies-container-max-width-tablet) {
                    padding: 40px 36px;
                    padding-top: 32px;
                }
            
                @media (min-width: $dobbies-container-max-width-xl) {
                    padding: 40px 56px;
                }
                .newsletter-subscription-div {
                    padding: 0;
    
                    > div {
                       > p {
                            margin-bottom: 20px;
                            @media (min-width: $dobbies-container-max-width-tablet) {
                                margin-bottom: 22px;
                            }
                        }
                    }
    
                    p {
                        font-size: 14px;
                        @media (min-width: $dobbies-container-max-width-tablet) {
                            font-size: 16px;
                        }
                    }
    
                    .email-address-input,
                    .first-name-input {
                        input {
                            font-size: $dobbies-font-size-m;
                            height: 50px;
                            margin-bottom: 20px;
                            border-color: $dobbies-gray-100;
                            padding: 20px;
    
                            @media (min-width: $dobbies-container-max-width-tablet) {
                                height: 56px;
                            }
                        }
                    }
                }
    
    
                .msc-modal__body {
                    
                    .checkbox-privacy-policy {
                        &-content {
                            display: flex;
                        }
                        input {
                            width: 20px;
                            height: 20px;
                            margin-right: 15px;
                        }
    
                        &-text {
                            color: $dobbies-grey;
                            a {
                                color: $dobbies-grey;
                            }
                        }
                    }
                    .msc-notify-me {
                        height: 30px;
                        min-width: 227px;
                        border-radius: 5px;
                        &:disabled {
                            background: RGBA(112,112,112,0.6 );
                        }
                    }
                }
    
                .close-popup {
                    padding: 0;
                    margin-left: 40px;
                    height: auto;
                    text-decoration: underline;
                }
            }
    
            &__body {
                .email-address-input {
                    margin: 0;
                }
                .newsletter-subscription-div {
                    .checkbox-privacy-policy {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    &.show {
        .backinstock-modal.msc-modal__dialog {
            transform: translate(-50%, -50%);
        }
    }
}