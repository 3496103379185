// bootstrap color variables
$dobbies-black: #000;
$dobbies-blue:  #0062af;
$dobbies-pure-red: #FF0000;
$dobbies-red: #A80000;
$dobbies-pink: #FCDFDF;
$dobbies-light-red: #dd1b1b;
$dobbies-alert-red: #fa141a;

$dobbies-gray-100: #707070;
$dobbies-gray-200: #E8E8E8;
$dobbies-gray-300: #d4d4d4;
$dobbies-gray-500: #616365;
$dobbies-gray-800: #8F8F8F;
$dobbies-gray-900: #1D1D1D;
$dobbies-gray-1000: #2D2D2D;
$dobbies-gray-1200: #fafafa;

$dobbies-primary: #5a9e33;
$dobbies-primary-hover: #006937;
$dobbies-secondary: #facc00;
$dobbies-secondary-hover: #FFD047;

$dobbies-text-color: $dobbies-gray-900;
$dobbies-link-color: $dobbies-gray-900;
$dobbies-divider-color: $dobbies-gray-300;
$dobbies-error-color: $dobbies-red;
$modal-input-background: $dobbies-gray-100;
$dobbies-modal-overlay: rgba(0, 0, 0, 0.7);
$dobbies-box-shadow-color: rgba(0, 0, 0, 0.132);

$dobbies-flyout-empty-img-border: #F3F2F1;

// Confirmed colors
$msv-black: #000000;
$msv-blue: #2266E3;
$msv-white: #FFFFFF;
$msv-red-10: #9A212A;
$msv-yellow: #FFFF00;

$msv-gray-10: #100E11;
$msv-gray-20: #2C262D;
$msv-gray-30: #463D48;
$msv-gray-40: #595959;
$msv-gray-50: #C4C4C4;
$msv-gray-60: #161316;
$msv-gray-70: #201C21;
$msv-gray-75: #BFBFBF;
$msv-gray-80: #362F37;
$msv-gray-100: #F8F7F6;
$msv-gray-120: #6E726E;
$msv-gray-130: #817979;
$msv-gray-140: #949494;
$msv-gray-160: #323130;
$msv-gray-200: #868686;
$msv-gray-210: #E8E8E8;
$msv-gray-300: #D1D1D1;
$msv-gray-400: #6B727A;
$msv-gray-500: #616365;
$msv-gray-800: #8F8F8F;
$msv-gray-900: #323130;
$msv-gray-910: #2D2D2D;
$msv-gray-920: #1D1D1D;
$msv-gray-930: #EDEDED;
$msv-gray-940: #B6BCC1;
$msv-white-10: #EBEBEB;
$msv-white-20: #F5F5F5;

$msv-blue-10: #CCDEFF;
$msv-blue-20: #E7EFFF;
$msv-blue-30: #0742AB;
$msv-blue-40: #0B53CE;
$msv-blue-50: #1F6CF9;
$msv-blue-60: #9CBEFF;
$msv-blue-70: #1264A0;
$msv-blue-80: #4083FF;
$msv-blue-90: #538FFF;
$msv-blue-100: #6EA1FF;


$msv-red: #A80000;
$msv-pink: #FFE7E7;
$msv-green-10: #107C10;
$msv-green-20: #DFF6DD;
$msv-green-30: #C8F1D4;
$msv-green-40: #3FF23F;
$msv-black-10: #333333;
$msv-brown: #600000;

$msv-transparent: transparent;

// Semantic colors
$msv-primary: $msv-gray-20;
$msv-primary-hover: $msv-gray-70;
$msv-primary-pressed: $msv-gray-60;
$msv-secondary: $msv-gray-50;
$msv-secondary-hover: $msv-white-20;
$msv-text-color: $msv-gray-20;
$msv-error-color: $msv-red-10;

$msv-link-color: $msv-blue;
$msv-divider-color: $msv-gray-40;

// Text color
$msv-font-primary-color: $msv-gray-20;
$msv-font-secondary-color: $msv-white;

// Accent colors
$msv-accent-brand-color: $msv-gray-20;
$msv-accent-brand-alt-color: $msv-white;

// Stroke colors
$msv-stroke-line: $msv-gray-50;

// Events
$msv-button-primary-rest-color: $msv-primary;
$msv-button-primary-hover-color: $msv-primary-hover;
$msv-button-primary-pressed-color: $msv-gray-60;
$msv-button-primary-disabled-color: $msv-gray-50;

$msv-button-secondary-rest-color: $msv-white;
$msv-button-secondary-hover-color: $msv-white-20;
$msv-button-secondary-pressed-color: $msv-gray-10;
$msv-button-secondary-disabled-color: $msv-white;


// Social button color variables
$facebook-bg-color: #3C3C41;
$facebook-hover-color: #1D1D1D;
$microsoft-bg-color: #3C3C41;
$microsoft-hover-color: #1D1D1D;

$dobbies-unfilled-rating-color: $dobbies-gray-800;

// Dobbies Color
$dobbies-grey: #111111;
$dobbies-green: #5a9e33;
$dobbies-yellow: #facc00;
$dobbies-white: #ffffff;
$dobbies-dark-blue: #25303B;
$dobbies-club-yellow: #FFD047;
$dobbies-club-green: #006937;
$dobbies-club-dark-green: #146937;
$dobbies-club-grey: #76777B;
$dobbies-kids-blue: #33a4d9;
$dobbies-dark-grey: #515056;
$dobbies-light-grey: #D8D8D8;
$dobbies-header-grey: #f8f8f8;
$dobbies-vlight-grey: #eeeeee;
$dobbies-footer-grey: #F0F0F2;
$dobbies-plp-tile: #efeef3;
$dobbies-sale-price: #fa141a;
$dobbies-store-bg: #F0F3F5;
$dobbies-bombay-grey: #ababac;
$dobbies-breadcrumb-icon-color: #FAD400;
$dobbies-navigation-border-color: #dbdbdb;
$dobbies-gray-400: #efeff1;
$dobbies-club-benefit-green: #8FD6B4;
$dobbies-club-benefit-black: #2c2e33;
$dobbies-club-benefit-green-hover: #8FD6B4;
$dobbies-club-light-green:#8FD6B4;
$dobbies-club-loyalty-yellow:#FED047;
$dobbies-club-more-room: #a3a4a6;
$dobbies-footer-font-color: #111111;
$dobbies-store-find-color: #FAD303;
$dobbies-homepage-grey: #EFEDED;
$icon-carousel-color: #666666;
$dobbies-club-premium-background:rgb(214,214,215);
$dobbies-club-premium-background-gradient: linear-gradient(90deg, rgba(214,214,215,1) 0%, rgba(205,206,208,1) 15%, rgba(159,161,168,1) 35%, rgba(170,173,179,1) 45%, rgba(214,214,215,1) 52%, rgba(186,186,190,1) 61%, rgba(170,173,179,1) 100%);
$dobbies-refiner-blue: #0093FF;
$dobbies-refiner-yellow: #FFE200;
$dobbies-refiner-orange: #FF9300;
$dobbies-refiner-red: #FF0000;
$dobbies-refiner-green: #79D100;
$dobbies-refiner-purple: #AA3BD3;
$dobbies-refiner-pink: #FF7ECD;
$dobbies-refiner-cream: #FAD7A7;
$dobbies-refiner-black: #000000;
$dobbies-refiner-gold: #DAA520;
$dobbies-refiner-brown: #964B00;
$dobbies-refiner-silver: #C0C0C0;
$dobbies-refiner-grey: #808080;
$dobbies-refiner-beige: #e4d5b7;
$dobbies-refiner-bronze: #CD7F32;
$dobbies-refiner-copper: #b87333;
$dobbies-refiner-natural: #E5D3BF;
$dobbies-refiner-clear: #F4FAFC;
$dobbies-refiner-terracotta: #D6692F;
$dobbies-refiner-ivory: #FFFCEA;

$dobbies-badge-shadow: #00000029;

// Style presets
:root {
    // Background
    --dobbies-bg-color: #{$dobbies-white};
    --dobbies-bg-color-primary: #{$dobbies-green};
    --dobbies-bg-color-secondary: #{$dobbies-yellow};
    --dobbies-header-grey: #{$dobbies-header-grey};
    --dobbies-tile-bg: #{$dobbies-plp-tile};
    --dobbies-instore-bg: #{$dobbies-store-bg};
    --dobbies-white-bg: #{$dobbies-white};
    --dobbies-gray-cart-banner: #{$dobbies-gray-400};
    --dobbies-club-benefit-green: #{$dobbies-club-benefit-green};
    --dobbies-club-benefit-green-hover: #{$dobbies-club-benefit-green-hover};
    --dobbies-club-loyalty-yellow: #{$dobbies-club-loyalty-yellow};
    --dobbies-homepage-grey: #{$dobbies-homepage-grey};

    // Font
    --dobbies-font-primary-color: #{$dobbies-grey};
    --dobbies-font-secondary-color: #{$dobbies-white};
    --dobbies-font-header-color: #{$dobbies-club-grey};
    --dobbies-heading-primary-color: #{$dobbies-green};
    --dobbies-sale-price-color: #{$dobbies-sale-price};
    --dobbies-msg-alert-color: #{$dobbies-alert-red};
    --dobbies-club-primary-color: #{$dobbies-club-yellow};
    --dobbies-club-dark-green: #{$dobbies-club-dark-green};
    --dobbies-club-font-light-green: #{$dobbies-club-light-green};
    --dobbies-club-font-pure-red: #{$dobbies-pure-red};
    --dobbies-footer-font-color: #{$dobbies-footer-font-color};

    // Icons
    --dobbies-icon-color-header: #{$dobbies-grey};
    --dobbies-icon-color: #{$dobbies-green};
    --dobbies-breadcrumb-icon-color: #{$dobbies-breadcrumb-icon-color};
 
    // Borders
    --dobbies-border-color: #{$dobbies-gray-100};
    --dobbies-dark-border-color: #{$dobbies-club-grey};
    --dobbies-green-border-color: #{$dobbies-green};
    --dobbies-bombay-border-color: #{$dobbies-bombay-grey};
    --dobbies-gray-border-color: #{$dobbies-light-grey};
    --dobbies-nav-border-color: #{$dobbies-navigation-border-color};
    
   
    // Brand accents
    --dobbies-accent-brand-color: #{$dobbies-primary};
    --dobbies-accent-brand-alt-color: #{$dobbies-primary-hover};
    --dobbies-accent-secondary-btn:#{$dobbies-secondary-hover};

    // Status
    --dobbies-error-color: #{$dobbies-red};
    --dobbies-success-color: #{$dobbies-green};

    // Modal
    --dobbies-modal-bg-color: var(--dobbies-bg-color);
    --dobbies-modal-bg-color: #{$dobbies-header-grey};
    --dobbies-modal-font-color: var(--dobbies-font-primary-color);
    --dobbies-flyout-bg-color: #{$dobbies-white};
}