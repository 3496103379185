$msv-checkout-margin-bottom: 28px;
$msv-checkout-margin-top: 28px;
$msv-checkout-margin-left-right: auto;
$msv-checkout-plain-container-padding-left: 0px;
$msv-checkout-main-max-width: calc(100% - 463px);
$msv-checkout-main-max-width-m: calc(100% - 330px);
$msv-checkout-side-width: 463px;
$msv-checkout-side-width-m: 330px;
$msv-checkout-standard-margin-padding: 32px;
$msv-checkout-main-panel-padding-right: 150px;
$msv-checkout-main-panel-padding-right-m: 18px;
$msv-checkout-guided-form-margin-bottom: 20px;
$msv-checkout-guided-card-padding-top: 20px;
$msv-checkout-guided-card-padding-bottom: 10px;
$msv-checkout-guided-card-padding-left: 32px;
$msv-checkout-guided-card-padding-right: 32px;
$msv-checkout-guided-card-border-top-width: 1px;
$msv-checkout-guided-card-body-margin-top: 12px;
$dobbies-checkout-guided-card-body-margin-top: 20px;
$msv-checkout-guided-card-footer-margin-top: 20px;
$msv-checkout-place-order-button-margin: 0;
$msv-checkout-side-control-margin-top: 20px;
$msv-checkout-side-control-margin-bottom: 20px;
$msv-checkout-side-control-first-margin-top: 0px;
$msv-checkout-side-control-first-margin-auto: 15px auto;
$msv-checkout-side-control-place-order-button-width: 100%;
$msv-checkout-side-control-first-padding: 0 20px 20px 20px;
$msv-checkout-side-control-margin-side: 20px;
$msv-checkout-save-button-margin-left: 10px;
$msv-checkout-shipping-image-width: 80px;
$msv-checkout-shipping-margin-right: 12px;
$dobbies-checkout-margin-top: 20px;

// checkout__lines styling
$msv-checkout-line-items-padding: 0;
$msv-checkout-line-image-size: 93px;
$msv-checkout-empty-image-width: $msv-checkout-line-image-size;
$msv-checkout-line-margin-bottom: 10px;
$msv-checkout-line-padding-bottom: 10px;
$msv-checkout-line-margin-left: 20px;
$msv-checkout-line-content-width: calc(100% - 128px);
$msv-checkout-line-header-margin-bottom: 20px;
$msv-checkout-line-heading-font-weight: 700;
$msv-checkout-line-edit-cart-font-weight: 500;
$msv-checkout-payment-instrument-error-max-width: 512px;
$msv-checkout-payment-instrument-error-padding: 8px;
$msv-checkout-payment-instrument-error-title-margin-bottom: 10px;
$msv-checkout-payment-instrument-error-margin-top: 2px;
$msv-checkout-payment-instrument-message-margin-buttom: 0px;
$msv-checkout-guided-card-title-step-width: 20px;
$msv-checkout-guided-card-title-text-padding-left: 12px;
$msv-checkout-guided-card-title-font-weight: $dobbies-font-weight-bold;
$msv-checkout-edit-btn-padding: 0;
$msv-checkout-edit-btn-margin-left: auto;
$msv-checkout-cart-line-quantity-margin-left: 0;
$msv-checkout-cart-line-quantity-label-margin-right: 5px;
$msv-checkout-cart-line-price-strikethrough-margin-right: 10px;
$msv-checkout-pick-up-at-store-margin-left: 114px;
$msv-checkout-pick-up-at-store-margin-top: 10px;
$msv-checkout-pick-up-at-store-margin-bottom: 0;

// terms-and-conditions
$msv-checkout-terms-and-conditions-margin-bottom: 10px;
$msv-checkout-terms-and-conditions-padding-right: 32px;
$msv-checkout-terms-and-conditions-padding-left: 32px;

// Mobile styles
$msv-mobile-checkout-min-width: 320px;
$msv-mobile-checkout-max-width: 100%;
$msv-mobile-checkout-padding-zero: 0px;
$msv-mobile-checkout-margin: 0px;
$msv-mobile-checkout-max-button-width: 100%;
$msv-mobile-checkout-place-order-margin-left: 0px;
$msv-mobile-checkout-place-order-margin-top: 10px;
$msv-mobile-checkout-keep-shopping-margin-top: 20px;

// Alert
$msv-checkout-error-message-padding: 18px;
$msv-checkout-error-message-margin-bottom: 65px;
$msv-checkout-error-message-width: 100%;
$msv-checkout-icon-margin-right: 8px;

:root {
  --msv-checkout-border: #{$dobbies-black};
  --msv-checkout-font-size: var(--msv-body-font-size-l);

  // background
  --msv-checkout-side-bg: var(--dobbies-bg-color);

  //heading
  --msv-checkout-heading-font-color: var(--dobbies-font-primary-color);
  --msv-checkout-heading-font-size: var(--msv-body-font-size-xl);

  // link
  --msv-checkout-btn-link-color: var(--dobbies-font-primary-color);

  // error
  --msv-checkout-error-message-bg: var(--dobbies-error-color);
  --msv-checkout-error-message-border: var(--dobbies-error-color);
  --msv-checkout-error-message-color: var(--dobbies-font-secondary-color);

  // primary button
  --msv-checkout-primary-btn-bg: var(--dobbies-accent-brand-color);
  --msv-checkout-primary-btn-font-color: var(--dobbies-font-secondary-color);
  --msv-checkout-primary-btn-border: var(--dobbies-accent-brand-color);

  // secondary button
  --msv-checkout-secondary-btn-bg: var(--dobbies-bg-color);
  --msv-checkout-secondary-btn-font-color: var(--dobbies-font-primary-color);
  --msv-checkout-secondary-btn-border: var(--dobbies-accent-brand-color);

  // checkout line items
  --msv-checkout-line-heading-font-color: var(--dobbies-font-primary-color);
  --msv-checkout-line-heading-font-size: #{$dobbies-heading-text-size};

  --msv-checkout-line-title-font-color: var(--dobbies-font-primary-color);
  --msv-checkout-line-title-font-size: var(--msv-body-font-size-l);

  --msv-checkout-line-text-font-color: var(--dobbies-font-primary-color);
  --msv-checkout-line-text-font-size: var(--msv-body-font-size-m);
}

.ms-checkout {
  padding-bottom: $msv-checkout-margin-bottom;
  margin-left: $msv-checkout-margin-left-right;
  margin-right: $msv-checkout-margin-left-right;
  font-size: var(--msv-checkout-font-size);
  line-height: $dobbies-line-height-l;
  margin-top: $dobbies-checkout-margin-top;

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &__error-message {
    width: $msv-checkout-error-message-width;
    background-color: var(--msv-checkout-error-message-bg);
    border: 1px solid var(--msv-checkout-error-message-border);
    color: var(--msv-checkout-error-message-color);
    padding: $msv-checkout-error-message-padding;
    margin-bottom: $msv-checkout-error-message-margin-bottom;

    &:before {
      @include msv-icon();
      content: $msv-IncidentTriangle;
      margin-right: $msv-checkout-icon-margin-right;
    }
  }

  &-section-container {
    padding-left: $msv-checkout-plain-container-padding-left;
    display: flex;
    flex-direction: column;

    &__item.hidden {
      display: none;
    }
    [data-type-name="checkout-billing-address"] {
      order: 1;
    }
  }

  &__title {
    color: var(--msv-checkout-heading-font-color);
    font-style: normal;
    font-weight: var(--msv-font-weight-normal);
    font-size: var(--msv-checkout-heading-font-size);
    line-height: $dobbies-line-height-xl;
    align-items: center;
    padding-bottom: $msv-checkout-guided-card-padding-bottom;
  }

  &__main {
    flex: auto;
    max-width: $msv-checkout-main-max-width;

    @media (min-width: $dobbies-container-max-width-tablet) {
      padding-right: 16px;
    }
    @media (min-width: $dobbies-container-max-width-xl) {
      padding-right: 20px;
    }

    .ms-checkout__terms-and-conditions {
      padding-right: $msv-checkout-terms-and-conditions-padding-right;
      padding-left: $msv-checkout-terms-and-conditions-padding-left;
      margin-bottom: $msv-checkout-terms-and-conditions-margin-bottom;
    }

    @media (max-width:$msv-breakpoint-xl) {
      max-width: $msv-checkout-main-max-width-m;
      padding-right: $msv-checkout-main-panel-padding-right-m;
    }
  }

  &__main-control {
    .ms-checkout__btn-place-order {
      margin-right: 0px;
    }

    .msc-btn {
      width: 100%;
      height: auto;
      padding: 11px 20px;
      min-width: 180px;
      font-size: var(--dobbies-body-font-size-m);
      font-weight: var(--dobbies-font-weight-heavy);
    }
  }

  &__side-control-first {
    display: block;
    background-color: var(--msv-checkout-side-bg);
    justify-content: center;

    .ms-checkout__btn-place-order {
      height: auto;
      width: $msv-checkout-side-control-place-order-button-width;
      margin: $msv-checkout-side-control-first-margin-auto;
      padding: 15px 26px;
    }

    .ms-checkout__btn-keep-shopping {
      display: block;
      text-decoration: underline;
    }
  }

  &__side-control-second {
    display: none;
  }

  &__main-control {
    display: flex;
    flex-direction: row-reverse;
  }

  &__btn-place-order {
    &.is-busy {
      &:before {
        @include msv-icon();
        content: $msv-Spinner;
        margin-right: $msv-checkout-icon-margin-right;
        -webkit-animation: spin 1s steps(8) infinite;
        animation: spin 1s steps(8) infinite;
      }

      cursor: progress;
    }

    @include primary-button(
      var(--msv-checkout-primary-btn-bg),
      var(--msv-checkout-primary-btn-font-color),
      var(--msv-checkout-primary-btn-border)
    );
    @include button-verticle-padding-zero();
    margin-left: $msv-checkout-place-order-button-margin;
    font-weight: var(--dobbies-font-weight-bold);
    margin-bottom: 20px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    overflow: hidden;
    border: 0;
    @media screen and (max-width: $msv-breakpoint-m) {
      width: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--dobbies-bg-color-secondary);
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      transform-origin: 0 50%;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
      color: var(--dobbies-font-primary-color);
      background: var(--dobbies-bg-color-primary);
      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: $dobbies-gray-300;
      background-color: $dobbies-gray-300;
      color: var(--dobbies-font-secondary-color);
      &:hover {
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:after {
          background-color: $dobbies-gray-300;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
        }
      }
    }
  }

  &__btn-keep-shopping {
    @include button-link(var(--msv-checkout-btn-link-color));
  }

  &__side {
    width: $msv-checkout-side-width;
    @media (max-width: $msv-breakpoint-xl) {
      width: $msv-checkout-side-width-m;
    }
  }

  &__guided-form {
    margin-bottom: $msv-checkout-guided-form-margin-bottom;
  }

  &-payment-instrument__error {
    background-color: var(--msv-checkout-error-message-bg);
    border: 1px solid var(--msv-checkout-error-message-border);
    color: var(--msv-checkout-error-message-color);
    max-width: $msv-checkout-payment-instrument-error-max-width;
    padding: $msv-checkout-payment-instrument-error-padding;

    &-title {
      margin-bottom: $msv-checkout-payment-instrument-error-title-margin-bottom;
      margin-top: $msv-checkout-payment-instrument-error-margin-top;

      &:before {
        @include msv-icon();
        content: $msv-IncidentTriangle;
        margin-right: $msv-checkout-icon-margin-right;
      }

      display: block;
    }

    &-message {
      display: block;
      margin-bottom: $msv-checkout-payment-instrument-message-margin-buttom;
      color: var(--dobbies-font-secondary-color);
    }
  }

  &__guided-card {
    padding-bottom: $msv-checkout-guided-card-padding-bottom;
    color: $dobbies-gray-500;

    &-header {
      display: flex;
      align-items: center;
      padding: 18px 21px 17px;
      background-color: $dobbies-header-grey;
    }

    &.hidden {
      display: none;
    }

    &.visted,
    &.active {
      color: var(--msv-checkout-heading-font-color);
      text-decoration: none;
    }

    &-title {
      @include font-content-heading($msv-checkout-guided-card-title-font-weight);
      font-size: $dobbies-font-size-m;
      line-height: 22px;
      display: flex;
      color: var(--dobbies-bg-color-primary);
      @media (max-width: $dobbies-container-max-width-s) {
        font-size: $dobbies-font-size-s;
      }
    }

    &-title-step {
      width: $msv-checkout-guided-card-title-step-width;
    }

    &-body {
      margin-top: $dobbies-checkout-guided-card-body-margin-top;
      padding-left: 0;
      padding-right: 0;

      &.hidden {
        display: none;
      }
      .ms-checkout-delivery-options {
        &__error-message {
          color: var(--dobbies-sale-price-color);
          font-weight: var(--dobbies-font-weight-bold)
        }
      }
    }

    &-btn-cancel {
      @include secondary-button(
        var(--msv-checkout-secondary-btn-bg),
        var(--msv-checkout-secondary-btn-font-color),
        var(--msv-checkout-secondary-btn-border)
      );
      @include button-verticle-padding-zero();
      min-width: 180px;
      padding: 11px 20px;
      position: relative;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      position: relative;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      overflow: hidden;
      border-color: $dobbies-grey;
      margin-left: 18px;
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-primary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover,
      &:focus,
      &:active {
        color: var(--dobbies-font-secondary-color);
        background: var(--dobbies-bg-color);
        &:after {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
      &:disabled,
      &[disabled] {
        cursor: default;
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:hover {
          border-color: $dobbies-gray-300;
          background-color: $dobbies-gray-300;
          color: var(--dobbies-font-secondary-color);
          &:after {
            background-color: $dobbies-gray-300;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
          }
        }
      }
    }

    &-btn-save {
      height: auto;
      @include primary-button(
        var(--msv-checkout-primary-btn-bg),
        var(--msv-checkout-primary-btn-font-color),
        var(--msv-checkout-primary-btn-border)
      );
      min-width: 180px;
      padding: 11px 20px;
      margin-bottom: 40px;
      font-weight: var(--dobbies-font-weight-bold);
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      position: relative;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      overflow: hidden;
      border: 0;
      @media screen and (max-width: $dobbies-container-max-width-m) {
        margin-right: 0;
        margin-bottom: 0;
      }
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-secondary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover,
      &:focus,
      &:active {
        color: var(--dobbies-font-primary-color);
        background: var(--dobbies-bg-color-primary);
        &:after {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
      &:disabled,
      &[disabled] {
        cursor: default;
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:hover {
          border-color: $dobbies-gray-300;
          background-color: $dobbies-gray-300;
          color: var(--dobbies-font-secondary-color);
          &:after {
            background-color: $dobbies-gray-300;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
          }
        }
      }
    }

    &-btn-cancel,
    &-btn-save {
      height: 46px;
      @media (max-width: $dobbies-container-max-width-s) {
        height: 30px;
        font-size: $dobbies-font-size-s;
        padding: 0;
        margin: 0;
      }

      @media (max-width: 400px) {
        min-width: 160px;
      }
      &.is-submitting {
        &:before {
          @include msv-icon();
          content: $msv-Spinner;
          margin-right: $msv-checkout-icon-margin-right;
          -webkit-animation: spin 1s steps(8) infinite;
          animation: spin 1s steps(8) infinite;
        }

        cursor: progress;
      }
    }

    &-btn-edit {
      @include font-content-m-underline(var(--msv-font-weight-bold));
      @include button-link(var(--msv-checkout-btn-link-color));
      padding: $msv-checkout-edit-btn-padding;
      margin-left: $msv-checkout-edit-btn-margin-left;
      height: auto;
    }

    &-footer {
      margin-top: $msv-checkout-guided-card-footer-margin-top;
      display: flex;
      @media screen and (max-width: $dobbies-container-max-width-m) {
        margin-bottom: 25px;
      }
    }
  }

  &__side-control {
    &-first,
    &-second {
      margin-bottom: $msv-checkout-side-control-margin-bottom;
    }
  }

  &__line-items {
    background-color: var(--msv-checkout-side-bg);
    font-size: var(--msv-body-font-size-s);
    line-height: $dobbies-line-height-s;
    padding: $msv-checkout-line-items-padding;

    &-edit-cart-link {
      @include font-content-m-underline(var(--msv-font-weight-heavy));
      @include button-link(var(--dobbies-bg-color-primary));
      padding: $msv-checkout-edit-btn-padding;
      margin-left: auto;
    }

    &-header {
      margin-bottom: $msv-checkout-line-header-margin-bottom;

      .ms-checkout__line-items-heading {
        color: var(--msv-checkout-line-heading-font-color);
        display: inline;
        font-size: var(--msv-checkout-line-heading-font-size);
        font-weight: var(--msv-font-weight-normal);
        line-height: $dobbies-heading-line-height;
      }

      .ms-checkout__line-items-edit-cart-link {
        color: var(--dobbies-bg-color-primary);
        font-weight: $msv-checkout-line-edit-cart-font-weight;
        font-size: var(--dobbies-body-font-size-s);
        float: right;
        height: auto;
      }
    }

    &-delivery-group {
      .ms-checkout__line-items-group-title {
        font-weight: var(--dobbies-font-weight-bold);
        font-size: var(--dobbies-body-font-size-m);
        margin-bottom: 15px;
      }

      .ms-checkout__pick-up-at-store {
        margin-left: $msv-checkout-pick-up-at-store-margin-left;
        margin-top: $msv-checkout-pick-up-at-store-margin-top;
        margin-bottom: $msv-checkout-pick-up-at-store-margin-bottom;

        .ms-checkout__store-label {
          margin-right: 5px;
        }
        .ms-checkout__store-location {
          font-weight: var(--msv-font-weight-bold);
          display: inline-block;
        }
      }

      .msc-cart-line {
        width: 100%;
        
        &__product-image {
          position: relative;
          width: $msv-checkout-line-image-size;
          height: $msv-checkout-line-image-size;
          margin-right: unset;
        }

        &__quantity,
        &__product-variants {
          font-weight: var(--msv-font-weight-normal);
          color: var(--msv-checkout-line-text-font-color);
          font-size: var(--msv-checkout-line-text-font-size);
          line-height: $dobbies-line-height-m;
        }

        &__content {
          display: block;
          margin-left: $msv-checkout-line-margin-left;
          width: $msv-checkout-line-content-width;
          min-height: auto;

          .msc-cart-line__product {
            padding-right: 0;

            &-title {
              color: var(--msv-checkout-line-title-font-color);
              font-weight: var(--dobbies-font-weight-bold);
              font-size: var(--msv-checkout-line-title-font-size);
              line-height: $dobbies-line-height-l;
            }
          }

          .msc-cart-line-item-product-discount {
            color: var(--dobbies-success-color);
          }

          .msc-cart-line__quantity {
            margin-left: $msv-checkout-cart-line-quantity-margin-left;

            .quantity-label {
              display: inline-block;
              font-size: var(--msv-body-font-size-s);
              line-height: $dobbies-text-line-height;
              margin-right: $msv-checkout-cart-line-quantity-label-margin-right;
            }

            .quantity-value {
              display: inline;
              text-align: left;
            }
          }

          .msc-cart-line__product-price {
            margin-left: $msv-checkout-cart-line-quantity-margin-left;
            text-align: left;
            display: block;

            .msc-price__strikethrough {
              color: var(--msv-checkout-line-text-font-color);
              font-size: var(--msv-body-font-size-s);
              line-height: $dobbies-text-line-height;
              display: inline;
              margin-right: $msv-checkout-cart-line-price-strikethrough-margin-right;
            }

            .msc-price__actual {
            //  color: var(--msv-checkout-line-text-font-color);
              font-size: var(--msv-body-font-size-s);
              line-height: $dobbies-text-line-height;
              color: var(--dobbies-sale-price-color);
            }
          }

          .msc-cart-line__product-savings {
            margin-left: 0px;
            text-align: left;
            // hide extra price component
            display: none;
          }
        }
      }
      .ms-checkout__line-item {
        border-bottom: solid 1px var(--dobbies-gray-border-color);
        margin-bottom: $msv-checkout-line-margin-bottom;
        padding-bottom: $msv-checkout-line-padding-bottom;
        min-height: 140px;
        &:last-child {
            border-bottom: 0;
        }
        .msc-alert-danger {
          margin: 0;
          padding: 0;
          margin-left: 113px;
          .msc-alert-danger {
            margin: 0;
            padding: 10px 0 5px;
          }
        }
      }
    }
    .msc-cart-line {
      &__product {
        padding-right: 0;
      }
      &-item-product-discount {
        font-size: var(--dobbies-body-font-size-s);
      }
      &__promo-codes {
        .msc-price__actual {
          font-size: var(--dobbies-body-font-size-s) !important;
        }
      }
    }
  }

  .msc-empty_image {
    @include image-placeholder($msv-checkout-empty-image-width);
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    &__main {
      min-width: $msv-mobile-checkout-min-width;
      max-width: $msv-mobile-checkout-max-width;
      padding-right: $msv-mobile-checkout-padding-zero;

      .ms-checkout__terms-and-conditions {
        display: none;
      }
    }

    &__side {
      width: $msv-mobile-checkout-max-width;
    }

    &__guided-card {
      &-body {
        padding-left: $msv-mobile-checkout-padding-zero;
        padding-right: $msv-mobile-checkout-padding-zero;
      }
    }

    &__body {
      width: $msv-mobile-checkout-max-button-width;
    }

    margin-right: $msv-mobile-checkout-margin;
    margin-left: $msv-mobile-checkout-margin;
    position: relative;
    padding-bottom: 60px;

    &__main-control,
    &__side-control-first {
      position: absolute;
      width: 100%;
      bottom: 0;
      margin-bottom: 0;
    }

    &__side-control-second {
      background: var(--msv-checkout-side-bg);
      display: block;

      .ms-checkout__btn-keep-shopping,
      .ms-checkout__btn-place-order {
        width: $msv-mobile-checkout-max-button-width;
      }

      .ms-checkout__btn-place-order {
        margin-left: $msv-mobile-checkout-place-order-margin-left;
        margin-top: $msv-mobile-checkout-place-order-margin-top;
      }

      .ms-checkout__btn-keep-shopping {
        margin-top: $msv-mobile-checkout-keep-shopping-margin-top;
      }
    }
  }
  .ms-breadcrumb {
    margin-top: -30px;
    .ms-breadcrumb_text {
      top: 0;
      font-weight: bold;
    }
    &_list {
      > a:last-child {
        font-weight: var(--dobbies-font-weight-bold);
        &:after {
          display: none;
        }
      }
    }
  }

  .msc-address-form {
    &__input {
      font-size: $dobbies-font-size-s;
    }
    &__label {
      font-size: $dobbies-font-size-s;
      line-height: 18px;
      @media screen and (max-width: $dobbies-container-max-width-s) {
        margin: 0;
      }
    }
  }

  .checkout-need-help {
    margin-bottom: 10px;
    @media screen and (max-width: $dobbies-container-max-width-tablet) {
      text-align: center;
    }
    h3 {
      font-size: $dobbies-font-size-s;
    }
    p {
      a {
        text-decoration: underline;
      }
    }
  }
}

// Payment UI
.custom-checkout-section {
  display: -webkit flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  form.ms-checkout-gift-card__form {
    background: $dobbies-white;
  }
  .ms-checkout-gift-card.add {
    border-bottom: none;
  }
  .ms-checkout-section-container__item {
      width: 100%;
        &:nth-child(4),
        &:nth-child(5) {
          background-color: $dobbies-header-grey;
          text-align: left;
          @media screen and (max-width: $dobbies-container-max-width-tablet) {
            text-align: center;
          }
        }

        &:nth-child(5) {
          width: 70%;
          @media screen and (max-width: $dobbies-container-max-width-tablet) {
            width: 100%;
          } 
        }

        &:nth-child(4) {
          padding-left: 20px;
          width: 30%;
          @media screen and (max-width: $dobbies-container-max-width-tablet) {
            width: 100%;
            padding: 0;
          }
          .checkout-payment-instrument__iframe {
            max-width: 255px;
            border-radius: 5px;
          }

          .ms-checkout-payment-instrument__mutation-observer {
            display: inline-block;
            margin: 0;
          }
        }

        &:nth-child(5) {
          .apple-pay-button {
            width: 255px;
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
    }

    &__title {
      margin: 20px 0;
      .border-line {
        text-align: center;
        position: relative;
        p {
          margin: 0;
          font-size: $msv-font-size-s;
          font-weight: $dobbies-font-weight-bold;
          display: inline-block;
          padding: 0 15px;
          background-color: $dobbies-white;
          position: relative;
        }
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          background-color: #C0C0C1;
          height: 1px;
          left: 0;
          top: 10px;
        }
      }
    }

    .custom-google-pay {
      padding-top: 37px;
      @media screen and (min-width: $dobbies-container-max-width-tablet) {
        padding: 37px 0;
      }
    }
}
.ms-checkout__main {
  .ms-checkout-apple-pay {
    width: 255px;
    display: inline-block;
    max-width: 100%;

    @media screen and (min-width: $dobbies-container-max-width-tablet) {
      padding: 37px 13px;
      margin-top: 0;
    }
  }
}
.gpay-card-info-container-fill, 
.gpay-card-info-container-fill >
 .gpay-card-info-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

#apple-pay {
  // width: 260px !important;
  height: 46px !important;
  margin: 0;
  margin-bottom: 37px;
  margin-top: 20px;
  @media screen and (min-width: $dobbies-container-max-width-tablet) {
    margin: 0;
  }
}

 
iframe.checkout-express__iframe.ms-checkout-express__add {
  border: 0;
}
// .ms-checkout__main {
//     .apple-pay-button {
//         max-width: 100%;
//     }
// }
@media only screen and (max-width: 991px) {
  .ms-checkout-express.ms-checkout-express-googlepay iframe{
      width: 100%;
  }
}


.ms-checkout-payment-instrument__add
 .ms-checkout-payment-instrument__btn-save {
    height: auto;
    cursor: pointer;
    @include primary-button(
      var(--msv-checkout-primary-btn-bg),
      var(--msv-checkout-primary-btn-font-color),
      var(--msv-checkout-primary-btn-border)
    );
    min-width: 180px;
    padding: 11px 20px;
    margin-bottom: 40px;
    font-weight: var(--dobbies-font-weight-bold);
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    overflow: hidden;
    border: 0;
    border-radius: 4px;
    @media screen and (max-width: $dobbies-container-max-width-m) {
      margin-right: 0;
      margin-bottom: 0;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--dobbies-bg-color-secondary);
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      transform-origin: 0 50%;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
      color: var(--dobbies-font-primary-color);
      background: var(--dobbies-bg-color-primary);
      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: $dobbies-gray-300;
      background-color: $dobbies-gray-300;
      color: var(--dobbies-font-secondary-color);
      &:hover {
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:after {
          background-color: $dobbies-gray-300;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
        }
      }
    }
  }


