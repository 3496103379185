/*Homepage style 10-1-2023*/
.homepage-carousal {
  h2 {
    color: #fff !important;
    font-size: 65px !important;
    @media (max-width: $msv-breakpoint-l) {
      font-size: 30px !important;
   }
  }
  
  p {
    color: #fff;
    @media (max-width: $msv-breakpoint-l) {
      font-size: 16px !important;
  }
  }
  .ms-content-block {
    img {
      height: auto;
    }
      > .ms-content-block__link {
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 0;
        z-index: 9;
      }
  }

  a {
    @include primary-button( var(--msv-write-review-primary-btn-font-color), var(--msv-write-review-primary-btn-border));
        margin: $msv-write-review-msc-btn-margin;
        padding: 10px 25px !important;
        border-radius: 5px;
        height: auto !important;
        font-size: 20px !important;
        font-weight: 500 !important;
       // -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        transition: none !important;
        position: relative;
       // -webkit-transition-duration: 0.3s;
       // transition-duration: 0.3s;
        overflow: hidden;
        border: none !important;
        min-width: 95% !important;
        background-color: #f8f8f8 !important;
        border: 1px solid black;
        color: #111111 !important;
        line-height: 20px;
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
          &:hover,
          &:focus,
          &:active {
            color: #111;
           // background: var(--dobbies-bg-color-primary);
           background: transparent;
            &:after {
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
            }
        }
        @media (max-width: $msv-breakpoint-l) {
          font-size: 16px !important;
      }

      &.msc-cta__primary {
        transform: perspective(1px) translateZ(0);
        transition: none !important;
      }
  }
  .offer-4{
    .ms-content-block__details{
    height: max-content !important;
    top: 0px !important;
    // max-height: 432px;
    width: 1068px;
    @media screen and (min-width: 993px) and (max-width: 1550px){
      height: 360px !important;
    }
    @media screen and (min-width: 993px) and (max-width: 1476px){
      padding: 0 80px;
    }
    &::before{
      @media screen and (min-width: 1578px){
        width: 400px;
        height: 400px;
    }
      @media screen and (min-width: 993px) and (max-width: 1550px){
        width: 300px;
        height: 300px;
      }

      @media screen and (min-width: 769px) and (max-width: 992px){
        width: 245px;
        top: 0;
      }

      @media screen and (max-width: $msv-breakpoint-mbl){
        width: 245px;
      }
    }
    @media screen and (min-width: 1476px){
      max-height: initial;
  }
  //   @media screen and (max-width: 1599px){
  //     top: 0;

  // }
  @media screen and (max-width: 1380px){
      width: 100%;
      height: 445px !important;
  }
  // @media screen and (max-width: $dobbies-container-max-width-tablet){
  //     // width: 1080px;
  //     height: 445px;
  // }
  @media screen and (max-width: 1150px){
     // width: 1030px;
      height: 400px !important;
  }



  // @media screen and (max-width: $dobbies-container-max-width-l){
  //     //width: 900px;
  //     height: 430px !important;
  // }
  @media screen and (min-width: 769px) and (max-width: 992px){
    height: 300px !important;
  }
  @media screen and (max-width: $msv-container-max-width-s) {
      //width: 600px;
      height: 400px !important;
      align-items: center;
      // top: -6px !important;
  }
  @media screen and (max-width: $msv-breakpoint-mbl-sm) {
     // width: 450px;
      height: 372px !important;
  }
  @media screen and (max-width: $msv-breakpoint-mbl) {
      //width: 420px;
      height: 330px !important;
  }
  @media screen and (max-width: $dobbies-container-max-width-xs) {
     // width: 390px;
      height: 300px !important;
  }
  @media screen and (max-width: 320px) {
     // width: 100%;
      height: 275px !important;
  }
    }
    

  }
  .ms-content-block__details {
    justify-content: center !important;
    height: auto !important;
    bottom: 0;
  }
  .ms-content-block[data-m-layout="full-width"] {
    .ms-content-block__title {
      color: $dobbies-white;
    }
  }
}
  .homepage-carousal .ms-content-block__link {
    display: block !important;
  }
  .home-three-promo .ms-content-block, .homepage-carousal .ms-content-block, .out-door-banner .ms-content-block, .home-four-promo .ms-content-block, .sustain-three-promo .ms-content-block{
    margin: 0px 10px;
  }

    @media (max-width: $msv-breakpoint-sm) {
      .home-three-promo .ms-content-block, .sustain-three-promo .ms-content-block{
        margin: 0 10px;
      }
  }
.out-door-banner .ms-content-block{
  min-height: auto;
}
  .home-three-promo .col-md-4 .ms-content-block, .sustain-three-promo .col-md-4 .ms-content-block{
    @media (max-width: $msv-breakpoint-sm) {
      margin-bottom: 20px;
  }
  }
  .home-three-promo .col-md-4:nth-child(3) .ms-content-block, .sustain-three-promo .col-md-4:nth-child(3) .ms-content-block {
    @media (max-width: $msv-breakpoint-sm) {
      margin-bottom: 0;
  }
  }
  .home-three-promo, .sustain-three-promo{
    .row{
      margin: 40px 0;
      @media (max-width: $msv-breakpoint-l) {
        margin: 20px 0;
     }
    }
  }

  .home-three-promo img, .sustain-three-promo img,
  .home-four-promo img {
    height: auto;
  }
  .home-three-promo, .sustain-three-promo{
    margin: auto;
    @media (max-width: $msv-breakpoint-l) {
        margin-bottom: 20px;
    }
    @media (max-width: $msv-breakpoint-m) {
      margin-bottom: 0px;
  }
  }
  .home-four-promo {
   margin: auto;
  }
  .home-four-promo .ms-content-block__details{
    margin-top: 20px;
    @media (max-width: $msv-breakpoint-l) {
      margin-top: 10px;
  }
  }
  // .home-four-promo .col-md-3:nth-child(1) .ms-content-block,
  // .home-four-promo .col-md-3:nth-child(3) .ms-content-block {
  //   background-color: #f3cd52;
  // }
  // .home-four-promo .col-md-3:nth-child(2) .ms-content-block {
  //   background-color: #6D6D71;
  // }
  body {
    overflow-x: hidden;
  }
  
  .home-four-promo .ms-content-block {
    justify-content: center;
   // display: flex;
    align-items: center;
    height: 100% !important;
  }
  .home-three-promo .ms-content-block__cta, 
  .sustain-three-promo .ms-content-block__cta,
  .out-door-banner .ms-content-block__cta{
   display: block !important;
   width: 85%;
   text-align: center;
   @media screen and (max-width: $msv-breakpoint-sm) {
     width: 100%;
   }
  }
  .home-three-promo a {
    background-color: #fff !important;
    border-radius: 5px !important;
    display: block !important;
    text-transform: uppercase !important;
    color: #000 !important;
    //padding: 30px 30px 50px 30px !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    border: none !important;
    @media (max-width: $msv-breakpoint-l) {
      font-size: 16px !important;
  }

  }

  .home-three-promo,
  .sustain-three-promo {
    .ms-content-block {
      @media (max-width: $dobbies-container-max-width-s) {
        margin: 10px 10px;
      }
    }
  }
  
  .home-three-promo {
    &.sustain-three-promo {

    }
    a {
      @include primary-button( var(--msv-write-review-primary-btn-font-color), var(--msv-write-review-primary-btn-border));
          margin: $msv-write-review-msc-btn-margin;
          padding: 10px 15px !important;
          height: auto !important;
          font-size: 16px;
          font-weight: var(--dobbies-font-weight-bold);
          -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
          position: relative;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          overflow: hidden;
          border: none !important;
          min-width: 95% !important;
          background-color:  #f8f8f8 !important;
          border: 1px solid black;
          color: #111111 !important;
          line-height: 20px !important;
          &:after {
              content: "";
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: var(--dobbies-bg-color-secondary);
              -webkit-transform: scaleX(0);
              transform: scaleX(0);
              -webkit-transform-origin: 0 50%;
              transform-origin: 0 50%;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-timing-function: ease-out;
              transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
              color: #111;
             // background: var(--dobbies-bg-color-primary);
             background: transparent;
              &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }
          }
          @media screen and (min-width: 768px) and (max-width: 992px){
            display: flex !important;
            justify-content: center;
            height: 55px !important;
            padding: 10px 10px !important;
            align-items: center;
          
        }
        @media screen and (min-width: 0px) and (max-width: 767px){
          //width: 70% !important;
          margin: auto !important;
          min-width: auto !important;
          font-size: 16px !important;
          line-height: 0.7;

        }
  
    }

    .ms-video-player {
      margin: 0 10px;
    }
  }

  .sustain-three-promo a {
    background-color: #fff !important;
    border-radius: 5px !important;
    display: block !important;
    text-transform: uppercase !important;
    color: #000 !important;
    //padding: 30px 30px 50px 30px !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    border: none !important;
    @media (max-width: $msv-breakpoint-l) {
      font-size: 16px !important;
  }

  }
  
  .sustain-three-promo {
    a {
      @include primary-button( var(--msv-write-review-primary-btn-font-color), var(--msv-write-review-primary-btn-border));
          margin: $msv-write-review-msc-btn-margin;
          padding: 10px 15px !important;
          height: auto !important;
          font-size: 16px;
          font-weight: var(--dobbies-font-weight-bold);
          -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
          position: relative;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          overflow: hidden;
          border: none !important;
          min-width: 95% !important;
          background-color:  #f8f8f8 !important;
          border: 1px solid black;
          color: #111111 !important;
          line-height: 20px !important;
          &:after {
              content: "";
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: var(--dobbies-bg-color-secondary);
              -webkit-transform: scaleX(0);
              transform: scaleX(0);
              -webkit-transform-origin: 0 50%;
              transform-origin: 0 50%;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-timing-function: ease-out;
              transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
              color: #111;
             // background: var(--dobbies-bg-color-primary);
             background: transparent;
              &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }
          }
          @media screen and (min-width: 0px) and (max-width: 767px){
            width: 70% !important;
            margin: auto !important;
            min-width: auto !important;
          }
  
    }
  }
  .home-three-promo .ms-content-block__details, .sustain-three-promo .ms-content-block__details,
  .out-door-banner .ms-content-block__details {
    top: 0;
    bottom: 0 !important;
    padding: 0 !important;
    margin: 0;
    left: 0;
    width: 100% !important;
    right: 0;
    height: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .home-three-promo .ms-content-block__details, .sustain-three-promo .ms-content-block__details{
    padding: 0px;
    margin: auto;
    justify-content: center;
    display: flex;
  }
  .out-door-banner img {
    height: auto !important;
  }
  .out-door-banner {
    margin-top: 40px;
    @media (max-width: $msv-breakpoint-l) {
        margin-top: 20px;
    }
    @media (max-width: $msv-breakpoint-m) {
      margin-top: 0px;
  }
  }
  .out-door-banner a {
  //   background-color: #fff !important;
  //   border-radius: 5px !important;
  //   display: block !important;
  //   text-transform: uppercase !important;
  //   color: #000 !important;
  //   //padding: 30px 80px 50px 80px !important;
  //   font-weight: 500 !important;
  //   font-size: 20px !important;
  //   border: none !important;
  //   @media (max-width: $msv-breakpoint-l) {
  //     font-size: 16px !important;
  // }
  }
  
  .out-door-banner {
    a {
      @include primary-button( var(--msv-write-review-primary-btn-font-color), var(--msv-write-review-primary-btn-border));
          margin: $msv-write-review-msc-btn-margin;
          padding: 10px 15px !important;
          border-radius: 5px;
          height: auto !important;
          font-size: 16px;
          font-weight: var(--dobbies-font-weight-bold) !important;
          -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
          position: relative;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          overflow: hidden;
          border: none !important;
          background-color:  #f8f8f8 !important;
          border: 1px solid black;
          color: #111111 !important;
          line-height: 20px !important;
          text-transform: uppercase !important;

          @media screen and (min-width: $msv-container-max-width-m){
            font-size: 20px !important
          }

          @media screen and (min-width: $dobbies-container-max-width-m){
            font-size: 16px !important
          }

          &:after {
              content: "";
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: var(--dobbies-bg-color-secondary);
              -webkit-transform: scaleX(0);
              transform: scaleX(0);
              -webkit-transform-origin: 0 50%;
              transform-origin: 0 50%;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-timing-function: ease-out;
              transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
              color: #111;
             // background: var(--dobbies-bg-color-primary);
             background: transparent;
              &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }
          }
  
    }
  }
 
  
  .home-four-promo {
    margin-top: 40px;
    margin-bottom: 40px;
    @media (max-width: $dobbies-container-max-width-tablet) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    @media (max-width: $msv-breakpoint-sm) {
      margin-top: 10px;
      margin-bottom: 10px;
    } 

    @media (max-width: $msv-breakpoint-sm) {
     .col-md-3 {
       width: 50%;
       margin-bottom: 10px;
     }
  }
  }
  
  .home-product-carousel {
    margin-top: 40px;
    margin-bottom: 40px;
    @media (max-width: $msv-breakpoint-l) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
  }
  .brands-home-new{
    //padding: 0 20px 20px 20px;
    margin-bottom: 40px;
    .col{
      margin-bottom: 0;
    }
    .col-12{
      &:first-child, &:nth-child(2){
        .ms-content-block{
          @media (max-width: $msv-breakpoint-l) {
            margin-top: 0;
        }
        }

      }
      &:nth-child(3){
        .ms-content-block{
          @media (max-width: $msv-breakpoint-l) {
            margin-top: 0;
        }
        @media (max-width: $msv-breakpoint-sm) {
          margin-top: -35px;
      }
        }
      }

    }
    .ms-content-block{
      min-height: auto;
      margin-right: 0;
      @media (max-width: $msv-breakpoint-l) {
        margin-top: -35px;
    }
    }
    @media (max-width: $msv-breakpoint-l) {
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0;
  }
}
  .shop-by-brand {
    margin-top: 40px;
    margin-bottom: 40px;
    @media (max-width: $msv-breakpoint-l) {
        margin-top: 20px;
        margin-bottom: 20px;
        //padding: 0 !important;
    }
  }
  .new-product-carousel{
    overflow: hidden;
    .msc-product__details{
        padding-left: 0;
        padding-right: 0;
    }
    .msc-ss-carousel-slide{
      ul{
        li{
          &:hover{
            a {
              color: green;
            }
          }
        }
      }
    }
      a.msc-add-to-carts {
      width: 100%;
      height: auto;
      min-height: 25px;
      min-width: 0;
      display: flex;
      justify-content: right;
      align-items: center;
      text-decoration: underline;
      margin-top: -25px;
    }

  }
  
  .shop-by-brand .ms-product-collection .msc-ss-carousel-item {
    border: none !important;
  }
  
  .shop-by-brand .ms-product-collection .msc-product {
    border: none !important;
  }
  
  .home-product-carousel .ms-product-collection .msc-product {
    border: none !important;
  }
  
  .home-product-carousel .ms-product-collection__item .msc-empty_image {
    height: 300px !important;
  }
  
  @media screen and (max-width: 768px) {
    .homepage-carousal .ms-content-block__text {
      display: block !important;
    }
  }
  .custom-product-carousel{
    overflow: hidden;
    .ms-product-collection{
      padding-top: 20px;
      @media screen and (max-width:  $msv-breakpoint-l) {
        padding-top: 0;
      }
    }
    .msc-active-image__details {
        align-items: center;
        position: relative;
        @media (max-width: $dobbies-container-max-width-tablet) {
          margin: 20px 0;
          padding: 0;
          line-height: normal;
          min-height: auto;
        }

        @media (max-width: $msv-breakpoint-sm) {
          margin: 20px 0 10px;
        }

        &__title {
          line-height: normal;
        }

        &.vertical-align {
          .msc-active-image__details__title {
            margin: 0;
          }
        }
    }
    .ms-product-collection .msc-ss-carousel .msc-ss-carousel__flipper{
      left: -20px !important;
      @media screen and (max-width:  $msv-breakpoint-sm) {
        left: 0 !important;
      }
      @media screen and (max-width:  $msv-breakpoint-l) {
        left: 10px !important;
      }
    }
    .msi-chevron-right{
      right: -20px;
      position: relative;
      @media screen and (max-width:  $msv-breakpoint-sm) {
        right: 0;
        position: relative;
      }
      @media screen and (max-width:  $msv-breakpoint-l) {
        right: 10px;
      }
    }
    background-color: #f8f8f8;

    .msc-active-image-wrapper {
      margin: 0;
    }
  }
  .brand-container{
    background-color: #f8f8f8;
    margin-bottom: 40px;
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  .new-product-carousel {
    .ms-product-collection {
      padding-top: 0;
      padding-bottom: 20px;

      .msc-product {
        &__image {
          img {
            margin-bottom: 10px;
          }
        }
        br {
          display: none;
        }
      }

      &__item {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
.brands-home-new {
  .row {
    .col-12 {
      margin-bottom: 0;
    }
  }
}

.home-three-promo,
.out-door-banner {
  .ms-content-block[data-m-layout="full-width"] {
    .ms-content-block__details {
      .msc-cta__primary,
      .msc-cta__secondary {
        @media screen and (max-width:  $msv-breakpoint-sm) {
          width: 70%;
          font-size: 16px;
        }
      }
    }
  }
}