$dobbies-club-bg-path: '../../../';
.dobbies-club-comparison {
    background-color: var(--dobbies-club-background-color);
    background-image: url('#{$dobbies-club-bg-path}dobbies-club-plus-bg.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    @media screen and (max-width: $dobbies-container-max-width-s) {
        background-size: 1000px auto;
    }

    h1{
        @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-xxl),$dobbies-line-height-55);
        color: var(--dobbies-club-primary-color);
        text-align: center;
        padding: 115px 0 65px;
        width: 100%;
        @media screen and (min-width: $dobbies-container-max-width-l) and (max-width: $dobbies-container-max-width-xl) {
            padding: 85px 0;
        }
        
        @media screen and (min-width: $msv-container-max-width-s) and (max-width: $dobbies-container-max-width-l) {
          @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-title-font-size-40),$dobbies-line-height-55);
          padding: 75px 0 22px;
        }
        @media screen and (max-width: $dobbies-container-max-width-s) {
          @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-tile-heading),$dobbies-line-height-45);
          padding: 50px 0;
        }
    }


    .dobbies-club-container-m {
        margin: 0 auto;
        background: var(--dobbies-bg-color);
        padding: 30px 12px;
        box-sizing: border-box;

        @media screen and (max-width: $dobbies-container-max-width-s) {
            width: auto;
            margin: 0 10px;
        }
        @media screen and (min-width: $msv-container-max-width-s) {
            width: 614px;
        }
        @media screen and (min-width: $dobbies-container-max-width-l) {
            width: 680px;
        }
        @media screen and (min-width: $dobbies-container-max-width-xl) {
            width: 710px;
        }

        .benefits-container {
            width: 467px;
            margin: 0 auto;

            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 100%;
                box-sizing: border-box;
            }
            @media screen and (min-width: $msv-container-max-width-s) {
                width: 488px;
            }
            @media screen and (min-width: $dobbies-container-max-width-l) {
                width: 506px;
            }
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                width: 467px;
            }

            h2 {
                @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-m),$dobbies-line-height-m);
                color: var(--dobbies-font-primary-color);
                text-align: center;
                text-transform: uppercase;
                margin: 0 0 13px;
            }

            &__header {
                h3 {
                    @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-m),$dobbies-line-height-l);
                    color: var(--dobbies-font-primary-color);
                    text-align: center;
                    text-transform: uppercase;
                    padding: 25px 0;

                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        padding: 16px 0;
                    }
                }

                &-plus {
                    background: var(--dobbies-club-primary-color);
                }
                &-normal {
                    background: var(--dobbies-club-benefit-green);
                }
            }

            &__per-benefit {
                border-bottom: 1px solid #707070;  
                padding-top: 14px;  
            }

            p {
                @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-s),$dobbies-line-height-s);
                color: var(--dobbies-font-primary-color);
                text-align: center;
                padding: 0 20px 14px;
            }

            .icon-holder {
                padding-bottom: 14px;
                text-align: center;
                font-size: var(--dobbies-title-font-size-40);
                &-benefit {
                    span {
                        &.icon-cross-plus {
                            &:after {
                                content: $dobbies-icon-cross;
                                color: var(--dobbies-club-benefit-green);
                            }
                        }
                        &.icon-check-plus {
                            &:after {
                                content: $dobbies-icon-check;
                                color: var(--dobbies-club-benefit-green);
                            }
                        }
                    }
                    &-plus {
                        span {
                            &.icon-check-plus {
                                &:after {
                                    content: $dobbies-icon-check;
                                    color: var(--dobbies-club-primary-color);
                                }
                            }
                        }
                    }
                }
            }

            .button-holder {
                margin: 0 -8px;
                padding-top: 14px;

                .button-benefit{
                    @include club-benefit-green-button();
                    @include button-verticle-padding-zero();
                    border-radius: $dobbies-btn-border-radius;
                    min-width: 0;
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    border: 0;
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: #7ebd9f;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                        -webkit-transform-origin: 0 50%;
                        transform-origin: 0 50%;
                        -webkit-transition-property: transform;
                        transition-property: transform;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        -webkit-transition-timing-function: ease-out;
                        transition-timing-function: ease-out;
                      }
                      &:hover,
                      &:focus,
                      &:active {
                        color: var(--dobbies-font-primary-color);
                        background: var(--dobbies-club-benefit-green);
                        &:after {
                          -webkit-transform: scaleX(1);
                          transform: scaleX(1);
                        }
                    }
                    &-plus {
                        @include club-plus-primary-button();
                        @include button-verticle-padding-zero();
                        border-radius: $dobbies-btn-border-radius;
                        min-width: 0;
                        -webkit-transform: perspective(1px) translateZ(0);
                        transform: perspective(1px) translateZ(0);
                        position: relative;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        overflow: hidden;
                        border: 0;
                        &:after {
                            content: "";
                            position: absolute;
                            z-index: -1;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: #E6BC40;
                            -webkit-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transform-origin: 0 50%;
                            transform-origin: 0 50%;
                            -webkit-transition-property: transform;
                            transition-property: transform;
                            -webkit-transition-duration: 0.3s;
                            transition-duration: 0.3s;
                            -webkit-transition-timing-function: ease-out;
                            transition-timing-function: ease-out;
                          }
                          &:hover,
                          &:focus,
                          &:active {
                            color: var(--dobbies-font-primary-color);
                            background: var(--dobbies-club-primary-color);
                            &:after {
                              -webkit-transform: scaleX(1);
                              transform: scaleX(1);
                            }
                        }
                    }
                }
                a {
                    text-align: center;
                    color: var(--dobbies-font-primary-color);
                    width: auto;
                    height: 62px;
                    font-size: var(--dobbies-body-font-size-m);
                    line-height: 62px;
                    display: block;
                    margin: 0 8px;

                    @media (max-width: $dobbies-container-max-width-s) {
                        height: 42px;
                        line-height: 42px;
                        padding: 0 !important;
                    }
                }
                
            }
        }

        &.redirection-link {
            background: none;
            padding: 0;

            a {
                padding: 35px 10px;
                text-align: center;
                @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-s),$dobbies-line-height-s);
                color: var(--dobbies-font-secondary-color);
                text-align: center;
                display: block;

                @media screen and (max-width: $dobbies-container-max-width-l) {
                    padding: 25px 0;
                }

                @media screen and (max-width: $dobbies-container-max-width-s) {
                    padding: 18px 0;

                    &.no-padding-bottom-mobile {
                        padding-bottom: 0;
                    }
                }

                span {
                    position: relative;
                    &:after{
                        top: 4px;
                        right: -20px;
                        position: absolute;
                        @include dobbies-icon();
                        color: var(--dobbies-club-primary-color);
                        font-size: var(--dobbies-club-loyalty-link-icon-font-size-s);
                        font-weight: var(--dobbies-font-weight-heavy);
                        content: $dobbies-icon-arrow;
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }

}