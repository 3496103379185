@import '../00-settings/colors';
@import '../00-settings/font';
@import '../00-settings/responsive-breakpoints';

$msv-btn-min-width: 160px;
$msv-btn-side-padding: 20px;
$msv-btn-vertical-padding: 12px;
$dobbies-static-page-button-bordered-width: 174px;
$dobbies-static-page-button-bordered-height: 30px;
$dobbies-static-page-button-bordered-line-height: 30px;
$dobbies-static-page-button-bordered-border-radius: 5px;


:root {
    // Background
    --msv-secondary-button-background-color: #{$msv-white};

    // Primary button --LIGHT THEME
    --msv-primary-btn-light-font-color: #{$msv-white};
    --msv-primary-btn-light-bg-color: #{$msv-gray-20};
    --msv-primary-btn-light-border-color: #{$msv-white};
    --msv-primary-btn-light-hover-bg-color: #{$msv-gray-70};
    --msv-primary-btn-light-focus-bg-color: #{$msv-gray-70};
    --msv-primary-btn-light-focus-border-color: #{$msv-blue-80};
    --msv-primary-btn-light-pressed-bg-color: #{$msv-gray-60};
    --msv-primary-btn-light-disabled-bg-color: #{$msv-gray-50};

    // Secondary button --LIGHT THEME
    --msv-secondary-btn-light-font-color: #{$msv-gray-20};
    --msv-secondary-btn-light-bg-color: #{$msv-white};
    --msv-secondary-btn-light-border-color: #{$msv-gray-20};
    --msv-secondary-btn-light-hover-bg-color: #{$msv-white-20};
    --msv-secondary-btn-light-focus-bg-color: #{$msv-white-20};
    --msv-secondary-btn-light-focus-border-color: #{$msv-blue};
    --msv-secondary-btn-light-pressed-bg-color: #{$msv-white-10};
    --msv-secondary-btn-light-disabled-bg-color: #{$msv-gray-50};
    --msv-secondary-btn-light-disabled-font-color: #{$msv-white};

    // Primary button --DARK THEME
    --msv-primary-btn-dark-font-color: #{$msv-gray-20};
    --msv-primary-btn-dark-bg-color: #{$msv-white};
    --msv-primary-btn-dark-border-color: #{$msv-gray-20};
    --msv-primary-btn-dark-hover-bg-color: #{$msv-white-20};
    --msv-primary-btn-dark-focus-bg-color: #{$msv-white-20};
    --msv-primary-btn-dark-focus-border-color: #{$msv-blue-80};
    --msv-primary-btn-dark-pressed-bg-color: #{$msv-white-10};
    --msv-primary-btn-dark-disabled-bg-color: #{$msv-gray-50};
    --msv-primary-btn-dark-disabled-font-color: #{$msv-gray-40};

    // Secondary button --DARK THEME
    --msv-secondary-btn-dark-font-color: #{$msv-white};
    --msv-secondary-btn-dark-bg-color: #{$msv-gray-20};
    --msv-secondary-btn-dark-border-color: #{$msv-white};
    --msv-secondary-btn-dark-hover-bg-color: #{$msv-gray-70};
    --msv-secondary-btn-dark-focus-bg-color: #{$msv-gray-70};
    --msv-secondary-btn-dark-focus-border-color: #{$msv-blue-80};
    --msv-secondary-btn-dark-pressed-bg-color: #{$msv-gray-60};
    --msv-secondary-btn-dark-disabled-bg-color: #{$msv-gray-50};
    --msv-secondary-btn-dark-disabled-font-color: #{$msv-gray-40};

    // Feature Link light
    --msv-feature-link-light-font-color: #{$msv-gray-20};
    --msv-feature-link-light-disabled-font-color: #{$msv-gray-50};
    --msv-feature-link-light-underline-bg-color: #{$msv-blue};
    --msv-feature-link-light-border-color: #{$msv-blue};
    --msv-feature-link-light-focus-underline-bg-color: #{$msv-blue-40};
    --msv-feature-link-light-hover-underline-bg-color: #{$msv-blue-40};
    --msv-feature-link-light-active-underline-bg-color: #{$msv-blue-30};
    --msv-feature-link-light-disabled-underline-bg-color: #{$msv-gray-50};

    // Feature Link dark
    --msv-feature-link-dark-font-color: #{$msv-white};
    --msv-feature-link-dark-disabled-font-color: #{$msv-gray-40};
    --msv-feature-link-dark-underline-bg-color: #{$msv-blue-80};
    --msv-feature-link-dark-border-color: #{$msv-blue};
    --msv-feature-link-dark-focus-underline-bg-color: #{$msv-blue-90};
    --msv-feature-link-dark-hover-underline-bg-color: #{$msv-blue-90};
    --msv-feature-link-dark-active-underline-bg-color: #{$msv-blue-100};
    --msv-feature-link-dark-disabled-underline-bg-color: #{$msv-gray-40};

    // Links
    --msv-link-font-color: #{$msv-gray-20};
    --msv-link-font-size-m: #{$msv-font-size-m};
    --msv-link-line-height-m: #{$msv-line-height-m};
    --msv-link-font-size-s: #{$msv-font-size-s};
    --msv-link-line-height-s: #{$msv-line-height-s};
    --msv-link-font-size-xs: #{$msv-font-size-xs};
    --msv-link-line-height-xs: #{$msv-line-height-xs};

    // Card buttons
    --msv-card-button-bg-color: #{$msv-black};
    --msv-card-button-font-color: #{$msv-white};
    --msv-card-button-disabled-font-color: #{$msv-gray-40};
    --msv-card-button-focus-border-color: #{$msv-blue-80};

    // Icon buttons
    --msv-icon-button-light-font-color: #{$msv-gray-20};
    --msv-icon-button-light-bg-color: #{$msv-transparent};
    --msv-icon-button-dark-font-color: #{$msv-white};
    --msv-icon-button-dark-bg-color: #{$msv-transparent};
    --msv-icon-button-light-focus-border-color: #{$msv-blue};
    --msv-icon-button-dark-focus-border-color: #{$msv-blue-80};

    // Toggle buttons
    --msv-toggle-button-color: #{$msv-blue};
    --msv-toggle-button-disabled-color: #{$msv-gray-50};
    --msv-toggle-button-focus-border-color: #{$msv-blue};
}


@mixin button-link($color: $dobbies-link-color) {
  border-color: transparent;
  background-color: transparent;
  color: $color;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@mixin primary-button($bg-color: var(--dobbies-accent-brand-color), $color: var(--dobbies-font-secondary-color), $border-color: var(--dobbies-accent-brand-color)) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;

  &:hover{
    background-color: var(--dobbies-accent-brand-alt-color);
    border-color: var(--dobbies-accent-brand-alt-color);
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $dobbies-gray-300;
    background-color: $dobbies-gray-300;
    color: var(--dobbies-font-secondary-color);
    &:hover {
      border-color: $dobbies-gray-300;
      background-color: $dobbies-gray-300;
      color: var(--dobbies-font-secondary-color);
      &:after {
        background-color: $dobbies-gray-300;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }
  }
}


// Buttons used on product cards with icons only.

@mixin card-button-with-icon-only() {
    background-color: var(--msv-card-button-bg-color);
    border: none;
    border-radius: 4px;
    color: var(--msv-card-button-font-color);
    height: 48 ;
    font-size: 0;
    opacity: 0.41;
    outline: none;
    width: 48;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.41;
    }

    &:focus {
        border: 1px solid var(--msv-card-button-focus-border-color);
        box-sizing: border-box;
        opacity: 0.8;
    }

    &:disabled,
    &[disabled] {
        opacity: 0.1;
    }

    &::after,
    &::before {
        font-size: $msv-icon-size-m;
        margin: auto;
        vertical-align: middle;
    }
}


// Primary button for light theme

@mixin primary-button-light() {
    @include msv-rubik-regular(
        $font-weight:$msv-font-weight-400,
        $font-style: $msv-font-style-normal,
        $font-size: $msv-font-size-s,
        $line-height: $msv-line-height-s);
    @include button-common-decoration();

    position: relative;
    background: var(--msv-primary-btn-light-bg-color);
    border: 1px solid var(--msv-primary-btn-light-border-color);
    color: var(--msv-primary-btn-light-font-color);

    &:hover {
        background-color: var(--msv-primary-btn-light-hover-bg-color);
        box-shadow: $msv-depth8;
    }

    &:focus {
        outline: none;
        background: var(--msv-primary-btn-light-focus-bg-color);

        &::before {
            @include button-focus-decoration();
            border: 1px dashed var(--msv-primary-btn-light-border-color);
        }
    }

    &:active {
        background-color: var(--msv-primary-btn-light-pressed-bg-color);
    }

    &:disabled,
    &[disabled] {
        background-color: var(--msv-primary-btn-light-disabled-bg-color);
        color: var(--msv-primary-btn-light-font-color);
    }
}

@mixin secondary-button($bg-color: var(--dobbies-bg-color), $color: var(--dobbies-font-primary-color), $border-color: var(--dobbies-accent-brand-color), $disableBtn: true) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;
  
  &:hover{
    background-color: var(--dobbies-accent-secondary-btn);
  }
  
  @if $disableBtn {
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: $dobbies-gray-300;
      background-color: $dobbies-gray-300;
      color: var(--dobbies-font-secondary-color);
      &:hover {
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:after {
          background-color: $dobbies-gray-300;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
        }
      }
    }
  }
}

@mixin club-plus-primary-button($bg-color: var(--dobbies-club-loyalty-yellow), $color: var(--dobbies-font-header-color), $border-color: var(--dobbies-club-loyalty-yellow)) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $dobbies-btn-vertical-padding $dobbies-btn-side-padding;
  min-width: $dobbies-btn-min-width;
  border-radius: 0;
  text-decoration: none;
  transition: all 0.3s;
  font-size: var(--dobbies-body-font-size-m);
  font-weight: var(--dobbies-font-weight-heavy);

  &:hover{
    background-color: var(--dobbies-breadcrumb-icon-color);
    color: var(--dobbies-font-header-color);
    $border-color: var(--dobbies-breadcrumb-icon-color);
    text-decoration: none;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $dobbies-gray-300;
    background-color: $dobbies-gray-300;
    color: var(--dobbies-font-secondary-color);
    &:hover {
      border-color: $dobbies-gray-300;
      background-color: $dobbies-gray-300;
      color: var(--dobbies-font-secondary-color);
      &:after {
        background-color: $dobbies-gray-300;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }
  }
}

@mixin club-green-button($bg-color: var(--dobbies-accent-brand-alt-color), $color: var(--dobbies-font-secondary-color), $border-color: var(--dobbies-accent-brand-alt-color)) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;

  &:hover{
    background-color: var(--dobbies-accent-brand-color);
    border-color: var(--dobbies-accent-brand-color);
    text-decoration: none;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $dobbies-gray-300;
    background-color: $dobbies-gray-300;
    color: var(--dobbies-font-secondary-color);
    &:hover {
      border-color: $dobbies-gray-300;
      background-color: $dobbies-gray-300;
      color: var(--dobbies-font-secondary-color);
      &:after {
        background-color: $dobbies-gray-300;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }
  }
}


@mixin secondary-button-bordered($bg-color: transparent , $color: var(--dobbies-font-primary-color), $border-color: /* var(--dobbies-accent-secondary-btn)*/ $dobbies-grey, $disableBtn: true) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  width: $dobbies-static-page-button-bordered-width;
  height: $dobbies-static-page-button-bordered-height;
  line-height: $dobbies-static-page-button-bordered-line-height;
  border-radius: $dobbies-static-page-button-bordered-border-radius;
  cursor: pointer;
  
  &:hover{
    background-color: var(--dobbies-accent-secondary-btn);
    text-decoration: none;
  }
  
  @if $disableBtn {
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: $dobbies-gray-300;
      background-color: $dobbies-gray-300;
      color: var(--dobbies-font-secondary-color);
      &:hover {
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:after {
          background-color: $dobbies-gray-300;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
        }
      }
    }
  }
}

@mixin button-default() {
  @include vfi();
  @include font-content-l();
  cursor: pointer;
  padding: 12px 20px;
  height: 48px;
  min-width: 160px;
  text-align: center;
}
@mixin button-transparent() {
  background-color: transparent;
  border: 1px solid var(--dobbies-breadcrumb-icon-color);
  color: var(--dobbies-font-primary-color);
  min-height: 38px;
  min-width: 215px;
  line-height: initial;
  border-radius: 4px;
  text-decoration: none;
  line-height: initial;
  font-size: var(--dobbies-body-font-size-l);
  font-weight: var(--dobbies-font-weight-normal);
  cursor: pointer;
  margin-top: 15px;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  overflow: hidden;
  div {
      margin: auto;
  }
  &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--dobbies-bg-color-secondary);
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      transform-origin: 0 50%;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
      color: var(--dobbies-font-primary-color);
      background: var(--dobbies-bg-color);
      text-decoration: none;
      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
  }
}


@mixin button-icon {
  width: 48px;
  height: 48px;
}
