$msv-btn-icon-to-text-gap: 4px;
$msv-btn-border-radius: 2px;
$dobbies-btn-border-radius: 4px;

$dobbies-btn-min-width: 223px;
$dobbies-btn-side-padding: 26px;
$dobbies-btn-vertical-padding: 15px;

.msc-btn {
  @include button-default();
  display: inline-block;
  border: 1px solid transparent;
  border-radius: $msv-btn-border-radius;
  font-family: $dobbies-primary-font-family;
  text-decoration: none;
  text-align: center;
  min-width: auto;

  
  &:not(:disabled) {
    cursor: pointer;
  }
}

@mixin primary-button($bg-color: var(--dobbies-accent-brand-color), $color: var(--dobbies-font-secondary-color), $border-color: var(--dobbies-accent-brand-color)) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $dobbies-btn-vertical-padding $dobbies-btn-side-padding;
  min-width: $dobbies-btn-min-width;
  border-radius: $dobbies-btn-border-radius;
  text-decoration: none;
  transition: all 0.3s;

  &:hover{
    background-color: var(--dobbies-accent-brand-alt-color);
    border-color: var(--dobbies-accent-brand-alt-color);
    text-decoration: none;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $dobbies-gray-300;
    background-color: $dobbies-gray-300;
    color: var(--dobbies-font-secondary-color);
  }
}

@mixin button-verticle-padding-zero {
  padding-top: 0;
  padding-bottom: 0;
}

@mixin secondary-button($bg-color: var(--dobbies-bg-color), $color: var(--dobbies-font-primary-color), $border-color: var(--dobbies-bg-color), $disableBtn: true) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $dobbies-btn-vertical-padding $dobbies-btn-side-padding;
  min-width: $dobbies-btn-min-width;
  border-radius: $dobbies-btn-border-radius;
  text-decoration: none;
  transition: all 0.3s;
  
  &:hover{
    background-color: var(--dobbies-bg-color-primary);
    color: var(--dobbies-font-secondary-color);
    $border-color: var(--dobbies-bg-color-primary)
  }
  
  @if $disableBtn {
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: $dobbies-gray-300;
      background-color: $dobbies-gray-300;
      color: var(--dobbies-font-secondary-color);
    }
  }
}

@mixin club-plus-primary-button($bg-color: var(--dobbies-club-primary-color), $color: var(--dobbies-font-header-color), $border-color: var(--dobbies-club-primary-color)) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $dobbies-btn-vertical-padding $dobbies-btn-side-padding;
  min-width: $dobbies-btn-min-width;
  border-radius: 0;
  text-decoration: none;
  transition: all 0.3s;
  font-size: var(--dobbies-body-font-size-m);
  font-weight: var(--dobbies-font-weight-heavy);

  &:hover{
    background-color: var(--dobbies-breadcrumb-icon-color);
    color: var($color);
    $border-color: var(--dobbies-breadcrumb-icon-color);
    text-decoration: none;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $dobbies-gray-300;
    background-color: $dobbies-gray-300;
    color: var(--dobbies-font-secondary-color);
  }
}

@mixin club-benefit-green-button($bg-color: var(--dobbies-club-benefit-green), $color: var(--dobbies-font-header-color), $border-color: var(--dobbies-club-benefit-green)) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $dobbies-btn-vertical-padding $dobbies-btn-side-padding;
  min-width: $dobbies-btn-min-width;
  border-radius: $dobbies-btn-border-radius;
  text-decoration: none;
  transition: all 0.3s;
  font-size: var(--dobbies-body-font-size-m);
  font-weight: var(--dobbies-font-weight-heavy);

  &:hover{
    background-color: var(--dobbies-club-benefit-green-hover);
    color: var($color);
    $border-color: var(--dobbies-breadcrumb-icon-color);
    text-decoration: none;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $dobbies-gray-300;
    background-color: $dobbies-gray-300;
    color: var(--dobbies-font-secondary-color);
  }
}

@mixin button-default() {
  @include vfi();
  @include font-content-l();
  cursor: pointer;
  padding: 10px 20px;
  height: 52px;
  min-width: 160px;
  text-align: center;
}