$active-image-full-width: 100%;
$active-image-details-heading-font-size-desktop: 100px;
$active-image-details-heading-font-size-mobile: 40px;
$active-image-details-heading-font-size-tablet: 60px;
$active-image-details-heading-line-height-desktop: 120px;
$active-image-details-heading-line-height-mobile: 50px;
$active-image-details-heading-line-height-tablet: 72px;
$active-image-details-text-font-size-desktop: 200px;
$active-image-details-text-font-size-mobile: 56px;
$active-image-details-text-font-size-tablet: 88px;
$active-image-details-text-line-height-desktop: 200px;
$active-image-details-text-line-height-mobile: 60px;
$active-image-details-text-line-height-tablet: 100px;
$active-image-details-additional-content-text-line-height-tablet-mobile: 40px;
$active-image-additional-content-font-weight-400: 400;
$active-image-additional-content-text-font-size: 36px;
$active-image-additional-content-text-line-height: 80px;
$active-image-product-card-image-height: 275px;
$active-image-product-card-image-width: 275px;

:root {
    //Background
    --msv-active-image-wrapper-background-color: var(--dobbies-bg-color);
    --msv-active-image-overlay-backgroud-color: #{$dobbies-black};

    //Color
    --msv-active-image-border-color: #{$dobbies-black};
    --msv-active-image-button-border-color: #{$dobbies-gray-1000};
    --msv-active-image-textheme-dark: #{$dobbies-black};
    --msv-active-image-textheme-light: #{$dobbies-white};

    //Body
    --msv-active-image-text-font-size: #{$dobbies-heading-text-size};
    --msv-active-image-button-text-font-size: #{$dobbies-font-size-s};
    --msv-active-image-button-color: #{$dobbies-gray-1000};
    --msv-active-image-details-color: #{$dobbies-gray-200};
    --msv-active-image-text-color: #{$dobbies-blue};
}

.msc-active-image {
    position: relative;
    *,
    *:hover {
        text-decoration: none;
    }

    &-wrapper {
        position: relative;
        margin-top: 40px;
    }

    &-container {
        position: relative;

        @include image($active-image-full-width);
    }

    &-canvas {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 2;
    }

    &-map {
        cursor: pointer;
    }

    &-details-container {
        display: flex;
        flex-direction: column;
    }

    .msc-active-image-product {
        margin: auto;
        max-width: 400px;
        border: solid 2px $dobbies-gray-200;
        border-radius: 10px;
    }

    .carousel {
        max-width: 500px;
        margin: 20px auto;
        .msc-flipper.msc-ss-carousel__flipper {
            top: 50%;
            color: var(--dobbies-icon-color);
            &.right {
                right: 0;
            }
            &.left {
                left: 0;
            }
        }
    }

    .msc-active-shadow .msc-product {
        border-bottom: none;
        .msc-product__delivery {
            display: none;
        }
    }

    .msc-add-to-cart {
        width: 100%;
        height: auto;
        min-height: 40px;
        min-width: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            color: $dobbies-white;
            &:hover {
                color: $dobbies-black;
            }
        }
    }
}

.msc-active-image {
    .additional-content-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
    }
    .ms-product-collection__heading {
        text-transform: uppercase;
    }
    .msc-active-image-product-card-container {
        position: absolute;
        z-index: 10;
    }

    .msc-active-image-product {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.9);

        .msc-active-image-product__image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            img {
                display: inline-block;
            }
        }

        .msc-active-image-product__details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 20px;
            width: 50%;
            .msc-active-image-product__title {
                font-family: $dobbies-primary-font-family;
                font-style: normal;
                font-weight: 500;
                font-size: var(--msv-active-image-text-font-size);
                line-height: $dobbies-line-height-l;
            }

            .msc-price {
                line-height: $dobbies-line-height-l;
                display: block;
                margin-top: 12px;
            }

            .msc-price__strikethrough {
                text-decoration: line-through;
                padding-right: 5px;
            }

            .msc-active-image-product__text {
                display: none;
            }
        }
    }

    &__details {
        &.vertical-align {
            position: relative;
            align-items: center;
            padding: 10px;
            * {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            > * {
                width: 100%;
            }
            .msc-active-image__details__title {
                @include font-content-xl($dobbies-font-weight-bold);
                text-transform: uppercase;
                font-size: 25px;
                width: auto;
                margin: 5px 0;
                @media (max-width: $msv-breakpoint-l) {
                    font-size: 20px;
                }
            }
            .msc-active-image__details__additional-content__paragraph {
                width: auto;
                margin: 5px 0;
            }
            .msc-active-image__details__cta__link {
                border: solid 2px $dobbies-gray-200;
                margin: 5px 0;
            }
        }
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        color: white;
        pointer-events: none;
        padding-left: 8%;
        &__title {
            @include font-content-xxl($dobbies-font-weight-bold);
            margin: 20px 0;
            text-transform: uppercase;
            width: 40%;
            color: #111;
            font-size: 25px;
        }
        .msc-active-image__details__additional-content__paragraph {
            @include font-content-s();
            margin: 10px 0;
            width: 45%;
            opacity: 0.8;
        }
        .msc-active-image__details__cta__link {
            display: flex;
            justify-content: center;
            pointer-events: all;
            max-width: 250px;
            margin: 10px 0;
            @include secondary-button();
            @include font-content-m($dobbies-font-weight-bold);
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            margin-left: 0;
            padding: 12px 30px;
            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-secondary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                background: var(--dobbies-bg-color);
                &:before {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }
    }
}
