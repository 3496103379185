$msv-business-organization-list-width: 100%;
$msv-business-organization-list-button-margin-top: 20px;
$msv-business-organization-list-primary-btn-font-color: white;
$msv-business-organization-list-button-group-spacing: 32px;
$msv-business-organization-list-button-margin-top: 20px;
$msv-business-organization-list-button-margin-spacing: 20px;
$msv-business-organization-list-margin-right: 20px;
$msv-business-organization-list-border-radius: 2px;
$msv-business-organization-list-form-item-margin: 20px 0 0;
$msv-business-organization-list-form-item-margin-right: 10px; 
$msv-business-organization-list-input-border: 1px solid transparent;
$msv-business-organization-list-input-padding: 6px 8px;
$msv-business-organization-list-input-size: 48px;
$msv-business-organization-list-input-spacing: 20px;
$msv-business-organization-list-label-margin: 0 0 5px 0;
$msv-business-organization-list-error-border: 1px solid $dobbies-white;
$msv-business-organization-list-error-bg-color: $dobbies-error-color;
$msv-business-organization-list-message-margin-top: 20px;
$msv-business-organization-list-message-padding: 20px;
$msv-business-organization-list-list-item-spacing: 8px;
$msv-business-organization-list-table-margin-top: 20px;
$msv-business-organization-list-table-data-row-height: 82px;
$msv-business-organization-list-modal-padding: 2 0px;
$msv-business-organization-list-pagination-padding-left: 0px;
$msv-business-organization-list-page-item-page-link-padding: 8px 4px;
$msv-business-organization-list-page-item-page-link-line-height: 1.25;
$msv-business-organization-list-page-item-padding-right: 10px;
$msv-business-organization-list-page-prev-next-padding: 0 4px;
//TODO: add to style presets
$msv-business-organization-list-table-border: 1px solid #D1D1D1;


:root {
    //title
    --msv-business-organization-list-title-font-size: var(--msv-body-font-size-xl);
    --msv-business-organization-list-title-font-color: var(--dobbies-font-primary-color);
   
    //text
    --msv-business-organization-list-text-font-size: var(--msv-body-font-size-m);
    --msv-business-organization-list-text-font-color: var(--dobbies-font-primary-color);
  
    // primary button
    --msv-business-organization-list-primary-btn-bg: var(--dobbies-accent-brand-color);
    --msv-business-organization-list-primary-btn-font-color: var(--dobbies-font-secondary-color);
    --msv-business-organization-list-primary-btn-border: var(--dobbies-accent-brand-color);
  
     // secondary button
     --msv-business-organization-list-secondary-btn-bg: var(--dobbies-bg-color);
     --msv-business-organization-list-secondary-btn-font-color: var(--dobbies-font-primary-color);
     --msv-business-organization-list-secondary-btn-border: var(--dobbies-accent-brand-color);

    //text
    --msv-business-organization-list-pagination-text-font-size: var(--msv-body-font-size-m);
    --msv-business-organization-list-text-font-color: var(--dobbies-font-primary-color);
    --msv-business-organization-list-pagination-color: var(--dobbies-font-primary-color);
    --msv-business-organization-list-pagination-size: var(--msv-body-font-size-m);
  }

.ms-business-organization-list {
    padding: 40px; 

    &__container {
        width: $msv-business-organization-list-width;
    }
    
    &__heading {
        @include font-content-xl();
    }

    &__add-user-button{
        @include primary-button(var(--msv-business-organization-list-primary-btn-bg), var(--msv-business-organization-list-primary-btn-font-color), var(--msv-business-organization-list-primary-btn-border));
        margin: 20px 0;
    }

    &__table {
        border-collapse: collapse;
        margin-top: $msv-business-organization-list-table-margin-top;
        table-layout: fixed;
        width: 100%;

        .ms-table {
            &__heading-row,
            &__heading-row-data,
            &__row,
            &__row-data,
            &__row-links {
                @include font-content-l();
                border-bottom: $msv-business-organization-list-table-border;
                height: $msv-business-organization-list-table-data-row-height;
                text-align: left;
    
                &.num-type {
                    text-align: right;
                }

                &.action-links {
                    padding-left: 20px;
                }
            }

            &__heading-row,
            &__heading-row-data {
                height: $msv-business-organization-list-table-data-row-height;
            }

            &__heading-sorting {
                cursor: pointer;
            }

            &__row-links {
                align-items: center;
                border-bottom: none;
                display: flex;
                padding-left: 20px;

                &-edit {
                    @include add-icon($msv-Edit);
                    background: none;
                    padding: 0px 10px;
                }

                &-delete {
                    @include add-icon($msv-Cancel);
                    background: none;
                    padding: 0px 10px;
                }
            }

        }
    }  
    
    &__modal {
        &-heading {
            margin-bottom: 40px;
        }

        &-body {
            @include font-content-l();
            display: flex;
            flex-wrap: wrap;
            padding-left: $msv-business-organization-list-modal-padding;
        }

        &-description {
            @include font-content-l();
            margin-bottom: 14px;
        }

        &-list {
            list-style: none;
        }

        &-list-item {
            @include font-content-l();
            margin-top: 8px;
            &-name {
                @include font-content-l($dobbies-font-weight-heavy);
            }

            span:nth-child(2) {
                margin-left: $msv-business-organization-list-list-item-spacing;
            }
        }
    
        &-remove-user-button {
            @include primary-button(var(--msv-business-organization-list-primary-btn-bg), var(--msv-business-organization-list-primary-btn-font-color), var(--msv-business-organization-list-primary-btn-border));
            margin-right: $msv-business-organization-list-margin-right;
            margin-top: $msv-business-organization-list-button-margin-top;
        }
    }
        
    &__mobile-view-container {
        display: none;
    }
  
    &__cancel-user-button,
    &__cancel-view-button {
        @include secondary-button(var(--msv-business-organization-list-secondary-btn-bg), var(--msv-business-organization-list-secondary-btn-font-color), var(--msv-business-organization-list-secondary-btn-border));
        margin-right: $msv-business-organization-list-margin-right;
        margin-top: $msv-business-organization-list-button-margin-top;
    }
  
    &__form {
        .msc-modal__body {
            display: flex;
            flex-wrap: wrap; 
        }

        &-item {
            display: flex;
            flex-direction: column;
            margin: $msv-business-organization-list-form-item-margin;
            margin-right: $msv-business-organization-list-input-spacing;
                
            &.width-50 {
                width: calc(50% - #{$msv-business-organization-list-input-spacing});
            }
        
            &.width-100 {
                width: calc(100% - #{$msv-business-organization-list-input-spacing})
            }
        
            &-label {
                @include font-content-l();
                margin: $msv-business-organization-list-label-margin;
            }
        
            &-input {
                @include font-content-l();
                @include vfi();
                background-color: #E5E5E5;
                border: $msv-business-organization-list-input-border;
                border-radius: $msv-business-organization-list-border-radius;
                box-sizing: border-box;
                display: block;
                height: $msv-business-organization-list-input-size;
                padding: $msv-business-organization-list-input-padding;
            
            }
        }
    
        &-save {
            @include primary-button(var(--msv-business-organization-list-primary-btn-bg), var(--msv-business-organization-list-primary-btn-font-color), var(--msv-business-organization-list-primary-btn-border));
            margin-top: $msv-business-organization-list-button-margin-top;
            margin-right: $msv-business-organization-list-margin-right;
        }

        &-cancel {
            @include secondary-button(var(--msv-business-organization-list-secondary-btn-bg), var(--msv-business-organization-list-secondary-btn-font-color), var(--msv-business-organization-list-secondary-btn-border));
            margin-top: $msv-business-organization-list-button-margin-top;
            margin-right: $msv-business-organization-list-margin-right;
        }   
                       
        @media only screen and (max-width: $msv-breakpoint-l) {
            .msc-modal__content {
                padding: $msv-business-organization-list-modal-padding;
            } 

            &__form-item {
                margin-right: $msv-business-organization-list-form-item-margin-right;

                &.width-50, &.width-100 {
                    width: 100%;
                }
            } 

            &__save-button,
            &__cancel-button {
                width: calc(50% - #{$msv-business-organization-list-input-spacing});
            }

        }
    }

    @media only screen and (max-width: $msv-breakpoint-l) {
        &__heading{
            margin-top: 32px;
        }

        &__table{
            display:none;
        }
        //   to render org-list on mobile port
        //     needs to be updated 
        &__mobile-view-container {
                display: flex;
                flex-direction: column;
        }
        &__mob-view-details {
            margin-top:20px;
            height: 82px;
            display: flex;
            justify-content: space-between;
            border-bottom:1px solid #D1D1D1;
        }

        &__mob-view-list-item {
            list-style-type: none;
            margin-bottom: 12px;
            @include font-content-l();
        }

        &__mob-view-action-button {
            align-self: flex-end;
            margin-left: 65%;
            position: absolute; 
        }        
        &__mob-hideable {
            background-color: orange;
            border: 1px solid green;
            width: 120px;
            color: white;
            font-size: 14px;
            padding: 10px;
            text-align: center;
            text-decoration: none;
            display: block;
        }
        // end of org-list view
            
        &__remove-user-button,
        &__add-user-back-button,
        &__cancel-user-button,
        &__cancel-view-button {
            width: 100%;
        }
    }

    .ms-table-business-org__pagination {
        width: 100%;
    
    .msc-pagination {
        @include font-content(var(--msv-font-weight-normal), var(--msv-business-organization-list-pagination-text-font-size), $dobbies-line-height-m);
        display: flex;
        list-style: none;
        padding-left: $msv-business-organization-list-pagination-padding-left;
        width: 100%;
    }
    
    ul.msc-pagination {
        display: flex;
        justify-content: center;
    }
    
    .previous.msc-page-item {
        margin-right: auto;
    
        .msc-pagination__prev {
            padding-left: 4px;
            display: inline-block;
        }
    
        .msc-page-link {
            color: var(--msv-business-organization-list-text-font-color);
            font-size: var(--msv-business-organization-list-pagination-text-font-size);
            display: block;
            padding: $msv-business-organization-list-page-item-page-link-padding;
            line-height: $msv-business-organization-list-page-item-page-link-line-height;
    
            &:before {
                @include msv-icon();
                content: '';
            }
    
            .ms-table-business-org__pagination-left {
                @include add-icon($msv-ChevronLeft, before);
            }
        }
    }
    
    .msc-page-item {
        padding-right: $msv-business-organization-list-page-item-padding-right;
    }
    
    .msc-page-item.disabled {
        .msc-page-link {
            color: $dobbies-secondary;
            pointer-events: none;
            cursor: auto;
        }
    }
    
    .next.msc-page-item {
        margin-left: auto;
    
        .msc-pagination__next {
            padding-right: 4px;
            display: inline-block;
        }
    
    
        .msc-page-link {
            color: var(--msv-business-organization-list-text-font-color);
            font-size: var(--msv-business-organization-list-pagination-text-font-size);
            display: block;
            padding: $msv-business-organization-list-page-item-page-link-padding;
            line-height: $msv-business-organization-list-page-item-page-link-line-height;
            padding-right: 4px;
    
            &:after {
                @include msv-icon();
                content: '';
            }
    
            .ms-table-business-org__pagination-right {
                @include add-icon($msv-ChevronRight, after);
            }
        }
    }
    
    .msc-page-link {
        color: var(--msv-business-organization-list-text-font-color);
        font-size: var(--msv-business-organization-list-pagination-text-font-size);
        display: block;
        padding: $msv-business-organization-list-page-item-page-link-padding;
        line-height: $msv-business-organization-list-page-item-page-link-line-height;
        @include vfi();
    }
    
    .active {
        text-decoration: underline;
    }
    
    .prev-text,
    .next-text {
        padding: $msv-business-organization-list-page-prev-next-padding;
    }
}
}