$msv-rating-count-border: 1px solid $dobbies-black;
$msv-rating-margin-right: 4px;
$msv-rating-margin-left: 2px;
$msv-rating-range-star-font-size:  var(--msv-body-font-size-m);
.msc-rating {
    position: relative;
    text-align: right;

    &__range {
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        overflow: visible;
        color: transparent;
        background: transparent;
        border: 0 none;
        opacity: 0;
        z-index: -1;

        &:focus {
            + .msc-rating__group {
                border: 1px dashed $white;
                outline: 1px dashed $black;
            }
        }
    }

    &__star {
        color: $dobbies-yellow;
        font-size: 10px;
        &:before {
            @include dobbies-icon();
            content: $dobbies-icon-star-filled;
            color: #facc00;
        }
    }

    &__half-star {
        //@include add-icon($msv-FavoriteStarFill);
        //@include add-icon($msv-half-star, after);
        color: $dobbies-gray-300;
        position: relative;
        font-size: 10px;

        &:after {
            @include dobbies-icon();
            content: $dobbies-icon-star-filled;
            color: var(--dobbies-font-primary-color);
            position: absolute;
            left: 0;
            top: 1px;
            width: 51%;
            overflow: hidden;
            color: #facc00;
        }

        &:before {
            @include dobbies-icon();
            content: $dobbies-icon-star-filled;

        }
    }

    &__empty-star {
        @include add-icon($msv-FavoriteStarFill);
        color: $dobbies-gray-300;
        font-size: 10px;
        &:before {
            @include dobbies-icon();
            content: $dobbies-icon-star-filled;
        }
    }

    &__star,
    &__half-star,
    &__empty-star {
        margin-left: $msv-rating-margin-left;
    }

    &__count {
        display: none;
        font-size: var(--msv-body-font-size-m);

        &:focus {
            outline: 0;
        }
    }
    &__group {
        display: inline-block; 
        margin-right: 20px;      
    }
}