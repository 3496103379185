$msv-sign-up-width: 40%;
$msv-sign-up-width-m: 100%;
$msv-sign-up-input-margin-top: 4px;
$msv-sign-up-account-item-margin-top: 20px;
$msv-sign-up-message-margin-top: 20px;
$msv-sign-up-message-padding: 20px;
$msv-sign-up-email-verification-buttons-margin-top: 12px;
$msv-sign-up-button-group-spacing: 32px;
$msv-sign-up-button-margin-top: 20px;
$form-btn-input-height: 46px;
$form-btn-input-height-md: 48px;
$form-btn-input-height-sm: 40px;
$dobbies-logo-path: '../../../';


//style presets
:root {
  --msv-sign-up-font-size: var(--msv-body-font-size-m);
  --msv-sign-up-font-color: var(--dobbies-font-primary-color);

  // heading
  --msv-sign-up-heading-font-size: var(--msv-body-font-size-xl);
  --msv-sign-up-heading-font-color: var(--dobbies-font-primary-color);

  // alert
  --msv-sign-up-success-alert-bg: #{$dobbies-gray-300};
  --msv-sign-up-success-alert-font-color: var(--dobbies-font-primary-color);
  --msv-sign-up-success-alert-border: #{$dobbies-gray-900};

  --msv-sign-up-error-alert-bg: var(--dobbies-error-color);
  --msv-sign-up-error-alert-font-color: var(--dobbies-font-secondary-color);
  --msv-sign-up-error-alert-border: var(--dobbies-error-color);

  --msv-sign-up-error-color: var(--dobbies-error-color);

  // primary button
  --msv-sign-up-primary-btn-bg: var(--dobbies-accent-brand-color);
  --msv-sign-up-primary-btn-font-color: var(--dobbies-font-secondary-color);
  --msv-sign-up-primary-btn-border: var(--dobbies-accent-brand-color);

   // secondary button
   --msv-sign-up-secondary-btn-bg: var(--dobbies-bg-color);
   --msv-sign-up-secondary-btn-font-color: var(--dobbies-font-primary-color);
   --msv-sign-up-secondary-btn-border: var(--dobbies-accent-brand-color);
}
$dobbies-image-path: '../../../';

.sign-up-container {
  position: relative;
  padding-top: 140px;
  min-height: calc(100vh - 304px);
  &:before {
    content: "";
    background-image: url('#{$dobbies-image-path}chevrons-top.svg');
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    display: block;
    height: 200px;
    position: absolute;
    top: 0;
    z-index: -1000;
    display: none;
  }
  &:after {
    content: "";
    background-image: url('#{$dobbies-image-path}chevrons-bottom.svg');
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    display: block;
    height: 260px;
    position: absolute;
    bottom: 0;
    z-index: -1000;
  }

  & > .row {
    width: 100%;
    margin: auto;
    // max-width: 504px;
    position: initial;

    @media (min-width: $dobbies-container-max-width-l) {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .ms-content-block {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-m-layout='full-width'] {
      .ms-content-block__details {

        @media (min-width : $msv-breakpoint-mbl-sm){
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .ms-content-block__cta a {
          display: block;
          background: url('#{$dobbies-logo-path}logo.svg') no-repeat top left;
          background-size: 100%;
          width: 210px;
          height: 55px;
          margin: 40px auto;
          border: 0;
          min-width: auto;

          @media (max-width: $msv-breakpoint-sm) {
            width: 169px;
            height: 46px;
          }
        }
      }
    }

    @media (max-width: $msv-breakpoint-xl) {
      padding-top: 35px;
    }
    &[data-m-layout="logo-settings"] {
      min-height: auto;
      margin: 70px 0 50px;
      @media (max-width: $dobbies-container-max-width-l) {
        margin: 30px 0;
      }
      @media (max-width: $dobbies-container-max-width-l) {
        margin: 10px 0 20px;
      }
    }
  }

  @media (min-width: $msv-breakpoint-xl) {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      width: 381px;
      height: 325px;
    }

    &:after {
      width: 279px;
      height: 295px;
    }

    // .row {
    //   max-width: 538px;
    // }
  }

  @media (max-width: $msv-breakpoint-m) {
    &:before {
      max-width: 181px;
      max-height: 154px;
    }

    &:after {
      max-width: 159px;
      max-height: 168px;
    }
  }

  @media (max-width: $msv-breakpoint-mbl-sm) {

    &:before, &:after {
      display: none;
    }
  }

  @media (max-width: $dobbies-container-max-width-l) and (min-width: $msv-breakpoint-sm){
    min-height: calc(100vh - 494px);
  }
}


.ms-sign-up {
  @include font-content(var(--msv-font-weight-normal), var(--msv-sign-up-font-size), $dobbies-line-height-m);
  color: var(--msv-sign-up-font-color);
  padding-bottom: 60px;
  max-width: 544px;
  margin: 0 auto;
  position: relative;

  @media (max-width: $dobbies-container-max-width-xl) {
    max-width: 590px;
  }
  @media (max-width: $dobbies-container-max-width-tablet) {
    max-width: 484px;
  }

  .step {
    &-one-details {
      position: absolute;
      left: 40px;
      top: 157px;

      &.inactive-step {
        display: none;
      }

      p {
        font-size: $dobbies-font-size-s;
        margin-bottom: 20px;
        line-height: 18px;
        &:last-child {
          margin-bottom: 0;
        }

        label {
          font-weight: normal;
          font-size: inherit;
          width: 80px;
          margin-right: 15px;
          margin-bottom: 0;
          display: inline-block;
        }

        b {
          font-size: inherit;
          font-weight: var(--dobbies-font-weight-bold);
          text-decoration: none;
        }
      }
    }

    &-breadcrums {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      left: 0px;
      top: 103px;
      z-index: 999;

      a {
        font-size: $dobbies-font-size-s;
        font-weight: $dobbies-font-weight-normal;
        &:hover,
        &:focus,
        &.active {
          text-decoration: none;
        }

        &.active {
          font-weight: $dobbies-font-weight-bold;
          pointer-events: none;
          color: $dobbies-grey;

          &:hover,
          &:focus {
            color: $dobbies-grey;
          }
        }

        &.inactive-pass {
          pointer-events: none;
          opacity: 0.5;
        }


        &::after {
          @include dobbies-icon();
          content: $dobbies-icon-arrow;
          width: 15px;
          height: 15px;
          font: 15px;
          font-weight: normal;
          color: $dobbies-secondary;
          transform: rotate(90deg);
          margin: 0 10px;
          position: relative;
          top: 5px;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  > a {
    position: absolute;
    right: 0;
    top: 35px;
    z-index: 999;
    font-weight: 500;
    text-decoration: underline;

    &:not([href]):not([class]) {
      text-decoration: underline;
    }
  }

  &__container {
    width: 100%;
  }

  &__heading {
    @include font-content(var(--msv-font-weight-normal), $dobbies-title-font-size-30, 38px);
    color: var(--msv-sign-up-heading-font-color);
    font-size: $dobbies-title-font-size-30;
    font-weight: var(--dobbies-font-weight-bold);
    text-align: center;
    margin-bottom: 25px;
    padding: 0;
    text-transform: uppercase;

    @media (max-width: $dobbies-container-max-width-tablet) {
      font-size: $dobbies-title-font-size-xs;
      line-height: 30px;
    }
  }

  &__account-items {
    display: flex;
    justify-content: center;
		margin-bottom: 40px;
    border: 1px solid $dobbies-grey;
    background: $dobbies-header-grey;

    > div {
      flex: 1;
      padding: 40px;
      padding-top: 94px;

      &.active {
        margin: 0;
        display: inline-block;
      }

      &.password-input {
        padding-top: 210px;
      }

      &.inactive {
        display: none;
      }
    }
  }

  &__account-item {
    margin-top: $msv-sign-up-account-item-margin-top;

    &:first-child {
      margin: 0;
    }

    &-input {
      @include form-input-el();
      margin-top: 0;
      width: 100%;
      border:1px solid $dobbies-gray-500;
      height: $form-btn-input-height;
      &:focus {
        // border: 1px dashed $dobbies-gray-500;
        outline: none;
      }

      @media (max-width: $msv-breakpoint-m) {
        height: $form-btn-input-height-md;
      }

      @media (max-width: $msv-breakpoint-mbl-sm) {
        height: $form-btn-input-height-sm;
      }
    }

    &-emailVerificationCode {
      .ms-sign-up__account-item-label {
        margin-top: 10px;
        display: inline-block;

        @media (max-width: $msv-breakpoint-m) {
          margin-top: 10px;
        }
      }
    }
  }

  .verificationControlContent {
    margin-top: $msv-sign-up-account-item-margin-top;
  }

  /*Override inline style inject by AAD so we need important*/
  &__page-success[aria-hidden="false"]{
  display: block !important;
  }
  &__page-error[aria-hidden="false"] {
    display: block ;
  }

  &__page-success {
    color: var(--dobbies-heading-primary-color);
    font-weight: var(--dobbies-font-weight-bold);
    display: none;
    margin-top: 10px;
    float: left;
  }

  &__page-error {
    color: var(--dobbies-msg-alert-color);
    font-weight: var(--dobbies-font-weight-bold);
    display: none;
    margin-top: 5px;
  }

  &__item-error {
    color:var(--msv-sign-up-error-color);
    text-decoration: none;
    float: left;
    width: 100%;
    line-height: 35px;
    margin-top: -10px;
  }

  &__email-verification-button,
  &__create-button {
    @include primary-button(var(--msv-sign-up-primary-btn-bg), var(--msv-sign-up-primary-btn-font-color), var(--msv-sign-up-primary-btn-border));
    margin-top: $msv-sign-up-button-margin-top;
    width: 100%;
    border-radius: 0;
    line-height: 0;
    font-size: $dobbies-font-size-m;
    font-weight: var(--dobbies-font-weight-bold);
    height: $form-btn-input-height;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    overflow: hidden;
    border: 0;
    float: left;

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--dobbies-bg-color-secondary);
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      transform-origin: 0 50%;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
      color: var(--dobbies-font-primary-color);
      background: var(--dobbies-bg-color-primary);
      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: $dobbies-gray-300;
      background-color: $dobbies-gray-300;
      color: var(--dobbies-font-secondary-color);
      &:hover {
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:after {
          background-color: $dobbies-gray-300;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
        }
      }
    }

  }

  &__create-button {
    float: none;
  }

  &__email-verification-button {
    &.verifyCode, &.ms-sign-up__verify-email-resend-button {
      width: 222px;
      min-width: auto;
      @media screen and (max-width: $dobbies-container-max-width-xl) {
        width: 247px;
      }
      @media screen and (max-width: $dobbies-container-max-width-tablet) {
        width: 193px;
      }
      @media screen and (max-width: $dobbies-container-max-width-s) {
        width: 100%;
      }
    }

    &.ms-sign-up__verify-email-resend-button  {
      float: right;
      background-color: transparent;
      border: 1px solid #111;
      color: var(--dobbies-font-primary-color);
    }
  }

  &__cancel-button {
    @include secondary-button(var(--msv-sign-up-secondary-btn-bg), var(--msv-sign-up-secondary-btn-font-color), var(--msv-sign-up-secondary-btn-border));
    margin-top: $msv-sign-up-button-margin-top;
    width: 100%;
    display: none;
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    &__container {
      width: $msv-sign-up-width-m;
    }
  }

  @media screen and (max-width: $msv-breakpoint-mbl-sm) {
    &__container {
      margin-top: 0;
    }
  }

  &__account-item-label {
    font-size: $dobbies-font-size-s;
    margin-bottom: 10px;
    display: inline-block;
    line-height: 18px;
  }

  &__sign-up-disclaimer {
    font-size: var(--dobbies-body-font-size-xs);
    padding-top: 12px;
    text-align: center;

    p {
      display: inline-block;
    }

    a {
      text-decoration: underline;
    }
  }
  .password-error-container{
    width: 78%;
    position: absolute;
    bottom: 340px;
    left: 40px;
    display: none;
    @media (min-width:461px) and (max-width: $dobbies-container-max-width-s)  {
      bottom: 318px;
    }

    @media (min-width: $msv-container-max-width-s){
      bottom: 318px;
      left: 42px;
    }
    @media (min-width: $msv-container-max-width-m){
       width: 75%;
       left: 45px;
    }
    @media (min-width: $dobbies-container-max-width-tablet) and (max-width:1476px)  {
      bottom: 300px;
    }


    .password-errors{
      display: flex;
      align-items: center;
      justify-content: space-between;
      p{
        text-decoration: underline #f8f8f8;
        font-size: 14px;
        line-height: 18px;
      }
      & .valid {
        color: var(--dobbies-bg-color-primary);;
        &::before {

          padding-right: 10px;
          content: "✔";
        }
      }
      & .invalid {
        color: var(--msv-sign-up-error-color);
        &::before {
          padding-right: 10px;
          content: "✖";
        }
      }
    }
  }

  &__page-error-text {
    float: left;
  }

  .CheckboxMultiSelect {
    list-style: none;
    margin-top: 20px;
    label:not(:first-child) {
      margin-bottom: 0;
      padding: 0 10px;
    }
    &.passwordFocused{
      margin-top: 65px;
    }
  }
}
.undefined  {
  &__account-item {
    margin-top: $msv-sign-up-account-item-margin-top;
    @media (max-width: $msv-breakpoint-m) {
      margin-top: 15px;
    }
    .undefined__dropdown {
      @include form-input-el();
      margin-top: $msv-sign-up-input-margin-top;
      width: 100%;
      border:1px solid $dobbies-gray-500;
      height: $form-btn-input-height;
      &:focus {
        outline: none;
      }
      @media (max-width: $msv-breakpoint-m) {
        height: $form-btn-input-height-md;
      }
      @media (max-width: $msv-breakpoint-mbl-sm) {
        height: $form-btn-input-height-sm;
      }
    }
    &-label {
      font-size: $dobbies-font-size-s;
      margin-bottom: 10px;
      display: inline-block;
    }
  }
  &__item-error {
    color: var(--msv-sign-up-error-color);
  }
}
.logo-container {
  .centered-header {
    position: absolute;
    width: 180px;
    height: 50px;
    z-index: 1;
    left: 50%;
    margin-left: -90px;
    margin-top: 50px;
    min-height: auto;
    > a {
      display: block;
      background: url('#{$dobbies-logo-path}logo.svg') no-repeat top left;
      background-size: 100%;
      width: 180px;
      height: 50px;
      border: 0;
      @media screen and (max-width: $dobbies-container-max-width-s) {
        width: 169px;
        height: 46px;
        margin: 20px auto;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .ms-content-block__details {
      display: none
    }

    .ms-content-block__image {
      pointer-events: none;
    }
  }
}


// temporary change
.modal-open {
	display: block;
	position: static !important;
	overflow: auto;
}
