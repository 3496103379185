$dobbies-advice-heading-font-size: 60px;
$msv-checkout-loyalty-details-icon-margin: 24px;

%common-list {
    list-style-type: none;
    padding: 0;
        li {
            padding-bottom: 15px;
            margin-left: 19px;
        &:before {
            @include dobbies-icon();
            content: $dobbies-icon-arrow;
            font-size: var(--dobbies-breadcrumb-icon);
            transform: rotate(90deg);
            color: var(--dobbies-breadcrumb-icon-color);
            font-weight: var(--dobbies-font-weight-heavy);
            margin-right: 10px;
            font-size: 12px;
            width: 11px;
            margin-left: -21px;
        }
    }
  }

  %common-button {
    margin-top: 0;
                
    .msc-cta__primary {
        background-color: transparent;
        border: 1px solid var(--dobbies-breadcrumb-icon-color);
        color: var(--dobbies-font-primary-color);
        min-height: 38px;
        min-width: 215px;
        line-height: initial;
        border-radius: 4px;
        text-decoration: none;
        line-height: initial;
        font-size: var(--dobbies-body-font-size-l);
        font-weight: var(--dobbies-font-weight-normal);
        cursor: pointer;
        position: relative;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        div {
            margin: auto;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
          &:hover,
          &:focus,
          &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-bg-color);
            text-decoration: none;
            &:after {
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
            }
        }
    }
}

%shared-text {
    min-height: auto;
    .ms-content-block__image {
        img {
            @media (max-width: $msv-breakpoint-mbl-sm){
                margin: auto;
            }
        }
    }

    .ms-content-block__details {
        position: initial;
        height: auto;
        padding: 0;
        text-align: center;

        @media (max-width: $msv-breakpoint-mbl-sm){
            width: 100%;
        }
    }
    
    .ms-content-block__title {
        text-transform: uppercase;
        font-size: $dobbies-advice-heading-font-size;
        color: $dobbies-grey;
        line-height: 50px;
        font-weight: var(--dobbies-font-weight-normal);
    }

    .ms-content-block__text {
        font-size: var(--dobbies-body-font-size-m);
        // margin-top: 21px;
    }

    @media (max-width: $msv-breakpoint-mbl-sm){
        .ms-content-block__title {
            font-size: 30px;
            line-height: 30px;
        }

        .ms-content-block__text {
            font-size: var(--dobbies-body-font-size-s);
            margin-top: 0;
        }
    }

    @media (max-width: $msv-breakpoint-sm){
        .ms-content-block__title {
            line-height: 30px;
        }
    }
}

.ms-gardening-tips, .ms-advice-template-container, .ms-advice-podcast-container, .ms-advice-recipe-guide-container, .ms-ideas-overview-container {
    .gardening-main-banner, div[class*='imageplacement'], .block-with-images, .ms-template-sample-banner {
        padding: 0;

        &.ms-content-block, .ms-content-block {
            @extend %shared-text;
        }
    }

    .gardening-main-banner.ms-content-block {
            .ms-content-block__details {
                padding: 20px 60px 50px 60px;
                text-align: center;
    
                @media (max-width: $msv-breakpoint-mbl-sm){
                    padding: 20px 10px 50px 10px;
                }
                
            }
            
            .ms-content-block__text, .ms-content-block__title {
                max-width: 100%;
                line-height: 23px;
            }
    
            .ms-content-block__title {
                text-align: center;
                margin: auto;
                line-height: 40px;
            }
    
            .ms-content-block__text {
                margin-top: 21px;
            }
    }

    div[class*='imageplacement'] {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 40px;

        .ms-content-block__details, .ms-content-block__image {
            width: 100%;

            @media (min-width: $msv-breakpoint-mbl-sm){
                width: 50%;
                display: flex;
                flex-direction: column;
                padding-left: 10px;
                padding-right: 10px;
            }

            @media (min-width: $msv-breakpoint-l){
                justify-content: center;
            }
        }

        .ms-content-block__details {
            margin-left: 0;
            align-items: normal;

            .ms-content-block__text {
                & > p:first-child {
                    padding-top: 15px;
                }

                ul {
                    @extend %common-list;
                }
            } 

            .ms-content-block__cta {
                @extend %common-button;
            }
        }

        &.ms-content-image-75 {
            margin-bottom: 25px;
    
            .ms-content-block__image, .ms-content-block__details {
                width: 100%;
                order: 0;
            }
    
            .ms-content-block__details {
                .ms-content-block__text p:last-child {
                    margin-bottom: 20px;
    
                    u {
                        text-decoration: unset;
                        position: absolute;
                        bottom: 8%;
                        left: 1%;
                    }
                }
            }
    
            &.imageplacement__right {
                .ms-content-block__text p:last-child {
                    u {
                        left: 36%;
                    }
                }
            }
    
            @media (min-width: $msv-breakpoint-l){
                .ms-content-block__image {
                    width: 65%;
                }
        
                .ms-content-block__details {
                    width: 35%;
                }
    
                &.imageplacement__right .ms-content-block__image {
                    order: 1;
                }
            }
    
            @media (max-width: $msv-breakpoint-l){
                .ms-content-block__image {
                    padding-left: 0;
                    padding-right: 0;
                }
                .ms-content-block__details {
                     position: relative;
                     padding-top: 40px;
                     
                     .ms-content-block__text p:last-child u {
                         top: 5px;
                         left: 0%;
                     }
                }
            }
    
            @media (max-width: $msv-breakpoint-l) and (min-width: $msv-breakpoint-sm) {
                max-width: 492px;
                margin-left: auto;
                margin-right: auto;
            }
    
        }
    
        &.ms-content-image-25 {
            width: 100%;
            
            .ms-content-block__image, .ms-content-block__details {
                width: 100%;
            }
    
            @media (min-width: $msv-breakpoint-mbl-sm){
                .ms-content-block__image {
                    width: 35%;
                }
        
                .ms-content-block__details {
                    width: 65%;
                }
            }
    
            @media (max-width: $msv-breakpoint-mbl-sm){
                .ms-content-block__image {
                    padding-left: 0;
                    padding-right: 0;
                }

                .ms-content-block__details {
                    padding-top: 20px;
                }
            }
        }
    }

    .imageplacement__right {
        order: 0;
        @media (min-width: $msv-breakpoint-mbl-sm){
            .ms-content-block__image {
                order: 1;
            }
        }
    }

    .block-with-images {

        .ms-content-block__details, .ms-content-block__image {
            @media (min-width: $msv-breakpoint-mbl-sm) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .ms-content-block {
            padding-bottom: 40px;
        }

        .ms-content-block .ms-content-block__details {
            padding-top: 30px;
            margin-left: 0;
            align-items: normal;

            .ms-content-block__title {
                padding-bottom: 15px;
            }
    
            .ms-content-block__text ul {
                @extend %common-list;
            }
    
            .ms-content-block__cta {
                @extend %common-button;
            }
        }
    }
}

.ms-gardening-tips {
    .ms-content-block:not(.gardening-main-banner) {
        .ms-content-block__details .ms-content-block__title{
            font-size: 30px;
            line-height: 30px;
        }
    }

    @media (max-width: $msv-breakpoint-mbl-sm) {
        div[class*='imageplacement'] {
            .ms-content-block__image {
                padding-left: 0;
                padding-right: 0;
            }

            .ms-content-block__details {
                margin-top: 20px;
            }
        }
    }
}

.ms-advice-template-container, .ms-advice-podcast-container, .ms-advice-recipe-guide-container, .ms-ideas-overview-container {

    .ms-text-block {
        font-size: var(--dobbies-body-font-size-m);
        line-height: 22px;
        ul {
            @extend %common-list;
        }
        > p {
            margin-bottom: 1em;
        }
    }

    .ms-template-sample-banner {
        margin-bottom: 40px;

        .ms-content-block__details {
            margin-top: -180px;

            .ms-content-block__title {
                margin-bottom: 100px;
                max-width: 360px;
                background: rgba(255, 255, 255, 0.75);
                width: 100%;
                position: relative;
                padding: 12px;
                text-align: right;
                line-height: 45px;
            }

            .ms-content-block__text {
                text-align: center;
            }
        }
        .ms-content-block__image img {
            width: 80%;
            margin-left: auto;
        }
    }

    &.ms-content-text-75 {
        .col-md-6:first-child {
            flex: 0 0 70%;
            max-width: 70%;
        }
        .col-md-6:nth-child(2) {
            flex: 0 0 30%;
            max-width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 30px;
        }

        @media (max-width: $msv-breakpoint-l){
            .col-md-6:first-child {
                flex: 0 0 50%;
                max-width: 50%;

                .ms-content-block__image {
                    display: none;
                }

                .ms-content-block__details {
                    width: 100%;
                }
            }
            .col-md-6:nth-child(2) {
                flex: 0 0 50%;
                max-width: 50%;
                padding-top: 0;
            }
        }

        @media (max-width: $msv-breakpoint-mbl-sm){
            .col-md-6:first-child {
                flex: 0 0 100%;
                max-width: 100%;
                
                .ms-content-block {
                    padding-bottom: 0;
                }

                .ms-content-block__image {
                    display: block;
                }

                .ms-content-block__details {
                    width: 100%;
                    padding-top: 20px;
                }
            }
            .col-md-6:nth-child(2) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-top: 0;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }

    & > .row > .col-md-2, & > .row > .col-md-4 {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 40px;

        .ms-content-block__details .ms-content-block__cta {
            text-align: center;
            padding: 8px 0;

            a {
                font-size: var(--dobbies-body-font-size-m);
                font-weight: var(--dobbies-font-weight-heavy);
            }
        }
    }
    
    @media (max-width: $msv-breakpoint-l){
        .ms-content-block .ms-content-block__image {
            justify-content: initial;
        }
    }

    @media (max-width: $msv-breakpoint-mbl-sm){
        .ms-template-sample-banner {
            .ms-content-block__image img {
                width: 100%;
            }

            .ms-content-block__details {
                margin-top: 0;
    
                .ms-content-block__title {
                    margin-bottom: 0;
                    max-width: 100%;
                    text-align: center;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    line-height: 30px;
                }
            }
        }

        & > .row > .col-md-2 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

.ms-advice-template-container {
    @media (max-height: $msv-breakpoint-mbl) and (orientation: landscape) {
        & > .row > .col-md-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }

        & > .row > .col-md-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.ms-advice-template-center-text h2 {
    font-size: $dobbies-advice-heading-font-size;
    color: var(--dobbies-heading-primary-color);
    line-height: 50px;
    font-weight: var(--dobbies-font-weight-heavy);
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
}

%shared-banner {
    .ms-content-block__details {
        .ms-content-block__title {
            margin-bottom: 85px;
            max-width: 400px;
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        .ms-content-block__details {
            margin-top: -145px;
        }
    }

    @media (max-width: $msv-breakpoint-mbl-sm){
        .ms-content-block__details {
            margin-top: 0;

            .ms-content-block__title {
                margin-bottom: 0;
                line-height: 35px;
            }
        }
    }
}

.ms-advice-podcast-container {
    .ms-template-sample-banner {
        @extend %shared-banner;

        .ms-content-block__details {
            .ms-content-block__text{ 
                @media (min-width: $msv-breakpoint-l){
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            @media (max-width: $msv-breakpoint-xl) and (min-width: $msv-breakpoint-l) {
                margin-top: -220px;

                .ms-content-block__title {
                    margin-bottom: 70px;
                    max-width: 270px;
                }
            }
            @media (max-width: $msv-breakpoint-l) and (min-width: $msv-breakpoint-mbl-sm) {
                .ms-content-block__title {
                    margin-bottom: 70px;
                    max-width: 200px;
                }
            }
        }
    }

    & > .row {
        justify-content: center;

        .col-12 {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
            padding-bottom: 40px;
    
            &:first-child, &:nth-child(2) {
                flex: 0 0 100%;
                max-width: 100%;

                .ms-content-block {
                    @extend %shared-text;
                }
            }

            &:not(:first-child):not(:nth-child(2)) {

                .ms-content-block {
                    @extend %shared-text;
                    .ms-content-block__title {
                        font-size: var(--dobbies-body-font-size-ml);
                        font-weight: var(--dobbies-font-weight-normal);
                        line-height: 25px;
                        padding-bottom: 15px;
                    }

                    .ms-content-block__details {
                        padding-top: 20px;
                    }

                    .ms-content-block__text {
                        margin-top: 0;
                    }

                    .ms-content-block__cta {
                        margin-top: 15px;

                        a {
                            font-size: var(--dobbies-body-font-size-m);
                            font-weight: var(--dobbies-font-weight-heavy);
                            text-decoration: underline;
                            margin-right: 30px;
                            cursor: pointer;
                        }
                    }
                }

                @media (max-width: $msv-breakpoint-l) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                @media (max-width: $msv-breakpoint-mbl-sm){
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                @media (min-width: $msv-breakpoint-mbl-sm){
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @media (min-width: $msv-breakpoint-l) {
                    &:nth-child(3n+3) {
                        padding-left: 0;
                    }
    
                    &:nth-child(3n+4) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
    
                    &:nth-child(3n+5) {
                        padding-right: 0;
                    }
                }
    
                @media (max-width: $msv-breakpoint-l) {
                    &:nth-child(2n+3) {
                        padding-left: 0;
                    }
                    &:nth-child(2n+4) {
                        padding-right: 0;
                    }
                }
            }
            
            &:first-child {
                padding-bottom: 0;

                .ms-content-block .ms-content-block__title {
                    @media (max-width: $msv-breakpoint-l){
                        font-size: 30px;
                        line-height: 30px;
                    }
                }
            }

            &:nth-child(2) {
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid $dobbies-black;
                border-bottom: 1px solid $dobbies-black;
                margin-bottom: 40px;

                .ms-content-block {
                    .ms-content-block__details {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding-top: 0;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .ms-content-block__text {
                        margin-right: 40px;
                        margin-top: 0;
                        font-size: var(--dobbies-body-font-size-ml);
                        font-weight: var(--dobbies-font-weight-bold);
                    }

                    .ms-content-block__cta {
                        display: inline-block;
                        margin-top: 0;

                        a {
                            min-height: 38px;
                            max-height: 38px;
                            line-height: initial;
                            min-width: 215px;
                            border: 1px solid $dobbies-black;
                            font-size: var(--dobbies-body-font-size-l);
                            font-weight: var(--dobbies-font-weight-normal);
                            border-radius: 4px;
                            background-color: transparent;
                            color: var(--dobbies-font-primary-color);
                            justify-content: center;
                            align-items: center;
                            display: inline-flex;
                            cursor: pointer;
                            padding: 6px;
                            position: relative;
                            -webkit-transform: perspective(1px) translateZ(0);
                            transform: perspective(1px) translateZ(0);
                            position: relative;
                            -webkit-transition-duration: 0.3s;
                            transition-duration: 0.3s;
                            overflow: hidden;
                            &:after {
                                content: "";
                                position: absolute;
                                z-index: -1;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background: var(--dobbies-bg-color-secondary);
                                -webkit-transform: scaleX(0);
                                transform: scaleX(0);
                                -webkit-transform-origin: 0 50%;
                                transform-origin: 0 50%;
                                -webkit-transition-property: transform;
                                transition-property: transform;
                                -webkit-transition-duration: 0.3s;
                                transition-duration: 0.3s;
                                -webkit-transition-timing-function: ease-out;
                                transition-timing-function: ease-out;
                              }
                              &:hover,
                              &:focus,
                              &:active {
                                color: var(--dobbies-font-primary-color);
                                background: var(--dobbies-bg-color);
                                text-decoration: none;
                                &:after {
                                  -webkit-transform: scaleX(1);
                                  transform: scaleX(1);
                                }
                              }

                            @media (max-width: $msv-breakpoint-l) {
                                min-width: 173px;
                            }

                            @media (max-width: $msv-breakpoint-sm) and (min-width: $msv-breakpoint-mbl) {
                                min-width: 130px;
                            }

                            @media (max-width: $msv-breakpoint-mbl) {
                                min-width: 150px;
                            }
                        }
                    }

                    @media (max-width: $msv-breakpoint-mbl-sm){
                        .ms-content-block__details {
                            flex-direction: column;
                        }

                        .ms-content-block__text {
                            font-size: var(--dobbies-body-font-size-ml);
                            margin-right: 0;
                            margin-bottom: 20px;
                        }

                        .ms-content-block__cta a {
                            margin: auto;
                            margin-right: 10px;
                        } 
                    }
                }
            }
        }
    }
}

.ms-advice-recipe-guide-container {

    .ms-template-sample-banner {
        @extend %shared-banner;

        .ms-content-block__details {
            @media (min-width: $msv-breakpoint-xl) {
                margin-top: -180px;

                .ms-content-block__title {
                    margin-bottom: 85px;
                    font-size: var(--dobbies-body-font-size-xxl);
                }
            }

            @media (max-width: $msv-breakpoint-l) and (min-width: $msv-breakpoint-mbl-sm) {
                margin-top: -180px;

                .ms-content-block__title {
                    margin-bottom: 65px;
                    max-width: 240px;
                    font-size: 30px;
                }
            }
        }

        .ms-content-block__text{ 
            @media (min-width: $msv-breakpoint-l){
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        @media (min-width: $msv-breakpoint-l) and (max-width: $msv-breakpoint-xl) {
            .ms-content-block__details {
                .ms-content-block__title {
                    margin-bottom: 60px;
                    max-width: 322px;
                }
            }
        }

        @media (max-width: $msv-breakpoint-mbl-sm) {
            margin-bottom: 25px;
        }

    }

    div[class*='imageplacement'] {
        @extend %shared-text;

        .ms-content-block__details .ms-content-block__text {
            ul  li {
                padding-bottom: 4px;
            }

            @media (min-width: $msv-breakpoint-mbl-sm) {
                ul {
                    margin-top: 0;
                }
            }
        }
    }

    .ms-text-block {
        h2 {
            font-size: 30px;
            color: var(--dobbies-heading-primary-color);
            line-height: 30px;
            font-weight: var(--dobbies-font-weight-heavy);
            
        }

        &.ms-recipe-top-tip {
            background-color: $dobbies-footer-grey;
            padding: 15px;
            padding-bottom: 1px;

            h2 {
                font-style: italic;
            }

            @media (max-width: $msv-breakpoint-mbl-sm) {
                &:not(.ms-recipe-no-title) {
                    margin-bottom: -65px;
                }
            }

            &.ms-recipe-no-title {

                @media (min-width: $msv-breakpoint-sm) {
                    margin-left: -8px;
                    padding-right: 15px;
                }

                @media (min-width: $msv-breakpoint-l) {
                    padding-right: 30px;
                }

                @media (max-height: $msv-breakpoint-mbl) and (orientation: landscape) {
                    padding-left: 6px;
                    padding-right: 33px;
                }
            }
        }

        &.ms-recipe-no-title {

            @media (max-width: $msv-breakpoint-mbl-sm) {
                h2 {
                    display: none;
                }
    
                ol {
                    margin-top: -10px;
                }

                ul {
                    margin-top: 0;
                }
            }
        }
    }

    .col-12:not(:first-child):not(:nth-child(2)):not(:last-child) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 40px;

        ol {
            padding-left: 0;
            list-style: none;
            counter-reset: li 0;
            margin-bottom: 0;

            li {
                margin-left: 17px;
                padding-bottom: 15px;

                &:before {
                  counter-increment: li;
                  content: counter(li) ". ";
                  text-indent: 60px;
                  margin-left: -19px;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        &:nth-child(4) {
            ol {
                list-style: none;
                counter-reset: li 3;
                

                @media (min-width: $msv-breakpoint-mbl-sm){
                    padding-left: 9px;
                }

                li {
                    margin-left: 17px;

                    &:before {
                      counter-increment: li;
                      content: counter(li) ". ";
                      text-indent: 60px;
                      margin-left: -19px;
                    }
                } 
            }  
        }

        @media (min-width: $msv-breakpoint-mbl-sm){
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media (min-width: $msv-breakpoint-xl){
            ol {
                padding-right: 15px;
            }
        }
    }

    .col-12:not(:first-child) {
        .ms-content-block {
            .ms-content-block__title {
                font-size: 30px;
                color: var(--dobbies-heading-primary-color);
                line-height: 30px;
                font-weight: var(--dobbies-font-weight-heavy);
            }
        
            .ms-content-block__text {
                font-size: var(--dobbies-body-font-size-m);

                @media (max-width: $msv-breakpoint-mbl-sm){
                    margin-top: 21px;
                }
            }
        
            @media (max-width: $msv-breakpoint-mbl-sm){
                .ms-content-block__title {
                    font-size: 30px;
                    line-height: 30px;
                }
        
                .ms-content-block__text {
                    font-size: var(--dobbies-body-font-size-s);
                    margin-top: 0;
                }
            }
        }

        .imageplacement__left .ms-content-block__image{
            padding-left: 0;
        }
    }

    div[class*='imageplacement'].ms-content-image-75 {
        margin-bottom: 0;

        @media (min-width: $msv-breakpoint-mbl-sm){
            max-width: unset;

            .ms-content-block__image {
                width: 65%;
            }

            .ms-content-block__title {
                padding-bottom: 15px;
            }
    
            .ms-content-block__details {
                width: 35%;
                padding-left: 15px;

                @media (min-width: $msv-breakpoint-mbl-sm) and (max-width: $msv-breakpoint-l) {
                    padding-top: 0;
                    justify-content: center;
                }
            }
        }

        @media (max-width: $msv-breakpoint-mbl) {
            .ms-content-block__details {
                padding-top: 20px;
            }
        }
    }

    @media (max-width: $msv-breakpoint-mbl-sm){

        div[class*='imageplacement'].ms-content-image-50 {
            .ms-content-block__image {
                padding-right: 0;
            }

            .ms-content-block__details {
                padding-left: 0;
                padding-top: 20px;

                h2 {
                    padding-bottom: 5px;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-mbl-sm) {
        div[class*='imageplacement'].ms-content-image-50 {
            .ms-content-block__details {
                padding-left: 7px;
            }
        }
    }

    @media (min-width: $msv-breakpoint-mbl-sm) and (max-width: $msv-breakpoint-l) {
        .ms-content-image-50 {
            .ms-content-block__details {
                justify-content: center;
            }
        }
    }
}

.ms-ideas-overview-container {

    .ms-template-sample-banner {
        padding-top: 20px;

        .ms-content-block__details {
            @media (min-width: $msv-breakpoint-xl) {
                margin-top: -110px;

                .ms-content-block__title {
                    margin-bottom: 60px;
                    font-size: var(--dobbies-body-font-size-xxl);
                }
            }

            @media (max-width: $msv-breakpoint-xl) and (min-width: $msv-breakpoint-l) {
                margin-top: -140px;

                .ms-content-block__title {
                    margin-bottom: 50px;
                    max-width: 270px;
                }
            }
            @media (max-width: $msv-breakpoint-l) and (min-width: $msv-breakpoint-mbl-sm) {
                margin-top: -100px;

                .ms-content-block__title {
                    margin-bottom: 50px;
                    max-width: 300px;
                }
            }
        }

        @media (min-width: $msv-breakpoint-xl) {
            padding-top: 35px;
        }

        @media (min-width: $msv-breakpoint-mbl-sm) {
            padding-left: 10px;
            padding-right: 10px;
        }

        @media (max-width: $msv-breakpoint-l) {
            padding-top: 13px;
        }

        @media (max-width: $msv-breakpoint-mbl-sm) {
            margin-bottom: 0;
        }
    }

    .ms-ideas-overview-header .col-12{
        &:nth-child(2) {
            padding-top: 0;
            padding-bottom: 35px;
            // margin-bottom: 40px;

            .ms-content-block {
                min-height: unset;
                text-align: center;

                .ms-content-block__details {
                    padding: 0;
                    margin-left: auto;
                    margin-right: auto;
                    height: auto;
                    display: initial;
                    position: initial;
                    text-align: center;
                }

                .ms-content-block__title {
                    font-size: var(--dobbies-body-font-size-ml);
                    font-weight: var(--dobbies-font-weight-heavy);
                    color: var(--dobbies-heading-primary-color);
                    max-width: 100%;
                }

                .ms-content-block__text {
                    margin-right: 40px;
                    margin-top: 0;
                    font-size: var(--dobbies-body-font-size-ml);
                    font-weight: var(--dobbies-font-weight-heavy);
                }

                .ms-content-block__cta {
                    display: inline-block;
                    margin-top: 0;

                    a {
                        min-height: 38px;
                        max-height: 38px;
                        line-height: initial;
                        min-width: 137px;
                        border: 1px solid var(--dobbies-breadcrumb-icon-color);
                        font-size: var(--dobbies-body-font-size-s);
                        font-weight: var(--dobbies-font-weight-normal);
                        border-radius: 4px;
                        background-color: transparent;
                        color: var(--dobbies-font-primary-color);
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                        cursor: pointer;
                        margin: 4px;
                        position: relative;
                        -webkit-transform: perspective(1px) translateZ(0);
                        transform: perspective(1px) translateZ(0);
                        position: relative;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        overflow: hidden;
                        &:after {
                            content: "";
                            position: absolute;
                            z-index: -1;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: var(--dobbies-bg-color-secondary);
                            -webkit-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transform-origin: 0 50%;
                            transform-origin: 0 50%;
                            -webkit-transition-property: transform;
                            transition-property: transform;
                            -webkit-transition-duration: 0.3s;
                            transition-duration: 0.3s;
                            -webkit-transition-timing-function: ease-out;
                            transition-timing-function: ease-out;
                          }
                          &:hover,
                          &:focus,
                          &:active {
                            color: var(--dobbies-font-primary-color);
                            background: var(--dobbies-bg-color);
                            text-decoration: none;
                            &:after {
                              -webkit-transform: scaleX(1);
                              transform: scaleX(1);
                            }
                          }

                        @media (min-width: $msv-breakpoint-l) {
                            &:last-child {
                                margin-right: 0;
                            }

                            &:first-child {
                                margin-left: 0px;
                            }
                        }

                        @media (min-width: $msv-breakpoint-xl) {
                            min-width: 174px;
                            margin: 8px;
                        }

                        @media (max-width: $msv-breakpoint-l) {
                            min-width: 138px;
                            margin-top: 10px;
                        }

                        @media (max-width: $msv-breakpoint-sm) and (min-width: $msv-breakpoint-mbl) {
                            min-width: 130px;
                        }

                        @media (max-width: $msv-breakpoint-mbl) {
                            min-width: 150px;
                            margin-bottom: 6px;
                        }
                    }
                }

                @media (max-width: $msv-breakpoint-mbl-sm){
                    // &__details {
                    //     position: relative;
                    //     min-height: 48px;
                    //     border: 1px solid var(--dobbies-border-color);
                    //     display: flex;
                    //     align-items: center;
                    //     padding-left: 12px;
                    //     font-size: var(--dobbies-body-font-size-m);
                
                    //     &:after {
                    //         @include dobbies-icon();
                    //         content: $dobbies-icon-arrow;
                    //         transform: rotate(-180deg);
                    //         position: absolute;
                    //         top: 50%;
                    //         right: 15px;
                    //         margin-top: -9px;
                    //         background: var(--dobbies-bg-color);
                    //         font-weight: var(--dobbies-font-weight-heavy);
                    //         font-size: var(--dobbies-body-font-size-l);
                    //         color: var(--dobbies-icon-color);
                    //         z-index: 1;
                    //         pointer-events: none;
                    //     }

                    //     &:before {
                    //         content: 'Filter';
                    //         position: absolute;
                    //         top: 15px;
                    //         left: 10px;
                    //         font-size: var(--dobbies-body-font-size-m);
                    //     }

                    //     .ms-content-block__cta {
                    //         display: none;
                    //     }

                    //     &:focus, &:hover {
                    //         border-bottom: 0;

                    //         .ms-content-block__cta {
                    //             display: block;
                    //         }
                    //     }
                    // }

                    // &__title {
                    //     display: none;
                    // }

                    // &__cta {
                    //     position: absolute;
                    //     width: 100%;
                    //     left: 0;
                    //     top: 48px;
                    //     z-index: 1;
                    //     background: white;
                    //     outline: var(--dobbies-border-color) solid 1px;

                    //     a {
                    //         min-width: 100%;;
                    //         font-size: var(--dobbies-body-font-size-m);
                    //         padding-bottom: 5px;
                    //         padding-left: 12px;
                    //         padding-top: 5px;
                    //         border: 0;
                    //         justify-content: initial;
                    //         margin: 0;
                    //         border-radius: 0;
                    //         text-decoration: none;
                            
                
                    //         &:hover, &:focus {
                    //           background-color: #1e90ff;
                    //           color: white;
                    //           outline: unset;
                    //           text-decoration: none;
                    //         }
                            
                    //         &:first-child {
                    //           padding-top: 6px;
                    //         }
                    //     } 
                    // }
                }
            }
        }
    }

    .ms-ideas-overview-content {
        & > .row {
            justify-content: center;
    
            .col-12 {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
                padding-bottom: 40px;
        
                &:first-child {
                    flex: 0 0 100%;
                    max-width: 100%;
    
                    .ms-content-block {
                        @extend %shared-text;

                        &__details {
                            justify-content: center;

                            @media (max-width: $msv-breakpoint-mbl-sm){
                                text-align: center;
                                margin-top: 20px;
                            }
                        }

                        @media (min-width: $msv-breakpoint-mbl-sm){
                            .ms-content-block__details {
                                padding-right: 10px;
                            }
                        }

                        &__cta {
                            margin-top: 20px;
                        }
                    }
                }
    
                &:not(:first-child) {
    
                    .ms-content-block {
                        @extend %shared-text;
                        .ms-content-block__title {
                            font-size: 30px;
                            line-height: 30px;
                            padding-bottom: 15px;
                        }
    
                        .ms-content-block__details {
                            padding-top: 20px;
                            text-align: center;
                        }
    
                        .ms-content-block__text {
                            margin-top: 0;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // display: -webkit-box;
                            // -webkit-line-clamp: 2;
                            // -webkit-box-orient: vertical;
                            // white-space: break-spaces;
                            // min-height: 90px;
                            // max-height: 90px;
                            // width: 100%;
                        }
                    }
    
                    // @media (max-width: $msv-breakpoint-l) {
                    //     flex: 0 0 50%;
                    //     max-width: 50%;
                    // }
    
                    @media (min-width: $msv-breakpoint-mbl-sm){
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    @media (max-width: $msv-breakpoint-mbl-sm){
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                
                &:first-child {
                    padding-bottom: 0;
    
                    .ms-content-block {
                        @media (max-width: $msv-breakpoint-l){
                            .ms-content-block__title {
                                font-size: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Advice filter styling
.ms-advice-blocks {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: center;
    .advice-blocks-container {
        width: 33.3%;
        padding: 0 10px;
        text-align: center;
        @media(max-width: $msv-breakpoint-m) {
            width: 50%;
        }
        @media(max-width: $msv-breakpoint-mbl-sm) {
            width: 100%;
        }
        img {
            width: 100%;
            height: auto;
        }
        h2 {
            color: var(--dobbies-heading-primary-color);
            font-weight: var(--dobbies-font-weight-normal);
            font-size: var(--dobbies-body-font-size-ml);
            margin: 10px 0;
            line-height: 26px;
        }
        .details-block {
            margin-bottom: 30px;
        }
        .link-url {
            font-weight: var(--dobbies-font-weight-bold);
            text-decoration: none;
            margin-top: 15px;
            margin-right: 10px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.filter-button-section {
    width: 100%;
    padding: 25px 0 10px;
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid var(--dobbies-green-border-color);
    border-bottom: 1px solid var(--dobbies-green-border-color);
    text-align: center;
    .msc-btn {
        min-height: 38px;
        max-height: 38px;
        line-height: initial;
        margin-right: 15px;
        margin-bottom: 15px;
        min-width: 90px;
        border: 1px solid var(--dobbies-breadcrumb-icon-color);
        font-size: 18px;
        font-weight: var(--dobbies-font-weight-normal);
        border-radius: 4px;
        background-color: transparent;
        color: var(--dobbies-font-primary-color);
        justify-content: center;
        align-items: center;
        display: inline-flex;
        cursor: pointer;
        padding: 0 15px;
        position: relative;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--dobbies-bg-color-secondary);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
            color: var(--dobbies-font-primary-color);
            background: var(--dobbies-bg-color);
            text-decoration: none;
            &:after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }
}
