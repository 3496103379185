.sustainability-wrapper {
  p {
    margin-bottom: 16px;
    font-weight: 400;
  }
  .ms-content-block__text {
    font-weight: 400;
  }
  .full-width-banner {
     .ms-content-block__details {
       text-align: center;
       padding: 20px 0;
    }
  }
  .our-pledge {
    h2 {
      font-size: 30px;
      color: var(--dobbies-font-primary-color);
      font-weight: var(--dobbies-font-weight-normal);
      line-height: 50px;
      margin-bottom: 10px;
      text-transform: uppercase;
      @media (max-width: $msv-breakpoint-m) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    
    .ms-content-block__details {
      max-width: 850px;
      margin: 0 auto;
    }
  }
  .terra-carta {
    background: $dobbies-homepage-grey;
    padding: 40px 0;
    margin-bottom: 60px;
    .time-fragment-col-spacing {
      max-width: 850px;
      margin: 0 auto;
      padding: 0 12px;
    }
    .full-width-banner {
      max-width: 850px;
      margin: 0 auto;
      padding: 0 12px;
    }
  }
  .peat-free {
    h2 {
      font-size: 30px;
      color: var(--dobbies-font-primary-color);
      font-weight: var(--dobbies-font-weight-normal);
      line-height: 50px;
      margin-bottom: 10px;
      text-transform: uppercase;
      @media (max-width: $msv-breakpoint-m) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .full-width-banner {
      max-width: 850px;
      margin: 0 auto;
      padding: 0 12px;
      @media (max-width: $msv-breakpoint-m) {
        padding: 0;
      }

    }
  }
  .product-tiles-wrap {
    .ms-content-block__details {
      text-align: center;
    }
    .ms-content-block__title {
      font-size: 22px;
      color: var(--dobbies-font-primary-color);
      font-weight: var(--dobbies-font-weight-normal);
      line-height: 32px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    p {
      font-size: 14px;
    }
  }
  .services-wrapper, .our-stores {
    .full-width-banner {
      max-width: 850px;
      margin: 0 auto;
      padding: 0 12px;
      @media (max-width: $msv-breakpoint-m) {
        padding: 0;
      }
    }
    .ms-content-block__title {
      @media (max-width: $msv-breakpoint-m) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;
      }
     }
  }
  .services-wrapper {
    .full-width-banner {
      padding: 0 12px;
    }
    .ms-content-block__details {
      max-width: 650px;
      margin: 0 auto;
    }
  }
  .charity-restaurant {
    .ms-content-block__details {
      text-align: center;
    }
    .ms-content-block__title {
      font-size: 24px;
      color: var(--dobbies-font-primary-color);
      font-weight: var(--dobbies-font-weight-normal);
      line-height: 36px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }
  .about-dobbies {
    background: $dobbies-homepage-grey;
    padding: 40px 0;
    @media (max-width: $msv-breakpoint-m) {
      padding-bottom: 0;
    }
    .full-width-banner {
      max-width: 850px;
      margin: 0 auto;
      padding: 0 12px;
    }
    h2 {
      font-size: 30px;
      color: var(--dobbies-font-primary-color);
      font-weight: var(--dobbies-font-weight-normal);
      line-height: 1;
      margin: 30px 0 40px;
      text-transform: uppercase;
      span {
        text-transform: none;
        display: block;
        @media (max-width: $msv-breakpoint-m) {
          display: inline-block;
        }
      }
      @media (max-width: $msv-breakpoint-m) {
        font-size: 24px;
        line-height: 32px;
        margin: 15px 0 25px;
      }
    }
    .ms-content-block__title {
      max-width: 600px;
      margin: 25px auto 50px;
      line-height: 1;
    }
    h3 {
      margin-bottom: 5px;
      font-weight: 500;
    }
  }
  .hashtag {
    padding: 40px 12px;
    max-width: 860px;
    h2 {
      border: 0;
      margin-bottom: 40px;
      @media (max-width: $msv-breakpoint-m) {
        margin-top: 0;
      }
    }
  }
  .full-width-banner .ms-content-block__image img {
    width: auto;
    margin: 0 auto;
    max-width: 100%;
  }
}