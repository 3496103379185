$msv-account-loyalty-points-modal-padding: 20px 20px;
$msv-account-loyalty-points-trigger-padding: 2px 20px;
$msv-account-loyalty-points-trigger-height: 25px;
$msv-modal-breakpoint-m: 450px;
$dobbies-club-background-color: #{$dobbies-club-grey};
$dobbies-club-plus-benefits-title-font-mobile: $dobbies-title-font-size-30;
$dobbies-club-plus-testimonial-fonts: $dobbies-title-font-size-30;
$dobbies-club-loyalty-link-icon-font-size-s: 12px;
$dobbies-club-loyalty-more-room-font-size-55:55px; 


:root {
    
  // heading
  --msv-account-loyalty-heading-font-color: var(--dobbies-font-primary-color);
  --msv-account-loyalty-heading-font-size: var(--msv-body-font-size-xl);

  // text
  --msv-account-loyalty-text-size: var(--msv-body-font-size-m);
  --msv-account-loyalty-text-color: var(--dobbies-font-primary-color);
  
  --msv-account-loyalty-border: #{msv-black};

  // link
  --msv-account-loyalty-link-color:  var(--dobbies-font-primary-color);

  // primary button
  --msv-account-loyalty-primary-btn-bg: var(--dobbies-accent-brand-color);
  --msv-account-loyalty-primary-btn-font-color: var(--dobbies-font-secondary-color);
  --msv-account-loyalty-primary-btn-border: var(--dobbies-accent-brand-color);

  --dobbies-club-background-color: #{$dobbies-club-background-color};

  --dobbies-club-plus-benefits-title-font-mobile: #{$dobbies-club-plus-benefits-title-font-mobile};
  --dobbies-club-plus-testimonial-fonts: #{$dobbies-club-plus-testimonial-fonts};

  --dobbies-club-loyalty-link-icon-font-size-s: #{$dobbies-club-loyalty-link-icon-font-size-s};
  --dobbies-club-loyalty-more-room-font-size-55: #{$dobbies-club-loyalty-more-room-font-size-55};
}
$dobbies-club-bg-path: '../../../';

.dobbies-club-free {
  main {
    background-color: var(--dobbies-club-background-color);
    background-image: url('#{$dobbies-club-bg-path}dobbies-club-free-bg.png');
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height: calc(100vh - 439px);
    padding-top: 140px;
    @media screen and (max-width: $dobbies-container-max-width-s) {
        background-size: 1000px auto;
    }
  }
}
.dobbies-club-plus {
    main {
        background-color: $dobbies-homepage-grey;
        // background-image: url('#{$dobbies-club-bg-path}dobbies-club-plus-bg.png');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 100% auto;
        min-height: calc(100vh - 439px);
        @media screen and (max-width: $dobbies-container-max-width-s) {
            background-size: 1000px auto;
        }

        .dobbies-club-container-m {
            margin: 0 auto;
            @media screen and (min-width: $dobbies-container-max-width-xl) {
                max-width: 716px;
            }

            .ms-content-block__details {
                text-align: center;
                @include ms-content-block-detail-reset();
                padding-bottom:16px; 
                .ms-content-block__text {
                    width: 100%;
                    max-width: 100%;

                    h1{
                        @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-xxl),$dobbies-line-height-xxl);
                        color: var(--dobbies-club-primary-color);
                        text-align: center;
                        padding: 115px 0 0;
                        width: 100%;
                        margin-bottom: 20px;
                        font-weight: 500;
                        @media screen and (min-width: $msv-container-max-width-s) and (max-width: $dobbies-container-max-width-l) {
                          @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-title-font-size-40),$dobbies-title-line-height-s);
                          padding: 60px 0 5px;
                        }
                        @media screen and (max-width: $dobbies-container-max-width-s) {
                          @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-tile-heading),$dobbies-title-line-height-s);
                          padding: 30px 0 5px;
                        }
                        span {
                            color: $dobbies-white;
                            display: block;
                            font-weight: 400;
                        }
                    }

                    p {
                        @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-ml),$dobbies-line-height-l);
                        color: var(--dobbies-font-secondary-color);
                        text-align: center;
                        @media screen and (max-width: $dobbies-container-max-width-s) {
                          @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-body-font-size-l),$dobbies-line-height-m);
                          font-size: 14px;
                        }

                        span {
                            color: var(--dobbies-club-primary-color);
                            margin: 0 5px;
                            @media (max-width: $msv-breakpoint-m) {
                                display: block;
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }


            .ms-content-block__cta {
                margin: 50px auto;

                &:hover{
                    cursor:pointer; 
                }

               .btn__clubmarketing{
                    width: 45px !important;
                    justify-content: center;
                    &:hover{
                        cursor:pointer; 
                    }
                  
                }

                .msc-cta__primary {
                    @include club-plus-primary-button();
                    @include button-verticle-padding-zero();
                    width: 100%;
                    height: 45px;
                    border-radius: $dobbies-btn-border-radius;
                    font-size: var(--dobbies-body-font-size-ml);
                    line-height: 40px;
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    overflow: hidden;
                    border: 0;
                    min-width: 150px;
                    color: $dobbies-footer-font-color;
                    font-weight: normal;
                    @media (max-width: $dobbies-container-max-width-l) {
                        max-width: 145px;
                        min-width: 145px;
                        height: 37px;
                        line-height: 34px;
                        font-size: var(--dobbies-body-font-size-l);
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $dobbies-black;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                        -webkit-transform-origin: 0 50%;
                        transform-origin: 0 50%;
                        -webkit-transition-property: transform;
                        transition-property: transform;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        -webkit-transition-timing-function: ease-out;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--dobbies-font-secondary-color);
                        background: var(--dobbies-bg-color-secondary);
                        &:before {
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                        }
                    }
                }
            }
        }

        .club-plus-benefits {
            margin: 0 0 30px;
            padding-top: 45px;
            @media (max-width: $dobbies-container-max-width-l) {
                margin-top: 0;
            }

            h2 {
                @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-heading-font-size-m),$dobbies-title-line-height-s);
                color: $dobbies-footer-font-color;
                text-align: center;
                text-transform: uppercase;

                @media screen and (max-width: $dobbies-container-max-width-l) {
                    @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-club-plus-benefits-title-font-mobile),$dobbies-title-line-height-s);
                }

                @media screen and (max-width: $dobbies-container-max-width-l) {
                    line-height: 38px;
                }
            }
        }

        .club-plus-benefits-boxes {
            .benefit-box{
                background: var(--dobbies-bg-color);
                padding:20px 20px;
                text-align: center;
                min-height: 208px;
                margin: 0 8px 16px;
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    margin:0 0 10px;
                }


                &__image {
                    height: 68px;
                    margin-bottom: 26px;
                    span {
                        font-size: 120px;
                        &:after {
                            position: relative;
                            top: -10px;
                        }
                        &.icon-cup {
                            &:after {
                                content: $dobbies-icon-cup;
                            }
                        }
                        &.icon-savings {
                            &:after {
                                content: $dobbies-icon-savings;
                            }
                        }
                        &.icon-vouchers {
                            &:after {
                                content: $dobbies-icon-vouchers;
                            }
                        }
                        &.icon-present {
                            &:after {
                                content: $dobbies-icon-present;
                            }
                        }
                        &.icon-booking {
                            &:after {
                                content: $dobbies-icon-booking;
                            }
                        }
                        &.icon-vip {
                            &:after {
                                content: $dobbies-icon-vip;
                            }
                        }
                    }
                }

                &__title {
                    @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-ml),$dobbies-line-height-l);
                    color: $dobbies-footer-font-color;
                    margin-bottom: 10px;
                }

                &__text {
                    @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-s),$dobbies-font-size-ml);
                    color: $dobbies-footer-font-color;
                }
            }
        }

        .dobbies-club-testimonial-carousel {
            // background: var(--dobbies-club-primary-color);
            padding-top: 70px;
            padding-bottom: 80px;

            @media screen and (max-width: $dobbies-container-max-width-l) {
                padding-top: 30px;
                padding-bottom: 60px;
            }
            .ms-carousel {
                max-width: 900px;
                @media (min-width: $msv-breakpoint-xl + 1) {
                    max-width: 1150px;
                }
            }

            .ms-content-block {
                min-height: 0;
            }
            .slide {
                background: var(--dobbies-club-primary-color);
                padding: 80px;
                color: $dobbies-footer-font-color;
                box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.15);
                @media (max-width: $dobbies-container-max-width-s) {
                    padding: 40px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    width: 80px;
                    height: 80px;
                    background-image: url('#{$dobbies-club-bg-path}quote.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    top: 24px;
                    left: 22px;
                    width: 80px;
                    transform: rotate(180deg);
                    @media (max-width: $dobbies-container-max-width-s) {
                        top: 0px;
                        left: 0px;
                        width: 60px;
                        height: 60px;
                    }
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 80px;
                    height: 80px;
                    background-image: url('#{$dobbies-club-bg-path}quote.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    bottom: 80px;
                    right: 22px;
                    width: 80px;
                    @media (max-width: $dobbies-container-max-width-s) {
                        bottom: 47px;
                        right: 0px;
                        width: 60px;
                        height: 60px;
                    }
                }
            }

            .ms-content-block__details {
                text-align: center;
                @include ms-content-block-detail-reset();

                h2.ms-content-block__title {
                    @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-club-plus-testimonial-fonts), $dobbies-line-height-xl);
                    // color: var(--dobbies-font-header-color);
                    // font-style: italic;
                    text-align: center;
                    width: 100%;
                    max-width: 100%;
                    @media screen and (min-width: $msv-container-max-width-s) and (max-width: $dobbies-container-max-width-l) {
                        @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-ml), $dobbies-line-height-l);
                    }
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-l), $dobbies-line-height-l);
                    }
                }

                .ms-content-block__text {
                    width: 100%;
                    margin: 30px auto 0;
                    @media screen and (max-width: $dobbies-container-max-width-l) {
                        margin-top: 17px;
                    }
                    p {
                      @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-body-font-size-ml), $dobbies-line-height-m);
                      // color: var(--dobbies-font-header-color);
                      text-align: center;
                      line-height: 24px;
                      @media screen and (max-width: $dobbies-container-max-width-l) {
                          font-size: var(--dobbies-body-font-size-l);
                      }
                    }
                }
            }

            .msc-carousel__indicators {
                position: absolute;
                bottom: -55px;
                @media screen and (max-width: $dobbies-container-max-width-l) {
                    bottom: -40px;
                }
                
                li {
                    border: 1px solid $dobbies-footer-font-color;
                    background: $dobbies-footer-font-color;
                    &:not(.active) {
                        background: none;
                    }
                }
            }
        }

        .dobbies-club-plus-today {
            background: $dobbies-white;
            text-align: center;
            .row {
                max-width: 860px;
                padding-top: 80px;
                padding-bottom: 20px;
                background-color: $dobbies-gray-100;
                background-image: url('#{$dobbies-club-bg-path}dobbies-club-plus-bg.png');
                background-repeat: no-repeat;
                background-position: center top;
                background-size: 100% auto;
                margin: 0 auto;
                @media screen and (max-width: $dobbies-container-max-width-l) {
                    padding-top: 30px;
                }
                @media (min-width: $msv-breakpoint-xl + 1) {
                    max-width: 1110px;
                }
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                max-width: 100%;
            }

            &__heading {
                h2 {
                    @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-tile-heading), $dobbies-font-size-xxxl);
                    color: $dobbies-white;
                    text-transform: uppercase;
                    span {
                        color: var(--dobbies-club-primary-color);
                    }
                }
                p {
                    @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-s), $dobbies-line-height-m);
                    color: var(--dobbies-font-secondary-color);
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        padding: 0 12px;
                    }
                }
            }
            &__howworks {
                margin-top: 20px;
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    padding: 12px;
                }
                .ms-content-block__details {
                    @include ms-content-block-detail-reset();
                    text-align: center;
                    color: $dobbies-white;
                    // background: var(--dobbies-bg-color);

                    h3.ms-content-block__title {
                        @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-ml),$dobbies-line-height-l);
                        color: var(--dobbies-club-primary-color);
                        width: 100%;
                        padding-top: 10px;
                        border-top: 1px solid $dobbies-white;
                        max-width: 420px;
                        margin: 0 auto;
                        text-transform: uppercase;
                    }

                    .ms-content-block__text {
                        width: 100%;
                        margin: 10px auto 0;
                        max-width: 420px;
                        text-align: left;
                        padding-bottom: 10px;
                        border-bottom: 1px solid $dobbies-white;
                        margin-bottom: 10px;
                        p {
                          @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-s), $dobbies-line-height-m);
                          color: $dobbies-white;

                          span {
                              font-weight: var(--dobbies-font-weight-normal);
                              color: var(--dobbies-club-primary-color);
                          }
                        }
                    }

                    .ms-content-block__cta {
                        margin: 5px auto 20px;
                        flex-wrap: wrap;
                        .msc-cta__primary, .msc-cta__secondary {
                            color: $dobbies-white;
                            width: 100%;
                            cursor: pointer;
                            background: none;
                            border: none;
                            font-size: 16px;
                            text-align: center;
                            justify-content: center;
                            padding: 0;
                            height: auto;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                            // @include club-plus-primary-button();
                            // @include button-verticle-padding-zero();
                            // width: 100%;
                            // height: 45px;
                            // border-radius: $dobbies-btn-border-radius;
                            // font-size: var(--dobbies-body-font-size-ml);
                            // line-height: 40px;
                            // -webkit-transform: perspective(1px) translateZ(0);
                            // transform: perspective(1px) translateZ(0);
                            // position: relative;
                            // -webkit-transition-duration: 0.3s;
                            // transition-duration: 0.3s;
                            // overflow: hidden;
                            // border: 0;
                            // @media (min-width: $dobbies-container-max-width-l) {
                            //     max-width: 145px;
                            //     min-width: 145px;
                            //     height: 37px;
                            //     font-size: var(--dobbies-body-font-size-l);
                            //     line-height: 35px;
                            // }
                            // &:before {
                            //     content: "";
                            //     position: absolute;
                            //     z-index: -1;
                            //     top: 0;
                            //     left: 0;
                            //     right: 0;
                            //     bottom: 0;
                            //     background: var(--dobbies-bg-color-primary);
                            //     -webkit-transform: scaleX(0);
                            //     transform: scaleX(0);
                            //     -webkit-transform-origin: 0 50%;
                            //     transform-origin: 0 50%;
                            //     -webkit-transition-property: transform;
                            //     transition-property: transform;
                            //     -webkit-transition-duration: 0.3s;
                            //     transition-duration: 0.3s;
                            //     -webkit-transition-timing-function: ease-out;
                            //     transition-timing-function: ease-out;
                            // }
                            // &:hover,
                            // &:focus,
                            // &:active {
                            //     color: var(--dobbies-font-secondary-color);
                            //     background: var(--dobbies-bg-color-secondary);
                            //     &:before {
                            //     -webkit-transform: scaleX(1);
                            //     transform: scaleX(1);
                            //     }
                            // }
                        }
                    }

                }
            }
        }

        .dobbies-club-loyalty-links {
            padding-top: 15px;
            padding-bottom: 30px;
            max-width: 960px;

            @media screen and (max-width: $dobbies-container-max-width-s) {
                padding-top: 0;
            }

            .row {
                justify-content: flex-start;

                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    justify-content: space-between;
                }
                
                @media screen and 
                (min-width: $msv-container-max-width-s) and 
                (max-width: $dobbies-container-max-width-xl) {
                    max-width: 716px;
                    margin: 0 auto;
                }
                .col-12 {
                    flex: 0 0 auto;
                    width: auto;
                    margin-top: 10px;

                    @media screen and 
                    (min-width: $msv-container-max-width-s) and 
                    (max-width: $dobbies-container-max-width-xl) {
                        flex: 0 0 50%;
                        width: 50%;
                        padding: 0 20px;
                    }

                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        flex: 0 0 100%;
                        width: 100%;
                        margin-top: 10px;
                    }

                    .ms-footer__link {
                        @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-body-font-size-s), $dobbies-line-height-m);
                        // color: var(--dobbies-font-secondary-color);
                        position: relative;

                        &:after{
                            top: 5px;
                            right: -20px;
                            position: absolute;
                            @include dobbies-icon();
                            color: var(--dobbies-club-primary-color);
                            font-size: var(--dobbies-club-loyalty-link-icon-font-size-s);
                            font-weight: var(--dobbies-font-weight-bold);
                            content: $dobbies-icon-arrow;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
        .club-marketing-header {
            .dobbies-club-container-m {
                max-width: 1068px;
                background-image: url('#{$dobbies-club-bg-path}dobbies-club-plus-bg.png');
                background-repeat: no-repeat;
                background-position: center top;
                background-size: 100% auto;
                background-color: $dobbies-club-grey;
                @media (min-width: $msv-breakpoint-xl + 1) {
                    max-width: 1476px;
                }
            }
        }
        .dobbies-marketing {
            padding: 50px 0 0;
            text-align: center;
            .heading {
                width: 100%;
                @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-heading-font-size-m),$dobbies-title-line-height-s);
                color: $dobbies-footer-font-color;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 30px;
                @media screen and (max-width: $dobbies-container-max-width-l) {
                    @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-club-plus-benefits-title-font-mobile),$dobbies-title-line-height-s);
                    text-transform: uppercase;
                }

                @media screen and (max-width: $dobbies-container-max-width-l) {
                    line-height: 38px;
                }
            }
            .row {
                margin-left: -8px;
                margin-right: -8px;
                @media (max-width: $msv-breakpoint-m) {
                    margin: 0;
                }
            }
            .col-12 {
                flex: 0 0 33.33%;
                max-width: 33.33%;
                @media (max-width: $dobbies-container-max-width-m) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                @media (max-width: $dobbies-container-max-width-s) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            .marketing-block {
                margin: 0 8px 16px;
                background: $dobbies-white;
                box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.16);
                height: calc(100% - 16px);
                img {
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                }
                .ms-content-block__details {
                    padding: 30px 10px;
                }
                .ms-content-block__title {
                    @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-ml),$dobbies-line-height-l);
                    color: $dobbies-footer-font-color;
                    margin-bottom: 10px;
                    text-transform: uppercase;

                }
                .ms-content-block__text {
                    @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-s),$dobbies-font-size-ml);
                    color: $dobbies-footer-font-color;
                }
            }
        }
    }
}
.ms-account-loyalty {
    max-width: 640px;
    margin-bottom: 40px;

    .back-to-shopping {
        width: auto;
    }

    &__home-link {
        @include primary-button(var(--msv-account-loyalty-primary-btn-bg), var(--msv-account-loyalty-primary-btn-font-color), var(--msv-account-loyalty-primary-btn-border));
    }

    &__reward-points {
        @include font-content(var(--msv-font-weight-normal),var(--msv-account-loyalty-text-size), $dobbies-line-height-m);
        color: var(--msv-account-loyalty-text-color);
    }

    &__heading {
        @include font-content(var(--msv-font-weight-normal),var(--msv-account-loyalty-heading-font-size), $dobbies-line-height-xl);
        color: var(--msv-account-loyalty-heading-font-color);
        margin-bottom: 28px;
    }

    &__info-label {
        @include font-content(var(--msv-font-weight-bold),var(--msv-account-loyalty-text-size), $dobbies-line-height-m);
        color: var(--msv-account-loyalty-text-color);
        margin-bottom: 12px;
    }

    &__card-number {
        @include font-content(var(--msv-font-weight-normal),var(--msv-account-loyalty-heading-font-size), $dobbies-line-height-xl);
        color: var(--msv-account-loyalty-text-color);
        margin-bottom: 8px;
    }

    &__join-date {
        @include font-content(var(--msv-font-weight-light),var(--msv-account-loyalty-text-size), $dobbies-line-height-m);
        color: var(--msv-account-loyalty-text-color);
        margin-bottom: 40px;
    }

    &__points {
        margin-bottom: 40px;
    }

    &__total-points-label {
        @include font-content-l();
        color: var(--msv-account-loyalty-text-color);
        margin-bottom: 12px;
    }

    &__total-points {
        @include font-content-xxl();
        margin-bottom: 25px;
        border-bottom: 1px dashed var(--msv-account-loyalty-border);
        background: transparent !important;

        margin: 0 15px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding-top: 5px;
        margin-top: 5px;
    }
    &__total-point {
        @include font-content-xxl();
        margin-bottom: 25px;
        border-bottom: 1px dashed var(--msv-account-loyalty-border);
        background: transparent !important;
        border-top: 1px solid $dobbies-grey;
        margin: 0 15px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding-top: 5px;
        margin-top: 5px;
    }

    &__point-breakdown {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    &__points-title {
        font-weight: var(--msv-font-weight-bold);
    }

    &__available-points-label,
    &__expiring-points-label {
        font-weight: var(--msv-font-weight-light);
        margin-bottom: 0;
    }

    &__points-trigger {
        @include button-link(var(--msv-account-loyalty-link-color));
        @include font-content(var(--msv-font-weight-bold),var(--msv-account-loyalty-text-size), $dobbies-line-height-m);
        @include vfi();
        text-decoration: underline;
        padding: $msv-account-loyalty-points-trigger-padding;
        height: $msv-account-loyalty-points-trigger-height;
    }

    &__points-modal-body-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px dashed var(--msv-account-loyalty-border);
    }

    &__points-modal-body-header {
        display: flex;
        justify-content: space-between;
        padding: 0 8px;
        margin-bottom: 4px;
    }

    &__points-modal-activity {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-between;
        padding: 8px;
        height: 65px;

        &:nth-child(odd) {
            background: var(--dobbies-bg-color);
        }
    }

    &__points-modal-type {
        margin-bottom: 4px;
    }

    &__points-modal-name {
        @include font-content-l();
    }

    &__points-modal-total {
        @include font-content-xl(var(--msv-font-weight-bold));
    }

    &__points-modal-points {
        @include font-content-l(var(--msv-font-weight-bold));
    }

    &__points-modal {
        @media screen and (max-width: $msv-modal-breakpoint-m) {
            .msc-modal__content{
                padding: $msv-account-loyalty-points-modal-padding;
            }
        }
    }
}
.loyalty-points-dropdown{
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
}

.loyalty-points-Club-dropdown{
    text-align: left;
    padding: 5px 0;

    > div {
        color: $dobbies-grey;
        font-size: $dobbies-font-size-s;
        font-weight: $msv-font-weight-400;
        margin-right: 2px;
    }
}
.clubPlus-points-drinks {
    font-size: 14px;
    line-height: 16px;
    color: #111111;
    text-align: left;
    span {
        font-weight: $msv-font-weight-500;
    }
    }
.club-points-drinks {
    font-size: 14px;
    line-height: 20px;
    color: #111111;
        }
.points-drink-text {
    font-size: 14px;
    line-height: 16px;
    color: white
    }
.loyalty-coffee-dropdown{
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
}


.membership-detail-boxes{
    display: flex;
    flex-wrap: wrap;

    &_perbox {
        width: calc((100% / 3) - 20px);
        margin: 0 10px;
        background: var(--dobbies-bg-color);
        padding:15px 20px;
        text-align: center;
        min-height: 190px;
        margin: 0 10px 27px;
    &.update-your-info {
        @media (min-width:768px) {
            position: relative;
        }
    .link-holder {
        @media (min-width:768px) {
            position: absolute;
            bottom: 24px;
            left: 0;
            margin: 0;
        }
        @media (min-width:892px) {
            bottom: 20px;
        }
        @media (min-width:1007px) {
            bottom: 23px;
        }
        @media (min-width:1477px) {
            bottom: 44px
        }
      }
    }

        @media screen and (max-width: $dobbies-container-max-width-s) {
            width: calc(100% - 20px);
            margin: 0 10px 20px;
            min-height: auto;
        }

        .icon-holder {
            height: 67px;

            span {
                &.icon-vouchers {
                    font-size: 130px;
                    color: $dobbies-club-grey;
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        font-size: 160px;
                    }
                    &:after{
                        content:$dobbies-icon-vouchers;
                        @media screen and (max-width: $dobbies-container-max-width-s) {
                            position: relative;
                            top: -30px;
                        }
                    }
                }

                &.icon-member {
                    font-size: 110px;
                    color: $dobbies-club-grey;
                    &:after{
                        content:$dobbies-icon-member;
                        position: relative;
                        top: -25px;
                    }
                }
                &.icon-cup {
                    font-size: 110px;
                    color: $dobbies-club-grey;
                    &:after{
                        content:$dobbies-icon-cup;
                        position: relative;
                        top: -25px;
                    }
                }
            }
        }

        h4 {
            @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-body-font-size-ml),$dobbies-line-height-l);
            color: var(--dobbies-font-header-color);
            margin-bottom: 10px;
        }

        p {
            @include font-content(var(--dobbies-font-weight-normal),var(--dobbies-body-font-size-s),$dobbies-font-size-ml);
            color: var(--dobbies-font-header-color);
            margin-bottom: 0;
        }

        .link-holder {
            margin-top: 20px;
    
            .cta__btn {
                height: 25px;
                border-radius: $dobbies-btn-border-radius;
                font-size: var(--dobbies-body-font-size-s);
                line-height: 24px;
                justify-content: center;
                &:not(:first-child) {
                    margin-top: 5px;
                }
            }
            .cancellation_text{
                margin-top: 8px;
            }
        }

        &.user-points {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &>p{
                padding: 20px 0;
                margin-bottom: 0;
            }

            .points{
                padding: 0;
                p.ms-account-loyalty__total-points {
                    @include font-content(var(--dobbies-font-weight-bold),$dobbies-title-line-height-s);
                    font-size: 45px;
                }
            }
        }

        &.user-voucher {
            .icon-holder {
                margin-bottom: 65px;
            }
        }
        &.drinks-permonth {
            .icon-holder {
                margin-bottom: 65px;
            }
        }
        &.card-info{
            .ms-account-loyalty__card-number {
                display: inline-block;
            }
            @include breakpoint(md){
                padding: 20px 18px;
            }
            @include breakpoint(lg){
                padding: 20px 12px;
            }
        }
        &.update-your-info {
            .content-section {
                min-height: 26px;
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    min-height: 41px;
                }
                @media screen and (max-width: $dobbies-container-max-width-m) {
                    min-height: 50px;
                }
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    min-height: auto;
                }
            }
        }
        p.tbc-red {
            @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-body-font-size-xs),$dobbies-line-height-xxs);
            color:var(--dobbies-club-font-pure-red);
            text-transform: uppercase;
            margin-top: 13px;
        }

        &.more-room {
            background: $dobbies-club-more-room;
            h4 {
                @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-body-font-size-ml),$dobbies-line-height-l);
                color: var(--dobbies-font-secondary-color);
                text-transform: uppercase;
                margin-bottom: 0;
                @media screen and (max-width: $dobbies-container-max-width-l) {
                    font-size: var(--dobbies-body-font-size-l);
                }
            }

            h2 {
                @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-club-loyalty-more-room-font-size-55),$dobbies-line-height-60);
                color: var(--dobbies-club-primary-color);
                text-transform: uppercase;
            }
            p {
                color: var(--dobbies-font-secondary-color);
                @media screen and (max-width: $dobbies-container-max-width-l) {
                    font-size: var(--dobbies-body-font-size-xs);
                }
                &.tbc-red {
                    color:var(--dobbies-club-font-pure-red);
                }
            }
            .link-holder {
                margin-top: 30px;
                @media screen and (min-width: $dobbies-container-max-width-xl) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.ms-loyalty-premium {
    margin-bottom: 34px;

    @media screen and (min-width: $dobbies-container-max-width-xl) {
        margin-bottom: 90px;
    }

    background-color: #2b2e35;
    background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/ctdwmwnctb/imageFileData/ME1nQG?pubver=0);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    @media screen and (max-width: $dobbies-container-max-width-s) {
        background-size: 1000px auto;
    }

    .ms-account-loyalty__heading {
        @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-tile-heading),$dobbies-title-line-height-s);
        color: white;
        text-align: center;
        padding: 60px 0;
        width: 100%;
        margin-bottom: 0;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            @include font-content(var(--dobbies-font-weight-bold),var($dobbies-club-plus-benefits-title-font-mobile),$dobbies-title-line-height-s);
            padding: 60px 0 25px;
        }
    }

    .link-holder {
        .cta__btn {
            @include club-plus-primary-button();
            @include button-verticle-padding-zero();
            max-width: 100%;
            width: 181px;
            min-width: 0;
            font-weight: var(--dobbies-font-weight-bold);
            height: 25px;
            border-radius: $dobbies-btn-border-radius;
            font-size: var(--dobbies-body-font-size-s);
            line-height: 24px;
            justify-content: center;
            background: $dobbies-club-premium-background;
            background: $dobbies-club-premium-background-gradient;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            border: 0;
            color: #000000;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 302px;
                display: block;
                margin: auto;
            }
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: black;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
              }
              &:hover,
              &:focus,
              &:active {
                color: white;
                background: black;
                &:after {
                  -webkit-transform: scaleX(1);
                  transform: scaleX(1);
                }
            }
        }
    }

    .user-points {
        .points .ms-account-loyalty__total-points {
            color: #000000;
        }
    }

    .icon-holder {
        height: 67px;

        span {
            &.icon-cup {
                font-size: 110px;
                color: #000000;
                &:after{
                    content:$dobbies-icon-cup;
                    position: relative;
                    top: 5px;
                }
            }
            &.icon-vouchers {
                color: #000000;
            }
        }
    }

    .membership-detail-boxes_perbox {
        &.user-voucher {
            p {
                color: #000000;
            }
        }
    }

    .membership-detail-boxes_perbox {
        &.drinks-permonth {
            p {
                color: #000000;
            }
        }
    } 

    .membership-detail-boxes_perbox {
        &.lost-your-card {
            h4 {
                color: #000000;
            }
            p {
                color: #000000;
            }
        }
    }

    .membership-detail-boxes_perbox {
        &.card-info {
            p {
                color: #000000;
            }
        }
    } 

    .membership-detail-boxes_perbox {
        &.update-your-info {
            h4 {
                color: #000000;
            }
        }
    }

    .membership-detail-boxes_perbox {
        &.user-points {
            p {
                color: #000000;
            }
        }
    } 
    
}

.ms-loyalty-free {
    margin-bottom: 34px;

    @media screen and (min-width: $dobbies-container-max-width-xl) {
        margin-bottom: 90px;
    }

    background-color: var(--dobbies-club-background-color);
    background-image: url('#{$dobbies-club-bg-path}dobbies-club-free-bg.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;

    .ms-account-loyalty__heading {
        @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-tile-heading),$dobbies-title-line-height-s);
        color: var(--dobbies-club-font-light-green);
        text-align: center;
        padding: 60px 0;
        width: 100%;
        margin-bottom: 0;

        @media screen and (max-width: $dobbies-container-max-width-s) {
            @include font-content(var(--dobbies-font-weight-bold),var($dobbies-club-plus-benefits-title-font-mobile),$dobbies-title-line-height-s);
            padding: 60px 0 25px;
        }
    }

    .link-holder {
        .cta__btn {
            @include club-plus-primary-button();
            @include button-verticle-padding-zero();
            max-width: 100%;
            width: 181px;
            min-width: 0;
            font-weight: var(--dobbies-font-weight-bold);
            height: 25px;
            border-radius: $dobbies-btn-border-radius;
            font-size: var(--dobbies-body-font-size-s);
            line-height: 24px;
            justify-content: center;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            border: 0;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 302px;
                display: block;
                margin: auto;
            }
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-primary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
              }
              &:hover,
              &:focus,
              &:active {
                color: var(--dobbies-font-secondary-color);
                background: var(--dobbies-bg-color-secondary);
                &:after {
                  -webkit-transform: scaleX(1);
                  transform: scaleX(1);
                }
            }
        }
    }

    .user-points {
        .points .ms-account-loyalty__total-points {
            color: #facc00;
        }
    }
}

.ms-loyalty-plus {
    margin-bottom: 34px;

    @media screen and (min-width: $dobbies-container-max-width-xl) {
        margin-bottom: 90px;
    }

    background-color: var(--dobbies-club-background-color);
    background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/rsbnfrmlpw/imageFileData/ME52gx?pubver=0);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    @media screen and (max-width: $dobbies-container-max-width-s) {
        background-size: 1000px auto;
    }

    .ms-account-loyalty__heading {
        @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-tile-heading),$dobbies-title-line-height-s);
        color: var(--dobbies-club-primary-color);
        text-align: center;
        padding: 60px 0;
        width: 100%;
        margin-bottom: 0;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            @include font-content(var(--dobbies-font-weight-bold),var($dobbies-club-plus-benefits-title-font-mobile),$dobbies-title-line-height-s);
            padding: 60px 0 25px;
        }
    }

    .link-holder {
        .cta__btn {
            @include club-plus-primary-button();
            @include button-verticle-padding-zero();
            max-width: 100%;
            width: 181px;
            min-width: 0;
            font-weight: var(--dobbies-font-weight-bold);
            height: 25px;
            border-radius: $dobbies-btn-border-radius;
            font-size: var(--dobbies-body-font-size-s);
            line-height: 24px;
            justify-content: center;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            border: 0;
            white-space: nowrap;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 302px;
                display: block;
                margin: auto;
            }
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-primary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
              }
              &:hover,
              &:focus,
              &:active {
                color: var(--dobbies-font-secondary-color);
                background: var(--dobbies-bg-color-secondary);
                &:after {
                  -webkit-transform: scaleX(1);
                  transform: scaleX(1);
                }
            }
        }
    }

    .user-points {
        .points .ms-account-loyalty__total-points {
            color: #facc00
        }
    }

    .icon-holder {
        height: 67px;

        span {
            &.icon-cup {
                font-size: 110px;
                &:after{
                    content:$dobbies-icon-cup;
                    position: relative;
                    top: 5px;
                }
            }
        }
    }

}
.ms-account-welcome-tile__heading {
    font-size: var(--dobbies-body-font-size-s);
    font-weight: var(--dobbies-font-weight-bold);
    // border-bottom: 1px solid #B7B7B7;
    padding-bottom: 15px;
    line-height: normal;

    @media screen and (max-width: $msv-breakpoint-l) {
        padding-bottom: 10px;
    }
}

.lost-your-card {
	display: flex;
	flex-direction: column;
	.link-holder {
		margin-top: 10px;
        @media screen and (max-width: $msv-breakpoint-l) {
            margin-top: 34px;
        }
        @media (min-width: 768px) and (max-width: 892px) {
            margin-top: 10px;
        }
		.cta__btn {
			//margin-top: 20px;
            &:disabled {
                background: $dobbies-gray-300;
                &:after {
                    background: $dobbies-gray-300;
                    transform: scaleX(0);
                }
            }
		}
	}
}

.ms-dobbies-lost-card {
	h4 {
		@include font-content(var(--dobbies-font-weight-bold),var(--dobbies-body-font-size-ml),$dobbies-line-height-l);
		color: var(--dobbies-font-header-color);
		margin-bottom: 10px;
	}
	.lost-your-card-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background: #fff;
		color: var(--dobbies-club-background-color);
		padding: 25px;
		> * {
			margin: 0 10px;
		}
		.lost-your-card-details {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			max-width: 50%;
			> * {
				margin: 10px 0;
			}
		}
		.lost-your-card-links {
			display: flex;
			flex-direction: row;
			width: 100%;
			.cta__btn, .go-back {
				width: 50%;
				height: fit-content;
				padding: 5px 20px;
				margin: 10px 0;
			}
			.go-back {
				text-decoration: underline;
				cursor: pointer;
				width: auto;
				margin:auto;
				color: var(--dobbies-font-header-color);
			}
		}
		.membership-card {
			max-width: 330px;
		}

		@media (max-width: $msv-breakpoint-m) {
			* {
				display: flex;
				justify-content: center;
				align-self: center;
				text-align: center;
			}
			.membership-card {
				display: none;
			}
			.lost-your-card-details {
				max-width: 100%;
			}
			.lost-your-card-links {
				flex-direction: column;
			}
			padding: 25px;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
}