
.ms-dobbies-maintenance {
    position: absolute;
    top: 0;
    left: 0;
	height: 100vh;
    width: 100vw;
	display: flex;
	justify-content: center;
    align-items: center;
    color: white;
    line-height: 1.5;
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    &__logo {
        margin: 0.7rem 0 1.5rem 0;
    }
    &__overlay {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        text-align: center;
        background: rgba(17, 17, 17, 0.8);
        height: fit-content;
        width: fit-content;
        border-radius: 0.5rem;
        max-width: 85%;
        h1 {
            font-size: 2rem;
        	margin: 0 0 0.7em;
            line-height: 1.2;
        }
        p {
            font-size: 1.2em;
            margin: 0.5rem 0;
        }
        @media screen and (max-width: 768px) {
           padding: 1rem;
        }
    }
}
