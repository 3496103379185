$msv-details-heading-margin-bottom: 20px;
$msv-details-heading-margin-top: 0;
$msv-details-text-margin-right: 5px;
$msv-details-container-max-width: 794px;
$msv-details-order-information-half-width: 50%;
$msv-details-order-information-padding-bottom: 20px;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-details-group-border: 1px solid $dobbies-black;
$msv-details-group-padding: 20px 0px;
$msv-details-group-pickup-border: 1px solid $dobbies-gray-300;
$msv-details-group-delivery-items-text-color: $dobbies-gray-500;
$msv-details-group-delivery-padding-top: 20px;
$msv-details-group-delivery-width: 340px;
$msv-details-sale-lines-padding: 12px 0 0 0;
$msv-details-sale-line-width: 81px;
$msv-details-sale-line-price-left: 210px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-margin: 0 0 20px 0;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-order-empty-image-width: 103px;
$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-spacing: 10px;
$msv-details-order-summary-heading-margin-bottom: 12px;
$msv-details-order-summary-line-spacing: 8px;
$msv-details-button-margin-top: 12px;
$msv-details-help-margin-top: 20px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-payment-methods-margin: 20px 20px 20px 0;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 12px;
$msv-details-payment-methods-line-space: 8px;
$msv-details-payment-method-padding-m: 20px 0 10px 0;
$msv-details-default-padding: 20px 0;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;
$msv-details-shipping-items-label-padding-top: 30px;
$msv-details-shipping-items-label-padding-top-mobile: 20px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-details-footer-block-width: 337px;
$msv-details-order-summary-spacing: 20px;
$msv-details-address-spacing: 20px;
$msv-main-container-margin:0 auto;
$dobbies-font-weight-600: 600;
$dobbies-font-weight-355: 355;
$dobbies-order-confirmation-brief-info-separator: var(--dobbies-font-primary-color);
$dobbies-order-confirmation-cart-line-border-bottom-thickness: 1px;
$dobbies-order-confirmation-line-border-bottom-style: solid;
$dobbies-order-confirmation-sale-line-image-size: 147px;
$dobbies-order-confirmation-sale-line-image-size-mobile: 93px;
$dobbies-order-confirmation-cart-line-padding-top: 12px;
$dobbies-order-confirmation-full-width: 100%;
$dobbies-order-confirmation-summary-padding: 20px;
$dobbies-order-confirmation-summary-padding-mobile: 18px;
$dobbies-order-confirmation-summary-title-font-size: var(--dobbies-body-font-size-ml);
$dobbies-order-confirmation-summary-margin-bottom: 20px;
$dobbies-order-confirmation-summary-line-spacing: 15px;
$dobbies-order-confirmation-help-heading-margin-top:20px;
$dobbies-order-confirmation-help-heading-margin-bottom:8px;


.ms-order-confirmation {
    width: 100%;
    margin: auto;
    //max-width: $msv-details-container-max-width;
    position: relative;
    @media screen and (min-width: $msv-breakpoint-m) {
        padding-bottom: 30px;
    }
    &__heading {
        margin-bottom: $msv-details-heading-margin-bottom;
        margin-top: $msv-details-heading-margin-top;
        align-items: center;
        padding: 19px 10px;
        color: $dobbies-black;
        background-color: $dobbies-header-grey;
        @include font-content(var(--dobbies-font-weight-bold),var(--dobbies-body-font-size-m),20px);
        display: flex;
        text-transform: uppercase;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            margin-top: 0;
        }
    }

    &__order-information {
        //padding-bottom: $msv-details-order-information-padding-bottom;
        padding-bottom: 0;
        border: 0;
        padding-right: 15px;
        @media screen and (max-width: $dobbies-container-max-width-s) {
            padding-right: 0;
        }

        &-channel-reference-id,
        &-sales-id,
        &-receipt-id,
        &-receipt-email {
            @include font-content-l(var(--dobbies-font-weight-bold));
            display: block;
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;

            span {
                font-weight: var(--dobbies-font-weight-normal);
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                font-size: var(--dobbies-body-font-size-m);
            }
        }
        .__order-brief-info {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-l), $dobbies-font-size-l);
            @media screen and (max-width: $dobbies-container-max-width-s) {
                display: block;
                font-size: var(--dobbies-body-font-size-m);
            }
            .__created-date {
                margin-right: 3px;
            }
        }
        &-created-date {
            //@include font-content-m();
            // position: absolute;
            // top: 8px;
            // right: 0;
            padding-right: 10px;
            margin-right: 10px;
            margin-bottom: 15px;
            border-right: 1px solid $dobbies-order-confirmation-brief-info-separator;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                padding-right: 0;
                border-right: 0;
            }
        }
        .__sales-order-count {
            padding: 0 10px;
            margin: 0 10px;
            border-left: 1px solid $dobbies-order-confirmation-brief-info-separator;
            border-right: 1px solid $dobbies-order-confirmation-brief-info-separator;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                padding: 0;
                border-right: 0;
                border-left: 0;
                margin: 10px 0;
            }
            .label {
                margin-right: 4px;
            }
        }
        .__sales-order-total {
            .label {
               font-weight: var(--dobbies-font-weight-bold); 
               display: inline-block;
               margin-right: 4px;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            &-created-date {
                position: relative;
                top: 0;
                display: block;
                margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
            }
        }

        &-count {
            border-right: 1px solid $dobbies-black;
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }
    }

    &__btn-keep-shopping {
        margin-bottom: $msv-details-keep-shopping-btn-margin-bottom;
        @include secondary-button();
    }

    &__sales-lines {
        display: flex;
        flex-wrap: wrap;
        padding: 0;

        &-shipping-items-label, &-picking-items-label {
            display: block;
            font-weight: $dobbies-font-weight-600;
        }

        &:first-child {
            border-top: none;
        }
    }

    &__groups {
        @include font-content-m();
        border-bottom: 1px solid var(--dobbies-bombay-border-color);
        //border-top: 1px solid $dobbies-gray-900;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        margin-right: 15px;
    }

    &__group {

        &-deliveryType-pickUp, &-deliveryType-ship {
            flex: 0 0 $dobbies-order-confirmation-full-width;
            display: flex;
            flex-direction: column;
        }

        &-delivery {
            padding-right: $msv-details-sale-line-spacing;
            //padding-top: $msv-details-group-delivery-padding-top;
            font-size: var(--dobbies-body-font-size-l);
            @media screen and (max-width: $dobbies-container-max-width-s) {
                font-size: var(--dobbies-body-font-size-m);
            }

            &-heading {
                @include font-content-l(var(--msv-font-weight-bold));
                margin-right: $msv-details-text-margin-right;
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    font-size: var(--dobbies-body-font-size-m);
                }
            }

            &-total-items {
                @include font-content-xl();
                color: $msv-details-group-delivery-items-text-color;
                margin-right: $msv-details-text-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }
    }

    &__tracking-link {
        text-decoration: underline;
    }

    &__sales-line,
    &__sales-lines {
        // margin: $msv-details-sale-line-margin;
        width: 100%;

        .sales-line-section {
            width: $dobbies-order-confirmation-full-width;
            flex: 0 0 $dobbies-order-confirmation-full-width;
            position: relative;

            .msc-cart-line-item-subtotal-amount {
                right: 0;
                top: 16px;
                .msc-price .msc-price__actual {
                    font-size: var(--dobbies-body-font-size-ml);
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        font-size: var(--dobbies-body-font-size-m);
                    }
                }
            }
        }

        .msc-cart-line {
            display: flex;
            //width: $msv-details-sale-line-width;
            width: 100%;
            flex: 0 0 100%;

            &__product-image  {
                margin: 0 20px 10px 0;
            }

            &__product-title {
                @include font-content(var(--msv-font-weight-normal), var(--dobbies-body-font-size-l), $dobbies-line-height-l);
                color: var(--dobbies-heading-primary-color);
            }

            &__quantity,
            &__product-quantity {
                .quantity {
                    display: inline-block;
                }
                .quantity-input {
                    font-size: var(--dobbies-body-font-size-s);
                    font-weight: var(--dobbies-font-weight-normal);
                }
                .quantity-value {
                    color: $dobbies-gray-500;
                    display: inline;
                    margin-left: $msv-details-text-margin-right;
                }
            }

            &__product-price, &__product-savings {
                display: none;
            }

            &-item-product-discount {
                display: none;
                color: var(--dobbies-success-color);
            }
        }

        &-btn-buy-it-again {
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            width: $msv-details-sale-line-buy-again-btn-width;
            @include secondary-button();
        }
    }

    .delivery-dispatched-details {
        flex-wrap: wrap;
        align-items: center;
        padding: 20px;
        background: var(--dobbies-gray-cart-banner);
        margin-bottom: 20px;

        &__count {
            width: 200px;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }
        &__right {
            width: calc(100% - 200px);
            @media screen and (max-width: $dobbies-container-max-width-s) {
                width: 100%;
            }
        }

        &__count,
        .click-collect-label,
        .order-index {
            @include font-content(var(--dobbies-font-weight-heavy), var(--dobbies-body-font-size-m), $dobbies-line-height-s);
            color: var(--dobbies-accent-brand-color);
            @media screen and (max-width: $dobbies-container-max-width-s) {
                @include font-content(var(--dobbies-font-weight-heavy), var(--dobbies-body-font-size-s), $dobbies-line-height-xs);
            }
        }
        .order-index {
            margin-left: 5px;
        }
        &__right {
            text-align: right;
            @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-m), $dobbies-line-height-s);
            color: var(--dobbies-font-primary-color);
            @media screen and (max-width: $dobbies-container-max-width-s) {
                text-align: left;
                margin-top: 10px;
                @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-s), $dobbies-line-height-xs);
            }
            &>div {
                &+div {
                    margin-top: 5px;
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .sales-line-section {
        margin-bottom: 10px;
        padding-bottom: 20px;
        position: relative;
        border-bottom: solid 1px var(--dobbies-gray-border-color);
        &:last-child {
            border-bottom: 0;
        }
        .msc-cart-line {
            &__product {
                &-title {
                    font-size: var(--dobbies-body-font-size-ml);
                }
            }
            &-item-product-discount {
                font-size: var(--dobbies-body-font-size-l);
                .msc-price {
                    &__actual {
                        font-size: var(--dobbies-body-font-size-l);
                    }
                }
            }
            &__quantity {
                font-size: var(--dobbies-body-font-size-l);
            }
            &-item-subtotal-amount {
                .msc-price {
                    .msc-price {
                        &__actual,
                        &__strikethrough {
                            font-size: var(--dobbies-body-font-size-ml);
                        }
                    }
                }
            }
        }
    }

    &__order-summary {
        width: 100%;
        @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-l), $dobbies-line-height-l);
        background: $dobbies-header-grey;
        padding: $dobbies-order-confirmation-summary-padding;
        padding-bottom: 0;
        border: 1px solid $dobbies-gray-100;
        border-bottom: 0;

        @media screen and (max-width: $dobbies-container-max-width-s) {
            font-size: var(--dobbies-body-font-size-m);
        }

        &:after {
            clear: both;
        }

        &-heading {
            // display: none;
            @include font-content(var(--dobbies-font-weight-bold),$dobbies-order-confirmation-summary-title-font-size, $dobbies-line-height-l);
            color: var(--dobbies-font-primary-color);
            display: block; 
            text-align: center;
            margin-bottom: $dobbies-order-confirmation-summary-margin-bottom;
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: $dobbies-order-confirmation-summary-line-spacing;
            //padding-top: $dobbies-order-confirmation-summary-line-spacing;
            //border-top: 1px solid var(--dobbies-font-primary-color);
            width: 100%;

            .ms-order-confirmation__order-summary-label {
                font-weight: var(--dobbies-font-weight-normal);
            }
            .ms-order-confirmation__order-summary-price {
                &.msc-price {
                    .msc-price__actual {
                        font-size: var(--dobbies-body-font-size-ml);
                        font-weight: var(--dobbies-font-weight-normal);
                        @media screen and (max-width: $dobbies-container-max-width-s) {
                            font-size: var(--dobbies-body-font-size-m);
                            font-weight: var(--dobbies-font-weight-bold);
                        }
                    }
                } 
            }
        }
        &-line-subtotal {
            padding-top: 0;
            border: 0;
            margin-bottom: 30px;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                margin-bottom: 38px;
            }
        }

        &-line-total-amount {
            margin-bottom: 0;
            padding-top: $dobbies-order-confirmation-summary-line-spacing;
            padding-bottom: $dobbies-order-confirmation-summary-line-spacing;
            border-top: 1px solid $dobbies-bombay-grey;
            border-bottom: 1px solid $dobbies-bombay-grey;
            width: 100%;
            margin-top: 10px;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                margin-top: 5px;
            }

            .ms-order-confirmation__order-summary-label {
                font-weight: bold;
            }
            .ms-order-confirmation__order-summary-price {
                &.msc-price {
                    .msc-price__actual {
                        font-size: var(--dobbies-body-font-size-ml);
                        font-weight: bold;
                        @media screen and (max-width: $dobbies-container-max-width-s) {
                            font-size: var(--dobbies-body-font-size-m);
                            font-weight: bold;
                        }
                    }
                } 
            }
        }

        &-line-earned-points {
            .ms-order-details__order-summary-label {
                font-weight: var(--msv-font-weight-bold);
            }
        }

        &-price {
            float: right;
        }
    }

    
    &__payment-methods {
        display: flex;
        line-height: 24px;
        color: var(--dobbies-font-primary-color);
        padding-top: $dobbies-order-confirmation-summary-line-spacing;
        background: $dobbies-header-grey;
        width: 100%;
        border: 1px solid $dobbies-gray-100;
        border-top: 0;

        @media screen and (min-width: $dobbies-container-max-width-s) {
            padding: $dobbies-order-confirmation-summary-padding;
            padding-bottom: 25px;
        }

        &-title {
           font-size: 18px;
            line-height: 24px;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                font-size: var(--dobbies-body-font-size-m);
            }
        }

        &-line {
            @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-l), $dobbies-line-height-l);
            margin-left: 5px;
            line-height: 26px;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                font-size: var(--dobbies-body-font-size-m);
            }
        }

        &-loyalty-amount,
        &-gift-card-amount {
            margin-left: 4px;
        }
    }

    &__help,
    .help-centre-block {
        @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-l), $dobbies-line-height-m);
        color: var(--dobbies-font-primary-color);
        float: none;
        width: 100%;

        @media screen and (max-width: $dobbies-container-max-width-s) {
            font-size: var(--dobbies-body-font-size-m);
        }
    
        p {
            margin-bottom: $dobbies-order-confirmation-help-heading-margin-bottom;
        }

        &-title,
        p:first-child {
            @include font-content(var(--dobbies-font-weight-heavy), var(--dobbies-body-font-size-l), $dobbies-line-height-m);
            margin-top: $dobbies-order-confirmation-help-heading-margin-top;
            margin-bottom: $dobbies-order-confirmation-help-heading-margin-bottom;
            @media screen and (max-width: $dobbies-container-max-width-s) {
                font-size: var(--dobbies-body-font-size-m);
            }
        }

        &-content-number {
            @include vfi();
        }
    }

    .need-help-block {
        margin-top: 20px;
        @media screen and (max-width: $dobbies-container-max-width-tablet) {
            text-align: center;
        }
        h3 {
          font-size: $dobbies-font-size-s;
        }
        p {
            font-size: $dobbies-font-size-s;
            margin: 0;
            margin-top: 5px;
          a {
            text-decoration: underline;
          }
        }
    }

    .delivery-dispatched-details {
        padding: 18px;
        padding-left: 10px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        background-color: $dobbies-header-grey;

        &__count {
            span {
                font-size: $dobbies-font-size-s;
                text-transform: uppercase;
                font-weight: $dobbies-font-weight-bold;
                color: $dobbies-black;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    font-size: $dobbies-font-size-m;
                }
            }
        }

        .click-collect-label {
            font-size: $dobbies-font-size-s;
            text-transform: uppercase;
            font-weight: $dobbies-font-weight-bold;
            color: $dobbies-black;
            @media screen and (min-width: $dobbies-container-max-width-s) {
                font-size: $dobbies-font-size-m;
            }
        }

        &__right {
            text-align: right;
            margin: 0;
            * {
                font-size: $dobbies-font-size-xs;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    font-size: $dobbies-font-size-s;
                }
            }
        }
        &::after {
            display: none;
        }
    }

    .order-number__wrap {
        margin-bottom: 22.5px;
        @media screen and (min-width: $dobbies-container-max-width-s) {
            margin-bottom: 25px;
        }
        h3 {
            font-size: $dobbies-font-size-m;
            font-weight: bold;
        }
    }

    .order-date__wrap {
        @media screen and (max-width: $dobbies-container-max-width-s) {
            margin-bottom: 22.5px;
        }
        h5 {
            font-size: $dobbies-font-size-s;
            font-weight: bold;
            span {
                font-weight: normal;
            }
        }
    }

    .item-order__total {
        p {
            font-size: $dobbies-font-size-s;
            font-weight: bold;
            line-height: 14px;
        }
    }

    .order-delivery__wrap {
        margin-bottom: 25px;
        margin-top: 30px;
        h5 {
            font-weight: bold;
        }
        h5,
        p   {
            font-size: $dobbies-font-size-s;
            line-height: 20px;
        }
    }

    .sales-line-section {
        border-color: $dobbies-gray-100;
        margin-top: 20px;
        &:first-child {
            margin-top: 20px;
            border: none;
        }

        .msc-cart-line {
            &__product {
                padding: 0;
                > label {
                    display: none;
                }
                > a {
                    font-size: $dobbies-font-size-s;
                    font-weight: $dobbies-font-weight-bold;
                    line-height: 16px;
                    margin-bottom: 14px;
                    color: $dobbies-grey;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        width: 60vw;
                        font-size: $dobbies-font-size-ml;
                        line-height: 24px;
                    }
                    @media screen and (min-width: $dobbies-container-max-width-tablet) {
                        width: 30vw;
                    }
                }
                &-price {
                    top: 0px;
                    float: left;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        position: absolute;
                        top: 0px;
                        right: 10px;
                    }
                    .msc-price.discount-value {
                        * {
                            font-size: $dobbies-font-size-s;
                            font-weight: bold;
                            @media screen and (min-width: $dobbies-container-max-width-s) {
                                font-size: $dobbies-font-size-ml;
                            }
                        }
                    }
                }

                &-image {
                    min-width: 88.5px;
                    max-width: 88.5px;
                    margin-right: 25px;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        min-width: 139px;
                        max-width: 139px;
                        margin-right: 20px;
                    }
                    .msc-empty_image {
                        height: 124px;
                        @media screen and (min-width: $dobbies-container-max-width-s) {
                            height: 194px;
                        }
                    }
                    img {
                        width: 100%;
                    }
                }
            }

            &__quantity {
                .quantity-label,
                .quantity-value {
                    font-size: $dobbies-font-size-s;
                    font-weight: normal;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        font-size: $dobbies-font-size-l;
                    }
                }
            }
            &-item-subtotal-amount {
                position: relative;
                left: 115px;
                margin: 0;
                bottom: 60px;
                top: auto;
                @media screen and (min-width: $dobbies-container-max-width-s) {
                    position: absolute;
                    top: 0px;
                    left: auto;
                    right: 10px;
                    bottom: auto;
                }
                .msc-price {
                    .msc-price__actual {
                        font-size: $dobbies-font-size-s;
                        font-weight: bold;
                        @media screen and (min-width: $dobbies-container-max-width-s) {
                            font-size: $dobbies-font-size-ml;
                        }
                    }
                }
            }
        }
    }

    &__address {
        font-weight: var(--dobbies-font-weight-normal);
        margin-bottom: 50px;
        margin-top: 6px;
        line-height: initial;

        &-header,
        &-threeletterisoregionname {
            display: none;
        }

        &-city,
        &-threeletterisoregionname {
            &::before {
                content: "\A";
                white-space: pre;
            }
        }

        &-county {
            display: none;
        }

        &-name,
        &-phone {
            display: block;
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }
    @media screen and (min-width: $msv-container-min-width-m) {
       margin: $msv-main-container-margin;  

       &__sales-lines {
            &-shipping-items-label, &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top;
            }
        }

       &__payment-methods {
            padding: $dobbies-order-confirmation-summary-padding;
            padding-bottom: 25px;
       }
    }
    @media screen and (max-width: $dobbies-container-max-width-s) {
        &__groups {
            display: block;
            & > div {
                border-top: 1px solid $dobbies-gray-900;
            }

            & > div:first-of-type {
                border: none;
            }
            
            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: 100%;

            &-shipping-items-label, &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top-mobile;
            }
        }

        &__order-summary, &__payment-methods, &__help {
            float: none;
            width: 100%;
            position: relative;
            margin-left: 0;
        }

        &__order-summary {
            padding: 15px;
            &-heading,
            &-line-subtotal,
            &-line-shipping,
            &-line-tax-amount,
            &-line-total-amount {
                max-width: 100%;
            }
        }
        &__payment-methods {
            padding: 0 15px 15px;
        }

        &__btn-keep-shopping,
        &__sales-line-btn-buy-it-again {
            width: 100%;
        }

        .msc-cart-line__product-price {
            position: unset;
        }
    }

    .msc-empty_image {
        @include image-placeholder($msv-order-empty-image-width);
        background-size: 100%;
    }
    strong {
        font-weight: var(--dobbies-font-weight-bold);
    }
    .order-confirmation-alldetails {
        @media (min-width: $dobbies-container-max-width-m) {
            padding-right: 20px;
        }
        .ms-pickedup-products {
            .deliveryType-pickUp {
                margin-bottom: 15px;
            }
        }
    }

    .signin-order-confirmation {
        .ms-order-confirmation {
            &__order-information {
                .__order-brief-info {
                    justify-content: space-between;
                }
                .count-ammount {
                    display: flex;
                    align-items: center;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        padding-right: 25%;
                    }
                    .__sales-order-count {
                        font-size: $dobbies-font-size-s;
                        font-weight: bold;
                        border-left: none;
                        border-right-width: 2px;
                        line-height: 14px;
                        @media screen and (max-width: $dobbies-container-max-width-s) {
                            padding-right: 10px;
                            margin-right: 10px;
                            border-right: 1px solid var(--dobbies-font-primary-color);
                        }
                    }
                    .__sales-order-total {
                        font-size: $dobbies-font-size-s;
                        font-weight: bold;
                    }
                }

                .__order-information-created-date {
                    @media screen and (max-width: $dobbies-container-max-width-s) {
                        margin-bottom: 22.5px;
                    }
                    .__created-date,
                    .__created-date-label {
                        font-size: 14px;
                    }
                    .__created-date {
                        strong {
                            font-weight: bold;
                        }
                    }
                }

                .ms-order-confirmation__order-information-channel-reference-id {
                    font-size: $dobbies-font-size-m;
                    font-weight: bold;
                    margin-bottom: 22.5px;
                    @media screen and (min-width: $dobbies-container-max-width-s) {
                        margin-bottom: 25px;
                    }
                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
.print-receipt {
    &.ms-content-block[data-m-layout='full-width'] {
        .ms-content-block {
            &__details {
                padding: 0;
                background: var(--dobbies-tile-bg);
                width: 100%;
                height: 70px;
                margin-top: 30px;
                .msc-cta__primary {
                    margin: 0;
                    padding: 22px;
                    border: 0;
                    min-width: auto;
                    background: transparent;
                    @include font-content(var(--dobbies-font-weight-heavy),var(--dobbies-body-font-size-ml),$dobbies-line-height-l);
                    color: var(--dobbies-bg-color-primary);
                }
            }
            &__cta {
                margin-top: 0;
            }
        }
    }
}


