$dobbies-club-bg-path: '../../../';
.ms-dobbies-lost-card-free {    
  background-color: var(--dobbies-club-background-color);
  background-image: url('#{$dobbies-club-bg-path}dobbies-club-free-bg.png');
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  min-height: calc(100vh - 439px);
  color: var(--dobbies-club-font-light-green);
  text-align: center;
  @media screen and (max-width: $dobbies-container-max-width-s) {
      background-size: 1000px auto;
  }
}
.ms-dobbies-lost-card-plus {
  background-color: var(--dobbies-club-background-color);
  background-image: url('#{$dobbies-club-bg-path}dobbies-club-plus-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  min-height: calc(100vh - 439px);
  color: var(--dobbies-club-primary-color);
  text-align: center;
  @media screen and (max-width: $dobbies-container-max-width-s) {
      background-size: 1000px auto;
  }
}
.ms-dobbies-lost {
  padding-bottom: 70px;
  @media screen and (max-width: $dobbies-container-max-width-xl) {
    padding-bottom: 87px;
  }
  @media screen and (max-width: $dobbies-container-max-width-m) {
    padding-bottom: 100px;
  }
  @media screen and (max-width: $dobbies-container-max-width-s) {
    padding-bottom: 30px;
  }
  .welcome-heading {
    font-size: var(--dobbies-body-font-size-xxl);
    padding: 118px 0 122px 0;
    line-height: initial;
    @media screen and (max-width: $dobbies-container-max-width-xl) {
      padding: 85px 0 98px 0;
    }
    @media screen and (max-width: $dobbies-container-max-width-m) {
      font-size: var(--dobbies-title-font-size-40);
      padding: 70px 0 90px 0;
    }
    @media screen and (max-width: $dobbies-container-max-width-s) {
      font-size: var(--dobbies-tile-heading);
      padding: 60px 0 50px 0;
    }
  }
  .lost-card-heading {
    font-size: var(--dobbies-tile-heading);
    line-height: initial;
    margin-bottom: 23px;
    @media screen and (max-width: $dobbies-container-max-width-xl) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: $dobbies-container-max-width-m) {
      font-size: var(--dobbies-tile-heading-s);
      margin-bottom: 34px;
    }
    @media screen and (max-width: $dobbies-container-max-width-s) {
      margin-bottom: 25px;
    }
  }
  .card-container {
    background-color: var(--dobbies-bg-color);
    padding: 16px;
    max-width: 716px;
    margin: auto;
    overflow: hidden;
    @media screen and (max-width: $dobbies-container-max-width-xl) {
      max-width: 688px;
    }
    @media screen and (max-width: $dobbies-container-max-width-m) {
      max-width: calc(100% - 30px);
      margin-left: 15px;
    }
    &__content {
      text-align: left;
      font-size: var(--dobbies-body-font-size-s);
      color: var(--dobbies-font-primary-color);
      max-width: calc(100% - 260px);
      float: left;
      @media screen and (max-width: $dobbies-container-max-width-s) {
        max-width: 100%;
        float: none;
        text-align: center;
      }
      > p {
        margin-bottom: 16px;
      }
      &__btn {
        @include club-plus-primary-button();
        @include button-verticle-padding-zero();
        height: 45px;
        margin-top: 15px;
        cursor: pointer;
        border-radius: $dobbies-btn-border-radius;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        overflow: hidden;
        border: 0;
        &:before {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: var(--dobbies-bg-color-primary);
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
          color: var(--dobbies-font-secondary-color);
          background: var(--dobbies-bg-color-secondary);
          &:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
        }
      }
    }
    &__image {
      width: 244px;
      float: right;
      @media screen and (max-width: $dobbies-container-max-width-s) {
        display: none;
      }
    }
  }
}

.ms-dobbies-lost-card-plus .ms-dobbies-lost .card-container__image .card-image {
  background: url('#{$dobbies-club-bg-path}dobbies-club-plus-card.svg') no-repeat top left;
  background-size: 100%;
  width: 100%;
  height: 154px;
  display: block;
}
.ms-dobbies-lost-card-free .ms-dobbies-lost .card-container__image .card-image {
  background: url('#{$dobbies-club-bg-path}dobbies-club-card.svg') no-repeat top left;
  background-size: 100%;
  width: 100%;
  height: 154px;
  display: block;
}