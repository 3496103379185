$msv-checkout-description-margin-left: 8px;
$msv-checkout-option-margin-bottom: 20px;
$msv-checkout-delivery-options-price-margin-left: 12px;
$msv-checkout-delivery-options-description-margin-left: 0px;
$dobbies-checkout-delivery-option-input-radio-height:17px;
$dobbies-checkout-delivery-option-input-radio-width:17px;

//style presets
:root {
  --msv-checkout-delivery-options-font-size: var(--msv-body-font-size-m);
  --msv-checkout-delivery-options-font-color: #{msv-gray-900};
}

.ms-checkout-delivery-options {
  &__price {
    @include font-content(var(--msv-font-weight-normal), var(--msv-checkout-delivery-options-font-size), $dobbies-line-height-m);
    color: var(--msv-checkout-delivery-options-font-color);
    margin-left: auto;
    float: right;
    margin-top: -3px;
    .msc-price__actual {
      font-size: var(--dobbies-body-font-size-m);
      margin-top: 3px;
    }
  }

  &__description {
    @include font-content(var(--dobbies-font-weight-normal), var(--dobbies-body-font-size-m), $dobbies-line-height-l);
    color: var(--msv-checkout-delivery-options-font-color);
    margin-left: $msv-checkout-description-margin-left;
  }

  &__option {
    margin-bottom: $msv-checkout-option-margin-bottom;
    display: block;
    align-items: center;

    &-selected {
      .ms-checkout-delivery-options__price {
        margin-left: $msv-checkout-delivery-options-price-margin-left;
      }

      .ms-checkout-delivery-options__description {
        margin-left: $msv-checkout-delivery-options-description-margin-left;
      }
    }

  }

  &__input-radio {
    @include form-input-radio();
    height: $dobbies-checkout-delivery-option-input-radio-height;
    width: $dobbies-checkout-delivery-option-input-radio-width;
    position: relative;
    top: -3px;
  }

  @media screen and (min-width: $msv-breakpoint-l) {
    &__description {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    &__price {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
      margin-left: 0;
    }
  }

}
