$msv-password-reset-width: 40%;
$msv-password-reset-width-m: 80%;
$msv-password-reset-message-margin-top: 20px;
$msv-password-reset-message-padding: 20px;
$msv-password-reset-account-item-margin-top: 20px;
$msv-password-reset-input-margin-top: 4px;
$msv-password-reset-button-group-spacing: 32px;
$msv-password-reset-button-margin-top: 30px;
$dobbies-logo-path: '../../../';

//style presets
:root {
  --msv-password-reset-font-size: var(--msv-body-font-size-m);
  --msv-password-reset-font-color: var(--dobbies-font-primary-color);

  // heading
  --msv-password-reset-heading-font-size: var(--msv-body-font-size-xl);
  --msv-password-reset-heading-font-color: var(--dobbies-font-primary-color);

  //error
  --msv-password-reset-error-font-color: #{$dobbies-red};
  --msv-password-reset-error-font-size: var(--msv-body-font-size-m);

  // alert
  --msv-password-reset-success-alert-bg: #{$dobbies-gray-300};
  --msv-password-reset-success-alert-font-color: var(--dobbies-font-primary-color);
  --msv-password-reset-success-alert-border: #{$dobbies-gray-900};
  
  --msv-password-reset-error-alert-bg: var(--dobbies-error-color);
  --msv-password-reset-error-alert-font-color: var(--dobbies-font-secondary-color);
  --msv-password-reset-error-alert-border: var(--dobbies-error-color);

  // primary button
  --msv-password-reset-primary-btn-bg: var(--dobbies-accent-brand-color);
  --msv-password-reset-primary-btn-font-color: var(--dobbies-font-secondary-color);
  --msv-password-reset-primary-btn-border: var(--dobbies-accent-brand-color);

  // secondary button
  --msv-password-reset-secondary-btn-bg: var(--dobbies-bg-color);
  --msv-password-reset-secondary-btn-font-color: var(--dobbies-font-primary-color);
  --msv-password-reset-secondary-btn-border: var(--dobbies-accent-brand-color);

  //input color
  --msv-password-reset-input-bg: #{$dobbies-white};
}

.password-reset-container {
  position: relative;
  padding-top: 140px;
  min-height: calc(100vh - 304px);

  .ms-content-block {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-m-layout='full-width'] {
      .ms-content-block__details {
        position: absolute;
        // top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width : $msv-breakpoint-mbl-sm){
          transform: translate(-50%, 0);
          padding: 20px;
        }

        .ms-content-block__cta a {
          display: block;
          background: url('#{$dobbies-logo-path}logo.svg') no-repeat top left;
          background-size: 100%;
          width: 210px;
          height: 55px;
          margin: 40px auto;
          border: 0;
          min-width: auto;
    
          @media (max-width: $msv-breakpoint-sm) {
            width: 169px;
            height: 46px;
          }
        }
      }
    }

    @media (max-width: $msv-breakpoint-xl) {
      // min-height: auto;
      // padding-top: 35px;
      position: initial;
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: $dobbies-container-max-width-l) {
      position: relative;
      top: 50px;
    }
  }

  @media (min-width: $msv-breakpoint-xl) {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before { 
      width: 381px;
      height: 325px;
    }

    &:after {
      width: 279px;
      height: 295px;
    }

    .row {
      max-width: 538px;
    }
  }

  @media (max-width: $msv-breakpoint-m) {
    &:before { 
      max-width: 181px;
      max-height: 154px;
    }

    &:after {
      max-width: 159px;
      max-height: 168px;
    }

    & > .row {
      width: 100%;
    }
  }

  @media (max-width: $msv-breakpoint-mbl-sm) {

    &:before, &:after { 
      display: none;
    }
  }

  .ms-password-reset-verification {
    .ms-content-block {
      text-align: center;
    }
  }
  
  .ms-password-reset-verification, .ms-password-reset {
    @include font-content(var(--msv-font-weight-normal),var(--msv-password-reset-font-size), $dobbies-line-height-m);
    color: var(--msv-password-reset-font-color);
    padding-bottom: 45px;

    @media (max-width: $msv-breakpoint-xl) {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: $msv-breakpoint-mbl-sm) {
        height: auto;
        margin-bottom: 30px;
      }
    }
  
    &__container {
      width: $msv-password-reset-width;
      max-width: 358px;
    }
  
    &__heading {
      @include font-content(var(--dobbies-font-weight-heavy), var(--dobbies-body-font-size-m), $dobbies-line-height-xl);
      color: var(--msv-password-reset-heading-font-color);
      padding-bottom: 9px;
      border-bottom: 3px solid var(--dobbies-accent-brand-color);
      text-align: center;
      margin-bottom: 25px;
  
      @media (max-width : $dobbies-container-max-width-l) {
        padding-bottom: 3px;
      }
    }
  
    &__account-item {
      margin-top: $msv-password-reset-account-item-margin-top;
  
      &-input {
        @include form-input-el(var(--msv-password-reset-input-bg));
        margin-top: $msv-password-reset-input-margin-top;
        width: 100%;
        height: 60px;
        border: 1px solid var(--dobbies-icon-color-header);
  
        @media screen and (max-width: $msv-breakpoint-m) {
          height: 40px;
        }
      }

      &-emailVerificationCode {
        label {
          margin-top: 20px;
          display: block;
  
          @media (max-width: $msv-breakpoint-m) {
            margin-top: 15px;
          }
        }
      }

    }
  
    &__page-success {
      @include validation-success(var(--msv-password-reset-success-alert-bg), var(--msv-password-reset-success-alert-border), var(--msv-password-reset-success-alert-font-color));
      display: none;
      margin-top: $msv-password-reset-message-margin-top;
      padding: $msv-password-reset-message-padding;
    }
  
    &__page-error {
      @include validation-error(var(--msv-password-reset-error-alert-bg), var(--msv-password-reset-error-alert-border), var(--msv-password-reset-error-alert-font-color));
      display: none;
      margin-top: $msv-password-reset-message-margin-top;
      padding: $msv-password-reset-message-padding;
    }
  
    &__item-error {
      @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-error-font-size), $dobbies-line-height-m);
      color: var(--msv-password-reset-error-font-color);
    }
  
    &__buttons {
      margin-top: 0;
  
      &__continue-button, &__email-verification-button {
        margin-top: 0px;
      }
    }
  
    &__continue-button, &__email-verification-button {
      @include primary-button(var(--msv-password-reset-primary-btn-bg), var(--msv-password-reset-primary-btn-font-color), var(--msv-password-reset-primary-btn-border));
      margin-top: $msv-password-reset-button-margin-top;
      width: 100%;
      border-radius: 0;
      line-height: inherit;
      font-size: var(--dobbies-body-font-size-m);
      font-weight: var(--dobbies-font-weight-heavy);
      height: 40px;
      padding: 0;
      position: relative;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      position: relative;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      overflow: hidden;
      border: 0;
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dobbies-bg-color-secondary);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover,
      &:focus,
      &:active {
        color: var(--dobbies-font-primary-color);
        background: var(--dobbies-bg-color-primary);
        &:after {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
      &:disabled,
      &[disabled] {
        cursor: default;
        border-color: $dobbies-gray-300;
        background-color: $dobbies-gray-300;
        color: var(--dobbies-font-secondary-color);
        &:hover {
          border-color: $dobbies-gray-300;
          background-color: $dobbies-gray-300;
          color: var(--dobbies-font-secondary-color);
          &:after {
            background-color: $dobbies-gray-300;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
          }
        }
      }
    }
  
    &__cancel-button {
      @include secondary-button(var(--msv-password-reset-secondary-btn-bg), var(--msv-password-reset-secondary-btn-font-color), var(--msv-password-reset-secondary-btn-border));
      margin-top: $msv-password-reset-button-margin-top;
      width: 100%;
      display: none;
    }
  
    &__verifying-modal,
    &__verifying-modal-message {
      display: none;
    }
  
    @media screen and (min-width: $msv-container-max-width-l) {
      &__container {
        width: 538px;
        max-width: 538px;
      }
    }
  
    @media screen and (min-width: $msv-container-max-width-m) {
      &__container {
        width: 504px;
        max-width: 504px;
      }
  
      &__continue-button, &__email-verification-button {
        height: 60px;
      }
    }
  
    @media screen and (max-width: $msv-breakpoint-m) {
      &__container {
        width: $msv-password-reset-width-m;
      }
    }  
  }
}
.logo-container {
  .centered-header {
    position: absolute;
    width: 180px;
    height: 50px;
    z-index: 1;
    left: 50%;
    margin-left: -90px;
    margin-top: 50px;
    min-height: auto;
    > a {
      display: block;
      background: url('#{$dobbies-logo-path}logo.svg') no-repeat top left;
      background-size: 100%;
      width: 180px;
      height: 50px;
      border: 0;
      @media screen and (max-width: $dobbies-container-max-width-s) {
        width: 169px;
        height: 46px;
        margin: 20px auto;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .ms-content-block__details {
      display: none
    }
  }
}