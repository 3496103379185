.ms-advice-media-container {
    padding-top: 57px;
    @media (max-width: $dobbies-container-max-width-xl) {
        padding-top: 22px;
    }
    @media (max-width: $dobbies-container-max-width-m) {
        padding-top: 13px;
    }
    @media (max-width: $dobbies-container-max-width-s) {
        padding-top: 9px;
    }


    &.terms-and-conditions-page-container {

        .ms-template-sample-banner {
            min-height: auto;
            padding: 0;
            margin-bottom: 40px;
            @media (max-width: $dobbies-container-max-width-s) {
                margin-bottom: 23px;
            }
            .ms-content-block {
                &__image {
                    width: 75%;
                    margin-left: auto;
                    overflow: hidden;
                    @media (max-width: $dobbies-container-max-width-s) {
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
                &__details {
                    position: relative;
                    height: auto;
                    padding: 0;
                    right: 0;
                    bottom: 0;
                    margin-left: 0;
                    
                    @media (min-width: $msv-container-max-width-m) {
                        margin-top: -150px;
                    }
                    // @media (min-width: $dobbies-container-max-width-xl) {
                    //     margin-top: -120px;
                    // }
                    
                    @media (max-width: $dobbies-container-max-width-l) {
                        margin-top: -100px;
                    }
                    @media (max-width: $dobbies-container-max-width-s) {
                        margin-top: 0;
                    }
                }
                &__title {
                    margin-bottom: 100px;
                    max-width: 360px;
                    background: rgba(255, 255, 255, 0.75);
                    width: 100%;
                    position: relative;
                    padding: 12px;
                    text-align: right;
                    line-height: 45px;
                    margin-bottom: 85px;
                    max-width: 400px;
                    font-size: $dobbies-advice-heading-font-size;
                    color: var(--dobbies-heading-primary-color);
                    line-height: 50px;
                    font-weight: var(--dobbies-font-weight-heavy);
                    
                    @media (min-width: $msv-container-max-width-m) {
                        margin-bottom: 50px;
                    }
                    @media (min-width: $dobbies-container-max-width-xl) {
                        margin-bottom: 85px;
                        max-width: 430px;
                    }

                    @media (max-width: $dobbies-container-max-width-l) {
                        margin-bottom: 50px;
                        max-width: 450px;
                    }
                    
                    @media (min-width: $dobbies-container-max-width-l) and (max-width: $dobbies-container-max-width-xl) {
                        max-width: 330px;
                    }

                    @media (max-width: $dobbies-container-max-width-s) {
                        margin: 14px 0 22px;
                        padding: 0;
                        text-align: center;
                        max-width: 100%;
                        font-size: var(--dobbies-tile-heading);
                    }
                }
                &__text{
                    text-align: center;
                    p {
                        line-height: 22px;
                    }
                }
            }
        }
        
        .terms-and-conditions-content {
            margin-left: -10px;
            margin-right: -10px;
            @media (max-width: $dobbies-container-max-width-s) {
                margin-left: 0;
                margin-right: 0;
            }
            > .row {
                justify-content: center;
                .col-12 {
                    
                    padding-bottom: 40px;
                    div[class*='imageplacement'] {
                        display: flex;
                        flex-wrap: wrap;
                        padding-bottom: 40px;
                        .ms-text-block {
                            
                            h2 {
                                color: var(--dobbies-heading-primary-color);
                                font-weight: var(--dobbies-font-weight-heavy);
                                border-bottom: solid 1px rgba(102,102,102,0.5);
                                font-size: var(--dobbies-tile-heading);
                                margin: 27px 0 20px;
                                padding-bottom: 15px;
                                line-height: 36px;
                            }

                            p {
                                font-size: var(--dobbies-body-font-size-m);
                                margin-top: 0;
                            }
                        }
                    }
                    .ms-text-block {
                         h2 {
                            color: var(--dobbies-heading-primary-color);
                            font-weight: var(--dobbies-font-weight-heavy);
                            border-bottom: solid 1px rgba(102,102,102,0.5);
                            font-size: var(--dobbies-tile-heading);
                            line-height: 36px;
                            margin: 27px 0 20px;
                            padding-bottom: 15px;
                        }
                        p {
                            font-size: var(--dobbies-body-font-size-s);
                            margin-top: 0;
                        }
                    }

                    @media (min-width: $msv-breakpoint-mbl-sm) {
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @media (max-height: $msv-breakpoint-mbl) and (orientation: landscape) {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }

            .media-page-heading {
                h2 {
                    @media (max-width: $dobbies-container-max-width-m) {
                        margin-top: 35px;
                    }
                    @media (max-width: $dobbies-container-max-width-s) {
                        margin-top: 10px;
                        line-height: 45px;
                    }
                }
            }
        }
        .ms-content-block {
            &[data-m-layout='full-width'] {
                min-height: auto;
                .ms-content-block__details {
                    padding: 0;
                    width: 100%;
                    display: block;
                    height: auto;
                    text-align: center;
                    margin:0;
                    position: relative;
                    @media (max-width: $dobbies-container-max-width-s) {
                        margin-top: 0;
                        height: auto;
                    }
                }
                .ms-content-block__cta {
                    display: inline-block;
                    margin-top: 0;
            
                    a,
                    .msc-cta__primary,
                    .msc-cta__secondary {
                        min-height: 29px;
                        max-height: 29px;
                        line-height: initial;
                        min-width: 215px;
                        border: 1px solid var(--dobbies-breadcrumb-icon-color);
                        font-size: var(--dobbies-body-font-size-s);
                        font-weight: var(--dobbies-font-weight-normal);
                        border-radius: 4px;
                        background-color: transparent;
                        color: var(--dobbies-font-primary-color);
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                        cursor: pointer;
                        padding: 0;
            
                        @media (max-width: $msv-breakpoint-l) {
                            min-width: 173px;
                        }
            
                        @media (max-width: $msv-breakpoint-sm) and (min-width: $msv-breakpoint-mbl) {
                            min-width: 130px;
                        }
            
                        @media (max-width: $msv-breakpoint-mbl) {
                            min-width: 150px;
                        }
            
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}