$msv-order-template-heading-height: 40px;
$msv-order-template-block-margin-bottom: 20px;

$msv-order-template-top-panel-block-margin-right: 20px;
$msv-order-template-top-panel-height: 48px;
$msv-order-template-top-panel-message-min-with: 300px;
$msv-order-template-top-panel-message-max-with: 50%;

$msv-order-template-table-header-height: 43px;
$msv-order-template-table-line-height: 82px;
$msv-order-template-table-line-button-height: 48px;
$msv-order-template-table-line-button-width: 48px;
$msv-order-template-table-line-quantity-width: 80px;
$msv-order-template-table-line-quantity-margin: 0px 8px;

$msv-order-template-line-image-size: 64px;
$msv-order-template-line-price-original-font-color: $dobbies-gray-300;
$msv-order-template-line-price-original-line-height: 16px;
$msv-order-template-line-price-current-font-color: var(--dobbies-font-primary-color);
$msv-order-template-line-dimension-margin-right: 10px;
$msv-order-template-line-dimension-label-margin: 5px;
$msv-order-template-line-message-padding: 10px;
$order-template-quantity-input: 5px;
$order-template-incr-decr: 5px;
$msv-mobile-port: 450px;
$msv-order-template-margin: 0 20px 40px 0;
$msv-order-template-list-message-empty-margin-bottom: 32px;
$msv-order-template-list-tile-image-max-width: 140px;
$msv-order-template-list-tile-image-margin: 2px;
$msv-order-template-list-tile-image-padding: 4px;

//style presets
:root {
    --msv-order-template-font-size: var(--msv-body-font-size-m);
    --msv-order-template-border: #{$dobbies-gray-300};
    --msv-order-template-btn-bg: #{$dobbies-white};
    --msv-order-template-btn-border-color: var(--dobbies-border-color);
    --msv-order-template-btn-font-color: var(--dobbies-font-primary-color);
    // heading
    --msv-order-template-heading-font-size: var(--msv-body-font-size-xl);
    --msv-order-template-heading-font-color: var(--dobbies-font-primary-color);

    // error
    --msv-order-template-error-bg: var(--dobbies-error-color);
    --msv-order-template-error-border: var(--dobbies-error-color);
    --msv-order-template-error-font-color: var(--dobbies-font-secondary-color);

    // link
    --msv-order-template-link-color: var(--dobbies-font-primary-color);

    // button
    --msv-order-template-secondary-btn-bg: var(--dobbies-bg-color);
    --msv-order-template-secondary-btn-font-color: var(--dobbies-font-primary-color);
    --msv-order-template-secondary-btn-border: var(--dobbies-accent-brand-color);
    --msv-order-template-primary-btn-bg: var(--dobbies-accent-brand-color);
    --msv-order-template-primary-btn-font-color: var(--dobbies-font-secondary-color);
    --msv-order-template-primary-btn-border: var(--dobbies-accent-brand-color);
}

.ms-order-template {
    @include font-content(var(--msv-font-weight-normal), var(--msv-order-template-font-size), $dobbies-line-height-m);

    &__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-order-template-heading-font-size), $dobbies-line-height-xl);
        color: var(--msv-order-template-heading-font-color);
        height: $msv-order-template-heading-height;
        margin-bottom: $msv-order-template-block-margin-bottom;
    }
    
    &__top-panel {
        display: flex;
        height: $msv-order-template-top-panel-height;
        justify-content: space-between;
        margin-bottom: $msv-order-template-block-margin-bottom;
        
        .ms-order-template-action-bar {
            &__delete-template-button {
                @include secondary-button(var(--msv-order-template-btn-bg));
                @include vfi();
            }
        }

        .ms-order-template-products-status {
            height: $msv-order-template-top-panel-height;
            min-width: $msv-order-template-top-panel-message-min-with;
            max-width: $msv-order-template-top-panel-message-max-with;

            .msc-alert {
                button.close {
                    background-color: transparent;
                    border: none;
                    float: right;
                    cursor: pointer;
                }
            }
        }
    }

    &__message {
        @include font-content-l();
        padding: $msv-order-template-line-message-padding;

        &-failed {
            color: var(--msv-order-template-error-font-color);
            background-color: var(--msv-order-template-error-bg);
            border-color: var(--msv-order-template-error-border);
        }
    }

    &__line-count {
        padding-left: 12px;
        padding-top:4px;
    }

    &__image-props{
        padding-left: $msv-order-template-list-tile-image-padding;
        padding-top: $msv-order-template-list-tile-image-padding;
        max-width: $msv-order-template-list-tile-image-max-width;
        margin-right: $msv-order-template-list-tile-image-margin;
        margin-left: $msv-order-template-list-tile-image-margin;
    }
    
    &__add-to-bag-button {
        background: var(--dobbies-bg-color);
        border: 1px solid #4C833A;
        box-sizing: border-box;
        border-radius: 2px;
        width: 160px;
    }

    &__add-to-bag-button-div {
        padding-bottom: 12px;
        padding-left: 12px;
    }

    &__tile {
        display: flex;
        flex-direction: column;
        position: relative;

        background: var(--dobbies-bg-color);

        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
        border-radius: 3px;
        margin: $msv-order-template-margin;
    }
    
    &__template-title {
        position: absolute;
        height: 0px;
        left: 0px;
        right: 0px;
        top: 65px;
        padding-left: 6px;
        border: 1px solid #EDEDED;
    }

    &__list-item-title {
        font-weight: var(--msv-font-weight-bold);
        padding-left: 12px;
        padding-top: 8px;
    }

    &__image-container {
        width: 292px;
        height: 292px;
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 12px;
        background: #F9F9F9;
    }

    &__add-template-button {
        background: #4C833A;
        border-radius: 2px;
        color: var(--dobbies-font-secondary-color);
        margin-bottom: 20px;
    }

    &__list-item {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
    }

    &__remove-list {
        @include vfi();
        @include add-icon($msv-x-shape);
        background-color: transparent;
        border: 1px solid transparent;
        color: var(--dobbies-font-primary-color);
        padding: 6px 12px;
        position: absolute;
        right: 0px;
        cursor: pointer;
    }

    &__message-empty,
    &__message-waiting,
    &__message-failed {
        margin-bottom: $msv-order-template-list-message-empty-margin-bottom;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        tr {
            height: $msv-order-template-table-line-height;
            border-bottom: 1px solid var(--msv-order-template-border);
        }

        .ms-order-template-table-line {
            height: $msv-order-template-table-line-height;

            &__heading {
                height: $msv-order-template-table-header-height;

                th {
                    @include font-content(var(--msv-font-weight-normal), var(--msv-order-template-font-size), $dobbies-line-height-m);
                    text-align: start;
                }
            }
            &__product {
                &-image {
                    width: $msv-order-template-line-image-size;
                }

                &.msc-empty_image {
                    @include image-placeholder($msv-order-template-line-image-size);
                }

                &-info {
                    &-name {
                        @include font-content(var(--msv-font-weight-bold), var(--msv-order-template-font-size), $dobbies-line-height-l);
                    }

                    &-dimensions {
                        color: var(--msv-order-template-link-color);
                        display: flex;
                    }
        
                    &-dimension {
                        margin-right: $msv-order-template-line-dimension-margin-right;
                        .msc-order-template-dimension {
                            &__label {
                                margin-right: $msv-order-template-line-dimension-label-margin;
                            }
                        }
                    }
                }

                &-price {
                    @include font-content-l();

                    .msc-price__strikethrough {
                        font-weight: var(--msv-font-weight-light);
                    }
        
                    .msc-price__actual {
                        font-weight: var(--msv-font-weight-normal);
                    }
        
                    .price-strikethrough {
                        color: $msv-order-template-line-price-original-font-color;
                        line-height: $msv-order-template-line-price-original-line-height;
                    }
        
                    .price-actual {
                        color: $msv-order-template-line-price-current-font-color;
                    }

                    .price-text-screen-reader {
                        display: none;
                    }
                }

                &-unit-of-measure {
                    font-weight: normal;
                }

                &-add-button {
                    font-size: var(--msv-order-template-font-size);
                    @include add-icon($msv-Shop, after);
                    color: var(--msv-order-template-primary-btn-bg);
                    background-color: transparent;
                    width: $msv-order-template-table-line-button-width;
                    height: $msv-order-template-table-line-button-height;
                    padding-left: 0;
                    padding-right: 0;
                }

                &-remove-button {
                    background-color: transparent;
                    @include add-icon($msv-x-shape, after);
                    color: var(--msv-order-template-primary-btn-bg);
                    width: $msv-order-template-table-line-button-width;
                    height: $msv-order-template-table-line-button-height;
                    padding-left: 0;
                    padding-right: 0;
                }

                &-quantity-input {
                    .quantity {
                        .quantity-input {
                            @include form-control();
                            width: $msv-order-template-table-line-quantity-width;
                            display: inline-block;
                            margin: $msv-order-template-table-line-quantity-margin;
                            -moz-appearance: textfield;
                            padding: $order-template-quantity-input;
                            vertical-align: bottom;
                        
                            &::-webkit-inner-spin-button,
                            &::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                            }
                        }
                    
                        .quantity__controls {
                            padding: $order-template-incr-decr;
                            padding-left: 0px;
                            padding-right: 0px;
                            background: var(--msv-order-template-btn-bg);
                            background-position: center;
                            background-repeat: no-repeat;
                            border: 1px solid var(--msv-order-template-btn-border-color);
                            min-width: auto;
                            width: $msv-order-template-table-line-button-width;
                            height: $msv-order-template-table-line-button-height;
                            cursor: pointer;
                        
                            &:disabled {
                                color: $dobbies-gray-300;
                            }
                        }
                    
                        .increment {
                            @include add-icon($msv-Add, after);
                            color: var(--msv-order-template-btn-font-color);
                        
                            &.disabled {
                                color: $dobbies-gray-300;
                            }
                            
                            &:not(:disabled):not(.disabled):active,
                            &:not(:disabled):not(.disabled):active:focus {
                                color: var(--msv-order-template-btn-font-color);
                            }
                        }
                    
                        .decrement {
                            @include add-icon($msv-Remove, after);
                            color: var(--msv-order-template-btn-font-color);
                            
                            &.disabled {
                                color: $dobbies-gray-300;
                            }
                        
                            &:not(:disabled):not(.disabled):active,
                            &:not(:disabled):not(.disabled):active:focus {
                                color: var(--msv-order-template-btn-font-color);
                            }
                        }
                    }
                }
            }             
        }
    }
}