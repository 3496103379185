$msv-wishlist-items-width: 310px;
$msv-wishlist-items-width-m: 310px;
$msv-wishlist-items-empty-image-height: 310;
$msv-wishlist-items-heading-spacing: 20px;
$msv-wishlist-item-margin: 0 20px 60px 0;
$msv-wishlist-item-height: 468px;
$msv-wishlist-item-margin-mobile: 0 20px 60px 0;
$msv-wishlist-item-image-margin-bottom: 20px;
$msv-wishlist-item-image-size: $msv-wishlist-items-width;
$msv-wishlist-item-image-width-m: $msv-wishlist-items-width-m;
$msv-wishlist-item-price-original-font-color: $dobbies-gray-300;
$msv-wishlist-item-price-original-line-height: 16px;
$msv-wishlist-item-price-current-font-color: var(--dobbies-font-primary-color);
$msv-wishlist-item-dimension-font-size: var(--dobbies-body-font-size-xs);
$msv-wishlist-item-dimension-line-height: 16px;
$msv-wishlist-item-dimension-margin-right: 10px;
$msv-wishlist-item-dimension-label-margin: 5px;
$msv-wishlist-item-add-button-icon-margin-right: 8px;
$msv-wishlist-item-add-button-margin: 10px auto 0px 0px;
$msv-wishlist-item-remove-button-right: 0px;
$msv-wishlist-item-remove-button-padding: 10px 10px;
$msv-wishlist-item-message-padding: 10px;
$msv-wishlist-item-product-status-max-width: 310px;
$msv-wishlist-items-message-empty-margin-bottom: 32px;
$dobbies-account-wishlist-title-margin-top: 20px;
$dobbies-account-header-breakpoint-l: 1200px;
$dobbies-account-header-breakpoint-xs: 576px;
$bobbies-wishlist-title-line-height: 63px;
$dobbies-wishlist-item-min-height: 430px;

//style presets
:root {
    --msv-wishlist-font-size: var(--msv-body-font-size-m);
    --msv-wishlist-title-font-color: var(--dobbies-font-primary-color);

    // heading
    --msv-wishlist-heading-font-size: var(--msv-body-font-size-xl);
    --msv-wishlist-heading-font-color: var(--dobbies-font-primary-color);

    // error
    --msv-wishlist-error-bg: var(--dobbies-error-color);
    --msv-wishlist-error-border: var(--dobbies-error-color);
    --msv-wishlist-error-font-color: var(--dobbies-font-secondary-color);

    // link
    --msv-wishlist-link-color: var(--dobbies-font-primary-color);

    // button
    --msv-wishlist-secondary-btn-bg: var(--dobbies-bg-color);
    --msv-wishlist-secondary-btn-font-color: var(--dobbies-font-primary-color);
    --msv-wishlist-secondary-btn-border: var(--dobbies-accent-brand-color);
}

.ms-wishlist-items {
    @include font-content(var(--msv-font-weight-normal), var(--msv-wishlist-font-size), $dobbies-line-height-m);
    @media screen and (max-width: $msv-breakpoint-m) {
        &__product {
            flex: 0 1 $msv-wishlist-items-width-m;
            // left: 50%;
            margin: 0 7px 20px 6px;
            min-height: 0px;
            // transform: translate(-50%, 0);
            width: $msv-wishlist-items-width;
            max-width: 176px;

            &-image {
                width: $msv-wishlist-item-image-width-m;
            }

            &-add-button {
                min-width: 100%;
            }
        }

        .msc-empty_image {
            @include image-placeholder-rectangle(252px, 353px);
            max-width: 100%;
            @media screen and (max-width: $dobbies-container-max-width-xl) {
                @include image-placeholder-rectangle(240px, 336px);
                max-width: 100%;
            }
            @media screen and (max-width: $dobbies-container-max-width-l) {
                @include image-placeholder-rectangle(231px, 321px);
                max-width: 100%;
            }
            @media screen and (max-width: $dobbies-container-max-width-s) {
                @include image-placeholder-rectangle(190px, 246px);
                max-width: 100%;
            }
            @media screen and (max-width: $msv-breakpoint-mbl-sm) {
                @include image-placeholder-rectangle(260px, 365px);
                max-width: 100%;
                background-position: 40px 50px;
            }
            @media screen and (max-width: $msv-breakpoint-mbl) {
                @include image-placeholder-rectangle(170px, 275px);
                max-width: 100%;
                background-position: 10px 20px;
            }
            @media screen and (max-width: $dobbies-container-max-width-xs) {
                @include image-placeholder-rectangle(169px, 245px);
                max-width: 100%;
            }
        }
    }
    &__heading {
        @include font-content(var(--dobbies-font-weight-bold), var(--dobbies-body-font-size-s), normal);
        color: var(--msv-wishlist-heading-font-color);
        margin-bottom: $msv-wishlist-items-heading-spacing;
        padding-bottom: 15px;
        //  margin-top: $dobbies-account-wishlist-title-margin-top;
        border-bottom: $msc-account-welcome-title-border-bottom-thickness $msc-account-welcome-title-border-bottom-style
            var(--dobbies-font-primary-color);
        color: var(--msv-account-landing-title-font-color);

        @media (max-width: $msv-breakpoint-l) {
            padding-bottom: 10px;
        }
    }

    &__message {
        @include font-content-l();
        padding: $msv-wishlist-item-message-padding;

        &-failed {
            color: var(--msv-wishlist-error-font-color);
            background-color: var(--msv-wishlist-error-bg);
            border: 1px solid var(--msv-wishlist-error-border);
        }

        &-empty {
            color: var(--msv-wishlist-title-font-color);
        }
    }

    &__products {
        display: flex;
        flex-wrap: wrap;
        padding-top: $msv-wishlist-items-heading-spacing;
        margin-left: -7px !important;
        margin-right: -8px !important;
    }

    &__product {
        display: flex;
        flex-direction: column;
        margin: $msv-wishlist-item-margin;
        min-height: auto;
        position: relative;
        margin: 0 7px 20px 8px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--dobbies-font-primary-color);

        &-image {
            margin-bottom: 10px;
            width: $msv-wishlist-item-image-size;

            &.msc-empty_image {
                @include image-placeholder-rectangle(252px, 353px);
                max-width: 100%;
                @media screen and (max-width: $dobbies-container-max-width-xl) {
                    @include image-placeholder-rectangle(240px, 336px);
                    max-width: 100%;
                }
                @media screen and (max-width: $dobbies-container-max-width-l) {
                    @include image-placeholder-rectangle(231px, 321px);
                    max-width: 100%;
                }
                @media screen and (max-width: $dobbies-container-max-width-s) {
                    @include image-placeholder-rectangle(190px, 246px);
                    max-width: 100%;
                }
                @media screen and (max-width: $msv-breakpoint-mbl-sm) {
                    @include image-placeholder-rectangle(260px, 365px);
                    max-width: 100%;
                    background-position: 40px 50px;
                }
                @media screen and (max-width: $msv-breakpoint-mbl) {
                    @include image-placeholder-rectangle(170px, 275px);
                    max-width: 100%;
                    background-position: 10px 20px;
                }
                @media screen and (max-width: $dobbies-container-max-width-xs) {
                    @include image-placeholder-rectangle(169px, 245px);
                    max-width: 100%;
                }
            }
        }

        &-link {
            @include button-link(var(--msv-wishlist-link-color));
            @include font-content(var(--msv-font-weight-normal), var(--msv-wishlist-font-size), normal);
            @include vfi();
            width: $msv-wishlist-items-width;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // white-space: break-spaces;
            //min-height: 42px;
            max-height: 42px;
            width: 100%;
            margin-bottom: 10px;
            display: block;
            white-space: nowrap;

            &:hover {
                color: $dobbies-grey;
            }
        }

        &-price {
            @include font-content-l();
            font-size: var(--dobbies-body-font-size-m);

            .msc-price__strikethrough {
                font-weight: var(--msv-font-weight-light);
            }

            .msc-price__actual {
                font-weight: var(--dobbies-font-weight-heavy);
                color: var(--msv-wishlist-title-font-color);
            }

            .price-strikethrough {
                color: $msv-wishlist-item-price-original-font-color;
                line-height: $msv-wishlist-item-price-original-line-height;
            }

            .price-actual {
                color: $msv-wishlist-item-price-current-font-color;
            }

            // not needed when Price component is moved to utilities folder
            .price-text-screen-reader {
                display: none;
            }
        }

        &-dimensions {
            color: var(--msv-wishlist-title-font-color);
            display: flex;
            font-size: $msv-wishlist-item-dimension-font-size;
        }

        &-dimension {
            margin-right: $msv-wishlist-item-dimension-margin-right;
            .msc-wishlist-dimension {
                &__label {
                    margin-right: $msv-wishlist-item-dimension-label-margin;
                }
            }
        }

        &-add-button {
            @include primary-button(
                var(--msv-wishlist-secondary-btn-bg),
                var(--msv-wishlist-secondary-btn-font-color),
                var(--msv-wishlist-secondary-btn-border)
            );
            box-sizing: border-box;
            font-size: var(--msv-wishlist-font-size);
            margin: $msv-wishlist-item-add-button-margin;
            @include add-icon($dobbies-icon-cart, before);
            background-color: var(--dobbies-accent-brand-color);
            border: 1px solid var(--dobbies-accent-brand-color);
            color: var(--dobbies-font-secondary-color);
            padding: 0 10px 0 35px;
            line-height: 17px;
            min-width: 100%;
            font-size: var(--dobbies-body-font-size-m);
            font-weight: var(--dobbies-font-weight-heavy);
            line-height: initial;
            border: 0;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            overflow: hidden;
            &:before {
                font-size: 29px;
                margin-right: 15px;
                font-weight: normal;
                font-family: '$dobbies-icon-font-family';
                position: absolute;
                top: 6px;
                left: 15px;
                @media (max-width: $msv-breakpoint-xl) {
                    top: 6px;
                    font-size: 28px;
                }
                @media (max-width: $dobbies-container-max-width-l) {
                    top: 8px;
                    font-size: var(--dobbies-body-font-size-ml);
                }
            }
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--dobbies-bg-color-secondary);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: var(--dobbies-font-primary-color);
                background: var(--dobbies-bg-color-primary);
                &:after {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
            &:disabled,
            &[disabled] {
                cursor: default;
                border-color: $dobbies-gray-300;
                background-color: $dobbies-gray-300;
                color: var(--dobbies-font-secondary-color);
                &:hover {
                    border-color: $dobbies-gray-300;
                    background-color: $dobbies-gray-300;
                    color: var(--dobbies-font-secondary-color);
                    &:after {
                        background-color: $dobbies-gray-300;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                    }
                }
            }

            @media (min-width: $dobbies-container-max-width-xl) {
                max-height: 45px;
            }

            @media (max-width: $dobbies-container-max-width-xl) {
                max-height: 43px;
            }

            @media (max-width: $dobbies-container-max-width-l) {
                max-height: 40px;
                line-height: 16px;
            }
            @media (max-width: $dobbies-container-max-width-s) {
                font-size: var(--dobbies-body-font-size-s);
            }
        }

        &-remove-button {
            @include vfi();
            @include add-icon($msv-x-shape);
            background-color: transparent;
            border: 1px solid transparent;
            color: var(--dobbies-font-primary-color);
            padding: 0;
            position: absolute;
            right: 10px;
            top: 10px;
            //top: 3px;
            cursor: pointer;
            width: 26px;
            height: 26px;
            display: -webkit-flex;
            justify-content: center;
            -webkit-justify-content: center;
            align-items: center;
            -webkit-align-items: center;

            &:before {
                font-size: var(--dobbies-body-font-size-ml);
                width: 100%;
                height: 100%;
                content: '';
                background: $white;
                border: 1px solid $dobbies-grey;
                border-radius: 20px;
                display: inline-block;
                font-weight: var(--dobbies-font-weight-heavy);
                position: absolute;
                left: 0;
                top: 0;
            }

            &::after {
                content: '\00d7';
                width: 20px;
                height: 20px;
                font-size: 26px;
                position: relative;
                right: 0px;
                top: 0px;
                line-height: 20px;
                bottom: 0;
                font-weight: 100;
                left: 0;
            }
        }

        &-status {
            max-width: $msv-wishlist-item-product-status-max-width;
            .msc-alert {
                padding: 10px;
                margin-top: 5px;
                background-color: var(--dobbies-header-grey);

                &.msc-alert-success {
                    color: var(--dobbies-font-primary-color);
                    border: 1px solid #666;
                    padding: 13px 10px;
                    margin: 5px 0;
                    background-color: transparent;

                    button.close {
                        margin-top: -10px;
                        margin-right: -7px;

                        &:before {
                            color: var(--dobbies-font-primary-color);
                        }
                    }
                }

                button.close {
                    background-color: transparent;
                    border: none;
                    float: right;
                    margin-top: -5px;
                    margin-right: -5px;
                }
            }
        }

        .entire-product-link {
            display: block;
            overflow: hidden;
            max-height: initial;

            &:hover {
                text-decoration: none;

                .msc-price__strikethrough {
                    color: $dobbies-grey;
                }

                .ms-wishlist-items__product-title {
                    text-decoration: underline;
                }
            }
        }

        @media (max-width: $dobbies-container-max-width-xl) {
            max-width: 31%;
        }

        @media (max-width: $msv-breakpoint-sm) {
            max-width: 46%;
            margin: 0px 6px 20px 7px;
        }

        @media (min-width: $msv-container-max-width-l) {
            max-width: 253px;
        }
    }
    &__inventory-label {
        color: var(--msv-wishlist-link-color);
    }
    &__message-empty,
    &__message-waiting,
    &__message-failed {
        margin-bottom: $msv-wishlist-items-message-empty-margin-bottom;
    }

    .msc-empty_image {
        @include image-placeholder-rectangle(252px, 353px);
        max-width: 100%;
        @media screen and (max-width: $dobbies-container-max-width-xl) {
            @include image-placeholder-rectangle(240px, 336px);
            max-width: 100%;
        }
        @media screen and (max-width: $dobbies-container-max-width-l) {
            @include image-placeholder-rectangle(231px, 321px);
            max-width: 100%;
        }
        @media screen and (max-width: $dobbies-container-max-width-s) {
            @include image-placeholder-rectangle(190px, 246px);
            max-width: 100%;
        }
        @media screen and (max-width: $msv-breakpoint-mbl-sm) {
            @include image-placeholder-rectangle(260px, 365px);
            max-width: 100%;
            background-position: 40px 50px;
        }
        @media screen and (max-width: $msv-breakpoint-mbl) {
            @include image-placeholder-rectangle(170px, 275px);
            max-width: 100%;
            background-position: 10px 20px;
        }
        @media screen and (max-width: $dobbies-container-max-width-xs) {
            @include image-placeholder-rectangle(169px, 245px);
            max-width: 100%;
        }
    }
}
