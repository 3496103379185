//style presets

$dobbies-breadcrumb-list-margin-bottom: 20px;
:root {
    --dobbies-breadcrumb-item-font-color: var(--dobbies-font-primary-color);
    --dobbies-breadcrumb-item-font-size: var(--dobbies-body-font-size-m);
}

.ms-breadcrumb {
    overflow: hidden;

    &.basket-breadcrumb {
        li {
            &:first-child {
                a {
                    font-weight: $dobbies-font-weight-bold;
                }
            }
        }
    }

    .ms-breadcrumb_list {
        color: var(--dobbies-breadcrumb-item-font-color);
        display: block;
        align-items: center;
        list-style: none;
        padding: 0;
        margin-bottom: $dobbies-breadcrumb-list-margin-bottom;
        .ms-breadcrumb-back-button {
            text-decoration: underline;
            cursor: pointer;
            float: left;
            margin-right: 10px;
            @include add-icon($msv-ChevronLeft, before);
            &:before {
                padding-right: 5px;
            }
        }
    }
    .ms-breadcrumbs_item,
    .ms-breadcrumb_item {
        cursor: pointer;
        color: var(--dobbies-breadcrumb-item-font-color);
        //float: left;
        display: inline-block;
        font-size: var(--dobbies-body-font-size-s);
        position: relative;
        margin: 0;
        font-weight: $dobbies-font-weight-normal;
        a[data-no-click='true'] {
            cursor: default;
            pointer-events: none;
        }
        > a {
            &:after {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                font-size: var(--dobbies-breadcrumb-icon);
                transform: rotate(90deg);
                color: var(--dobbies-breadcrumb-icon-color);
                font-weight: var(--dobbies-font-weight-heavy);
                width: 14px;
                height: 12px;
                text-align: center;
                position: relative;
                top: 7px;
                margin-left: 2px;
            }

            div {
                border-bottom: 1px solid transparent;
            }

            &:hover {
                text-decoration: none;
                div {
                    border-bottom: 1px solid var(--dobbies-breadcrumb-item-font-color);
                }
            }

            &.ms-breadcrumb_link {
                &:after {
                    top: -2px;
                }
            }
        }
        > span {
            font-size: 0;
            width: 24px;
            display: none;
            text-align: center;
            &:after {
                @include dobbies-icon();
                content: $dobbies-icon-arrow;
                font-size: var(--dobbies-breadcrumb-icon);
                transform: rotate(90deg);
                color: var(--dobbies-breadcrumb-icon-color);
                font-weight: var(--dobbies-font-weight-heavy);
            }
        }
    }
    .breadcrumb_item-current {
        > a {
            &:after {
                display: none;
            }
        }
    }
    .ms-breadcrumb_link {
        color: var(--dobbies-breadcrumb-item-font-color);
        //float: left;
        display: inline-block;
        font-size: var(--dobbies-body-font-size-s);
        position: relative;
        margin: 0;
        padding-right: 0;
        cursor: pointer;
        font-weight: $dobbies-font-weight-normal;
        > div {
            display: inline-block;
            border-bottom: 1px solid transparent;
        }
        &:after {
            @include dobbies-icon();
            content: $dobbies-icon-arrow;
            font-size: var(--dobbies-breadcrumb-icon);
            transform: rotate(90deg);
            color: var(--dobbies-breadcrumb-icon-color);
            font-weight: var(--dobbies-font-weight-heavy);
            display: inline-block;
            width: 14px;
            height: 12px;
            text-align: center;
            position: relative;
            top: -2px;
            margin-right: 2px;
        }
        &:hover {
            text-decoration: none;
            div {
                border-bottom: 1px solid var(--dobbies-breadcrumb-item-font-color);
            }
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    &.breadcrumb_item-current,
    .ms-breadcrumb_text {
        font-weight: var(--dobbies-font-weight-bold);
        text-decoration: none;
        display: inline-block;
        font-size: var(--dobbies-body-font-size-s);
        position: relative;
        top: -1px;

        .ms-breadcrumb_link {
            font-weight: var(--dobbies-font-weight-bold);
            text-decoration: none;
        }
    }
}
